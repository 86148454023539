<script>
  import { toast } from "svelte-sonner";
  import Router from "svelte-spa-router";
  import { fetchPost } from "../helpers";
  import { validEmail, validPassword } from "../lib/utils/AuthUtils";
  import {
    accessToken,
    fullname,
    isAuthenticated,
    user_id,
    role,
    currentUser,
  } from "../stores";
  import Email from "./Email.svelte";

  export let showPasswordReset;

  let email = "";
  let password = "";

  async function loginUser() {
    if (email === "" || password === "") {
      toast.warning("Please fill in all fields.");
      return;
    }

    if (!validEmail(email)) {
      toast.warning("The email provided is invalid.");
      return;
    }

    if (!validPassword(password)) {
      toast.warning("The password provided is invalid.");
      return;
    }

    try {
      if (import.meta.env.VITE_APP_MODE === "ADMIN") {
        let response = await fetchPost("/auth/login", { email, password });

        if (!response.success) toast.error(response?.message);
        else {
          fullname.set(
            response.user.first_name + " " + response.user.last_name,
          );
          window.location.reload();
        }

        isAuthenticated.set(await response.success);

        email = "";
        password = "";
      } else {
        let response = await fetchPost("/auth/login", { email, password });

        if (!response.detail.success) toast.error(response?.detail?.message);
        else {
          fullname.set(
            response.user.first_name + " " + response.user.last_name,
          );
          localStorage.setItem("accessToken", response.token.access_token);
          window.location.reload();
        }

        isAuthenticated.set(response.detail.success);
        email = "";
        password = "";
      }
    } catch (e) {
      console.warn(e);
    }
  }
</script>

<div class="bg-base-200 flex h-screen items-center justify-center">
  <main
    class="bg-base-100 m-4 flex w-full max-w-md flex-col overflow-hidden rounded border p-8"
  >
    <Router routes={{ "/email/:email_id/:email_type": Email }} />

    <img src="/Haast.svg" class="h-12 self-center" alt="Haast" />

    <form on:submit|preventDefault={loginUser}>
      <div class="form-control w-full">
        <label class="label" for="email">
          <span class="label-text">Email</span>
        </label>
        <input
          name="email"
          id="email"
          type="text"
          placeholder="Enter your email"
          class="input input-bordered w-full"
          bind:value={email}
          on:keydown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              document.querySelector("#pass").focus();
            }
          }}
          required
        />
      </div>

      <div class="form-control w-full">
        <label class="label" for="pass">
          <span class="label-text">Password</span>
        </label>
        <input
          name="pass"
          id="pass"
          class="input input-bordered w-full"
          type="password"
          placeholder="Enter your password"
          bind:value={password}
          on:keydown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              loginUser();
            }
          }}
          required
        />
      </div>

      <label class="label w-max">
        <button
          class="link-hover label-text-alt"
          on:click={() => (showPasswordReset = true)}
        >
          Forgot Password?
        </button>
      </label>

      <button class="btn btn-primary mt-2 w-full" type="submit">Login</button>
    </form>
  </main>
</div>
