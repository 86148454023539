<script lang="ts">
  import Modal from "../Modals/Modal.svelte";
  import QueryPrettyPrint from "./QueryPrettyPrint.svelte";

  export let hit;
  export let rule;

  const source_query: { [key: string]: number } = JSON.parse(
    hit.json,
  ).source_query;
  const highlightedPhrases = Object.entries(source_query)
    .sort((a, b) => b[1] - a[1])
    .map(([k, v]) => k.toLowerCase());
</script>

<Modal modalId="hit_info_modal_{hit.hit_id}">
  <h2 class="max-w-m truncate text-xl font-semibold">
    ID: #{hit.id}
  </h2>

  <QueryPrettyPrint {rule} {highlightedPhrases} />
</Modal>
