<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchGet, saveLiveRule, tidyPoolLabel } from "../../helpers";
  import { RuleDataType } from "../../lib/interfaces/Rule.interface";
  import { saveSharedAssetRuleToMyRules } from "../../lib/utils/RuleUtils";
  import { convertBuilderToBoolean } from "../../ruleBuilderConverter";
  import { org_id } from "../../stores";
  import AssetRuleBuilder from "../AssetReview/AssetRuleBuilder.svelte";
  import Modal from "../Modals/Modal.svelte";
  import Rule from "./../Rule.svelte";
  import ConditionalAssetRuleBuilder from "../AssetReview/ConditionalAssetRuleBuilder.svelte";

  const dispatch = createEventDispatcher();

  export let rule;
  export let ruleSets;

  let originalRuleBoolean = rule["boolean"];
  let originalRuleName = rule["name"];
  let originalRuleDescription = rule["description"];
  let originalRuleRemediationStep = rule["remediation_step"];
  let originalRuleDataTypes = rule["data_type"];
  let originalDefaultHitPriority = rule["default_hit_priority"];
  let dataTypes = [];
  let selectedDataTypes = rule["data_type"].split(", ");
  let data = {
    loaded: false,
    index: -1,
    name: rule.name,
    type: RuleDataType.BUILDER,
    description: rule.description,
    remediation_step: rule.remediation_step,
    data_types: selectedDataTypes,
    size: 10000,
    boolean: rule.boolean,
    builder: [],
  };

  function cancelView() {
    rule["boolean"] = originalRuleBoolean;
    rule["name"] = originalRuleName;
    rule["description"] = originalRuleDescription;
    rule["remediation_step"] = originalRuleRemediationStep;
    rule["data_type"] = originalRuleDataTypes;
    rule["default_hit_priority"] = originalDefaultHitPriority;

    dispatch("viewCancelled");

    document.querySelector(`#ruleView-${rule?.id}`).close();
  }

  function updateRuleDataTypes() {
    rule["data_type"] = selectedDataTypes.join(", ");
  }

  async function updateDataTypes() {
    const res = await fetchGet("/pool");
    dataTypes = await res.data;

    for (let i = 0; i < dataTypes.length; i++) {
      dataTypes[i].label = tidyPoolLabel(dataTypes[i].label);
    }
  }

  const saveMyAssetRule = async () => {
    let res = await saveSharedAssetRuleToMyRules(rule);

    if (res.success) {
      toast.success("Successfully saved shared live rule to My Rules.");
      dispatch("sharedRuleSaved", rule);
    }
  };

  const saveMyLiveRule = async () => {
    let res = await saveLiveRule(rule, $org_id, true);

    if (res.success) {
      toast.success("Successfully saved shared live rule to My Rules.");
      dispatch("sharedRuleSaved", rule);
    }
  };

  onMount(async () => {
    await updateDataTypes();
  });

  $: if (selectedDataTypes) {
    updateRuleDataTypes();
  }
</script>

<Modal modalId="ruleView-{rule?.id}" size="xl" on:close={cancelView}>
  <h3 class="text-xl font-semibold">View Rule</h3>

  <div class="border-top my-4">
    <h4 class="text-l font-semibold">Checklists</h4>
    <div class="mt-2 flex flex-wrap gap-2">
      {#each ruleSets as ruleSet}
        {#if ruleSet.rules.includes(rule.id)}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <button class="btn btn-outline btn-primary btn-sm">
            {ruleSet.name}
          </button>
        {/if}
      {/each}
    </div>
  </div>

  {#if rule.type === "asset"}
    {#if rule.is_conditional}
      <ConditionalAssetRuleBuilder
        editingSavedRule={true}
        conditionalRuleData={rule}
        isViewing={true}
        on:removeRule={cancelView}
        on:saveConditionalRule={() => null}
        loading={false}
      />
    {:else}
      <AssetRuleBuilder
        bind:rule
        savingRule={false}
        isViewing={true}
        on:removeRule={cancelView}
        on:saveRule={(e) => saveMyAssetRule(e.detail)}
      />
    {/if}
  {:else}
    <Rule
      index={0}
      bind:data
      d_types={dataTypes}
      bind:defaultRulePriority={rule.default_hit_priority}
      isViewing={true}
      on:saveRule={async () => {
        rule.name = data.name;
        rule.description = data.description;
        rule.remediation_step = data.remediation_step;
        rule.boolean = convertBuilderToBoolean(data.builder);
        rule.data_types = data.data_types;
        await saveMyLiveRule(rule);
      }}
      runRule={() => console.log("Do nothing for now")}
      deleteRule={cancelView}
    />
  {/if}
</Modal>
