<script>
  import Icon from "@iconify/svelte";
  import { Doughnut } from "svelte-chartjs";

  export let chartIndex;
  export let chartData;
  export let chartTitle;
  export let chartDataSet;
  export let reportId = -1;

  let chartDataSetSearch = "";
</script>

<div class="relative min-w-0 bg-base-100">
  {#if chartTitle}
    {#if reportId > 0}
      <div
        class="tooltip tooltip-left absolute right-0 top-0"
        data-tip="Open stream in new tab"
      >
        <a
          href="#/stream/{reportId}"
          target="_blank"
          class="btn btn-square btn-ghost btn-sm"
        >
          <Icon icon="iconoir:open-new-window" />
        </a>
      </div>
    {/if}

    <div class="dropdown block" style="max-width: calc(100% - 2rem);">
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->

      <label
        tabindex="0"
        class="btn btn-ghost btn-sm mb-1 w-full flex-nowrap justify-between text-gray-500"
        for=""
      >
        <div class="truncate" style="max-width: calc(100% - 2rem);">
          {chartTitle}
        </div>
        <Icon icon="iconoir:nav-arrow-down" />
      </label>
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <ul
        tabindex="0"
        class="menu dropdown-content z-[1] max-h-80 w-52 flex-nowrap overflow-y-auto rounded border bg-base-100 p-2 shadow"
      >
        <input
          type="text"
          bind:value={chartDataSetSearch}
          class="input input-sm input-bordered mb-2 w-full"
          placeholder="Search ..."
        />
        {#each chartDataSet.filter((c) => c.options.plugins.title.text
            .toLowerCase()
            .includes(chartDataSetSearch.toLowerCase())) as chartData}
          <li>
            <button
              on:click={() => {
                const index = chartDataSet.findIndex(
                  (data) =>
                    data.options.plugins.title.text ===
                    chartData.options.plugins.title.text,
                );
                chartIndex = index;
              }}
            >
              {chartData.options.plugins.title.text}
            </button>
          </li>
        {/each}
      </ul>
    </div>
  {/if}
  <div class="mx-auto w-[90%]">
    <Doughnut data={chartData?.data} options={chartData?.options} />
  </div>
</div>
