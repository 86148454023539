<script lang="ts">
  import Icon from "@iconify/svelte";
  import { toast } from "svelte-sonner";

  export let phrase: string = "";
  export let isHighlighted: boolean = false;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(phrase);
    toast.success("Copied to clipboard");
  };
</script>

<div
  class="flex justify-between rounded border py-1 pl-2 pr-1"
  class:bg-base-300={isHighlighted}
>
  {phrase}
  <button class="btn btn-square btn-ghost btn-xs" on:click={copyToClipboard}>
    <Icon icon="iconoir:copy" />
  </button>
</div>
