<script>
//     <!-- Basic usage -->
// <LabelManager
//   bind:allLabels
//   bind:selectedLabels={labels}
// />

// <!-- Disabled state -->
// <LabelManager
//   bind:allLabels
//   bind:selectedLabels={labels}
//   disabled={!canEditThisStream || activeTabLabel === "Archived"}
// />

// <!-- With tooltip -->
// <LabelManager
//   bind:allLabels
//   bind:selectedLabels={labels}
//   disabled={reportDetails.status === "Archived" || !$canEditCurrentStream}
//   tooltip={reportDetails.status === "Archived" 
//     ? "This report has been archived and cannot be modified"
//     : !$canEditCurrentStream 
//       ? "You do not have permission to modify this report"
//       : ""}
// />

// <!-- Read-only (no search) -->
// <LabelManager
//   bind:allLabels
//   bind:selectedLabels={labels}
//   showSearch={false}
// />

// <!-- With direct API operations -->
// <LabelManager
//   bind:allLabels
//   bind:selectedLabels={labels}
//   reportId={report.id}
// />
    
    import { fetchPost, fetchDelete } from "../../helpers";
    import LabelSearch from "./LabelSearch.svelte";
    import ListOfBeans from "./ListOfBeans.svelte";
    import { toast } from "svelte-sonner";

  
    export let selectedLabels = [];
    export let disabled = false;
    export let showSearch = true;
    export let tooltip = "";
    export let reportId = null;
  
  
    async function handleLabelSelected(event) {
      const label = event.detail;
      // Check if label already exists
      if (selectedLabels.some(l => l.id === label.id)) {
        return;
      }

      // Add label before API call for immediate UI update
      const updatedLabels = [...selectedLabels, label];
      selectedLabels = updatedLabels;
      
      if (reportId) {
        try {
          await fetchPost(`/label/assign/${label.id}`, {
            report_id: reportId
          });
        } catch (error) {
          selectedLabels = selectedLabels.filter(l => l.id !== label.id);
          toast.error("Failed to assign label");
          return;
        }
      }
      
    }
  
    async function handleLabelRemoved(event) {
      const label = event.detail;
      const updatedLabels = selectedLabels.filter(l => l.id !== label.id);
      selectedLabels = updatedLabels;
      if (reportId) {
        try {
          await fetchDelete(`/label/assign/${label.id}`, {
            report_id: reportId
          });
        } catch (error) {
          selectedLabels = [...selectedLabels, label];
          toast.error("Failed to remove label");
          return;
        }
      }
  
    }
  </script>
  
  <div class="flex flex-wrap items-center gap-2">
    {#if tooltip}
      <div class="tooltip" data-tip={tooltip}>
        <div class="contents">
          {#if showSearch}
            <LabelSearch
              {selectedLabels}
              {disabled}
              on:labelSelected={handleLabelSelected}
              on:deleteLabel={handleLabelRemoved}
            />
          {/if}
        </div>
      </div>
    {:else if showSearch}
      <LabelSearch
        {selectedLabels}
        {disabled}
        on:labelSelected={handleLabelSelected}
        on:deleteLabel={handleLabelRemoved}
      />
    {/if}
  
    <ListOfBeans
      labels={selectedLabels}
      {disabled}
      closeIcon={showSearch}
      on:labelBeanClicked={handleLabelRemoved}
    />
  </div>