import ApprovalStatus from "./CommentType/ApprovalStatus.svelte";
import Action from "./CommentType/Action.svelte";
import Assign from "./CommentType/Assign.svelte";
import Done from "./CommentType/Done.svelte";
import Email from "./CommentType/Email.svelte";
import Priority from "./CommentType/Priority.svelte";
import Status from "./CommentType/Status.svelte";
import Text from "./CommentType/Text.svelte";
import Unassign from "./CommentType/Unassign.svelte";
import Version from "./CommentType/Version.svelte";

export const comment_types = {
  Action: Action,
  ApprovalStatus: ApprovalStatus,
  Assign: Assign,
  Done: Done,
  Email: Email,
  Priority: Priority,
  Status: Status,
  Text: Text,
  Unassign: Unassign,
  Version: Version,
};

export const comment_icons = {
  Action: "iconoir:clipboard-check",
  ApprovalStatus: "iconoir:design-nib",
  Assign: "iconoir:add-user",
  Done: "iconoir:check",
  Email: "iconoir:mail",
  Priority: "iconoir:sort",
  Status: "iconoir:warning-circle",
  Text: "iconoir:message-text",
  Unassign: "iconoir:remove-user",
  Version: "iconoir:arrow-up-circle",
};
