<script>
  export let text = "";
  export let maxLength = 20;

  $: truncatedText = truncateMiddle(text, maxLength);

  function truncateMiddle(str, maxLength) {
    if (str.length <= maxLength) return str;
    const leftHalf = Math.ceil(maxLength / 2);
    const rightHalf = Math.floor(maxLength / 2) - 3;
    return str.substr(0, leftHalf) + "..." + str.substr(str.length - rightHalf);
  }
</script>

<span title={text}>{truncatedText}</span>
