
import {labelToFilter,pinnedLabels} from '../../stores'

// Navigate to the streams page and show the streams with the selected label.
export function showStreamsWithSelectedLabel(labelId, streamType = "live") {
  labelToFilter.set(labelId);

  if (streamType == "live") {
    if (window.location.hash != "#/streams") window.location.hash = "#/streams?labels=";
  } else if (streamType === "asset") {
    if (window.location.hash != "#/reviews") window.location.hash = "#/reviews?labels=";
  }
}

// Update the list of pinned labels.
export function updatePinnedLabels(labels) {
  // Filter the labels to only include the pinned labels.
  pinnedLabels.set(
    labels.filter(
      (label) => label.pinned || label.pinned_live || label.pinned_asset,
    ),
  );
}
