<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";

  export let searchText = "";
  export let searchSettings = {};
  export let dropdownFilterDesign = true;

  const dispatch = createEventDispatcher();

  let settingsToggle = false;
</script>

<div class="m-2 flex">
  <input
    class="input input-sm input-bordered input-ghost w-full rounded-r-none"
    type="text"
    placeholder="Filter"
    bind:value={searchText}
    on:keyup={(e) => {
      if (e.key === "Enter") dispatch("enterPressed");
    }}
  />

  <div class="dropdown dropdown-end">
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
    <label
      tabindex="0"
      for=""
      class="btn btn-sm rounded-l-none"
      on:click={() => (settingsToggle = !settingsToggle)}
    >
      <Icon icon="iconoir:filter-alt" />
    </label>
    {#if dropdownFilterDesign}
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <div
        tabindex="0"
        class="dropdown-content z-10 w-48 rounded border bg-base-100"
      >
        <p class="px-4 py-1">Search filters</p>
        <div class="menu menu-sm p-2 pt-0">
          {#each Object.keys(searchSettings) as settingKey}
            <li>
              <label>
                <input
                  type="checkbox"
                  class="checkbox-primary checkbox checkbox-sm"
                  id="hit-title"
                  bind:checked={searchSettings[settingKey]["enabled"]}
                />
                <span class="label-text" for="hit-title">
                  {searchSettings[settingKey]["label"]}
                </span>
              </label>
            </li>
          {/each}
        </div>
      </div>
    {/if}
  </div>

  {#if !dropdownFilterDesign}
    <button
      class="btn btn-primary btn-sm ml-2"
      on:click={() => dispatch("enterPressed")}
    >
      Go
    </button>
  {/if}
</div>

{#if !dropdownFilterDesign && settingsToggle}
  <li>
    <p class="menu-title">Search filters</p>
    <ul>
      {#each Object.keys(searchSettings) as settingKey}
        <li>
          <label class="label cursor-pointer justify-start">
            <input
              type="checkbox"
              class="checkbox-primary checkbox checkbox-sm"
              id="hit-title"
              bind:checked={searchSettings[settingKey]["enabled"]}
            />
            <span class="label-text" for="hit-title">
              {searchSettings[settingKey]["label"]}
            </span>
          </label>
        </li>
      {/each}
    </ul>
  </li>
{/if}
