/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `SharedRule` entity permissions.
 */
export const SharedRulePermissions = Object.freeze({
  EDIT: "edit",
  VIEW: "view",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `SharedRule` entity statuses.
 */
export const SharedRuleStatus = Object.freeze({
  ACTIVE: "active",
  UPDATED: "updated",
  DELETED: "deleted",
});
