<script>
  import Icon from "@iconify/svelte";
  export let showOnPx = 150;
  let hidden = true;

  const scrollContainer = () => document.documentElement || document.body;

  const handleOnScroll = () => {
    if (scrollContainer().scrollTop > showOnPx) hidden = false;
    else hidden = true;
  };
</script>

<svelte:window on:scroll={handleOnScroll} />
{#if !hidden}
  <button
    class="btn btn-circle btn-sm fixed bottom-4 right-4 z-20 shadow-xl"
    on:click={() => (scrollContainer().scrollTop = 0)}
  >
    <Icon icon="iconoir:nav-arrow-up" class="text-xl" />
  </button>
{/if}
