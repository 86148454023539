<script>
  import { onMount } from "svelte";
  import { RuleSearchType } from "../../lib/interfaces/Rule.interface";

  export let text = ""; // The text to display
  export let ruleSearchType = ""; // The type of rule

  // Tooltip texts mapped by ruleSearchType
  const tooltipTexts = {
    [RuleSearchType.LEXICAL]:
      "An exact match is when the exact word or phrase (disregarding spacing and punctuation) is detected on the asset.",
    [RuleSearchType.BOOLEAN]:
      "A boolean match occurs when the asset matches a set of true/false conditions connected by logical operators (e.g., AND, OR, NOT).",
    [RuleSearchType.MANUAL]: "A mark is where a user has ticked the checkbox.",
    [RuleSearchType.TENSOR]:
      "Similar terms or examples mean the same thing but may be expressed differently (e.g., synonyms or minor variations).",
    [RuleSearchType.CONTEXTUAL]:
      "The AI will detect a match based on your instructions and definitions.",
    [RuleSearchType.IMAGE]:
      "A similar feature in the image is detected when the specified idea or object is present.",
    [RuleSearchType.VISUAL]:
      "A similar feature in the image is detected when the specified idea or object is present.",
  };

  let showTooltip = false;
  let tooltipText = tooltipTexts[ruleSearchType] || "Unknown rule type.";
  let tooltipStyles = "";

  function handleMouseEnter(event) {
    showTooltip = true;
    positionTooltip(event);
  }

  function handleMouseLeave() {
    showTooltip = false;
  }

  function positionTooltip(event) {
    const rect = event.target.getBoundingClientRect();
    const tooltipMaxWidth = 250; // Set a maximum width for the tooltip
    const tooltipPadding = 12; // Sum of horizontal paddings (6px left + 6px right)
    const top = rect.bottom + 8; // 8px offset from the bottom of the text
    let left = rect.left + rect.width / 2 - tooltipMaxWidth / 2;

    // Ensure the tooltip doesn't go off the left side of the screen
    if (left < 8) left = 8;
    // Ensure the tooltip doesn't go off the right side of the screen
    if (left + tooltipMaxWidth > window.innerWidth - 8)
      left = window.innerWidth - tooltipMaxWidth - 8;

    tooltipStyles = `
      position: fixed;
      top: ${top}px;
      left: ${left}px;
      max-width: ${tooltipMaxWidth}px;
      z-index: 1000;
      background-color: rgba(0, 0, 0, 0.75);
      padding: 6px 8px;
      border-radius: 4px;
    `;
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<span
  class="italic underline"
  on:mouseenter={handleMouseEnter}
  on:mouseleave={handleMouseLeave}
>
  {text}
</span>

{#if showTooltip}
  <div class="custom-tooltip" style={tooltipStyles}>
    {tooltipText}
  </div>
{/if}

<style>
  .italic {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
  }

  .custom-tooltip {
    color: #fff;
    white-space: normal; /* Allow text wrapping */
    text-align: center;
    font-size: 14px;
    pointer-events: none;
    box-sizing: border-box; /* Include padding in width calculations */
  }
</style>
