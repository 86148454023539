<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { link, push } from "svelte-spa-router";
  import LabelSearch from "../components/Labels/LabelSearch.svelte";
  import ListOfBeans from "../components/Labels/ListOfBeans.svelte";
  import LogoLoader from "../components/LogoLoader.svelte";
  import ReportHistory from "../components/ReportHistory.svelte";
  import ReportAccess from "../components/Reports/ReportAccess.svelte";
  import ReportSubscribeButton from "../components/Reports/ReportSubscribeButton.svelte";
  import Table from "../components/TableViews/Table.svelte";
  import TableLoading from "../components/TableViews/TableLoading.svelte";
  import { fetchGet, fetchPatch, generate32BitInteger } from "../helpers";
  import {
    createReportClone,
    deleteLabel,
    deleteStream,
    fetchDataAndUpdateDisplay,
    getLabels,
    labelSelected,
    requestScrape,
    saveDetails,
  } from "./reportFunctions";

  import NProgress from "nprogress";
  import ActionConfirmation from "../components/ActionConfirmation.svelte";
  import {
    actions,
    canEditCurrentStream,
    currentUser,
    isAuthenticated,
  } from "../stores";
  import "./nprogress.css";

  export let params = null;

  let reportDetails = {
    name: "",
    status: "Draft",
    description: "",
    report_labels: [],
  };

  let reportData = {
    tableData: [],
    hitCountInRule: {},
    allRules: [],
  };

  let labelData = {
    labels: [],
    allLabels: [],
  };

  let forceStateUpdate = 1;

  let report_id;
  let actionTexts = [];

  let loading = { state: false };
  let deletionInProgress = { state: false };
  let isInitialised = { state: false };

  let loadingRefresh = false;
  let confirmRefresh = false;

  let dateToSortByString = "";

  let currentPage = 1;
  let openedTab = "Open";

  let loading_report = true;

  let accessKeyer = false;

  async function fetchDataAndUpdateDisplayWrapper(shouldReset = false) {
    NProgress.start();

    try {
      await fetchDataAndUpdateDisplay(
        shouldReset,
        reportData,
        loading,
        report_id,
        reportDetails,
      );

      // Force responsiveness
      reportData = reportData;
      reportDetails = reportDetails;
      loading = loading;
    } catch (error) {
      console.error("Error during data fetch:", error);
    } finally {
      NProgress.done();
    }
  }

  // Keeping refreshRules in here are we may want to handle it differently for spelling vs normal reports
  async function refreshRules() {
    //TODO: Update how this behaves - depending on how we are going to run spelling do we just want to take this out?

    let hitCount = 0;

    reportData.reportData.allRules?.forEach((rule) => {
      hitCount += hitCountInRule[rule.id];
    });

    if (confirmRefresh) {
      loadingRefresh = true; // Set loading to true before starting the operations

      try {
        const refreshPromises = Array.from(
          reportData.reportData.allRules.values(),
        ).map((rule) => {
          return fetchPatch(`/rule/run/${rule.id}`, rule); // Just return the promise here
        });

        // Wait for all promises to resolve before proceeding
        await Promise.all(refreshPromises);
        fetchDataAndUpdateDisplayWrapper(true);
        toast.success("All rules have been refreshed.");
      } catch (error) {
        toast.error("Something went wrong while refreshing your rules.");
        console.error("Error during rule refresh:", error);
      } finally {
        loadingRefresh = false; // Set loading to false after all operations are complete
      }
    } else {
      refresh_confirm_modal.showModal();
    }
  }

  const logoutUser = async () => {
    $isAuthenticated = false;
    localStorage.setItem("logged_in", false);
    localStorage.setItem("accessToken", "");
    await fetchGet(`/auth/logout`);

    push("/home");
  };

  function refreshTable(event) {
    reportData.tableData = event.detail;
    forceStateUpdate = forceStateUpdate + 1;
  }

  NProgress.configure({
    // Full list: https://github.com/rstacruz/nprogress#configuration
    minimum: 0.16,
    showSpinner: false,
  });

  onMount(async () => {
    loading_report = true;

    report_id = params.report_id ? params.report_id : generate32BitInteger();

    actionTexts = $actions.map(function (elem) {
      return elem.action_text;
    });
    try {
      await fetchDataAndUpdateDisplayWrapper().then(() =>
        getLabels(labelData, reportDetails).then(() => (labelData = labelData)),
      );
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle errors, e.g., show an error message to the user
    }

    if ($currentUser.role == "admin") {
      $canEditCurrentStream = true;
    } else {
      const response = await fetchGet(
        `/report/${report_id}/get-user-permission-to-report`,
      );
      const orgPermissionsResponse = await fetchGet(
        `/report/${report_id}/get-org-permission`,
      );

      if (!response.permission || response.permission === "none") {
        if (
          !orgPermissionsResponse.permission ||
          orgPermissionsResponse.permission === "none"
        )
          logoutUser();
      }

      if (
        response.permission === "edit" ||
        orgPermissionsResponse.permission === "edit"
      )
        $canEditCurrentStream = true;
      else $canEditCurrentStream = false;
    }

    loading_report = false;
    isInitialised.state = true;
  });
</script>

<svelte:head>
  <title>Spelling Report - Haast</title>
</svelte:head>

<header class="bg-base-content/[5%] -mx-12 -my-4 mb-4 px-12 py-4">
  <!-- extra contianer required for max-w-screen-xl but full background -->
  <div class="mx-auto max-w-[1706px] 2xl:pl-[13.5rem]">
    <div class="breadcrumbs text-sm">
      <ul>
        <li>
          <a href="/spelling" use:link>Spelling Reports</a>
        </li>
        <li>{reportDetails.name}</li>
      </ul>
    </div>

    <div class="flex items-center gap-4">
      <div class="tooltip min-w-0" data-tip={reportDetails.name}>
        <h1 class="truncate text-3xl font-semibold">
          {reportDetails.name}
        </h1>
      </div>

      {#if $canEditCurrentStream}
        <div class="dropdown">
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <label tabindex="0" class="btn btn-square btn-ghost btn-sm" for="">
            <Icon icon="iconoir:more-horiz" class="text-xl" />
          </label>
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <ul
            tabindex="0"
            class="menu dropdown-content bg-base-100 z-20 m-1 w-52 rounded border p-2"
          >
            <li class:disabled={reportDetails.status === "Archived"}>
              <button
                on:click={() => {
                  reportDetails.status = "Draft";
                  saveDetails(
                    report_id,
                    reportDetails,
                    isInitialised,
                    $currentUser.id,
                  );
                  push(`/spelling/edit/${report_id}`);
                }}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="iconoir:edit-pencil" class="text-xl" /> Edit Report
              </button>
            </li>
            <li class:disabled={reportDetails.status === "Archived"}>
              <button
                on:click={() => createReportClone(report_id)}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="iconoir:copy" class="text-xl" /> Duplicate Report
              </button>
            </li>
            <li class:disabled={reportDetails.status === "Archived"}>
              <!-- svelte-ignore missing-declaration -->
              <button
                on:click={() => deletionConfirmationModal.showModal()}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="iconoir:trash" class="text-xl" /> Delete Report
              </button>
            </li>
            <li class:disabled={reportDetails.status === "Archived"}>
              <button
                on:click={() =>
                  document.querySelector("#report-history-dialog")?.showModal()}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="open-book" class="text-xl" /> Report History
              </button>
            </li>

            {#each [{ name: "Draft", icon: "page-edit" }, { name: "Live", icon: "megaphone" }, { name: "Archived", icon: "archive" }] as e}
              <li class:hidden={reportDetails.status === e.name}>
                <button
                  on:click={() => {
                    reportDetails.status = e.name;
                    saveDetails(
                      report_id,
                      reportDetails,
                      isInitialised,
                      $currentUser.id,
                    );
                  }}
                >
                  <Icon icon="iconoir:{e.icon}" class="text-xl" />
                  {e.name} Spelling Report
                </button>
              </li>
            {/each}

            <li class:disabled={reportDetails.status === "Archived"}>
              <button
                on:click={() => requestScrape(report_id)}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="iconoir:refresh-double" class="text-xl" />
                Request Rescrape
              </button>
            </li>
          </ul>
        </div>
      {/if}

      <div class="ml-auto flex items-center gap-2">
        <ReportAccess
          reportStatus={reportDetails.status}
          bind:loading={accessKeyer}
          disabled={reportDetails.status === "Archived"}
          canEditPermissions={$canEditCurrentStream}
        />

        {#if report_id}
          <ReportSubscribeButton
            {report_id}
            canSubscribe={reportDetails.status === "Live"}
          />
        {/if}
      </div>
    </div>

    <p class="text-base-content/70 font-light">
      {#if reportDetails.description}
        {reportDetails.description.trim() === ""
          ? ""
          : reportDetails.description}
      {/if}
    </p>

    <div class="mt-4 flex items-center gap-4">
      <div class="z-10 flex flex-wrap items-center gap-2">
        {#if $canEditCurrentStream}
          <LabelSearch
            allLabels={labelData.allLabels}
            selectedLabels={labelData.labels}
            on:labelSelected={(e) => {
              labelSelected(e, labelData, report_id);
              labelData = labelData;
            }}
            on:labelUnselected={(e) => {
              deleteLabel(e, labelData, report_id);
              labelData = labelData;
            }}
            disabled={reportDetails.status === "Archived" ||
              $canEditCurrentStream === false}
          />
        {/if}
        {#if labelData.labels.length > 0}
          <ListOfBeans
            labels={labelData.labels}
            on:labelBeanClicked={(e) => {
              deleteLabel(e, labelData, report_id);
              labelData = labelData;
            }}
            closeIcon={true}
            disabled={reportDetails.status === "Archived" ||
              $canEditCurrentStream === false}
          />
        {/if}
      </div>

      <div class="ml-auto flex gap-2">
        <button
          class="btn btn-sm"
          on:click={() => {
            reportDetails.status = "Draft";
            saveDetails(
              report_id,
              reportDetails,
              isInitialised,
              $currentUser.id,
            );
            push(`/spelling/edit/${report_id}?step=1`);
          }}
          disabled={reportDetails.status === "Archived" ||
            $canEditCurrentStream === false}
        >
          Edit Dictionaries
        </button>
        <button
          disabled={loadingRefresh ||
            loading.state ||
            reportDetails.status === "Archived" ||
            $canEditCurrentStream === false}
          class="btn btn-primary btn-sm"
          on:click={() => refreshRules()}
        >
          {#if loadingRefresh}
            <LogoLoader size="1.25rem" />
          {:else}
            <Icon icon="iconoir:refresh-double" />
          {/if}
          Refresh Report
        </button>
      </div>
    </div>
  </div>
</header>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="refresh_confirm_modal"
  message="Warning!"
  text="This spelling report generates a large number of results. Refreshing may take some time. Do you want to refresh now?"
  onConfirm={() => {
    confirmRefresh = true;
    refreshRules();
    refresh_confirm_modal.close();
  }}
  onCancel={() => refresh_confirm_modal.close()}
  confirmText="Refresh now"
  cancelText="Back"
/>

{#if loading.state}
  <TableLoading tableRootPage="spelling" />
{:else}
  {#key reportData.tableData}
    <Table
      bind:dateToSortByString
      bind:currentPage
      bind:loading={loading.state}
      bind:openedTab
      on:refresh={refreshTable}
      on:close={(e) => {
        if (e.detail) fetchDataAndUpdateDisplayWrapper();
      }}
      data={reportData.tableData}
      tableType="NONE"
      tableRootPage="spelling"
      tableId={report_id}
      disableEditing={reportDetails.status === "Archived" ||
        $canEditCurrentStream === false}
    />
  {/key}
{/if}

<!-- modals below -->
<ReportHistory reportId={report_id} />

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="deletionConfirmationModal"
  message="Are you sure you want to delete this report?"
  onConfirm={async () => {
    deletionInProgress.state = true;
    await deleteStream(report_id, true);
    deletionInProgress.state = false;
    deletionConfirmationModal.close();
  }}
  onCancel={() => deletionConfirmationModal.close()}
  confirmText="Delete"
  cancelText="Cancel"
  showLoadingSpinner={true}
  loading={deletionInProgress.state}
/>
