<script>
  export let size = "1.5rem";
  export { classes as class };

  let classes = "";

  let data = [
    { x: "14", y: "4", width: 16, height: 8, rx: 1.31 },
    { x: "34", y: "4", width: 16, height: 8, rx: 1.31 },
    { x: "4", y: "16", width: 16, height: 8, rx: 1.31 },
    { x: "24", y: "16", width: 16, height: 8, rx: 1.31 },
    { x: "44", y: "16", width: 16, height: 8, rx: 1.31 },
    { x: "14", y: "28.04", width: 16, height: 8, rx: 1.31 },
    { x: "34", y: "28.04", width: 16, height: 8, rx: 1.31 },
    { x: "4", y: "40.04", width: 16, height: 8, rx: 1.31 },
    { x: "24", y: "40.04", width: 16, height: 8, rx: 1.31 },
    { x: "44", y: "40.04", width: 16, height: 8, rx: 1.31 },
    { x: "14", y: "52.04", width: 16, height: 8, rx: 1.31 },
    { x: "34", y: "52.04", width: 16, height: 8, rx: 1.31 },
  ];

  // growing and shrinking animation on logo

  // onMount(() => {
  //   setInterval(() => {
  //     data = data.map((i) => {
  //       return {
  //         ...i,
  //         width: i.width === 16 ? 8 : 16,
  //         height: i.height === 8 ? 4 : 8,
  //         rx: i.rx === 1.31 ? 2.62 : 1.31,
  //       };
  //     });
  //   }, 700);
  //   // 11*50+150 = 700
  // });
</script>

<div class="relative aspect-square {classes}" style:width={size}>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="4 4 56 56"
    fill="#0074d9"
    width="50%"
    height="50%"
    class="absolute inset-[25%]"
  >
    {#each data as { x, y, width, height, rx }}
      <rect {x} {y} {width} {height} {rx} />
      <!-- if we want growing and shrinking animation on logo -->
      <!-- class="transition-all"
          style:transition-delay={i * 50 + "ms"} -->
    {/each}
  </svg>
  <span class="loading text-primary absolute inset-0 w-max"></span>
</div>
