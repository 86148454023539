<script>
  import { createEventDispatcher } from "svelte";

  export let type; // 'Submit', 'Send', 'fullyApprove', 'ApproveToNextStage'
  export let assetStatus = "Processing";
  export let currentAssetApprovingPermission;
  export let versions;
  export let activeVersion;
  export let disabled = false;

  const dispatch = createEventDispatcher();

  const getButtonText = () => {
    switch (type) {
      case "Submit":
        return "Submit for Approval";
      case "Send":
        return "Send for Approval";
      case "fullyApprove":
        return "Approve";
      case "ApproveToNextStage":
        return "Approve";
      default:
        return "Approve";
    }
  };
  const getTooltip = () => {
    switch (type) {
      case "Submit":
        return "Submit for Approval";
      case "Send":
        return "Send for Approval";
      case "fullyApprove":
        return "Fully Approve";
      case "ApproveToNextStage":
        return "Send to the next stage for approval";
      default:
        return "Approve";
    }
  };

  const getDisabledTooltip = () => {
    if (!currentAssetApprovingPermission)
      return "You don't have approving permissions.";
    if (versions[0].version !== activeVersion.version)
      return "This asset is not on the latest version.";
    if (assetStatus === "Processing") return "This asset is still processing.";
    return null;
  };

  const isDisabled =
    !currentAssetApprovingPermission ||
    versions[0].version !== activeVersion.version ||
    assetStatus === "Processing" ||
    disabled;

  const approve = async () => {
    switch (type) {
      case "Submit":
        document.querySelector(`#approval-dialog-submit`)?.showModal();
        break;
      case "Send":
        document.querySelector(`#approval-dialog-send`)?.showModal();
        break;
      case "fullyApprove":
        dispatch("fullyApprove");
        break;
      case "ApproveToNextStage":
        document.querySelector(`#approval-dialog-approve`)?.showModal();
        break;
    }
  };
</script>

{#if !isDisabled}
  <button
    class="btn btn-success btn-sm tooltip"
    on:click={approve}
    data-tip={getTooltip()}
  >
    {getButtonText()}
  </button>
{:else}
  <div class="tooltip" data-tip={getDisabledTooltip()}>
    <button {disabled} class="btn btn-disabled btn-success btn-sm">
      {getButtonText()}
    </button>
  </div>
{/if}
