<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  export let reports = [];
  export let allRules = [];
  let rules = {};

  function updateRules() {
    for (let i = 0; i < allRules.length; i++) {
      const rule = allRules[i];
      const ruleName = rule["name"];
      const ruleId = rule["id"];

      if (ruleId in rules) {
        continue;
      }

      rules[ruleId] = {
        id: ruleId,
        displayName: ruleName + " (#" + ruleId + ")",
        selected: true,
      };
    }

    const output = Object.keys(rules)
      .filter((key) => rules[key] && rules[key].selected)
      .reduce((obj, key) => {
        obj[key] = rules[key];
        return obj;
      }, {});

    dispatch("updateRules", output);
  }

  $: if (reports.length > 0 || Object.keys(rules).length > 0) {
    updateRules();
  }
</script>

<div>
  <h3 class="text-lg font-semibold">Rules</h3>

  <div class="grid grid-cols-1 gap-x-2 sm:grid-cols-2">
    {#each Object.keys(rules) as rule}
      <label class="label col-span-1 cursor-pointer justify-start gap-2">
        <input
          class="checkbox checkbox-sm"
          type="checkbox"
          name={rules[rule]["displayName"]}
          id={rules[rule]["id"]}
          bind:checked={rules[rule]["selected"]}
        />

        <span class="label-text">{rules[rule]["displayName"]}</span>
      </label>
    {/each}
  </div>
</div>
