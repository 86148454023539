<script>
  import Icon from "@iconify/svelte";
  import { tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchGet, fetchPatch } from "../../../helpers";
  import { HitColour } from "../../../lib/interfaces/Hit.interface";
  import { RuleStatus } from "../../../lib/interfaces/Rule.interface";
  import { actions } from "../../../stores";
  import ChecklistInfoModal from "../../ChecklistInfoModal.svelte";
  import Comments from "../../comments/Comments.svelte";
  import LogoLoader from "../../LogoLoader.svelte";
  import AssetRuleInfoDialog from "../AssetRuleInfoDialog.svelte";
  import AssignUsers from "../AssignUsers.svelte";
  import ConditionalRuleChildren from "../ConditionalRules/ConditionalRuleChildren.svelte";
  import AssetManualSubTask from "./AssetManualSubTask.svelte";
  import AssetStatusInfo from "../AssetStatusInfo.svelte";

  export let activeVersion;
  export let activeAsset;
  export let groupedManualTasks;
  export let currentStreamPerm;
  export let currentAssetApprovingPermission;
  export let versionUpdated;
  export let reportId;
  export let activeAssetId;
  export let manualTasksLoading = false;
  export let previewSearchText;
  export let assetStatus = {
    status: "",
    assetRuleStatuses: {
      ready: 0,
      processing: 0,
      failed: 0,
      total: 0,
    },
    percentageProgress: 0,
  };

  let ruleComment = "";
  let activeRuleGroup = 0;
  let activeRule = -1;
  let loading = false;
  let groupedHits = new Map();
  let actionTexts = $actions.map(function (elem) {
    return elem.action_text;
  });

  function getActiveAssetRuleIdx(ruleId) {
    return activeAsset.rules.findIndex((r) => r.rule.id === ruleId);
  }

  async function updateColour(ruleId, assetId, colour, isConditional = false) {
    try {
      const updateColourResponse = await fetchPatch(
        `/asset-rule/status/${assetId}/${ruleId}`,
        {
          colour: colour,
          is_conditional_rule: isConditional,
        },
      );

      if (!updateColourResponse.success) {
        throw Error("Unable to update Rule colour.");
      }

      if (isConditional && colour === HitColour.PASSED) {
        activeAsset.asset.status = "Searching";
        activeAsset = activeAsset;
      }
    } catch (e) {
      toast.error(
        `Something went wrong updating the colour of Rule(${ruleId}).`,
      );
    }
  }

  const updateRuleComments = async (assetId, ruleId, ruleGroupIndex) => {
    try {
      const res = await fetchGet(`/asset-rule/comment/${assetId}/${ruleId}`);

      if (!res?.success) {
        throw new Error(`Unable to fetch comments for Rule(${ruleId})`);
      }

      const countResponse = await fetchGet(
        `/asset/${assetId}/rule/${ruleId}/comment?count=true`,
      );

      if (!countResponse.success) {
        throw new Error(
          countResponse.message ??
            `Something went wrong updating the total comment count for Rule(ID: ${ruleId})`,
        );
      }

      groupedManualTasks[ruleGroupIndex].rules = groupedManualTasks[
        ruleGroupIndex
      ].rules.map((r) => {
        if (r.rule.id === ruleId) {
          r.comments = res.data;
          r.comment_count = countResponse.comment_count;
        }
        return r;
      });
    } catch (e) {
      console.error(e);
      toast.error(`Something went wrong updating comments for Rule(${ruleId})`);
    }
  };
  async function handleTaskStatusToggle(
    ruleId,
    isConditional = false,
    ruleGroupIndex,
  ) {
    const activeAssetRuleIdx = getActiveAssetRuleIdx(ruleId);

    try {
      if (activeAsset.rules[activeAssetRuleIdx].colour === HitColour.PASSED) {
        await updateColour(
          ruleId,
          activeAssetId,
          HitColour.REVIEW,
          activeAsset.rules[activeAssetRuleIdx].rule.is_conditional,
        );
        activeAsset.rules[activeAssetRuleIdx].colour = HitColour.REVIEW;
        activeAsset.asset.ruleColour = HitColour.REVIEW;
      } else {
        await updateColour(
          ruleId,
          activeAssetId,
          HitColour.PASSED,
          activeAsset.rules[activeAssetRuleIdx].rule.is_conditional,
        );
        activeAsset.rules[activeAssetRuleIdx].colour = HitColour.REVIEW;
        activeAsset.asset.ruleColour = HitColour.PASSED;
      }

      if (!isConditional) {
        await updateRuleComments(activeAssetId, ruleId, ruleGroupIndex);
      }

      activeAsset.rules[activeAssetRuleIdx] =
        activeAsset.rules[activeAssetRuleIdx];
    } catch (e) {
      toast.error(
        `Something went wrong updating the status of Task(${activeAsset.rules[activeAssetRuleIdx].rule.name})`,
      );
    }

    activeAsset = activeAsset;
  }

  async function refreshRule(
    event,
    ruleId,
    isConditional = false,
    ruleGroupIndex,
  ) {
    const activeAssetRuleIdx = getActiveAssetRuleIdx(ruleId);

    try {
      if (activeAsset.rules[activeAssetRuleIdx].colour !== event.detail) {
        await updateColour(
          ruleId,
          activeAssetId,
          event.detail,
          activeAsset.rules[activeAssetRuleIdx].rule.is_conditional,
        );
        activeAsset.rules[activeAssetRuleIdx].colour = event.detail;
        activeAsset.asset.ruleColour = event.detail;

        if (!isConditional) {
          await updateRuleComments(activeAssetId, ruleId, ruleGroupIndex);
        }

        activeAsset.rules[activeAssetRuleIdx] =
          activeAsset.rules[activeAssetRuleIdx];
      }
    } catch (e) {
      toast.error(
        `Something went wrong updating the status of Task(${activeAsset.rules[activeAssetRuleIdx].rule.name})`,
      );
    }

    activeAsset = activeAsset;
  }
</script>

<div
  class="relative {activeAsset?.asset?.status !== 'Ready'
    ? 'overflow-hidden'
    : 'overflow-auto'} rounded border"
  style="height: calc(100vh - 350px);"
>
  {#if !manualTasksLoading}
    {#each groupedManualTasks as ruleGroup, ruleGroupIndex}
      <div class="flex flex-col border-b" in:slide>
        <button
          class="bg-base-200 flex w-full cursor-pointer flex-col gap-2 px-4 py-2 text-start"
          on:click|stopPropagation={() =>
            (activeRuleGroup =
              activeRuleGroup === ruleGroupIndex ? -1 : ruleGroupIndex)}
        >
          <div class="flex w-full items-center justify-between">
            <span class="flex h-fit flex-wrap items-center gap-2 font-semibold">
              {ruleGroup.setName}

              <ChecklistInfoModal
                data={{
                  name: ruleGroup.setName,
                  id: ruleGroup.setId,
                  description: ruleGroup.setDescription,
                }}
              />
            </span>
            <div class="flex items-center gap-4">
              <div
                class="tooltip tooltip-bottom before:-left-16"
                data-tip={`Incomplete top-level rules are set as '${RuleStatus.REVIEW}' and shown as amber. When completed they are set to '${RuleStatus.PASSED}' and shown as green.`}
              >
                <div class="flex items-center gap-2">
                  <div
                    class="flex h-6 w-6 items-center justify-center rounded-full bg-[#fbe5b8] text-sm text-[#FF8D00]"
                  >
                    {ruleGroup.rules.filter((r) => {
                      if (r.colour == "amber") return true;
                    }).length}
                  </div>
                  <div
                    class="flex h-6 w-6 items-center justify-center rounded-full bg-[#c4d4bd] text-sm text-[#336A1D]"
                  >
                    {ruleGroup.rules.filter((r) => {
                      if (r.colour == "green") return true;
                    }).length}
                  </div>
                </div>
              </div>
              <button class="btn btn-square btn-ghost btn-sm">
                <Icon
                  icon="iconoir:nav-arrow-down"
                  class="text-lg transition-transform
                  {activeRuleGroup === ruleGroupIndex ? 'rotate-180' : ''}"
                />
              </button>
            </div>
          </div>
        </button>
        {#if activeRuleGroup === ruleGroupIndex}
          {#each ruleGroup.rules as rule, ruleIndex}
            <div
              class="flex w-full flex-col gap-3 py-2 pl-8 pr-4"
              class:border-t={ruleIndex !== 0}
              in:slide
            >
              <button
                class="flex cursor-pointer items-center justify-between gap-4 text-start"
                on:click|stopPropagation={() => {
                  activeRule = activeRule === ruleIndex ? -1 : ruleIndex;
                  ruleComment = "";
                }}
              >
                <div
                  class={currentStreamPerm !== "edit" ||
                  !rule.results.every((h) => {
                    return h.colour === HitColour.PASSED;
                  })
                    ? "tooltip tooltip-right flex content-center"
                    : "flex content-center"}
                  data-tip={currentStreamPerm !== "edit"
                    ? "You do not have permission to modify this task's status"
                    : "All sub-tasks must be completed prior to completing the top-level task"}
                >
                  <input
                    type="checkbox"
                    class="checkbox checkbox-sm border-gray-300 [--chkbg:#c4d4bd] [--chkfg:#336A1D] checked:border-[#336A1D]"
                    checked={rule.colour === HitColour.PASSED ? true : false}
                    name={rule.rule.name}
                    id={`${ruleGroupIndex}-${ruleIndex}-${rule.rule.id}`}
                    disabled={currentStreamPerm !== "edit" ||
                      !rule.results
                        .filter((r) => r.version === activeVersion?.version)
                        .every((h) => {
                          return h.colour === HitColour.PASSED;
                        })}
                    on:click|stopPropagation={async () => {
                      await handleTaskStatusToggle(
                        rule.rule.id,
                        false,
                        ruleGroupIndex,
                      );
                    }}
                  />
                </div>
                <div
                  class="flex w-64 flex-row items-center justify-between gap-2"
                >
                  <span
                    class="flex flex-wrap align-text-bottom text-base leading-6"
                  >
                    {rule.rule.name}
                  </span>
                  <button
                    class="btn btn-circle btn-ghost btn-xs"
                    on:click|stopPropagation={() =>
                      document
                        .getElementById("rule_info_modal_" + rule.rule.id)
                        .showModal()}
                  >
                    <Icon icon="iconoir:info-circle" class="text-lg" />
                    <!--Note: using material symbols here not iconify, as the iconify info-outline is not rendering properly-->
                  </button>
                </div>

                <div class="flex w-96 items-center justify-end gap-2">
                  <button class="btn btn-ghost btn-xs">
                    <Icon icon="iconoir:check" class="text-base" />
                    {rule.results.reduce(
                      (total, r) =>
                        total +
                        (r.colour === HitColour.PASSED &&
                          r.version === activeVersion?.version),
                      0,
                    )}/{rule.results.filter(
                      (r) => r.version === activeVersion?.version,
                    ).length}
                  </button>
                  <button
                    class="btn btn-ghost btn-xs"
                    on:click|stopPropagation={async () => {
                      activeRule = ruleIndex;
                      ruleComment = "";
                      await tick();

                      const ctxtarea = document.getElementById("ruleComment");
                      if (ctxtarea) {
                        ctxtarea.scrollIntoView({ behavior: "smooth" });
                        ctxtarea.focus();
                      } else console.log("Comment textarea not found");
                    }}
                  >
                    <Icon icon="iconoir:chat-lines" class="text-base" />
                    {rule?.comment_count ?? 0}
                  </button>

                  <AssignUsers
                    rule_id={rule.rule.id}
                    asset_id={activeAssetId}
                    assignees={rule.assigned_users}
                    disableEditing={currentStreamPerm !== "edit"}
                    isCompact
                    on:change={async (e) => {
                      rule.assigned_users = e.detail;
                      rule = rule;

                      await updateRuleComments(
                        activeAssetId,
                        rule.rule.id,
                        ruleGroupIndex,
                      );
                    }}
                  />

                  <button class="btn btn-square btn-ghost btn-sm">
                    <Icon
                      icon="iconoir:nav-arrow-down"
                      class="text-lg transition-transform
                      {activeRule === ruleIndex ? 'rotate-180' : ''}"
                    />
                  </button>
                </div>
              </button>
              {#if activeRule === ruleIndex}
                <div class="flex flex-col gap-2">
                  <div
                    class="border-l-asset-hit-border flex flex-col gap-2 border-l-2 pl-1"
                  >
                    {#each rule.results.filter((x) => x.version === activeVersion?.version) as hit}
                      <AssetManualSubTask
                        bind:hit
                        bind:activeAsset={activeAsset.asset}
                        {reportId}
                        {actionTexts}
                        {currentStreamPerm}
                        bind:asset_rule={rule}
                        on:refresh={async (e) => {
                          await refreshRule(
                            e,
                            rule.rule.id,
                            false,
                            ruleGroupIndex,
                          );
                        }}
                        on:updateRuleCommentCount={async () => {
                          try {
                            const commentCountResponse = await fetchGet(
                              `/asset/${activeAssetId}/rule/${rule.rule.id}/comment?count=true`,
                            );

                            if (!commentCountResponse.success) {
                              throw new Error(
                                commentCountResponse.message ??
                                  `Something went wrong updating the total comment count for Rule(ID: ${rule.rule.id})`,
                              );
                            }

                            rule.comment_count =
                              commentCountResponse.comment_count;
                            rule = rule;
                          } catch (e) {
                            console.error(e);
                            toast.error(
                              `Something went wrong updating the total comment count for Rule(ID: ${rule.rule.id})`,
                            );
                          }
                        }}
                      />
                    {:else}
                      <p>No results found</p>
                    {/each}
                  </div>

                  {#if rule.rule.is_conditional}
                    <div
                      class="flex flex-col gap-2 border-l {rule.colour ===
                      HitColour.PASSED
                        ? 'border-success'
                        : 'border-error'}"
                    >
                      <ConditionalRuleChildren
                        bind:activeAssetId
                        bind:activeAsset
                        bind:activeVersion
                        bind:previewSearchText
                        bind:reportId
                        bind:currentStreamPerm
                        bind:currentAssetApprovingPermission
                        bind:actionTexts
                        bind:rules={rule.rule.children}
                        bind:versionUpdated
                        bind:parentRuleColour={rule.colour}
                        parentRuleId={rule.rule.id}
                        on:updateRuleCommentCount={async () => {
                          try {
                            const commentCountResponse = await fetchGet(
                              `/asset/${activeAssetId}/rule/${rule.rule.id}/comment?count=true`,
                            );

                            if (!commentCountResponse.success) {
                              throw new Error(
                                commentCountResponse.message ??
                                  `Something went wrong updating the total comment count for Rule(ID: ${rule.rule.id})`,
                              );
                            }

                            rule.comment_count =
                              commentCountResponse.comment_count;
                            rule = rule;
                          } catch (e) {
                            console.error(e);
                            toast.error(
                              `Something went wrong updating the total comment count for Rule(ID: ${rule.rule.id})`,
                            );
                          }
                        }}
                      />
                    </div>
                  {/if}
                </div>
                <Comments
                  commentInputId="ruleComment"
                  comments={rule.comments}
                  apiEndpoint={`/asset-rule/comment/${activeAssetId}/${rule.rule.id}`}
                  type="asset"
                  on:updateComments={() =>
                    updateRuleComments(
                      activeAssetId,
                      rule.rule.id,
                      ruleGroupIndex,
                    )}
                />
              {/if}
            </div>
            <AssetRuleInfoDialog rule={rule.rule} />
          {/each}
        {/if}
      </div>
    {:else}
      <div
        class="flex flex-col justify-center items-center p-4 text-base-content/50 h-full"
      >
        <Icon icon="mdi:tick-box-multiple-outline" class="text-8xl" />
        <p class="text-sm text-center">No manual tasks yet!</p>
      </div>
    {/each}

    <AssetStatusInfo status={activeAsset?.asset?.status} {assetStatus} />
  {:else}
    <div
      class="absolute inset-0 flex items-center justify-center text-xl backdrop-blur"
    >
      <LogoLoader size="3rem" />
    </div>
  {/if}
</div>
