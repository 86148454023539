<script>
  import { createEventDispatcher } from "svelte";
  // import { textareaAutosizeAction } from "svelte-legos";

  const dispatch = createEventDispatcher();

  let input = "";

  let showSuggestions = true;

  function handleSubmit() {
    const text = (input || "").trim();
    input = "";
    if (text) dispatch("submit", { text });
  }
</script>

<form
  on:submit|preventDefault={handleSubmit}
  class="sticky bottom-0 z-10 flex flex-col gap-2 border-t bg-base-100 p-4"
>
  {#if showSuggestions}
    <div class="flex flex-wrap gap-x-2 gap-y-1">
      {#each ['Find me all instances of the use of the word "free" and synonyms', "Build me a rule looking for greenwashing and ESG related words"] as e}
        <button
          class="badge badge-ghost inline truncate"
          on:click={() => {
            input = e;
            handleSubmit();
            showSuggestions = false;
          }}
        >
          {e}
        </button>
      {/each}
    </div>
  {/if}

  <textarea
    class="textarea textarea-bordered max-h-[78px] w-full"
    placeholder="Ask me a question"
    bind:value={input}
    on:keypress={(e) => {
      if (e.key === "Enter") handleSubmit();
    }}
  />
  <!-- use:textareaAutosizeAction -->

  <button
    class="btn btn-primary btn-sm ml-auto"
    disabled={!input}
    type="submit"
  >
    Send
  </button>
</form>
