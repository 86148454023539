<script>
  import { slide } from "svelte/transition";
  import Comments from "../comments/Comments.svelte";

  export let activeAssetId;
  export let activeAsset;

  function handleUpdateComments(event) {
    activeAsset.asset_comments = event.detail;
  }
</script>

<div class="flex flex-col gap-2 py-2" in:slide>
  <Comments
    comments={activeAsset.asset_comments}
    apiEndpoint={`/asset/comment/${activeAssetId}`}
    on:updateComments={handleUpdateComments}
    type={"asset"}
    subType={"assetStatus"}
  />
</div>
