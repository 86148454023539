<script>
  import PSPDFKit from "pspdfkit";
  import { afterUpdate, onDestroy, onMount } from "svelte";
  import { writable } from "svelte/store";

  let originalContainer;
  let changedContainer;
  let operationsMap = writable(new Map());

  export let originalDoc =
    "https://public-solutions-engineering-bucket.s3.eu-central-1.amazonaws.com/docs/text-comparison-a.pdf";
  export let changedDoc =
    "https://public-solutions-engineering-bucket.s3.eu-central-1.amazonaws.com/docs/text-comparison-b.pdf";
  const numberOfContextWords = 100;
  const deleteHighlightColor = { r: 255, g: 201, b: 203 }; // #FFC9CB
  const insertHighlightColor = { r: 192, g: 216, b: 239 }; // #C0D8EF

  let originalInstance;
  let changedInstance;
  let operationsRef = new Map();

  const licenseKey = import.meta.env.VITE_APP_PSPDFKIT_LICENSE;

  const pspdfkitConfig = {
    baseUrl: `${window.location.protocol}//${window.location.host}/`,
    licenseKey: licenseKey,
    toolbarItems: [{ type: "sidebar-document-outline" }, { type: "search" }],
  };

  async function compareDocuments() {
    const originalContainerEl = originalContainer;
    const changedContainerEl = changedContainer;

    if (originalContainerEl) PSPDFKit.unload(originalContainerEl);
    if (changedContainerEl) PSPDFKit.unload(changedContainerEl);

    // Ensure both documents load properly
    try {
      [originalInstance, changedInstance] = await Promise.all([
        PSPDFKit.load({
          ...pspdfkitConfig,
          container: originalContainerEl,
          document: originalDoc,
        }),
        PSPDFKit.load({
          ...pspdfkitConfig,
          container: changedContainerEl,
          document: changedDoc,
        }),
      ]);
    } catch (error) {
      console.error("Error loading PSPDFKit instances:", error);
      return;
    }

    const scrollElement =
      changedInstance.contentDocument.querySelector(".PSPDFKit-Scroll");
    scrollElement.addEventListener("scroll", syncViewState);
    changedInstance.addEventListener(
      "viewState.currentPageIndex.change",
      syncViewState,
    );
    changedInstance.addEventListener("viewState.zoom.change", syncViewState);

    function syncViewState() {
      const customViewState = {
        pageNumber: changedInstance.viewState.currentPageIndex,
        zoomLevel: changedInstance.viewState.zoom,
        scrollLeft:
          changedInstance.contentDocument.querySelector(".PSPDFKit-Scroll")
            .scrollLeft,
        scrollTop:
          changedInstance.contentDocument.querySelector(".PSPDFKit-Scroll")
            .scrollTop,
      };

      const viewState = originalInstance.viewState;
      originalInstance.setViewState(
        viewState.set("currentPageIndex", customViewState.pageNumber),
      );
      originalInstance.setViewState(
        viewState.set("zoom", customViewState.zoomLevel),
      );

      const scrollElement =
        originalInstance.contentDocument.querySelector(".PSPDFKit-Scroll");
      scrollElement.scrollLeft = customViewState.scrollLeft;
      scrollElement.scrollTop = customViewState.scrollTop;
    }

    const totalPageCount = await originalInstance.totalPageCount;

    for (let i = 0; i < totalPageCount; i++) {
      const originalDocument = new PSPDFKit.DocumentDescriptor({
        filePath: originalDoc,
        pageIndexes: [i],
      });

      const changedDocument = new PSPDFKit.DocumentDescriptor({
        filePath: changedDoc,
        pageIndexes: [i],
      });

      const textComparisonOperation = new PSPDFKit.ComparisonOperation(
        PSPDFKit.ComparisonOperationType.TEXT,
        {
          numberOfContextWords,
        },
      );

      const comparisonResult = await originalInstance.compareDocuments(
        { originalDocument, changedDocument },
        textComparisonOperation,
      );

      let originalInstanceRects = PSPDFKit.Immutable.List([]);
      let changedInstanceRects = PSPDFKit.Immutable.List([]);

      const originalPageInfo = await originalInstance.pageInfoForIndex(i);
      const changedPageInfo = await changedInstance.pageInfoForIndex(i);

      let changes = new Map();
      for (const comp of comparisonResult) {
        for (const docComp of comp.documentComparisonResults) {
          for (const comparison of docComp.comparisonResults) {
            for (const hunks of comparison.hunks) {
              for (const operation of hunks.operations) {
                switch (operation.type) {
                  case "equal":
                    break;
                  case "delete":
                    originalInstanceRects = originalInstanceRects.push(
                      new PSPDFKit.Geometry.Rect({
                        left: operation.originalTextBlocks[0].rect[0],
                        top:
                          originalPageInfo.height -
                          operation.originalTextBlocks[0].rect[1] -
                          operation.originalTextBlocks[0].rect[3],
                        width: operation.originalTextBlocks[0].rect[2],
                        height: operation.originalTextBlocks[0].rect[3],
                      }),
                    );

                    let delCoord = `${operation.changedTextBlocks[0].rect[0]},${operation.changedTextBlocks[0].rect[1]}`;
                    if (changes.has(delCoord)) {
                      changes.set(delCoord, {
                        insertText: changes.get(delCoord).insertText,
                        insert: changes.get(delCoord).insert,
                        deleteText: operation.text,
                        del: true,
                      });
                    } else {
                      changes.set(delCoord, {
                        deleteText: operation.text,
                        del: true,
                      });
                    }
                    break;

                  case "insert":
                    changedInstanceRects = changedInstanceRects.push(
                      new PSPDFKit.Geometry.Rect({
                        left: operation.changedTextBlocks[0].rect[0],
                        top:
                          changedPageInfo.height -
                          operation.changedTextBlocks[0].rect[1] -
                          operation.changedTextBlocks[0].rect[3],
                        width: operation.changedTextBlocks[0].rect[2],
                        height: operation.changedTextBlocks[0].rect[3],
                      }),
                    );

                    let insertCoord = `${operation.changedTextBlocks[0].rect[0]},${operation.changedTextBlocks[0].rect[1]}`;
                    if (changes.has(insertCoord)) {
                      changes.set(insertCoord, {
                        deleteText: changes.get(insertCoord).deleteText,
                        del: changes.get(insertCoord).del,
                        insertText: operation.text,
                        insert: true,
                      });
                    } else {
                      changes.set(insertCoord, {
                        insertText: operation.text,
                        insert: true,
                      });
                    }
                    break;
                  default:
                    break;
                }
              }
            }
          }
        }
      }

      // Only create annotations if rects are found
      if (originalInstanceRects.size > 0) {
        const originalAnnotations =
          new PSPDFKit.Annotations.HighlightAnnotation({
            pageIndex: i,
            rects: originalInstanceRects,
            color: new PSPDFKit.Color(deleteHighlightColor),
          });
        await originalInstance.create(originalAnnotations);
      }

      if (changedInstanceRects.size > 0) {
        const changedAnnotations = new PSPDFKit.Annotations.HighlightAnnotation(
          {
            pageIndex: i,
            rects: changedInstanceRects,
            color: new PSPDFKit.Color(insertHighlightColor),
          },
        );
        await changedInstance.create(changedAnnotations);
      }

      operationsRef = new Map([...operationsRef, ...changes]);
    }

    operationsMap.set(operationsRef);
    console.log($operationsMap);
  }

  const unload = () => {
    if (originalInstance) PSPDFKit.unload(originalInstance);
    if (changedInstance) PSPDFKit.unload(changedInstance);
  };

  onMount(compareDocuments);

  afterUpdate(() => {
    // if (originalDoc !== , basically if props are updated but we just used {#key in the parent to re-render the component}
    //   unload();
    //   compareDocuments();
  });

  onDestroy(unload);
</script>

<div class="flex gap-2">
  <div
    bind:this={originalContainer}
    id="original-document-viewer"
    class="bg-base-200 mt-2 h-[calc(100vh-185px)] w-full rounded border p-2"
  />
  <div
    bind:this={changedContainer}
    id="changed-document-viewer"
    class="bg-base-200 mt-2 h-[calc(100vh-185px)] w-full rounded border p-2"
  />

  <div class="max-h-[calc(100vh-185px)] w-96 overflow-auto">
    {#each Array.from($operationsMap) as [key, value]}
      <div
        {key}
        class="border-1 mx-auto mb-2 w-11/12 rounded border border-gray-400 p-1"
      >
        <div class="flex justify-between p-1 pl-0">
          <div class="text-xs text-gray-400">
            {value.insert && value.del
              ? "replaced"
              : value.insert
                ? "inserted"
                : "deleted"}
          </div>

          <div class="text-xs">
            {#if value.insert && value.del}
              <span class="bg-[#FFC9CB]">-1</span> |
              <span class="bg-[#C0D8EF]">+1</span>
            {:else if value.insert}
              <span class="bg-[#C0D8EF]">+1</span>
            {:else}
              <span class="bg-[#FFC9CB]">-1</span>
            {/if}
          </div>
        </div>

        <div>
          <p class="text-xs">
            <span class="bg-[#FFC9CB]">{value.deleteText ?? ""}</span>
          </p>
          <p class="text-xs">
            <span class="bg-[#C0D8EF]">{value.insertText ?? ""}</span>
          </p>
        </div>
      </div>
    {/each}
  </div>
</div>
