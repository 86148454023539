<script>
  import LabelSettings from "../components/Settings/LabelSettings.svelte";
  import { toast } from "svelte-sonner";
  import { fetchGet } from "../../src/helpers.js"
  import Modal from "../components/Modals/Modal.svelte";
  import Actions from "../components/Settings/Actions.svelte";
  import AddActionModal from "../components/Settings/AddActionModal.svelte";
  import AddLabelModal from "../components/Settings/AddLabelModal.svelte";
  import AddTeamModal from "../components/Settings/AddTeamModal.svelte";
  import CreateEmailTemplateModal from "../components/Settings/CreateEmailTemplateModal.svelte";
  import Emails from "../components/Settings/Emails.svelte";
  import InviteUserModal from "../components/Settings/InviteUserModal.svelte";
  import OrganisationUsers from "../components/Settings/OrganisationUsers.svelte";
  import SettingArticle from "../components/Settings/SettingArticle.svelte";
  import TeamSettings from "../components/Settings/TeamSettings.svelte";
  import WorkflowDefaultStagesSettings from "../components/Settings/WorkflowDefaultStagesSettings.svelte";
  import {
    UserAccountStatus,
    UserRole,
  } from "../lib/interfaces/User.interface";
  import { capitalise } from "../lib/utils/GenericUtils";
  import { currentUser,org_users, org_teams } from "../stores";
  import DueDaysSettings from "./DueDaysSettings.svelte";
  import { onMount } from "svelte";


  let showRemovedUsers = false;
  let activeSection = "orgsettings";

  function setSection(section) {
    activeSection = section;
  }
  // Single place to refresh store data
  onMount(async () => {
    try {
      const [usersRes, teamsRes] = await Promise.all([
        fetchGet("/organisation"),
        fetchGet("/team")
      ]);
      
      if (usersRes.success) {
        org_users.set(usersRes.users);
      }
      
      if (teamsRes.success) {
        org_teams.set(teamsRes.teams);
      }
    } catch (error) {
      toast.error("Failed to refresh organization data");
      console.error(error);
    }
  });
</script>

<svelte:head>
  <title>Org Settings - Haast</title>
</svelte:head>

<SettingArticle>
  <span slot="header">
    <h1 class="text-xl">User Management</h1>
    <p class="text-base-content/70 text-sm">
      Manage organization members all in one place.
    </p>
  </span>
  <span slot="beside">
    <InviteUserModal />
    {#if $currentUser.role === UserRole.ADMIN}
      <button
        class="btn {showRemovedUsers
          ? 'btn-ghost'
          : 'btn-primary'} btn-sm ml-auto"
        on:click={() => {
          showRemovedUsers = !showRemovedUsers;
        }}
      >
        Show {capitalise(
          showRemovedUsers
            ? UserAccountStatus.ACTIVE
            : UserAccountStatus.REMOVED,
        )} Users
      </button>
    {/if}
  </span>
  <OrganisationUsers bind:showRemovedUsers />
</SettingArticle>

<SettingArticle>
  <span slot="header">
    <h1 class="text-xl">Teams</h1>
    <p class="text-base-content/70 text-sm">
      Create teams to organise your organisation's users.
    </p>
  </span>
  <span slot="beside">
    <AddTeamModal />
  </span>
  <TeamSettings />
</SettingArticle>

<SettingArticle>
  <span slot="header">
    <h1 class="text-xl">Labels</h1>
    <p class="text-base-content/70 text-sm">
      Create labels to sort your organisation's streams.
    </p>
  </span>
  <span slot="beside"><AddLabelModal /></span>
  <LabelSettings />
</SettingArticle>

<SettingArticle>
  <span slot="header">
    <h1 class="text-xl">Email Templates</h1>
    <p class="text-base-content/70 text-sm">
      Create email templates for 'Action Required' emails sent by your
      organisation.
    </p>
    <p class="text-base-content/70 text-sm">
      <!-- svelte-ignore missing-declaration -->
      See
      <button
        class="link-hover link link-primary"
        on:click={() => email_guide.showModal()}
      >
        our email templating guide
      </button>
      for more details about how each of the settings below are applied to the email
      template.
    </p>
  </span>
  <span slot="beside">
    <CreateEmailTemplateModal />
  </span>
  <Emails />
</SettingArticle>

<SettingArticle>
  <span slot="header">
    <h1 class="text-xl">Saved Actions</h1>
  </span>
  <span slot="beside">
    <AddActionModal />
  </span>
  <Actions />
</SettingArticle>

<SettingArticle>
  <span slot="header">
    <h1 class="text-xl">Due Days</h1>
    <p class="text-base-content/70 text-sm">
      Determine after how many days should be considered past due for an open
      Hit
    </p>
  </span>
  <DueDaysSettings />
</SettingArticle>

<SettingArticle>
  <span slot="header">
    <h1 class="text-xl">Uploaded and Approved Stage</h1>
    <p class="text-base-content/70 text-sm">
      You may enable or disable these stages in the asset review workflow.
    </p>
  </span>
  <WorkflowDefaultStagesSettings />
</SettingArticle>

<Modal modalId="email_guide" size="md">
  <h3 class="text-lg font-bold">Email Templating Guide</h3>
  <p class="text-base-content/70 text-sm">
    See what content each setting changes below.
  </p>
  <img src="/email-guide.png" alt="Email Templating Guide" />
</Modal>
