<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import { createEventDispatcher } from "svelte";

  export let data = [];
  export let hitId = -1;

  export let disableEditing = false;

  const originalData = data;
  const dispatch = createEventDispatcher();

  let isSelected = false;

  function toggleEntrySelected() {
    dispatch("entrySelectedToggled");
    data = data.map((entry) => {
      if (entry["hit"]["id"] === hitId) {
        if (entry["selected"]) {
          entry["selected"] = false;
        } else {
          entry["selected"] = true;
        }
      }

      return entry;
    });
  }

  function getEntrySelected() {
    let selected = false;

    data.forEach((entry) => {
      if (entry["hit"]["id"] === hitId) {
        if (entry["selected"]) {
          selected = true;
        }
      }
    });

    return selected;
  }

  $: if (data) {
    isSelected = getEntrySelected();
  }
</script>

<input
  type="checkbox"
  class="checkbox-primary checkbox checkbox-sm"
  id={hitId}
  on:click={toggleEntrySelected}
  bind:checked={isSelected}
  disabled={disableEditing}
/>
