<script>
  import { onMount } from "svelte";
  import { link } from "svelte-spa-router";
  import { dTypesToMultiSelect, fetchGet } from "../../helpers";
  import { currentUser } from "../../stores";
  import DropdownMultiSelect from "../DropdownMultiSelect.svelte";
  import DictionarySelector from "../Spelling/DictionarySelector.svelte";

  export let step;
  export let report_id;

  let dictionaries = [];
  let selectedDictionaries = [];
  let standardDictionaries = [];
  let sharedDictionaries = [];
  let myDictionaries = [];

  let selectedDataTypes = [];
  let items = {};
  let fetchedDataTypes = [];

  function loadDictionaries() {
    //To implement actual API and abstract this out to helpers.js

    dictionaries = [
      {
        id: 1,
        name: "Australian English",
        description: "Standard Australian English Dictionary",
        type: "standard",
        owner: [], //No owner
        collaborators: [], //No collaborators
      },
      {
        id: 2,
        name: "Aboriginal Place Names",
        description: "Common Aboriginal Place Names",
        type: "standard",
        owner: [], //No owner
        collaborators: [], //No collaborators
      },
      {
        id: 3,
        name: "NRMA Terms",
        description: "Specific Terms for NRMA",
        type: "shared",
        owner: [2], //User ID of the owner of this dictionary
        collaborators: [1, 3, 4], //User IDs of any collaborators of this dictionary
      },
      {
        id: 4,
        name: "My Team Dictionary",
        description: "My Custom Terms",
        type: "shared",
        owner: [1], //User ID of the owner of this dictionary
        collaborators: [2, 3, 4], //User IDs of any collaborators of this dictionary
      },
      {
        //This should be autocreated and autoupdated for the report
        id: 142292782, //ID always equals stream ID
        name: "Report Dictionary",
        description: "Custom Dictionary, specific to this report",
        type: "report",
        owner: [], //Set to none
        collaborators: [1, 2, 3, 4], //Any users with edit permissions on the stream
      },
    ];
    //Classify dictionaries

    for (let i = 0; i < dictionaries.length; i++) {
      if (dictionaries[i].type == "standard") {
        standardDictionaries.push(dictionaries[i]);
      } else if (dictionaries[i].owner.includes($currentUser.id)) {
        myDictionaries.push(dictionaries[i]);
      } else if (dictionaries[i].type == "shared") {
        sharedDictionaries.push(dictionaries[i]);
      }
    }
  }

  function getCurrentDictionaries() {
    //To implement actual API.
    selectedDictionaries = [1];
  }

  function getSelectedDataTypes() {
    //To implement actual API.
    selectedDataTypes = ["anu_website_text"];
  }

  onMount(async () => {
    loadDictionaries();
    getCurrentDictionaries();

    // Get channels
    const response = await fetchGet("/pool");
    fetchedDataTypes = response.data;
    items = dTypesToMultiSelect(fetchedDataTypes);

    getSelectedDataTypes();
  });

  // $: if (selectedDataTypes.length > 0) {
  //   console.log(selectedDataTypes);
  // }
</script>

<p class="text-base-content/70 text-sm">
  Select which channels to scan and which dictionaries to apply when looking for
  spelling and grammar issues. You can also <a
    class="link text-primary mt-4"
    target="_blank"
    href="#/spelling/dictionaries/{report_id}"
  >
    Edit Report Dictionary
  </a> to add custom terms for this report only.
</p>

<div class="my-8">
  <h3 class="text-2xl font-semibold">Channels to Scan</h3>
  <div class="flex flex-wrap items-center gap-1">
    <DropdownMultiSelect
      bind:selectedItems={selectedDataTypes}
      badgesInSearchboxDesign={false}
      useTidyPoolLabels={true}
      {items}
      searchBarClasses="input input-sm input-bordered w-full max-w-xs"
      loading={fetchedDataTypes.length === 0}
    />
  </div>
</div>

<DictionarySelector
  dictionaryName={"Standard Dictionaries"}
  dictionaries={standardDictionaries}
  bind:selectedDictionaries
/>

<DictionarySelector
  dictionaryName={"Dictionaries Shared With Me"}
  dictionaries={sharedDictionaries}
  bind:selectedDictionaries
/>

<DictionarySelector
  dictionaryName={"My Dictionaries"}
  dictionaries={myDictionaries}
  bind:selectedDictionaries
/>

<div class="my-4 flex gap-2">
  <button
    class="btn btn-sm"
    on:click={() => {
      if (step === 0) return;
      step -= 1;
    }}
  >
    Back
  </button>

  {#if selectedDictionaries.length === 0 || selectedDataTypes.length === 0}
    <div class="tooltip" data-tip="You need at least one rule to proceed.">
      <button class="btn btn-primary btn-sm" disabled>
        Save & View Report
      </button>
    </div>
  {:else}
    <a href="/spelling/{report_id}" class="btn btn-primary btn-sm" use:link>
      Save & View Report
    </a>
  {/if}
</div>
