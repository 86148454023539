<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../../helpers";
  import { isEmpty } from "../../lib/utils/GenericUtils";
  import { invalidInputFocus } from "../../lib/Focus";
  import { slide } from "svelte/transition";

  export let rule;
  export let isViewing = false;

  let list = [""];
  let ready = false;
  let abandonedInputs = new Map();

  function handleAbandonedFocus(id, isAbandoned) {
    if (isAbandoned) abandonedInputs.set(id, isAbandoned);
    else abandonedInputs.delete(id);

    abandonedInputs = abandonedInputs;
  }

  onMount(() => {
    list = rule.lexical.split(" OR ").map((e) => e.replaceAll('"', ""));
    ready = true;
  });

  function handleKeyDown(event) {
    if (event.key === "Enter") event.preventDefault();
  }

  $: if (ready) rule.lexical = list.map((e) => `"${e}"`).join(" OR ");

  async function stem(word) {
    let response = await fetchPost("/stem", { word: [word] });
    return response.stems;
  }

  async function handleStemClick(idx) {
    const word = list[idx];
    if (word.trim().split(/\s+/).length > 1) {
      toast.error("Stem generation is only available for single words");
      return;
    }
    let stem_word = word.toLowerCase();

    const stems = await stem(stem_word);

    if (stems.length > 1) {
      list = [...list.slice(0, idx), word, ...stems, ...list.slice(idx + 1)];
    } else {
      toast.error("Word stems do not exist for this word");
    }
  }
</script>

{#each list as e, idx}
  <div class="flex items-center gap-2">
    <span class="min-w-8 pl-1 text-sm">{idx + 1}</span>
    <textarea
      placeholder="Phrase to match"
      class="textarea textarea-bordered grow"
      rows="1"
      id="exact-rule-example-{idx}"
      bind:value={e}
      on:keydown={handleKeyDown}
      use:invalidInputFocus={{
        id: `exact-rule-example-${idx}`,
        onChange: handleAbandonedFocus,
        validateEmpty: true,
      }}
    ></textarea>
    <div
      class="tooltip tooltip-left"
      data-tip="Add different variations of the word to broaden your check"
    >
      <button
        class="btn btn-square btn-ghost btn-sm hover:!btn-primary"
        on:click={() => handleStemClick(idx)}
        disabled={isViewing || e.trim().split(/\s+/).length > 1}
      >
        <Icon icon="iconoir:sparks" />
      </button>
    </div>
    <button
      class="!hover:btn-error btn btn-ghost border-base-content/20"
      disabled={isViewing}
      on:click={() => {
        list.splice(idx, 1);
        list = list; // Reassign to trigger rerender
      }}
    >
      <Icon icon="iconoir:trash" />
    </button>
  </div>
  {#if abandonedInputs.get(`exact-rule-example-${idx}`) || false}
    <p class="text-error ml-11 first-line:text-xs" in:slide out:slide>
      *Cannot have an empty phrase.
    </p>
  {/if}
{/each}

<button
  class="btn btn-outline btn-primary btn-sm mr-auto"
  disabled={isViewing}
  on:click={() => (list = [...list, ""])}
>
  <Icon icon="iconoir:plus" />
  Add Phrase
</button>
