/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Asset` entity status values.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * utilising Asset statuses so as to reduce redundancy & inconsistency in the codebase.
 */
export const AssetStatus = Object.freeze({
  PROCESSING: "Processing",
  READY: "Ready",
  SEARCHING: "Searching",
  FAILED: "Upload Failed",
  SEARCH_FAILED: "Searching Error",
});
