<script>
  import { createEventDispatcher } from "svelte";
  import { getInitials, getTextColor, nameToColor } from "../../helpers";

  const dispatch = createEventDispatcher();

  export let associatedUsers;
  export let associatedTeams;
  export let canEditPermissions = true;
  export let disabled;
</script>

<div class="avatar-group -space-x-2 overflow-visible">
  {#each associatedUsers as user}
    {#if !user.is_deleted}
      {@const initials = getInitials(user.first_name + " " + user.last_name)}
      <div
        class="tooltip"
        data-tip="{user.first_name} {user.last_name}
        ({user.permission === 'edit' ? 'editor' : 'viewer'})"
      >
        <div class="avatar placeholder border-2">
          <div
            class="w-8 rounded-full"
            style:background-color={nameToColor(initials)}
            style:color={getTextColor(nameToColor(initials))}
          >
            <span>{initials}</span>
          </div>
        </div>
      </div>
    {/if}
  {/each}

  {#each associatedTeams as team}
    {@const initials = getInitials(team.name)}
    <div
      class="tooltip"
      data-tip="{team.name}
      ({team.permission === 'edit' ? 'editor' : 'viewer'})"
    >
      <div class="avatar placeholder border-2">
        <div
          class="w-8 rounded-full"
          style:background-color={nameToColor(initials)}
          style:color={getTextColor(nameToColor(initials))}
        >
          <span>{initials}</span>
        </div>
      </div>
    </div>
  {/each}
</div>

<div
  class={disabled || !canEditPermissions ? "tooltip" : ""}
  data-tip={disabled
    ? "This report has been archived and cannot be shared"
    : !canEditPermissions
      ? "You do not have permission to share this report"
      : ""}
>
  <button
    class="btn btn-primary btn-sm"
    disabled={disabled || !canEditPermissions}
    on:click={() => dispatch("share")}
  >
    Share
  </button>
</div>
