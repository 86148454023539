<script>
  import Icon from "@iconify/svelte";
  import { toast } from "svelte-sonner";
  import { push } from "svelte-spa-router";
  import { fetchPatch } from "../../helpers";
  import { convertBoolToBuilder } from "../../ruleBuilderConverter";
  import Modal from "../Modals/Modal.svelte";
  import QueryPrettyPrint from "./QueryPrettyPrint.svelte";
  import { isParentConditionalRule } from "../../lib/utils/RuleUtils";
  import { edit, getRuleCopy } from "../new-stream/ApplyChecklists.svelte";
  import { RuleSearchType } from "../../lib/interfaces/Rule.interface";
  import { org_id, org_name } from "../../stores";
  import { getTextConstant } from "../../lib/utils/ConstantsUtils";

  export let rule;
  export let hit;
  export let report_id;

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");

  let example_field = select_highlight(hit); //hit.highlight.length !== 0 ? hit.highlight : hit.text
  //hit.highlight.length > 0 ? hit.highlight.replace(/<\/?em>/g, "") : hit.text;

  function select_highlight(hit) {
    // Check if there is text within <em> tags in hit.highlight
    const emTextMatch = hit.highlight && hit.highlight.match(/<em>(.*?)<\/em>/);
    if (emTextMatch && emTextMatch[1]) {
      return emTextMatch[1]; // Use text within the first <em> tags
    } else if (hit.highlight && hit.highlight.length > 0) {
      return hit.highlight; // Use entire highlight if no <em> text
    } else {
      return hit.text; // Fallback to hit.text if highlight is empty
    }
  }

  function addExample(rule, example) {
    const isContextual = rule.search_type === RuleSearchType.CONTEXTUAL;
    if (rule.search_type == RuleSearchType.TENSOR || isContextual) {
      rule.examples = rule.examples || [];
      rule.examples.push({
        text: example,
        score: rule.affirmative_rule || isContextual ? 1 : -1,
      });
    } else if (rule.search_type == RuleSearchType.LEXICAL) {
      rule.examples = rule.lexical || '"' + rule.examples + '"';
      rule.examples += `" OR "${example}"`;
      rule.lexical = '"' + rule.examples;
    }
  }
</script>

<Modal
  modalId="rule_add_example_{rule.id}_{hit.hit_id}"
  cornerCloseButton={false}
>
  <div class="mb-2 flex items-center">
    <button class="btn btn-circle btn-primary mx-auto">
      <Icon icon="iconoir:folder-plus" class="text-2xl" />
    </button>

    <h2 class="-ml-[48px] flex-grow text-center font-semibold">
      Provide {textConstants["Folder"]} Level Feedback
    </h2>
  </div>

  <span class="text-sm font-medium"> Hit Content </span>
  <div class="bg-base-200 rounded-lg px-2 py-1 text-sm">
    {@html hit.highlight.length !== 0 ? hit.highlight : hit.text}
  </div>

  <span class="text-sm font-medium">
    What example do you want to {rule.affirmative_rule ? "include" : "exclude"}?
  </span>
  <textarea
    class="textarea textarea-bordered max-h-full w-full"
    style="height: auto; min-height:100px"
    placeholder="Example"
    bind:value={example_field}
    on:keypress={(e) => {
      if (e.shiftKey && e.key === "Enter") {
        e.preventDefault();
      }
    }}
  ></textarea>

  <div class="collapse-arrow bg-base-200 collapse rounded border">
    <input type="checkbox" class="peer min-h-[unset]" />
    <div class="collapse-title min-h-[unset] py-2 after:!top-5">
      Rule Information
    </div>

    <div
      class="collapse-content bg-base-100 !mt-0 rounded peer-checked:m-2 peer-checked:p-4"
    >
      <h2 class="max-w-xs truncate text-xl font-semibold">
        {rule.name}
      </h2>

      <p class="text-base-content/80 mt-4 text-sm">
        ID: #{rule.id}
      </p>

      {#if rule.description}
        <div class="mt-4 space-y-2">
          <p class="text-sm font-bold">Description</p>
          <p class="text-base-content/80 text-sm">{rule.description}</p>
        </div>
      {/if}

      {#if rule.remediation_step}
        <div class="mt-4 space-y-2">
          <p class="text-sm font-bold">Remediation Step</p>
          <p class="text-base-content/80 text-sm">
            {rule.remediation_step}
          </p>
        </div>
      {/if}

      <div class="mt-4 space-y-2">
        <p class="text-sm font-bold">Query</p>
        <QueryPrettyPrint
          {rule}
          isParentConditional={isParentConditionalRule(rule)}
        />
      </div>
    </div>
  </div>

  <form method="dialog" class="bg-base-200 mt-0 flex flex-col gap-2 pb-2 pt-2">
    <div
      class="tooltip"
      data-tip="This will take you back to the Asset {textConstants['Folder']}."
    >
      <button
        class="btn btn-primary w-full"
        disabled={!example_field}
        on:click={async () => {
          console.log("Button clicked");
          let temp = getRuleCopy(rule);
          console.log("Rule copy", temp);
          addExample(temp, example_field);
          console.log(example_field);
          console.log("Example added", temp);
          temp.id = rule.id;
          temp.name = rule.name;

          let resp = await edit(temp, report_id);
          console.log("Response", resp);
          if (resp.success) toast.success("Rule example added!");
          else toast.error(resp.message);

          push(`/review/${report_id}`);
        }}
      >
        Update Rule <Icon icon="iconoir:arrow-right" />
      </button>
    </div>
    <button class="btn btn-ghost text-primary">Cancel</button>
  </form>
</Modal>
