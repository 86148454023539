/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Hit` entity colour values.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * utilising Hit colours so as to reduce redundancy & inconsistency in the codebase.
 */
export const HitColour = Object.freeze({
  PASSED: "green",
  REVIEW: "amber",
  RISK: "red",
  UNKNOWN: "grey",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Hit` colour to name mappings.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * utilising Hit colours so as to reduce redundancy & inconsistency in the codebase.
 */
export const HitColourName = Object.freeze({
  default: {
    STRONG: "Passed",
    POSSIBLE: "To Review",
    WEAK: "Weak",
    ACCEPTED: "Accepted",
    REJECTED: "Risk",
    ACCEPTEVERYWHERE: "Accept Everywhere",
  },
  negative: {
    STRONG: "Risk",
    POSSIBLE: "To Review",
    WEAK: "Weak",
    ACCEPTED: "Accepted",
    REJECTED: "Risk",
    ACCEPTEVERYWHERE: "Accept Everywhere",
  },
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Hit` bulk action types.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * utilising Hit colours so as to reduce redundancy & inconsistency in the codebase.
 */
export const HitBulkAction = Object.freeze({
  ASSIGN: "assign",
  FLAG: "flag",
  FLAGASSIGN: "flag_and_assign",
  CLOSE: "close",
  OPEN: "open",
});
