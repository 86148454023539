<script>
  import Icon from "@iconify/svelte";
  import TeamSelectDropdown from "./TeamSelectDropdown.svelte";
  import { teamToShowName } from "./teamOverview";
</script>

<div class="flex justify-between">
  <h1 class="text-lg font-bold">Team Overview</h1>
  <div class="flex items-center">
    <Icon icon="ic:baseline-groups" class="h-8 w-8 rounded-full p-1" />
    <h2 class="p-1 font-semibold">{$teamToShowName}</h2>
    <TeamSelectDropdown />
  </div>
</div>
