<script>
  import { toast } from "svelte-sonner";
  import { push } from "svelte-spa-router";
  import { fetchGet } from "../helpers";

  export let params = null;

  let email_id = null;
  let email_type = null;
  let success = null;
  let reason = null;
  let hit = null;
  let done = false;

  async function onStart() {
    email_id = await params.email_id;
    email_type = await params.email_type;

    await load();
  }

  async function load() {
    const response = await fetchGet(
      "/email/" + email_id + "/" + email_type,
    );

    done = true;
    success = await response.success;

    if (success) {
      hit = await response.hit;
      toast.success("Added to Hit to Todo");
    } else {
      reason = await response.reason;
      toast.error("Failed to add Hit to Todo");
    }

    push("/");
  }
  onStart();
</script>
