// workflowAction.js
import { writable } from "svelte/store";

export const workflowAction = writable({
  addStep: null, // Initially, there's no addStep function
});

export const saveStep = writable({
  id: null,
  name: null,
  description: null,
  saveChanges: () => {},
});
