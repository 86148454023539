import { toast } from "svelte-sonner";
import { push } from "svelte-spa-router";
import { get, writable } from "svelte/store";
import { HitColour, HitColourName } from "./lib/interfaces/Hit.interface";
import {
  RuleDataType,
  RuleSearchType,
  RuleStatus,
} from "./lib/interfaces/Rule.interface";
import {
  ApprovalStatus,
  ApprovalStatusColor,
} from "./lib/interfaces/Workflow.interface";
import {
  convertBoolToBuilder,
  convertBuilderToBoolean,
} from "./ruleBuilderConverter";
import { notifications, org_id, user_list } from "./stores";

export const notification = writable("");
const API_VERSION = import.meta.env.VITE_API_VERSION;

function notifyOnError(error) {
  notification.set(error);
}

export function mutateURL(url) {
  if (!url && typeof url !== "string") {
    throw new Error(
      "Error: No URL was provided or the URL is of incorrect type.",
    );
  }

  if (url.startsWith("/")) {
    return `/api/${API_VERSION}${url}`;
  } else {
    return `/api/${API_VERSION}/${url}`;
  }
}

class FetchRequest {
  constructor(method, body) {
    this.method = method;
    this.body = body ? JSON.stringify(body) : null;
  }

  toObject() {
    let request = {
      method: this.method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    if (this.method !== "GET") {
      request = {
        ...request,
        body: this.body,
      };
    }

    return request;
  }
}

class FileUploadRequest extends FetchRequest {
  constructor(method, body) {
    super(method, body);
    this.body = body ? body : null;
    console.log("body", this.body);
  }

  toObject() {
    const request = super.toObject();
    // Important: let fetch assign the content-type for files!
    delete request.headers["Content-Type"];
    return request;
  }
}

export async function fetchGet(url) {
  try {
    const request = new FetchRequest("GET").toObject();
    const response = await fetch(mutateURL(url), request);

    // Example for how we should be processing responses rather than hardcoding a "success" property
    // if (!response.ok) {
    //   throw new Error(
    //     `Response returned Status(${response.status} - ${response.statusText})`,
    //   );
    // }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    notifyOnError("Something went wrong when getting your data.");
    console.error(error);

    throw error;
  }
}

export async function fetchPost(url, data = {}) {
  try {
    const request = new FetchRequest("POST", data).toObject();
    const response = await fetch(mutateURL(url), request);
    const responseJSON = await response.json();

    return responseJSON;
  } catch (error) {
    notifyOnError("Something went wrong when posting your data.");
    console.error(error);

    throw error;
  }
}

export async function fetchPatch(url, data = {}, has_file = false) {
  try {
    const request = has_file
      ? new FileUploadRequest("PATCH", data).toObject()
      : new FetchRequest("PATCH", data).toObject();
    const response = await fetch(mutateURL(url), request);
    const responseJSON = await response.json();

    return responseJSON;
  } catch (error) {
    notifyOnError("Something went wrong when patching your data.");
    console.error(error);

    throw error;
  }
}

export async function fetchDelete(url, data = {}) {
  try {
    const request = new FetchRequest("DELETE", data).toObject();
    const response = await fetch(mutateURL(url), request);
    const responseJSON = await response.json();

    return responseJSON;
  } catch (error) {
    notifyOnError("Something went wrong when deleting your data.");
    console.error(error);

    throw error;
  }
}

export function generate32BitInteger() {
  return Math.floor(Math.random() * (2 ** 31 - 1)) + 1;
}

export function tidyPoolLabel(l) {
  if (l != null) {
    return l.replace(/_/g, " ").replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return l;
}

export function getUTCDateString() {
  const now = new Date();
  const utc = new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    now.getUTCHours(),
    now.getUTCMinutes(),
    now.getUTCSeconds(),
  );

  return utc.toISOString();
}

export function clickOutside(node, onEventFunction) {
  const handleClick = (event) => {
    var path = event.composedPath();

    if (!path.includes(node)) {
      onEventFunction();
    }
  };

  document.addEventListener("click", handleClick);

  return {
    destroy() {
      document.removeEventListener("click", handleClick);
    },
  };
}

export function getTextColor(backgroundColor) {
  let r = parseInt(backgroundColor.slice(1, 3), 16);
  let g = parseInt(backgroundColor.slice(3, 5), 16);
  let b = parseInt(backgroundColor.slice(5, 7), 16);

  let luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

  if (luminance < 0.5) return "#ffffff";
  else return "#000000";
}

export const nameToColor = (name) => {
  let hash = 0;

  for (let i = 0; i < name.length; i++)
    hash = name.charCodeAt(i) + ((hash << 5) - hash);

  let color = "";

  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }

  return "#" + color;
};

export function getHitType(hitIndex) {
  const hitTypes = {
    facebook: ["facebook", "meta"],
    twitter: ["twitter"],
    instagram: ["instagram"],
    linkedin: ["linkedin"],
    youtube: ["youtube"],
    tiktok: ["tiktok"],
    document: ["document"],
    image: ["image"],
  };

  if (!hitIndex) {
    return "website";
  }

  for (let hitType in hitTypes) {
    for (let keyword of hitTypes[hitType]) {
      if (hitIndex.includes(keyword)) {
        return hitType;
      }
    }
  }

  return "website";
}


function jsonParseIfString(data){
  if (typeof data === 'string') {
    return JSON.parse(data);
  }
  return data;
}
/**
 * Builds the examples to filter against for a given rule.
 * @param {*} rule
 * @param {string} [purpose="edit"] - The purpose of the examples, either "edit" or "copy".
 * @returns
 */
export function buildExamples(rule, purpose="edit") {
  let examples = "*";

  switch (rule.search_type) {
    case RuleSearchType.IMAGE:
    case RuleSearchType.VISUAL:
    case RuleSearchType.MANUAL:
      examples = rule.examples;
      break;
    case RuleSearchType.LEXICAL:
      examples = rule.lexical;
      break;
    case RuleSearchType.CONTEXTUAL:
      examples = jsonParseIfString(rule.examples);
      break;
    case RuleSearchType.TENSOR:
      const filteredExamples = rule.examples.filter(
        (ex) => ex.text.trim() !== "",
      );
      let result = {}; // Object to accumulate results.
      if (purpose === "edit") {
      for (const ex of filteredExamples) {
        result[ex.text] = ex.score; // Assign text as key and score as value.
      }
      examples = result;
    } else {
      examples = filteredExamples;
    }
      break;
    case RuleSearchType.BOOLEAN:
    default:
      break;
  }

  return examples;
}

export const buildSearchSources = (search_sources) =>
  Object.entries(search_sources)
    .filter(([key, value]) => value)
    .map(([key, _]) => key);

export const saveAssetRule = async (rule, stream_id, my_rule = false) => {
  const examples = buildExamples(rule);

  const assetRuleSaveResponse = await fetchPost("/asset/save", {
    rule: {
      examples,
      operator: rule.operator,
      doc_query:
        rule.search_type === RuleSearchType.BOOLEAN
          ? rule.queryBoolean
          : rule.doc_query,
      section_query: rule.section_query,
      index: rule.index,
      name: rule.name,
      description: rule.description,
      remediation_step: rule.remediation_step,
      strong_match_threshold: rule.strong_match_threshold,
      no_match_threshold: rule.no_match_threshold,
      affirmative_rule: rule.affirmative_rule,
      search_type: rule.search_type,
      search_sources: buildSearchSources(rule.search_sources),
      limit: rule.limit,
      exclusion_weight: rule.exclusion_weight,
      type: "asset", //rule.type,
      context: rule.context,
      definitions: rule.definitions,
    },
    stream_id,
    my_rule: my_rule,
  });

  return assetRuleSaveResponse;
};

export const saveLiveRule = async (rule, stream_id, my_rule = false) => {
  rule.boolean =
    rule.visual_type === RuleDataType.BUILDER
      ? convertBuilderToBoolean(rule.builder)
      : rule.boolean;
  rule.type = RuleDataType.BOOLEAN;

  if (!boolChecker(rule.boolean)) {
    toast.warning("Rule is not valid, please check the syntax.");
    return;
  } else rule.boolean = boolChecker(rule.boolean);
  rule.type = "live";
  return await fetchPost("/rule/add", {
    report_id: stream_id,
    rule: rule,
    boolean: rule.boolean,
    data_types: rule.data_types,
    priority: rule.default_hit_priority,
    my_rule: my_rule,
  });
};

export async function saveRuleToMyRules(
  ruleToSaveId,
  savedRules = [],
  type = "live",
) {
  let newSavedRules = savedRules && savedRules.length >= 0 ? savedRules : [];

  const res = await fetchGet(`/rule/duplicate/${ruleToSaveId}/${get(org_id)}`);
  const duplicatedRule = res.rule;

  await fetchGet(`/rule/savedrule/${duplicatedRule.id}/${type}`);
  duplicatedRule["save_to_org"] = !duplicatedRule["save_to_org"];
  duplicatedRule["label"] = duplicatedRule["name"];

  if (duplicatedRule["save_to_org"]) {
    newSavedRules = [...newSavedRules, duplicatedRule];
  } else {
    newSavedRules = newSavedRules.filter(
      (saved_rule) => saved_rule["id"] != duplicatedRule["id"],
    );
  }

  return newSavedRules;
}

export const dTypesToMultiSelect = (d_types) => {
  let items = {};

  d_types = d_types.sort((a, b) => a.data_type.localeCompare(b.data_type));

  for (const entry of d_types) {
    const customer = entry.customer;
    const dataType = entry.data_type;

    items[customer] = items[customer] || {};
    items[customer]["selected"] = "none";

    // if (!items[customer]["members"]) items[customer]["members"] = [];

    // Check if the dataType contains "website"
    if (dataType.includes("website")) {
      const websiteCategory = items[customer]["website"] || {
        members: [],
        selected: "none",
      };

      const existingMember = websiteCategory.members.find(
        (member) => member.name === dataType,
      );

      if (!existingMember) {
        websiteCategory.members = [
          ...websiteCategory.members,
          { name: dataType, show: true, value: dataType, selected: false },
        ];
      }

      items[customer]["website"] = websiteCategory;
    } else {
      // For other dataTypes (not containing "website"), categorize them as "social"
      const socialCategory = items[customer]["social"] || {
        members: [],
        selected: "none",
      };

      const existingMember = socialCategory.members.find(
        (member) => member.name === dataType,
      );

      if (!existingMember) {
        socialCategory.members = [
          ...socialCategory.members,
          { name: dataType, show: true, value: dataType, selected: false },
        ];
      }

      items[customer]["social"] = socialCategory;
    }
  }
  for (const key of Object.keys(items)) {
    if (!items[key].website)
      items[key].website = { members: [], selected: "none" };
    if (!items[key].social)
      items[key].social = { members: [], selected: "none" };
  }

  return items;
};

/**
 * Since the timezone we are getting from the backend is an UTC date,
 * we need to convert it to the local timezone. It is important to get UTC
 * dates to have each user see the same date.
 * @param {string} dateString
 */
function convertUTCDateToCurrentTimezone(dateString) {
  try {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offset * 60 * 1000);

    return newDate;
  } catch (e) {
    console.log(
      `Unable to convert UTC date to current timezone due to error: ${e}`,
    );
  }
}

/**
 * Get issue age in years,
 * if it's not a year old in days,
 * if it's not a day old in hours,
 * if it's not an hour old in minutes,
 * if it's not a minute old say "just now."
 * @param {string} dateString
 */
export function getDisplayDate(dateString) {
  try {
    let dateToCompare = convertUTCDateToCurrentTimezone(dateString);

    if (isNaN(dateToCompare)) dateToCompare = new Date();

    const dateFormat = "date"; //get(date_format);

    if (dateFormat === "ago" || !dateFormat) {
      const today = new Date();
      const diffTime = Math.abs(today.getTime() - dateToCompare.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      const diffYears = Math.ceil(diffDays / 365);

      if (diffYears > 1) {
        return diffYears + " years ago";
      } else if (diffDays > 1) {
        return diffDays + " days ago";
      } else if (diffHours > 2) {
        return diffHours + " hours ago";
      } else if (diffHours > 1) {
        return diffHours + " hour ago";
      } else if (diffMinutes > 2) {
        return diffMinutes + " mins ago";
      } else {
        return "Just now";
      }
    }
    // else if $date_format === "date"
    return dateToCompare.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  } catch (e) {
    console.log(
      `Unable to get display date string from DateString(${dateString}) due to error: ${e}`,
    );
  }
}

// sanitizes & checks if a bool string is valid
export const boolChecker = (boolstr) => {
  boolstr = boolstr.replaceAll(/[‘’‛`]/g, "'").replaceAll(/[“”‟]/g, '"');

  try {
    convertBoolToBuilder(boolstr);
    return boolstr;
  } catch (error) {
    return false;
  }
};

export const getUser = (id) => {
  const ul = get(user_list);

  const result = ul.find((obj) => obj.value === id);
  return result ? result.label : undefined;
};

export const getInitials = (name) => {
  const names = name.split(" ");

  let initials = "";

  for (let i = 0; i < names.length; i++) initials += names[i].charAt(0);

  return initials.toUpperCase();
};

export function hitColourToName(colour, isParentConditional = false) {
  if (isParentConditional) {
    switch (colour) {
      case HitColour.PASSED:
        return RuleStatus.TRUE;
      case HitColour.REVIEW:
        return HitColourName.default.POSSIBLE;
      case HitColour.RISK:
        return RuleStatus.FALSE;
      default:
        return HitColourName.default.WEAK;
    }
  } else {
    switch (colour) {
      case HitColour.PASSED:
        return HitColourName.default.STRONG;
      case HitColour.REVIEW:
        return HitColourName.default.POSSIBLE;
      case HitColour.RISK:
        return HitColourName.default.REJECTED;
      default:
        return HitColourName.default.WEAK;
    }
  }
}

export function assetApprovalStatusToColour(approvalStatus) {
  let styles = {};

  switch (approvalStatus) {
    case ApprovalStatus.PENDING:
      styles = { color: ApprovalStatusColor.PENDING.Color };
      break;
    case ApprovalStatus.REVIEW:
      styles = { color: ApprovalStatusColor.REVIEW.Color };
      break;
    case ApprovalStatus.REQUESTCHANGES:
      styles = { color: ApprovalStatusColor.REQUESTCHANGES.Color };
      break;
    case ApprovalStatus.ARCHIVE:
      styles = { color: ApprovalStatusColor.ARCHIVE.Color };
      break;
    case ApprovalStatus.REJECTED:
      styles = { color: ApprovalStatusColor.REJECTED.Color };
      break;
    case ApprovalStatus.APPROVE:
      styles = { color: ApprovalStatusColor.APPROVE.Color };
      break;
  }

  return Object.entries(styles)
    .map(([key, value]) => `${key}: ${value}`)
    .join(";");
}

export const updateNotifStore = async () => {
  const res = await fetchGet("/notification?currentPage=1&pageAmount=5");
  if (res.success) notifications.set(res);
};
export const openNotif = async (id, url, seen = false) => {
  push(url);
  if (seen) return;

  const res = await fetchPatch(`/notification/${id}`);
  if (res.success) updateNotifStore();
};
