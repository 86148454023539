<script>
  import { getUser } from "../../../helpers";

  export let comment;
</script>

<p class="text-sm">
  {getUser(comment.user_id)} <strong>changed priority</strong> from
  <button
    class="btn btn-xs"
    class:btn-error={comment.old === "High"}
    class:btn-warning={comment.old === "Med"}
    class:btn-success={comment.old === "Low"}
  >
    {comment.old}
  </button>
  to
  <button
    class="btn btn-xs"
    class:btn-error={comment.new === "High"}
    class:btn-warning={comment.new === "Med"}
    class:btn-success={comment.new === "Low"}
  >
    {comment.new}
  </button>
</p>
