<script>
  import Icon from "@iconify/svelte";

  export let cardTitle = "";
  export let cardDescription = "(Last 30 Days)";
  export let value = 0;
  export let icon = "iconoir:refresh";
</script>

<!-- NOTE: change the min-[Xpx] on changes -->
<div class="flex w-52 gap-2 rounded border p-4 shadow-lg min-[1428px]:grow">
  <div class="rounded-full bg-base-200 p-4">
    <Icon {icon} class="h-8 w-8 text-primary" />
  </div>
  <div class="flex flex-col">
    <h1 class="text-xs font-bold text-base-content/50">{cardTitle}</h1>
    <h2
      class="text-xs text-base-content/50 [font-size:0.66rem] [line-height:0.8rem]"
    >
      {cardDescription}
    </h2>
    <p class="mt-auto text-3xl font-bold">{value}</p>
  </div>
</div>
