<script>
  import { fetchPost } from "../../helpers";
  import { org_users } from "../../stores";
  import Select from "../Select.svelte";

  export let role;
  export let userId;
  export let selectDisabled = false;

  /**
   * Set the user's role string in the backend and update the store
   * @param {string} role
   */
  async function changeUserRole() {
    await fetchPost("/org-users/change-role/" + userId, { role });

    $org_users = $org_users.map((user) => {
      if (user.id === userId) user.role = role;
      return user;
    });
  }

  let roles = [
    { value: "admin", label: "Admin" },
    { value: "assignee", label: "Assignee" },
    { value: "user", label: "User" },
  ];
</script>

<Select
  size="xs"
  items={roles}
  placeholder="Select Team Members"
  disabled={selectDisabled}
  bind:selectedValue={role}
  on:change={changeUserRole}
/>
