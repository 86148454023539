/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `RuleSet` entity default conditions.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * utilising the default `RuleSet` so as to reduce redundancy & inconsistency in the codebase.
 */
export const NoRuleSetDefaults = Object.freeze({
  NAME: "Uncategorised Rules",
  ID: "no_rule_set",
  DESCRIPTION: "Rules without a checklist.",
});
