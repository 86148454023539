<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { fetchPost } from "../../helpers";
  import Modal from "../Modals/Modal.svelte";
  import Select from "../Select.svelte";

  const dispatch = createEventDispatcher();

  export let hitId;
  export let entry;
  export let originalData;

  let editableDictionaries = [];
  let selectedDictionary = 0;

  async function addToDict() {
    //TODO: Add backend support for adding this to the selected dictionary
    await fetchPost("/spelling/add-to-dict", {
      rule_id: entry.rule.id,
      text: entry.hit.text,
      dictionary: selectedDictionary,
    });
    for (let i = 0; i < originalData.length; i++) {
      if (
        originalData[i].hit.text == entry.hit.text &&
        originalData[i].rule.id == entry.rule.id
      ) {
        originalData[i].hit.in_report = "Closed";
      }
    }
    dispatch("refresh", originalData);
  }

  onMount(() => {
    //TODO: Implement getting list of editable dictionaries

    editableDictionaries = [
      {
        id: 3,
        name: "NRMA Terms",
        description: "Specific Terms for NRMA",
        type: "shared",
        owner: 1, //User ID of the owner of this dictionary
        collaborators: [2, 3, 4], //User IDs of any collaborators of this dictionary
      },
      {
        id: 4,
        name: "Team Dictionary",
        description: "Custom Terms",
        type: "shared",
        owner: 1, //User ID of the owner of this dictionary
        collaborators: [2, 3, 4], //User IDs of any collaborators of this dictionary
      },
      {
        id: 142292782,
        name: "Report Dictionary",
        description: "Custom Dictionary, specific to this report",
        type: "report",
        owner: "", //Set to
        collaborators: [2, 3, 4], //Any users with edit permissions on the stream
      },
    ];
  });
</script>

<button
  class="btn btn-xs"
  on:click={() => {
    const addToDictModal = document.getElementById(`addToDictModal-${hitId}`);
    addToDictModal.showModal();
  }}
>
  Add to Dict
</button>

<Modal modalId="addToDictModal-{hitId}" size="md">
  <div
    class="bg-base-100 sticky top-0 -mx-6 -mb-6 flex -translate-y-6 items-center justify-center px-6 py-4"
  >
    <h2 class="text-xl font-semibold">Add to Dictionary</h2>
    <form method="dialog">
      <button class="btn btn-square btn-ghost btn-sm absolute right-1 top-1">
        <Icon icon="iconoir:cancel" />
      </button>
    </form>
  </div>

  <div class="mx-auto flex w-full flex-col">
    <p class="py-4 text-lg">
      Please select the dictionary you want to add the term <span
        class="badge text-lg">'{entry.hit.text.split(",")[1]}'</span
      > to:
    </p>

    <Select
      bind:selectedValue={selectedDictionary}
      items={editableDictionaries.map((dict) => ({
        value: dict.id,
        label: dict.name,
        desc: dict.description,
      }))}
    />
  </div>

  <form
    class="mx-auto mt-6 flex w-full max-w-md flex-col gap-4"
    method="dialog"
  >
    <button on:click={addToDict} class="btn btn-primary w-full">
      Add to Dictionary
    </button>
    <button class="link-hover link link-primary w-full text-xs">
      Cancel
    </button>
  </form>
</Modal>
