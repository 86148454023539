<script>
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../../helpers";
  import { actions } from "../../stores";
  import Icon from "@iconify/svelte";
  import Modal from "../Modals/Modal.svelte";

  let bottomButtons = {
    show: true,
    primaryText: "Confirm",
    secondaryText: "Cancel",
    loading: false,
    primaryAction: createAction,
    secondaryAction: closeModal,
  };

  let newActionText = "";
  let errorMessage = "";
  let submitDisabled = false;

  function openModal() {
    newActionText = "";
    errorMessage = "";
    submitDisabled = false;
    document.querySelector("#addActionModal")?.showModal();
  }

  function closeModal() {
    document.querySelector("#addActionModal")?.close();
  }

  function validateForm() {
    if (!newActionText.trim()) {
      errorMessage = "Action name is required.";
      return false;
    }

    return true;
  }

  async function createAction() {
    const originalActions = $actions;

    if (!validateForm()) {
      return;
    }
    bottomButtons.loading = true;
    let res = await fetchPost("/action", { action: newActionText });

    const updatedActions = [...$actions, res.action];
    actions.set(updatedActions);

    if (!res.success) {
      console.warn("Error creating action", res);
      actions.set(originalActions);
      return;
    }
    bottomButtons.loading = false;
    toast.success("Action created successfully.");
    closeModal();
  }
</script>

<button class="btn btn-primary btn-sm" on:click={openModal}>
  Add Action
</button>

<Modal
  modalId="addActionModal"
  size="sm"
  cornerBackButton={true}
  cornercloseButton={false}
  {bottomButtons}
>
  <div class="space-y-2">
    <div
      class="bg-primary-content m-auto flex aspect-square w-16 items-center justify-center rounded-full"
    >
      <Icon icon="iconoir:mail" class="text-primary text-3xl" />
    </div>
    <h3 class="text-center font-bold">Add Action</h3>
  </div>

  <div class="mt-6 space-y-2">
    <p class="text-sm">Action Name</p>
    <input
      class="input {submitDisabled ? 'input-error' : 'input-bordered'} w-full"
      id="newAction"
      name="newAction"
      placeholder="Enter action name"
      bind:value={newActionText}
      required
    />

    {#if errorMessage}
      <span class="text-xs text-red-500">*{errorMessage}</span>
    {/if}
  </div>
</Modal>
