<script>
  import { currentUser } from "../../stores";

  export let dictionaryName = "";
  export let dictionaries = [];
  export let selectedDictionaries = [];
</script>

<div class="my-8">
  <h3 class="text-2xl font-semibold">{dictionaryName}</h3>
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
  <div class=" grid w-full gap-2 overflow-auto rounded border p-2">
    <div class="grid min-w-[750px] grid-cols-2 border-b px-6 font-semibold">
      <label
        class="label col-span-1 cursor-pointer justify-start gap-4"
        on:click|stopPropagation
      >
        <input
          type="checkbox"
          class="checkbox-primary checkbox checkbox-sm"
          indeterminate={dictionaries.some((r) =>
            selectedDictionaries.includes(r.id),
          ) && !dictionaries.every((r) => selectedDictionaries.includes(r.id))}
          checked={dictionaries.every((r) =>
            selectedDictionaries.includes(r.id),
          )}
          on:change={(e) => {
            if (e.target.checked) {
              // This probably can be optimised?
              for (let i = 0; i < dictionaries.length; i++) {
                const found = selectedDictionaries.includes(dictionaries[i].id);
                if (!found) selectedDictionaries.push(dictionaries[i].id);
              }
              selectedDictionaries = selectedDictionaries;
            } else {
              // This probably can be optimised?
              for (let i = 0; i < dictionaries.length; i++) {
                selectedDictionaries = selectedDictionaries.filter(
                  (d) => d != dictionaries[i].id,
                );
              }
              selectedDictionaries = selectedDictionaries;
            }
          }}
        />
        <span class="label-text">Dictionary Name</span>
      </label>
      <span class="col-span-1 text-end">Actions</span>
    </div>

    {#each dictionaries as dictionary}
      <div class="rounded border bg-base-200">
        <div
          class="flex cursor-pointer items-center justify-between gap-2 px-6 py-2"
        >
          <label
            class="label col-span-1 cursor-pointer justify-start gap-4"
            on:click|stopPropagation
          >
            <input
              type="checkbox"
              class="checkbox-primary checkbox checkbox-sm"
              checked={selectedDictionaries.includes(dictionary.id)}
              on:change={(e) => {
                if (e.target.checked)
                  selectedDictionaries = [
                    ...selectedDictionaries,
                    dictionary.id,
                  ];
                else
                  selectedDictionaries = selectedDictionaries.filter(
                    (d) => d !== dictionary.id,
                  );
              }}
            />
            <div>
              <span class="label-text">{dictionary.name}</span>
              <p class="text-sm font-light">
                {dictionary.description}
              </p>
            </div>
          </label>

          <div class="flex items-center gap-1">
            <a
              class="btn btn-outline btn-primary btn-xs"
              href="#/spelling/dictionaries/{dictionary.id}"
            >
              {dictionary.collaborators.includes($currentUser.id) ||
              dictionary.owner.includes($currentUser.id)
                ? "Edit"
                : "View"} Dictionary
            </a>
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>
