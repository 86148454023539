<script>
  import { onMount } from "svelte";
  import { link } from "svelte-spa-router";
  import TableLoading from "../components/TableViews/TableLoading.svelte";
  import { fetchGet } from "../helpers";
  import { actions, currentUser, user_id } from "../stores";
  import IssueTable from "./../components/TableViews/IssueTable/IssueTable.svelte";

  let data;
  let group = 1;
  let allRules = [];
  let loading = true;

  let actionTexts = [];

  let searchSettings = {
    isCaseSensitive: false,
    searchByRuleTitle: false,
    searchByRuleDescription: false,
    searchByHitTitle: true,
    searchByHitDescription: true,
  };

  let dateToSortByString;

  actionTexts = $actions.map(function (elem) {
    return elem.action_text;
  });

  function refreshTable(event) {
    data = event.detail;
    data = [...data];
  }

  function getUsersThatHaveBeenAssigned() {
    let users = [];
    data.forEach((hit) => {
      for (let i = 0; i < hit["hit"]["assigned_users"].length; i++) {
        if (!users.includes(hit["hit"]["assigned_users"][i]["assigned_id"])) {
          users.push(hit["hit"]["assigned_users"][i]["assigned_id"]);
        }
      }
    });
    return users;
  }

  onMount(async () => {
    const currentUserId = $currentUser?.id ?? $user_id;

    if (currentUserId) {
      loading = true;
      const res = await fetchGet(`/user/${currentUserId}/hits`);
      data = res.data;

      let output = {};
      data.forEach((entry) => (output[entry["rule"]["id"]] = entry["rule"]));
      allRules = Object.values(output);

      loading = false;
    }
  });
</script>

<svelte:head>
  <title>My Issues - Haast</title>
</svelte:head>

<div class="mx-auto max-w-[1706px] 2xl:pl-[13.5rem]">
  <h1 class="mb-4 text-3xl font-semibold">My Issues</h1>

  <div class="flex items-center gap-2 rounded border bg-base-200 px-2">
    Sort By :
    <div class="tabs-boxed tabs">
      {#each ["None", "Priority", "Age", "Status", "Assignee", "Actions"] as e, i}
        <button
          class="tab"
          class:tab-active={group === i + 1}
          on:click={() => (group = i + 1)}
        >
          {e}
        </button>
      {/each}
    </div>
  </div>
</div>

{#if !loading}
  {#key data}
    {#if group === 1}
      <IssueTable
        openedTab="Flagged"
        on:refresh={refreshTable}
        {data}
        tableType="CLASSIC"
        tableRootPage={"my-hits"}
        {allRules}
      />
    {:else if group === 2}
      <IssueTable
        openedTab="Flagged"
        bind:searchSettings
        bind:dateToSortByString
        on:refresh={refreshTable}
        {data}
        tableType="HIGH_PRIORITY"
        tableRootPage={"my-hits"}
        {allRules}
      />
      <IssueTable
        openedTab="Flagged"
        bind:searchSettings
        bind:dateToSortByString
        on:refresh={refreshTable}
        {data}
        tableType="MED_PRIORITY"
        tableRootPage={"my-hits"}
        {allRules}
      />
      <IssueTable
        openedTab="Flagged"
        bind:searchSettings
        bind:dateToSortByString
        on:refresh={refreshTable}
        {data}
        tableType="LOW_PRIORITY"
        tableRootPage={"my-hits"}
        {allRules}
      />
    {:else if group === 3}
      <IssueTable
        openedTab="Flagged"
        bind:searchSettings
        bind:dateToSortByString
        on:refresh={refreshTable}
        {data}
        tableType="LESS_THAN_THREE_DAYS"
        tableRootPage={"my-hits"}
        {allRules}
      />
      <IssueTable
        openedTab="Flagged"
        bind:searchSettings
        bind:dateToSortByString
        on:refresh={refreshTable}
        {data}
        tableType="LESS_THAN_TEN_DAYS"
        tableRootPage={"my-hits"}
        {allRules}
      />
      <IssueTable
        openedTab="Flagged"
        bind:searchSettings
        bind:dateToSortByString
        on:refresh={refreshTable}
        {data}
        tableType="MORE_THAN_TEN_DAYS"
        tableRootPage={"my-hits"}
        {allRules}
      />
    {:else if group === 4}
      <IssueTable
        openedTab="Flagged"
        bind:searchSettings
        bind:dateToSortByString
        on:refresh={refreshTable}
        {data}
        tableType="NOT_DONE"
        tableRootPage={"my-hits"}
        {allRules}
      />
      <IssueTable
        openedTab="Flagged"
        bind:searchSettings
        bind:dateToSortByString
        on:refresh={refreshTable}
        {data}
        tableType="DONE"
        tableRootPage={"my-hits"}
        {allRules}
      />
    {:else if group === 5}
      {#each getUsersThatHaveBeenAssigned() as user}
        {#if getUsersThatHaveBeenAssigned()[0] === user}
          <IssueTable
            openedTab="Flagged"
            bind:searchSettings
            bind:dateToSortByString
            on:refresh={refreshTable}
            {data}
            tableType={"ASSIGNEE:" + user}
            tableRootPage={"my-hits"}
            {allRules}
          />
        {:else}
          <IssueTable
            openedTab="Flagged"
            bind:searchSettings
            bind:dateToSortByString
            on:refresh={refreshTable}
            {data}
            tableType={"ASSIGNEE:" + user}
            tableRootPage={"my-hits"}
            {allRules}
          />
        {/if}
      {/each}
    {:else if group === 6}
      {#each actionTexts as action}
        <IssueTable
          openedTab="Flagged"
          bind:searchSettings
          bind:dateToSortByString
          on:refresh={refreshTable}
          {data}
          tableType={"ACTION:" + action}
          tableRootPage={"my-hits"}
          {allRules}
        />
      {:else}
        <p
          class="text-center text-base-content/50 text-xl p-4 mx-auto my-8 rounded border-4 border-dotted"
        >
          No actions found, please
          <a class="link hover:link-primary" href="/settings" use:link>
            create an action
          </a> first.
        </p>
      {/each}
    {:else}
      <p>404 Not Found...</p>
    {/if}
  {/key}
{:else}
  <TableLoading tableRootPage="myissues" />
{/if}
