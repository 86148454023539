<script>
  import Icon from "@iconify/svelte";
  import download from "downloadjs";
  import { tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost, mutateURL } from "../../../helpers";
  import LogoLoader from "../../LogoLoader.svelte";
  import Modal from "../../Modals/Modal.svelte";
  import ExportColumns from "./ExportColumns.svelte";
  import ExportFilter from "./ExportFilter.svelte";
  import ExportRules from "./ExportRules.svelte";

  export let reports = [];
  export let allRules = [];
  // export let reportId = -1;

  let loading = false;
  let dialog = null;
  let exportStatus = "";

  let requestBody = {
    columns: {},
    rules: {},
    filters: {},
  };

  async function downloadReport() {
    loading = true;
    exportStatus = "";

    try {
      const request = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(requestBody),
      };
      const response = await fetch(mutateURL("/report/export"), request);

      if (!response.ok) {
        throw new Error("Something went wrong exporting the report.");
      }

      const blob = await response.blob();
      download(blob, "stream.csv", "text/csv");
      toast.success("Stream exported successfully.");
      exportStatus = "success";
      await tick();
      document.querySelector("#exportDialog").close();
      exportStatus = "";
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong while exporting the stream.");
      exportStatus = "error";
    }

    loading = false;
  }
</script>

<button
  class="btn btn-sm m-4 mt-auto"
  on:click={() => {
    document.querySelector("#exportDialog").showModal();
    exportStatus = "";
  }}
>
  Export Results
</button>

<Modal modalId="exportDialog" size="sm">
  <h2 class="text-xl font-semibold">Data export</h2>

  <p class="mt-2 text-sm">
    Please select the fields that you would like to export below, then press
    'Download CSV' to export a CSV file with details about your stream.
  </p>

  <div class="my-4 flex h-96 flex-col gap-2 overflow-auto">
    <ExportRules
      bind:reports
      bind:allRules
      on:updateRules={(e) => (requestBody.rules = e.detail)}
    />
    <ExportColumns on:updateColumns={(e) => (requestBody.columns = e.detail)} />
    <ExportFilter on:updateFilters={(e) => (requestBody.filters = e.detail)} />
  </div>

  <button
    class="btn btn-primary btn-sm w-full"
    class:btn-success={exportStatus === "success"}
    class:btn-error={exportStatus === "error"}
    disabled={loading}
    on:click={downloadReport}
  >
    {#if loading}
      <LogoLoader size="1.25rem" />
    {:else}
      <Icon icon="iconoir:download" />
    {/if}
    Download CSV
  </button>
</Modal>
