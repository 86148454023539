<script>
  import { getUser } from "../../../helpers";

  export let comment;
</script>

<p class="text-sm">
  {getUser(comment.user_id)} <strong>sent email</strong>
  <i>{comment.text}</i> to <i>{getUser(comment.to_user_id)}</i>
</p>
