<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { link } from "svelte-spa-router";
  import { fly } from "svelte/transition";
  import ActionConfirmation from "../components/ActionConfirmation.svelte";
  import AssetRuleBuilder from "../components/AssetReview/AssetRuleBuilder.svelte";
  import AssetRuleTypeSelector from "../components/AssetReview/AssetRuleTypeSelector.svelte";
  import ConditionalAssetRuleBuilder from "../components/AssetReview/ConditionalAssetRuleBuilder.svelte";
  import LogoLoader from "../components/LogoLoader.svelte";
  import AiGenChecklistModal from "../components/Modals/AiChecklists/AiGenChecklistModal.svelte";
  import Modal from "../components/Modals/Modal.svelte";
  import NewChecklistModal from "../components/Modals/NewChecklistModal.svelte";
  import Rule from "../components/Rule.svelte";
  import SavedRulesTable from "../components/TableViews/SavedRulesTable/SavedRulesTable.svelte";
  import {
    fetchGet,
    fetchPost,
    saveAssetRule,
    saveLiveRule,
    tidyPoolLabel,
  } from "../helpers";
  import { RuleScope, RuleType } from "../lib/interfaces/Rule.interface";
  import { SharedRulePermissions } from "../lib/interfaces/SharedRule.interface";
  import {
    appendMissingRuleSets,
    isParentConditionalRule,
  } from "../lib/utils/RuleUtils";
  import { convertBuilderToBoolean } from "../ruleBuilderConverter";
  import {
    currentUser,
    disabledOrganisationModules,
    org_id,
    org_name,
    user_teams,
    org_labels
  } from "../stores";
  import { buildExamples, buildSearchSources } from "./../helpers.js";
  import { RuleSearchType } from "./../lib/interfaces/Rule.interface.js";
  import { getTextConstant } from "../lib/utils/ConstantsUtils";

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");
  const ruleScopeTabs = [
    { desc: "Rules you have created and own.", type: RuleScope.MY },
    { desc: "Checklists shared with you.", type: RuleScope.SHARED },
    {
      desc: "Haast created checklists, which you can use as a starting point.",
      type: RuleScope.INDUSTRY,
    },
  ];
  const ruleTypeTabs = [
    {
      label: `${textConstants["AssetReview"]} Rules`,
      icon: "iconoir:clipboard-check",
    },
    { label: "Live Rules", icon: "iconoir:page" },
  ];

  let allRules = [];
  let ruleSetSearch = "";
  let ruleSets = [];
  let selectableRuleSets = [];
  let sharing = false;
  let selectedRules = [];
  let sharedWith = [];
  let showEmptyChecklists = false;
  let selectedRuleSets = new Set(); // Use a Set to store selected rule set IDs
  let newRule;
  let newRuleStep = 1;
  let checklistName = "";
  let checklistDescription = "";
  let activeRuleTypeTab = `${textConstants["AssetReview"]} Rules`;
  let activeRuleScopeTab = RuleScope.MY;
  let d_types = [];
  let willModalClose = true;
  let saveAndCreateMode = false;

  const addRuleSetAssociation = (ruleSetId) => {
    selectedRuleSets.add(ruleSetId);
    selectedRuleSets = selectedRuleSets;
    // Optionally, update server here or defer until rule save
  };

  const removeRuleSetAssociation = (ruleSetId) => {
    selectedRuleSets.delete(ruleSetId);
    selectedRuleSets = selectedRuleSets;
  };

  const createRuleSet = async () => {
    const res = await fetchPost("/ruleset/create", {
      name: ruleSetSearch,
      description: "",
      share: sharing,
    });

    if (!res.success) {
      toast.error("Failed to create Checklist");
      return;
    }

    toast.success("Checklist created successfully");
    await updateRuleSets();
    ruleSetSearch = "";
  };

  const updateRuleSets = async () => {
    const res = await fetchGet("/ruleset");
    ruleSets = [...appendMissingRuleSets(res.rule_sets, allRules)];
    sharedWith = [...res?.shared_with];
  };

  const updateRules = async () => {
    const queryParams = new URLSearchParams({
      type: "saved",
    }).toString();
    const res = await fetchGet(`/rule?${queryParams}`);
    allRules = res.rules;

    if (allRules?.length > 0) {
      allRules.forEach((r) => {
        r.labels = [];
        r.label_ids.forEach((lid) =>
          r.labels.push($org_labels.find((label) => label.id === lid)),
        );
      });
    }

    await updateRuleSets();
  };

  const saveNewRule = async (type) => {
    let res;

    newRule.type = type;

    if (type === RuleType.LIVE) {
      res = await saveLiveRule(newRule, $org_id, true);
    } else if (type === RuleType.ASSET) {
      newRule.boolean = newRule.queryBoolean
        ? newRule.queryBoolean
        : newRule.boolean;
      res = await saveAssetRule(newRule, $org_id, true);
    }

    if (!res.success) {
      toast.error("Failed to save rule");
      return;
    }

    if (res.success) {
      for (let ruleSetId of selectedRuleSets) {
        // Make an API request to add the rule to each rule set
        const response = await fetchPost("/ruleset/add-to-ruleset", {
          rule_set_id: ruleSetId,
          rule_id: res.rule.id,
        });

        // Handle potential errors in adding to rule sets
        if (!response.success) {
          toast.error(`Failed to add to checklist ${ruleSetId}`);
          // Optionally, you might want to break here or handle this error differently
        }
      }
      toast.success("Checklists saved successfully");
      await updateRules();

      newRuleStep = 1;
      newRule = undefined;

      if (!willModalClose) {
        return;
      }

      if (type === RuleType.LIVE) {
        createLiveRuleModal.close();
      } else if (type === RuleType.ASSET) {
        createAssetRuleModal.close();
      }
    } else {
      toast.error("Failed to save rule");
    }
  };

  const updateDataTypes = async () => {
    const res = await fetchGet("/pool");
    d_types = await res.data;

    for (let i = 0; i < d_types.length; i++)
      d_types[i].label = tidyPoolLabel(d_types[i].label);
  };

  function updateSelectableRuleSets(rule) {
    selectableRuleSets = [
      ...ruleSets.filter(
        (rs) =>
          (rs.owner === $currentUser.id ||
            sharedWith.some(
              (sr) =>
                sr.parent_rule_set_id === rs?.id &&
                (!!$user_teams.find((ut) => ut.id === sr.team_id) ||
                  sr.user_id === $currentUser.id) &&
                sr.permission === SharedRulePermissions.EDIT,
            )) &&
          !rs?.rules?.includes(rule?.id) &&
          rs?.report_id === $org_id,
      ),
    ];
  }

  let loaded = false;
  onMount(async () => {
    updateDataTypes();
    await updateRuleSets();

    await updateRules();

    loaded = true;

    if (allRules.length === 0 && ruleSets.length === 0)
      document.querySelector("#noRulesModal")?.showModal();
  });

  $: updateSelectableRuleSets(), [ruleSets];

  const createLiveRule = () => {
    newRule = {
      id: -1,
      loaded: false,
      index: -1,
      name: "",
      type: RuleType.LIVE,
      description: "",
      data_types: [],
      size: 10000,
      boolean: 'text:("Haast" OR "Haaast")',
      builder: [],
    };
    createLiveRuleModal.showModal();
  };

  function removeRule() {
    newRule = undefined;
    newRuleStep = 1;
    saveAndCreateMode = false;
    willModalClose = true;
    selectedRuleSets = new Set();
    createAssetRuleModal.close();
  }

  const createAssetRule = () => {
    newRule = {
      id: -1,
      name: "",
      description: "",
      examples: [{ text: "", score: 1 }],
      operator: "OR",
      doc_query: "",
      boolean: "",
      section_query: "",
      affirmative_rule: true,
      limit: 5,
      exclusion_weight: 0.3,
      search_sources: {
        DOCUMENT: true,
        IMAGE_TEXT: false,
        IMAGE_FEATURE: false, // Initialize according to your default needs
      },
      index: "vector-db-index",
      strong_match_threshold: 0.95,
      no_match_threshold: 0.92,
      search_type: undefined,
      type: RuleType.ASSET,
      lexical: "",
    };
    if (willModalClose) {
      createAssetRuleModal.showModal();
    }
  };

  let savingConditionalRule = false;
  const handleSaveConditionalRule = async (event) => {
    savingConditionalRule = true;

    try {
      const conditionalRule = event.detail;

      const rulesWithExamples = [
        conditionalRule.ifRule,
        ...conditionalRule.thenRules,
      ].map((r) => ({
        ...r,
        examples: buildExamples(r),
        search_sources: buildSearchSources(r.search_sources),
        rule_type: "asset",
        report_id: $org_id,
      }));

      const saveRuleResponse = await fetchPost("/rule", {
        rules: rulesWithExamples,
        report_id: $org_id,
      });

      if (!saveRuleResponse.success) {
        const errMsg = "Something went wrong saving the rule.";
        toast.error(errMsg);

        throw new Error(errMsg);
      }

      const ifRule = saveRuleResponse.rules.find((r) =>
        isParentConditionalRule(r),
      );

      for (let ruleSetId of selectedRuleSets) {
        const response = await fetchPost("/ruleset/add-to-ruleset", {
          rule_set_id: ruleSetId,
          rule_id: ifRule.id,
        });

        if (!response.success) {
          const errMsg = `Failed to add to checklist ${ruleSetId}.`;
          toast.error(errMsg);

          throw new Error(errMsg);
        }
      }

      toast.success("Checklists saved successfully");
      await updateRules();
    } catch (e) {
      console.error(e);
    }

    savingConditionalRule = false;
    createAssetRuleModal.close();
    newRule = undefined;
    newRuleStep = 1;
  };
</script>

<svelte:head>
  <title>Saved Rules - Haast</title>
</svelte:head>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore missing-declaration -->

<header class="bg-base-content/[5%] -mx-12 -my-4 mb-4 px-12 py-4 pb-0">
  <!-- extra container required for max-w-screen-xl but full background -->
  <div class="mx-auto flex max-w-screen-xl flex-col gap-8">
    <div class="breadcrumbs text-sm">
      <ul>
        <li>
          <a href="/rules" use:link>Saved Rules</a>
        </li>
        <li>{activeRuleTypeTab}</li>
      </ul>
    </div>

    <div class="flex items-center gap-4">
      <h1 class="text-3xl font-semibold">Saved Rules</h1>

      <div class="dropdown dropdown-end ml-auto">
        <div tabindex="0" role="button" class="btn btn-primary btn-sm">
          + New
        </div>
        <ul
          tabindex="0"
          class="menu dropdown-content bg-base-100 z-[1] mt-1 w-full min-w-max rounded border p-2 shadow"
        >
          <li>
            <button on:click={createLiveRule}>Live Rule</button>
          </li>
          <li>
            <button on:click={createAssetRule}
              >{textConstants["Asset"]} Rule</button
            >
          </li>
          <li>
            <button on:click={() => newChecklistModal?.showModal()}>
              Checklist
            </button>
          </li>
          {#if $disabledOrganisationModules.every((dm) => dm !== "generate_checklist")}
            <li>
              <button on:click={() => aiGenChecklistModal?.showModal()}>
                AI-Generated Checklist
              </button>
            </li>
          {/if}
        </ul>
      </div>
    </div>

    <div class="tabs relative">
      {#each ruleTypeTabs as item}
        <button
          class="tab whitespace-nowrap text-base font-medium"
          class:text-primary={activeRuleTypeTab === item.label}
          on:click={() => (activeRuleTypeTab = item.label)}
        >
          <Icon icon={item.icon} class="mr-1" />
          {item.label}
        </button>
      {/each}
      <div
        class="slider bg-primary absolute bottom-0 left-0 h-0.5 transition-all"
        style:width="{100 / ruleTypeTabs.length}%"
        style="translate: {ruleTypeTabs.findIndex(
          (i) => i.label === activeRuleTypeTab,
        ) * 100}%"
      />
    </div>
  </div>
</header>

<div class="mx-auto max-w-screen-xl">
  <div class="tabs-boxed tabs mb-4 border">
    {#each ruleScopeTabs as e}
      <button
        class="tab tooltip"
        data-tip={e.desc}
        class:tab-active={activeRuleScopeTab === e.type}
        on:click={() => (activeRuleScopeTab = e.type)}
      >
        <span class="tooltip" data-tip={e.desc}>
          {e.type === RuleScope.SHARED
            ? `Checklists ${e.type}`
            : `${e.type} Rules`}
        </span>
      </button>
    {/each}
  </div>

  {#if loaded}
    {#key (activeRuleTypeTab, activeRuleScopeTab)}
      <div in:fly={{ y: 50 }}>
        <SavedRulesTable
          ruleScope={activeRuleScopeTab}
          rules={allRules?.filter((r) => {
            if (activeRuleTypeTab === "Live Rules")
              return (
                r.rule_type === "opensearch" || r.rule_type === RuleType.LIVE
              );
            else if (
              activeRuleTypeTab === `${textConstants["AssetReview"]} Rules`
            )
              return (
                r.rule_type !== "opensearch" || r.rule_type === RuleType.ASSET
              );
          }) || []}
          bind:sharing
          assetRulesTable={activeRuleTypeTab === "Live Rules" ? false : true}
          bind:selectedRules
          bind:ruleSets
          bind:sharedWith
          bind:showEmptyChecklists
          on:updateRules={updateRules}
        />
      </div>
    {/key}
  {:else}
    <div class="rounded border p-4">
      <LogoLoader size="5rem" class="mx-auto" />
    </div>
  {/if}
</div>

<div class="-mx-12 -my-4 mb-4 px-12 py-4 pb-0">
  <div class="mx-auto flex max-w-screen-xl flex-col justify-end gap-8">
    <button
      class="btn btn-ghost btn-sm ml-auto mt-2 w-48"
      on:click={() => (showEmptyChecklists = !showEmptyChecklists)}
    >
      {showEmptyChecklists ? "Hide" : "Show"} Empty Checklists
    </button>
  </div>
</div>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="noRulesModal"
  message="It looks like you don't have any checklists. Would you like to create one?"
  onConfirm={() => {
    newChecklistModal?.showModal();
    noRulesModal.close();
  }}
  onCancel={() => noRulesModal.close()}
/>

<NewChecklistModal
  bind:ruleSets
  bind:activeRuleTypeTab
  bind:checklistName
  bind:checklistDescription
  bind:sharing
  on:updateRuleSets={updateRuleSets}
/>

<AiGenChecklistModal
  on:updateRules={async () => {
    await updateRuleSets();
    await updateRules();
  }}
/>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-label-has-associated-control -->

<Modal
  modalId="createLiveRuleModal"
  size="xl"
  on:close={() => {
    newRule = undefined;
    newRuleStep = 1;
  }}
>
  <h3 class="text-lg font-bold">Create Live Rule</h3>
  <div class="mt-2 flex flex-wrap gap-2">
    {#each ruleSets as ruleSet}
      {#if selectedRuleSets.has(ruleSet.id)}
        <button
          class="btn btn-outline btn-primary btn-sm"
          on:click={() => {
            removeRuleSetAssociation(ruleSet.id);
            updateSelectableRuleSets(newRule);
          }}
        >
          {ruleSet.name}
          <div class="bg-base-100 rounded-full p-1">
            <Icon icon="ic:baseline-close" class="h-4 w-4 text-black" />
          </div>
        </button>
      {/if}
    {/each}
    <div class="dropdown">
      <label tabindex="0" class="btn btn-primary btn-sm"> Add Checklist </label>
      <div
        tabindex="0"
        class="dropdown-content bg-base-100 z-[1] w-52 rounded border p-2 shadow"
      >
        <input
          type="text"
          placeholder="Search checklists..."
          class="input input-sm input-bordered mb-2 w-full"
          bind:value={ruleSetSearch}
        />

        <ul class="menu max-h-80 flex-nowrap overflow-auto p-0">
          {#each selectableRuleSets as ruleSet}
            {#if ruleSet.name
              .toLowerCase()
              .includes(ruleSetSearch.toLowerCase()) && !selectedRuleSets.has(ruleSet.id)}
              <li>
                <button
                  on:click={() => {
                    addRuleSetAssociation(ruleSet.id);
                    updateSelectableRuleSets(newRule);
                  }}
                >
                  {ruleSet.name}
                </button>
              </li>
            {/if}
          {/each}
          {#if ruleSets.filter((rs) => rs.name
              .toLowerCase()
              .includes(ruleSetSearch.toLowerCase())).length === 0 && ruleSetSearch.trim() !== ""}
            <li>
              <button
                on:click={async () => {
                  await createRuleSet();
                }}
              >
                Create: {ruleSetSearch}
              </button>
            </li>
          {/if}
        </ul>
      </div>
    </div>
  </div>

  {#if newRule && d_types.length}
    <!-- svelte-ignore missing-declaration -->
    <Rule
      index={newRule.index}
      bind:data={newRule}
      isMyRulesPage={true}
      {d_types}
      runRule={async () => {
        newRule.boolean = convertBuilderToBoolean(newRule.builder);
        await saveNewRule(RuleType.LIVE);
        selectedRuleSets = new Set();
      }}
      deleteRule={() => {
        newRule = undefined;
        newRuleStep = 1;
        createLiveRuleModal.close();
      }}
      on:saveRule={() => console.log("do nothing for now")}
      editing={false}
      bind:defaultRulePriority={newRule.default_hit_priority}
    />
  {:else}
    <p>
      Your administrator has not added any live channels to your organisation.
      Please contact support@haast.io to configure this.
    </p>
  {/if}
</Modal>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-label-has-associated-control -->

<Modal modalId="createAssetRuleModal" size="xl" on:close={removeRule}>
  <h3 class="text-lg font-bold">Create {textConstants["Asset"]} Rule</h3>

  {#if newRule}
    {#if newRuleStep === 1}
      <AssetRuleTypeSelector
        bind:value={newRule.search_type}
        bind:rule={newRule}
      />

      {#if newRule.search_type}
        <button
          on:click={() => (newRuleStep = 2)}
          class="btn btn-primary btn-sm float-right mt-2"
        >
          Select
        </button>
      {:else}
        <div
          class="tooltip tooltip-left float-right"
          data-tip="You need to select a rule type to proceed."
        >
          <button
            on:click={() => (newRuleStep = 2)}
            class="btn btn-primary btn-sm float-right mt-2"
            disabled
          >
            Select
          </button>
        </div>
      {/if}
    {:else if newRuleStep === 2}
      <div class="mt-2 flex flex-wrap gap-2">
        {#each ruleSets as ruleSet}
          {#if selectedRuleSets.has(ruleSet.id)}
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <button
              class="btn btn-outline btn-primary btn-sm"
              on:click={() => {
                removeRuleSetAssociation(ruleSet.id);
                updateSelectableRuleSets(newRule);
              }}
            >
              {ruleSet.name}
              <div class="bg-base-100 rounded-full p-1">
                <Icon icon="ic:baseline-close" class="h-4 w-4 text-black" />
              </div>
            </button>
          {/if}
        {/each}
        <div
          class={`${selectedRuleSets.size >= 1 ? "tooltip" : ""}`}
          data-tip="A rule can only be assigned to one checklist."
        >
          <div class="dropdown">
            <label tabindex="0" class="btn btn-primary btn-sm">
              Add Checklists
            </label>
            <div
              tabindex="0"
              class="dropdown-content bg-base-100 z-[1] w-52 rounded border p-2 shadow"
            >
              <input
                type="text"
                placeholder="Search checklists..."
                class="input input-sm input-bordered mb-2 w-full"
                disabled={selectedRuleSets.size >= 1}
                bind:value={ruleSetSearch}
              />

              <ul class="menu max-h-80 flex-nowrap overflow-auto p-0">
                {#each selectableRuleSets as ruleSet}
                  {#if ruleSet.name
                    .toLowerCase()
                    .includes(ruleSetSearch.toLowerCase()) && !selectedRuleSets.has(ruleSet.id)}
                    <li>
                      <button
                        disabled={selectedRuleSets.size >= 1}
                        on:click={() => {
                          addRuleSetAssociation(ruleSet.id);
                          updateSelectableRuleSets(newRule);
                        }}
                      >
                        {ruleSet.name}
                      </button>
                    </li>
                  {/if}
                {/each}
                {#if ruleSets.filter((rs) => rs.name
                    .toLowerCase()
                    .includes(ruleSetSearch.toLowerCase())).length === 0 && ruleSetSearch.trim() !== ""}
                  <li>
                    <button
                      disabled={selectedRuleSets.size >= 1}
                      on:click={async () => {
                        await createRuleSet();
                      }}
                    >
                      Create: {ruleSetSearch}
                    </button>
                  </li>
                {/if}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {#if newRule?.search_type === RuleSearchType.CONDITIONAL}
        <div class="mt-2">
          <ConditionalAssetRuleBuilder
            on:removeRule={removeRule}
            on:saveConditionalRule={handleSaveConditionalRule}
            loading={savingConditionalRule}
          />
        </div>
      {:else}
        <!-- svelte-ignore missing-declaration -->
        <AssetRuleBuilder
          saveAndCreateButton={true}
          bind:saveAndCreateMode
          bind:rule={newRule}
          on:saveRule={async (e) => {
            willModalClose = !saveAndCreateMode; // This will determine if it's a save or a save and create asset
            await saveNewRule(RuleType.ASSET);
            selectedRuleSets = new Set();

            updateRuleSets();
            if (!willModalClose) {
              createAssetRule();
            }
          }}
          on:changeRuleType={() => confirmNewRuleTypeChange?.showModal()}
        />

        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <!-- svelte-ignore missing-declaration -->
        <Modal
          modalId="confirmNewRuleTypeChange"
          bottomButtons={{
            show: true,
            primaryText: "Switch rule type",
            secondaryText: "Cancel",
            primaryAction: () => {
              newRuleStep = 1;
              confirmNewRuleTypeChange.close();
            },
            secondaryAction: () => confirmNewRuleTypeChange.close(),
          }}
          containerClasses="flex flex-col gap-4 items-center"
          cornerCloseButton={false}
        >
          <span
            class="bg-error flex h-12 w-12 items-center justify-center rounded-full"
          >
            <Icon icon="iconoir:warning-triangle" class="text-xl text-white" />
          </span>

          <h3 class="font-semibold">
            Are you sure you want to switch rule type?
          </h3>
          <p class="text-base-content/70 text-center text-sm">
            If you switch rule type,
            <span class="font-semibold">you will lose your progress</span>
            with the current rule type.
          </p>
        </Modal>
      {/if}
    {/if}
  {/if}
</Modal>
