<script>
  import Icon from "@iconify/svelte";
  import NProgress from "nprogress";
  import { onDestroy, onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { link, push } from "svelte-spa-router";
  import ActionConfirmation from "../components/ActionConfirmation.svelte";
  import LabelManager from "../components/Labels/LabelManager.svelte";
  import LogoLoader from "../components/LogoLoader.svelte";
  import ReportHistory from "../components/ReportHistory.svelte";
  import ReportAccess from "../components/Reports/ReportAccess.svelte";
  import ReportSubscribeButton from "../components/Reports/ReportSubscribeButton.svelte";
  import { groupDataByGroupId } from "../components/TableViews/GroupedTable/grouped";
  import GroupedTable from "../components/TableViews/GroupedTable/GroupedTable.svelte";
  import Table from "../components/TableViews/Table.svelte";
  import TableFilterSidebar from "../components/TableViews/TableFilterSidebar.svelte";
  import TableLoading from "../components/TableViews/TableLoading.svelte";
  import { fetchGet, fetchPatch, generate32BitInteger } from "../helpers";
  import {
    actions,
    canEditCurrentStream,
    currentUser,
    isAuthenticated,
    org_labels,
    org_id,
    org_name,
  } from "../stores";
  import "./nprogress.css";
  import {
    createReportClone,
    deleteLabel,
    deleteStream,
    labelSelected,
    requestScrape,
    saveDetails,
  } from "./reportFunctions";
  import { ReportStatus } from "../lib/interfaces/Report.interface";
  import { calculatePercentage } from "../lib/utils/GenericUtils";
  import { getTextConstant } from "../lib/utils/ConstantsUtils";

  export let params = null;

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");

  let reportDetails = {
    name: "",
    status: "Draft",
    description: "",
    report_labels: [],
  };

  let reportData = {
    tableData: [],
    hitCountInRule: {},
    allRules: [],
  };
  let totalHits;

  let forceStateUpdate = 1;

  let report_id;
  let actionTexts = [];

  let loading = { state: false };
  let deletionInProgress = { state: false };
  let isInitialised = { state: false };

  let loadingRefresh = false;
  let confirmRefresh = false;

  let dateToSortByString = "";
  let itemsPerPage = 10;
  let currentPage = 1;
  let openedTab = "Open";

  let showGroupedTable = false;

  let loading_report = true;

  let accessKeyer = false;

  let rows = [];
  let rules = [];
  let unique_vendors = [];
  let unique_indexes = [];
  let unique_data_types = [];
  let selectedDataTypes = [];
  let selectedVendors = [];
  let selectedRulesId = [];
  let selectedLabels =[];
  let selectedSocialTypes = [];
  let columnToSortBy = "";
  let selectedHits = new Set();
  let pageResetTriggered = false;
  let hitSearchText = "";
  let sourceSearchText = "";
  let hitSearchSettings = {
    isCaseSensitive: { enabled: false, label: "Case sensitive" },
  };
  let sourceSearchSettings = {
    isCaseSensitive: { enabled: false, label: "Case sensitive" },
  };
  let rulesHitsStats = new Map();
  let reportStatus = {
    status: "",
    liveRuleStatuses: {
      ready: 0,
      processing: 0,
      failed: 0,
      total: 0,
    },
    percentageProgress: 0,
  };
  let reportStatusInterval;

  /**
   * @todo [LOW PRIORITY] Need to refactor the selected... objects in downstream components to use
   * this `reportFilters` object rather than leaving them separated.
   */
  let reportFilters = {
    in_report: "Open",
    sort: "hit_id",
    hitSearchText: hitSearchText,
    isHitSearchCaseSensitive: hitSearchSettings.isCaseSensitive.enabled,
    sourceSearchText: sourceSearchText,
    isSourceSearchCaseSensitive: sourceSearchSettings.isCaseSensitive.enabled,
    limit: itemsPerPage,
    currentPage: 1,
    selectedDataTypes: [],
    selectedVendors: [],
    selectedRulesId: [],
    selectedSocialTypes: [],
    initial: true,
    columnToSortBy: "",
    showGroupedTable: showGroupedTable,
  };
  let applyFiltersDisabled = true;

  function enableApplyFilters() {
    if (!reportFilters && !loading.state) {
      if (
        reportFilters.selectedDataTypes != selectedDataTypes ||
        reportFilters.selectedVendors != selectedVendors ||
        reportFilters.selectedRulesId != selectedRulesId ||
        reportFilters.selectedSocialTypes != selectedSocialTypes
      ) {
        applyFiltersDisabled = false;
      }
    }
  }

  async function watchVariablesCurrentPage() {
    if (!reportFilters.initial && !loading.state) {
      if (reportFilters.limit != itemsPerPage) {
        currentPage = 1;
      }
      if (
        reportFilters.currentPage != currentPage ||
        reportFilters.limit != itemsPerPage
      ) {
        //||reportFilters.openedTab != openedTab ){ TODO:this shouldn't work but fixes the issue of open not refreshing
        await fetchData();
      }
    }
  }

  async function watchVariables() {
    if (!reportFilters.initial && !loading.state) {
      await fetchData();
    }
  }

  async function watchSortBy() {
    if (!reportFilters.initial && !loading.state) {
      if (reportFilters.columnToSortBy != columnToSortBy) {
        await fetchData();
      }
    }
  }

  async function watchGrouped() {
    if (!reportFilters.initial && !loading.state) {
      if (showGroupedTable) {
        itemsPerPage = 5;
      } else {
        itemsPerPage = 10;
      }
      await fetchData();
    }
  }

  $: watchVariablesCurrentPage(), [currentPage, itemsPerPage];
  $: watchVariables(), [openedTab, forceStateUpdate];
  $: watchGrouped(), [showGroupedTable];
  $: watchSortBy(), [columnToSortBy];

  $: enableApplyFilters(), [selectedDataTypes];
  $: enableApplyFilters(), [selectedVendors];
  $: enableApplyFilters(), [selectedRulesId];
  $: enableApplyFilters(), [selectedSocialTypes];

  // Watch for changes in currentPage and openedTab
  //$: fetchData(), currentPage, openedTab,selectedDataTypes,selectedVendors,selectedRulesId,selectedSocialTypes;
  let statusCounts = { Open: 0, Flagged: 0, Closed: 0, Removed: 0 };

  let currentAbortController;
  let latestFetchDataId = 0;

  async function fetchData() {
    if (currentAbortController) currentAbortController.abort();
    currentAbortController = new AbortController();
    const { signal } = currentAbortController;

    let currentFetchId = latestFetchDataId;
    latestFetchDataId++;

    loading.state = true;
    NProgress.start();

    reportFilters.currentPage = currentPage;
    reportFilters.in_report = openedTab;
    reportFilters.selectedDataTypes = selectedDataTypes;
    reportFilters.selectedVendors = selectedVendors;
    reportFilters.selectedRulesId = selectedRulesId;
    reportFilters.selectedSocialTypes = selectedSocialTypes;
    reportFilters.columnToSortBy = columnToSortBy;
    reportFilters.showGroupedTable = showGroupedTable;
    reportFilters.limit = itemsPerPage;
    reportFilters.hitSearchText = hitSearchText;
    reportFilters.isHitSearchCaseSensitive =
      hitSearchSettings.isCaseSensitive.enabled;
    reportFilters.sourceSearchText = sourceSearchText;
    reportFilters.isSourceSearchCaseSensitive =
      sourceSearchSettings.isCaseSensitive.enabled;

    const queryString = new URLSearchParams(reportFilters).toString();

    try {
      const response = await fetchGet(
        `/report/${params.report_id}?${queryString}`,
      );

      if (
        response.success &&
        response.report.status === ReportStatus.DELETING
      ) {
        toast.error(
          `Cannot view Report(${response.report.name}) as it is currently being deleted.`,
        );
        push("/streams");

        return;
      } else if (response.report.status === ReportStatus.SEARCHING) {
        return;
      } else {
        if (currentFetchId === latestFetchDataId - 1) {
          reportData.tableData = await response.data;
          reportData.tableData = reportData.tableData;
          rows = await response.data;
          rows = rows;
          statusCounts = await response.status_counts;
          loading.state = false;

          // Recursive pagination fallback if there are no results (rows), this should only _ever_ reach a depth of one (1).
          if (rows.length <= 0 && currentPage > 1 && !pageResetTriggered) {
            currentPage = 1;
            pageResetTriggered = true;
            await fetchData();
            pageResetTriggered = false;
          }
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Fetch error:", error);
      }
    } finally {
      NProgress.done();
    }
  }

  async function fetchDataAndUpdateDisplayWrapper(shouldReset = false) {
    NProgress.start();
    loading.state = true;
    const res = await fetchGet(`/report/details/${report_id}`);

    if (res.success === false) {
      if (res.message === "Unauthorized") logoutUser();
      else {
        toast.error(res.message);
        push("/streams");
      }

      return;
    } else if (res.success && res.report.status === ReportStatus.DELETING) {
      toast.error(
        `Cannot view Report(${res.report.name}) as it is currently being deleted.`,
      );
      push("/streams");

      return;
    }

    reportDetails.name =
      res.report.name ||
      (isAsset
        ? `Untitled ${textConstants["Folder"]}`
        : `Untitled ${textConstants["Stream"]}`);
    reportDetails.status = res.report.status || "Draft";
    reportDetails.description = res.report.description || "";
    reportDetails.report_labels = res.report.label_ids || [];
    reportDetails = reportDetails;

    reportFilters.currentPage = currentPage;
    reportFilters.openedTab = openedTab;
    reportFilters.initial = shouldReset;

    if (res.report.status === ReportStatus.SEARCHING) {
      return;
    }

    const queryString = new URLSearchParams(reportFilters).toString();
    const response = await fetchGet(
      `/report/${params.report_id}?${queryString}`,
    );

    reportData.tableData = await response.data;
    reportData.tableData = reportData.tableData;
    rows = await response.data;
    rows = rows;
    originalData = rows;
    originalData = originalData;
    statusCounts = await response.status_counts;
    rules = await response.rules;
    unique_vendors = await response.unique_vendors;
    unique_indexes = await response.unique_indexes;
    unique_data_types = await response.unique_data_types;

    selectedDataTypes = await unique_data_types;
    selectedVendors = await unique_vendors;
    selectedRulesId = await rules.map((r) => r.id);
    selectedSocialTypes = await unique_indexes;
    reportFilters.selectedDataTypes = await selectedDataTypes;
    reportFilters.selectedVendors = await selectedVendors;
    reportFilters.selectedRulesId = await selectedRulesId;
    reportFilters.selectedSocialTypes = await selectedSocialTypes;
    reportFilters.initial = false;
    loading.state = false;

    /**
     * Recursive pagination fallback if there are no results (rows), this
     * should only _ever_ reach a depth of one (1).
     */
    if (rows.length <= 0 && currentPage > 1 && !pageResetTriggered) {
      currentPage = 1;
      pageResetTriggered = true;
      await fetchData();
      pageResetTriggered = false;
    }

    NProgress.done();
  }

  async function refreshRules() {
    let hitCount = 0;

    if (confirmRefresh) {
      loadingRefresh = true;
      reportDetails.status = ReportStatus.SEARCHING;
      reportDetails = reportDetails;

      try {
        const refreshPromises = Array.from(rules.values()).map((rule) => {
          return fetchPatch(`/rule/run/${rule.id}`, {
            ...rule,
            remediation_step: "",
          });
        });

        await Promise.all(refreshPromises);
        await fetchDataAndUpdateDisplayWrapper(true);
        toast.success(
          `Successfully sent refresh rules job for Report(${reportDetails.name}).`,
        );
      } catch (error) {
        toast.error("Something went wrong while refreshing your rules.");
        console.error("Error during rule refresh:", error);
      } finally {
        loadingRefresh = false;
      }
    } else {
      refresh_confirm_modal.showModal();
    }
  }

  const logoutUser = async () => {
    $isAuthenticated = false;
    localStorage.setItem("logged_in", false);
    localStorage.setItem("accessToken", "");
    await fetchGet(`/auth/logout`);
    push("/home");
  };

  function refreshTable(event) {
    rows = event.detail;
    forceStateUpdate = forceStateUpdate + 1;
  }

  NProgress.configure({
    // Full list: https://github.com/rstacruz/nprogress#configuration
    minimum: 0.16,
    showSpinner: false,
  });

  async function updateReportStatus(skipCheck = false) {
    if (
      !skipCheck &&
      (!reportDetails ||
        [ReportStatus.FAILED, ReportStatus.LIVE].includes(reportDetails.status))
    ) {
      return;
    }

    const errMessage = `Something went wrong polling for updates for Report(ID: ${report_id}).`;

    try {
      const statusResponse = await fetchGet(`/report/${report_id}/status`);

      if (!statusResponse.success) {
        console.error(statusResponse?.message);
        throw new Error(errMessage);
      }

      if (statusResponse.status === ReportStatus.FAILED) {
        reportDetails.status = statusResponse.status;
        reportDetails = reportDetails;

        return;
      }

      if (
        NProgress.isStarted() &&
        statusResponse.live_rule_statuses.ready >
          reportStatus.liveRuleStatuses.ready
      ) {
        NProgress.inc();
      } else {
        NProgress.start();
      }

      reportStatus = {
        status: statusResponse.status,
        liveRuleStatuses: statusResponse.live_rule_statuses,
        percentageProgress:
          statusResponse.status === ReportStatus.LIVE
            ? 100
            : calculatePercentage(
                statusResponse.live_rule_statuses?.ready,
                statusResponse.live_rule_statuses?.total,
              ),
      };
      reportStatus = reportStatus;

      if (reportDetails.status !== reportStatus.status) {
        reportDetails.status = reportStatus.status;
        reportDetails = reportDetails;

        if (reportStatus.status === ReportStatus.LIVE) {
          NProgress.done();
          await fetchDataAndUpdateDisplayWrapper(true);
          await tick();
          toast.info(`Report(${reportDetails.name}) is ready!`);
        } else {
          NProgress;
        }
      }
    } catch (e) {
      console.error(e);
      toast.error(errMessage);
    }
  }

  onMount(async () => {
    loading_report = true;

    report_id = params.report_id ? params.report_id : generate32BitInteger();

    actionTexts = $actions.map(function (elem) {
      return elem.action_text;
    });
    // With:
    await fetchDataAndUpdateDisplayWrapper(true);
    // Set selected labels based on report's labels
    selectedLabels = $org_labels.filter(label =>reportDetails.report_labels.includes(label.id));

    if ($currentUser.role == "admin") {
      $canEditCurrentStream = true;
    } else {
      const response = await fetchGet(
        `/report/${report_id}/get-user-permission-to-report`,
      );
      const orgPermissionsResponse = await fetchGet(
        `/report/${report_id}/get-org-permission`,
      );

      if (!response.permission || response.permission === "none") {
        if (
          !orgPermissionsResponse.permission ||
          orgPermissionsResponse.permission === "none"
        )
          logoutUser();
      }

      if (
        response.permission === "edit" ||
        orgPermissionsResponse.permission === "edit"
      )
        $canEditCurrentStream = true;
      else $canEditCurrentStream = false;
    }
    await updateReportStatus(true);

    loading_report = false;
    isInitialised = true;
  });

  onDestroy(() => {
    if (reportStatusInterval) {
      clearInterval(reportStatusInterval);
    }
  });

  let selectedGroups = new Set();
  let originalData = rows;

  // moving grouped rules table filtering sidebar here
  function getAllRules() {
    let output = {};
    rules.forEach((rule) => (output[rule.id] = rule));
    return Object.values(output);
  }
  let allRules = getAllRules();

  let shouldSearch = true;

  let groupedData = groupDataByGroupId(rows, openedTab);

  $: if (shouldSearch) {
    fetchData().then(() => {
      groupedData = groupDataByGroupId(originalData, openedTab);
      groupedData = [...groupedData];
      shouldSearch = false;
    });
  }

  const allTabs = ["Open", "Flagged", "Closed", "Removed"];

  let updatedOpenedTab = false;

  reportStatusInterval = setInterval(updateReportStatus, 10000);
</script>

<svelte:head>
  <title>Stream - Haast</title>
</svelte:head>

<header class="bg-base-content/[5%] -mx-12 -my-4 mb-4 px-12 py-4">
  <!-- extra contianer required for max-w-screen-xl but full background -->
  <div class="mx-auto max-w-[1706px] 2xl:pl-[13.5rem]">
    <div class="breadcrumbs text-sm">
      <ul>
        <li>
          <a href="/streams" use:link>Streams</a>
        </li>
        <li>{reportDetails.name}</li>
      </ul>
    </div>

    <div class="flex items-center gap-4">
      <div class="tooltip min-w-0" data-tip={reportDetails.name}>
        <h1 class="truncate text-3xl font-semibold">
          {reportDetails.name}
        </h1>
      </div>

      {#if $canEditCurrentStream}
        <div class="dropdown">
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <label tabindex="0" class="btn btn-square btn-ghost btn-sm" for="">
            <Icon icon="iconoir:more-horiz" class="text-xl" />
          </label>
          <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
          <ul
            tabindex="0"
            class="menu dropdown-content bg-base-100 z-20 m-1 w-52 rounded border p-2"
          >
            <li class:disabled={reportDetails.status === "Archived"}>
              <button
                on:click={() => {
                  reportDetails.status = "Draft";
                  saveDetails(
                    report_id,
                    reportDetails,
                    isInitialised,
                    $currentUser.id,
                  );
                  push(`/stream/edit/${report_id}`);
                }}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="iconoir:edit-pencil" class="text-xl" /> Edit Stream
              </button>
            </li>
            <li class:disabled={reportDetails.status === "Archived"}>
              <button
                on:click={() => createReportClone(report_id)}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="iconoir:copy" class="text-xl" /> Duplicate Stream
              </button>
            </li>
            <li class:disabled={reportDetails.status === "Archived"}>
              <!-- svelte-ignore missing-declaration -->
              <button
                on:click={() => deletionConfirmationModal.showModal()}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="iconoir:trash" class="text-xl" /> Delete Stream
              </button>
            </li>
            <li class:disabled={reportDetails.status === "Archived"}>
              <button
                on:click={() =>
                  document.querySelector("#report-history-dialog")?.showModal()}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="iconoir:open-book" class="text-xl" /> Stream History
              </button>
            </li>

            {#each [{ label: "Draft", value: "Draft", icon: "page-edit" }, { label: "Live", value: "Live", icon: "megaphone" }, { label: "Archive", value: "Archived", icon: "archive" }] as e}
              <li class:hidden={reportDetails.status === e.value}>
                <button
                  on:click={() => {
                    reportDetails.status = e.value;
                    saveDetails(
                      report_id,
                      reportDetails,
                      isInitialised,
                      $currentUser.id,
                    );
                  }}
                >
                  <Icon icon="iconoir:{e.icon}" class="text-xl" />
                  {e.label} Stream
                </button>
              </li>
            {/each}

            <li class:disabled={reportDetails.status === "Archived"}>
              <button
                on:click={() => requestScrape(report_id)}
                disabled={reportDetails.status === "Archived"}
              >
                <Icon icon="iconoir:refresh-double" class="text-xl" />
                Request Rescrape
              </button>
            </li>
          </ul>
        </div>
      {/if}

      <div class="ml-auto flex items-center gap-2">
        <ReportAccess
          reportStatus={reportDetails.status}
          bind:loading={accessKeyer}
          disabled={reportDetails.status === "Archived"}
          canEditPermissions={$canEditCurrentStream}
        />

        {#if report_id}
          <ReportSubscribeButton
            {report_id}
            canSubscribe={reportDetails.status === "Live"}
          />
        {/if}
      </div>
    </div>

    <p class="text-base-content/70 font-light">
      {#if reportDetails.description}
        {reportDetails.description.trim() === ""
          ? ""
          : reportDetails.description}
      {/if}
    </p>

    <div class="mt-4 flex items-center gap-4">
      <div class="z-10 flex flex-wrap items-center gap-2">
        <div
          class={reportDetails.status === "Archived" || !$canEditCurrentStream
            ? "tooltip"
            : ""}
          data-tip={reportDetails.status === "Archived"
            ? "This report has been archived and cannot be modified"
            : !$canEditCurrentStream
              ? "You do not have permission to modify this report"
              : ""}
        >
          <LabelManager
            bind:selectedLabels
            reportId={report_id}
            disabled={reportDetails.status === "Archived" || !$canEditCurrentStream}
            showSearch={$canEditCurrentStream}
          />
        </div>
      </div>

      <div class="ml-auto flex gap-2">
        <button
          class="btn btn-primary btn-sm"
          disabled={loading.state}
          on:click={() => {
            selectedHits = new Set();
            selectedGroups = new Set();
            showGroupedTable = !showGroupedTable;
          }}
        >
          {showGroupedTable ? "Ungroup" : "Group"} Hits
        </button>
        <div
          class={reportDetails.status === "Archived" || !$canEditCurrentStream
            ? "tooltip"
            : ""}
          data-tip={reportDetails.status === "Archived"
            ? "This report has been archived and cannot be modified"
            : "You do not have permission to modify this report"}
        >
          <button
            class="btn btn-sm"
            on:click={() => {
              reportDetails.status = "Draft";
              saveDetails(
                report_id,
                reportDetails,
                isInitialised,
                $currentUser.id,
              );
              push(`/stream/edit/${report_id}?step=1`);
            }}
            disabled={reportDetails.status === "Archived" ||
              $canEditCurrentStream === false}
          >
            Edit Rules
          </button>
        </div>
        <div
          class={reportDetails.status === "Archived" || !$canEditCurrentStream
            ? "tooltip tooltip-left"
            : ""}
          data-tip={reportDetails.status === "Archived"
            ? "This report has been archived and rules cannot be refreshed"
            : "You do not have permission to refresh rules on this report"}
        >
          <button
            disabled={loadingRefresh ||
              loading.state ||
              reportDetails.status === "Archived" ||
              $canEditCurrentStream === false}
            class="btn btn-primary btn-sm"
            on:click={() => refreshRules()}
          >
            {#if loadingRefresh}
              <LogoLoader size="1.25rem" />
            {:else}
              <Icon icon="iconoir:refresh-double" />
            {/if}
            Refresh Rules
          </button>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- 1706px why? -> max-w-screen-xl = 1280px + 208px (left filtering sidebar) + 1rem (gap) -->
<div class="mx-auto max-w-[1706px]">
  <div class="flex gap-2">
    {#key loading.state}
      <TableFilterSidebar
        {originalData}
        {report_id}
        exportRes={true}
        tableRootPage="reports"
        bind:showGroupedTable
        bind:currentTab={openedTab}
        bind:allRules={rules}
        bind:unique_data_types
        bind:unique_vendors
        bind:unique_indexes
        bind:selectedDataTypes
        bind:selectedVendors
        bind:selectedRulesId
        bind:selectedSocialTypes
        canEditPermissions={$canEditCurrentStream}
        bind:reportFilters
        bind:loading
        bind:rulesHitsStats
        {itemsPerPage}
        on:selectChanged={() => {
          if (showGroupedTable) shouldSearch = true;
          else fetchData();
        }}
        disableEditing={reportDetails.status === "Archived"}
        bind:hitSearchText
        bind:hitSearchSettings
        bind:sourceSearchText
        bind:sourceSearchSettings
        bind:applyFiltersDisabled
        applyingFilters={loading.state}
      />
    {/key}

    <div class="flex w-full flex-col gap-2 overflow-x-auto">
      <div
        class="tabs bg-base-200 relative shrink-0 overflow-hidden rounded border"
      >
        {#each allTabs as tab}
          <button
            class="tab whitespace-nowrap capitalize"
            class:text-primary={openedTab === tab}
            on:click={() => {
              openedTab = tab;
              updatedOpenedTab = tab;
            }}
          >
            {tab} ({statusCounts[tab]})
          </button>
        {/each}
        <div
          class="slider bg-primary absolute bottom-0 left-0 h-0.5 transition-all"
          style:width="{100 / allTabs.length}%"
          style="translate: {allTabs.findIndex((i) => i === openedTab) * 100}%"
        />
      </div>

      {#if loading.state}
        <TableLoading tableRootPage="reports" />
      {:else if showGroupedTable}
        <GroupedTable
          bind:updatedOpenedTab
          bind:groupedData
          bind:data={rows}
          bind:openedTab
          bind:showGroupedTable
          bind:forceStateUpdate
          bind:statusCounts
          bind:rules
          bind:unique_data_types
          bind:unique_vendors
          bind:unique_indexes
          bind:selectedDataTypes
          bind:selectedVendors
          bind:selectedRulesId
          bind:selectedSocialTypes
          bind:selectedHits
          bind:columnToSortBy
          bind:currentPage
          bind:itemsPerPage
          bind:reportFilters
          bind:loading
          bind:rulesHitsStats
          on:refresh={refreshTable}
          tableId={report_id}
          tableRootPage="reports"
          disableEditing={reportDetails.status === "Archived"}
          canEditPermissions={$canEditCurrentStream}
        />
      {:else}
        <Table
          bind:updatedOpenedTab
          bind:showGroupedTable
          bind:dateToSortByString
          bind:currentPage
          bind:loading
          bind:openedTab
          bind:statusCounts
          bind:rules
          bind:unique_data_types
          bind:unique_vendors
          bind:unique_indexes
          bind:selectedDataTypes
          bind:selectedVendors
          bind:selectedRulesId
          bind:selectedSocialTypes
          bind:selectedHits
          bind:columnToSortBy
          bind:forceStateUpdate
          bind:reportFilters
          bind:itemsPerPage
          bind:hitSearchText
          bind:hitSearchSettings
          bind:sourceSearchText
          bind:sourceSearchSettings
          bind:rulesHitsStats
          on:refresh={refreshTable}
          on:close={(e) => {
            if (e.detail) fetchDataAndUpdateDisplayWrapper();
          }}
          bind:data={rows}
          tableType="NONE"
          tableRootPage="reports"
          tableId={report_id}
          disableEditing={reportDetails.status === "Archived" ||
            $canEditCurrentStream === false}
          canEditPermissions={$canEditCurrentStream}
          on:search={fetchData}
        />
      {/if}
    </div>
  </div>
</div>

<!-- modals below -->
<ReportHistory reportId={report_id} />

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="refresh_confirm_modal"
  message="Warning!"
  text="Refreshing rules that generate many hits or search across multiple channels may take some time. Would you like to refresh now?"
  onConfirm={() => {
    confirmRefresh = true;
    refreshRules();
    refresh_confirm_modal.close();
  }}
  onCancel={() => refresh_confirm_modal.close()}
  confirmText="Refresh now"
  cancelText="Back"
/>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="deletionConfirmationModal"
  message="Are you sure you want to delete this stream?"
  onConfirm={async () => {
    deletionInProgress.state = true;
    await deleteStream(report_id, false);
    deletionInProgress.state = false;
    deletionConfirmationModal.close();
  }}
  onCancel={() => deletionConfirmationModal.close()}
  confirmText="Delete"
  cancelText="Cancel"
  showLoadingSpinner={true}
  loading={deletionInProgress.state}
/>
