<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onDestroy } from "svelte";
  import {
    fetchDelete,
    fetchGet,
    fetchPatch,
    getUTCDateString,
  } from "../../../helpers";
  import { canEditCurrentStream, role } from "../../../stores";
  import AddToDictionary from "../../Spelling/AddToDictionary.svelte";

  export let status;
  export let hitId;
  export let disableEditing = false;
  export let originalData = [];
  export let data = [];
  export let tableRootPage;
  export let entry;

  const dispatch = createEventDispatcher();

  const originalStatus = status;

  function updateHitStatusInData(statusLabel) {
    originalData = originalData.map((entry) => {
      if (hitId === hitId) {
        entry.hit.in_report = statusLabel;
        entry.hit.last_updated = getUTCDateString();
      }
      return entry;
    });
  }

  async function updateStatus(statusLabel) {
    if (!($role === "admin" || $role === "user" || $canEditCurrentStream))
      return;

    if (statusLabel === status) return;

    const requestURL = `/hit/${hitId}/status`;

    try {
      status = statusLabel;
      switch (statusLabel) {
        case "Open":
          await fetchGet(requestURL);
          break;

        case "Flagged":
          await fetchPatch(requestURL);
          break;

        case "Closed":
          await fetchDelete(requestURL);
          break;

        default:
          break;
      }
    } catch (error) {
      console.warn("Something went wrong when setting the status.", error);
    }
  }

  onDestroy(() => {
    if (originalStatus !== status) {
      updateHitStatusInData(status);
      data = originalData;
      dispatch("refresh", originalData);
    }
  });
</script>

<button
  class="btn btn-xs"
  class:btn-primary={status === "Open"}
  class:btn-warning={status === "Flagged"}
  class:btn-disabled={disableEditing}
  on:click={() => updateStatus("Flagged")}
>
  <Icon icon="iconoir:dash-flag" /> Flag{status === "Flagged" ? "ged" : ""}
</button>

<button
  class="btn btn-xs hover:btn-error"
  class:btn-error={status === "Closed"}
  class:btn-disabled={disableEditing}
  on:click={() => updateStatus("Closed")}
>
  Close{status === "Closed" ? "d" : ""}
</button>

{#if originalStatus !== status}
  <div
    class="tooltip tooltip-left"
    data-tip="Undo status back to {originalStatus}"
  >
    <button
      class="btn btn-square btn-xs"
      on:click={() => updateStatus(originalStatus)}
    >
      <Icon icon="iconoir:undo" />
    </button>
  </div>
{/if}

{#if tableRootPage == "spelling"}
  <AddToDictionary
    {hitId}
    {entry}
    {originalData}
    on:refresh={() => {
      dispatch("refresh", originalData);
    }}
  />
{/if}

<!-- <button class="btn btn-xs" on:click={() => updateStatus("Open")}> Open </button> -->
