<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../../helpers";
  import { SharedRulePermissions } from "../../lib/interfaces/SharedRule.interface";
  import { currentUser, user_teams } from "../../stores";

  export let ruleSets = [];
  export let selectedRuleSetId = null;
  export let sharedWith = [];

  let selectableRuleSets = [];

  const dispatch = createEventDispatcher();

  let ruleSetSearch = "";

  const createRuleSet = async () => {
    const res = await fetchPost("//ruleset/create", {
      name: ruleSetSearch,
      description: "",
      sharing: false,
    });

    if (!res.success) {
      toast.error("Failed to create Checklist");
      return;
    }

    ruleSetSearch = "";
    toast.success("Checklist created successfully");
    dispatch("updateRuleSets");
  };

  function updateSelectableRuleSets() {
    selectableRuleSets = [
      ...ruleSets.filter(
        (rs) =>
          rs.owner === $currentUser.id ||
          sharedWith.some(
            (sr) =>
              sr.parent_rule_set_id === rs?.id &&
              (!!$user_teams.find((ut) => ut.id === sr.team_id) ||
                sr.user_id === $currentUser.id) &&
              sr.permission === SharedRulePermissions.EDIT,
          ),
      ),
    ];
  }

  $: updateSelectableRuleSets(), [ruleSets];
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-label-has-associated-control -->

<div class="mb-4 mt-6 flex flex-wrap gap-2">
  {#if ruleSets.find((r) => r.id === selectedRuleSetId)}
    {@const rs = ruleSets.find((r) => r.id === selectedRuleSetId)}
    <button
      class="btn btn-outline btn-primary btn-sm"
      on:click={() => (selectedRuleSetId = null)}
    >
      {rs.name}
      <div class="bg-base-100 rounded-full p-1">
        <Icon icon="ic:baseline-close" class="h-4 w-4 text-black" />
      </div>
    </button>
  {/if}

  <div
    class={selectedRuleSetId ? "tooltip" : ""}
    data-tip="A rule can only be assigned to one checklist."
  >
    <div class="dropdown">
      <label
        tabindex="0"
        class="btn {selectedRuleSetId ? 'btn-disabled' : 'btn-primary'} btn-sm"
      >
        Add Checklist
      </label>
      <div
        tabindex="0"
        class="dropdown-content bg-base-100 z-[1] w-52 rounded border p-2 shadow"
      >
        <input
          type="text"
          placeholder="Search checklists..."
          class="input input-sm input-bordered mb-2 w-full"
          disabled={selectedRuleSetId}
          bind:value={ruleSetSearch}
        />

        <ul class="menu max-h-80 flex-nowrap overflow-auto p-0">
          {#each selectableRuleSets as ruleSet}
            {#if ruleSet?.name
              ?.toLowerCase()
              ?.includes(ruleSetSearch.toLowerCase()) && ruleSet.id !== selectedRuleSetId}
              <li>
                <button
                  disabled={selectedRuleSetId}
                  on:click={() => (selectedRuleSetId = ruleSet.id)}
                >
                  {ruleSet.name}
                </button>
              </li>
            {/if}
          {/each}
          {#if selectableRuleSets.filter((rs) => rs.name
              .toLowerCase()
              .includes(ruleSetSearch.toLowerCase())).length === 0 && ruleSetSearch.trim() !== ""}
            <li>
              <button
                on:click={async () => {
                  await createRuleSet();
                }}
              >
                Create: {ruleSetSearch}
              </button>
            </li>
          {/if}
        </ul>
      </div>
    </div>
  </div>
</div>
