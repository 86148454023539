import organisationTexts from "../constants/organisationTexts.json";
import { OrganisationsWithText } from "../constants/organisations";
import { isEmpty } from "./GenericUtils";

function organisationIdToTextKey(orgId = null, orgName = null) {
  const defaultKey = "default";

  if (!orgId) return defaultKey;

  switch (orgId) {
    case 89:
    case 35:
      if (orgName === OrganisationsWithText.ZURICHPDRDEMO)
        return OrganisationsWithText.ZURICHPDRDEMO;

      return defaultKey;
    default:
      return defaultKey;
  }
}

export function getTextConstant(
  orgId = null,
  orgName = null,
  key = "AssetReview",
  field = "",
  defaultText = "",
) {
  const organisationKey = organisationIdToTextKey(orgId, orgName);

  if (!isEmpty(field))
    return organisationTexts[organisationKey][key][field] || defaultText;

  return organisationTexts[organisationKey][key] || defaultText;
}
