<script>
  import { javascript } from "@codemirror/lang-javascript";
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import CodeMirror from "svelte-codemirror-editor";
  import { toast } from "svelte-sonner";
  import { boolChecker, fetchPost } from "../../../helpers";
  import { RuleDataType } from "../../../lib/interfaces/Rule.interface";
  import {
    convertBoolToBuilder,
    convertBuilderToBoolean,
  } from "../../../ruleBuilderConverter";
  import { defaultRBStyle } from "../../../stores";
  import Modal from "../../Modals/Modal.svelte";
  import Rule from "../../Rule.svelte";
  import ReadOnlyRB from "../../RuleBuilder/ReadOnlyRB.svelte";
  import IconBot from "../icons/IconBot.svelte";

  export let data = {};
  // export let role = "system";
  export let timestamp = null;
  // export let validated = null;
  export let d_types = [];
  export let report_id;

  const originalData = { ...data };

  let statusText;
  let statusClass;

  // Reactive declaration to determine status text and class
  $: if (data.validated === true) {
    statusText = "This rule has been checked for validity and is correct.";
    statusClass = "text-green-500";
  } else if (data.validated === false) {
    statusText = "This rule isn't correct.";
    statusClass = "text-red-500";
  } else {
    statusText = "This rule hasn't been checked.";
    statusClass = "text-gray-500";
  }
  //NOTE: commenting with hyperlinks is commented out
  // function splitMessage(message) {
  //     return [...message.split(/(http[s]?:\/\/[^\s]+)/).entries()];
  // }
  const converter = () => {
    if (data.visual_type === RuleDataType.BOOLEAN && data.builder)
      data.boolean = convertBuilderToBoolean(data.builder);
    else if (data.visual_type === RuleDataType.BUILDER && data.boolean)
      data.builder = convertBoolToBuilder(data.boolean);
  };
  onMount(() => {
    data.visual_type =
      $defaultRBStyle === "classic"
        ? RuleDataType.BOOLEAN
        : RuleDataType.BUILDER;
    converter();
  });

  const randID = Math.floor(Math.random() * 1000000000);

  const dispatch = createEventDispatcher();
</script>

<div class="chat chat-start">
  <IconBot />
  <div class="chat-header">
    Haast Bot
    <time class="text-xs opacity-50">{timestamp}</time>
  </div>
  <div class="chat-bubble chat-bubble-secondary overflow-auto text-black">
    {#if data.name}
      <div>
        <strong>Rule:</strong>
        {data.name}
      </div>
    {/if}
    {#if data.description}
      <div>
        <strong>Description:</strong>
        {data.description}
      </div>
      <div class="divider divider-horizontal" />
    {/if}
    {#if data.explanation}
      <div>
        <strong>Explanation:</strong>
        {data.explanation}
      </div>
      <div class="divider divider-horizontal" />
    {/if}
    <div class="my-2">
      {#if data.visual_type == RuleDataType.BOOLEAN}
        <CodeMirror
          value={data.boolean}
          editable={false}
          readonly={true}
          lang={javascript()}
          styles={{
            "&": { background: "white", fontSize: "14px" },
            ".cm-gutters": { display: "none" },
          }}
          lineWrapping={true}
        />
      {:else}
        <div class="bg-base-100 max-w-max overflow-auto rounded">
          <ReadOnlyRB query={data.builder[0]} />
        </div>
      {/if}
    </div>
    <div class="divider divider-horizontal" />
    <div class="flex flex-row justify-around">
      <button
        class="btn btn-primary btn-sm"
        on:click={() =>
          document.getElementById(`save_rule_modal_${randID}`).showModal()}
      >
        <Icon icon="iconoir:save-floppy-disk" />
        Preview, Edit & Save
      </button>
      <button
        class="btn btn-primary btn-sm"
        on:click={() => {
          data.visual_type =
            data.visual_type === RuleDataType.BUILDER
              ? RuleDataType.BOOLEAN
              : RuleDataType.BUILDER;
          converter();
        }}
      >
        <Icon icon="iconoir:coins-swap" />
        {data.visual_type === RuleDataType.BUILDER ? "View Text" : "Visualise"} Rule
      </button>
    </div>
  </div>
  <div class="chat-footer">
    <span class="text-xs opacity-50">Delivered</span>
    <span class={`text-xs ${statusClass}`}>{statusText}</span>
  </div>
</div>

<Modal
  modalId="save_rule_modal_{randID}"
  size="xl"
  on:close={() => {
    const dvt = data.visual_type;
    data = { ...originalData };
    data.visual_type = dvt;
  }}
>
  <div class="-mx-6 -my-10">
    <Rule
      index={0}
      loaded={false}
      {data}
      {d_types}
      runRule={async () => {
        // TODO: look into this, even when we are not binding data it is still updating
        data.priority = "Low";
        data.boolean =
          data.visual_type === RuleDataType.BUILDER
            ? convertBuilderToBoolean(data.builder)
            : data.boolean;
        data.type = RuleDataType.BOOLEAN;

        if (!boolChecker(data.boolean)) {
          toast.warning("Rule is not valid, please check the syntax.");
          return;
        } else data.boolean = boolChecker(data.boolean);

        await fetchPost("/rule/add", {
          report_id,
          rule: data,
          boolean: data.boolean,
          data_types: data.data_types,
          priority: "Low",
        });

        dispatch("updateRulePen");

        document.getElementById(`save_rule_modal_${randID}`).close();
        data = { ...originalData };
      }}
      deleteRule={() => {
        document.getElementById(`save_rule_modal_${randID}`).close();
        const dvt = data.visual_type;
        data = { ...originalData };
        data.visual_type = dvt;
      }}
    />
  </div>
</Modal>
