export let reference_fields = [
  {
    label: "Text",
    name: "text",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "string",
    desc: "Search all text on the page, post or document",
    scope: ["live", "pre-ex"],
  },
  {
    label: "Body",
    name: "body",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "text",
    desc: "Search all text excluding headers and footers on web pages. This is identical to text for documents. For social media, search the post caption.",
    scope: ["live"],
  },
  {
    label: "Images",
    name: "images",
    operations: ["exactly contains", "regular expression", "includes any of"],
    type: "text",
    desc: "Search text extracted from images on pages, posts and documents",
    scope: ["live"],
  },
  {
    label: "Title",
    name: "title",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "text",
    desc: "Search the title of the page, post or document",
    scope: ["live", "pre-ex"],
  },
  {
    label: "Links",
    name: "links",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "string",
    desc: "Search all links present on the page, post or document",
    scope: ["live", "pre-ex"],
  },
  {
    label: "Redirect Links",
    name: "redirect_links",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "string",
    desc: "Search all destination urls that are redirected to by present links",
    scope: ["pre-ex"],
  },
  {
    label: "URL",
    name: "url",
    operations: ["exactly contains", "regular expression", "includes any of"],
    type: "text",
    desc: "Search the url of the page, post or document",
    scope: ["live"],
  },
  {
    label: "HTTP Status",
    name: "HTTP_Status",
    operations: [
      "equal",
      "greater than",
      "less than",
      "less than or equal to",
      "greater than or equal to",
    ],
    type: "number",
    desc: "Search the HTTP status code",
    scope: ["live"],
  },
  {
    label: "HTML",
    name: "html",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "text",
    desc: "Search the html contents of a web page. Only available on web pages.",
    scope: ["live"],
  },
  {
    label: "Vendor",
    name: "vendor",
    operations: ["exactly contains", "regular expression", "includes any of"],
    type: "text",
    desc: "Search the vendor.",
    scope: ["live"],
  },
  {
    label: "Hashtags",
    name: "hashtags",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "string",
    desc: "Search all the hashtags of a post. Only available on social media.",
    scope: ["live"],
  },
  {
    label: "Type",
    name: "type",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "string",
    desc: "Search the filetype of an asset (e.g. 'image/jpg').",
    scope: ["pre-ex"],
  },
  {
    label: "Tagged Users",
    name: "tagged_users",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "string",
    desc: "Search all the tagged users on a post. Only available on social media.",
    scope: ["live"],
  },
  {
    label: "Page",
    name: "page",
    operations: [
      "equal",
      "greater than",
      "less than",
      "less than or equal to",
      "greater than or equal to",
    ],
    type: "number",
    desc: "Search which page the match belongs to.",
    scope: ["pre-ex"],
  },
  {
    label: "Min Font Size",
    name: "min_size",
    operations: [
      "equal",
      "greater than",
      "less than",
      "less than or equal to",
      "greater than or equal to",
    ],
    type: "number",
    desc: "Search for the minimum font size in a section.",
    scope: ["pre-ex"],
  },
  {
    label: "Common Size",
    name: "common_size",
    operations: [
      "equal",
      "greater than",
      "less than",
      "less than or equal to",
      "greater than or equal to",
    ],
    type: "number",
    desc: "Search for the common font size in a section.",
    scope: ["pre-ex"],
  },
  {
    label: "Max Font Size",
    name: "max_size",
    operations: [
      "equal",
      "greater than",
      "less than",
      "less than or equal to",
      "greater than or equal to",
    ],
    type: "number",
    desc: "Search for the maximum font size in a section.",
    scope: ["pre-ex"],
  },
  {
    label: "Media",
    name: "media",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "string",
    desc: "Search all content from any media on the page, post or document",
    scope: ["live"],
  },
  {
    label: "Media Links",
    name: "media_links",
    operations: [
      "exactly contains",
      "regular expression",
      "proximity search",
      "includes any of",
    ],
    type: "string",
    desc: "Search all links to any media on the page, post or document",
    scope: ["live"],
  },
];
