<script>
  import anchorme from "anchorme";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import { org_users } from "../../stores";

  export let comment = "";
  export let taggedUserIds = new Set();
  export let commentInputId;

  const dispatch = createEventDispatcher();

  let inputElement;
  let dropdownElement;
  let cursorPosition = 0;
  let taggedUsers = new Set();
  let filteredUsers = [];
  let dropdownPosition = { top: 0, left: 0 };
  let showUserList = false;
  let highlightedIndex = 0;
  let html = "";

  function updateTaggedUsers() {
    const regex = /@(\w+ \w+)\b/g;
    const matches = [...comment.matchAll(regex)];
    taggedUsers = new Set(
      matches
        .map((match) =>
          $org_users.find((u) => `${u.first_name} ${u.last_name}` === match[1]),
        )
        .filter(Boolean),
    );
    taggedUserIds = new Set([...taggedUsers].map((user) => user.id));
  }

  function updateFilteredUsers() {
    const lastAtSymbol = comment.lastIndexOf("@", cursorPosition - 1);
    if (lastAtSymbol !== -1 && cursorPosition > lastAtSymbol) {
      const searchTerm = comment
        .slice(lastAtSymbol + 1, cursorPosition)
        .toLowerCase();
      filteredUsers = $org_users
        .filter((user) =>
          `${user.first_name?.toLowerCase()} ${user.last_name?.toLowerCase()}`.startsWith(
            searchTerm,
          ),
        )
        .splice(0, 3);
      highlightedIndex = 0;
    } else filteredUsers = [];

    showUserList = filteredUsers.length > 0;
  }

  function updateDropdownPosition() {
    if (!inputElement) return;
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();
    const inputRect = inputElement.getBoundingClientRect();
    dropdownPosition = {
      top: rect.bottom - inputRect.top,
      left: rect.left - inputRect.left,
    };
  }

  async function handleInput(event) {
    comment = event.target.innerText;
    cursorPosition = getCursorPosition();
    html = formatText(comment);

    updateTaggedUsers();
    updateFilteredUsers();

    await tick();
    if (inputElement) {
      inputElement.innerHTML = html;
      setCursorPosition(cursorPosition);
      updateDropdownPosition();
    }
  }

  function formatText(text) {
    text = text.replace(/@(\w+ \w+)\b/g, (match, fullName) => {
      const user = $org_users.find(
        (u) => `${u.first_name} ${u.last_name}` === fullName,
      );
      return user ? `<strong>${match}</strong>` : match;
    });

    text = anchorme({
      input: text,
      options: {
        attributes: {
          target: "_blank",
          class: "link",
        },
        truncate: 50,
        exclude: (string) => {
          return !string.includes(".");
        },
        extensions: [
          {
            test: /(https?:\/\/)?(www\.)?([a-zA-Z0-9-_.]+\.[a-zA-Z]{2,})(\/[^\s]*)?/g,
            transform: (string) => {
              try {
                const url = new URL(
                  string.startsWith("http") ? string : "http://" + string,
                );
                let displayText = url.hostname.replace(/^www\./, "");
                if (url.pathname !== "/") {
                  displayText += url.pathname;
                }
                if (
                  string.startsWith("https://www.") ||
                  string.startsWith("http://www.")
                ) {
                  displayText = "www." + displayText;
                }
                if (string.startsWith("https://")) {
                  displayText = "https://" + displayText;
                } else if (string.startsWith("http://")) {
                  displayText = "http://" + displayText;
                }
                return `<a href="${url.href}" target="_blank" class="link">${displayText}</a>`;
              } catch (error) {
                return string;
              }
            },
          },
        ],
      },
    });

    return text;
  }

  function getCursorPosition() {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return 0;
    const range = selection.getRangeAt(0);
    const preCaretRange = document.createRange();
    preCaretRange.selectNodeContents(inputElement);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  }

  async function selectUser(user) {
    const lastAtSymbol = comment.lastIndexOf("@", cursorPosition - 1);
    if (lastAtSymbol === -1) return;

    const beforeTag = comment.slice(0, lastAtSymbol);
    const afterTag = comment.slice(cursorPosition);
    const newText = `${beforeTag}@${user.first_name} ${user.last_name} ${afterTag}`;
    const newCursorPosition =
      lastAtSymbol + user.first_name?.length + user.last_name?.length + 3;

    comment = newText;
    html = formatText(newText);
    cursorPosition = newCursorPosition;

    updateTaggedUsers();
    updateFilteredUsers();

    await tick();
    if (inputElement) {
      inputElement.innerHTML = html;
      setCursorPosition(newCursorPosition);
      updateDropdownPosition();
      highlightedIndex = 0;
    }
  }

  function setCursorPosition(position) {
    const range = document.createRange();
    const sel = window.getSelection();
    let currentPos = 0;

    function traverseNodes(node) {
      if (node.nodeType === Node.TEXT_NODE) {
        if (currentPos + node.length >= position) {
          range.setStart(node, position - currentPos);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
          return true;
        }
        currentPos += node.length;
      } else {
        for (let child of node.childNodes) {
          if (traverseNodes(child)) {
            return true;
          }
        }
      }
      return false;
    }

    traverseNodes(inputElement);
  }

  function handleKeydown(event) {
    if (showUserList) {
      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          highlightedIndex = (highlightedIndex + 1) % filteredUsers.length;
          break;
        case "ArrowUp":
          event.preventDefault();
          highlightedIndex =
            (highlightedIndex - 1 + filteredUsers.length) %
            filteredUsers.length;
          break;
        case "Enter":
        case "Tab":
          event.preventDefault();
          if (
            highlightedIndex >= 0 &&
            highlightedIndex < filteredUsers.length
          ) {
            selectUser(filteredUsers[highlightedIndex]);
          }
          break;
        case "Escape":
          event.preventDefault();
          showUserList = false;
          break;
      }
    }
    else {
      if (event.shiftKey && event.key === "Enter") {
        event.preventDefault();
        // Dispatch custom event to trigger submit in `comments` component
        dispatch("submitComment");
      }
      // } else if (event.key === "Enter") {
      //   event.preventDefault();
      //   comment += "\n";  // Adds a new line when Enter is pressed
      // }
    }
  }

  onMount(() => {
    document.addEventListener("click", (event) => {
      if (
        dropdownElement &&
        !dropdownElement.contains(event.target) &&
        event.target !== inputElement
      ) {
        showUserList = false;
      }
    });
  });

  $: {
    if (comment === "") {
      if (inputElement) {
        inputElement.innerHTML = "";
      }
      taggedUsers = new Set();
      taggedUserIds = new Set();
    }
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div class="bg-base-100 relative">
  <div
    id={commentInputId}
    bind:this={inputElement}
    contenteditable="true"
    on:input={handleInput}
    on:keydown={handleKeydown}
    class="min-h-16 w-full overflow-y-auto rounded border px-2 py-1"
  ></div>

  {#if showUserList}
    <ul
      bind:this={dropdownElement}
      class="menu bg-base-100 absolute z-10 w-52 flex-nowrap border"
      style="top: {dropdownPosition.top}px; left: {dropdownPosition.left}px"
    >
      {#each filteredUsers as user, index}
        <li>
          <button
            class={index === highlightedIndex ? "bg-base-content/10" : ""}
            on:click={() => selectUser(user)}
          >
            {user.first_name}
            {user.last_name}
          </button>
        </li>
      {/each}
    </ul>
  {/if}
</div>
