<script>
  export let status = "";
  export let assetStatus = {
    status: "",
    assetRuleStatuses: {
      ready: 0,
      processing: 0,
      failed: 0,
      total: 0,
    },
    percentageProgress: 0,
  };
</script>

{#key status}
  {#if status === "Searching"}
    <div
      class="absolute inset-0 z-10 flex min-h-screen items-center justify-center backdrop-blur"
    ></div>
    <div class="absolute inset-0 z-20 flex items-center justify-center text-xl">
      <div class="flex flex-col items-center justify-center gap-4 text-xl">
        <div class="flex w-full flex-col items-center gap-2">
          <span class="text-l min-w-8 text-start">
            {assetStatus.percentageProgress}&#37;
          </span>
          <progress
            class="progress progress-primary w-full"
            value={assetStatus.assetRuleStatuses.ready}
            max={assetStatus.assetRuleStatuses.total}
          />
        </div>
        The asset is currently being searched.
      </div>
    </div>
  {:else if status === "Processing"}
    <div
      class="absolute inset-0 z-10 flex min-h-screen items-center justify-center backdrop-blur"
    ></div>
    <div class="absolute inset-0 z-20 flex items-center justify-center text-xl">
      <div class="flex flex-col items-center justify-center gap-4 text-xl">
        <div class="flex w-full flex-col items-center gap-2">
          <span class="text-l min-w-8 text-start"> 0&#37; </span>
          <progress class="progress progress-primary w-full" />
        </div>
        The asset is currently processing.
      </div>
    </div>
  {:else if status === "Upload Failed"}
    <div
      class="absolute inset-0 z-10 flex min-h-screen items-center justify-center backdrop-blur"
    ></div>
    <div class="absolute inset-0 z-20 flex items-center justify-center text-xl">
      The asset has failed to upload.
    </div>
  {:else if ["Searching Failed", "Searching Error"].includes(status)}
    <div
      class="absolute inset-0 z-10 flex min-h-screen items-center justify-center backdrop-blur"
    ></div>
    <div class="absolute inset-0 z-20 flex items-center justify-center">
      <span class="mx-20 text-center text-xl">
        Something went wrong while analysing the document. Please try
        reuploading it.
      </span>
    </div>
  {:else if status !== "Ready"}
    <div
      class="absolute inset-0 flex items-center justify-center text-xl backdrop-blur"
    >
      The asset is still {status.toLowerCase()}.
    </div>
  {/if}
{/key}
