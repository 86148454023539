<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { push } from "svelte-spa-router";
  import { slide } from "svelte/transition";
  import Select from "../../components/Select.svelte";
  import { fetchDelete, fetchGet, fetchPatch, fetchPost } from "../../helpers";
  import { currentUser, org_id, org_name, org_users, org_labels } from "../../stores";
  import LabelManager from "../Labels/LabelManager.svelte";
  import LabelSearch from "../Labels/LabelSearch.svelte";
  import ListOfBeans from "../Labels/ListOfBeans.svelte";
  import LogoLoader from "../LogoLoader.svelte";
  import { getTextConstant } from "../../lib/utils/ConstantsUtils";

  export let type = "stream";
  export let classification = "live";

  export let step;
  export let stepsCompletedTill;
  export let report_id;
  export let streamType;
  export let streamName = "";
  export let streamDesc = "";
  export let streamStatus = "";

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");

  let streamAccess = "Only me";

  const streamAccessTypes = ["Only me", "Everyone", "Specific people or teams"];

  let selectedFrequency = "Never";

  let labels = [];

  onMount(async () => {
    if (report_id) {  // Only run if report_id exists
      await setStreamDetails();

      if (streamName.trim().length === 0) {
        await saveDetails();
        addCurrentUserToStream();
      }

      setStreamAccessDetails();
    }
  });

  async function setStreamDetails() {
    const res = await fetchGet(`/report/details/${report_id}`);
    if (res.success === false) return;

    streamName = res.report.name;
    streamDesc = res.report.description;
    streamStatus = res.report.status;

    if (res.report.rule_run_schedule == 167) {
      selectedFrequency = "Weekly";
      /*else if (res.report.rule_run_schedule == 24) { //to enable if daily is supported in future
      selectedFrequency = "Daily";
    }*/
    } else {
      //default to never
      selectedFrequency = "Never";
    }

    labels = $org_labels.filter((l) => res.report.label_ids.includes(l.id));
  }

  const setStreamAccessDetails = async () => {
    const usersResponse = await fetchGet(`/report/${report_id}/users`);
    if (usersResponse.success) associatedUsers = usersResponse.users;

    const teamsResponse = await fetchGet(`/report/${report_id}/teams`);
    if (teamsResponse.success) associatedTeams = teamsResponse.teams;

    if (associatedTeams.length || associatedUsers.length) {
      if (
        associatedTeams.length === 0 &&
        associatedUsers.length === 1 &&
        associatedUsers[0].id === $currentUser.id
      )
        streamAccess = "Only me";
      else if (
        associatedUsers[0]?.id === $currentUser.id &&
        orgPermission === "view" &&
        associatedUsers.length === 1 &&
        associatedTeams.length === 1 &&
        associatedTeams[0].name === "All Users"
      )
        streamAccess = "Everyone";
      else streamAccess = "Specific people or teams";
    } else if (orgPermission === "view") streamAccess = "Everyone";

    const res3 = await fetchGet(`/report/get-all-subs/${report_id}`);
    if (res3.success) {
      const subs = res3.subscribers;

      if (subs.length > 0) {
        associatedUsers = associatedUsers.map((user) => {
          const sub = subs.find((sub) => sub.id === user.id);
          user.subscribed = sub ? true : false;
          return user;
        });
      }
    }
  };

  const saveDetails = async () => {
    //TODO: refactor this to use the reportFunctions saveDetails.
    let description = "";

    if (streamDesc && streamDesc.trim().length > 0) {
      description = streamDesc;
    }

    await fetchPost("/report/1", {
      report_id,
      name:
        streamName.trim().length > 0
          ? streamName
          : type === "asset"
            ? `Untitled ${textConstants["Folder"]}`
            : `Untitled ${textConstants["Stream"]}`,
      description: description,
      status: type == "asset" && streamStatus === "" ? "Uploads" : streamStatus,
      classification: classification,
    });
  };

  const addCurrentUserToStream = async () => {
    const currentUserId = $currentUser.id;

    fetchPost(`/user/${currentUserId}/report/${report_id}`, {
      permission: "edit",
    }).catch((error) => {
      toast.error(
        `Something went wrong adding User with ID(${currentUserId}) to Report(${report_id})`,
      );
      console.error("Failed to add current user to stream:", error);
    });
  };

  let runningRules = false;

  async function runRule(rule) {
    try {
      if (!rule.created_date) {
        await fetchDelete(`/rule/${rule.id}`);
        await fetchPost(`/rule/run`, {
          status: "Draft",
          report_id: report_id,
          name: streamName,
          description: streamDesc,
          data_types: rule.data_type.split(", "),
          priority: rule.priority,
          boolean: rule.boolean,
          default_hit_priority: rule.default_hit_priority,
          rule,
        });
      } else {
        await fetchPatch(`/rule/${rule.id}`, {
          boolean: rule.boolean,
          name: rule.name,
          description: rule.description,
          data_type: rule.data_type,
          default_hit_priority: rule.default_hit_priority,
          remediation_step: rule.remediation_step,
        });
        await fetchPatch(`/rule/run/${rule.id}`, { rule });
      }
    } catch (error) {
      toast.error("Something went wrong while running rule...");
    }
  }

  const checkForStepCompletion = async (viewReport = false) => {
    let frequencyValue = -1;
    if (selectedFrequency === "Never") frequencyValue = -1;
    else if (selectedFrequency === "Weekly") frequencyValue = 167;
    // else if (selectedFrequency === "Daily") frequencyValue = 24;

    await fetchPost("/report/schedule/" + report_id, {
      schedule: frequencyValue,
    });

    if (streamName.trim().length >= 3) {
      await saveDetails();
      if (stepsCompletedTill < step) stepsCompletedTill = step;
    } else {
      stepsCompletedTill = -1;
      toast.warning("Please enter a valid name");
      return false;
    }

    if (labels.length > 0) {
      labels.forEach((l) => {
        fetchPost(`/label/assign/${l.id}`, {
          report_id,
        });
      });
    }

    // Save Stream Access

    if (["Only me", "Everyone"].includes(streamAccess)) {
      // remove all associated Users/Teams
      if (associatedUsers.length > 0) {
        associatedUsers.forEach((user) => {
          if (user.id !== $currentUser.id) {
            entityToEditType = "user";
            entityToEditId = user.id;
            deletePermissionFromEntity();
          }
        });
      }
      if (associatedTeams.length > 0) {
        associatedTeams.forEach((team) => {
          entityToEditType = "team";
          entityToEditId = team.id;
          deletePermissionFromEntity();
        });
      }
    }

    if (streamAccess === "Everyone") {
      await fetchPost(`/report/${report_id}/change-org-permission`, {
        permission: "edit",
      });
    } else if (streamAccess === "Only me") {
      if (orgPermission === "view")
        await fetchPost(`/report/${report_id}/change-org-permission`, {
          permission: "none",
        });
    }

    if (viewReport === true && streamType === "edit") {
      fetchPost("/report/1", {
        report_id,
        name: streamName,
        description: streamDesc,
        status: "Live",
      });

      const response = await fetchGet(`/report/${report_id}/rules`);
      const allRules = response.rules;

      runningRules = true;

      for (const rule of allRules) await runRule(rule);

      runningRules = false;

      push(`/stream/${report_id}`);
      return;
    }

    step += 1;
  };

  // for stream access/ alerts
  let associatedUsers = [];
  let associatedTeams = [];
  let orgPermission = "none";

  let allUsers = $org_users;
  let allTeams = [];

  let entityToEditId = -1;
  let entityToEditType = "";

  let warningMessage = "";

  async function getOrganizationTeams() {
    try {
      const response = await fetchGet("/team");

      if (!response.success) {
        throw new Error(response.message);
      }

      allTeams = response.teams;
    } catch (error) {
      console.error(error);
    }
  }
  async function getOrganizationPermission() {
    try {
      const response = await fetchGet(
        `/report/${report_id}/get-org-permission`,
      );

      if (response.success) orgPermission = response.permission;
    } catch (error) {
      console.error(error);
    }
  }
  onMount(async () => {
    await getOrganizationTeams();
    await getOrganizationPermission();
  });

  let selectedPermission = null;
  let selectedEntity = null;

  let selectableEntities = getSelectableEntities();

  $: if (orgPermission || associatedTeams || associatedUsers) {
    selectableEntities = getSelectableEntities();
  }

  function getSelectableEntities() {
    let output = [
      ...allUsers.map((user) => ({
        value: `user:${user.id}`,
        label: user.first_name + " " + user.last_name,
        group: "Users",
      })),

      ...allTeams.map((team) => ({
        value: `team:${team.id}`,
        label: team.name,
        group: "Teams",
      })),
    ];

    if (orgPermission === "none") {
      output = [
        ...output,
        {
          value: "org",
          label: "All Users",
          group: "Organisation",
        },
      ];
    }

    output = output.filter((entity) => {
      if (entity.group === "Teams") {
        return !isEntityAssociated(entity.value.split(":")[1], associatedTeams);
      } else if (entity.group === "Users") {
        return !isEntityAssociated(entity.value.split(":")[1], associatedUsers);
      }
      return true;
    });
    return output;
  }

  const groupBy = (entity) => entity.group;

  function isEntityAssociated(entityId, associatedEntities) {
    return associatedEntities.some((entity) => entity.id == entityId);
  }

  async function sharePermissionWithEntity() {
    if (selectedPermission === "none" || selectedEntity === "none") {
      warningMessage = "* Please select a permission and entity to share with.";
      return;
    }

    const [entityType, entityId] = selectedEntity.split(":");

    try {
      if (entityType != "org") {
        const response = await fetchPost(
          `/${entityType}/${entityId}/report/${report_id}`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchPost(
          `/report/${report_id}/change-org-permission`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function deletePermissionFromEntity() {
    try {
      if (entityToEditType === "org") {
        const response = await fetchPost(
          `/report/${report_id}/change-org-permission`,
          {
            permission: "none",
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchDelete(
          `/${entityToEditType}/${entityToEditId}/report/${report_id}`,
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function updatePermissionForEntity() {
    try {
      if (entityToEditType === "org") {
        const response = await fetchPost(
          `/report/${report_id}/change-org-permission`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchPatch(
          `/${entityToEditType}/${entityToEditId}/report/${report_id}`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function subscribeToReport(userId) {
    try {
      const response = await fetchPost(`/report/subscribe/${report_id}`, {
        user_id: userId,
      });

      if (!response.success) {
        warningMessage = response.message;
        return;
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function unsubscribeFromReport(userId) {
    try {
      const response = await fetchDelete(`/report/subscribe/${report_id}`, {
        user_id: userId,
      });

      if (!response.success) {
        warningMessage = response.message;
        return;
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }
</script>

<label class="label max-w-xl">
  <span class="label-text">
    {type == "asset"
      ? textConstants["Folder"]
      : type == "spelling"
        ? "Report"
        : textConstants["Stream"]} Name
  </span>
  <input
    type="text"
    placeholder="Type here"
    class="input input-bordered w-full"
    minlength="4"
    maxlength="60"
    bind:value={streamName}
  />
</label>

<label class="label max-w-xl flex-row">
  <span class="label-text">
    {type == "asset"
      ? textConstants["Folder"]
      : type == "spelling"
        ? "Report"
        : textConstants["Stream"]} Description
  </span>
  <textarea
    class="textarea textarea-bordered h-24 w-full"
    placeholder="Add a description, outlining the {type == 'asset'
      ? `type of assets this ${textConstants['Folder'].toLowerCase()} contains`
      : type == 'spelling'
        ? 'spelling and grammar issues this report has been set up to monitor'
        : 'topics or issues this stream has been set up to monitor'}"
    bind:value={streamDesc}
  />
</label>

<label class="label max-w-xl" for="">
  <span class="label-text">Labels</span>
  <div class="z-20 flex flex-wrap items-center gap-2">
    <LabelManager
      bind:selectedLabels={labels}
      reportId={report_id}
    />
  </div>
</label>

<div class="form-control w-full max-w-xl">
  <label class="label" for="sAccess">
    <span class="label-text">
      {#if type === "asset"}
        Document Owners
      {:else}
        Who can see and edit this {type === "spelling" ? "report" : "stream"}
      {/if}
    </span>
  </label>

  {#each streamAccessTypes as acc}
    <label
      class="label cursor-pointer !flex-row !items-center justify-start gap-2"
    >
      <input
        type="radio"
        class="radio-primary radio radio-sm"
        checked={acc === streamAccess}
        on:click={() => {
          streamAccess = acc;
          // setStreamAccessDetails();
        }}
      />
      <span class="label-text !text-sm">{acc}</span>
    </label>
  {/each}
</div>

{#if !type == "asset" && ["Only me", "Everyone"].includes(streamAccess)}
  <div class="form-control w-full max-w-xl">
    <label class="label cursor-pointer">
      <span class="label-text"
        >Notifications for new hits in this {type == "spelling"
          ? "report"
          : "stream"}?</span
      >
      <input
        type="checkbox"
        class="toggle toggle-primary"
        name="sNotif"
        checked={associatedUsers.find((user) => user.id === $currentUser.id)
          ?.subscribed}
        on:change={(e) => {
          if (e.target.checked) subscribeToReport($currentUser.id);
          else unsubscribeFromReport($currentUser.id);
        }}
      />
    </label>
    <span class="label-text-alt px-1">
      You will receive email notifications when new hits are found as part of
      this {type == "spelling" ? "report" : "stream"}.
    </span>
  </div>
{/if}

{#if streamAccess === "Specific people or teams"}
  <div class="mt-1 max-w-xl" transition:slide>
    <Select
      classes="w-full"
      items={selectableEntities}
      bind:selectedValue={selectedEntity}
      placeholder="Add Users or Teams"
      on:change={(e) => {
        selectedPermission = "view";
        selectedEntity = e.detail;

        if (selectedEntity.startsWith("team")) {
          const teamId = selectedEntity.split(":")[1];
          const team = allTeams.find((team) => team.id == teamId);

          if (!isEntityAssociated(teamId, associatedTeams)) {
            associatedTeams = [
              ...associatedTeams,
              { ...team, permission: "view" },
            ];
          }
        } else if (selectedEntity.startsWith("user")) {
          const userId = selectedEntity.split(":")[1];
          const user = allUsers.find((user) => user.id == userId);

          if (!isEntityAssociated(userId, associatedUsers)) {
            associatedUsers = [
              ...associatedUsers,
              { ...user, permission: "view" },
            ];
          }
        } else orgPermission = "view";

        sharePermissionWithEntity();

        selectedPermission = null;
        selectedEntity = null;
      }}
    />

    <table class="mt-2 table">
      <thead>
        <tr>
          <th>People/Teams</th>
          {#if !type == "asset"}
            <th>
              <div
                class="tooltip tooltip-bottom"
                data-tip="Email notifications when new hits are found."
              >
                Notifications
              </div>
            </th>
          {/if}
          <th colspan="2">
            <div
              class="tooltip tooltip-bottom"
              data-tip="Permission to view / edit this stream"
            >
              Permissions
            </div>
          </th>
        </tr>
      </thead>
      {#each associatedUsers as user (user.id)}
        <tr>
          <td>
            <div class="flex items-center gap-1">
              <Icon icon="iconoir:user" />
              {user.first_name
                ? `${user.first_name} ${user.last_name}`
                : "All Users"}
            </div>
          </td>
          {#if !type == "asset"}
            <td>
              <input
                class="toggle toggle-primary"
                type="checkbox"
                checked={user.subscribed}
                on:change={(e) => {
                  if (e.target.checked) subscribeToReport(user.id);
                  else unsubscribeFromReport(user.id);
                }}
              />
            </td>
          {/if}
          <td>
            <Select
              selectedValue={user.permission}
              on:change={(e) => {
                entityToEditId = user.id;
                entityToEditType = "user";
                selectedPermission = e.detail;
                updatePermissionForEntity();
              }}
              items={type == "asset"
                ? [
                    { value: "view", label: "View" },
                    { value: "upload", label: "Upload Assets" },
                    { value: "edit", label: "Manage" },
                  ]
                : [
                    { value: "view", label: "View" },
                    { value: "edit", label: "Edit" },
                  ]}
            />
          </td>
          <td>
            <button
              class="btn btn-square btn-ghost btn-sm hover:btn-error float-right"
              on:click={() => {
                entityToEditId = user.id;
                entityToEditType = "user";
                associatedUsers = associatedUsers.filter(
                  (user) => user.id !== entityToEditId,
                );
                deletePermissionFromEntity();
              }}
            >
              <Icon icon="iconoir:cancel" />
            </button>
          </td>
        </tr>
      {/each}
      {#each associatedTeams as team (team.id)}
        <tr>
          {#if team.name !== "All Users"}
            <td>
              <div class="flex items-center gap-1">
                <Icon icon="iconoir:community" />
                {team.name}
              </div>
            </td>
            {#if !type == "asset"}<td />{/if}
            <td>
              <Select
                selectedValue={team.permission}
                on:change={(e) => {
                  entityToEditId = team.id;
                  entityToEditType = "team";
                  selectedPermission = e.detail.value;
                  updatePermissionForEntity();
                }}
                items={type == "asset"
                  ? [
                      { value: "view", label: "View" },
                      { value: "upload", label: "Upload Assets" },
                      { value: "edit", label: "Manage" },
                    ]
                  : [
                      { value: "view", label: "View" },
                      { value: "edit", label: "Edit" },
                    ]}
              />
            </td>
            <td>
              <button
                class="btn btn-square btn-ghost btn-sm hover:btn-error float-right"
                on:click={() => {
                  entityToEditId = team.id;
                  entityToEditType = "team";
                  associatedTeams = associatedTeams.filter(
                    (team) => team.id !== entityToEditId,
                  );
                  deletePermissionFromEntity();
                }}
              >
                <Icon icon="iconoir:cancel" />
              </button>
            </td>
          {/if}
        </tr>
      {/each}
      {#if orgPermission != "none"}
        <tr>
          <td>
            <div class="flex items-center gap-1">
              <Icon icon="iconoir:community" /> All Users
            </div>
          </td>
          {#if !type == "asset"}<td />{/if}
          <td>
            <Select
              selectedValue={orgPermission}
              on:change={(e) => {
                entityToEditId = "org";
                entityToEditType = "org";
                selectedPermission = e.detail.value;
                updatePermissionForEntity();
              }}
              items={type == "asset"
                ? [
                    { value: "view", label: "View" },
                    { value: "upload", label: "Upload Assets" },
                    { value: "edit", label: "Manage" },
                  ]
                : [
                    { value: "view", label: "View" },
                    { value: "edit", label: "Edit" },
                  ]}
            />
          </td>
          <td>
            <button
              class="btn btn-square btn-ghost btn-sm hover:btn-error float-right"
              on:click={() => {
                entityToEditId = "org";
                entityToEditType = "org";
                orgPermission = "none";
                deletePermissionFromEntity();
              }}
            >
              <Icon icon="iconoir:cancel" />
            </button>
          </td>
        </tr>
      {/if}
    </table>
  </div>
{/if}

{#if type != "asset"}
  <label class="label cursor-pointer" for="">
    <span class="label-text">
      How frequently should this {type == "spelling" ? "report" : "stream"} automatically
      refresh results?
    </span>
    <Select
      items={[
        { label: "Never", value: "Never" },
        // { label: "Daily", value: "Daily" },
        { label: "Weekly", value: "Weekly" },
      ]}
      bind:selectedValue={selectedFrequency}
    />
  </label>
{/if}

<div class="mt-4 flex max-w-xl gap-2">
  <div class="tooltip" data-tip="You are on the first page">
    <button class="btn btn-sm" disabled>Back</button>
  </div>

  {#if streamName.length < 3}
    <div
      class="tooltip"
      data-tip="{type == 'asset'
        ? textConstants['Folder']
        : type == 'spelling'
          ? 'Report'
          : textConstants['Stream']} Name should at least have 3 letters"
    >
      <button class="btn btn-primary btn-sm" disabled>Next Step</button>
    </div>
  {:else}
    <button class="btn btn-primary btn-sm" on:click={checkForStepCompletion}>
      Next Step
    </button>
    {#if streamType === "edit"}
      <button
        class="btn btn-primary btn-sm"
        disabled={runningRules}
        on:click={() => checkForStepCompletion(true)}
      >
        {#if runningRules}
          <LogoLoader size="1.25rem" />
        {/if}
        Save and View {type == "spelling" ? "Report" : "Stream"}
      </button>
    {/if}
  {/if}
</div>

<style lang="postcss">
  .label {
    @apply flex-col items-start p-0;
  }
  .label-text {
    @apply py-1;
  }
</style>
