<script>
  import {
    assetApprovalStatusToColour,
    getUser,
    hitColourToName,
  } from "../../../helpers";

  export let comment;
  export let type = "live";
  export let subType = "hit";
  export let isParentConditional = false;
</script>

<p class="text-sm">
  {getUser(comment.user_id)} <strong>changed status</strong> from
  {#if type === "live"}
    <i>{comment.old}</i> to <i>{comment.new}</i>
  {:else if type === "asset"}
    {#if subType === "assetStatus"}
      <strong style={assetApprovalStatusToColour(comment.old)}
        >{comment.old}</strong
      >
      to
      <strong style={assetApprovalStatusToColour(comment.new)}
        >{comment.new}</strong
      >
    {:else}
      <strong class={comment.old}>
        {hitColourToName(comment.old, isParentConditional)}
      </strong>
      to
      <strong class={comment.new}>
        {hitColourToName(comment.new, isParentConditional)}
      </strong>
    {/if}
  {/if}
</p>

<style>
  .red {
    color: #ff4e4e;
  }
  .amber {
    color: #ffad48;
  }
  .green {
    color: #336a1d;
  }
</style>
