<script>
  import { getUser } from "../../../helpers";

  export let comment;
</script>

<p class="text-sm">
  {getUser(comment.user_id)} marked it as
  <strong>{comment.old !== "false" ? "not" : ""} complete</strong>
</p>
