<script>
  import { toast } from "svelte-sonner";
  import { fetchPost, fetchGet } from "../../helpers";
  import { org_users, org_teams } from "../../stores";
  import Icon from "@iconify/svelte";
  import Modal from "../Modals/Modal.svelte";
  import MultiSelect from "../MultiSelect.svelte";
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();

  let bottomButtons = {
    show: true,
    primaryText: "Confirm",
    secondaryText: "Cancel",
    loading: false,
    primaryAction: addTeam,
    secondaryAction: closeModal,
  };

  const maximumStringLength = 64;

  let teamName = "";
  let teamDescription = "";
  let selectedUsers = [];
  let users = $org_users;
  let errorMessage = "";
  let submitDisabled = false;

  $: displayUsers = $org_users
    .filter(u => !u.is_deleted)
    .map(user => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id,
    }));

  function openModal() {
    // Initialize all variables here
    teamName = "";
    teamDescription = "";
    selectedUsers = [];

    errorMessage = "";
    submitDisabled = false;
    document.querySelector("#AddTeamModal").showModal();
  }

  function closeModal() {
    document.querySelector("#AddTeamModal").close();
  }

  function validateForm() {
    if (!teamName.trim()) {
      errorMessage = "Team name is required.";
      return false;
    }
    return true;
  }

  async function addTeam() {
    if (!validateForm()) return;

    bottomButtons.loading = true;
    try {
      const response = await fetchPost("/team", {
        name: teamName,
        description: teamDescription,
        users: selectedUsers,
      });

      if (!response.success) throw new Error(response.error);

      org_teams.update(teams => [...teams, response.team]);
      toast.success("Team added successfully");
      closeModal();
    } catch (error) {
      toast.error("Failed to add team");
      console.error(error);
    } finally {
      bottomButtons.loading = false;
    }
  }
</script>

<button class="btn btn-primary btn-sm" on:click={openModal}> Add Team </button>

<Modal
  modalId="AddTeamModal"
  size="sm"
  cornerBackButton={true}
  cornerCloseButton={false}
  {bottomButtons}
>
  <div class="space-y-2">
    <div
      class="bg-primary-content m-auto flex aspect-square w-16 items-center justify-center rounded-full"
    >
      <Icon icon="iconoir:group" class="text-primary text-3xl" />
    </div>
    <h3 class="text-center font-bold">Add Team</h3>
  </div>

  <div class="flex flex-col items-center gap-2"></div>

  <div class="mt-6 space-y-2">
    <p class="text-sm">Team Name</p>
    <input
      class="input {submitDisabled ? 'input-error' : 'input-bordered'} w-full"
      placeholder="Enter Team Name"
      type="text"
      maxlength={maximumStringLength}
      bind:value={teamName}
    />

    <p class="text-sm">Team Description</p>
    <input
      class="input input-bordered w-full"
      type="text"
      maxlength={maximumStringLength}
      bind:value={teamDescription}
      placeholder="Enter Team Description"
    />

    <p class="text-sm">Select Users</p>
    <MultiSelect
      items={displayUsers}
      placeholder="Select Team Members"
      size="md"
      classes="w-full"
      bind:selectedValues={selectedUsers}
    />
  </div>

  {#if errorMessage}
    <span class="text-xs text-red-500">*{errorMessage}</span>
  {/if}
</Modal>
