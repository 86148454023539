<script>
  import { link } from "svelte-spa-router";
  import { RuleStatus } from "../../../lib/interfaces/Rule.interface";
  import Pagination from "../../table/Pagination.svelte";
  import AssignUsers from "../AssignUsers.svelte";
  import Badge from "../WorkflowBoard/Badge.svelte";
  import { getRuleColourCounts } from "../../../lib/utils/RuleUtils";
  import {
    getTotalAssetRules,
    getProcessingAssetRules,
  } from "../../../lib/utils/AssetReviewUtils";
  import { AssetStatus } from "../../../lib/interfaces/Asset.interface";
  import AssetListRow from "./AssetListRow.svelte";

  export let rows = [];
  export let reportId;
  export let activeAssetId;
  export let asset;
  export let workflow;
  export let assetStatusMap = new Map();

  const itemsPerPage = 10;

  let currentPage = 1;
</script>

<table class="table border-collapse rounded-lg border border-gray-300 shadow">
  <thead
    class="rounded-t-lg bg-[#F8FAFC]"
    style="border-radius: 16px;font-color: #475569;"
  >
    <tr style="background-color:#F8FAFC;">
      <th class="rounded-tl-lg">Asset Name</th>
      <th>Status</th>
      <th>Upload Status</th>
      <th>Progress</th>
      <th>Approval Status</th>
      <th>Due Date</th>
      <th class="rounded-tr-lg text-end">Assigned To</th>
    </tr>
  </thead>
  <tbody class="divide-y divide-gray-300 bg-white">
    {#each rows.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as row, index}
      <AssetListRow {rows} {row} {reportId} {index} {assetStatusMap} />
    {/each}
  </tbody>
</table>

<Pagination bind:currentPage totalHits={rows.length} {itemsPerPage} />
