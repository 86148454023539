import { org_users } from "../../stores";
import { get } from "svelte/store";

function calculateDaysFromDate(date) {
  const today = new Date();
  const dateToCompare = new Date(date);
  const diffTime = Math.abs(today.getTime() - dateToCompare.getTime());
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

export function getTableByCategory(
  tableCategory = "NONE",
  inputTableData = [],
) {
  let newData = inputTableData;
  let newTitle = "";

  switch (tableCategory) {
    case "DONE":
      newData = newData.filter((entry) => entry["hit"]["done"] === true);
      newTitle = "Completed issues";
      return { data: newData, title: newTitle };

    case "NOT_DONE":
      newData = newData.filter((entry) => entry["hit"]["done"] === false);
      newTitle = "Not completed issues";
      return { data: newData, title: newTitle };

    case "HIGH_PRIORITY":
      newData = newData.filter((entry) => entry["hit"]["priority"] === "High");
      newTitle = "High priority issues";
      return { data: newData, title: newTitle };

    case "MED_PRIORITY":
      newData = newData.filter((entry) => entry["hit"]["priority"] === "Med");
      newTitle = "Medium priority issues";
      return { data: newData, title: newTitle };

    case "LOW_PRIORITY":
      newData = newData.filter((entry) => entry["hit"]["priority"] === "Low");
      newTitle = "Low priority issues";
      return { data: newData, title: newTitle };

    case "LESS_THAN_THREE_DAYS":
      newData = newData.filter(
        (entry) => calculateDaysFromDate(entry["hit"]["last_updated"]) < 3,
      );
      newTitle = "Issues less than 3 days old";
      return { data: newData, title: newTitle };

    case "LESS_THAN_TEN_DAYS":
      newData = newData.filter(
        (entry) =>
          calculateDaysFromDate(entry["hit"]["last_updated"]) < 10 &&
          calculateDaysFromDate(entry["hit"]["last_updated"]) >= 3,
      );
      newTitle = "Issues less than 10 days old";
      return { data: newData, title: newTitle };

    case "MORE_THAN_TEN_DAYS":
      newData = newData.filter(
        (entry) => calculateDaysFromDate(entry["hit"]["last_updated"]) >= 10,
      );
      newTitle = "Issues more than 10 days old";
      return { data: newData, title: newTitle };

    case "NONE":
      newTitle = "";
      return { data: newData, title: newTitle };

    default:
      if (tableCategory.startsWith("ASSIGNEE:")) {
        const assignee = tableCategory.split(":")[1];
        newData = newData.filter((entry) => {
          for (let i = 0; i < entry["hit"]["assigned_users"].length; i++) {
            const assignedUser = entry["hit"]["assigned_users"][i];
            if (assignedUser["assigned_id"] == assignee) {
              return true;
            }
          }
          return false;
        });

        let user = get(org_users).find((user) => user.id == assignee);
        newTitle = `Issues assigned to ${
          user.first_name + " " + user.last_name
        }`;
      } else if (tableCategory.startsWith("ACTION:")) {
        const action = tableCategory.split(":")[1];
        newTitle = action;
        newData = newData.filter((entry) => {
          if (entry["hit"]["action"] === null) {
            return false;
          } else {
            return entry["hit"]["action"] === action;
          }
        });
      } else {
        newTitle = "Issues";
      }

      return { data: newData, title: newTitle };
  }
}
