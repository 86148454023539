/**
 * @description Validates that an email is a valid email.
 * @param {string} email The email string to validate.
 * @returns {boolean} A boolean corresponding to whether the email is valid.
 */
export function validEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
}

/**
 * @description Validates that a password meets the minimum length requirements.
 * @param {string} password The password string to validate.
 * @returns {boolean} A boolean corresponding to whether the password is valid.
 */
export function validPassword(password) {
  return password.length >= 5 ? true : false;
}
