<script>
  import { javascript } from "@codemirror/lang-javascript";
  import Icon from "@iconify/svelte";
  import { onDestroy, onMount } from "svelte";
  import CodeMirror from "svelte-codemirror-editor";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import DropdownMultiSelect from "../components/DropdownMultiSelect.svelte";
  import RuleBuilder from "../components/RuleBuilder/RuleBuilder.svelte";
  import SearchTable from "../components/SearchTable.svelte";
  import Select from "../components/Select.svelte";
  import {
    boolChecker,
    dTypesToMultiSelect,
    fetchGet,
    fetchPost,
  } from "../helpers";
  import {
    convertBoolToBuilder,
    convertBuilderToBoolean,
  } from "../ruleBuilderConverter";
  import { maximised } from "../stores";

  const maximumHits = 10000;

  let results;
  let moreHits = true;
  let searchLimit = 20;
  let searchFrom = 0;
  let selectedField = "text";
  let selectedDataTypes = [];
  let searchType = "Exact Phrase";
  let items = {};
  let fetchedDataTypes = [];
  let showAdvancedOptions = false;
  let builder;
  let to_search = {
    type: "Boolean",
    size: 1000,
    boolean: ' (text:("Haast") AND text:("Haast"))',
    text: "",
  };
  let searchInput = "";
  let searchingSelected = false;
  let searchingEverywhere = false;

  const runRule = async (searchAll = false) => {
    if (selectedDataTypes.length === 0 && !searchAll) {
      toast.warning("Search stopped, no pools selected.");
      return;
    }

    if (
      searchInput.trim() === "" &&
      searchType !== "Boolean (Visual Builder)"
    ) {
      toast.warning("Please enter a search query.");
      searchingSelected = false;
      searchingEverywhere = false;
      return;
    }

    to_search.text = searchInput;

    if (searchType === "Exact Phrase") to_search.text = `"${to_search.text}"`;
    else if (searchType === "Contains All Words")
      to_search.text = to_search.text
        .split(" ")
        .map((e) => `+${e}`)
        .join(" ");

    if (searchType === "Boolean (Visual Builder)" && builder) {
      if (typeof results === "undefined")
        to_search.boolean = convertBuilderToBoolean(builder);

      if (!boolChecker(to_search.boolean)) {
        searchingSelected = false;
        searchingEverywhere = false;
        toast.error(
          "Something went wrong while trying to run your search. Please check your query is valid.",
        );
        return;
      }
    } else if (searchType !== "Boolean (Visual Builder)")
      to_search.boolean = `${selectedField}:(${to_search.text})`;

    results = [];
    let failedIndices = [];
    showAdvancedOptions = false;
    searchFrom = 0;

    moreHits = true;

    while (moreHits) {
      const response = await fetchPost("/search", {
        dataTypes: searchAll
          ? fetchedDataTypes.map((e) => e.data_type)
          : selectedDataTypes,
        rule: to_search,
        size: searchLimit,
        searchFrom: searchFrom,
      });
      failedIndices = response.failed_indices;

      if (!response.success) {
        searchingSelected = false;
        searchingEverywhere = false;
        toast.error("Search failed with error: " + response.error + ".");
        return false;
      }

      results = [...results, ...response.data];
      searchFrom += searchLimit;
      moreHits =
        response.maxed_out_hits &&
        !(results.length >= maximumHits) &&
        (searchingEverywhere || searchingSelected);
    }

    if (failedIndices !== undefined && failedIndices.length > 0) {
      toast.error(
        "Failed to search the following indices: [" +
          failedIndices.join(", ") +
          "].",
      );
    }

    if (results.length === 0) {
      if (failedIndices.length === 0) {
        toast.error("No results found.");
      }

      results = undefined;
    }

    searchingSelected = false;
    searchingEverywhere = false;
  };

  onMount(async () => {
    const response = await fetchGet("/pool");
    fetchedDataTypes = response.data;

    items = dTypesToMultiSelect(fetchedDataTypes);
    $maximised = false;
  });
  onDestroy(() => ($maximised = true));
</script>

<svelte:head>
  <title>Search - Haast</title>
</svelte:head>

<div
  class="mx-auto w-full max-w-screen-lg transition-[margin]"
  style:margin-top={typeof results === "undefined"
    ? `calc(50vh - ${searchType === "Boolean (Visual Builder)" ? "300px" : "200px"})`
    : 0}
>
  {#if typeof results === "undefined"}
    <div transition:slide>
      <img
        src="/Haast.svg"
        alt=""
        class="mx-auto mb-8 w-1/2 min-w-[240px]"
      />
    </div>
  {/if}
  {#if searchType === "Boolean (Visual Builder)"}
    {#if typeof results === "undefined"}
      <div transition:slide>
        <RuleBuilder bind:query={builder} searchModule="live" />
      </div>
    {/if}
    {#if to_search.boolean && typeof results !== "undefined"}
      <div class="mt-4 flex items-center justify-between gap-2">
        <CodeMirror
          editable={!moreHits}
          readonly={moreHits}
          bind:value={to_search.boolean}
          lang={javascript()}
          styles={{
            "&": { background: "white", fontSize: "14px" },
            ".cm-gutters": { display: "none" },
          }}
          lineWrapping={true}
        />
        <button
          class="btn btn-primary btn-xs"
          on:click={() => {
            //console.log("to_search.boolean", to_search.boolean);
            builder = convertBoolToBuilder(to_search.boolean);
            results = undefined;
          }}
        >
          Edit in Visual Rule Builder
        </button>
      </div>
    {/if}
  {:else}
    <div class="relative">
      <input
        class="input input-bordered w-full pr-16"
        id="rule_boolean"
        name="rule_boolean"
        bind:value={searchInput}
        on:keydown={(e) => {
          if (e.which === 13)
            selectedDataTypes.length > 0 ? runRule() : runRule(true);
        }}
        placeholder="Search..."
      />
      <button
        class="btn btn-ghost absolute right-0"
        on:click={() =>
          selectedDataTypes.length > 0 ? runRule() : runRule(true)}
      >
        <Icon icon="iconoir:search" />
      </button>
    </div>
  {/if}

  <div class="mt-6 flex flex-wrap items-center gap-2">
    <div class="flex flex-wrap items-center gap-1">
      <DropdownMultiSelect
        bind:selectedItems={selectedDataTypes}
        badgesInSearchboxDesign={false}
        useTidyPoolLabels={true}
        {items}
        searchBarClasses="input input-sm input-bordered w-full max-w-xs"
        loading={fetchedDataTypes.length === 0}
      />
    </div>

    <div class="flex grow items-center gap-2">
      <button
        class="btn {searchingSelected
          ? 'btn-error'
          : 'btn-primary'} btn-sm ml-auto"
        disabled={selectedDataTypes.length === 0 || searchingEverywhere}
        on:click={async () => {
          searchingSelected = !searchingSelected;

          if (searchingSelected) {
            await runRule();
          }
        }}
      >
        {searchingSelected ? "Stop Search" : "Search Selected Channels"}
      </button>

      <button
        class="btn {searchingEverywhere ? 'btn-error' : 'btn-primary'} btn-sm"
        disabled={searchingSelected}
        on:click={async () => {
          searchingEverywhere = !searchingEverywhere;

          if (searchingEverywhere) {
            await runRule(true);
            selectedDataTypes = [];
          }
        }}
      >
        {searchingEverywhere ? "Stop Search" : "Search Everywhere"}
      </button>
    </div>
  </div>
  <div class="mt-4 flex justify-end">
    <button
      class="btn btn-ghost btn-sm"
      on:click={() => (showAdvancedOptions = !showAdvancedOptions)}
    >
      {showAdvancedOptions ? "Hide" : "Show"} Advanced Options
      <Icon icon="iconoir:nav-arrow-{showAdvancedOptions ? 'up' : 'down'}" />
    </button>
  </div>

  {#if showAdvancedOptions}
    <div class="flex gap-4" transition:slide>
      <div class="mt-2 flex items-center gap-1">
        <span class="label-text">Search Field:</span>
        <Select
          bind:selectedValue={selectedField}
          items={[
            { value: "text", label: "Text" },
            { value: "html", label: "HTML" },
            { value: "body", label: "Body" },
          ]}
          size="xs"
        />
      </div>

      <div class="mt-2 flex items-center gap-1">
        <span class="label-text">Search Type:</span>
        <Select
          bind:selectedValue={searchType}
          items={[
            "Exact Phrase",
            "Contains All Words",
            "Contains Any Words",
            "Boolean (Visual Builder)",
          ].map((e) => ({ value: e, label: e }))}
          size="xs"
        />
      </div>
    </div>
  {/if}
</div>

{#if typeof results !== "undefined"}
  <SearchTable bind:data={results} {moreHits} />
{/if}
