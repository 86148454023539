<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { getTextColor } from "../../helpers";

  const dispatch = createEventDispatcher();

  export let name = "Lorem Ipsum";
  export let color = "#6EE7B7";
  export let id = -1;
  export let closeIcon = false;
  export let btnSize = "xs";
  export let disabled = false;
</script>

<button
  class="btn"
  class:btn-xs={btnSize === "xs"}
  class:btn-sm={btnSize === "sm"}
  {disabled}
  style="background-color: {color}; color: {getTextColor(color)}; border:none;"
  on:click={(event) => {
    event.stopPropagation();
    dispatch("labelBeanClicked", { name, color, id });
  }}
>
  {name}
  {#if closeIcon && !disabled}
    <Icon icon="iconoir:cancel" class="text-lg" />
  {/if}
</button>
