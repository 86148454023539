<script>
  import { onMount } from "svelte";
  import { fetchGet } from "../../helpers";
  import { org_users } from "../../stores";
  import ReportAccessPanel from "./ReportAccessPanel.svelte";
  import ReportShareDialog from "./ReportShareDialog.svelte";

  export let canEditPermissions = true;
  export let reportStatus;
  export let loading = false;
  export let assetView = false;
  export let disabled;

  const reportId = window.location.href.split("/")[5];

  let associatedUsers = [];
  let associatedTeams = [];
  let orgPermission = "none";

  let allUsers = $org_users;
  let allTeams = [];

  let entityToEditId = -1;
  let entityToEditType = "none";
  let inEditMode = false;

  function openEditDialog({ detail: { entityId, entityType } }) {
    entityToEditId = entityId;
    entityToEditType = entityType;
    inEditMode = true;
    document.querySelector("#report-share-dialog")?.showModal();
  }

  async function getOrganizationTeams() {
    try {
      const response = await fetchGet("/team");

      if (!response.success) {
        throw new Error(response.message);
      }

      allTeams = response.teams;
    } catch (error) {
      console.error(error);
    }
  }

  async function getAssociatedTeams() {
    try {
      const response = await fetchGet(`/report/${reportId}/teams`);

      if (response.success) {
        associatedTeams = response.teams;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getAssociatedUsers() {
    try {
      const response = await fetchGet(`/report/${reportId}/users`);

      if (response.success) associatedUsers = response.users;
    } catch (error) {
      console.error(error);
    }
  }

  async function getOrganizationPermission() {
    try {
      const response = await fetchGet(`/report/${reportId}/get-org-permission`);

      if (response.success) {
        orgPermission = response.permission;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getStreamDetails() {
    loading = true;
    await getAssociatedTeams();
    await getAssociatedUsers();
    await getOrganizationTeams();
    await getOrganizationPermission();
    loading = false;
  }

  onMount(async () => {
    await getStreamDetails();
  });
</script>

{#if !loading}
  <ReportAccessPanel
    {associatedTeams}
    {associatedUsers}
    {canEditPermissions}
    {disabled}
    on:edit={openEditDialog}
    on:share={() => document.querySelector("#report-share-dialog")?.showModal()}
  />
  <ReportShareDialog
    {allUsers}
    {allTeams}
    {reportId}
    {reportStatus}
    {assetView}
    bind:orgPermission
    bind:associatedTeams
    bind:associatedUsers
    bind:entityToEditId
    bind:entityToEditType
    bind:inEditMode
  />
{/if}
