<script>
  import { onDestroy, onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { link, push } from "svelte-spa-router";
  import ActionConfirmation from "../components/ActionConfirmation.svelte";
  import AssetList from "../components/AssetReview/AssetList.svelte";
  import AssetReportSubHeader from "../components/AssetReview/AssetReviewHeader/AssetReportSubHeader.svelte";
  import WorkflowBoard from "../components/AssetReview/WorkflowBoard.svelte";
  import ReportHistory from "../components/ReportHistory.svelte";
  import { fetchDelete, fetchGet } from "../helpers";
  import { AssetStatus } from "../lib/interfaces/Asset.interface";
  import { autoCheckActiveTab } from "../lib/utils/AssetReviewUtils";
  import { getTextConstant } from "../lib/utils/ConstantsUtils";
  import { calculatePercentage } from "../lib/utils/GenericUtils";
  import {
    currentUser,
    maximised,
    org_id,
    org_labels,
    org_name,
    org_users,
  } from "../stores";
  import AssetExportModal from "./AssetExportModal.svelte";

  //At some point, we need to refactor how permissions work. However, this isn't as simple as the live streams permissions (e.g. uploading is a concept we need to support, not just editing, so leaving perms intact for now)
  export let params = null;

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");

  let report_id;
  let report;
  let assets = [];

  //Active Asset
  let activeAsset = {};
  let activeAssetId = -1;
  let activeAssetName = "";
  let accessKeyer = false;
  let labels = [];
  let report_loaded = false;
  let versions = [];
  let versionIdx = 0;
  let activeVersion = {};
  let previewUrl = "";
  let previewOpen = false;
  let currentAssetApprovingPermission = false;
  let isInitialised = false;
  let workflow = {};
  let deletingAsset = false;
  let assetStatusMap = new Map();
  let activeUsers = $org_users.filter((user) => !user.is_deleted);
  let assetStatusInterval;
  // let webSocket;

  // function initialiseWebSocket() {
  //   webSocket = new WebSocketService("", {
  //     onMessage: async (message) => {
  //       if (message?.type === NotificationType.PROGRESS) {
  //         const { entity_id } = message;
  //         const assetIdx = assets.findIndex((a) => a.asset.id === entity_id);

  //         if (assetIdx !== -1) {
  //           const { status, status_counts } = message?.record;
  //           assets[assetIdx].asset.status = status;
  //           assets = assets;
  //           const assetStatus = {
  //             status: status,
  //             assetRuleStatuses: {
  //               ready: status_counts?.ready ?? 0,
  //               processing: status_counts?.processing ?? 0,
  //               failed: status_counts?.failed ?? 0,
  //               total: status_counts?.total ?? 0,
  //             },
  //             percentageProgress:
  //               status === AssetStatus.READY
  //                 ? 100
  //                 : calculatePercentage(
  //                     status_counts?.ready,
  //                     status_counts?.total,
  //                   ),
  //           };

  //           assetStatusMap.set(entity_id, assetStatus);
  //           assetStatusMap = assetStatusMap;

  //           if (status === AssetStatus.READY) {
  //             await handleUpdateAssetFromStatusChange(assetIdx);
  //           }
  //         }
  //       }
  //     },
  //     onError: (error) => {
  //       console.error("WebSocket error:", error);
  //     },
  //   });
  //   webSocket.connect();
  // }

  async function handleUpdateAssetFromStatusChange(assetIdx = -1) {
    if (assetIdx === -1) return;

    const stats = assetStatusMap.get(assets[assetIdx].asset.id);
    const errMsg = `Something went wrong updating Asset(ID: ${assets[assetIdx].asset.id})`;

    try {
      if (!assets[assetIdx].asset.id) {
        throw new Error(
          `KeyError: Unable to retrieve Asset at Index(${assetIdx})`,
        );
      }

      if (
        ![AssetStatus.FAILED, AssetStatus.SEARCH_FAILED].includes(stats.status)
      ) {
        const updatedAssetResponse = await fetchGet(
          `/asset/report/${report_id}/document/${assets[assetIdx].asset.id}`,
        );

        if (!updatedAssetResponse.success) {
          throw new Error(errMsg);
        }

        const updatedAsset = {
          ...updatedAssetResponse.asset,
          asset: {
            ...updatedAssetResponse.asset.asset,
            approvers: updatedAssetResponse.asset.approvers,
          },
        };

        assets[assetIdx] = updatedAsset;
        assets = [...assets];
        await tick();
        toast.info(`Asset(${updatedAsset.asset.name}) is ready!`);
      }
    } catch (err) {
      console.error(err);
      toast.error(errMsg);
    }
  }

  async function resetReviewProperties() {
    isInitialised = false;
    activeAsset = {};
    versions = [];
    versionIdx = 0;
    activeVersion = {};
    previewUrl = "";
    previewOpen = false;
    currentAssetApprovingPermission = false;
    activeAssetName = "";
  }

  const deleteStream = async () => {
    deletingAsset = true;
    const res = await fetchDelete("/report/" + report_id);
    deletingAsset = false;

    if (res.success) push("/reviews");
  };

  // in future while setting currentStreamPerm if  status == "Archived" set as view only
  // view, upload, edit
  let currentStreamPerm = "view";

  async function updateAssetStatuses() {
    const hasProcessingAssets = assets.some(
      (assetData) =>
        assetData.asset.status === AssetStatus.PROCESSING ||
        assetData.asset.status === AssetStatus.SEARCHING,
    );

    if (!hasProcessingAssets) {
      return;
    }

    const errMessage = `Something went wrong polling for updates for Report(ID: ${report_id}).`;

    try {
      const statusResponse = await fetchGet(
        `/asset/report/${report_id}/status`,
      );
      if (!statusResponse.success) {
        console.error(statusResponse?.message);
        throw new Error(errMessage);
      }

      statusResponse.asset_statuses.forEach(async (assetStatus) => {
        assetStatusMap.set(assetStatus.asset_id, {
          status: assetStatus.status,
          assetRuleStatuses: {
            ready: assetStatus.asset_rule_statuses?.ready ?? 0,
            processing: assetStatus.asset_rule_statuses?.processing ?? 0,
            failed: assetStatus.asset_rule_statuses?.failed ?? 0,
            total: assetStatus.asset_rule_statuses?.total ?? 0,
          },
          percentageProgress:
            status === AssetStatus.READY
              ? 100
              : calculatePercentage(
                  assetStatus.asset_rule_statuses?.ready,
                  assetStatus.asset_rule_statuses?.total,
                ),
        });

        const assetIdx = assets.findIndex(
          (a) => a.asset.id === assetStatus.asset_id,
        );
        const shouldUpdate = assetIdx !== -1;

        if (
          shouldUpdate &&
          assets[assetIdx].asset.status !== assetStatus.status
        ) {
          assets[assetIdx].asset.status = assetStatus.status;
          assets = [...assets];

          if (assetStatus.status === AssetStatus.READY) {
            await handleUpdateAssetFromStatusChange(assetIdx);
          }
        }
      });
      assetStatusMap = assetStatusMap;
    } catch (err) {
      console.error(err);
      toast.error(errMessage);
    }
  }

  onMount(async () => {
    report_id = params.report_id ? params.report_id : generate32BitInteger();
    let workflow_response = await fetchGet(`/workflow/${report_id}`);
    workflow = workflow_response.data;
    const report_response = await fetchGet(`/report/details/${report_id}`);

    report = await report_response.report;
    if (report == undefined) {
      push(`/reviews`);
      toast.error(
        `Review ${textConstants["Folder"].toLowerCase()} does not exist`,
      );
    }
    labels = $org_labels.filter((label) => {
          return report.label_ids.includes(label.id);
        });
    const res = await fetchGet(`/asset/report/${report_id}`);
    const approvers = [];
    // const approvers = await fetchGet(
    //   `/asset/report/${report_id}/approvers`,
    // );

    if (!res.success) {
      toast.error(
        `Something went wrong retrieving assets for Report(${report_id}).`,
      );
    }

    assets = res.data;
    //Merge approvers data with assets data
    assets = res.data.map((assetData) => {
      const assetId = assetData.asset.id;
      return {
        ...assetData,
        asset: {
          ...assetData.asset,
          asset_assigned_users: assetData.asset.asset_assigned_users.filter(
            (user) =>
              activeUsers.find(
                (activeUser) => activeUser.id === user.assigned_id,
              ),
          ),
          approvers: approvers[assetId] || {},
        },
      };
    });

    await updateAssetStatuses();
    // initialiseWebSocket();
    await autoCheckActiveTab(assets);

    report_loaded = true;

    if ($currentUser.role == "admin") currentStreamPerm = "edit";
    else {
      const userPerm = await fetchGet(
        `/report/${report_id}/get-user-permission-to-report`,
      );

      if (userPerm.permission === "none") {
        const orgPerm = await fetchGet(
          `/report/${report_id}/get-org-permission`,
        );
        if (orgPerm.permission === "none") push("/streams");
        else if (["view", "edit", "upload"].includes(orgPerm.permission))
          currentStreamPerm = orgPerm.permission;
      } else {
        if (["view", "edit", "upload"].includes(userPerm.permission))
          currentStreamPerm = userPerm.permission;
      }
    }
    $maximised = false;
  });

  onDestroy(() => {
    clearInterval(assetStatusInterval);
    $maximised = true;

    // if (webSocket) webSocket.close();
  });

  let activePageView = "Board View";
  const pageViews = ["Board View", "List View"];
  assetStatusInterval = setInterval(updateAssetStatuses, 10000);
</script>

<svelte:head>
  {#if report_loaded && report}
    <title>{report.name} - {textConstants["AssetReview"]} - Haast</title>
  {:else}
    <title>{textConstants["AssetReview"]} - Haast</title>
  {/if}
</svelte:head>

{#if assets && assets.length > 0}
  <AssetExportModal bind:assets />
{/if}
{#if report_loaded}
  <header class="-m-4 mb-4 bg-white p-4">
    <div class="breadcrumbs text-sm">
      <ul>
        <li>
          <a href="/reviews" use:link>{textConstants["AssetReview"]}</a>
        </li>
        <li>
          <a href="/review/{report_id}" use:link>{report.name}</a>
        </li>
      </ul>
    </div>
    <AssetReportSubHeader
      bind:reportLoaded={report_loaded}
      bind:report
      bind:reportId={report_id}
      bind:currentStreamPerm
      bind:assets
      bind:accessKeyer
      bind:labels
      bind:isInitialised
    />
  </header>
{/if}
{#if !report_loaded}
  <div class="text-center">
    <div class="loading loading-lg m-auto" />
  </div>
{:else}
  <div class="tabs relative mb-2 shrink-0 overflow-hidden py-2">
    {#each pageViews as tab}
      <button
        class="tab whitespace-nowrap text-base font-semibold capitalize"
        class:text-primary={activePageView === tab}
        on:click={() => {
          activePageView = tab;
          autoCheckActiveTab(assets);
        }}
      >
        {tab}
      </button>
    {/each}
    <div
      class="slider bg-primary absolute bottom-0 left-0 h-0.5 transition-all"
      style:width="{100 / pageViews.length}%"
      style="translate: {pageViews.findIndex((i) => i === activePageView) *
        100}%"
    />
  </div>

  {#if activePageView === "Board View"}
    {#key (assets, workflow, report_id)}
      <WorkflowBoard
        bind:assets
        bind:currentStreamPerm
        {report_id}
        {workflow}
        {assetStatusMap}
      />
    {/key}
  {:else if activePageView === "List View"}
    <AssetList
      bind:assets
      bind:activeAssetId
      bind:report_loaded
      bind:reportId={report_id}
      {workflow}
      {assetStatusMap}
    />
  {/if}
{/if}

<!-- modals below -->
<ReportHistory reportId={report_id} />
<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  message="Are you sure you want to delete this {textConstants[
    'Folder'
  ].toLowerCase()}?"
  onConfirm={async () => {
    await deleteStream();
  }}
  onCancel={() => confirmModal.close()}
  showLoadingSpinner={true}
  loading={deletingAsset}
/>
