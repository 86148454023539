<script>
  import {
    BaseEdge,
    EdgeLabelRenderer,
    useEdges,
    getBezierPath,
  } from "@xyflow/svelte";
  import Icon from "@iconify/svelte";

  export let id;
  export let sourceX;
  export let sourceY;
  export let sourcePosition;
  export let targetX;
  export let targetY;
  export let targetPosition;
  export let markerEnd = undefined;
  export let style = undefined;

  $: [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const edges = useEdges();

  const onEdgeClick = () =>
    edges.update((eds) => eds.filter((edge) => edge.id !== id));
</script>

<BaseEdge path={edgePath} {markerEnd} {style} />
<EdgeLabelRenderer>
  <div
    class="edgeButtonContainer nodrag nopan flex items-center justify-center rounded-full border p-2 shadow-lg"
    style="background-color:white; opacity:1; transform: translate(-50%, -50%) translate({labelX}px,{labelY}px);"
  >
    <button class="edgeButton" on:click={onEdgeClick}>
      <Icon icon="iconoir:plus" class="text-2xl" /></button
    >
  </div>
</EdgeLabelRenderer>

<style>
  .edgeButtonContainer {
    position: absolute;
    /* font-size: 12pt; */
    /* everything inside EdgeLabelRenderer has no pointer events by default */
    /* if you have an interactive element, set pointer-events: all */
    pointer-events: all;
  }

  /* .edgeButton {
      width: 20px;
      height: 20px;
      background: #eee;
      border: 1px solid #fff;
      cursor: pointer;
      border-radius: 50%;
      font-size: 12px;
      line-height: 1;
    } */

  .edgeButton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
  }

  :global(.svelte-flow .handle) {
    background-color: transparent;
    pointer-events: none;
    min-width: 0;
    min-height: 0;
    width: 0;
    height: 0;
    background-color: var(
      --xy-handle-background-color,
      var(--xy-handle-background-color-default)
    );
    border: 0;
    border-radius: 0;
  }
</style>
