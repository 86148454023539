<article class="overflow-x-auto rounded border shadow-sm">
  <header
    class="bg-base-200 flex flex-col justify-between gap-4 px-8 py-6 lg:flex-row"
  >
    <div>
      <slot name="header" />
    </div>
    {#if $$slots.beside}
      <div>
        <slot name="beside" />
      </div>
    {/if}
  </header>
  <div class="px-8 pb-6 pt-4">
    <slot />
  </div>
</article>
