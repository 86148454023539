<script>
  import Icon from "@iconify/svelte";
  import { toast } from "svelte-sonner";
  import {
    fetchGet,
    getDisplayDate,
    openNotif,
    updateNotifStore,
  } from "../../../helpers";
  import { notifications } from "../../../stores";

  let pageAmount = 5;
  let showOnlyUnread = false;
  let allLoaded = false;

  const loadMore = async () => {
    if (allLoaded) {
      toast.error("All notifications already shown");
      return;
    }

    pageAmount += 5;

    const res = await fetchGet(
      `/notification?currentPage=1&pageAmount=${pageAmount}&type=${showOnlyUnread ? "unread" : "all"}`,
    );
    if (res.success) {
      $notifications = {
        ...$notifications,
        notifications: res.notifications,
        total_notifications: showOnlyUnread
          ? res.unseen_notifications
          : res.total_notifications,
      };
      allLoaded =
        res.notifications.length >=
        (showOnlyUnread ? res.unseen_notifications : res.total_notifications);
    }
  };

  const toggleUnread = async () => {
    showOnlyUnread = !showOnlyUnread;
    pageAmount = 5;
    allLoaded = false;
    const res = await fetchGet(
      `/notification?currentPage=1&pageAmount=${pageAmount}&type=${showOnlyUnread ? "unread" : "all"}`,
    );
    if (res.success) {
      $notifications = {
        ...res,
        total_notifications: showOnlyUnread
          ? res.unseen_notifications
          : res.total_notifications,
      };
      allLoaded =
        res.notifications.length >=
        (showOnlyUnread ? res.unseen_notifications : res.total_notifications);
    }
  };

  const dropdownOpened = async () => {
    pageAmount = 5;
    allLoaded = false;
    await updateNotifStore();
    document.querySelector("#notificationsDropdown").scrollTop = 0;
    const res = await fetchGet(
      `/notification?currentPage=1&pageAmount=${pageAmount}&type=${showOnlyUnread ? "unread" : "all"}`,
    );
    if (res.success) {
      $notifications = {
        ...res,
        total_notifications: showOnlyUnread
          ? res.unseen_notifications
          : res.total_notifications,
      };
      allLoaded =
        res.notifications.length >=
        (showOnlyUnread ? res.unseen_notifications : res.total_notifications);
    }
  };
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div class="dropdown dropdown-end">
  <div
    tabindex="0"
    role="button"
    class="btn btn-square btn-ghost relative"
    on:focus={dropdownOpened}
  >
    <Icon icon="iconoir:bell" class="text-xl" />
    <span
      class="badge badge-primary badge-xs absolute right-0 top-0 outline outline-1"
    >
      {$notifications.unseen_notifications > 99
        ? "99+"
        : $notifications.unseen_notifications}
    </span>
  </div>
  <ul
    tabindex="0"
    class="dropdown-content bg-base-100 text-base-content z-[1] mt-1 flex max-h-[80vh] w-96 flex-col overflow-auto rounded border p-2 shadow"
    id="notificationsDropdown"
  >
    <h2 class="pl-2 text-xl font-bold">Notifications</h2>

    <div class="flex gap-2 py-2">
      <button
        class="btn btn-outline btn-primary btn-sm rounded-full"
        class:btn-active={!showOnlyUnread}
        on:click={toggleUnread}
      >
        All
      </button>
      <button
        class="btn btn-outline btn-primary btn-sm rounded-full"
        class:btn-active={showOnlyUnread}
        on:click={toggleUnread}
      >
        Unread
      </button>
    </div>

    {#each $notifications.notifications as n}
      <button
        class="hover:bg-base-200 relative flex flex-col gap-1 rounded p-2 pr-6 text-start"
        on:click={() => openNotif(n.id, n.url, n.seen)}
      >
        {#if !n.seen}
          <div
            class="badge badge-primary badge-xs absolute right-2 top-2"
          ></div>
        {/if}

        <p class="line-clamp-3 text-sm" title={n.text}>{n.text}</p>
        <p class="text-base-content/70 text-xs">
          {getDisplayDate(n.created_date)}
        </p>
      </button>
    {/each}

    <div class:tooltip={allLoaded} data-tip="All notifications already shown">
      <button
        class="btn btn-sm mt-2 w-full"
        class:btn-disabled={allLoaded}
        on:click={loadMore}
      >
        See previous notifications
      </button>
    </div>
  </ul>
</div>
