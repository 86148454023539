<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { RuleScope } from "../../../lib/interfaces/Rule.interface";
  import { NoRuleSetDefaults } from "../../../lib/interfaces/RuleSet.interface";
  import { SharedRulePermissions } from "../../../lib/interfaces/SharedRule.interface";
  import {
    currentUser,
    org_teams,
    org_users,
    user_teams,
  } from "../../../stores";
  import ChecklistInfoModal from "../../ChecklistInfoModal.svelte";
  import CopyChecklistModal from "../../Modals/CopyChecklistModal.svelte";
  import DeleteChecklistModal from "../../Modals/DeleteChecklistModal.svelte";
  import EditChecklistModal from "../../Modals/EditChecklistModal.svelte";
  import ShareChecklistModal from "../../Modals/ShareChecklistModal.svelte";
  import {
    getInitials,
    getTextColor,
    nameToColor,
  } from "./../../../helpers.js";
  import SavedRulesTableRowRules from "./SavedRulesTableRowRules.svelte";

  const dispatch = createEventDispatcher();

  export let ruleScope = RuleScope.MY;
  export let ruleSet = {};
  export let ruleSets = [];
  export let sharedWith = [];
  export let assetRulesTable = false;
  export let ruleToDelete = null;
  export let ruleToEdit = null;
  export let convertingSharedRule = false;
  export let ruleToCopy = null;
  export let ruleSetIdToDelete = -1;
  export let isViewing = false;

  let associatedUsers = new Map();
  let associatedTeams = new Map();
  let collapse = true;
  let ownerName = "No owner";
  let activeRuleId = -1;
  let userChecklistAccessPermission = SharedRulePermissions.VIEW;
  let sharedUsersAndTeams = [];

  function getOwnerName() {
    const ownerUser = $org_users.find(
      (ou) => ou.id === ruleSet.rule_set?.owner,
    );

    if (ownerUser) {
      ownerName = `${ownerUser.first_name} ${ownerUser.last_name}`;
    } else {
      ownerName = "No owner";
    }
  }

  function getUserChecklistPermission() {
    if (
      (ruleSet.rule_set.owner &&
        ruleSet.rule_set?.owner === $currentUser.id &&
        ruleScope !== RuleScope.INDUSTRY) ||
      sharedWith.some(
        (sr) =>
          sr.parent_rule_set_id === ruleSet.rule_set?.id &&
          (!!$user_teams.find((ut) => ut.id === sr.team_id) ||
            sr.user_id === $currentUser.id) &&
          sr.permission === SharedRulePermissions.EDIT,
      )
    ) {
      return SharedRulePermissions.EDIT;
    }

    return SharedRulePermissions.VIEW;
  }

  async function populateAssociatedUsersTeams() {
    associatedUsers = new Map();
    associatedTeams = new Map();

    if (
      !ruleSet?.rule_set?.id ||
      !sharedWith.some((sr) => sr.parent_rule_set_id === ruleSet.rule_set.id)
    ) {
      return;
    }

    const sharedRuleUsers = new Map();
    const sharedRuleTeams = new Map();

    sharedWith.forEach((sr) => {
      if (sr.parent_rule_set_id === ruleSet.rule_set.id && sr.user_id !== -1) {
        sharedRuleUsers.set(sr.user_id, sr);
      } else if (
        sr.parent_rule_set_id === ruleSet.rule_set.id &&
        sr.team_id !== -1
      ) {
        sharedRuleTeams.set(sr.team_id, sr);
      }
    });

    $org_users.forEach((ou) => {
      if (sharedRuleUsers.has(ou.id) && !associatedUsers.has(ou.id)) {
        associatedUsers.set(ou.id, {
          ...ou,
          permission: sharedWith.find(
            (sr) =>
              sr.parent_rule_set_id === ruleSet.rule_set?.id &&
              sr.user_id === ou.id,
          )?.permission,
        });
        associatedUsers = associatedUsers;
      }
    });
    $org_teams.forEach((ot) => {
      if (sharedRuleTeams.has(ot.id) && !associatedTeams.has(ot.id)) {
        associatedTeams.set(ot.id, {
          ...ot,
          permission: sharedWith.find(
            (sr) =>
              sr.parent_rule_set_id === ruleSet.rule_set?.id &&
              sr.team_id === ot.id,
          )?.permission,
        });
        associatedTeams = associatedTeams;
      }
    });

    let shareItems = [...associatedUsers].map(([userId, user]) => ({
      id: userId,
      name: `${user.first_name} ${user.last_name}`,
      type: "user",
      permission: user.permission,
    }));

    shareItems = [...shareItems, ...([...associatedTeams].map(([teamId, team]) => ({
      id: teamId,
      name: team.name,
      type: "team",
      permission: team.permission,
    })))];

    sharedUsersAndTeams = shareItems;
  }

  onMount(async () => {
    await populateAssociatedUsersTeams();
    getOwnerName();
    userChecklistAccessPermission = getUserChecklistPermission();
  });

  $: populateAssociatedUsersTeams(), [ruleSet, sharedWith];
  $: (userChecklistAccessPermission = getUserChecklistPermission()),
    [ruleSet, sharedWith];
</script>

<tr>
  <td class="max-w-[20vw]">
    <p class="flex items-center gap-2 pl-2 font-semibold">
      {ruleSet.rule_set?.name}
      <ChecklistInfoModal data={ruleSet.rule_set} />
    </p>
  </td>
  {#if ruleScope === RuleScope.SHARED}
    <td>
      <div class="avatar-group -space-x-2 overflow-visible">
        <div class="tooltip" data-tip={ownerName}>
          <div class="avatar placeholder border-2">
            <div
              class="w-8 rounded-full"
              style:background-color={nameToColor(getInitials(ownerName))}
              style:color={getTextColor(nameToColor(getInitials(ownerName)))}
            >
              <span>{getInitials(ownerName)}</span>
            </div>
          </div>
        </div>
      </div>
    </td>
  {/if}
  {#if ruleScope !== RuleScope.INDUSTRY}
    <td>
      {#if ruleSet.rule_set?.id !== NoRuleSetDefaults.ID}
        <div class="avatar-group -space-x-2 overflow-visible">
            {#each sharedUsersAndTeams.slice(0, 3) as {id, name, type, permission}}
              {@const initials = getInitials(name)}
              <div
                class="tooltip"
                data-tip="{name}
                ({permission === 'edit' ? 'editor' : 'viewer'})"
              >
                <div
                  class="avatar placeholder border-2"
                  id="{type}-{id}-avatar"
                >
                  <div
                    class="w-8 rounded-full"
                    style:background-color={nameToColor(initials)}
                    style:color={getTextColor(nameToColor(initials))}
                  >
                    <span>{initials}</span>
                  </div>
                </div>
              </div>
            {/each}
            {#if sharedUsersAndTeams.length > 3}
              <div
                class="avatar placeholder border-2"
                id="more-users-teams-avatar"
              >
                <div
                  class="w-8 rounded-full"
                  style:background-color="gray"
                  style:color="white"
                >
                  <span>+{sharedUsersAndTeams.length - 3}</span>
                </div>
              </div>
            {/if}
        </div>
      {/if}
    </td>
  {/if}
  <td>
    <div class="flex items-center gap-1 pr-6">
      <div class="ml-auto"></div>
      {#if ruleSet.rule_set?.id !== NoRuleSetDefaults.ID}
        {#if ruleScope !== RuleScope.INDUSTRY}
          <button
            class="btn btn-outline btn-primary btn-sm"
            on:click|stopPropagation={() =>
              document
                .querySelector(`#share-checklist-${ruleSet.rule_set?.id}`)
                ?.showModal()}
          >
            Share
          </button>
        {/if}
        {#if userChecklistAccessPermission === SharedRulePermissions.EDIT && ruleScope !== RuleScope.INDUSTRY}
          <button
            class="btn btn-outline btn-primary btn-sm"
            on:click|stopPropagation={() =>
              document
                .querySelector(`#edit-checklist-${ruleSet.rule_set?.id}`)
                ?.showModal()}
          >
            Edit
          </button>
        {/if}
        {#if ruleScope !== RuleScope.SHARED}
          <button
            class="btn btn-outline btn-primary btn-sm"
            on:click|stopPropagation={() =>
              document
                .querySelector(`#duplicate-checklist-${ruleSet.rule_set?.id}`)
                ?.showModal()}
          >
            Copy
          </button>
        {/if}
        {#if userChecklistAccessPermission === SharedRulePermissions.EDIT && ruleScope !== RuleScope.INDUSTRY}
          <button
            class="btn btn-outline btn-error btn-sm"
            on:click|stopPropagation={() => {
              document
                .getElementById(`delete-checklist-${ruleSet.rule_set?.id}`)
                .showModal();
            }}
          >
            Delete
          </button>
        {/if}
      {/if}
      <button
        class="btn btn-square btn-ghost btn-sm"
        on:click={() => (collapse = !collapse)}
      >
        <Icon
          icon={collapse ? "iconoir:nav-arrow-up" : "iconoir:nav-arrow-down"}
          class="text-xl"
        />
      </button>
    </div>
  </td>
</tr>

{#if !collapse}
  <SavedRulesTableRowRules
    bind:ruleScope
    bind:ruleSet
    {sharedWith}
    bind:activeRuleId
    bind:assetRulesTable
    bind:ruleToCopy
    bind:ruleToDelete
    bind:ruleToEdit
    bind:convertingSharedRule
    bind:userChecklistAccessPermission
    bind:isViewing
  />
{/if}

<ShareChecklistModal
  bind:ruleSet
  bind:sharedWith
  bind:associatedUsers
  bind:associatedTeams
  bind:userChecklistAccessPermission
  existingUsers={associatedUsers ? new Map(associatedUsers) : new Map()}
  existingTeams={associatedTeams ? new Map(associatedTeams) : new Map()}
  on:updateRuleSets={() => dispatch("updateRuleSets")}
/>
<EditChecklistModal
  bind:ruleSet
  bind:ruleSets
  updateRules={false}
  on:updateRuleSets={() => {
    dispatch("updateRuleSets");
  }}
/>
<CopyChecklistModal
  bind:ruleSet
  bind:ruleSets
  on:updateRuleSets={() => {
    dispatch("updateRuleSets");
  }}
/>
<DeleteChecklistModal
  bind:ruleSet
  bind:ruleSetIdToDelete
  on:deleteRuleSet={() => {
    ruleSetIdToDelete = ruleSet.rule_set?.id;
    dispatch("deleteRuleSet");
  }}
/>
