<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import {
    boolChecker,
    buildExamples,
    fetchGet,
    fetchPatch,
    fetchPost,
    tidyPoolLabel,
  } from "../../helpers";
  import {
    RuleDataType,
    RuleSearchType,
  } from "../../lib/interfaces/Rule.interface";
  import { NoRuleSetDefaults } from "../../lib/interfaces/RuleSet.interface";
  import { SharedRulePermissions } from "../../lib/interfaces/SharedRule.interface";
  import { convertBuilderToBoolean } from "../../ruleBuilderConverter";
  import { currentUser, user_teams } from "../../stores";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import AssetRuleBuilder from "../AssetReview/AssetRuleBuilder.svelte";
  import AssetRuleTypeSelector from "../AssetReview/AssetRuleTypeSelector.svelte";
  import ConditionalAssetRuleBuilder from "../AssetReview/ConditionalAssetRuleBuilder.svelte";
  import Modal from "../Modals/Modal.svelte";
  import Rule from "../Rule.svelte";

  const dispatch = createEventDispatcher();

  export let rule;
  export let ruleSets;
  export let sharedWith;

  let ruleSetSearch = "";
  let selectableRuleSets = [];
  let originalRuleBoolean = rule["boolean"];
  let originalRuleName = rule["name"];
  let originalRuleDescription = rule["description"];
  let originalRuleDataTypes = rule["data_type"];
  let originalDefaultHitPriority = rule["default_hit_priority"];
  let d_types = [];
  let savingRule = false;
  let selectedDataTypes = rule["data_type"].split(", ");
  $: if (selectedDataTypes) {
    updateDataTypes();
  }

  let data = {
    loaded: false,
    index: -1,
    name: rule.name,
    type: RuleDataType.BUILDER,
    description: rule.description,
    remediation_step: rule.remediation_step,
    data_types: selectedDataTypes,
    size: 10000,
    boolean: rule.boolean,
    builder: [],
  };
  let ruleSetActionQueue = [];

  async function updateRule() {
    if (!boolChecker(rule.boolean)) {
      toast.warning("Rule is not valid, please check the syntax.");
      return false;
    } else rule.boolean = boolChecker(rule.boolean);

    const response = await fetchPatch(`/rule/${rule.id}`, {
      boolean: rule.boolean,
      name: rule.name,
      description: rule.description,
      remediation_step: rule.remediation_step,
      data_type: rule.data_type,
      default_hit_priority: rule.default_hit_priority,
    });

    if (!response.success)
      console.error("Something went wrong while updating rule", rule["id"]);

    document.querySelector(`#ruleEdit-${rule?.id}`).close();

    dispatch("editSaved", rule);

    return true;
  }

  function updateDataTypes() {
    rule["data_type"] = selectedDataTypes.join(", ");
  }

  function cancelEdit() {
    rule["boolean"] = originalRuleBoolean;
    rule["name"] = originalRuleName;
    rule["description"] = originalRuleDescription;
    rule["data_type"] = originalRuleDataTypes;
    rule["default_hit_priority"] = originalDefaultHitPriority;

    dispatch("editCancelled");

    document.querySelector(`#ruleEdit-${rule?.id}`).close();
  }

  async function updateDTypes() {
    const res = await fetchGet("/pool");
    d_types = await res.data;

    for (let i = 0; i < d_types.length; i++)
      d_types[i].label = tidyPoolLabel(d_types[i].label);
  }

  async function handleRuleSetUpdate() {
    for (const actionIdx in ruleSetActionQueue) {
      const action = ruleSetActionQueue[actionIdx];
      let response;

      switch (action.type) {
        case "remove":
          response = await fetchPost("/ruleset/remove-from-ruleset", {
            rule_set_id: action.ruleSetId,
            rule_id: action.ruleId,
          });
          break;

        case "add":
          response = await fetchPost("/ruleset/add-to-ruleset", {
            rule_set_id: action.ruleSetId,
            rule_id: action.ruleId,
          });
          break;

        default:
          break;
      }
    }
  }

  onMount(async () => {
    await updateDTypes();
    updateSelectableRuleSets();
  });

  const editAssetRule = async (rule) => {
    if (rule.search_type == RuleSearchType.BOOLEAN) {
      rule.lexical = rule.queryBoolean;
    }

    const res = await fetchPatch(`/asset/rule/${rule.id}`, {
      rule: {
        examples: buildExamples(rule),
        operator: rule.operator,
        //TODO: rewrite this as it assumes it is only edited as a boolean
        boolean:
          rule.search_type === RuleSearchType.BOOLEAN
            ? convertBuilderToBoolean(rule.builder)
            : "",
        doc_query:
          rule.search_type === RuleSearchType.BOOLEAN
            ? convertBuilderToBoolean(rule.builder)
            : rule.doc_query,
        section_query: handleSectionQuery(rule),
        index: rule?.data_type ?? "vector-db-index", // Default to vector-db-index if no index nor data type
        name: rule.name,
        description: rule.description,
        remediation_step: rule.remediation_step,
        strong_match_threshold: rule.strong_match_threshold,
        no_match_threshold: rule.no_match_threshold,
        affirmative_rule: rule.affirmative_rule,
        search_type: rule.search_type,
        // search_sources: Object.entries(rule.search_sources)
        //   .filter(([key, value]) => value)
        //   .map(([key, _]) => key),

        limit: rule.limit,
        exclusion_weight: rule.exclusion_weight,
        type: rule.type,
        context: rule.context,
        definitions: rule.definitions,
      },
      // stream_id: report_id,
    });

    return res;
  };

  function handleSectionQuery(rule) {
    if (rule.search_type === RuleSearchType.BOOLEAN) {
      // Checks if section_query is defined and not empty
      if (rule.section_query && rule.section_query.trim().length > 0) {
        return rule.section_query + " AND " + rule.lexical;
      } else {
        return rule.lexical || ""; // Fallback to lexical or empty string if lexical is undefined
      }
    }
    return rule.section_query || ""; // Return existing section_query or empty string if undefined
  }

  const saveAssetRule = async (idx) => {
    await handleRuleSetUpdate();
    const resp = await editAssetRule(rule);

    if (resp.success) {
      toast.success("Rule saved successfully");
      dispatch("editSaved", rule);
    }
  };

  const handleSaveConditionalRule = async (e) => {
    savingRule = true;
    try {
      const conditionalRule = e.detail;

      await Promise.all(
        [conditionalRule.ifRule, ...conditionalRule.thenRules].map((r) =>
          editAssetRule(r),
        ),
      );
      await handleRuleSetUpdate();

      toast.success("Rule saved successfully");
      dispatch("editSaved", rule);
    } catch (error) {
      console.error("Failed to save conditional rule", error);
      toast.error("Failed to save conditional rule");
    }
    savingRule = false;
  };

  const createRuleSet = async () => {
    const res = await fetchPost("/ruleset/create", {
      name: ruleSetSearch,
      description: "",
      sharing: false,
    });

    if (!res.success) {
      toast.error("Failed to create Checklist");
      return;
    }

    toast.success("Checklist created successfully");
    ruleSetSearch = "";
    dispatch("updateRuleSets");
  };

  let assetRuleStep = 2;
  let editingSavedRule = true;

  function updateSelectableRuleSets() {
    selectableRuleSets = [
      ...ruleSets.filter(
        (rs) =>
          (rs.owner === $currentUser.id ||
            sharedWith.some(
              (sr) =>
                sr.parent_rule_set_id === rs?.id &&
                (!!$user_teams.find((ut) => ut.id === sr.team_id) ||
                  sr.user_id === $currentUser.id) &&
                sr.permission === SharedRulePermissions.EDIT,
            )) &&
          !rs?.rules?.includes(rule.id),
      ),
    ];
  }

  $: updateSelectableRuleSets(), [ruleSets];
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-label-has-associated-control -->
<!-- svelte-ignore a11y-click-events-have-key-events -->

<Modal modalId="ruleEdit-{rule?.id}" size="xl" on:close={cancelEdit}>
  <h3 class="text-xl font-semibold">Edit Rule</h3>

  <div class="mb-4 mt-6 flex flex-wrap gap-2">
    {#each ruleSets as ruleSet}
      {#if ruleSet?.rules?.includes(rule.id)}
        <button
          class="btn btn-outline btn-primary btn-sm"
          on:click={() => {
            ruleSet.rules = ruleSet.rules.filter((r) => r !== rule.id);
            ruleSetActionQueue = [
              ...ruleSetActionQueue,
              { type: "remove", ruleSetId: ruleSet.id, ruleId: rule.id },
            ];
          }}
        >
          {ruleSet.name}
          <div class="bg-base-100 rounded-full p-1">
            <Icon icon="ic:baseline-close" class="h-4 w-4 text-black" />
          </div>
        </button>
      {/if}
    {/each}

    <div
      class={ruleSets.filter(
        (rs) =>
          rs.rules &&
          rs?.rules?.includes(rule.id) &&
          rs.id !== NoRuleSetDefaults.ID,
      ).length >= 1
        ? "tooltip"
        : ""}
      data-tip="A rule can only be assigned to one checklist."
    >
      <div class="dropdown">
        <label
          tabindex="0"
          class="btn btn-sm {ruleSets.filter(
            (rs) =>
              rs.rules &&
              rs?.rules?.includes(rule.id) &&
              rs.id !== NoRuleSetDefaults.ID,
          ).length >= 1
            ? 'btn-disabled'
            : 'btn-primary'}"
        >
          Add Checklist
        </label>
        <div
          tabindex="0"
          class="dropdown-content bg-base-100 z-[1] w-52 rounded border p-2 shadow"
        >
          <input
            type="text"
            placeholder="Search checklists..."
            class="input input-sm input-bordered mb-2 w-full"
            disabled={ruleSets.filter(
              (rs) =>
                rs.rules &&
                rs?.rules?.includes(rule.id) &&
                rs.id !== NoRuleSetDefaults.ID,
            ).length >= 1}
            bind:value={ruleSetSearch}
          />

          <ul class="menu max-h-80 flex-nowrap overflow-auto p-0">
            {#each selectableRuleSets as ruleSet}
              {#if ruleSet?.name
                ?.toLowerCase()
                ?.includes(ruleSetSearch.toLowerCase()) && !ruleSet?.rules?.includes(rule?.id)}
                <li>
                  <button
                    disabled={ruleSets.filter(
                      (rs) =>
                        rs?.rules &&
                        rs?.rules?.includes(rule.id) &&
                        rs.id !== NoRuleSetDefaults.ID,
                    ).length >= 1}
                    on:click={async () => {
                      ruleSet.rules = [...ruleSet.rules, rule.id];
                      ruleSets = [...ruleSets];
                      ruleSetActionQueue = [
                        ...ruleSetActionQueue,
                        {
                          type: "add",
                          ruleSetId: ruleSet.id,
                          ruleId: rule.id,
                        },
                      ];
                      updateSelectableRuleSets();
                    }}
                  >
                    {ruleSet.name}
                  </button>
                </li>
              {/if}
            {/each}
            {#if ruleSets.filter((rs) => rs.name
                .toLowerCase()
                .includes(ruleSetSearch.toLowerCase())).length === 0 && ruleSetSearch.trim() !== ""}
              <li>
                <button
                  disabled={rule?.is_conditional}
                  on:click={async () => {
                    await createRuleSet();
                  }}
                >
                  Create: {ruleSetSearch}
                </button>
              </li>
            {/if}
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <!-- svelte-ignore missing-declaration -->
  {#if rule.type == "asset"}
    {#if assetRuleStep === 1}
      <AssetRuleTypeSelector bind:value={rule.rule_type} bind:rule />

      <button
        on:click={() => (assetRuleStep = 2)}
        class="btn btn-primary btn-sm mt-2"
      >
        Select
      </button>
    {:else}
      <!-- for conditional rule -->
      {#if rule.is_conditional && rule?.sequence_order === 0}
        <ConditionalAssetRuleBuilder
          editingSavedRule={true}
          conditionalRuleData={rule}
          on:removeRule={cancelEdit}
          on:saveConditionalRule={handleSaveConditionalRule}
          loading={savingRule}
        />
      {:else}
        <AssetRuleBuilder
          bind:rule
          {savingRule}
          bind:editingSavedRule
          on:removeRule={cancelEdit}
          on:saveRule={(e) => saveAssetRule(e.detail)}
          on:changeRuleType={() => {
            document.querySelector(`#confirmRuleTypeChange`)?.showModal();
          }}
        />

        <Modal
          modalId="confirmRuleTypeChange"
          bottomButtons={{
            show: true,
            primaryText: "Switch rule type",
            secondaryText: "Cancel",
            primaryAction: () => {
              assetRuleStep = 1;
              document.querySelector(`#confirmRuleTypeChange`)?.close();
            },
            secondaryAction: () => document.querySelector(`#confirmRuleTypeChange`)?.close(),
          }}
          containerClasses="flex flex-col gap-4 items-center"
          cornerCloseButton={false}
        >
          <span
            class="bg-error flex h-12 w-12 items-center justify-center rounded-full"
          >
            <Icon icon="iconoir:warning-triangle" class="text-xl text-white" />
          </span>
    
          <h3 class="font-semibold">Are you sure you want to switch rule type?</h3>
          <p class="text-base-content/70 text-center text-sm">
            If you switch rule type,
            <span class="font-semibold">you will lose your progress</span>
            with the current rule type.
          </p>
        </Modal>
      {/if}
    {/if}

    <!--TODO: implement these functions-->
  {:else}
    <Rule
      index={0}
      bind:data
      {d_types}
      on:saveRule={async () => {
        rule.name = data.name;
        rule.description = data.description;
        rule.remediation_step = data.remediation_step;
        rule.boolean = convertBuilderToBoolean(data.builder);
        selectedDataTypes = data.data_types;

        for (const actionIdx in ruleSetActionQueue) {
          const action = ruleSetActionQueue[actionIdx];
          let response;

          switch (action.type) {
            case "remove":
              response = await fetchPost("/ruleset/remove-from-ruleset", {
                rule_set_id: action.ruleSetId,
                rule_id: action.ruleId,
              });
              break;

            case "add":
              response = await fetchPost("/ruleset/add-to-ruleset", {
                rule_set_id: action.ruleSetId,
                rule_id: action.ruleId,
              });
              break;

            default:
              break;
          }
        }

        document.querySelector(`#ruleEdit-${rule?.id}`).close();

        if (
          originalRuleBoolean !== rule.boolean ||
          originalRuleDataTypes !== rule.data_type
        )
          document.getElementById(`editModal-${rule.id}`).showModal();
        else await updateRule();
      }}
      runRule={() => console.log("do nothing for now")}
      deleteRule={cancelEdit}
      bind:defaultRulePriority={rule.default_hit_priority}
    />
  {/if}
</Modal>

<ActionConfirmation
  message="You are about to edit this rule's search parameters. This will cause all existing results to be removed, with results for the new rule returned. This operation is not reversible. Are you sure you want to edit this rule?"
  modalId="editModal-{rule.id}"
  onConfirm={async () => {
    document.getElementById(`editModal-${rule.id}`).close();

    if (!(await updateRule()))
      document.querySelector(`#ruleEdit-${rule?.id}`)?.showModal();
  }}
  onCancel={() => {
    document.getElementById(`editModal-${rule.id}`).close();

    cancelEdit();
  }}
/>
