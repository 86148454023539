<script>
  import { onMount } from "svelte";
  import { currentUser } from "../../stores";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import Modal from "../Modals/Modal.svelte";
  import Pagination from "../table/Pagination.svelte";

  export let dictionary;

  let rules = [];

  let canEdit =
    dictionary.collaborators.includes($currentUser.id) ||
    dictionary.owner.includes($currentUser.id);

  let currentPage = 1;
  let itemsPerPage = 50;

  let ruleEditingId = -1;
  let ruleEditingText = "";

  let ruleToDeleteId = -1;

  async function loadDictionaryRules() {
    //Todo: implement an API call that loads the rules nested in the dictionary function
    rules = [
      {
        id: 1, //ID for the rule
        text: "a-maizing", //the word / phrase that is ignored. Should be case sensitive and punctuation inclusive
      },
      {
        id: 2, //ID for the rule
        text: "Large Llama", //the word / phrase that is ignored. Should be case sensitive and punctuation inclusive
      },
    ];

    // To generate some bulk data I've put the function in below. Obviously this should be deleted once rules is actually implemented

    for (let i = 3; i < 103; i++) {
      rules.push({ id: i, text: generateRandomText(20) });
    }
  }

  //Delete when no longer needed
  function generateRandomText(length) {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomText = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      randomText += charset[randomIndex];
    }

    return randomText;
  }

  function deleteRule() {
    //To implement api call to delete rule

    rules = rules.filter((r) => r.id != ruleToDeleteId);
    ruleToDeleteId = -1;
  }

  function editRule() {
    //To implement api call to edit rule

    rules.some((r) => {
      if (r.id == ruleEditingId) {
        r.text = ruleEditingText;
        return true;
      }
    });

    ruleEditingId = -1;
    ruleEditingText = "";
    rules = rules;
  }

  onMount(async () => {
    loadDictionaryRules();
  });
</script>

<div class="my-8">
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
  <div class=" grid w-full gap-2 overflow-auto p-2">
    <div class="grid min-w-[750px] grid-cols-2 border-b px-6 font-semibold">
      <div
        class=" col-span-1 cursor-pointer justify-start gap-4"
        on:click|stopPropagation
      >
        <span class="label-text">Word / Phrase</span>
      </div>
      <span class="col-span-1 text-end">Actions</span>
    </div>
    {#if rules?.length > 0}
      {#each rules.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as rule}
        <div class="bg-base-200 rounded border">
          <div
            class="flex cursor-pointer items-center justify-between gap-2 px-6 py-2"
          >
            <div
              class="label col-span-1 cursor-pointer justify-start gap-4"
              on:click|stopPropagation
            >
              <div>
                <span class="label-text">{rule.text}</span>
              </div>
            </div>

            <div class="flex items-center gap-1">
              {#if canEdit}
                <button
                  class="btn btn-outline btn-primary btn-xs"
                  on:click={() => {
                    ruleEditingId = rule.id;
                    ruleEditingText = rule.text;
                    editWordModal.showModal();
                  }}
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline btn-error btn-xs"
                  on:click={() => {
                    ruleToDeleteId = rule.id;
                    ruleDeleteModal.showModal();
                  }}
                >
                  Delete
                </button>
              {/if}
            </div>
          </div>
        </div>
      {/each}

      <Pagination bind:currentPage bind:itemsPerPage totalHits={rules.length} />
    {/if}
  </div>
</div>

<Modal
  modalId="editWordModal"
  on:close={() => (ruleEditingText = "")}
  size="md"
>
  <h3 class="text-center font-bold">Edit Word / Phrase in Dictionary</h3>

  <div class="my-4 flex flex-col items-center gap-2">
    <div class="w-full">
      <label class="label" for="rule_text">
        <span class="label-text"
          >What word / phrase would you like to replace this with?</span
        >
      </label>
      <input
        type="text"
        bind:value={ruleEditingText}
        placeholder="Word / phrase to add"
        class="input input-bordered w-full"
        id="rule_text"
      />
    </div>
  </div>

  <form method="dialog" class="w-full">
    <button
      on:click={() => {
        editRule();
        ruleEditingText = "";
      }}
      class="btn btn-primary w-full"
    >
      Save Word / Phrase
    </button>
  </form>
</Modal>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="ruleDeleteModal"
  message="Are you sure you want to delete this word / phrase?"
  onConfirm={() => {
    deleteRule();
    ruleDeleteModal.close();
  }}
  onCancel={() => ruleDeleteModal.close()}
/>
