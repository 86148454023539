<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost,getTextColor } from "../../helpers";
  import LabelBean from "./LabelBean.svelte";
  import { org_labels } from "../../stores";

  const dispatch = createEventDispatcher();

  export let selectedLabels = [];
  export let disabled = false;

  let search = "";

  async function createLabel() {
    if (search.trim().length === 0) {
      toast.warning("Label name cannot be empty");
      return;
    }

    try {
      const res = await fetchPost("/label/create", {
        name: search,
        color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      });

      org_labels.update(labels => [...labels, res.label]);
      dispatch("labelSelected", res.label);
      search = "";
    } catch (error) {
      toast.error("Failed to create label");
    }
  }

  function handleSelect(label) {
    if (!selectedLabels.some(l => l.id === label.id)) {
      dispatch("labelSelected", label);
    } else {
      dispatch("deleteLabel", label);
    }
  }
</script>

<div class="dropdown">
  <label
    tabindex="0"
    class="btn btn-ghost btn-xs border-base-content/30 border-2 border-dashed"
    class:btn-disabled={disabled}
  >
    Add Label
  </label>

  <ul
    tabindex="0"
    class="dropdown-content bg-base-100 z-[1] m-1 max-h-72 w-52 overflow-auto rounded border p-2 shadow"
  >
    <input
      class="input input-sm input-bordered w-full"
      type="text"
      placeholder="Search labels"
      bind:value={search}
    />

    <div class="menu menu-sm mt-2 gap-2 p-0">
      {#each $org_labels.filter(l =>
        l.name.toLowerCase().includes(search.toLowerCase())
      ) as label}
        <li class:disabled>
          <button
            class:bg-base-200={selectedLabels.some(l => l.id === label.id)}
            {disabled}
            on:click={() => handleSelect(label)}
            class="flex w-full items-center justify-between"
          >
            <!-- <LabelBean
              name={label.name}
              color={label.color}
              id={label.id}
              disabled={disabled}
            /> -->
            <div
              class="btn btn-xs"
              style="background-color: {label.color}; color: {getTextColor(label.color)}; border: none;"
              aria-disabled="true"
            >
              {label.name}
            </div>
            <Icon
              icon="iconoir:{selectedLabels.some(l => l.id === label.id) ? 'cancel' : 'check'}"
              class="ml-2"
            />
          </button>
        </li>
      {/each}

      {#if !$org_labels.some(l =>
        l.name.toLowerCase() === search.toLowerCase()
      ) && search.length > 0}
        <li class:disabled>
          <button {disabled} on:click={createLabel}>
            Create Label "{search}"
          </button>
        </li>
      {/if}
    </div>
  </ul>
</div>