<script>
  import LogoLoader from "./LogoLoader.svelte";
  import Modal from "./Modals/Modal.svelte";

  export let message = "Are you sure you want to delete this item?";
  export let text;
  export let onConfirm;
  export let confirmText = "Yes";
  export let onCancel;
  export let cancelText = "No";
  export let showLoadingSpinner = false;
  export let loading = false;

  // pass another modalId if you want to have multiple modals on the same page
  export let modalId = "confirmModal";
</script>

<Modal {modalId} cornerCloseButton={false} on:close={onCancel}>
  <h2 class="text-xl font-semibold">{message}</h2>
  {#if text?.length}<p class="pt-2">{text}</p>{/if}
  <div class="mt-4 flex flex-col gap-2">
    <button
      class="btn btn-primary"
      disabled={showLoadingSpinner ? loading : false}
      on:click={onConfirm}
    >
      {#if showLoadingSpinner && loading}<LogoLoader size="1.25rem" />{/if}
      {confirmText}
    </button>
    <button
      class="btn btn-ghost text-primary"
      disabled={showLoadingSpinner ? loading : false}
      on:click={onCancel}>{cancelText}</button
    >
  </div>
</Modal>
