<script>
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchDelete, fetchGet, fetchPatch } from "../../helpers";
  import { org_users, org_teams } from "../../stores";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import MultiSelect from "../MultiSelect.svelte";
  import Pagination from "../table/Pagination.svelte";

  const maximumStringLength = 64;
  $: teams = $org_teams;
  let editTeamName = "";
  let editTeamDescription = "";
  let editSelectedUsers = [];
  let users = $org_users;
  let teamToEdit = 0;
  let teamToDelete;
  let currentPage = 1;
  let itemsPerPage = 5;
  let loading = false;

  $: displayUsers = $org_users
    .filter(user => !user.is_deleted || 
      $org_teams.find(t => t.id === teamToEdit)?.users.some(u => u.id === user.id))
    .map(user => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id
    }));

  async function setTeamToEdit(teamId) {
    teamToEdit = teamId;
    const team = $org_teams.find(t => t.id === teamId);
    if (team) {
      editTeamName = team.name;
      editTeamDescription = team.description;
      editSelectedUsers = team.users.map(u => u.id);
    }
  }

  async function editTeam() {
    if (!editTeamName.trim()) {
      toast.warning("Please enter a team name");
      return;
    }

    loading = true;
    try {
      const response = await fetchPatch(`/team/${teamToEdit}`, {
        name: editTeamName,
        description: editTeamDescription,
        users: editSelectedUsers
      });

      if (!response.success) throw new Error(response.error);

      org_teams.update(teams => 
        teams.map(team => team.id === teamToEdit ? response.team : team)
      );

      toast.success("Team updated successfully");
      teamToEdit = 0;
    } catch (error) {
      toast.error("Failed to update team");
      console.error(error);
    } finally {
      loading = false;
    }
  }

  async function deleteTeam(teamId) {
    loading = true;
    try {
      const response = await fetchDelete(`/team/${teamId}`);
      if (!response.success) {
        throw new Error("Team may be active");
      }

      org_teams.update(teams => teams.filter(t => t.id !== teamId));
      toast.success("Team deleted successfully");
    } catch (error) {
      toast.error(`Failed to delete team: ${error.message}`);
    } finally {
      loading = false;
      teamToDelete = null;
    }
  }

  // async function getTeams() {
  //   try {
  //     const response = await fetchGet("/team");

  //     if (!response.success) throw new Error(response.error);

  //     teams = [...response.teams];
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  function updateUsersToDisplay() {
    if (teamToEdit !== 0) {
      displayUsers = [
        ...$org_users
          .filter(
            (user) =>
              !user.is_deleted ||
              teams
                .find((t) => t.id === teamToEdit)
                ?.users.some((u) => u.id === user.id),
          )
          .map((user) => ({
            label: user.first_name + " " + user.last_name,
            value: user.id,
          })),
      ];
    }
  }

  // function handleOrganisationUsersUpdate() {
  //   updateUsersToDisplay();
  //   getTeams();
  // }

  // onMount(async () => {
  //   await getTeams();
  // });

  // $: updateUsersToDisplay(), [teamToEdit];
  // $: handleOrganisationUsersUpdate(), [$org_users];
  // $: getTeams(), [$org_teams];
</script>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="deleteTeamModal"
  message="Are you sure you want to delete this team?"
  showLoadingSpinner={true}
  {loading}
  onConfirm={async () => {
    await deleteTeam(teamToDelete);
    deleteTeamModal.close();
  }}
  onCancel={() => deleteTeamModal.close()}
/>

<div class="overflow-auto">
  <table
    class="bg-base-200 text-base-content/80 table border-collapse text-sm *:h-12"
  >
    <thead>
      <tr
        class="text-base-content/90 border-gray-200 *:text-sm *:font-semibold"
      >
        <th class="max-w-max">Team Name</th>
        <th class="max-w-max">Team Description</th>
        <th class="min-w-60 whitespace-normal">Users</th>
        <th class="min-w-32 whitespace-normal" />
      </tr>
    </thead>
    <tbody>
      {#each teams.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as team}
        {#if teamToEdit === team.id}
          <tr>
            <td>
              <input
                class="input input-sm input-bordered"
                type="text"
                maxlength={maximumStringLength}
                bind:value={editTeamName}
                placeholder="Add Team Name"
              />
            </td>
            <td>
              <input
                class="input input-sm input-bordered"
                type="text"
                maxlength={maximumStringLength}
                bind:value={editTeamDescription}
                placeholder="Add Team Description"
              />
            </td>
            <td>
              <MultiSelect
                items={displayUsers}
                placeholder="Select Team Members"
                bind:selectedValues={editSelectedUsers}
              />
            </td>
            <td class="w-44">
              <div class="m-auto flex w-full flex-wrap gap-2">
                <button
                  class="btn btn-outline btn-primary btn-xs"
                  disblaed={loading}
                  on:click={async () => {
                    await editTeam();
                  }}
                >
                  Save
                </button>

                <button
                  class="btn btn-outline btn-error btn-xs"
                  on:click={() => setTeamToEdit(0)}
                >
                  Cancel
                </button>
              </div>
            </td>
          </tr>
        {:else}
          <tr>
            <td>{team.name}</td>
            <td>{team.description}</td>
            <td>
              <div>
                {(team?.users || [])
                  .map((u) => u.first_name + " " + u.last_name)
                  .join(", ")}
              </div>
            </td>
            <td class="w-44">
              <div class="m-auto flex flex-wrap gap-2">
                <button
                  class="btn btn-outline btn-primary btn-xs"
                  disabled={loading}
                  on:click={() => setTeamToEdit(team.id)}
                >
                  Edit
                </button>

                <!-- svelte-ignore missing-declaration -->
                <button
                  class="btn btn-outline {loading
                    ? 'btn-disabled'
                    : 'btn-error'} btn-xs"
                  disblaed={loading}
                  on:click={() => {
                    teamToDelete = team.id;
                    deleteTeamModal.showModal();
                  }}
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        {/if}
      {/each}
    </tbody>
  </table>
</div>

<Pagination bind:currentPage bind:itemsPerPage totalHits={teams.length} />
