<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchGet, fetchPatch } from "../../../../helpers";
  import {
    ApprovalStatus,
    ApprovalStatusColor,
    ApprovalStatusLabel,
  } from "../../../../lib/interfaces/Workflow.interface";
  import { getCurrentStepIndex } from "../../../../lib/utils/AssetReviewUtils";
  import ActionConfirmation from "../../../ActionConfirmation.svelte";
  import LogoLoader from "../../../LogoLoader.svelte";

  export let activeAsset;
  export let currentAssetApprovingPermission;
  export let versions;
  export let activeVersion;
  export let activeAssetId;
  export let workflow;
  export let isLastStep = false;
  export let isLegacyWorkflow = true;

  let dropdownOptions = [];
  let updatingStatus = false;
  let selectedOption;

  const updateAssetComments = async () => {
    const res = await fetchGet(`/asset/comment/${activeAssetId}`);

    activeAsset.asset_comments = res.comments;
  };

  let approval_statuses = [
    //JW: Across the board, statuses aren't aligned to the "Asset Statuses" slide we are working off
    //JW: I have edited Workflow.interfaces to what I think it should be - can you bring these in as appropriate
    {
      label: ApprovalStatusLabel.PENDING,
      status: ApprovalStatus.PENDING,
      color: ApprovalStatusColor.PENDING.Color,
      bg: ApprovalStatusColor.PENDING.Background,
    },
    {
      label: ApprovalStatusLabel.REVIEW,
      status: ApprovalStatus.REVIEW,
      color: ApprovalStatusColor.REVIEW.Color,
      bg: ApprovalStatusColor.REVIEW.Background,
    },
    {
      label: ApprovalStatusLabel.REQUESTCHANGES,
      status: ApprovalStatus.REQUESTCHANGES,
      color: ApprovalStatusColor.REQUESTCHANGES.Color,
      bg: ApprovalStatusColor.REQUESTCHANGES.Background,
    },
    // {
    //     label: ApprovalStatusLabel.ROADBLOCK,
    //     status: ApprovalStatus.ROADBLOCK,
    //     color: ApprovalStatusColor.ROADBLOCK.Color,
    //     bg: ApprovalStatusColor.ROADBLOCK.Background,
    // },
  ];

  function getApprovalStyle(status) {
    const defaultStyle = { bg: "#FFFFFF", color: "#000000" }; // Default fallback styles
    const statusStyle = approval_statuses.find((s) => s.status === status);

    return statusStyle ? statusStyle : defaultStyle;
  }

  function getDropdownOptions() {
    const currentStepIndex = getCurrentStepIndex(workflow, activeAsset);
    const options = [
      //JW: I don't understand why we are doing this here rather than above?
      {
        label: ApprovalStatusLabel.ARCHIVE,
        status: ApprovalStatus.ARCHIVE,
        action: "archive",
        bg: ApprovalStatusColor.ARCHIVE.Color,
        color: ApprovalStatusColor.ARCHIVE.Background,
        step: currentStepIndex,
      }, // Assuming colors for Archive
      ...approval_statuses, // Include standard approval statuses
    ];

    if (currentStepIndex === 0) {
      const firstStep = workflow.workflow_steps[0];
      options.unshift({
        label: ApprovalStatusLabel.REJECTED,
        status: ApprovalStatus.REJECTED,
        step: firstStep.id,
        color: ApprovalStatusColor.REJECTED.Color,
        bg: ApprovalStatusColor.REJECTED.Background,
      });
    }

    if (currentStepIndex > 0) {
      // Option to go back to the first step if not currently on it
      // JW: In my view this should just be uploaded
      const firstStep = workflow.workflow_steps[0];
      if (firstStep.id !== activeAsset.asset.workflow_step.id) {
        options.unshift({
          label: `${ApprovalStatusLabel.REVERT} to ${firstStep.name}`,
          status: ApprovalStatus.REJECTED,
          step: firstStep.id,
          color: ApprovalStatusColor.REJECTED.Color,
          bg: ApprovalStatusColor.REJECTED.Background,
        });
      }

      // Option to go back to the previous step only if it's different from the first step
      if (currentStepIndex > 1) {
        const previousStep = workflow.workflow_steps[currentStepIndex - 1];
        options.unshift({
          label: `${ApprovalStatusLabel.REVERT} to ${previousStep.name}`,
          status: ApprovalStatus.REJECTED,
          step: previousStep.id,
          color: ApprovalStatusColor.REJECTED.Color,
          bg: ApprovalStatusColor.REJECTED.Background,
        });
      }
    }

    if (
      (isLegacyWorkflow &&
        currentStepIndex < workflow.workflow_steps.length - 1) ||
      (!isLegacyWorkflow &&
        currentStepIndex < workflow.workflow_steps.length - 1 &&
        !isLastStep)
    ) {
      // If there is a next step
      const nextStep = workflow.workflow_steps[currentStepIndex + 1];
      options.push({
        label: `${ApprovalStatusLabel.APPROVE} to ${nextStep.name}`,
        status: ApprovalStatus.APPROVE,
        step: nextStep.id,
        color: ApprovalStatusColor.APPROVE.Color,
        bg: ApprovalStatusColor.APPROVE.Background,
      });
    }

    if (
      (isLegacyWorkflow &&
        currentStepIndex === workflow.workflow_steps.length - 1) ||
      (!isLegacyWorkflow &&
        currentStepIndex + 1 === workflow.workflow_steps.length - 1)
    ) {
      options.push({
        label: ApprovalStatusLabel.APPROVE,
        status: ApprovalStatus.APPROVE,
        step: isLegacyWorkflow ? workflow.workflow_steps[currentStepIndex].id : workflow.workflow_steps[currentStepIndex].id + 1,
        color: ApprovalStatusColor.APPROVE.Color,
        bg: ApprovalStatusColor.APPROVE.Background,
      });
    }

    approval_statuses = options;

    return options;
  }

  function updateDropdownOptions() {
    approval_statuses = [
      {
        label: ApprovalStatusLabel.PENDING,
        status: ApprovalStatus.PENDING,
        color: ApprovalStatusColor.PENDING.Color,
        bg: ApprovalStatusColor.PENDING.Background,
      },
      {
        label: ApprovalStatusLabel.REVIEW,
        status: ApprovalStatus.REVIEW,
        color: ApprovalStatusColor.REVIEW.Color,
        bg: ApprovalStatusColor.REVIEW.Background,
      },
      {
        label: ApprovalStatusLabel.REQUESTCHANGES,
        status: ApprovalStatus.REQUESTCHANGES,
        color: ApprovalStatusColor.REQUESTCHANGES.Color,
        bg: ApprovalStatusColor.REQUESTCHANGES.Background,
      },
    ];

    dropdownOptions = getDropdownOptions();
    dropdownOptions = dropdownOptions;
  }

  async function updateAssetStatus(option) {
    try {
      const res = await fetchPatch(`/asset/${activeAssetId}`, {
        status: option.status,
        step: option.step,
        operation: "approval",
        isLegacyWorkflow: isLegacyWorkflow,
      });

      if (!res.success) {
        throw new Error(
          res?.message ??
            `Something went wrong updating that status of Asset(${activeAssetId})`,
        );
      }

      // activeAsset.approvers[activeAsset.asset.workflow_step.id] =
      //   activeAsset.approvers[activeAsset.asset.workflow_step.id]?.map((a) => {
      //     if (a.user_id === $currentUser.id) a.status = option.status;
      //     return a;
      //   });

      activeAsset.asset = res?.asset;
      activeAsset = activeAsset;

      await updateAssetComments();
      updateDropdownOptions();

      toast.success(res?.action_detail);
    } catch (e) {
      console.error(e);
      toast.error(e.toString());
    }

    updatingStatus = false;
  }

  onMount(() => {
    dropdownOptions = getDropdownOptions();
  });

  // $: currentStatus = activeAsset.approvers[
  //   activeAsset.asset.workflow_step.id
  // ]?.find((a) => a.user_id === $currentUser.id)?.status;
</script>

<!-- {#key currentStatus} -->
{#key activeAsset.asset.approval_status}
  {#if (currentAssetApprovingPermission || activeAsset.asset.workflow_step.position == 0) && versions[0].version === activeVersion.version}
    {#if updatingStatus}
      <div
        class="btn btn-disabled btn-sm flex-nowrap whitespace-nowrap capitalize"
      >
        <LogoLoader size="1.25rem" />
        Updating Status
      </div>
    {:else if activeAsset.asset.status === "Processing"}
      <div class="tooltip" data-tip="This asset is still processing.">
        <div
          class="btn btn-disabled btn-sm flex-nowrap whitespace-nowrap capitalize"
        >
          Pending
          <Icon icon="iconoir:nav-arrow-down" class="text-xs" />
        </div>
      </div>
    {:else}
      <div class="dropdown dropdown-end">
        <!-- <span
          class="absolute left-1/2 w-full -translate-x-1/2 -translate-y-full p-1 text-center text-xs font-bold"
        >
          Your Status
        </span> -->
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <!-- svelte-ignore a11y-label-has-associated-control -->

        <label
          tabindex="0"
          class="btn btn-sm flex-nowrap whitespace-nowrap capitalize"
          style="background-color: {getApprovalStyle(
            activeAsset.asset.approval_status,
          ).bg}; color: {getApprovalStyle(activeAsset.asset.approval_status)
            .color};"
        >
          {activeAsset.asset.approval_status || ApprovalStatus.PENDING}
          <Icon icon="iconoir:nav-arrow-down" class="text-xs" />
        </label>
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <ul
          tabindex="0"
          class="menu dropdown-content menu-xs bg-base-100 z-[1] flex w-48 flex-col gap-1 rounded border p-2"
        >
          {#each dropdownOptions as option}
            {#if option.status !== activeAsset.asset.approval_status}
              <!-- btn btn-xs bg-[{option.bg}] hover:bg-[{option.color}] hover:text-white -->
              <li>
                <!-- svelte-ignore missing-declaration -->
                <button
                  class="text-[{option.color}] font-medium"
                  disabled={updatingStatus}
                  on:click={async () => {
                    updatingStatus = true;
                    // selectedOption = option || {
                    //   status: ApprovalStatus.PENDING,
                    //   step: activeAsset.asset.workflow_step.id,
                    // };

                    if (
                      option.status === ApprovalStatus.APPROVE &&
                      activeAsset.rules.some((r) => r.colour !== "green")
                    ) {
                      selectedOption = option;
                      updateApprovalStatusModal.showModal();
                    } else {
                      await updateAssetStatus(option);
                    }
                  }}
                >
                  <input
                    type="radio"
                    class="radio-primary radio radio-xs"
                    checked={option.status ===
                      activeAsset.asset.approval_status}
                  />

                  {option.label}
                </button>
              </li>
            {/if}
          {/each}
        </ul>
      </div>
    {/if}
  {:else if !isLegacyWorkflow && getCurrentStepIndex(workflow, activeAsset) === workflow.workflow_steps.length - 1 && activeAsset.asset.approval_status === ApprovalStatus.APPROVE}
    {@const tip =
      versions[0].version !== activeVersion.version
        ? "This asset is not on the latest version."
        : null}
    {@const tip2 = "This asset has been fully approved."}
    <div class="tooltip tooltip-left" data-tip={tip || tip2}>
      <button
        class="btn btn-sm flex-nowrap whitespace-nowrap capitalize bg-[{ApprovalStatusColor
          .APPROVED.Background}] hover:bg-[{ApprovalStatusColor.APPROVED
          .Background}] text-[{ApprovalStatusColor.APPROVED.Color}]"
      >
        {activeAsset.asset.approval_status}
      </button>
    </div>
  {:else}
    {@const tip =
      versions[0].version !== activeVersion.version
        ? "This asset is not on the latest version."
        : null}
    {@const tip2 = "You don't have permissions to change asset status."}
    <div class="tooltip tooltip-left" data-tip={tip || tip2}>
      <button
        class="btn btn-disabled btn-sm flex-nowrap whitespace-nowrap capitalize"
        style="background-color: {getApprovalStyle(
          activeAsset.asset.approval_status,
        ).bg}; color: {getApprovalStyle(activeAsset.asset.approval_status)
          .color};"
      >
        {activeAsset.asset.approval_status || ApprovalStatus.PENDING}
      </button>
    </div>
  {/if}
{/key}

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="updateApprovalStatusModal"
  message="Not all rules have been approved. Are you sure you want to approve this asset?"
  onConfirm={async () => {
    updateApprovalStatusModal.close();
    await updateAssetStatus(selectedOption);
  }}
  onCancel={() => {
    selectedOption = null;
    updatingStatus = false;
    updateApprovalStatusModal.close();
  }}
/>
