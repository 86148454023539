<script lang="ts">
  import Icon from "@iconify/svelte";
  import PhraseItem from "./PhraseItem.svelte";

  export let phrases: string[] = [];
  export let highlightedPhrases: string[] = [];

  $: phrases = phrases.sort(
    (a, b) =>
      (highlightedPhrases.includes(b.toLowerCase()) ? 1 : 0) -
      (highlightedPhrases.includes(a.toLowerCase()) ? 1 : 0),
  );

  $: nonHighlightedCount = phrases.filter(
    (p) => !highlightedPhrases.includes(p.toLowerCase()),
  ).length;

  $: isCollapsed = nonHighlightedCount > 3;

  const toggleCollapse = () => {
    isCollapsed = !isCollapsed;
  };
</script>

<div class="my-4 flex">
  {#if nonHighlightedCount > 3}
    <button class="pl group z-10 my-4" on:click={toggleCollapse}>
      <div
        class="border-primary ml-2 h-full rounded-l-md border-b border-l border-t border-opacity-20 pr-2 group-hover:border-opacity-100"
      />
    </button>
  {/if}
  <div class="tree flex w-full flex-col gap-4">
    {#each phrases as phrase, index}
      {@const isHighlighted = highlightedPhrases.includes(phrase.toLowerCase())}
      {@const isEdge = index === 0 || index === phrases.length - 1}
      {@const showButton = index === highlightedPhrases.length && isCollapsed}
      {@const showPhrase = !isCollapsed || isHighlighted || isEdge}

      {#if showPhrase}
        <div class:leaf={nonHighlightedCount > 3}>
          <PhraseItem {phrase} {isHighlighted} />
        </div>
      {:else if showButton}
        <div class="flex" class:leaf={nonHighlightedCount > 3}>
          <button
            class="btn btn-primary btn-sm btn-outline"
            on:click={toggleCollapse}
          >
            <Icon icon="iconoir:plus" /> Show {nonHighlightedCount - 3} more phrases
          </button>
        </div>
      {/if}
    {/each}
  </div>
</div>

<style>
  .tree .leaf::before {
    content: "–";
    position: absolute;
    transform: translate(-8px, 4px);
    color: #0064bc;
    opacity: 0.2;
  }
</style>
