<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { persisted } from "svelte-persisted-store";
  import { maximised } from "../../../stores";
  import Pagination from "../../table/Pagination.svelte";
  import { findHitFromURL } from "../openHitByUrl";
  import { getTableByCategory } from "../tableUtils";
  import { defaultTimestampDialogData } from "../../../lib/utils/TimestampUtils";
  import { getUsableMediaLink } from "../../../lib/utils/GeneralDataUtils";
  import IssueTableFilterSidebar from "./IssueTableFilterSidebar.svelte";
  import IssueTableHeader from "./IssueTableHeader.svelte";
  import IssueTableMassOptions from "./IssueTableMassOptions.svelte";
  import IssueTableRow from "./IssueTableRow.svelte";
  import TimestampViewModal from "../../Modals/TimestampViewModal.svelte";

  const dispatch = createEventDispatcher();

  function sendRefreshRequest(newData) {
    dispatch("refresh", newData);
  }

  export let data;
  export let tableType = "NONE";
  export let tableRootPage;
  export let tableId = "";
  export let loading = false;
  export let showGroupedTable = false;
  export let openedTab = "Open";
  export let tableTitle;
  export let disableEditing = false;
  export let canEditPermissions;

  let originalData = data;

  let hitSearchText = "";
  export let hitSearchSettings = {
    isCaseSensitive: { enabled: false, label: "Case sensitive" },
  };

  let sourceSearchText = "";
  export let sourceSearchSettings = {
    isCaseSensitive: { enabled: false, label: "Case sensitive" },
  };

  let shouldSearch = true;

  export let dateToSortByString = "";

  let itemsPerPage = 10;
  export let currentPage = 1;

  let canUseKeysToPaginate = true;
  let ruleToHighlight = "";

  let isTableSorted = false;

  let timestampModal = null;
  let timestampDialogData = { ...defaultTimestampDialogData }

  function selectAllFiltered() {
    data = filteredData.map((entry) => {
      entry["selected"] = true;
      return entry;
    });
  }

  function selectAllOnPage() {
    const currentPageHits = filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );

    data = originalData.map((entry) => {
      if (currentPageHits.includes(entry)) entry["selected"] = true;
      return entry;
    });
  }

  function deselectAll() {
    data = data.map((entry) => {
      entry["selected"] = false;
      return entry;
    });
  }

  function setTableDetails() {
    const table = getTableByCategory(tableType, originalData);

    if (!tableTitle) tableTitle = table.title;
    originalData = table.data;
  }

  let tableChecksLoading = true;

  onMount(async () => {
    tableChecksLoading = true;

    setTableDetails();

    const urlHitDetails = findHitFromURL(data, itemsPerPage);
    const hitPage = urlHitDetails.page;
    const hitTab = urlHitDetails.tab;

    if (hitPage !== 1 || hitTab !== "Open") {
      openedTab = hitTab;
      currentPage = hitPage;
    }

    if (openedTab === "Open" || !window.location.href.includes("stream")) {
      const statuses = ["Open", "Flagged", "Closed", "Removed"];

      for (let i = 0; i < statuses.length; i++) {
        const status = statuses[i];
        const statusData = originalData.filter(
          (entry) => entry.hit.in_report === status,
        );

        if (statusData.length > 0) {
          openedTab = status;
          data = statusData;
          break;
        }
      }
    }

    tableChecksLoading = false;

    $maximised = false;
  });
  onDestroy(() => ($maximised = true));

  let lastData = [...data];
  let filteredData = [...data];

  function getFilteredData() {
    return originalData
      .filter((entry) => {
        if (entry.hit.in_report !== openedTab) return false;

        if (hitSearchText && entry.hit.highlight) {
          const searchText = hitSearchSettings.isCaseSensitive.enabled
            ? hitSearchText
            : hitSearchText.toLowerCase();
          const highlight = hitSearchSettings.isCaseSensitive.enabled
            ? entry.hit.highlight
            : entry.hit.highlight.toLowerCase();
          const parser = new DOMParser();
          const plainTextHighlight = parser.parseFromString(
            highlight,
            "text/html",
          ).body.textContent;
          if (!plainTextHighlight.includes(searchText)) return false;
        }

        if (sourceSearchText && entry.hit.title) {
          const searchText = sourceSearchSettings.isCaseSensitive.enabled
            ? sourceSearchText
            : sourceSearchText.toLowerCase();
          const title = sourceSearchSettings.isCaseSensitive.enabled
            ? entry.hit.title
            : entry.hit.title.toLowerCase();
          if (!title.includes(searchText)) return false;
        }

        return true;
      })
      .filter((entry) => $selectedRulesId.includes(entry.rule.id))
      .filter((entry) => $selectedDataTypes.includes(entry.hit.data_type))
      .filter((entry) => $selectedVendors.includes(entry.hit.vendor))
      .filter((entry) =>
        $selectedSocialTypes.includes(entry.hit.index.split("_")[1]),
      )
      .sort((a, b) => {
        if (isTableSorted) return 0;
        isTableSorted = true;

        if (dateToSortByString === "") {
          return a.hit.id - b.hit.id;
        }
      });
  }

  function getAllRules() {
    let output = {};

    originalData.forEach((entry) => {
      output[entry.rule.id] = entry.rule;
    });

    return Object.values(output);
  }

  function handleTimestampData(e) {
    timestampDialogData = {
      title: e.detail.title,
      timestamp: e.detail.media_timestamp,
      url: getUsableMediaLink(
        e.detail.index,
        e.detail.data_type,
        e.detail.url,
        e.detail.content_link
      ),
      caption: e.detail.highlight
    }
    timestampModal.showModal()
  }

  let allResults = originalData.map((e) => e.hit);
  let allRules = getAllRules();

  const selectedRulesId = persisted(
    `selectedRulesId-${tableId}`,
    allRules.map((r) => r.id),
  );
  const selectedDataTypes = persisted(`selectedDataTypes-${tableId}`, [
    ...new Set(allResults.map((e) => e.data_type)),
  ]);
  const selectedVendors = persisted(`selectedVendors-${tableId}`, [
    ...new Set(allResults.map((e) => e.vendor)),
  ]);

  allResults.forEach((e, i) => {
    if (!e.index) {
      console.log(`Found problematic entry at index ${i}:`, e);
    }
  });
  const selectedSocialTypes = persisted(`selectedSocialTypes-${tableId}`, [
    ...new Set(allResults.map((e) => e.index.split("_")[1])),
  ]);

  const lastRuleCount = persisted(`lastRuleCount-${tableId}`, 0);

  if ($lastRuleCount !== allRules.length) {
    //console.log({ $lastRuleCount, allRules: allRules.length });
    $lastRuleCount = allRules.length;

    $selectedRulesId = allRules.map((r) => r.id);
    $selectedDataTypes = [...new Set(allResults.map((e) => e.data_type))];
    $selectedVendors = [...new Set(allResults.map((e) => e.vendor))];
    $selectedSocialTypes = [
      ...new Set(allResults.map((e) => e.index.split("_")[1])),
    ];
  }

  $: {
    if (
      (!arraysAreEqual(data, lastData) || shouldSearch) &&
      !tableChecksLoading
    ) {
      setTableDetails();
      lastData = [...data];
      filteredData = getFilteredData();
      shouldSearch = false;
    }
  }

  function arraysAreEqual(a, b) {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  let allTabs = ["Open", "Flagged", "Closed", "Removed"];
</script>

<TimestampViewModal 
  bind:this={timestampModal} 
  title={timestampDialogData.title}
  caption={timestampDialogData.caption}
  url={timestampDialogData.url}
  timestamp={timestampDialogData.timestamp}
/>

<!-- 1706px why? -> max-w-screen-xl = 1280px + 208px fix left filtering sidebar + 1rem (gap) -->
<div class="mx-auto max-w-[1706px]">
  {#if tableTitle && tableTitle !== ""}
    <h2 class="mt-4 px-[13.5rem] text-xl font-normal">{tableTitle}</h2>
  {/if}

  {#if data}
    <div class="flex" class:-mb-16={tableRootPage.includes("reports")}>
      <IssueTableFilterSidebar
        {originalData}
        report_id={tableId}
        exportRes={true}
        {tableRootPage}
        bind:selectedRulesId={$selectedRulesId}
        bind:selectedDataTypes={$selectedDataTypes}
        bind:selectedVendors={$selectedVendors}
        bind:selectedSocialTypes={$selectedSocialTypes}
        bind:showGroupedTable
        bind:currentTab={openedTab}
        {allRules}
        {canEditPermissions}
        on:selectChanged={() => (shouldSearch = true)}
        {disableEditing}
      />

      <div class="ml-2 flex w-full flex-col overflow-x-auto">
        <div
          class="tabs relative mb-2 shrink-0 overflow-hidden rounded border bg-base-200"
        >
          {#each allTabs as tab}
            <button
              class="tab whitespace-nowrap capitalize"
              class:text-primary={openedTab === tab}
              on:click={() => {
                openedTab = tab;
                currentPage = 1;
                data = originalData.filter(
                  (e) => e.hit.in_report === openedTab,
                );
                shouldSearch = true;
              }}
            >
              {tab} ({originalData.filter(
                (entry) => entry.hit.in_report === tab,
              ).length})
            </button>
          {/each}
          <div
            class="slider absolute bottom-0 left-0 h-0.5 bg-primary transition-all"
            style:width="{100 / allTabs.length}%"
            style="translate: {allTabs.findIndex((i) => i === openedTab) *
              100}%"
          />
        </div>

        {#if data.filter((e) => e.selected).length > 0}
          <IssueTableMassOptions
            {originalData}
            bind:data
            bind:currentPage
            bind:itemsPerPage
            {openedTab}
            on:refresh={() => sendRefreshRequest(data)}
            on:deselectAll={deselectAll}
            on:selectAllOnPage={selectAllOnPage}
            on:selectAllFiltered={selectAllFiltered}
          />
        {/if}

        <div
          class="mt-2 h-full min-h-[300px] overflow-auto rounded border"
          style:max-height={tableRootPage.includes("reports")
            ? "calc(100vh - 378px)"
            : "auto"}
        >
          <table class="table table-zebra table-fixed">
            <IssueTableHeader
              bind:currentPage
              bind:dateToSortByString
              bind:originalData
              bind:data
              bind:hitSearchSettings
              bind:sourceSearchSettings
              {hitSearchText}
              {sourceSearchText}
              {openedTab}
              {tableRootPage}
              on:search={(e) => {
                hitSearchText = e.detail.hitSearchText;
                sourceSearchText = e.detail.sourceSearchText;
                shouldSearch = true;
              }}
            />

            <tbody>
              {#each filteredData
                .sort((a, b) => {
                  if (isTableSorted) return 0;
                  isTableSorted = true;

                  if (dateToSortByString === "") return a.hit.id - b.hit.id;
                })
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as entry (entry.hit.id)}
                <IssueTableRow
                  bind:data
                  bind:originalData
                  {entry}
                  {openedTab}
                  bind:hitSearchText
                  bind:hitSearchSettings
                  bind:sourceSearchText
                  bind:sourceSearchSettings
                  bind:currentPage
                  bind:loading
                  bind:canUseKeysToPaginate
                  bind:highlightedRule={ruleToHighlight}
                  {tableRootPage}
                  on:refresh={(e) => sendRefreshRequest(e.detail)}
                  on:entrySelectedToggled={() => {
                    // forceShowMassFilters = false;
                  }}
                  on:clickTimestamp={(timestamp) => handleTimestampData(timestamp)}
                  disableEditing={disableEditing ||
                    entry.report.status === "Archived"}
                />
              {/each}
            </tbody>
          </table>
        </div>

        <Pagination
          bind:currentPage
          bind:itemsPerPage
          totalHits={filteredData.length}
        />
      </div>
    </div>
  {:else}
    <h3>No results found</h3>
  {/if}
</div>
