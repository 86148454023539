<script>
  export let currentAssetApprovingPermission;
  export let versions;
  export let activeVersion;
  export let disabled = false;
</script>

{#if currentAssetApprovingPermission && versions[0].version === activeVersion.version}
  <button
    class="btn btn-primary btn-sm"
    {disabled}
    on:click={() => requestChangesModal.showModal()}
  >
    Request Changes
  </button>
{:else}
  <!-- if don't approving permissions or not on latest version -->
  {@const tip =
    versions[0].version !== activeVersion.version
      ? "This asset is not on the latest version."
      : null}
  {@const tip2 = "You don't have approving permissions."}

  <div class="tooltip" data-tip={tip || tip2}>
    <button {disabled} class="btn btn-disabled btn-primary btn-sm">
      Request Changes
    </button>
  </div>
{/if}
