<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { fetchGet } from "../../helpers";
  import { teamToShowId, teamToShowName } from "./teamOverview";

  let teams = [];

  async function fetchTeams() {
    const response = await fetchGet("/team");
    teams = response.teams;
  }

  function setTeamToShow(teamId, teamName) {
    teamToShowId.set(teamId);
    teamToShowName.set(teamName);
  }

  onMount(fetchTeams);
</script>

<div class="dropdown dropdown-end">
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <label class="btn btn-square btn-ghost btn-sm" tabindex="0" for="">
    <Icon icon="heroicons:ellipsis-horizontal-solid" class="text-2xl" />
  </label>
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <ul
    tabindex="0"
    class="menu dropdown-content z-[1] w-52 rounded border bg-base-100 p-2 shadow"
  >
    <li>
      <button on:click={() => setTeamToShow(-1, "All Users")}>All Users</button>
    </li>
    {#if teams.length}
      <div class="divider my-1" />
    {/if}
    {#each teams as team}
      <li>
        <button on:click={() => setTeamToShow(team.id, team.name)}>
          {team.name}
        </button>
      </li>
    {:else}
      <li class="menu-title">No Teams Found</li>
    {/each}
  </ul>
</div>
