<script>
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { persisted } from "svelte-persisted-store";
  import { arrayRange } from "../../lib/utils/GenericUtils";
  import { maximised } from "../../stores";
  import Pagination from "../table/Pagination.svelte";
  // import TableFilterSidebar from "./TableFilterSidebar.svelte";
  import TableHeader from "./TableHeader.svelte";
  import TableMassOptions from "./TableMassOptions.svelte";
  import TableRow from "./TableRow.svelte";
  import TimestampViewModal from "../Modals/TimestampViewModal.svelte";
  import { findHitFromURL } from "./openHitByUrl";
  import { getTableByCategory } from "./tableUtils";
  import { defaultTimestampDialogData } from "../../lib/utils/TimestampUtils";
  import { getUsableMediaLink } from "../../lib/utils/GeneralDataUtils";

  const dispatch = createEventDispatcher();

  function sendRefreshRequest(newData) {
    dispatch("refresh", newData);
  }

  export let data;
  export let forceStateUpdate;
  export let tableType = "NONE";
  export let tableRootPage;
  export let tableId = "";
  export let loading = { state: false };
  export let showGroupedTable = false;
  export let openedTab;
  export let tableTitle;
  export let disableEditing = false;
  export let canEditPermissions;
  export let itemsPerPage = 10;
  export let currentPage = 1;
  export let statusCounts = { Open: 0, Flagged: 0, Closed: 0, Removed: 0 };
  export let rules = [];
  export let unique_data_types = [];
  export let unique_indexes = [];
  export let unique_vendors = [];
  export let selectedRulesId;
  export let selectedDataTypes;
  export let selectedVendors;
  export let selectedSocialTypes;
  export let selectedHits = new Set();
  export let dateToSortByString = "";
  export let columnToSortBy;
  export let reportFilters;
  export let hitSearchText = "";
  export let sourceSearchText = "";
  export let rulesHitsStats = new Map();

  let selectedGroups = new Set();
  let totalHits = statusCounts[openedTab];
  let numberOfSelectedHits = selectedHits.size;
  let timestampModal = null;

  let originalData = data;
  let timestampDialogData = { ...defaultTimestampDialogData }

  export let hitSearchSettings = {
    isCaseSensitive: { enabled: false, label: "Case sensitive" },
  };
  export let sourceSearchSettings = {
    isCaseSensitive: { enabled: false, label: "Case sensitive" },
  };

  let shouldSearch = true;
  let canUseKeysToPaginate = true;
  let ruleToHighlight = "";
  let isTableSorted = false;

  function selectAllFiltered() {
    numberOfSelectedHits = statusCounts[openedTab];
    selectedHits = new Set(
      Array.from({ length: numberOfSelectedHits }, (_, index) => index),
    );
    selectedHits = selectedHits;

    data = [
      ...filteredData.map((entry) => {
        entry["selected"] = true;
        return entry;
      }),
    ];
  }

  function selectAllOnPage() {
    const currentPageIndexes = arrayRange(
      currentPage - 1,
      currentPage * itemsPerPage - 1,
      1,
    );

    currentPageIndexes.forEach((idx) => selectedHits.add(idx));
    selectedHits = selectedHits;
    numberOfSelectedHits = selectedHits.size;

    const currentPageHits = filteredData.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );

    data = [
      ...originalData.map((entry) => {
        if (currentPageHits.includes(entry)) entry["selected"] = true;
        return entry;
      }),
    ];
  }

  function deselectAll() {
    data = [
      ...data.map((entry) => {
        entry["selected"] = false;
        return entry;
      }),
    ];

    selectedHits = new Set();
    selectedHits = selectedHits;
    numberOfSelectedHits = 0;
  }

  function setTableDetails() {
    const table = getTableByCategory(tableType, originalData);

    if (!tableTitle) tableTitle = table.title;
    originalData = table.data;
  }

  let tableChecksLoading = true;

  onMount(async () => {
    tableChecksLoading = true;

    setTableDetails();

    const urlHitDetails = findHitFromURL(data, itemsPerPage);
    const hitPage = urlHitDetails.page;
    const hitTab = urlHitDetails.tab;

    if (hitPage !== 1 || hitTab !== "Open") {
      openedTab = hitTab;
      currentPage = hitPage;
    }

    tableChecksLoading = false;

    $maximised = false;
  });

  onDestroy(() => ($maximised = true));

  let lastData = [...data];
  let filteredData = [...data];

  function getFilteredData() {
    return originalData;
  }

  function getAllRules() {
    let output = {};
    rules.forEach((rule) => {
      output[rule.id] = rule;
    });
    return Object.values(output);
  }

  let allRules = getAllRules();

  const lastRuleCount = persisted(`lastRuleCount-${tableId}`, 0);

  if ($lastRuleCount !== allRules.length) {
    $lastRuleCount = allRules.length;
  }

  $: {
    if (
      (!arraysAreEqual(data, lastData) || shouldSearch) &&
      !tableChecksLoading
    ) {
      setTableDetails();
      lastData = [...data];
      filteredData = getFilteredData();
      shouldSearch = false;
    }
  }

  function arraysAreEqual(a, b) {
    return a.length === b.length && a.every((val, index) => val === b[index]);
  }

  let allTabs = ["Open", "Flagged", "Closed", "Removed"];

  function handleEntrySelectedToggled(hitIdx) {
    if (selectedHits) {
      if (selectedHits.has(hitIdx)) {
        selectedHits.delete(hitIdx);
      } else {
        selectedHits.add(hitIdx);
      }

      numberOfSelectedHits = selectedHits.size;
    }
  }

  function handleDataStateChange() {
    filteredData = [...data];
  }

  function handleTimestampData(e) {
    timestampDialogData = {
      title: e.detail.title,
      timestamp: e.detail.media_timestamp,
      url: getUsableMediaLink(
        e.detail.index,
        e.detail.data_type,
        e.detail.url,
        e.detail.content_link
      ),
      caption: e.detail.highlight
    }
    timestampModal.showModal()
  }

  $: handleDataStateChange(), [data];

  const updateTab = (tab) => {
    openedTab = tab;
    currentPage = 1;
    data = originalData.filter((e) => e.hit.in_report === openedTab);
    shouldSearch = true;
    selectedHits = new Set();
  };
  export let updatedOpenedTab = false;
  $: if (updatedOpenedTab) {
    updateTab(openedTab);
    updatedOpenedTab = false;
  }
</script>

{#if tableTitle && tableTitle !== ""}
  <h2 class="mt-4 px-[13.5rem] text-xl font-normal">{tableTitle}</h2>
{/if}

{#if selectedHits.size > 0}
  <TableMassOptions
    {originalData}
    bind:data
    bind:currentPage
    bind:itemsPerPage
    bind:selectedDataTypes
    bind:selectedVendors
    bind:selectedRulesId
    bind:selectedSocialTypes
    bind:forceStateUpdate
    bind:selectedHits
    reportId={tableId}
    bind:columnToSortBy
    bind:showGroupedTable
    bind:numberOfSelectedHits
    {openedTab}
    bind:statusCounts
    bind:hitSearchText
    bind:hitSearchSettings
    on:refresh={() => sendRefreshRequest(data)}
    on:deselectAll={deselectAll}
    on:selectAllOnPage={selectAllOnPage}
    on:selectAllFiltered={selectAllFiltered}
  />
{/if}

<TimestampViewModal 
  bind:this={timestampModal} 
  title={timestampDialogData.title}
  caption={timestampDialogData.caption}
  url={timestampDialogData.url}
  timestamp={timestampDialogData.timestamp}
/>

<div
  class="h-full min-h-[300px] overflow-auto rounded border"
  style:max-height={tableRootPage.includes("reports")
    ? "calc(100vh - 378px)"
    : "auto"}
>
  <table class="table-zebra table table-fixed">
    <TableHeader
      bind:currentPage
      bind:dateToSortByString
      bind:originalData
      bind:data
      bind:hitSearchSettings
      bind:sourceSearchSettings
      bind:columnToSortBy
      {hitSearchText}
      {sourceSearchText}
      {openedTab}
      {tableRootPage}
      on:search={(e) => {
        hitSearchText = e.detail.hitSearchText;
        sourceSearchText = e.detail.sourceSearchText;
        shouldSearch = true;
        dispatch("search");
      }}
    />
    {#if data}
      <tbody>
        {#each filteredData as entry, index (index)}
          <TableRow
            bind:data
            bind:originalData
            {entry}
            {openedTab}
            bind:hitSearchText
            bind:hitSearchSettings
            bind:sourceSearchText
            bind:sourceSearchSettings
            bind:currentPage
            bind:loading
            bind:canUseKeysToPaginate
            bind:highlightedRule={ruleToHighlight}
            bind:forceStateUpdate
            bind:selectedHits
            index={index + (currentPage - 1) * itemsPerPage}
            {tableRootPage}
            on:refresh={(e) => sendRefreshRequest(e.detail)}
            on:entrySelectedToggled={() => {
              handleEntrySelectedToggled(
                index + (currentPage - 1) * itemsPerPage,
              );
            }}
            on:clickTimestamp={(timestamp) => handleTimestampData(timestamp)}
            disableEditing={disableEditing ||
              entry.report.status === "Archived"}
          />
        {/each}
      </tbody>
    {:else}
      <h3>No results found</h3>
    {/if}
  </table>
</div>

{#if data.length > 0}
  <Pagination
    bind:currentPage
    bind:itemsPerPage
    bind:totalHits
    {canUseKeysToPaginate}
  />
{/if}
