<script>
  import Icon from "@iconify/svelte";
  import { Handle, Position } from "@xyflow/svelte";
  import { workflowAction } from "./workflowAction";

  export let isConnectable = false;
  export let data; // "start" or "end"

  function addStep() {
    workflowAction.update(($action) => {
      if ($action.addStep) {
        $action.addStep(data.position);
      }
      return $action;
    });
  }
</script>

<div class="workflow-step-node">
  {#if data.position == "end"}
    <Handle type="target" position={Position.Left} {isConnectable} />
  {/if}
  <div
    style="background-colour:white; opacity:1;"
    class="flex items-center justify-center rounded-full border p-2 shadow-lg"
  >
    <button on:click={addStep}>
      <Icon icon="iconoir:plus" class="text-2xl" />
    </button>
  </div>
  {#if data.position == "start"}
    <Handle type="source" position={Position.Right} {isConnectable} />
  {/if}
</div>
