<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import Pagination from "../components/table/Pagination.svelte";
  import {
    fetchGet,
    fetchPatch,
    getDisplayDate,
    openNotif,
    updateNotifStore,
  } from "../helpers";
  import { notifications } from "../stores";

  const itemsPerPage = 10;
  let currentPage = 1;

  let currentNotifications = $notifications || {
    notifications: [],
    total_notifications: 0,
    unseen_notifications: 0,
  };

  const loadNotifs = async () => {
    const res = await fetchGet(
      `/notification?currentPage=${currentPage}&pageAmount=${itemsPerPage}&type=all`,
    );
    if (res.success) currentNotifications = res;
  };

  const markAsSeen = async (id) => {
    const res = await fetchPatch(`/notification/${id}`);
    if (res.success) {
      updateNotifStore();
      loadNotifs();
    }
  };

  const markBatch = async (seen = true) => {
    const res = await fetchPatch("/notification/batch", {
      ids: selectedNotifs,
      seen,
    });
    if (!res.success) {
      toast.error(res.message);
      return;
    }

    updateNotifStore();
    loadNotifs();
    selectedNotifs = [];
  };

  onMount(loadNotifs);
  $: if (currentPage) loadNotifs();

  let selectedNotifs = [];
</script>

<svelte:head>
  <title>Notifications - Haast</title>
</svelte:head>

<div class="mx-auto flex max-w-screen-xl flex-col gap-4">
  <h1 class="text-3xl font-semibold">Notifications</h1>

  <div
    transition:slide
    class="bg-base-200 flex items-center gap-2 rounded border p-2"
  >
    <!-- invisible badge to have the exact same spacing as notifs below -->
    <div class="badge badge-primary badge-xs invisible"></div>

    <input
      type="checkbox"
      class="checkbox checkbox-sm"
      checked={selectedNotifs.length ===
        currentNotifications.notifications.length}
      indeterminate={selectedNotifs.length > 0 &&
        selectedNotifs.length < currentNotifications.notifications.length}
      on:change={(e) => {
        if (e.target.checked)
          selectedNotifs = currentNotifications.notifications.map((n) => n.id);
        else selectedNotifs = [];
      }}
    />

    <span class="text-sm">
      {selectedNotifs.length
        ? `${selectedNotifs.length} selected`
        : "Select all"}
    </span>

    <button
      class="btn btn-outline btn-primary btn-xs"
      class:invisible={!selectedNotifs.length}
      on:click={() => markBatch(true)}
    >
      Mark as seen
    </button>
    <button
      class="btn btn-outline btn-xs"
      class:invisible={!selectedNotifs.length}
      on:click={() => markBatch(false)}
    >
      Mark as not seen
    </button>
  </div>

  {#each currentNotifications.notifications as n}
    <div
      class="bg-base-200 hover:border-base-content/20 group relative flex items-center gap-2 rounded border border-transparent p-2"
    >
      <div class="badge badge-primary badge-xs" class:invisible={n.seen}></div>

      <input
        type="checkbox"
        class="checkbox checkbox-sm"
        bind:group={selectedNotifs}
        value={n.id}
      />

      <Icon
        icon={n.url.includes("/review/")
          ? "iconoir:clipboard-check"
          : n.url.includes("/stream/")
            ? "iconoir:page"
            : "iconoir:bell"}
        class="shrink-0 text-xl {!n.seen ? 'text-primary' : ''}"
      />

      <button
        class="hover:text-primary flex grow flex-col gap-1 text-start"
        on:click={() => openNotif(n.id, n.url, n.seen)}
      >
        <p class="text-sm [word-break:break-word]">{n.text}</p>
        <p class="text-base-content/70 text-xs">
          {getDisplayDate(n.created_date)}
        </p>
      </button>

      <button
        class="btn btn-square btn-ghost btn-sm tooltip invisible mr-1 inline-flex"
        data-tip="Mark as seen"
        on:click={() => markAsSeen(n.id)}
        class:group-hover:visible={!n.seen}
      >
        <Icon icon="iconoir:check" />
      </button>

      <!-- {#if !n.seen}
        <button
          class="btn btn-ghost btn-sm btn-square tooltip mr-1 hidden group-hover:inline-flex"
          data-tip="Mark as seen"
          on:click={() => markAsSeen(n.id)}
        >
          <Icon icon="iconoir:check" />
        </button>
      {/if} -->
    </div>
  {/each}

  <Pagination
    bind:currentPage
    {itemsPerPage}
    totalHits={currentNotifications.total_notifications}
  />
</div>
