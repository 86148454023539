<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { isEmpty } from "../lib/utils/GenericUtils";

  export let items = [];
  export let placeholder = "Please Select";
  export let size = "sm";
  export let dropdownPosition = "bottom";
  export let selectedValue;
  export let disabled = false;
  export let classes = "";
  export let inputClasses = "";
  export let dropdownContentClasses = "";
  export let dropdownListItemClass = "";
  export let useCustomTooltip = false;

  // useFixedPositioning doesn't work well with modals by default. You need to remove translate from the modal to use this approach.
  export let useFixedPositioning = true;

  const dispatch = createEventDispatcher();

  let search = "";
  let highlightedIndex = -1; // Initialize highlighted index


  const groupsPresent =
    items.filter((i) => {
      if (i.group) return i.group;
    }).length > 0;

  if (groupsPresent) {
    items = items.sort((a, b) => a.label.localeCompare(b.label));
    items = items.sort((a, b) => a.group.localeCompare(b.group));
  }

  $: selectedValueLabel = items.find((i) => i.value === selectedValue)?.label;

  let dropdown;
  let dropdownCoords;
  let showCustomTooltip = false;
  let customTooltipStyles = "";
  let customToolTipText = "";
  let maxHeight = "100vh";

  const updateDropdownCoords = () => {
    if (dropdown) {
      dropdownCoords = dropdown.getBoundingClientRect();
      const mainBody = document.querySelector("#app");
      if (mainBody) {
        maxHeight = +(mainBody.scrollHeight - dropdownCoords.bottom) + "px";
      }
    }
  };

  function handleMouseEnter(event, text) {
    showCustomTooltip = true;
    customToolTipText = text;
    positionCustomTooltip(event);
  }

  function handleMouseExit(event) {
    showCustomTooltip = false;
    customToolTipText = "";
  }

  // Define filteredItems as a reactive variable
  $: filteredItems = items.filter((i) =>
    i.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  function handleKeydown(event) {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      highlightedIndex = (highlightedIndex + 1) % filteredItems.length;
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      highlightedIndex = (highlightedIndex - 1 + filteredItems.length) % filteredItems.length;
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (highlightedIndex >= 0 && highlightedIndex < filteredItems.length) {
        selectItem(filteredItems[highlightedIndex]);
      }
    }
  }

  function selectItem(item) {
    selectedValue = item.value;
    search = ""; // Clear search input after selection
    highlightedIndex = -1; // Reset highlighted index
    document.activeElement.blur(); // Close dropdown
    dispatch("change", item.value);
  }



  function positionCustomTooltip(event) {
    const rect = event.target.getBoundingClientRect();
    const tooltipMaxWidth = 250;
    const tooltipPadding = 12;
    const top = rect.bottom + 8;
    let left = rect.left + rect.width / 2 - tooltipMaxWidth / 2;

    // Ensure the tooltip doesn't go off the left side of the screen
    if (left < 8) left = 8;
    // Ensure the tooltip doesn't go off the right side of the screen
    if (left + tooltipMaxWidth > window.innerWidth - 8)
      left = window.innerWidth - tooltipMaxWidth - 8;

    customTooltipStyles = `
      position: fixed;
      top: ${top}px;
      left: ${left}px;
      max-width: ${tooltipMaxWidth}px;
      z-index: 1000;
      background-color: rgba(43, 52, 64, 1);
      padding: 6px 8px;
      border-radius: 4px;
    `;
  }

  onMount(() => {
    if (!useFixedPositioning) return;

    updateDropdownCoords();

    window.addEventListener("scroll", updateDropdownCoords);
    window.addEventListener("resize", updateDropdownCoords);

    const observer = new MutationObserver(updateDropdownCoords);
    observer.observe(document.body, { subtree: true, childList: true });

    setInterval(updateDropdownCoords, 100);

    return () => {
      window.removeEventListener("scroll", updateDropdownCoords);
      window.removeEventListener("resize", updateDropdownCoords);
      observer.disconnect();
      clearInterval(updateDropdownCoords);
    };
  });
</script>

<div
  class="dropdown min-w-fit {classes}"
  class:dropdown-top={dropdownPosition === "top"}
  bind:this={dropdown}
>
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <label tabindex="0" for="" class:pointer-events-none={disabled}>
    <input
      type="text"
      class="input input-bordered w-full {inputClasses}"
      class:input-xs={size === "xs"}
      class:input-sm={size === "sm"}
      class:input-lg={size === "lg"}
      class:placeholder:text-black={selectedValueLabel}
      placeholder={selectedValueLabel || placeholder}
      on:keydown={handleKeydown}
      bind:value={search}
      {disabled}
      tabindex="-1"
    />
  </label>
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <ul
    tabindex="0"
    class="menu dropdown-content bg-base-100 z-10 my-1 max-h-[20vh] w-full flex-nowrap flex-nowrap overflow-auto overflow-y-auto rounded border shadow {dropdownContentClasses}"
    class:p-1={size === "xs" || size === "sm"}
    class:p-2={size === "md" || size === "lg"}
    class:menu-xs={size === "xs"}
    class:menu-lg={size === "lg"}
    class:!fixed={useFixedPositioning}
    style="left: {dropdownCoords?.left}px; top: {dropdownCoords?.bottom}px; width:{dropdownCoords?.width}px; max-height: {maxHeight};"
  >
    {#each filteredItems as e, idx}
      {#if groupsPresent && (idx === 0 || e.group !== items[idx - 1].group)}
        <li class="menu-title">{e.group}</li>
      {/if}

      <li class:disabled={e.disabled || disabled}>
        <button
          class="{dropdownListItemClass} {highlightedIndex === idx ? 'bg-gray-200' : ''}"
          class:active={selectedValue === e.value}
          on:click={() => selectItem(e)}
          disabled={e.disabled || disabled}
          tabindex="-1"
        >
          {e.label}

          {#if e.desc}
            {#if useCustomTooltip}
              <!-- svelte-ignore a11y-no-static-element-interactions -->
              <span
                class="ml-auto h-6 w-6"
                on:mouseenter={(event) => handleMouseEnter(event, e.desc)}
                on:mouseleave={handleMouseExit}
              >
                <button class="btn btn-circle btn-ghost btn-xs" tabindex="-1">
                  <Icon icon="iconoir:info-circle" />
                </button>
              </span>
            {:else}
              <span class="tooltip ml-auto h-6 w-6" data-tip={e.desc}>
                <button class="btn btn-circle btn-ghost btn-xs" tabindex="-1">
                  <Icon icon="iconoir:info-circle" />
                </button>
              </span>
            {/if}
          {/if}
        </button>
      </li>
    {/each}
    {#if filteredItems.length === 0}
      <li class="menu-title">No items found</li>
    {/if}
  </ul>
</div>

{#if useCustomTooltip && showCustomTooltip && !isEmpty(customToolTipText)}
  <div class="custom-tooltip" style={customTooltipStyles}>
    {customToolTipText}
  </div>
{/if}

<style>
  .menu li button.active {
    background-color: oklch(var(--p) / var(--tw-bg-opacity));
    color: oklch(var(--pc) / var(--tw-text-opacity));
  }

  .custom-tooltip {
    color: #fff;
    white-space: normal; /* Allow text wrapping */
    text-align: center;
    font-size: 14px;
    pointer-events: none;
    box-sizing: border-box; /* Include padding in width calculations */
  }
</style>
