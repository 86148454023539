<script>
  import { onMount, tick } from "svelte";
  import { fly } from "svelte/transition";
  import LogoLoader from "./LogoLoader.svelte";
  import IssueTable from "./TableViews/IssueTable/IssueTable.svelte";

  export let userId;
  export let data;

  let assignData = {};
  let allResults = [];
  let allRules = [];

  async function initialiseUserData() {
    let output = {};

    for (let i = 0; i < data.length; i++)
      if (data[i].user["id"] == userId) output = data[i];

    return output;
  }

  function refreshTable(event) {
    allResults = event.detail;
  }

  onMount(async () => {
    assignData = await initialiseUserData();
    allResults = assignData.hits || [];

    let allRulesObject = {};
    allResults.forEach((entry) => {
      allRulesObject[entry.rule.id] = entry.rule;
    });

    allRules = Object.values(allRulesObject);

    await tick();

    scrollMe.scrollIntoView();
  });
</script>

<div in:fly={{ y: 50 }} id="scrollMe" class="scroll-mt-28">
  {#if assignData && assignData.user}
    {#key assignData}
      <IssueTable
        {allRules}
        data={allResults}
        tableType="CLASSIC"
        tableRootPage="user-assigns"
        tableId={userId}
        on:refresh={refreshTable}
      />
    {/key}
  {:else}
    <div class="m-4 border p-4">
      <LogoLoader />
    </div>
  {/if}
</div>
