<script>
  import { date_format } from "../stores";
</script>

<div>
  <label class="label cursor-pointer justify-start gap-2">
    <input
      class="radio-primary radio radio-sm"
      type="radio"
      checked={$date_format === "date"}
      on:change={(e) => {
        if (e.target.checked) $date_format = "date";
      }}
    />

    <span class="label-text">
      {new Date().toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      })}
    </span>
  </label>

  <label class="label cursor-pointer justify-start gap-2">
    <input
      class="radio-primary radio radio-sm"
      type="radio"
      checked={$date_format === "ago"}
      on:change={(e) => {
        if (e.target.checked) $date_format = "ago";
      }}
    />

    <span class="label-text">2 hours ago</span>
  </label>
</div>
