<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchDelete, fetchPost } from "../../helpers";
  import { org_teams, org_users } from "../../stores";
  import Modal from "../Modals/Modal.svelte";
  import Select from "../Select.svelte";

  export let selectedTeams = [];
  export let assignees = [];
  // We need to bind this upwards for responsiveness -
  // however, when adding a binding it seems to spawn some sort of infinite loop
  // that causes the tab to slow down and eventually crash
  // See
  export let rule_id = null;
  export let asset_id = null;
  export let hit_id;
  export let workflow_step_id = null;
  export let asset_type = "asset_rule";

  export let size = "xs";
  export let disableEditing = false;
  export let containerClass = "";
  export let tableView = false;
  export let disabled = false;
  export let tableHeaderContent = "People/Team";
  export let isCompact = false;
  export let alignLeft = false;

  let liveAssignees;
  $: (liveAssignees = assignees.filter(
    (assignee) =>
      !$org_users.find((user) => user.id === assignee.assigned_id)?.is_deleted,
  )),
    [$org_users];

  const dispatch = createEventDispatcher();

  const id = Math.random().toString(36).substring(7); // random id for modal dialog

  function getUrl(asset_type, id) {
    switch (asset_type) {
      case "asset_rule":
        //console.log("An asset rule assigning event");
        return `asset-rule/${rule_id}/${asset_id}/assign/${id}`;
      case "asset":
        //console.log("An asset assigning event");
        return `asset/${asset_id}/assign/${id}`;
      case "hit":
        //console.log("A hit assigning event");
        return `hit/${hit_id}/assign/${id}`;
      case "workflow_step":
        //console.log("A workflow step assigning event");
        return `workflow-step/${workflow_step_id}/user/${id}`;
      default:
        console.log("Invalid asset type");
        break;
    }
  }
  function getTeamUrl(asset_type, id) {
    switch (asset_type) {
      case "workflow_step":
        //console.log("A workflow step assigning event");
        return `workflow-step/${workflow_step_id}/team/${id}`;
      default:
        console.log("Invalid asset type");
        break;
    }
  }

  function getUserInfo(id) {
    const user = $org_users.find((user) => user.id === id);
    if (!user) return { first_name: "", last_name: "" };
    if (user)
      return { first_name: user.first_name, last_name: user.last_name ?? "" };
  }

  const deleteAssignee = async (id) => {
    const url = getUrl(asset_type, id);
    const res = await fetchDelete(url);

    if (!res.success) {
      toast.error("Failed to delete assignee");
      return;
    }

    assignees = assignees.filter((a) => a.assigned_id !== id);

    dispatch("change", assignees);
    dispatch("delete", id);
  };
  const deleteTeam = async (id) => {
    const url = getTeamUrl(asset_type, id);
    const res = await fetchDelete(url);

    if (!res.success) {
      toast.error("Failed to delete team");
      return;
    }

    selectedTeams = selectedTeams.filter((t) => t !== id);

    dispatch("delete", id);
  };

  const updateAssignees = async (id) => {
    const url = getUrl(asset_type, id);
    const res = await fetchPost(url);

    if (!res.success) {
      toast.error("Failed to add assignee");
      return;
    }

    assignees = [...assignees, { assigned_id: id }];

    dispatch("change", assignees);
    dispatch("addUser", id);
  };
  const updateTeams = async (id) => {
    const url = getTeamUrl(asset_type, id);
    const res = await fetchPost(url);

    if (!res.success) {
      toast.error("Failed to add team");
      return;
    }

    selectedTeams = [...selectedTeams, id];

    dispatch("addTeam", id);
  };
</script>

{#if !tableView}
  <div
    class="flex w-24 min-w-fit shrink-0 items-center gap-0.5 {containerClass}"
  >
    {#if liveAssignees?.length}
      <div
        class="avatar-group -space-x-2 overflow-visible {alignLeft
          ? ''
          : 'ml-auto'}"
      >
        {#each liveAssignees.slice(0, liveAssignees.length === 3 ? 3 : 2) as assignee}
          <div
            class="tooltip"
            data-tip="{getUserInfo(assignee.assigned_id)
              .first_name} {getUserInfo(assignee.assigned_id).last_name}"
          >
            <div class="avatar placeholder border-2">
              <div
                class="bg-neutral text-neutral-content w-6 rounded-full uppercase"
              >
                {getUserInfo(assignee.assigned_id).first_name[0]}{getUserInfo(
                  assignee.assigned_id,
                ).last_name[0] ?? ""}
              </div>
            </div>
          </div>
        {/each}
        {#if liveAssignees?.length > 3}
          <div
            class="tooltip"
            data-tip={liveAssignees
              .slice(2, liveAssignees.length)
              .map(
                (a) =>
                  `${getUserInfo(a.assigned_id).first_name} ${getUserInfo(a.assigned_id).last_name}`,
              )
              .toString()
              .replaceAll(",", ", ")}
          >
            <div class="avatar placeholder border-2">
              <div class="bg-neutral text-neutral-content w-6 rounded-full">
                +{liveAssignees.length - 2}
              </div>
            </div>
          </div>
        {/if}
      </div>
    {/if}

    <!-- svelte-ignore missing-declaration -->
    <button
      class="btn btn-xs"
      class:btn-sm={size === "sm" && (isCompact || !liveAssignees?.length)}
      class:btn-primary={!isCompact && !liveAssignees?.length}
      class:btn-circle={isCompact || liveAssignees?.length}
      class:btn-disabled={disableEditing}
      class:border-none={isCompact || liveAssignees?.length}
      class:bg-[#E8F1FE]={isCompact || liveAssignees?.length}
      class:ml-auto={isCompact && !assignees?.length && !alignLeft}
      on:click|stopPropagation={() =>
        document.getElementById(`assign-user-${id}`).showModal()}
    >
      {#if isCompact || liveAssignees?.length}
        <Icon icon="iconoir:user-plus" class="text-primary ml-0.5 text-lg" />
      {:else}
        Add Assignee
      {/if}
    </button>
  </div>
{:else}
  {#if !disabled}
    <button
      class="btn btn-outline btn-primary btn-xs w-full"
      class:btn-sm={size === "sm"}
      class:btn-disabled={disableEditing}
      on:click|stopPropagation={() =>
        document.getElementById(`assign-user-${id}`).showModal()}
    >
      Add Approvers
    </button>
  {/if}

  <table class="table-xs bg-base-200 mt-2 table rounded">
    <thead>
      <tr class="border-b-base-content/10 border-b">
        <th class="w-full">Reviewer</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {#each liveAssignees as a}
        <tr>
          <td>
            <div class="flex items-center gap-1">
              <Icon icon="iconoir:user" class="shrink-0" />
              {getUserInfo(a.assigned_id).first_name}
              {getUserInfo(a.assigned_id).last_name}
            </div>
          </td>
          <td>
            {#if !disabled}
              <button
                class="btn btn-square btn-ghost btn-xs hover:!btn-error"
                on:click={() => deleteAssignee(a.assigned_id)}
              >
                <Icon icon="iconoir:cancel" />
              </button>
            {/if}
          </td>
        </tr>
      {/each}
      {#each selectedTeams as t}
        <tr>
          <td>
            <div class="flex items-center gap-1">
              <Icon icon="iconoir:community" class="shrink-0" />
              {$org_teams.find((team) => team.id === t).name}
            </div>
          </td>
          {#if !disabled}
            <td>
              <button
                class="btn btn-square btn-ghost btn-xs hover:!btn-error"
                on:click={() => deleteTeam(t)}
              >
                <Icon icon="iconoir:cancel" />
              </button>
            </td>
          {/if}
        </tr>
      {/each}
    </tbody>
  </table>
{/if}

<Modal modalId="assign-user-{id}">
  <h3 class="text-lg font-bold">Assigned Users</h3>

  <Select
    classes="w-full mt-2"
    items={[
      ...$org_users
        .filter(
          (u) =>
            !liveAssignees.map((a) => a.assigned_id).includes(u.id) &&
            !u.is_deleted,
        )
        .map((u) => ({
          label: `${u.first_name} ${u.last_name}`,
          value: `user:${u.id}`,
          group: "Users",
        })),
      ...$org_teams
        .filter((t) => !selectedTeams.includes(t.id))
        // if asset type is workflow_step only then we can assign teams
        .filter((t) => asset_type === "workflow_step")
        .map((t) => ({
          label: t.name,
          value: `team:${t.id}`,
          group: "Teams",
        })),
    ]}
    on:change={(e) => {
      const type = e.detail.split(":")[0];
      const id = Number(e.detail.split(":")[1]);

      if (type === "user") updateAssignees(id);
      else if (type === "team") updateTeams(id);
    }}
  />

  <table class="table">
    <thead>
      <tr>
        <th class="w-full">{tableHeaderContent}</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      {#each liveAssignees as a}
        <tr>
          <td>
            {getUserInfo(a.assigned_id).first_name}
            {getUserInfo(a.assigned_id).last_name}
          </td>
          <td>
            <button
              class="btn btn-outline btn-error btn-xs"
              on:click={() => deleteAssignee(a.assigned_id)}
            >
              Remove
            </button>
          </td>
        </tr>
      {/each}
      {#each selectedTeams as t}
        <tr>
          <td>{$org_teams.find((team) => team.id === t).name}</td>
          <td>
            <button
              class="btn btn-outline btn-error btn-xs"
              on:click={() => deleteTeam(t)}
            >
              Remove
            </button>
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</Modal>
