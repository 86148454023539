<script>
  import { toast } from "svelte-sonner";
  import { saveRuleToMyRules } from "../../../helpers";
  import { isEmpty } from "../../../lib/utils/GenericUtils";
  import QueryPrettyPrint from "../QueryPrettyPrint.svelte";
  import { isParentConditionalRule } from "../../../lib/utils/RuleUtils";
  import Badge from "../WorkflowBoard/Badge.svelte";
  import SavedRulesTableRowConditionalRules from "../../TableViews/SavedRulesTable/SavedRulesTableRowConditionalRules.svelte";
  import {
    ConditionalRuleType,
    RuleStatus,
  } from "./../../../lib/interfaces/Rule.interface.js";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let rule = {};
  export let savedRules = [];
  export let isSavedRulesTable = false;
  export let isConditionalChild = false;

  let context = "";
  let examples = [""];
  let definitions = [];

  $: if (rule && rule?.query) {
    const query = JSON.parse(rule.query);
    context = query?.context;
    examples = query.examples;
    definitions = query.definitions.length > 0 ? query.definitions : [];
  }
</script>

<div>
  <p class="text-sm font-medium">ID</p>
  <p class="text-sm font-light">
    #{rule.id}
  </p>
</div>
<div>
  <p class="text-sm font-medium">Description</p>
  <p class="text-sm font-light">
    {rule.description}
  </p>
</div>
<div>
  <p class="text-sm font-medium">Remediation Step</p>
  <p class="text-sm font-light">
    {isEmpty(rule.remediation_step)
      ? "No remediation step."
      : rule.remediation_step}
  </p>
</div>
<div class="col-span-3 grid grid-cols-2 gap-2">
  <div class="col-span-2 flex flex-col gap-1">
    <p class="mt-1 text-sm font-medium">Instruction</p>
    <p class="text-sm font-light">
      {context}
    </p>
  </div>

  <div class="col-span-2 flex flex-col gap-1">
    <p class="mt-1 text-sm font-medium">Definitions</p>

    <div class="bg-base-100 overflow-x-auto rounded border">
      <table class="table-xs table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {#each definitions as def, idx}
            <tr>
              <td>
                <span
                  class="badge badge-ghost h-auto {isConditionalChild
                    ? 'text-xs'
                    : ''}"
                >
                  {def.name}
                </span>
              </td>
              <td>{def.desc}</td>
            </tr>
          {:else}
            <p class="col-span-2 text-sm font-light">No definitions found.</p>
          {/each}
        </tbody>
      </table>
    </div>
  </div>

  <div
    class="{isConditionalChild
      ? 'col-span-3'
      : 'col-span-1'} flex flex-col gap-1"
  >
    <p class="mt-1 text-sm font-medium">Examples</p>
    <QueryPrettyPrint
      {rule}
      isParentConditional={isParentConditionalRule(rule)}
    />
  </div>

  {#if !isSavedRulesTable}
    <div class="col-span-2 ml-auto mr-[36px] flex place-items-end gap-1">
      <button
        class="btn btn-outline btn-primary btn-xs ml-auto"
        on:click={async () => {
          savedRules = saveRuleToMyRules(rule.id, savedRules, "asset");
          toast.success("Rule has been saved to My Rules");
        }}
      >
        Save to My Rules
      </button>
    </div>
  {/if}
</div>
