<script class="ts">
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../../helpers";
  import { org_labels } from "../../stores";
  import Icon from "@iconify/svelte";
  import Modal from "../Modals/Modal.svelte";

  let bottomButtons = {
    show: true,
    primaryText: "Confirm",
    secondaryText: "Cancel",
    loading: false,
    primaryAction: createLabel,
    secondaryAction: closeModal,
  };

  let labelName = "";
  let labelColor = getRandomLabelColor();
  let errorMessage = "";
  let submitDisabled = false;

  function getRandomLabelColor() {
    return "#" + Math.floor(Math.random() * 16777215).toString(16);
  }

  function openModal() {
    labelName = "";
    errorMessage = "";
    submitDisabled = false;
    labelColor = getRandomLabelColor();
    document.querySelector("#addLabelModal")?.showModal();
  }

  function closeModal() {
    document.querySelector("#addLabelModal")?.close();
  }

  function validateForm() {
    if (!labelName.trim()) {
      errorMessage = "Label name is required.";
      return false;
    }

    return true;
  }

  async function createLabel() {
    const originalLabels = $org_labels;

    if (!validateForm()) {
      return;
    }
    bottomButtons.loading = true;

    const res = await fetchPost("/label/create", {
      name: labelName,
      color: labelColor,
    });

    const updatedlabels = [...$org_labels, res.label];
    org_labels.set(updatedlabels);

    if (!res.success) {
      console.warn("Error creating label", res);
      org_labels.set(originalLabels);
      return;
    }
    bottomButtons.loading = false;
    toast.success("Label created successfully.");
    closeModal();
  }
</script>

<button class="btn btn-primary btn-sm" on:click={openModal}>Add Label</button>

<Modal
  modalId="addLabelModal"
  size="sm"
  cornerBackButton={true}
  cornercloseButton={false}
  {bottomButtons}
>
  <div class="space-y-2">
    <div
      class="bg-primary-content m-auto flex aspect-square w-16 items-center justify-center rounded-full"
    >
      <Icon icon="iconoir:label" class="text-primary text-3xl" />
    </div>
    <h3 class="text-center font-bold">Add Label</h3>
  </div>

  <div class="mt-6 space-y-2">
    <p class="text-sm">Label Name</p>
    <input
      class="input input-bordered w-full"
      type="text"
      placeholder="Add label name"
      bind:value={labelName}
    />
    <p class="text-sm">Label Color</p>
    <div class="input-bordered flex items-stretch gap-2">
      <input
        class="input cursor-pointer rounded-lg border-0 p-0 disabled:pointer-events-none disabled:opacity-50"
        type="color"
        bind:value={labelColor}
      />
      <input
        class="input input-bordered grow"
        type="text"
        placeholder="Label Value"
        bind:value={labelColor}
      />
    </div>
    {#if errorMessage}
      <span class="text-xs text-red-500">*{errorMessage}</span>
    {/if}
  </div>
</Modal>

<!-- Removes the color around the color picker -->
<style>
  input[type="color"]::-moz-color-swatch {
    border: none;
  }

  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 0;
  }

  input[type="color"]::-webkit-color-swatch {
    border: none;
  }
</style>
