<script>
  import { toast } from "svelte-sonner";
  import { fetchGet, fetchPatch } from "../../../../helpers";
  import { isLegacyWorkflowCheck } from "../../../../lib/utils/WorkflowUtils";
  import { currentUser } from "../../../../stores";
  import AssignUsers from "../../AssignUsers.svelte";
  import ApprovalStatusDropdown from "./ApprovalStatusDropdown.svelte";
  import AssetVersion from "./AssetVersion.svelte";
  import ApprovalButton from "./StageButtons/ApprovalButton.svelte";
  import RequestChangesButton from "./StageButtons/RequestChangesButton.svelte";

  //JW: We need one (or two) more Stage Buttons to handle "Approve to next stage" and "Approve" at the final stage

  export let reportId;
  export let activeAsset;
  export let activeVersion;
  export let versions;
  export let currentAssetApprovingPermission;
  export let versionIdx;
  export let currentStreamPerm;
  export let activeAssetId;
  export let workflow;
  export let previewUrl;
  export let versionUpdated = false;
  export let showCompareVersions = false;

  let userAssignedToAsset = false;
  let isFullyApprovedOrArchived = false;
  let isDefault = false;
  let isLastStep = false;
  let isLegacyWorkflow = isLegacyWorkflowCheck(workflow);
  let activeWorkflowStep;
  let canApproveOrSubmit = false;

  $: if (
    activeAsset.asset.workflow_step.id ||
    activeAsset.asset.approval_status
  ) {
    activeWorkflowStep = workflow.workflow_steps.find(
      (ws) => ws.id === activeAsset.asset.workflow_step.id,
    );
    updateHotBtnVariables();
  }

  const updateHotBtnVariables = () => {
    userAssignedToAsset = activeAsset.asset.asset_assigned_users.some(
      (user) => user.assigned_id === $currentUser.id,
    );
    isFullyApprovedOrArchived = checkAssetStatusAndStep();
    isLastStep = isOnLastStep();
    isDefault = isLegacyWorkflow
      ? activeWorkflowStep.default
      : activeWorkflowStep.default && !isLastStep; //&& (activeWorkflowStep.postion==0) idk why this isn't working
    canApproveOrSubmit =
      currentStreamPerm === "edit" ||
      activeAsset.approvers[activeWorkflowStep.id].find(
        (user) => user.user_id === $currentUser.id,
      );
  };

  function isOnLastStep() {
    if (!activeWorkflowStep || !workflow.workflow_steps.length) return false;

    const lastStepPosition = workflow.workflow_steps.reduce(
      (max, step) => (step.position > max.position ? step : max),
      workflow.workflow_steps[0],
    )?.position;

    if (isLegacyWorkflow) {
      return activeWorkflowStep.position === lastStepPosition;
    } else {
      return (
        activeWorkflowStep.position === lastStepPosition ||
        activeWorkflowStep.position + 1 === lastStepPosition
      );
    }
  }

  function checkAssetStatusAndStep() {
    if (!activeAsset.asset || !workflow || !workflow.workflow_steps) {
      console.error("Missing asset or workflow data.");
      return false;
    }

    return (
      activeAsset.asset.approval_status === "Archived" ||
      (activeAsset.asset.approval_status === "Approved" && isOnLastStep())
    );
  }

  const fullyApprove = async () => {
    // If fully approve, we don't need to show the modal

    const res = await fetchPatch(`/asset/${activeAssetId}`, {
      status: "Approved",
      step: isLegacyWorkflow
        ? activeWorkflowStep.id
        : workflow.workflow_steps.reduce(
            (max, step) => (step.position > max.position ? step : max),
            workflow.workflow_steps[0],
          )?.id,
      operation: "approval",
      isLegacyWorkflow: isLegacyWorkflow,
    });

    if (!res.success) {
      toast.error(
        res?.message ??
          `Something went wrong updating the status for Asset(${activeAssetId})`,
      );
      return;
    }

    activeAsset.asset = res?.asset;
    activeAsset = activeAsset;

    toast.success(
      res?.message ??
        `Successfully updated the status of Asset(${activeAssetId}) to Status(Approved)`,
    );
  };

  const updateAssetComments = async () => {
    const res = await fetchGet(`/asset/comment/${activeAssetId}`);

    activeAsset.asset_comments = res.comments;
  };

  // $: console.log(
  //   "currentAssetApprovingPermission",
  //   currentAssetApprovingPermission,
  // );
</script>

{#if !isFullyApprovedOrArchived}
  <AssetVersion
    bind:activeVersion
    bind:currentStreamPerm
    bind:versions
    bind:versionIdx
    bind:previewUrl
    bind:activeAssetId
    bind:reportId
    bind:versionUpdated
    bind:showCompareVersions
  />
{/if}

<AssignUsers
  size="sm"
  asset_id={activeAssetId}
  assignees={activeAsset.asset.asset_assigned_users}
  asset_type={"asset"}
  disableEditing={!currentAssetApprovingPermission}
  tableHeaderContent="People"
  on:change={(e) => {
    activeAsset.asset.asset_assigned_users = e.detail;
    updateAssetComments();
  }}
/>
<!-- {#key activeAsset.approvers}
  <ApproverStatus
    size="sm"
    {activeWorkflowStep}
    approvers={activeAsset.approvers}
    {workflow}
    assetId={activeAssetId}
  />
{/key} -->

{#if canApproveOrSubmit}
  <div class="divider divider-horizontal mx-2"></div>
  {#key [isDefault, isLastStep, isFullyApprovedOrArchived, activeAsset, currentAssetApprovingPermission]}
    {#if (isLegacyWorkflow && isDefault) || (!isLegacyWorkflow && isDefault && !isLastStep)}
      <ApprovalButton
        type="Submit"
        currentAssetApprovingPermission={true}
        bind:versions
        bind:activeVersion
        bind:assetStatus={activeAsset.asset.status}
        disabled={activeAsset.asset.status === "Processing"}
      />
    {:else if isLastStep && !isFullyApprovedOrArchived}
      <RequestChangesButton
        bind:currentAssetApprovingPermission
        bind:versions
        bind:activeVersion
      />
      <span class="text-sm">or</span>
      <ApprovalButton
        type="fullyApprove"
        bind:currentAssetApprovingPermission
        bind:versions
        bind:activeVersion
        on:fullyApprove={fullyApprove}
        bind:assetStatus={activeAsset.asset.status}
        disabled={activeAsset.asset.status === "Processing"}
      />
    {:else if activeAsset?.asset?.approval_status === "Changes Requested"}
      <ApprovalButton
        type="Send"
        bind:currentAssetApprovingPermission
        bind:versions
        bind:activeVersion
        bind:assetStatus={activeAsset.asset.status}
        disabled={activeAsset.asset.status === "Processing"}
      />
    {:else if !isFullyApprovedOrArchived}
      <RequestChangesButton
        bind:currentAssetApprovingPermission
        bind:versions
        bind:activeVersion
      />
      <span class="text-sm">or</span>
      <ApprovalButton
        type="ApproveToNextStage"
        bind:currentAssetApprovingPermission
        bind:versions
        bind:activeVersion
        bind:assetStatus={activeAsset.asset.status}
        disabled={activeAsset.asset.status === "Processing"}
      />
    {/if}
  {/key}

  <div
    class="divider divider-horizontal mx-2"
    class:hidden={isLastStep && isFullyApprovedOrArchived}
  ></div>

  <ApprovalStatusDropdown
    bind:activeAsset
    bind:currentAssetApprovingPermission
    bind:versions
    bind:activeAssetId
    bind:activeVersion
    bind:workflow
    bind:isLastStep
    bind:isLegacyWorkflow
  />
{/if}
