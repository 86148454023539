<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchDelete, fetchGet, fetchPatch } from "../../helpers";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import Pagination from "../table/Pagination.svelte";
  import LabelBean from "../Labels/LabelBean.svelte";
  import { showStreamsWithSelectedLabel, updatePinnedLabels } from "../Labels/labels";
  import { org_id, org_labels, org_name, pinnedLabels } from "../../stores";
  import { getTextConstant } from "../../lib/utils/ConstantsUtils";

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");

  let labelToDelete = 0;
  let editLabel = null;
  let currentPage = 1;
  let itemsPerPage = 5;

  async function handleUpdatePin(label, isAssetPin = false) {
    const originalPinnedState = label.pinned;
    const originalLivePinnedState = label.pinned_live;
    const originalAssetPinnedState = label.pinned_asset;

    try {
      const requestPayload = {
        operation: "pinning",
        pinned: null,
        pinned_live: null,
        pinned_asset: null,
      };

      if (label.pinned) {
        label.pinned = false;
        requestPayload.pinned = false;

        if (isAssetPin) {
          label.pinned_live = true;
          requestPayload.pinned_live = true;
        } else {
          label.pinned_asset = true;
          requestPayload.pinned_asset = true;
        }
      } else {
        if (isAssetPin) {
          requestPayload.pinned_asset = !label.pinned_asset;
          label.pinned_asset = !label.pinned_asset;
        } else {
          requestPayload.pinned_live = !label.pinned_live;
          label.pinned_live = !label.pinned_live;
        }
      }

      const response = await fetchPatch(`/label/${label.id}`, requestPayload);

      if (!response.success) {
        throw new Error(
          response?.message ??
            `Failed to pin ${label.name} to the ${isAssetPin ? textConstants["AssetReview"] : "Streams"} Sidebar.`,
        );
      }

      // Update store after successful API call
      org_labels.update(labels => {
        const index = labels.findIndex(l => l.id === label.id);
        if (index !== -1) {
          labels[index] = { ...labels[index], ...label };
        }
        return labels;
      });

      // Update pinnedLabels store based on new state
      pinnedLabels.update(labels => {
        if (label.pinned || label.pinned_live || label.pinned_asset) {
          // Add to pinned if not already there
          return labels.some(l => l.id === label.id) 
            ? labels.map(l => l.id === label.id ? { ...l, ...label } : l)
            : [...labels, label];
        } else {
          // Remove from pinned
          return labels.filter(l => l.id !== label.id);
        }
      });

      return label;
    } catch (e) {
      label.pinned = originalPinnedState;
      label.pinned_live = originalLivePinnedState;
      label.pinned_asset = originalAssetPinnedState;
      
      console.error(e);
      toast.error(
        `Failed to pin ${label.name} to the ${isAssetPin ? textConstants["AssetReview"] : "Streams"} Sidebar.`,
      );
    }

    return label;
  }

  async function updateLabel() {
    try {
      if (editLabel.name.trim().length === 0) {
        toast.warning("Label name cannot be empty");
        return;
      }

      const response = await fetchPatch(`/label/${editLabel.id}`, {
        name: editLabel.name,
        color: editLabel.color,
      });

      if (response.success) {
        org_labels.update(labels => {
          const index = labels.findIndex(l => l.id === editLabel.id);
          if (index !== -1) {
            labels[index] = { ...labels[index], name: editLabel.name, color: editLabel.color };
          }
          return labels;
        });
        
        editLabel = null;
        toast.success("Changes saved.");
      }
    } catch {
      toast.error("Failed to save changes.");
    }
  }

  async function deleteLabel() {
    try {
      labelDeleteModal.close();
      await fetchDelete(`/label/${labelToDelete}`);
      
      org_labels.update(labels => 
        labels.filter(l => l.id !== labelToDelete)
      );

      // Update pinnedLabels store directly
      pinnedLabels.update(labels => 
        labels.filter(l => l.id !== labelToDelete)
      );
      
      toast.success("Item deleted.");
    } catch (error) {
      toast.error("Failed to delete item.");
    }
  }

  // Update initial load to handle both stores
  onMount(async () => {
    const response = await fetchGet("/label");
    if (response.success) {
      const sortedLabels = response.labels.sort((a, b) => a.id - b.id);
      org_labels.set(sortedLabels);
      
      // Set pinned labels initially
      const pinnedOnes = sortedLabels.filter(
        label => label.pinned || label.pinned_live || label.pinned_asset
      );
      pinnedLabels.set(pinnedOnes);
    }
  });
</script>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="labelDeleteModal"
  message="Are you sure you want to delete this label?"
  onConfirm={deleteLabel}
  onCancel={() => labelDeleteModal.close()}
/>

<div class="overflow-x-auto">
  <table class="bg-base-200 text-base-content/80 table border-collapse text-sm *:h-12">
    <thead>
      <tr class="text-base-content/90 border-gray-200 *:text-sm *:font-semibold">
        <th>Label</th>
        <th># of Streams</th>
        <th># of Folders</th>
      </tr>
    </thead>
    <tbody>
      {#each $org_labels.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as label}
        {#if editLabel && editLabel.id === label.id}
          <tr>
            <td>
              <div class="m-auto flex gap-2">
                <input
                  type="text"
                  placeholder="Add label name"
                  class="input input-sm input-bordered"
                  bind:value={editLabel.name}
                />
                <input
                  class="color-picker input input-sm input-bordered"
                  type="color"
                  bind:value={editLabel.color}
                />
              </div>
            </td>
            <td />
            <td>
              <div class="m-auto flex gap-2">
                <button class="btn btn-outline btn-primary btn-xs" on:click={updateLabel}>
                  Save
                </button>
                <button class="btn btn-outline btn-error btn-xs" on:click={() => (editLabel = null)}>
                  Cancel
                </button>
              </div>
            </td>
          </tr>
        {:else}
          <tr>
            <td>
              <LabelBean name={label.name} color={label.color} id={label.id} />
            </td>
            <td>
              <button
                class="view-streams-button"
                disabled={label.reports.filter((r) => r.classification === "live").length === 0}
                on:click={() => showStreamsWithSelectedLabel(label.id)}
                type="button"
              >
                {#if label.reports.filter((r) => r.classification === "live").length === 0}
                  No Streams
                {:else if label.reports.filter((r) => r.classification === "live").length === 1}
                  1 Stream
                {:else}
                  {label.reports.filter((r) => r.classification === "live").length} Streams
                {/if}
              </button>
            </td>
            <td>
              <button
                class="view-streams-button"
                disabled={label.reports.filter((r) => r.classification === "asset").length === 0}
                on:click={() => showStreamsWithSelectedLabel(label.id, "asset")}
                type="button"
              >
                {#if label.reports.filter((r) => r.classification === "asset").length === 0}
                  No Folders
                {:else if label.reports.filter((r) => r.classification === "asset").length === 1}
                  1 Folder
                {:else}
                  {label.reports.filter((r) => r.classification === "asset").length} Folders
                {/if}
              </button>
            </td>
            <td>
              <div class="m-auto flex gap-2">
                <div class="tooltip" data-tip={label.pinned || label.pinned_live ? "Unpin Label from Streams Sidebar" : "Pin Label to Streams Sidebar"}>
                  <button
                    class="btn btn-outline flex-nowrap {label.pinned || label.pinned_live ? 'btn-ghost' : 'btn-primary'} btn-xs"
                    on:click={async () => {
                      const updatedLabel = await handleUpdatePin(label, false);
                      updatePinnedLabels($org_labels);
                    }}
                  >
                    {#if label.pinned || label.pinned_live}
                      <Icon icon="iconoir:pin-slash" />
                    {:else}
                      <Icon icon="iconoir:pin" />
                    {/if}
                    Streams
                  </button>
                </div>
                
                <div class="tooltip" data-tip={label.pinned || label.pinned_asset ? "Unpin Label from Asset Review Sidebar" : "Pin Label to Asset Review Sidebar"}>
                  <button
                    class="btn btn-outline flex-nowrap text-nowrap {label.pinned || label.pinned_asset ? 'btn-ghost' : 'btn-primary'} btn-xs"
                    on:click={async () => {
                      const updatedLabel = await handleUpdatePin(label, true);
                      updatePinnedLabels($org_labels);
                    }}
                  >
                    {#if label.pinned || label.pinned_asset}
                      <Icon icon="iconoir:pin-slash" />
                    {:else}
                      <Icon icon="iconoir:pin" />
                    {/if}
                    Asset Review
                  </button>
                </div>
                
                <button class="btn btn-outline btn-primary btn-xs" on:click={() => (editLabel = { ...label })}>
                  Edit
                </button>
                
                <!-- svelte-ignore missing-declaration -->
                <button
                  class="btn btn-outline btn-error btn-xs"
                  on:click={() => {
                    labelToDelete = label.id;
                    labelDeleteModal.showModal();
                  }}
                >
                  Delete
                </button>
              </div>
            </td>
          </tr>
        {/if}
      {/each}
    </tbody>
  </table>
</div>

<Pagination bind:currentPage bind:itemsPerPage totalHits={$org_labels.length} />

<style>
  .color-picker {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    padding: 0 2px;
  }
  .color-picker::-webkit-color-swatch {
    border-radius: 4px;
    border: none;
  }
  .color-picker::-moz-color-swatch {
    border-radius: 4px;
    border: none;
  }
</style>