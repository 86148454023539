<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import Select from "../../components/Select.svelte";
  import { fetchDelete, fetchGet, fetchPatch, fetchPost } from "../../helpers";
  import Modal from "../Modals/Modal.svelte";

  const dispatch = createEventDispatcher();

  export let reportId;
  export let reportStatus;
  export let allUsers;
  export let allTeams;
  export let associatedTeams;
  export let associatedUsers;
  export let orgPermission = "none";
  export let entityToEditId;
  export let entityToEditType;
  export let inEditMode = false;
  export let assetView = false;

  let selectedPermission = null;
  let selectedEntity = null;

  let warningMessage = "";

  let dialog = null;

  let selectableEntities = getSelectableEntities();

  $: if (orgPermission || associatedTeams || associatedUsers) {
    selectableEntities = getSelectableEntities();
  }

  function getSelectableEntities() {
    let output = [
      ...allUsers
        .filter((user) => !user.is_deleted)
        .map((user) => ({
          value: `user:${user.id}`,
          label: user.first_name + " " + user.last_name,
          group: "Users",
        })),

      ...allTeams.map((team) => ({
        value: `team:${team.id}`,
        label: team.name,
        group: "Teams",
      })),
    ];

    if (orgPermission == "none") {
      output = [
        ...output,
        {
          value: "org",
          label: "All Users",
          group: "Organisation",
        },
      ];
    }

    output = output.filter((entity) => {
      if (entity.group === "Teams") {
        return !isEntityAssociated(entity.value.split(":")[1], associatedTeams);
      } else if (entity.group === "Users") {
        return !isEntityAssociated(entity.value.split(":")[1], associatedUsers);
      }
      return true;
    });

    return output;
  }

  const groupBy = (entity) => entity.group;

  function closeDialog() {
    inEditMode = false;
    document.querySelector("#report-share-dialog")?.close();
    dispatch("close");
  }

  function isEntityAssociated(entityId, associatedEntities) {
    return associatedEntities.some((entity) => entity.id == entityId);
  }

  async function sharePermissionWithEntity() {
    if (selectedPermission === "none" || selectedEntity === "none") {
      warningMessage = "* Please select a permission and entity to share with.";
      return;
    }

    const [entityType, entityId] = selectedEntity.split(":");

    try {
      if (entityType != "org") {
        const response = await fetchPost(
          `/${entityType}/${entityId}/report/${reportId}`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchPost(
          `/report/${reportId}/change-org-permission`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function deletePermissionFromEntity() {
    try {
      if (entityToEditType === "org") {
        const response = await fetchPost(
          `/report/${reportId}/change-org-permission`,
          {
            permission: "none",
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchDelete(
          `/${entityToEditType}/${entityToEditId}/report/${reportId}`,
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function updatePermissionForEntity() {
    try {
      if (entityToEditType === "org") {
        const response = await fetchPost(
          `/report/${reportId}/change-org-permission`,
          { permission: selectedPermission },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      } else {
        const response = await fetchPatch(
          `/${entityToEditType}/${entityToEditId}/report/${reportId}`,
          {
            permission: selectedPermission,
          },
        );

        if (!response.success) {
          warningMessage = response.message;
          return;
        }
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function getUserToReportSubscriptions() {
    try {
      const response = await fetchGet(`/report/get-all-subs/${reportId}`);

      if (response.success) {
        const subs = response.subscribers;

        associatedUsers = associatedUsers.map((user) => {
          const sub = subs.find((sub) => sub.id === user.id);
          user["subscribed"] = sub ? true : false;
          return user;
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function subscribeToReport(userId) {
    try {
      const response = await fetchPost(`/report/subscribe/${reportId}`, {
        user_id: userId,
      });

      if (!response.success) {
        warningMessage = response.message;
        return;
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  async function unsubscribeFromReport(userId) {
    try {
      const response = await fetchDelete(`/report/subscribe/${reportId}`, {
        user_id: userId,
      });

      if (!response.success) {
        warningMessage = response.message;
        return;
      }
    } catch (error) {
      warningMessage = error.message;
      return;
    }
  }

  function copyStreamLink() {
    navigator.clipboard.writeText(window.location.href);
    toast.success("Copied URL to clipboard");
  }

  getUserToReportSubscriptions();

  $: permItems = assetView
    ? [
        { value: "view", label: "View" },
        { value: "upload", label: "Upload" },
        { value: "edit", label: "Manage & Approve" },
      ]
    : [
        { value: "view", label: "View" },
        { value: "edit", label: "Edit" },
      ];
</script>

<Modal modalId="report-share-dialog" size="md" on:close={closeDialog}>
  <h1 class="flex items-center gap-2 text-2xl">
    <Icon icon="iconoir:community" class="text-2xl" /> Access / Alerts
  </h1>

  <Select
    items={selectableEntities}
    bind:selectedValue={selectedEntity}
    placeholder="Add Users or Teams"
    classes="w-full mt-4"
    size="md"
    on:change={(e) => {
      selectedPermission = "view";
      selectedEntity = e.detail;

      if (selectedEntity.startsWith("team")) {
        const teamId = selectedEntity.split(":")[1];
        const team = allTeams.find((team) => team.id == teamId);

        if (!isEntityAssociated(teamId, associatedTeams)) {
          associatedTeams = [
            ...associatedTeams,
            { ...team, permission: "view" },
          ];
        }
      } else if (selectedEntity.startsWith("user")) {
        const userId = selectedEntity.split(":")[1];
        const user = allUsers.find((user) => user.id == userId);

        if (!isEntityAssociated(userId, associatedUsers)) {
          associatedUsers = [
            ...associatedUsers,
            { ...user, permission: "view" },
          ];
        }
      } else orgPermission = "view";

      sharePermissionWithEntity();

      selectedPermission = null;
      selectedEntity = null;
    }}
  />

  <table class="mt-2 table">
    <thead>
      <tr>
        <th>People/Teams</th>
        <th class:hidden={assetView}>
          <div
            class="tooltip tooltip-bottom"
            data-tip="Email alerts when new hits are found"
          >
            Alerts
          </div>
        </th>
        <th colspan="2">
          <div
            class="tooltip tooltip-bottom"
            data-tip="Permission to view / edit this stream"
          >
            Permissions
          </div>
        </th>
      </tr>
    </thead>
    {#each associatedUsers as user (user.id)}
      <tr>
        <td>
          <div class="flex items-center gap-1">
            <Icon icon="iconoir:user" />
            {user.first_name
              ? user.first_name + " " + user.last_name
              : "All Users"}
          </div>
        </td>
        <td class:hidden={assetView}>
          <input
            class="toggle toggle-primary"
            type="checkbox"
            id="switch"
            name="switch"
            checked={user.subscribed}
            disabled={reportStatus === "Live" ? false : true}
            on:change={(e) => {
              const subscribe = e.target.checked;
              const userId = user.id;

              if (subscribe) subscribeToReport(userId);
              else unsubscribeFromReport(userId);
            }}
          />
        </td>
        <td>
          <Select
            items={permItems}
            selectedValue={user.permission}
            on:change={async (e) => {
              entityToEditId = user.id;
              entityToEditType = "user";
              selectedPermission = e.detail;
              await updatePermissionForEntity();
            }}
          />
        </td>
        <td>
          <button
            class="btn btn-square btn-ghost btn-sm hover:btn-error"
            on:click={() => {
              entityToEditId = user.id;
              entityToEditType = "user";
              associatedUsers = associatedUsers.filter(
                (user) => user.id !== entityToEditId,
              );
              deletePermissionFromEntity();
            }}
          >
            <Icon icon="iconoir:cancel" />
          </button>
        </td>
      </tr>
    {/each}
    {#each associatedTeams as team (team.id)}
      <tr>
        {#if team.name !== "All Users"}
          <td>
            <div class="flex items-center gap-1">
              <Icon icon="iconoir:community" />
              {team.name}
            </div>
          </td>
          <td class:hidden={assetView} />
          <td>
            <Select
              items={permItems}
              selectedValue={team.permission}
              on:change={async (e) => {
                entityToEditId = team.id;
                entityToEditType = "team";
                selectedPermission = e.detail;
                await updatePermissionForEntity();
              }}
            />
          </td>
          <td>
            <button
              class="btn btn-square btn-ghost btn-sm hover:btn-error"
              on:click={() => {
                entityToEditId = team.id;
                entityToEditType = "team";
                associatedTeams = associatedTeams.filter(
                  (team) => team.id !== entityToEditId,
                );
                deletePermissionFromEntity();
              }}
            >
              <Icon icon="iconoir:cancel" />
            </button>
          </td>
        {/if}
      </tr>
    {/each}
    {#if orgPermission != "none"}
      <tr>
        <td>
          <div class="flex items-center gap-1">
            <Icon icon="iconoir:community" /> All Users
          </div>
        </td>
        <td class:hidden={assetView} />
        <td>
          <Select
            items={permItems}
            selectedValue={orgPermission}
            on:change={async (e) => {
              entityToEditId = "org";
              entityToEditType = "org";
              selectedPermission = e.detail.value;
              await updatePermissionForEntity();
            }}
          />
        </td>
        <td>
          <button
            class="btn btn-square btn-ghost btn-sm hover:btn-error"
            on:click={() => {
              entityToEditId = "org";
              entityToEditType = "org";
              orgPermission = "none";
              deletePermissionFromEntity();
            }}
          >
            <Icon icon="iconoir:cancel" />
          </button>
        </td>
      </tr>
    {/if}
  </table>

  <div class="divider my-1" />

  <div class="flex justify-between gap-2">
    <button class="btn btn-sm" on:click={copyStreamLink}>
      Copy link to stream
    </button>
    <button class="btn btn-primary btn-sm" on:click={closeDialog}>
      Done
    </button>
  </div>
</Modal>
