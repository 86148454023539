<script>
  import { marked } from "marked";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchPost, getInitials } from "../../../helpers";
  import { fullname } from "../../../stores";
  import IconBot from "../../LLMChat/icons/IconBot.svelte";
  import SvelteMarkdown from "svelte-markdown";
  import IconUser from "../../LLMChat/icons/IconUser.svelte";
  import Modal from "../Modal.svelte";

  const dispatch = createEventDispatcher();

  export let thread_id;
  export let rules = [];
  export let chatHistory = [];

  let textInput = "";

  const generatedRule = async (feedback) => {
    textInput = "";
    const res = await fetchPost("/rule_generation/regenerate_rules", {
      thread_id,
      feedback,
      enable_rule_output:
        typeof chatHistory[chatHistory.length - 1].text === "string"
          ? chatHistory[chatHistory.length - 1].text.includes(
              "turn these into rules",
            )
          : false,
      feedback_rule_ids: rules.map((r) => r.id),
      accepted_rule_ids: [],
    });

    if (res.message !== "Thread response generated successfully") {
      toast.error("Failed to generate rules");
      return;
    }

    if (typeof res.content === "string") {
      chatHistory = [
        ...chatHistory,
        {
          role: "bot",
          text: res.content.replace(/\n/g, "<br/>"),
          timestamp: new Date().toLocaleTimeString("en-US", { hour12: true }),
          hint: [
            "Yes, I'm ready! Let's turn these into rules.",
            "Not yet. Can you change the rule about...?",
          ],
        },
      ];
    } else if (typeof res.content === "object") {
      rules = Object.entries(res.content).map(([key, value]) => ({
        id: key,
        ...value,
      }));

      chatHistory = [
        ...chatHistory,
        {
          role: "bot",
          text: rules
            .map(
              (c) =>
                `<span class="font-semibold">${c.rule_title}:</span> ${c.explanation}`,
            )
            .join("<br/><br/>"),
          timestamp: new Date().toLocaleTimeString("en-US", { hour12: true }),
          is_rule: true,
        },
      ];
    }

    setTimeout(() => scrollToMe.scrollIntoView({ behavior: "smooth" }), 400);
  };

  const sendInput = async () => {
    if (textInput.trim().length === 0) return;

    chatHistory = [
      ...chatHistory,
      {
        role: "user",
        text: textInput,
        timestamp: new Date().toLocaleTimeString("en-US", { hour12: true }),
      },
    ];
    textInput = "";

    await generatedRule(chatHistory[chatHistory.length - 1].text);
  };
</script>

<Modal modalId="editDescriptionsModal" size="2xl">
  <div class="flex flex-col gap-4">
    <h1 class="text-3xl font-semibold">Manage AI-generated Descriptions</h1>

    <div class="rounded border">
      <h2 class="px-4 py-2 text-xl font-semibold">AI-assisted Haast Chatbot</h2>

      <div class="border-b" />

      <div class="px-4 py-2">
        {#each chatHistory as c}
          <div
            class="chat {c.role === 'bot' ? 'chat-start' : 'chat-end'}"
            transition:slide
          >
            {#if c.role === "bot"}
              <IconBot />
            {:else}
              <IconUser initials={getInitials($fullname)} />
            {/if}

            <div class="chat-header">
              {c.role === "user" ? $fullname : "Haast Bot"}
              <time class="text-xs opacity-50">{c.timestamp}</time>
            </div>
            <div class="chat-bubble chat-bubble-secondary text-black">
              <!-- {@html c.text} -->
              {#if c.is_rule}
                {@html c.text}
              {:else}
                <SvelteMarkdown source={c.text} />
              {/if}
            </div>
            <!-- <div class="chat-footer opacity-50">Delivered</div> -->
          </div>
        {/each}
      </div>
    </div>

    <div class="flex flex-wrap gap-x-4 gap-y-2">
      {#each chatHistory[chatHistory.length - 1]?.hint ?? [] as e}
        <button
          class="badge badge-ghost badge-lg"
          on:click={() => {
            textInput = e;
            // sendInput();
          }}
        >
          {e}
        </button>
      {/each}
    </div>

    <div class="flex flex-col gap-2">
      <textarea
        class="textarea textarea-bordered"
        placeholder="Ask me a question"
        bind:value={textInput}
      />
      <button
        class="btn btn-sm btn-primary ml-auto"
        disabled={textInput.trim().length === 0}
        on:click={sendInput}
      >
        Send
      </button>
    </div>

    <div class="mt-6 flex gap-4">
      <button class="btn btn-sm" on:click={() => dispatch("back")}>Back</button>

      {#if rules.length === 0}
        <div class="tooltip" data-tip="No rules generated yet">
          <button class="btn btn-sm btn-primary" disabled>
            Proceed to Create AI Rules from Descriptions
          </button>
        </div>
      {:else}
        <button
          class="btn btn-sm btn-primary"
          on:click={() => {
            editDescriptionsModal?.close();
            dashboardModal?.showModal();
          }}
        >
          Proceed to Create AI Rules from Descriptions
        </button>
      {/if}
    </div>
    <div id="scrollToMe" />
  </div>
</Modal>
