<svg
  class="max-logo max-h-full max-w-full"
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xmlns:svgjs="http://svgjs.dev/svgjs"
  width="1000"
  height="229"
  viewBox="0 0 1000 229"
  ><g transform="matrix(1,0,0,1,-0.6060606060606233,-0.4907016366405088)"
    ><svg
      viewBox="0 0 396 91"
      data-background-color="#ffffff"
      preserveAspectRatio="xMidYMid meet"
      height="229"
      width="1000"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      ><g
        id="tight-bounds"
        transform="matrix(1,0,0,1,0.2400000000000091,0.19499497351216633)"
        ><svg
          viewBox="0 0 395.52 90.61001005297567"
          height="90.61001005297567"
          width="395.52"
          ><g
            ><svg
              viewBox="0 0 540.3729549726006 123.79449555628922"
              height="90.61001005297567"
              width="395.52"
              ><g
                transform="matrix(1,0,0,1,144.85295497260057,17.109080066028405)"
                ><svg
                  viewBox="0 0 395.52 89.57633542423241"
                  height="89.57633542423241"
                  width="395.52"
                  ><g id="textblocktransform"
                    ><svg
                      viewBox="0 0 395.52 89.57633542423241"
                      height="89.57633542423241"
                      width="395.52"
                      id="textblock"
                      ><g
                        ><svg
                          viewBox="0 0 395.52 89.57633542423241"
                          height="89.57633542423241"
                          width="395.52"
                          ><g transform="matrix(1,0,0,1,0,0)"
                            ><svg
                              width="395.52"
                              viewBox="3.25 -33.900001525878906 151.44000244140625 34.30000305175781"
                              height="89.57633542423241"
                              data-palette-color="#fff"
                              ><path
                                d="M31.05-33.7L31.05 0 23.3 0 23.3-13.45 10.95-13.45 10.95 0 3.25 0 3.25-33.7 10.95-33.7 10.95-20 23.3-20 23.3-33.7 31.05-33.7ZM47.75-14.3L47.75-14.3 47.4-13.1 55.5-13.1 55.15-14.3Q53.7-19.2 52.77-22.65 51.85-26.1 51.65-27.1L51.65-27.1 51.45-28.1Q51.05-25.35 47.75-14.3ZM45.55-6.9L43.5 0 35.45 0 46.7-33.7 56.4-33.7 67.65 0 59.4 0 57.35-6.9 45.55-6.9ZM82.25-14.3L82.25-14.3 81.9-13.1 90-13.1 89.65-14.3Q88.2-19.2 87.27-22.65 86.35-26.1 86.15-27.1L86.15-27.1 85.95-28.1Q85.55-25.35 82.25-14.3ZM80.05-6.9L78 0 69.95 0 81.2-33.7 90.9-33.7 102.15 0 93.9 0 91.85-6.9 80.05-6.9ZM127.49-9.8L127.49-9.8Q127.49-5.25 124.14-2.43 120.79 0.4 115.24 0.4L115.24 0.4Q109.29 0.4 105.54-1.7L105.54-1.7 105.54-9.45Q110.09-6.15 115.24-6.15L115.24-6.15Q116.99-6.15 118.14-7.08 119.29-8 119.29-9.5L119.29-9.5Q119.29-10 119.14-10.45 118.99-10.9 118.59-11.3 118.19-11.7 117.82-11.98 117.44-12.25 116.72-12.63 115.99-13 115.49-13.25 114.99-13.5 114.02-13.95 113.04-14.4 112.44-14.65L112.44-14.65Q109.19-16.15 107.22-18.35 105.24-20.55 105.24-24.2L105.24-24.2Q105.24-28.55 108.44-31.23 111.64-33.9 117.29-33.9L117.29-33.9Q122.34-33.9 125.99-32.2L125.99-32.2 125.99-25Q121.94-27.35 117.59-27.35L117.59-27.35Q115.64-27.35 114.62-26.55 113.59-25.75 113.59-24.5 113.59-23.25 114.84-22.28 116.09-21.3 118.59-20.2L118.59-20.2Q120.14-19.5 121.17-18.98 122.19-18.45 123.52-17.53 124.84-16.6 125.62-15.58 126.39-14.55 126.94-13.05 127.49-11.55 127.49-9.8ZM154.69-33.7L154.69-27.5 146.49-27.5 146.49 0 138.74 0 138.74-27.5 130.49-27.5 130.49-33.7 154.69-33.7Z"
                                opacity="1"
                                transform="matrix(1,0,0,1,0,0)"
                                fill="#fff"
                                class="wordmark-text-0"
                                data-fill-palette-color="primary"
                                id="text-0"
                              /></svg
                            ></g
                          ></svg
                        ></g
                      ></svg
                    ></g
                  ></svg
                ></g
              ><g
                ><svg
                  viewBox="0 0 123.70613201098946 123.79449555628922"
                  height="123.79449555628922"
                  width="123.70613201098946"
                  ><g
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="4 4 56 56.040000915527344"
                      x="0"
                      y="0"
                      height="123.79449555628922"
                      width="123.70613201098946"
                      class="icon-icon-0"
                      data-fill-palette-color="accent"
                      id="icon-0"
                      ><g
                        data-name="Layer 2"
                        fill="#fff"
                        data-fill-palette-color="accent"
                        ><rect
                          x="4"
                          y="40.04"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="14"
                          y="28.04"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="34"
                          y="28.04"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="14"
                          y="52.04"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="34"
                          y="52.04"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="14"
                          y="4"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="34"
                          y="4"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="24"
                          y="40.04"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="44"
                          y="40.04"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="4"
                          y="16"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="24"
                          y="16"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /><rect
                          x="44"
                          y="16"
                          width="16"
                          height="8"
                          rx="1.31"
                          ry="1.31"
                          fill="#fff"
                          data-fill-palette-color="accent"
                        /></g
                      ></svg
                    ></g
                  ></svg
                ></g
              ></svg
            ></g
          ><defs /></svg
        ><rect
          width="395.52"
          height="90.61001005297567"
          fill="none"
          stroke="none"
          visibility="hidden"
        /></g
      ></svg
    ></g
  ></svg
>
