<script>
  import Icon from "@iconify/svelte";
  import Select from "../../components/Select.svelte";
  import { generate32BitInteger } from "../../helpers";
  import Clause from "./Clause.svelte";

  export let fields;
  export let query;
  export let delBtn = true;
  export let isViewing = false;
  export let selectedChannels = [];

  // Local state for collapse
  let isCollapsed = false;

  function addClause() {
    query.nested = query.nested.concat([
      {
        id: generate32BitInteger(),
        field: "text",
        type: "clause",
        not: false,
        operation: "",
        value: "",
        slop: 1,
        term: true,
        regex: false,
        delete: false,
        options: [],
      },
    ]);
    query = query;
  }

  function addGroup() {
    query.nested = query.nested.concat([
      {
        id: generate32BitInteger(),
        not: false,
        type: "relation",
        relation: "and",
        delete: false,
        nested: [],
      },
    ]);
    query = query;
  }

  const queryRelationOptions = [
    {
      label: "AND",
      value: "AND",
      desc: "All rules are true",
    },
    {
      label: "OR",
      value: "OR",
      desc: "At least one rule is true",
    },
    {
      label: "AND NOT",
      value: "AND NOT",
      desc: "At least one rule is false",
    },
    {
      label: "OR NOT",
      value: "OR NOT",
      desc: "All rules are false",
    },
  ];

  // Counts the number of clauses including those nested recursively
  function countClauses(queryObject) {
    let count = 0;

    function traverse(obj) {
      if (obj.type === "clause") {
        count++;
      }
      if (Array.isArray(obj.nested)) {
        obj.nested.forEach(traverse);
      }
    }

    traverse(queryObject);
    return count;
  }

  const toggleCollapse = () => {
    isCollapsed = !isCollapsed;
  };
</script>

{#if !isCollapsed}
  <!-- This if else is responsible for making queries collapsible -->
  {#if query.type == "clause"}
    <Clause {fields} bind:query {selectedChannels} />
  {/if}
  {#if query.type == "relation"}
    {#if !query.delete}
      <!-- delBtn is false only for top level -->
      <div
        class="flex min-w-[564px] gap-2 rounded border p-2"
        class:overflow-auto={!delBtn}
      >
        <div
          class="group flex flex-col gap-1
            {query.nested.length >= 1 ? 'mb-10 py-0.5' : ''}"
        >
          {#if query.nested.length > 1}
            <button
              on:click={toggleCollapse}
              class="ml-auto h-full w-1/2 rounded-tl border-l border-t border-opacity-20 group-hover:border-opacity-100
                {query.relation === 'AND'
                ? 'border-primary'
                : query.relation === 'OR'
                  ? 'border-secondary'
                  : 'border-gray-500'}"
              class:invisible={query.nested.length <= 1}
            />
          {/if}

          <Select
            dropdownRowClass="block justify-between"
            useCustomTooltip={true}
            items={queryRelationOptions}
            inputClasses="!min-w-[112px]"
            disabled={query.nested.length <= 1 || isViewing}
            on:change={(e) => {
              switch (e.detail) {
                case "AND":
                  query.relation = "AND";
                  query.not = false;
                  break;
                case "OR":
                  query.relation = "OR";
                  query.not = false;
                  break;
                case "AND NOT":
                  query.relation = "AND";
                  query.not = true;
                  break;
                case "OR NOT":
                  query.relation = "OR";
                  query.not = true;
                  break;
              }
            }}
            selectedValue={query.relation + (query.not ? " NOT" : "")}
          />
          {#if query.nested.length > 1}
            <button
              on:click={toggleCollapse}
              class="relative ml-auto h-full w-1/2 rounded-bl border-b border-l border-opacity-20 group-hover:border-opacity-100
                {query.relation === 'AND'
                ? 'border-primary'
                : query.relation === 'OR'
                  ? 'border-secondary'
                  : 'border-gray-500'}"
              class:invisible={query.nested.length <= 1}
            >
              <button
                on:click={toggleCollapse}
                class="absolute -bottom-2 -left-2 aspect-square bg-white p-0.5 text-opacity-60 hover:text-opacity-100
                  {query.relation === 'AND'
                  ? 'text-primary'
                  : query.relation === 'OR'
                    ? 'text-secondary'
                    : 'text-gray-600'}"
              >
                <Icon icon="iconoir:minus-circle" />
              </button>
            </button>
          {/if}
        </div>

        <div class="grow space-y-2">
          {#each query.nested as elem}
            <svelte:self
              {fields}
              bind:query={elem}
              {isViewing}
              {selectedChannels}
              on:queryChanged={() => {
                // Force update of the parent component
                query = query;
              }}
            />
          {/each}

          <div class="flex gap-2">
            <button
              class="btn btn-outline btn-primary btn-sm"
              disabled={isViewing}
              on:click={addClause}
            >
              <Icon icon="iconoir:plus" />
              Add a Rule
            </button>

            <button
              class="btn btn-outline btn-primary btn-sm"
              data-tooltip="Add Group"
              disabled={isViewing}
              on:click={addGroup}
            >
              <Icon icon="iconoir:add-circle" />
              Add a Group
            </button>

            <button
              class="btn btn-outline btn-error btn-sm ml-auto"
              data-tooltip="Delete"
              on:click={() => {
                query.delete = true;
                query = query;
              }}
              disabled={!delBtn || isViewing}
            >
              <Icon icon="iconoir:trash" />
              Delete
            </button>
          </div>
        </div>
      </div>
    {/if}
  {/if}
{:else}
  <div>
    <button on:click={toggleCollapse} class="text-primary btn btn-sm btn-ghost">
      <Icon icon="iconoir:add-circle" />
      Show {countClauses(query)} more hidden clauses</button
    >
  </div>
{/if}
