<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  let columns = {
    index: { displayName: "Hit ID", selected: true },
    ruleName: { displayName: "Rule Name", selected: true },
    pool: { displayName: "Channel", selected: true },
    hitTitle: { displayName: "Hit Title", selected: true },
    highlight: { displayName: "Hit Highlight", selected: true },
    url: { displayName: "Source Location URL", selected: true },
    contentLink: { displayName: "Direct Content URL", selected: true },
    assignedUsers: { displayName: "Assigned Users", selected: true },
    status: { displayName: "Status", selected: true },
    dataType: { displayName: "Data Type", selected: true },
    priority: { displayName: "Priority", selected: true },
    action: { displayName: "Actions", selected: false },
    done: { displayName: "Completion State", selected: false },
    ruleQuery: { displayName: "Rule Query", selected: false },
    createdDate: { displayName: "Hit Created Timestamp", selected: false },
    lastUpdated: { displayName: "Last Updated Timestamp", selected: false },
  };

  onMount(async () => {
    dispatch("updateColumns", columns);
  });

  $: dispatch("updateColumns", columns);
</script>

<div>
  <h3 class="text-lg font-semibold">Columns</h3>

  <div class="grid grid-cols-2 gap-x-2 sm:grid-cols-3">
    {#each Object.keys(columns) as column}
      <label class="label col-span-1 cursor-pointer justify-start gap-2">
        <input
          class="checkbox checkbox-sm"
          type="checkbox"
          name={"column_" + columns[column]["displayName"]}
          id={"column_" + columns[column]["displayName"]}
          bind:checked={columns[column]["selected"]}
        />

        <span class="label-text">{columns[column]["displayName"]}</span>
      </label>
    {/each}
  </div>
</div>
