<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { fetchGet, fetchPost } from "../../../helpers";
  import { canEditCurrentStream, org_users } from "../../../stores";

  export let assignees = [];
  export let hit;
  export let data;
  export let disableEditing = false;
  export let assigneesUpdated = false;

  let users = {};

  let assigneeNames = [];

  $: if ($org_users && assignees) assigneeNames = getAssigneeNames();

  $: if (assigneesUpdated) {
    assigneesUpdated = false;

    fetchGet(`/hit/${hit.hit.id}/assign`).then((response) => {
      if (response.success) {
        const assignedUserIds = response.assigned_users;
        const newAssignees = assignedUserIds.map((id) => {
          return { assigned_id: id };
        });
        assignees = newAssignees;
        assigneeNames = getAssigneeNames();

        data = data.map((entry) => {
          if (entry.hit.id === hit.hit.id) entry.hit.assigned_users = assignees;
          return entry;
        });
      }
    });
  }

  function getAssigneeNames() {
    const uniqueAssignees = new Set(
      assignees.map((assignee) => {
        const user = $org_users?.find(
          (user) => user.id == assignee.assigned_id,
        );

        if (user) {
          return `${user.first_name} ${user.last_name}`;
        } else {
          return "";
        }
      }),
    );
    return Array.from(uniqueAssignees).filter((name) => name !== "");
  }

  async function updateAssignees(assignedUserId) {
    const hitId = hit.hit.id;

    try {
      assigneeNames = getAssigneeNames();

      await fetchPost(`/hit/${hitId}/assign`, {
        act: "user",
        assigned_user: { value: assignedUserId },
        to_delete: false,
      });
    } catch (error) {
      console.warn(error);
    } finally {
      data = data.map((hit) => {
        if (hit.hit.id === hitId) hit.hit.assigned_users = assignees;
        return hit;
      });
    }
  }

  async function deleteAssignee(userId) {
    const hitId = hit["hit"]["id"];
    const originalAssignees = assignees;

    try {
      assignees = assignees.filter((a) => a.assigned_id != userId);
      assigneeNames = getAssigneeNames();

      const response = await fetchPost(`/hit/${hitId}/assign`, {
        act: "user",
        assigned_user: { value: userId },
        to_delete: true,
      });

      if (!response.success) {
        assignees = originalAssignees;
        assigneeNames = getAssigneeNames();
      }
    } catch (error) {
      assignees = originalAssignees;
      assigneeNames = getAssigneeNames();
      console.warn(error);
    } finally {
      data = data.map((hit) => {
        if (hit.hit.id === hitId) hit.hit.assigned_users = assignees;
        return hit;
      });
    }
  }

  onMount(async () => {
    $org_users.forEach((user) => {
      users[user.id] = `${user.first_name} ${user.last_name}`;
    });
  });
</script>

<div class="flex items-center gap-0.5">
  <div class="avatar-group mx-auto -space-x-2 overflow-visible">
    {#each assigneeNames.slice(0, assigneeNames.length === 3 ? 3 : 2) as assigneeName}
      <div class="tooltip" data-tip={assigneeName}>
        <button
          class="group avatar placeholder border-2"
          class:btn-disabled={disableEditing}
          on:click={() => {
            const selected = assignees.find(
              (user) => users[user.assigned_id] === assigneeName,
            );
            if (selected) {
              deleteAssignee(selected.assigned_id);
              assignees = assignees.filter((a) => !assigneeNames.includes(a));
            }
          }}
        >
          <div
            class="w-6 rounded-full bg-neutral text-neutral-content"
            class:group-hover:bg-error={!disableEditing}
            class:group-hover:text-error-content={!disableEditing}
          >
            <span class:group-hover:hidden={!disableEditing}>
              {#if assigneeName.split(" ").length > 1}
                {assigneeName
                  .split(" ")
                  .map((word) => word[0].toUpperCase())
                  .join("")}
              {:else}
                {assigneeName[0].toUpperCase()}
              {/if}
            </span>
            <Icon
              icon="iconoir:cancel"
              class="hidden text-lg
                {!disableEditing ? 'group-hover:inline' : ''}"
            />
          </div>
        </button>
      </div>
    {/each}
    {#if assigneeNames.length > 3}
      <div
        class="tooltip"
        data-tip={assigneeNames
          .slice(2, assigneeNames.length)
          .toString()
          .replaceAll(",", ", ")}
      >
        <div class="avatar placeholder border-2">
          <div class="w-6 rounded-full bg-neutral text-neutral-content">
            <span>+{assigneeNames.length - 2}</span>
          </div>
        </div>
      </div>
    {/if}
  </div>

  {#if assignees && $canEditCurrentStream}
    <div class="dropdown dropdown-end">
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <label
        tabindex="0"
        class="btn btn-primary btn-xs whitespace-nowrap"
        class:btn-disabled={disableEditing}
        class:btn-circle={assigneeNames.length}
        for=""
      >
        {assigneeNames.length ? "+" : "Add Assignee"}
      </label>
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <ul
        tabindex="0"
        class="menu dropdown-content menu-xs z-[1] m-1 w-max gap-1 rounded border bg-base-100 p-1 shadow max-h-80 overflow-auto flex-nowrap"
      >
        {#each $org_users as user}
          <li>
            <button
              class:active={assignees.find((a) => a.assigned_id === user.id)}
              on:click={() => {
                if (assignees.find((a) => a.assigned_id == user.id)) {
                  deleteAssignee(user.id);
                } else {
                  assignees = [...assignees, { assigned_id: user.id }];
                  updateAssignees(user.id);
                }
              }}
            >
              {user.first_name}
              {user.last_name}
            </button>
          </li>
        {/each}
      </ul>
    </div>
  {/if}
</div>
