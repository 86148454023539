<script>
  export let query;
</script>

<!-- <div class=""> -->
{#if query.type == "clause"}
  <div class="flex min-w-max flex-nowrap gap-2">
    <span class="badge badge-ghost">{query.field}</span>
    <span class="badge badge-ghost">{query.not ? "does not" : "does"}</span>
    <span class="badge badge-ghost">{query.operation}</span>
    <div class="join">
      <span class="badge join-item badge-ghost">{query.value}</span>
      {#if query.slop}
        <span class="badge join-item badge-ghost border-l border-l-white">
          {query.slop}
        </span>
      {/if}
    </div>
    {#if query.options.length}
      <span class="badge badge-ghost">{query.options}</span>
    {/if}
  </div>
{:else if query.type == "relation"}
  <div class="flex min-w-max gap-2 rounded border p-2">
    <div class="flex flex-col gap-1 py-1" class:py-2={query.nested.length > 1}>
      {#if query.nested.length > 1}
        <div
          class="ml-auto h-full w-1/2 rounded-tl border-l border-t"
          class:border-primary={query.relation === "AND"}
          class:border-secondary={query.relation === "OR"}
          class:invisible={query.nested.length <= 1}
        />
      {/if}

      <span class="badge badge-ghost">
        {query.relation}
        {query.not ? "not" : ""}
      </span>

      {#if query.nested.length > 1}
        <div
          class="ml-auto h-full w-1/2 rounded-bl border-b border-l"
          class:border-primary={query.relation === "AND"}
          class:border-secondary={query.relation === "OR"}
          class:invisible={query.nested.length <= 1}
        />
      {/if}
    </div>

    <div class="grow space-y-2">
      {#each query.nested as elem}
        <svelte:self query={elem} />
      {/each}
    </div>
  </div>
{/if}
<!-- </div> -->
