<script>
  import { onMount } from "svelte";
  import { link } from "svelte-spa-router";
  import Badge from "../components/AssetReview/WorkflowBoard/Badge.svelte";
  import Pagination from "../components/table/Pagination.svelte";
  import { fetchGet } from "../helpers";
  import { currentUser } from "../stores";

  let activeSection = "Assets";
  let assets = [];
  let asset_reviews = [];
  let others = [];
  let loading = true;
  let currentPage = 1;
  const itemsPerPage = 10;

  function isOverdue(dateString) {
    if (!dateString) return false;
    const dueDate = new Date(dateString);
    const today = new Date();
    return dueDate < today;
  }
  // function sortAssetReviews(reviews) {
  //   return reviews.sort(
  //     (a, b) => new Date(b.review_date) - new Date(a.review_date),
  //   );
  // }

  function formatDate(dateString) {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }

  function setSection(section) {
    activeSection = section;
  }

  function refreshTable(event) {
    // This function might need to be adjusted based on your needs
  }

  function getGoToLink(item) {
    if (!item.asset) return "";

    const baseUrl = `/#/review/${item.asset.report_id}/asset/${item.asset.id}`;

    if (item.type === "Hit") {
      return `${baseUrl}?ruleId=${item.rule_id}&hitId=${item.id}`;
    } else if (item.type === "Asset Rule") {
      return `${baseUrl}?ruleId=${item.rule.id}`;
    } else if (item.type === "Notification") {
      // Extract ruleId and hitId from the notification URL if present
      const urlParts = item.url.split("?");
      if (urlParts.length > 1) {
        const queryParams = new URLSearchParams(urlParts[1]);
        const ruleId = queryParams.get("ruleId");
        const hitId = queryParams.get("hitId");

        if (hitId) {
          return `${baseUrl}?ruleId=${ruleId}&hitId=${hitId}`;
        } else if (ruleId) {
          return `${baseUrl}?ruleId=${ruleId}`;
        }
      }
      return baseUrl;
    }

    return baseUrl;
  }

  onMount(async () => {
    const currentUserId = $currentUser.id;
    loading = true;
    try {
      const res = await fetchGet(`/user/${currentUserId}/queue`);
      //console.log("API response:", res); // Log the entire response

      if (res.success) {
        assets = [...res.data.assets] || [];
        // asset_reviews = [...sortAssetReviews(res.data.asset_reviews || [])];
        others = [
          ...(res.data.hits || []).map((hit) => ({ ...hit, type: "Hit" })),
          ...(res.data.asset_rules || []).map((rule) => ({
            ...rule,
            type: "Asset Rule",
          })),
          ...(res.data.notifications || []).map((notification) => ({
            ...notification,
            type: "Notification",
          })),
        ];
        //console.log("Assets:", assets); // Log the assets
        //console.log("Others:", others); // Log the others
      } else {
        console.error("API request was not successful");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      loading = false;
    }
  });
</script>

<svelte:head>
  <title>My Assets - Haast</title>
</svelte:head>

<div class="mx-auto max-w-screen-xl">
  <h1 class="mb-4 text-3xl font-semibold">My Assets</h1>
  {#if !loading}
    <div
      class="tabs bg-base-200 relative mb-2 shrink-0 overflow-hidden rounded border"
    >
      <button
        class="tab whitespace-nowrap capitalize"
        class:text-primary={activeSection === "Assets"}
        on:click={() => setSection("Assets")}>Assets ({assets.length})</button
      >
      <!-- <button
        class="tab whitespace-nowrap capitalize"
        class:text-primary={activeSection === "Asset Reviews"}
        on:click={() => setSection("Asset Reviews")}
        >Asset Reviews ({asset_reviews.length})</button
      > -->
      <button
        class="tab whitespace-nowrap capitalize"
        class:text-primary={activeSection === "Others"}
        on:click={() => setSection("Others")}>Others ({others.length})</button
      >
      <div
        class="slider bg-primary absolute bottom-0 left-0 h-0.5 w-1/2 transition-all"
        style:translate={activeSection === "Assets"
          ? "0%"
          : activeSection === "Asset Reviews"
            ? "100%"
            : "200%"}
      ></div>
    </div>

    <div class="mt-2 overflow-auto rounded border">
      <table class="table">
        <thead
          class="rounded-t-lg bg-[#F8FAFC]"
          style="border-radius: 16px;font-color: #475569;"
        >
          <tr style="background-color:#F8FAFC;">
            {#if activeSection === "Assets"}
              <th class="rounded-tl-lg">Asset Name</th>
              <th>Your Role</th>
              <th>Current Status</th>
              <th>Current Stage</th>
              <th>Due Date</th>
              <th class="rounded-tr-lg text-end">Link</th>
            <!-- {:else if activeSection === "Asset Reviews"}
              <th class="rounded-tl-lg">Asset Name</th>
              <th>Current Status</th>
              <th>Current Stage</th>
              <th>Due Date</th>
              <th class="rounded-tr-lg text-end">Link</th> -->
            {:else}
              <th class="rounded-tl-lg">Asset Name</th>
              <th>Type</th>
              <th>Status</th>
              <th>Preview</th>
              <th class="rounded-tr-lg text-end">Link</th>
            {/if}
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-300 bg-white">
          {#if activeSection === "Assets"}
            {#each assets.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as asset, index (asset.id)}
              <tr
                style={index === assets.length - 1 ? "border-radius: 16px" : ""}
              >
                <td>
                  <a
                    class="hover:text-primary block truncate py-0.5 text-start font-semibold"
                    href="/review/{asset.report_id}/asset/{asset.id}"
                    use:link
                  >
                    {asset.name}
                  </a>
                </td>
                <td>
                  {#if asset.is_approver}
                    Approver
                  {:else}
                    Viewer
                  {/if}
                </td>
                <td>
                  <Badge fullyRound={true} bgColor="#e0e0e0" textColor="#000">
                    {asset.status}
                  </Badge>
                </td>
                <td>
                  <Badge fullyRound={true} bgColor="#e0e0e0" textColor="#000">
                    {asset.approval_status}
                  </Badge>
                </td>
                <td>
                  {#if asset.due_date}
                    <Badge
                      fullyRound={true}
                      bgColor={isOverdue(asset.due_date)
                        ? "#FFE4E4"
                        : "#E0E0E0"}
                      textColor={isOverdue(asset.due_date)
                        ? "#D92D20"
                        : "black"}
                    >
                      {formatDate(asset.due_date)}
                    </Badge>
                  {/if}
                </td>
                <td class="text-end">
                  <a
                    href="/review/{asset.report_id}/asset/{asset.id}"
                    class="btn btn-primary btn-xs"
                    use:link
                  >
                    Go to Asset
                  </a>
                </td>
              </tr>
            {/each}
          <!-- {:else if activeSection === "Asset Reviews"}
            {#each asset_reviews as review, index (review.id)}
              <tr
                style={index === asset_reviews.length - 1
                  ? "border-radius: 16px"
                  : ""}
              >
                <td>
                  <a
                    class="hover:text-primary block truncate py-0.5 text-start font-semibold"
                    href="/review/{review.report_id}/asset/{review.id}?stage=review"
                    use:link
                  >
                    {review.name}
                  </a>
                </td>
                <td>
                  <Badge fullyRound={true} bgColor="#e0e0e0" textColor="#000">
                    {review.status}
                  </Badge>
                </td>
                <td>
                  <Badge fullyRound={true} bgColor="#e0e0e0" textColor="#000">
                    {review.approval_status}
                  </Badge>
                </td>
                <td>
                  {#if review.due_date}
                    <Badge
                      fullyRound={true}
                      bgColor={isOverdue(review.due_date)
                        ? "#FFE4E4"
                        : "#E0E0E0"}
                      textColor={isOverdue(review.due_date)
                        ? "#D92D20"
                        : "black"}
                    >
                      {formatDate(review.due_date)}
                    </Badge>
                  {/if}
                </td>

                <td class="text-end">
                  <a
                    href="/review/{review.report_id}/asset/{review.id}?stage=review"
                    class="btn btn-primary btn-xs"
                    use:link
                  >
                    Go to Asset
                  </a>
                </td>
              </tr>
            {/each} -->
          {:else}
            {#each others.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as item, index (item.id)}
              <tr
                style={index === others.length - 1 ? "border-radius: 16px" : ""}
              >
                <td>{item.asset?.name || ""}</td>
                <td>
                  <Badge fullyRound={true} bgColor="#e0e0e0" textColor="#000">
                    {item.type}
                  </Badge>
                </td>
                <td>
                  <Badge fullyRound={true} bgColor="#e0e0e0" textColor="#000">
                    {item.asset?.status || ""}
                  </Badge>
                </td>
                <td>
                  <div class="whitespace-normal break-words">
                    {#if item.type === "Hit"}
                      {@html item.highlight || ""}
                    {:else if item.type === "Asset Rule"}
                      {item.rule?.name || ""}
                    {:else}
                      {item.text || ""}
                    {/if}
                  </div>
                </td>
                <td class="text-end">
                  {#if item.asset}
                    <a
                      href={getGoToLink(item)}
                      class="btn btn-primary btn-xs whitespace-nowrap"
                    >
                      Go to {item.type}
                    </a>
                  {/if}
                </td>
              </tr>
            {/each}
          {/if}
        </tbody>
      </table>
    </div>

    <Pagination
      bind:currentPage
      totalHits={activeSection === "Assets" ? assets.length : others.length}
      {itemsPerPage}
    />
  {:else}
    <p>Loading...</p>
  {/if}
</div>
