<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { hitColourToName } from "../../helpers";
  import { HitColour, HitColourName } from "../../lib/interfaces/Hit.interface";

  export let editable;
  export let hit;
  export let rule;

  const dispatch = createEventDispatcher();

  function rejectHit(hit) {
    hit.expectedColour = HitColour.RISK;
    dispatch("refresh", hit);
  }

  function irrelevantHit(hit) {
    hit.expectedColour = HitColour.UNKNOWN;
    dispatch("refresh", hit);
  }

  function indeterminatetHit(hit) {
    hit.expectedColour = HitColour.REVIEW;
    dispatch("refresh", hit);
  }

  function approveHit(hit) {
    hit.expectedColour = HitColour.PASSED;
    dispatch("refresh", hit);
  }
</script>

{#key hit}
  <div class="flex shrink-0 items-center">
    <div class="dropdown dropdown-end">
      <!--svelte-ignore a11y-no-noninteractive-tabindex-->
      <!--svelte-ignore a11y-label-has-associated-control-->
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <label
        tabindex="0"
        class="btn btn-ghost btn-xs w-[116px] flex-nowrap justify-between whitespace-nowrap
                hit-status-colour-{editable
          ? hit.expectedColour
          : hit.colour
            ? hit.colour
            : 'grey'}"
      >
        ● {hitColourToName(editable ? hit.expectedColour : hit.colour)}

        {#if editable}
          <Icon icon="iconoir:nav-arrow-down" class="text-xs" />
        {/if}
      </label>

      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      {#if editable}
        <ul
          class="menu dropdown-content menu-xs bg-base-100 z-[1] mt-1 flex w-full min-w-max flex-col gap-1 rounded border p-1"
        >
          {#if rule.rule.affirmative_rule}
            <li>
              <button
                class="text-[#336A1D]"
                on:click|stopPropagation={() => approveHit(hit)}
              >
                <input
                  type="radio"
                  class="radio-primary radio radio-xs"
                  checked={hit.expectedColour == "green"}
                />
                {HitColourName.default.STRONG}
              </button>
            </li>
            <li>
              <button
                class="text-[#FFAD48]"
                on:click|stopPropagation={() => indeterminatetHit(hit)}
              >
                <input
                  type="radio"
                  class="radio-primary radio radio-xs"
                  checked={hit.expectedColour == "amber"}
                />
                {HitColourName.default.POSSIBLE}
              </button>
            </li>
            <li>
              <button on:click|stopPropagation={() => irrelevantHit(hit)}>
                <input
                  type="radio"
                  class="radio-primary radio radio-xs"
                  checked={hit.expectedColour == "grey"}
                />
                {HitColourName.default.WEAK}
              </button>
            </li>
            <li>
              <button
                class="text-[#FF4E4E]"
                on:click|stopPropagation={() => rejectHit(hit)}
              >
                <input
                  type="radio"
                  class="radio-primary radio radio-xs"
                  checked={hit.expectedColour == "red"}
                />
                {HitColourName.default.REJECTED}
              </button>
            </li>
          {:else}
            <button
              class="text-[#FF4E4E]"
              on:click|stopPropagation={() => rejectHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.expectedColour == "red"}
              />
              {HitColourName.negative.STRONG}
            </button>
            <button
              class="text-[#FFAD48]"
              on:click|stopPropagation={() => indeterminatetHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.expectedColour == "amber"}
              />
              {HitColourName.negative.POSSIBLE}
            </button>
            <button
              class=""
              on:click|stopPropagation={() => irrelevantHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.expectedColour == "grey"}
              />
              {HitColourName.negative.WEAK}
            </button>
            <button
              class="text-[#336A1D]"
              on:click|stopPropagation={() => approveHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.expectedColour == "green"}
              />
              {HitColourName.negative.ACCEPTED}
            </button>
          {/if}

          <!-- <button
                  class="btn btn-primary btn-xs btn-outline"
                  on:click={() => {
                    approveHitEverywhere(hit);
                  }}
                >
                  {HitColourName.ACCEPTEVERYWHERE}
                </button> -->
        </ul>
      {/if}
    </div>
  </div>
{/key}

<style>
  .hit-status-colour-red {
    background-color: #fae9e4;
    color: #ff4e4e;
  }

  .hit-status-colour-amber {
    background-color: #fff3e5;
    color: #ffad48;
  }

  .hit-status-colour-green {
    background-color: #ddffd0;
    color: #336a1d;
  }
</style>
