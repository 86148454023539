/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Report` entity classification values.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * utilising Report classifications so as to reduce redundancy & inconsistency in the codebase.
 */
export const ReportClassification = Object.freeze({
  ASSET: "asset",
  LIVE: "live",
  SPELLING: "spelling",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Report` entity status values.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * utilising Report statuses so as to reduce redundancy & inconsistency in the codebase.
 */
export const ReportStatus = Object.freeze({
  ARCHIVED: "Archived",
  DRAFT: "Draft",
  LIVE: "Live",
  UPLOAD: "Uploads",
  SEARCHING: "Searching",
  FAILED: "Failed",
  DELETING: "Deleting",
});
