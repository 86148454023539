<script>
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../helpers";
  import { currentUser, defaultRBStyle, role } from "../stores";
  import DateFormatSettings from "./DateFormatSettings.svelte";
  import SettingArticle from "../components/Settings/SettingArticle.svelte";
</script>

<svelte:head>
  <title>Settings - Haast</title>
</svelte:head>

<div class="flex max-w-screen-xl flex-col gap-6">
  {#if $role == "assignee" || $role == "user"}
    <h1 class="my-4 text-3xl font-semibold">Settings</h1>
  {/if}

  <SettingArticle>
    <div slot="header">
      <h1 class="text-xl">Date format</h1>
      <p class="text-base-content/70 text-sm">
        Select your prefered timestamp format for records.
      </p>
    </div>
    <DateFormatSettings />
  </SettingArticle>

  <SettingArticle>
    <div slot="header">
      <h1 class="text-xl">Rule Builder Style</h1>
      <p class="text-base-content/70 text-sm">
        Select your default rule builder view for the Haast chat bot:
      </p>
    </div>
    <div class="max-w-sm">
      <label class="label cursor-pointer justify-start gap-2">
        <input
          class="radio-primary radio radio-sm"
          type="radio"
          checked={$defaultRBStyle === "classic"}
          value="classic"
          on:change={() => ($defaultRBStyle = "classic")}
        />
        <span class="label-text">Classic: Show rules as 'text' results</span>
      </label>
      <label class="label cursor-pointer justify-start gap-2">
        <input
          class="radio-primary radio radio-sm"
          type="radio"
          checked={$defaultRBStyle === "visual"}
          value="visual"
          on:change={() => ($defaultRBStyle = "visual")}
        />
        <span class="label-text">
          Visual: Show rules via the visual rule builder
        </span>
      </label>
    </div>
  </SettingArticle>

  <SettingArticle>
    <div slot="header">
      <h1 class="text-xl">Account</h1>
      <p class="text-base-content/70 text-sm">
        Manage your account related settings
      </p>
    </div>
    <div>
      <button
        class="btn btn-primary"
        on:click={async () => {
          const response = await fetchPost("/auth/generate-reset-token", {
            email: $currentUser.email,
          });

          if (!response.success)
            toast.error(
              "Could not send password reset email. Please contact support.",
            );

          toast.success("Password reset email sent.");
        }}
      >
        Reset Password
      </button>
    </div>
  </SettingArticle>

  <p>
    Contact
    <a class="link-hover link link-primary" href="mail:support@haast.io">
      support@haast.io
    </a> with any issues.
  </p>
</div>
