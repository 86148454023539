<script>
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";
  import IndexStatistic from "../charts/BarChart/IndexStatistic.svelte";
  import ReportStatus from "../charts/BarChart/ReportsStatus.svelte";
  import TimeSaved from "../charts/BarChart/TimeSaved.svelte";
  import HomeCard from "../components/HomeCard.svelte";
  import TeamOverview from "../components/TeamOverview/TeamOverview.svelte";
  import { fetchGet, generate32BitInteger } from "../helpers";
  import { showLearnHaast } from "../stores";

  let report_id = generate32BitInteger();
  let dataReady = false;

  let cardData = {
    hitsFound: {
      cardTitle: "Hits found",
      cardDescription: "(Last 30 Days)",
      value: "~",
      icon: "ic:baseline-notifications",
    },
    hitsFlagged: {
      cardTitle: "Hits flagged",
      cardDescription: "(Last 30 Days)",
      value: "~",
      icon: "ic:baseline-flag",
    },
    hitsResolved: {
      cardTitle: "Hits resolved",
      cardDescription: "(Last 30 Days)",
      value: "~",
      icon: "ic:baseline-check-box",
    },
    streamsLive: {
      cardTitle: '"Live" Streams',
      cardDescription: " ",
      value: "~",
      icon: "ic:baseline-remove-red-eye",
    },
    hitsOpen: {
      cardTitle: "Open hits",
      cardDescription: " ",
      value: "~",
      icon: "ic:outline-format-list-numbered",
    },
  };

  async function getDataCount(hitType = "flagged", dataType = "hit") {
    const hits = await fetchGet(`/${dataType}/${hitType}`);
    return hits.count;
  }

  onMount(async () => {
    // Fetching hit counts
    const hitsStatsQueryParams = new URLSearchParams({
      inReport: "All",
    }).toString();
    fetchGet(`/hit/count?${hitsStatsQueryParams}`).then((data) => {
      if (data.success) {
        cardData.hitsFound.value = data.total;
        cardData.hitsFlagged.value = data.flagged;
        cardData.hitsResolved.value = data.resolved;
        cardData.hitsOpen.value = data.open;
      }
    });
    // Fetching live streams count
    getDataCount("live", "report").then((count) => {
      cardData.streamsLive.value = count;
    });

    dataReady = await new Promise(function (resolve) {
      fetchGet("/pool/refresh")
        .then((response) => {
          if (response.success) {
            resolve(true);
          } else {
            console.error("Reponse failed from /pool/refresh:", error);
            resolve(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching data /pool/refresh:", error);
          resolve(false);
        });
    });
  });
</script>

<svelte:head>
  <title>Home - Haast</title>
</svelte:head>

<main class="mx-auto max-w-screen-xl space-y-4">
  <h1 class="mb-4 text-3xl font-semibold">Dashboard</h1>

  {#if $showLearnHaast}
    <article class="space-y-2 rounded border p-4 shadow-lg" out:slide|local>
      <div class="flex w-full items-center justify-between">
        <h1 class="text-lg font-bold">Get Started</h1>
      </div>

      <div class="flex flex-col justify-between gap-2 lg:flex-row">
        <div class="flex items-center gap-2">
          <a
            href="mailto:support@haast.io"
            target="_blank"
            class="btn btn-primary btn-sm w-56 lg:h-auto lg:w-28 lg:py-2 xl:w-36"
          >
            Get help
          </a>
          <p class="text-sm">
            Get help by emailing <a href="mailto:support@haast.io">
              support@haast.io
            </a>
          </p>
        </div>

        <div class="lg:divider" />

        <div class="flex items-center gap-2">
          <a
            href="#/stream/new/{report_id}"
            class="btn btn-primary btn-sm w-56 lg:h-auto lg:w-28 lg:py-2 xl:w-36"
          >
            Create stream
          </a>
          <p class="text-sm">Create a stream to monitor an issue</p>
        </div>

        <div class="lg:divider" />

        <div class="flex items-center gap-2">
          <a
            href="#/search"
            class="btn btn-primary btn-sm w-56 lg:h-auto lg:w-28 lg:py-2 xl:w-36"
          >
            Run search
          </a>
          <p class="text-sm">Run a search across all channels</p>
        </div>
      </div>
    </article>
  {/if}

  <article class="flex flex-wrap gap-4">
    {#each Object.keys(cardData) as card}
      <HomeCard
        cardTitle={cardData[card].cardTitle}
        cardDescription={cardData[card].cardDescription}
        value={cardData[card].value}
        icon={cardData[card].icon}
      />
    {/each}
  </article>

  <article class="space-y-2 rounded border p-4 shadow-lg">
    <div class="flex w-full items-center">
      <h1 class="text-lg font-bold">Stream Status</h1>
    </div>
    <ReportStatus />
  </article>

  <div class="flex gap-4 max-lg:flex-col">
    <TeamOverview />

    <article class="space-y-2 rounded border p-4 shadow-lg">
      <div class="flex w-full items-center">
        <h1 class="text-lg font-bold">Assets Checked</h1>
      </div>
      <TimeSaved bind:dataReady />
    </article>
  </div>

  <article class="space-y-2 rounded border p-4 shadow-lg">
    <div class="mb-2 flex w-full items-center">
      <h1 class="text-lg font-bold">Channel Assets Count</h1>
    </div>
    <IndexStatistic bind:dataReady />
  </article>
</main>
