<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { link } from "svelte-spa-router";
  import FolderCard from "../components/FolderCard.svelte";
  import FolderList from "../components/FolderList.svelte";
  import LabelFilter from "../components/Labels/LabelFilter.svelte";
  import Pagination from "../components/table/Pagination.svelte";
  import { fetchGet, generate32BitInteger } from "../helpers";
  import { role, org_labels, org_id, org_name } from "../stores.js";
  import { getTextConstant } from "../lib/utils/ConstantsUtils";

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");

  let reports = [];
  let report_id = generate32BitInteger();
  let activeTabLabel = "Uploads";
  let statuses = [
    { label: "Uploads", value: 1, icon: "cloud-upload" },
    { label: "Archived", value: 2, icon: "archive" },
  ];
  let loading = true;
  let selectedLabels = [];
  let searchString = null;
  let unlabeledOnly = false;
  let itemsPerPage = 10;

  let sortColumn = "created_date";
  let sortDirection = 1;

  const filterReports = (reports) => {
    let filteredResult = reports;

    if (unlabeledOnly) {
      filteredResult = reports.filter((r) => {
        return r.label_ids.length === 1 && r.label_ids[0] === null;
      });
    } else if (selectedLabels.length > 0) {
      filteredResult = reports.filter((report) => {
        return selectedLabels.every((label) =>
          report.label_ids.includes(label.id),
        );
      });
    }

    const checkMatch = (str, pattern) =>
      str && str.toLowerCase().indexOf(pattern.toLowerCase()) > -1;

    if (searchString) {
      filteredResult = filteredResult.filter(
        (report) =>
          checkMatch(report.name, searchString) ||
          checkMatch(report.description, searchString),
      );
    }

    return filteredResult;
  };

  function updateReportCount() {
    const fReports = filterReports(reports);

    statuses[0]["count"] = fReports.filter((x) => x.status == "Uploads").length;
    statuses[1]["count"] = fReports.filter(
      (x) => x.status == "Archived",
    ).length;
  }

  async function addClonedReport(report_id) {
    if (report_id) {
      const response = await fetchGet(`/report`);

      if (response.success) {
        reports = response.reports;
        updateReportCount();
      }
    }
  }

  onMount(async () => {
    // Fetch all reports from the API
    const response = await fetchGet("/report/asset");

    // If the API request is successful, set the `reports` variable and update the report count
    if (response.success) {
      reports = response.reports.filter((report) => report.id > 1);
      updateReportCount();
    }

    // Set `loading` to false to indicate that the component has finished loading
    loading = false;

    // Determine which tab should be active based on the status of the reports
    if (reports.length > 0) {
      const liveReport = reports.find((report) => report.status == "Uploads");
      const archivedReport = reports.find(
        (report) => report.status == "Archived",
      );

      if (liveReport) activeTabLabel = "Uploads";
      else if (archivedReport) activeTabLabel = "Archived";
      else activeTabLabel = "Uploads";
      //if there are no reports, defaulted to uploads
    }

    groupAssetFolders();
  });

  $: if (selectedLabels || unlabeledOnly) updateReportCount();

  const groupAssetFolders = () => {
    const fReports = filterReports(reports).filter(
      (e) => e.status === activeTabLabel,
    );

    switch (groupBy) {
      case "Individual":
        groupedData = [
          {
            label: { name: "Individual", id: Math.random() },
            reports: fReports,
            currentPage: 1,
          },
        ];
        break;
      case "Labels":
        groupedData = [
          {
            label: { name: "Unlabeled", id: Math.random() },
            reports: fReports.filter((r) => {
              return r.label_ids.length === 1 && r.label_ids[0] === null;
            }),
            currentPage: 1,
          },
          ...$org_labels.map((label) => {
            return {
              label,
              reports: fReports.filter((r) => r.label_ids.includes(label.id)),
              currentPage: 1,
            };
          }),
        ];
        break;
    }

    groupedData = groupedData.filter((gd) => gd.reports.length);
  };

  function searchByString() {
    updateReportCount();
    groupAssetFolders();
  }

  $: if ([searchString]) searchByString();

  let groupBy = "Individual"; // Individual or Labels
  let view = "List"; // grid or list

  let groupedData = [];

  function sortTable(column, direction) {
    sortDirection = direction;
    sortColumn = column;

    groupedData = groupedData.map((group) => ({
      ...group,
      reports: sortReports(group.reports),
    }));

    const elem = document.activeElement;
    if (elem) {
      elem.blur();
    }
  }

  function sortReports(reports) {
    if (sortColumn == "created_date") {
      return [...reports].sort(
        (a, b) =>
          (new Date(a.created_date) - new Date(b.created_date)) * sortDirection,
      );
    }

    if (sortColumn == "last_refreshed") {
      return [...reports].sort(
        (a, b) =>
          (new Date(a.last_refreshed) - new Date(b.last_refreshed)) *
          sortDirection,
      );
    }

    if (sortColumn == "name") {
      if (sortDirection > 0) {
        return [...reports].sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return [...reports].sort((a, b) => b.name.localeCompare(a.name));
      }
    }
  }

  $: if ([groupBy, activeTabLabel, selectedLabels, unlabeledOnly, reports])
    groupAssetFolders();
</script>

<svelte:head>
  <title>{textConstants["AssetReview"]} - Haast</title>
</svelte:head>

<header class="bg-base-content/[5%] -mx-12 -my-4 mb-4 px-12 py-4 pb-0">
  <!-- extra contianer required for max-w-screen-xl but full background -->
  <div class="mx-auto flex max-w-screen-xl flex-col gap-8">
    <div class="breadcrumbs text-sm">
      <ul>
        <li>
          <a href="/reviews" use:link>{textConstants["AssetReview"]}</a>
        </li>
        <li>{activeTabLabel}</li>
      </ul>
    </div>

    <div class="flex items-center gap-4">
      <h1 class="text-3xl font-semibold">{textConstants["AssetReview"]}</h1>

      {#if $role != "assignee"}
        <a
          href="/review/edit/{report_id}"
          class="btn btn-primary btn-sm ml-auto"
          use:link
        >
          {textConstants["CreateFolder"]}
        </a>
      {/if}
    </div>

    <div class="tabs relative">
      {#each statuses as item}
        <button
          class="tab whitespace-nowrap text-base font-medium"
          class:text-primary={activeTabLabel === item.label}
          on:click={() => (activeTabLabel = item.label)}
          disabled={loading}
        >
          <Icon icon="iconoir:{item.icon}" class="mr-1" />
          {item.label} - {item.count > 0 ? item.count : 0}
        </button>
      {/each}
      <div
        class="slider bg-primary absolute bottom-0 left-0 h-0.5 transition-all"
        style:width="{100 / statuses.length}%"
        style="translate: {statuses.findIndex(
          (i) => i.label === activeTabLabel,
        ) * 100}%"
      />
    </div>
  </div>
</header>

<div class="mx-auto flex max-w-screen-xl flex-col">
  <div class="flex flex-wrap items-center gap-2 py-4">
    <div class="dropdown">
      <div
        tabindex="0"
        role="button"
        class="btn btn-outline btn-primary btn-sm rounded-full"
      >
        View: {view}
      </div>
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <ul
        tabindex="0"
        class="menu dropdown-content menu-sm bg-base-100 z-10 mt-1 w-full min-w-fit rounded border p-1"
      >
        {#each ["List", "Grid"] as item}
          <li>
            <button on:click={() => (view = item)}>
              <input
                type="radio"
                class="radio-primary radio radio-sm"
                checked={view === item}
              />
              {item}
            </button>
          </li>
        {/each}
      </ul>
    </div>

    <div class="dropdown">
      <div
        tabindex="0"
        role="button"
        class="btn btn-outline btn-primary btn-sm rounded-full"
      >
        Group: {groupBy}
      </div>
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <ul
        tabindex="0"
        class="menu dropdown-content menu-sm bg-base-100 z-10 mt-1 w-full min-w-fit rounded border p-1"
      >
        {#each ["Individual", "Labels"] as item}
          <li>
            <button on:click={() => (groupBy = item)}>
              <input
                type="radio"
                class="radio-primary radio radio-sm"
                checked={groupBy === item}
              />
              {item}
            </button>
          </li>
        {/each}
      </ul>
    </div>

    {#if $org_labels.length > 0}
      <LabelFilter labels={$org_labels} bind:selectedLabels bind:unlabeledOnly />
    {/if}

    <div class="flex flex-grow justify-end">
      <div class="relative min-w-[320px]">
        <input
          class="input input-sm input-bordered w-full pr-16"
          bind:value={searchString}
          placeholder={textConstants["SearchAssetFolders"]}
        />
        <button
          class="btn btn-sm btn-ghost absolute right-0"
          on:click={() => searchByString()}
        >
          <Icon icon="iconoir:search" />
        </button>
      </div>
    </div>
  </div>

  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  {#each groupedData as gd, idx (gd.label.id)}
    <div
      class="collapse-arrow bg-base-200 collapse mt-4 {view === 'List'
        ? 'overflow-auto'
        : 'overflow-auto sm:overflow-visible'}"
    >
      <input type="radio" name="reportsAccordion" checked={idx === 0} />
      <div class="collapse-title text-xl font-medium">
        {gd.label.name}
      </div>
      <div class="collapse-content">
        {#if view === "List"}
          <div class="bg-base-100 overflow-x-auto rounded border">
            <table class="table">
              <thead class="bg-base-200">
                <tr>
                  <th class="py-0">
                    <div class="dropdown w-full">
                      <button
                        tabindex="0"
                        for=""
                        class="btn-ghost btn btn-sm flex-grow flex-nowrap gap-2 rounded-sm p-0 text-xs font-bold"
                      >
                        {textConstants["Folder"]} Name <Icon
                          icon="iconoir:sort"
                        />
                      </button>
                      <ul
                        tabindex="0"
                        class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-48 overflow-auto rounded border p-1 font-normal shadow"
                      >
                        <li>
                          <button on:click={() => sortTable("name", 1)}>
                            Sort A-Z
                          </button>
                        </li>
                        <li>
                          <button on:click={() => sortTable("name", -1)}>
                            Sort Z-A
                          </button>
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th>Uploaded</th>
                  <th>In Review</th>
                  <th>Approved</th>
                  <th>Labels</th>
                  <th class="py-0">
                    <div class="dropdown w-full">
                      <button
                        tabindex="0"
                        for=""
                        class="btn-ghost btn btn-sm flex-grow flex-nowrap gap-2 rounded-sm p-0 text-xs font-bold"
                      >
                        Date Created <Icon icon="iconoir:sort" />
                      </button>
                      <ul
                        tabindex="0"
                        class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-48 overflow-auto rounded border p-1 font-normal shadow"
                      >
                        <li>
                          <button on:click={() => sortTable("created_date", 1)}>
                            Sort Oldest to Latest
                          </button>
                        </li>
                        <li>
                          <button
                            on:click={() => sortTable("created_date", -1)}
                          >
                            Sort Latest to Oldest
                          </button>
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th class="py-0">
                    <div class="dropdown w-full">
                      <button
                        tabindex="0"
                        for=""
                        class="btn-ghost btn btn-sm flex-grow flex-nowrap gap-2 rounded-sm p-0 text-xs font-bold"
                      >
                        Last Updated <Icon icon="iconoir:sort" />
                      </button>
                      <ul
                        tabindex="0"
                        class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-48 overflow-auto rounded border p-1 font-normal shadow"
                      >
                        <li>
                          <button
                            on:click={() => sortTable("last_refreshed", 1)}
                          >
                            Sort Oldest to Latest
                          </button>
                        </li>
                        <li>
                          <button
                            on:click={() => sortTable("last_refreshed", -1)}
                          >
                            Sort Latest to Oldest
                          </button>
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {#each gd.reports.slice((gd.currentPage - 1) * itemsPerPage, gd.currentPage * itemsPerPage) as report (report.id)}
                  {@const lbls = $org_labels.filter((l) =>
                    report.label_ids?.includes(l.id),
                  )}

                  <FolderList
                    {report}
                    {activeTabLabel}
                    on:reportDeleted={updateReportCount}
                    labels={lbls}
                    on:labelAdded={(e) => {
                      const label = e.detail;
                      report.label_ids.push(label.id);
                    }}
                    on:labelRemoved={(e) => {
                      const label = e.detail;
                      report.label_ids = report.label_ids.filter(
                        (labelId) => labelId !== label.id,
                      );
                    }}
                    on:reportCloned={(e) => addClonedReport(e.detail)}
                  />
                {/each}
              </tbody>
              <tr>
                <td colspan="8">
                  <div class="flex w-full items-center justify-center px-4">
                    <Pagination
                      bind:currentPage={gd.currentPage}
                      bind:itemsPerPage
                      totalHits={filterReports(gd.reports).filter(
                        (e) => e.status === activeTabLabel,
                      ).length}
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        {:else if view === "Grid"}
          <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
            {#each gd.reports.slice((gd.currentPage - 1) * itemsPerPage, gd.currentPage * itemsPerPage) as report (report.id)}
              {@const lbls = $org_labels.filter((l) =>
                report.label_ids.includes(l.id),
              )}
              <FolderCard
                {report}
                {activeTabLabel}
                on:reportDeleted={updateReportCount}
                labels={lbls}
                on:labelAdded={(e) => {
                  const label = e.detail;
                  report.label_ids.push(label.id);
                }}
                on:labelRemoved={(e) => {
                  const label = e.detail;
                  report.label_ids = report.label_ids.filter(
                    (labelId) => labelId !== label.id,
                  );
                }}
                on:reportCloned={(e) => addClonedReport(e.detail)}
              />
            {/each}
          </div>

          <Pagination
            bind:currentPage={gd.currentPage}
            bind:itemsPerPage
            totalHits={filterReports(gd.reports).filter(
              (e) => e.status === activeTabLabel,
            ).length}
          />
        {/if}
      </div>
    </div>
  {/each}

  {#if groupedData.length === 0 && !loading}
    <p
      class="text-base-content/50 m-auto mt-4 rounded border-4 border-dotted p-4 text-center text-xl"
    >
      There are no {textConstants["Folder"].toLowerCase()}s.
    </p>
  {/if}
</div>
