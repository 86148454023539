<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { invalidInputFocus } from "../../lib/Focus";

  export let rule;
  export let excludedMatches = false;
  export let isViewing = false;

  let abandonedInputs = new Map();
  let relIdxToAbsIdx = new Map();

  function addExample() {
    rule.examples = [...rule.examples, { text: "", score: 1 }];
    rule = rule;
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  function removeExample(exIndex) {
    const absIdx = relIdxToAbsIdx.get(exIndex);

    // 0 is considered "falsy"
    if (absIdx !== undefined) {
      if (absIdx === 0) rule.examples.shift();
      else rule.examples.splice(absIdx, 1);
      rule.examples = [...rule.examples];
    }
  }

  function handleAbandonedFocus(id, isAbandoned) {
    if (isAbandoned) abandonedInputs.set(id, isAbandoned);
    else abandonedInputs.delete(id);

    abandonedInputs = abandonedInputs;
  }

  function handleUpdateRelativeIndexes() {
    relIdxToAbsIdx = new Map();

    if (rule) {
      let relativeIdx = 0;
      rule.examples.forEach((ex, idx) => {
        if (
          (excludedMatches && ex?.score === -1) ||
          (!excludedMatches && ex?.score === 1)
        ) {
          relIdxToAbsIdx.set(relativeIdx, idx);
          relativeIdx += 1;
        }
      });
    }

    relIdxToAbsIdx = relIdxToAbsIdx;
  }

  /**
   * onMount method to initialise the form with Rule data if present.
   */
  onMount(() => {
    if (rule && rule.query && rule.query.examples) {
      rule.examples = rule.query.examples;
      rule = rule;
    }
    handleUpdateRelativeIndexes();
  });

  $: handleUpdateRelativeIndexes(), [rule];
</script>

{#each rule.examples.filter( (ex) => (excludedMatches ? ex?.score === -1 : ex?.score === 1), ) as ex, exIndex}
  {#if (excludedMatches && ex?.score === -1) || (!excludedMatches && ex?.score === 1)}
    <div class="flex items-center gap-2">
      <span class="min-w-8 pl-1 text-sm">{exIndex + 1}</span>
      <textarea
        placeholder="Example text"
        class="textarea textarea-bordered grow"
        rows="1"
        id="similarity-rule-{excludedMatches
          ? 'negative'
          : 'positive'}-example-{exIndex}"
        bind:value={ex.text}
        on:keydown={handleKeyDown}
        use:invalidInputFocus={{
          id: `similarity-rule-${excludedMatches ? "negative" : "positive"}-example-${exIndex}`,
          onChange: handleAbandonedFocus,
          validateEmpty: true,
        }}
      ></textarea>
      <input
        type="number"
        placeholder="Score"
        class="input input-bordered hidden"
        min="1"
        max="5"
        bind:value={ex.score}
      />
      <button
        class="!hover:btn-error btn btn-ghost border-base-content/20"
        disabled={isViewing}
        on:click={() => {
          removeExample(exIndex);
        }}
      >
        <Icon icon="iconoir:trash" />
      </button>
    </div>
    {#if abandonedInputs.get(`similarity-rule-${excludedMatches ? "negative" : "positive"}-example-${exIndex}`) || false}
      <p class="text-error ml-11 first-line:text-xs" in:slide out:slide>
        *Cannot have an empty example.
      </p>
    {/if}
  {/if}
{/each}
<button
  class="btn btn-outline btn-primary btn-sm mr-auto"
  disabled={isViewing}
  on:click={() => addExample()}
>
  <Icon icon="iconoir:plus" />
  {excludedMatches ? "Add Excluded Phrase" : "Add Phrase"}
</button>
