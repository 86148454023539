<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchPatch, fetchPost } from "../../../../../helpers";
  import { getCurrentStepIndex } from "../../../../../lib/utils/AssetReviewUtils";
  import { org_users } from "../../../../../stores";
  import LogoLoader from "../../../../LogoLoader.svelte";
  import Modal from "../../../../Modals/Modal.svelte";

  const dispatch = createEventDispatcher();

  export let reportId;
  export let activeAsset;
  export let activeAssetId;
  export let workflow;
  export let modalType = "approve"; // "approve", "send", "submit"
  let disableEditing = false;

  let status = modalType === "send" ? "Approved" : "Pending";
  let sendingForApproval = false;
  let assignees = [];
  let comment = "";
  let approvers = [];

  approvers = getApprovers(
    workflow,
    activeAsset,
    !["send"].includes(modalType),
  );

  function getUserInfo(id) {
    const user = $org_users.find((user) => user.id === id);
    if (!user) return { first_name: "", last_name: "" };
    return { first_name: user.first_name, last_name: user.last_name ?? "" };
  }

  const deleteAssignee = async (id) => {
    assignees = assignees.filter((a) => a.assigned_id !== id);
  };
  const updateAssignees = async (id) => {
    assignees = [...assignees, { id: id, assigned_id: id }];
  };

  function getApprovers(workflow, activeAsset, next = true) {
    if (!workflow || !activeAsset) return [];
    const currentStepIndex = workflow.workflow_steps.findIndex(
      (step) => step.id === activeAsset.asset.workflow_step.id,
    );
    const step = next
      ? workflow.workflow_steps[currentStepIndex + 1]
      : workflow.workflow_steps[currentStepIndex];
    if (!step) return [];
    const approverUserIds = step.users
      .filter((user) => user.permission === "approve")
      .map((user) => user.user_id);
    const approverTeamIds = step.teams
      .filter((team) => team.permission === "approve")
      .map((team) => team.team_id);
    let approvers = $org_users.filter(
      (user) =>
        approverUserIds.includes(user.id) ||
        user.teams.some((teamId) => approverTeamIds.includes(teamId)),
    );
    return approvers;
  }

  async function handleSendApproval() {
    sendingForApproval = true;

    try {
      const currStepIdx = getCurrentStepIndex(workflow, activeAsset);
      let step = workflow.workflow_steps[currStepIdx].id;

      if (
        modalType !== "send" &&
        currStepIdx < workflow.workflow_steps.length - 1
      )
        step = workflow.workflow_steps[currStepIdx + 1].id;

      const res = await fetchPatch(`/asset/${activeAssetId}`, {
        status: "Approved",
        step,
        operation: "approval",
        suppressEmailNotification: true,
      });

      if (!res.success) {
        throw new Error(
          res?.message ??
            `Something went wrong updating the status for Asset(${activeAssetId})`,
        );
      }

      activeAsset.asset = res?.asset;
      activeAsset = activeAsset;

      toast.success(
        res?.message ??
          `Successfully updated the status of Asset(${activeAssetId}) to Status(${status})`,
      );

      const emailResponse = await fetchPost(
        `/email/request-changes/${activeAssetId}`,
        {
          assignees,
          comment,
          report_id: reportId,
          email_type:
            modalType === "send"
              ? "send_for_approval"
              : "approve_to_next_stage",
        },
      );

      if (!emailResponse.success)
        throw new Error(
          "Something went wrong sending asset status change email.",
        );

      document.querySelector(`#approval-dialog-${modalType}`)?.close();
      dispatch("updateComments");
      toast.success(
        "Successfully sent asset status change notification email.",
      );
    } catch (e) {
      console.error(e);
      toast.error(e);
    } finally {
      sendingForApproval = false;
    }
  }

  // for showing current and next stage approvers
  const nextWorkflowStepId = workflow.workflow_steps.find(
    (step) => step.position === activeAsset.asset.workflow_step.position + 1,
  )?.id;

  let currentApprovers =
    activeAsset.approvers[activeAsset.asset.workflow_step.id]?.filter(
      (a) => a.user_id,
    ) ?? [];
  let nextStepApprovers =
    activeAsset.approvers[nextWorkflowStepId]?.filter((a) => a.user_id) ?? [];

  // let ApprovalStatuses = Object.keys(ApprovalStatus).map((key) => ({
  //   label: ApprovalStatus[key],
  //   color: ApprovalStatusColor[key].Color,
  //   bg: ApprovalStatusColor[key].Background,
  // }));

  async function prepopulateSelectedApprovers() {
    if (modalType === "send")
      currentApprovers.forEach((ca) => {
        if (ca.user_id) updateAssignees(ca.user_id);
      });
    else
      nextStepApprovers.forEach((nsa) => {
        if (nsa.user_id) updateAssignees(nsa.user_id);
      });
  }

  onMount(prepopulateSelectedApprovers);
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->

<Modal modalId="approval-dialog-{modalType}">
  <h3 class="text-center font-semibold">
    {modalType === "approve"
      ? "Approve"
      : modalType === "send"
        ? "Notify users and send for approval"
        : "Submit for approval"}
  </h3>

  <div class="my-2">
    <span class="text-sm"> Select Users </span>
    <div class="flex w-24 items-center gap-0.5 pt-1">
      {#if assignees?.length > 0}
        <div class="avatar-group -space-x-2 overflow-visible">
          {#each assignees.slice(0, assignees.length === 3 ? 3 : 2) as assignee}
            <div
              class="tooltip"
              data-tip="{getUserInfo(assignee.assigned_id)
                .first_name} {getUserInfo(assignee.assigned_id).last_name}"
            >
              <button
                class="avatar placeholder group border-2"
                class:btn-disabled={disableEditing}
                on:click={() => deleteAssignee(assignee.assigned_id)}
              >
                <div
                  class="bg-neutral text-neutral-content w-6 rounded-full"
                  class:group-hover:bg-error={!disableEditing}
                  class:group-hover:text-error-content={!disableEditing}
                >
                  <span
                    class="uppercase"
                    class:group-hover:hidden={!disableEditing}
                  >
                    {getUserInfo(assignee.assigned_id)
                      .first_name[0]}{getUserInfo(assignee.assigned_id)
                      .last_name[0] ?? ""}
                  </span>
                  <Icon
                    icon="iconoir:cancel"
                    class="hidden text-lg {!disableEditing
                      ? 'group-hover:inline'
                      : ''}"
                  />
                </div>
              </button>
            </div>
          {/each}
          {#if assignees?.length > 3}
            <div
              class="tooltip"
              data-tip={assignees
                .slice(2, assignees.length)
                .toString()
                .replaceAll(",", ", ")}
            >
              <div class="avatar placeholder border-2">
                <div class="bg-neutral text-neutral-content w-6 rounded-full">
                  <span>+{assignees.length - 2}</span>
                </div>
              </div>
            </div>
          {/if}
        </div>
      {/if}
      {#if !disableEditing}
        <div class="dropdown">
          <label
            tabindex="0"
            class="btn btn-primary btn-xs whitespace-nowrap"
            class:btn-disabled={disableEditing}
            class:btn-circle={assignees?.length}
            for=""
            on:click|stopPropagation
          >
            {assignees?.length ? "+" : "Add Approver"}
          </label>
          <ul
            tabindex="0"
            class="menu dropdown-content menu-xs bg-base-100 z-[1] m-1 max-h-28 w-max flex-nowrap gap-1 overflow-auto rounded border p-1 shadow"
          >
            {#each approvers as user}
              <li>
                <button
                  class:active={assignees?.find((a) => a.id === user.id)}
                  on:click|stopPropagation={() => {
                    if (assignees?.find((a) => a.assigned_id === user.id))
                      deleteAssignee(user.id);
                    else updateAssignees(user.id);
                  }}
                >
                  {user.first_name}
                  {user.last_name}
                </button>
              </li>
            {/each}
          </ul>
        </div>
      {/if}
    </div>
  </div>

  <!-- <table class="mt-4 table">
      {#if currentApprovers.length != 0}
        <thead>
          <tr>
            <th class="w-full">Current Step Approvers</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {#each currentApprovers as c}
            {@const status = ApprovalStatuses.find((s) => s.label === c.status)}
            <tr>
              <td>{c.name}</td>
              <td>
                <button
                  class="btn btn-xs whitespace-nowrap"
                  style="color: {status.color}; background: {status.bg}"
                >
                  {status.label}
                </button>
              </td>
            </tr>
          {/each}
        </tbody>
      {/if}
      {#if nextStepApprovers.lenght != 0}
        <thead>
          <tr>
            <th class="w-full">Next Step Approvers</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {#each nextStepApprovers as n}
            {@const status = ApprovalStatuses.find((s) => s.label === n.status)}
            <tr>
              <td>{n.name}</td>
              <td>
                <button
                  class="btn btn-xs whitespace-nowrap"
                  style="color: {status.color}; background: {status.bg}"
                >
                  {status.label}
                </button>
              </td>
            </tr>
          {/each}
        </tbody>
      {/if}
    </table> -->

  <div class="my-2">
    <span class="text-sm"> Add instructions </span>
    <div class="flex flex-col gap-2 pt-2" in:slide>
      <textarea
        class="textarea textarea-bordered w-full"
        placeholder="Comment"
        bind:value={comment}
      ></textarea>
    </div>
  </div>

  <div class="flex">
    <div
      class="tooltip-left ml-auto before:!max-w-48"
      class:tooltip={!assignees.length}
      data-tip="Please select assignees."
    >
      <button
        class="btn {sendingForApproval ? 'btn-disabled' : 'btn-primary'} btn-sm"
        disabled={!assignees.length || sendingForApproval}
        on:click={async () => {
          await handleSendApproval();
        }}
      >
        {#if sendingForApproval}
          <LogoLoader size="1.25rem" />
        {/if}
        {modalType === "approve"
          ? "Approve and send to the next stage"
          : modalType === "send"
            ? "Send for Approval"
            : "Submit for Approval"}
      </button>
    </div>
  </div>
</Modal>
