<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { fetchDelete, fetchGet, fetchPost } from "../../helpers";
  import { currentUser } from "../../stores";

  export let report_id;
  export let canSubscribe = false;

  let isSubscribed = false;
  let loaded = false;

  let associatedUsers = [];

  onMount(async () => {
    try {
      const response = await fetchGet(`/report/${report_id}/users`);

      if (response.success) associatedUsers = response.users;
    } catch (error) {
      console.error(error);
    }
  });

  async function setupSubscriptionStatus() {
    try {
      const response = await fetchGet(`/report/subscribe/${report_id}`);
      isSubscribed = response.is_subscribed;
    } catch (error) {
      console.warn("Error while getting subscription status", error);
    }
  }

  async function subscribeToRule() {
    isSubscribed = true;

    if (!associatedUsers.find((user) => user.id === $currentUser.id)) {
      const currentUserId = $currentUser.id;
      const response = await fetchPost(
        `/user/${currentUserId}/report/${report_id}`,
        {
          report_id,
          user_id: $currentUser.id,
          permission: "view",
        },
      );
      if (!response.success) {
        warningMessage = response.message;
        return;
      }
    }

    try {
      await fetchPost(`/report/subscribe/${report_id}`, {
        user_id: $currentUser.id,
      });
    } catch (error) {
      console.warn("Error while subscribing to report", error);
      isSubscribed = false;
    }
  }
  ///TODO: does this even work?
  async function unsubscribeFromRule() {
    isSubscribed = false;

    try {
      await fetchDelete(`/report/subscribe/${report_id}`, {
        user_id: $currentUser.id,
      });
    } catch (error) {
      console.warn("Error while unsubscribing from report", error);
      isSubscribed = true;
    }
  }

  $: if (canSubscribe === true) {
    setupSubscriptionStatus();
  }

  $: if (report_id && !loaded) {
    setupSubscriptionStatus();
    loaded = true;
  }
</script>

{#if canSubscribe}
  {#if isSubscribed}
    <button class="btn btn-sm" on:click={() => unsubscribeFromRule()}>
      <Icon icon="iconoir:bell-off" /> Unsubscribe
    </button>
  {:else}
    <button class="btn btn-primary btn-sm" on:click={() => subscribeToRule()}>
      <Icon icon="iconoir:bell" /> Subscribe
    </button>
  {/if}
{:else}
  <div
    class="tooltip tooltip-left"
    data-tip="You can only subscribe to live reports"
  >
    <button
      class="btn btn-primary btn-sm"
      on:click={() => subscribeToRule()}
      disabled
    >
      <Icon icon="iconoir:bell" /> Subscribe
    </button>
  </div>
{/if}
