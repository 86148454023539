<script>
  import { onDestroy, onMount } from "svelte";
  import { maximised } from "../../stores";

  export let tableRootPage = ""; // myissues, reports, streamReview, search

  onMount(() => ($maximised = false));
  onDestroy(() => ($maximised = true));
</script>

<div
  class={tableRootPage !== "reports"
    ? "mx-auto mt-2 flex max-w-[1706px] gap-2"
    : "w-full"}
  class:-mb-16={["myissues", "reports", "search"].includes(tableRootPage)}
>
  <aside
    class="flex max-h-full w-52 shrink-0 flex-col overflow-y-auto rounded border"
    class:hidden={tableRootPage === "reports"}
  >
    <h2 class="flex items-center justify-between gap-2 p-2 font-semibold">
      Filters
    </h2>
    <div class="divider my-0 h-0" />

    {#each { length: 4 } as _}
      <div class="mb-4 flex flex-col gap-2 p-2">
        <div class="skeleton h-6 w-full pb-4"></div>
        <div class="skeleton h-4 w-2/3"></div>
        <div class="skeleton h-4 w-2/3"></div>
      </div>
    {/each}
  </aside>

  <div
    class="h-full min-h-[300px] w-full overflow-auto rounded border"
    style:max-height={[
      "myissues",
      "reports",
      "streamReview",
      "search",
    ].includes(tableRootPage)
      ? `calc(100vh - ${tableRootPage === "myissues" ? 261 : 268}px)`
      : "auto"}
  >
    <table class="table-zebra table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Location</th>
          <th class="w-full">Hit</th>
          <th>Rules</th>
        </tr>
      </thead>
      <tbody>
        {#each { length: 10 } as _}
          <tr>
            <td>
              <div class="flex flex-col gap-2">
                <div class="skeleton h-4 w-24" />
                <div class="skeleton h-4 w-20" />
              </div>
            </td>
            <td class="max-w-[200px]">
              <div class="flex flex-col gap-2">
                <div class="skeleton h-4 w-48" />
                <div class="skeleton h-4 w-40" />
              </div>
            </td>
            <td class="max-w-[max(400px,50vw)]">
              <div class="flex flex-col gap-2">
                <div class="skeleton h-4 w-full" />
                <div class="skeleton h-4 w-full" />
                <div class="skeleton h-4 w-full" />
              </div>
            </td>
            <td class="min-w-[150px]">
              <div class="skeleton h-4 w-24" />
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>
