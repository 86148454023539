import { fetchGet } from "../../helpers";

/**
 *
 * @param {*} workflow
 */
export function isLegacyWorkflowCheck(workflow) {
  if (
    !workflow ||
    !workflow.workflow_steps ||
    workflow.workflow_steps.length === 0
  ) {
    return;
  }

  const defaultFinalWorkflowStepDetails = {
    description: "Final approval step",
    name: "Approved",
  };
  const lastStep = workflow.workflow_steps.sort(
    (a, b) => a.position - b.position,
  )[workflow.workflow_steps.length - 1];

  if (
    lastStep.default &&
    lastStep.description === defaultFinalWorkflowStepDetails.description &&
    lastStep.name === defaultFinalWorkflowStepDetails.name
  ) {
    return false;
  }

  return true;
}

export async function fetchAllReportAccessorsAndApprovers(assetId) {
  const queryParams = new URLSearchParams({
    field: "permissions",
  }).toString();
  const response = await fetchGet(`/asset/${assetId}?${queryParams}`);

  if (!response.success) {
    throw new Error(
      response?.message ??
        `Something went wrong retrieving all users and teams with access for Asset(${assetId})`,
    );
  }

  return response.data;
}

export function isDefaultWorkflow(workflow) {
  const defaultWorkflowName = "Default Approval Workflow";
  const defaultWorkflowDescription =
    "This is the default Haast generated approval workflow for this organisation, use this workflow unless directed otherwise.";

  if (
    workflow.name === defaultWorkflowName &&
    workflow.description === defaultWorkflowDescription
  ) {
    return true;
  }

  return false;
}
