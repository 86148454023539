<script>
  import { onDestroy, onMount } from "svelte";
  import { persisted } from "svelte-persisted-store";
  import UserAssigns from "../components/UserAssigns.svelte";
  import { fetchGet } from "../helpers";
  import { currentUser, maximised } from "../stores";

  let users = [];
  let loaded = false;

  const chosenUserId = persisted("chosenUserId", $currentUser.id);

  onMount(async () => {
    users = await fetchGet("/org-users");
    users = [
      ...users
        .filter((ou) => !ou?.user?.is_deleted)
        .sort((a, b) => a?.user?.first_name.localeCompare(b?.user?.first_name)),
    ];
    loaded = true;

    $maximised = false;
  });
  onDestroy(() => ($maximised = true));
</script>

<svelte:head>
  <title>All Issues - Haast</title>
</svelte:head>

<div class="mx-auto mb-4 flex max-w-[1706px] flex-wrap gap-4 2xl:pl-[13.5rem]">
  <h1 class="mt-4 w-full text-3xl font-semibold">All Issues</h1>

  {#each users.filter((ou) => !ou.user.is_deleted) as user}
    <button
      class="cursor-pointer rounded border p-4"
      class:border-primary={$chosenUserId === user.user.id}
      on:click={() => ($chosenUserId = user.user.id)}
    >
      <h3 class="text-lg font-semibold">
        {user.user.first_name}
        {user.user.last_name}
      </h3>

      <div class="divider -mx-2 my-1" />

      <div class="flex flex-col gap-2">
        <div class="grid grid-cols-2 items-center gap-2 text-sm">
          <span class="col-span-1"> Assigned Hits: </span>
          <button class="btn btn-ghost btn-sm col-span-1">
            {user.user.hits_count}
          </button>
        </div>

        <div class="grid grid-cols-2 text-sm">
          <span class="col-span-1"> Flagged hits: </span>
          <button class="btn btn-ghost btn-sm col-span-1">
            {user.user.flagged_hits_count}
          </button>
        </div>

        <div class="grid grid-cols-2 text-sm">
          <span class="col-span-1"> Open hits: </span>
          <button class="btn btn-ghost btn-sm col-span-1">
            {user.user.open_hits_count}
          </button>
        </div>
      </div>
    </button>
  {:else}
    {#if !loaded}
      {#each { length: 4 } as _}
        <div class="flex flex-col gap-4 w-52 p-4 rounded border">
          <div class="skeleton h-12 w-full" />
          <div class="divider my-0 -mx-2" />
          <div class="skeleton h-4 w-full" />
          <div class="skeleton h-4 w-full" />
          <div class="skeleton h-4 w-full" />
        </div>
      {/each}
    {/if}
  {/each}
</div>

{#if $chosenUserId > -1 && users.length > 0}
  {#key $chosenUserId}
    <UserAssigns userId={$chosenUserId} data={users} />
  {/key}
{/if}
