<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../../helpers";
  import { SharedRulePermissions } from "../../lib/interfaces/SharedRule.interface";
  import { UserRole } from "../../lib/interfaces/User.interface";
  import { capitalise } from "../../lib/utils/GenericUtils";
  import { currentUser, org_teams, org_users } from "../../stores";
  import LogoLoader from "../LogoLoader.svelte";
  import Select from "../Select.svelte";
  import Modal from "./Modal.svelte";

  const dispatch = createEventDispatcher();

  export let ruleSet = {};
  export let sharedWith = [];
  export let associatedUsers = new Map();
  export let associatedTeams = new Map();
  export let userChecklistAccessPermission = SharedRulePermissions.VIEW;

  let selectableEntities = [];
  let selectedPermission = null;
  let selectedEntity = null;
  let permissions = [
    {
      value: SharedRulePermissions.VIEW,
      label: capitalise(SharedRulePermissions.VIEW),
    },
    {
      value: SharedRulePermissions.EDIT,
      label: capitalise(SharedRulePermissions.EDIT),
      disabled: userChecklistAccessPermission === SharedRulePermissions.VIEW,
    },
  ];
  let usersToAdd = new Map();
  let teamsToAdd = new Map();
  let usersToDelete = new Map();
  let teamsToDelete = new Map();
  let updating = false;
  let existingUsers =
    associatedUsers?.size > 0 ? new Map(associatedUsers) : new Map();
  let existingTeams =
    associatedTeams?.size > 0 ? new Map(associatedTeams) : new Map();

  function resetDialog() {
    selectedPermission = null;
    selectedEntity = null;
    usersToDelete = new Map();
    teamsToDelete = new Map();
    usersToAdd = new Map();
    usersToDelete = new Map();
    associatedUsers = associatedUsers;
    associatedTeams = associatedTeams;
    existingUsers =
      associatedUsers?.size > 0 ? new Map(associatedUsers) : new Map();
    existingTeams =
      associatedTeams?.size > 0 ? new Map(associatedTeams) : new Map();
    sharedWith = sharedWith;
  }

  function closeDialog() {
    resetDialog();
    document.querySelector(`#share-checklist-${ruleSet.rule_set?.id}`)?.close();
  }

  function getSelectableEntities() {
    let output = [
      ...$org_users.flatMap((user) => {
        if (
          user.role !== UserRole.ASSIGNEE &&
          user.id !== $currentUser.id &&
          user.id !== ruleSet.rule_set?.owner &&
          !user.is_deleted &&
          !associatedUsers?.has(user.id)
        ) {
          return {
            value: `user:${user.id}`,
            label: user.first_name + " " + user.last_name,
            group: "Users",
          };
        }

        return [];
      }),
      ...$org_teams.flatMap((team) => {
        if (!associatedTeams.has(team.id)) {
          return {
            value: `team:${team.id}`,
            label: team.name,
            group: "Teams",
          };
        }

        return [];
      }),
    ];

    return output;
  }

  async function updateChecklistShares() {
    try {
      if (
        (!ruleSet.rule_set.id && ruleSet.rule_set.id <= 0) ||
        (associatedUsers.size === 0 &&
          associatedTeams.size === 0 &&
          usersToDelete.size === 0 &&
          teamsToDelete.size === 0)
      ) {
        throw new Error("Error: Empty request payload.");
      }

      const res = await fetchPost(
        `/ruleset/share/${ruleSet.rule_set?.id}/update`,
        {
          add: {
            users: Array.from(usersToAdd.values()),
            teams: Array.from(teamsToAdd.values()),
          },
          delete: {
            users: Array.from(usersToDelete.values()),
            teams: Array.from(teamsToDelete.values()),
          },
        },
      );

      if (!res.success || !res.shared_with) {
        throw new Error("Error: API request was not successful.");
      }

      toast.info(`Successfully shared Checklist(${ruleSet.rule_set?.name})`);
      sharedWith = [...res.shared_with];
      dispatch("updateRuleSets");
      resetDialog();
      document
        .querySelector(`#share-checklist-${ruleSet.rule_set?.id}`)
        ?.close();
      return;
    } catch (e) {
      console.log(e);
      toast.error(
        `Something went wrong sharing Checklist(${ruleSet.rule_set?.name}).`,
      );
      return;
    }
  }

  onMount(async () => {
    selectableEntities = getSelectableEntities();
  });

  $: (selectableEntities = getSelectableEntities()),
    [associatedTeams, associatedUsers];
</script>

<Modal
  modalId="share-checklist-{ruleSet.rule_set?.id}"
  size="md"
  on:close={closeDialog}
>
  <h3 class="text-center font-bold">Share Checklist</h3>

  <div class="flex w-full flex-col items-center gap-2">
    <label class="label" for="add_users">
      <span>Add users or teams</span>
    </label>
    <Select
      items={selectableEntities}
      bind:selectedValue={selectedEntity}
      placeholder="Add Users or Teams"
      classes="w-full"
      size="md"
      on:change={(e) => {
        selectedPermission = SharedRulePermissions.VIEW;
        selectedEntity = e.detail;
        const id = selectedEntity.split(":")[1];

        if (selectedEntity.startsWith("team")) {
          if (!associatedTeams) associatedTeams = new Map();

          const teamDetails = $org_teams.find((team) => team.id == id);
          associatedTeams.set(id, {
            ...teamDetails,
            permission: selectedPermission,
          });

          teamsToAdd.set(id, {
            ...teamDetails,
            permission: selectedPermission,
          });

          associatedTeams = associatedTeams;
          teamsToAdd = teamsToAdd;
        } else if (selectedEntity.startsWith("user")) {
          if (!associatedUsers) {
            associatedUsers = new Map();
          }

          const userDetails = $org_users.find((user) => user.id == id);
          associatedUsers.set(id, {
            ...userDetails,
            permission: selectedPermission,
          });

          usersToAdd.set(id, {
            ...userDetails,
            permission: selectedPermission,
          });

          associatedUsers = associatedUsers;
          usersToAdd = usersToAdd;
        }

        selectedPermission = null;
        selectedEntity = null;
      }}
    />

    <table class="mt-2 table">
      <thead>
        <tr>
          <td class="font-bold">People/Teams</td>
          <td colspan="2">
            <div
              class="tooltip tooltip-bottom"
              data-tip="Permission to view or edit this checklist and its rules."
            >
              Permissions
            </div>
          </td>
        </tr>
      </thead>

      {#if associatedUsers?.size > 0}
        {#each [...associatedUsers] as [userId, user]}
          <tr id="user-{userId}">
            <td class="flex items-center gap-1">
              <Icon icon="iconoir:user" />
              {user.first_name + " " + user.last_name}
            </td>
            <td>
              <Select
                items={permissions}
                selectedValue={user.permission}
                disabled={userChecklistAccessPermission ===
                  SharedRulePermissions.VIEW &&
                  user.permission === SharedRulePermissions.EDIT}
                on:change={(e) => {
                  const userRecord = associatedUsers.get(userId);
                  userRecord.permission = e.detail;

                  if (usersToDelete.has(userId)) usersToDelete.delete(userId);

                  associatedUsers.set(userId, userRecord);
                  associatedUsers = associatedUsers;

                  if (
                    !existingUsers.has(userId) ||
                    existingUsers.get(userId).permission !==
                      userRecord.permission
                  ) {
                    usersToAdd.set(userId, userRecord);
                    usersToAdd = usersToAdd;
                  }
                }}
              />
            </td>
            <td>
              <button
                class="btn btn-square btn-ghost btn-sm hover:btn-error"
                disabled={userChecklistAccessPermission ===
                  SharedRulePermissions.VIEW}
                on:click={() => {
                  if (existingUsers.has(userId)) {
                    usersToDelete.set(userId, user);
                    usersToDelete = usersToDelete;
                  }

                  if (usersToAdd.has(userId)) {
                    usersToAdd.delete(userId);
                    usersToAdd = usersToAdd;
                  }

                  associatedUsers.delete(userId);
                  associatedUsers = associatedUsers;
                }}
              >
                <Icon icon="iconoir:cancel" />
              </button>
            </td>
          </tr>
        {/each}
      {/if}

      {#if associatedTeams?.size > 0}
        {#each [...associatedTeams] as [teamId, team]}
          <tr>
            <td class="flex items-center gap-1">
              <Icon icon="iconoir:community" />
              {team.name}
            </td>
            <td>
              <Select
                items={permissions}
                selectedValue={team.permission}
                disabled={userChecklistAccessPermission ===
                  SharedRulePermissions.VIEW &&
                  team.permission === SharedRulePermissions.EDIT}
                on:change={(e) => {
                  const teamRecord = associatedTeams.get(teamId);
                  teamRecord.permission = e.detail;

                  if (teamsToDelete.has(teamId)) teamsToDelete.delete(teamId);

                  associatedTeams.set(teamId, teamRecord);
                  associatedTeams = associatedTeams;

                  if (
                    !existingTeams.has(teamId) ||
                    existingTeams.get(teamId).permission !==
                      teamRecord.permission
                  ) {
                    teamsToAdd.set(teamId, teamRecord);
                    teamsToAdd = teamsToAdd;
                  }
                }}
              />
            </td>
            <td>
              <button
                class="btn btn-square btn-ghost btn-sm hover:btn-error"
                disabled={userChecklistAccessPermission ===
                  SharedRulePermissions.VIEW}
                on:click={() => {
                  if (existingTeams.has(teamId)) {
                    teamsToDelete.set(teamId, team);
                    teamsToDelete = teamsToDelete;
                  }

                  if (teamsToAdd.has(teamId)) {
                    teamsToAdd.delete(teamId);
                    teamsToAdd = teamsToAdd;
                  }

                  associatedTeams.delete(teamId);
                  associatedTeams = associatedTeams;
                }}
              >
                <Icon icon="iconoir:cancel" />
              </button>
            </td>
          </tr>
        {/each}
      {/if}
    </table>
  </div>

  <!-- svelte-ignore missing-declaration -->
  <button
    class="btn {updating ? 'btn-disabled' : 'btn-primary'} btn-sm w-full"
    disabled={updating ||
      (!updating &&
        associatedUsers.size === 0 &&
        associatedTeams.size === 0 &&
        usersToDelete.size === 0 &&
        teamsToDelete.size === 0)}
    on:click={async () => {
      updating = true;
      await updateChecklistShares();
      updating = false;
    }}
  >
    {#if updating}
      <LogoLoader size="1.25rem" />
    {/if}
    Share Checklist
  </button>
</Modal>
