<script>
  import { getDisplayDate, getInitials, getUser } from "../../../helpers";
  import anchorme from "anchorme"; //https://alexcorvi.github.io/anchorme.js/

  export let comment;
  function attributes(string) {
    const attributes = {
      target: "_blank",
      class: "link",
    };
    // if (string.indexOf("google.com") > -1) {
    //     attributes["class"] = "google";
    // } else if (string.indexOf("bing.com") > -1) {
    //     attributes["class"] = "bing";
    // } else {
    //     attributes["class"] = "other";
    // }
    // if (string.endsWith("zip")) {
    //     attributes["download"] = true;
    // }
    return attributes;
  }
  let options = {
    //limited to 20 characters
    truncate: 30,
    // characters will be taken out of the middle
    middleTruncation: true,
    // all links will be opened in a new window
    // google links will have "google" class
    // bing links will have "bing" class
    // other links will have "other" class
    // links that end with ".zip" will be downloaded
    attributes: attributes,
    /// Remove the https:// and http:// from the rendered string
    // TODO: make work
    // extensions: [
    //   {
    //     test: /https?:\/\/[^\s]+/gi,
    //     transform: (string) => {
    //       // Constructing attributes string for HTML
    //       const attrString = Object.entries(attributes(string)).map(([key, value]) => `${key}="${value}"`).join(" ");
    //       // Removing protocol from display, keeping in href
    //       const displayText = string.replace(/(^\w+:|^)\/\//, '');
    //       return `<a href="${string}" ${attrString}>${displayText}</a>`;
    //     }
    //   }
    // ]
  };
</script>

<!-- relative for z-index issue -->
<div class="relative -ml-12 flex flex-col gap-1 rounded bg-[#E8F1FE] p-2.5">
  <div class="relative text-xs text-[#475569]">
    {getUser(comment.user_id)}

    <div class="avatar placeholder absolute -left-10 -top-1">
      <div class="w-6 rounded-full bg-neutral text-neutral-content">
        <span class="text-xs">{getInitials(getUser(comment.user_id))}</span>
      </div>
    </div>
  </div>

  <p class="whitespace-pre-wrap text-sm">
    {@html anchorme({ input: comment.text, options: options })}
  </p>

  <p class="text-xs text-[#94A3B8]">
    {getDisplayDate(comment.created_date)}
  </p>
</div>
