/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `User` entity `role`.
 *
 * @todo Implement this in other places where we are still using string objects to
 * define a user's `role`.
 */
export const UserRole = Object.freeze({
  ADMIN: "admin",
  ASSIGNEE: "assignee",
  USER: "user",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `User` entity `is_deleted` status.
 *
 * @todo Implement this in other places where we are still using string objects to
 * define a user's `role`.
 */
export const UserAccountStatus = Object.freeze({
  ACTIVE: "active",
  REMOVED: "removed",
});
