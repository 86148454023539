<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import { 
    doesDataTypeSupportMedia,
    doesSupportDirectTimestamp
  } from "../../../lib/utils/TimestampUtils";
  import Highlight from "../../Highlight.svelte";

  export let hitId = "";
  export let hit = "";
  export let hitUrl = "";
  export let hitContentLink = null;
  export let hitField = "";
  export let searchText = "";
  export let searchSettings = {};
  export let hitPool = "";
  export let hitDataType = "";
  export let hitTimestamp = null;

  let shouldDisplayHitContentLink = false;
  $: if (hitContentLink && hitPool) {
    shouldDisplayHitContentLink =
      hitPool.includes("website_image_text") ||
      hitPool.includes("website_document_text") ||
      hitPool.includes("website_multimedia") ||
      hitPool.includes("pool");
  } else shouldDisplayHitContentLink = false;
</script>

<div class="line-clamp-3 w-full text-xs">
  {#if hitId}<span>#{hitId}</span>
  {/if}

  {#if searchSettings.searchByHitDescription}
    <Highlight
      displayText={hit}
      url={hitContentLink && shouldDisplayHitContentLink
        ? hitContentLink
        : hitUrl}
      field={hitField}
      isCaseSensitive={false}
      isNotATag={doesDataTypeSupportMedia(hitDataType) 
                  && !doesSupportDirectTimestamp(hitContentLink)}
      timestamp={hitTimestamp}
      on:click
      bind:search={searchText}
    />
  {:else}
    <Highlight
      displayText={hit}
      url={hitContentLink && shouldDisplayHitContentLink
        ? hitContentLink
        : hitUrl}
      field={hitField}
      isCaseSensitive={false}
      isNotATag={doesDataTypeSupportMedia(hitDataType) 
                  && !doesSupportDirectTimestamp(hitContentLink)}
      timestamp={hitTimestamp}
      on:click
      bind:search={searchText}
    />
  {/if}
</div>
