<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { actions } from "../../../stores";
  import ResultOptions from "../../ResultOptions.svelte";

  const dispatch = createEventDispatcher();

  export let entry;
  export let reportId;
  export let ruleName;
  export let data = [];
  export let disableEditing = false;
  export let originalData = [];

  let originalStatus;
  let newStatus = "";
  if (entry && entry["hit"]) {
    originalStatus = entry["hit"]["in_report"];
  }

  let isOpen = false;
  let loaded = false;
  let actionTexts = [];

  function toggleOptionsOpen() {
    isOpen = !isOpen;

    if (entry && isOpen) {
      dispatch("open", entry["id"]);
    } else if (entry) {
      dispatch("close", entry["id"]);
    }
  }

  function handleHightlightRule(e) {
    dispatch("highlightRule", e.detail);
  }

  onMount(async () => {
    actionTexts = $actions.map(function (elem) {
      return elem.action_text;
    });

    loaded = true;
  });

  $: if (!isOpen && newStatus !== "") {
    originalData.map((originalDataEntry) => {
      if (originalDataEntry.hit.id === entry.hit.id) {
        originalDataEntry.hit.in_report = newStatus;
      }
      return originalDataEntry;
    });

    // data = data.filter((dataEntry) => {
    //   if (dataEntry.hit.id === entry.hit.id) return;
    //   return entry;
    // });

    newStatus = "";

    dispatch("refresh", originalData);
  }
</script>

<!-- for some reason it was taking h-8 instead of h-6 what btn-xs defaults too -->
<div class="tooltip tooltip-left h-6" data-tip="More Details">
  <button class="btn btn-ghost btn-xs" on:click={toggleOptionsOpen}>
    <Icon icon="iconoir:more-horiz" class="text-2xl" />
  </button>
</div>

{#if loaded && entry}
  <ResultOptions
    report_id={reportId}
    rule_name={ruleName}
    issue_id={entry["id"]}
    bind:maximize={isOpen}
    hit={entry}
    actions={actionTexts}
    on:highlightRule={handleHightlightRule}
    on:assign_state_updated={() => {
      dispatch("assign_state_updated");
    }}
    {disableEditing}
    on:status_updated={(event) => {
      newStatus = event.detail;
    }}
  />
{/if}
