<script>
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchGet, tidyPoolLabel } from "../../../helpers";
  import { maximised } from "../../../stores";
  import { defaultTimestampDialogData } from "../../../lib/utils/TimestampUtils";
  import { getUsableMediaLink } from "../../../lib/utils/GeneralDataUtils";
  import { isProperSite } from "../../../lib/utils/TimestampUtils";
  import Pagination from "../../table/Pagination.svelte";
  import TableAge from "../TableAge.svelte";
  import TableAssignedTo from "../TableAssignedTo.svelte";
  import TableHit from "../TableHit.svelte";
  import TableHitOptions from "../TableHitOptions.svelte";
  import TableMassOptions from "../TableMassOptions.svelte";
  import TableSource from "../TableSource.svelte";
  import TableStatus from "../TableStatus.svelte";
  import TableType from "../TableType.svelte";
  import TimestampViewModal from "../../Modals/TimestampViewModal.svelte";
  import AssignUsers from "../../AssetReview/AssignUsers.svelte";
  import { groupDataByGroupId } from "./grouped";
  import { getDataType } from "../../../lib/utils/GeneralDataUtils";

  const dispatch = createEventDispatcher();

  export let data = [];
  export let forceStateUpdate = 1;
  export let showGroupedTable = true;
  export let disableEditing = false;
  export let tableId = "";
  export let tableRootPage;
  export let canEditPermissions;
  export let openedTab;
  export let itemsPerPage = 10;
  export let currentPage = 1;
  export let statusCounts = { Open: 0, Flagged: 0, Closed: 0, Removed: 0 };
  export let rules = [];
  export let unique_data_types = [];
  export let unique_indexes = [];
  export let unique_vendors = [];
  export let selectedRulesId;
  export let selectedDataTypes;
  export let selectedVendors;
  export let selectedSocialTypes;
  export let columnToSortBy;
  export let selectedHits = [];
  export let reportFilters;
  export let loading;
  export let rulesHitsStats = new Map();
  export let groupedData = groupDataByGroupId(data, openedTab);

  let totalHits = statusCounts[openedTab];
  let numberOfSelectedHits = selectedHits.size;
  let originalData = data;
  let searchText = "";
  let searchSettings = {
    isCaseSensitive: { enabled: false, label: "Case sensitive" },
    searchByRuleTitle: { enabled: false, label: "Rule titles" },
    searchByRuleDescription: { enabled: false, label: "Rule info" },
    searchByHitTitle: { enabled: true, label: "Hit titles" },
    searchByHitDescription: { enabled: true, label: "Hit highlights" },
  };
  let canUseKeysToPaginate = true;
  let selectedGroups = new Set();
  let allRules = getAllRules();
  let shouldSearch = true;
  let allTabs = ["Open", "Flagged", "Closed", "Removed"];
  let timestampModal = null;

  let timestampDialogData = { ...defaultTimestampDialogData }

  function toggleGroupSelection(group) {
    if (selectedGroups.has(group.group_id)) {
      selectedGroups.delete(group.group_id);
      selectedGroups = selectedGroups;
      group.entries.forEach((e) => selectedHits.delete(e.hit.id));
      selectedHits = selectedHits;
    } else {
      selectedGroups.add(group.group_id);
      selectedGroups = selectedGroups;
      selectedHits = new Set([
        ...Array.from(selectedHits),
        ...groupedData
          .filter((g) => g.group_id === group.group_id)
          .flatMap((g) => {
            return g.entries.map((e) => e.hit.id);
          }),
      ]);
    }

    numberOfSelectedHits = selectedHits.size;
  }

  function toggleHitSelection(hitId) {
    if (selectedHits) {
      if (selectedHits.has(hitId)) {
        selectedHits.delete(hitId);
      } else {
        selectedHits.add(hitId);
      }

      numberOfSelectedHits = selectedHits.size;
    }
  }

  function getAllRules() {
    let output = {};

    rules.forEach((rule) => {
      output[rule.id] = rule;
    });

    return Object.values(output);
  }

  async function selectAllFiltered() {
    const queryString = new URLSearchParams({ ...reportFilters }).toString();
    const res = await fetchGet(`/report/${tableId}/hits/ids?${queryString}`);

    if (res.success) {
      selectedHits = new Set(res.ids);
      selectedHits;
      selectedGroups = new Set([...groupedData.flatMap((g) => g.group_id)]);
      selectedGroups = selectedGroups;
      numberOfSelectedHits = selectedHits.size;
    } else {
      toast.error("There was an error selecting all filtered hits.");
    }
  }

  function selectAllOnPage() {
    selectedHits = new Set([
      ...Array.from(selectedHits),
      ...groupedData.flatMap((g) => {
        return g.entries.map((e) => e.hit.id);
      }),
    ]);
    selectedHits = selectedHits;
    selectedGroups = new Set([...groupedData.flatMap((g) => g.group_id)]);
    selectedGroups = selectedGroups;
    numberOfSelectedHits = selectedHits.size;
  }

  function deselectAll() {
    selectedGroups = new Set();
    selectedGroups = selectedGroups;
    selectedHits = new Set();
    selectedHits = selectedHits;
    numberOfSelectedHits = selectedHits.size;
    groupedData = groupedData;
  }

  function handleTimestampData(timestamp) {
    timestampDialogData = {
      title: timestamp.title,
      timestamp: timestamp.media_timestamp,
      url: getUsableMediaLink(
        timestamp.index,
        timestamp.data_type,
        timestamp.url,
        timestamp.content_link
      ),
      caption: timestamp.highlight
    }
    timestampModal.showModal()
  }

  onMount(() => ($maximised = false));
  onDestroy(() => ($maximised = true));

  $: if (shouldSearch) {
    groupedData = groupDataByGroupId(originalData, openedTab);
    shouldSearch = false;
  }

  $: if (data && openedTab) groupedData = groupDataByGroupId(data, openedTab);

  const updateTab = (tab) => {
    openedTab = tab;
    currentPage = 1;
    deselectAll();
  };
  export let updatedOpenedTab = false;
  $: if (updatedOpenedTab) {
    updateTab(openedTab);
    updatedOpenedTab = false;
  }
</script>

<TimestampViewModal 
  bind:this={timestampModal} 
  title={timestampDialogData.title}
  caption={timestampDialogData.caption}
  url={timestampDialogData.url}
  timestamp={timestampDialogData.timestamp}
/>

{#if selectedHits.size > 0}
  <TableMassOptions
    {originalData}
    bind:data
    bind:currentPage
    {itemsPerPage}
    bind:selectedDataTypes
    bind:selectedVendors
    bind:selectedRulesId
    bind:selectedSocialTypes
    bind:forceStateUpdate
    bind:selectedHits
    reportId={tableId}
    bind:columnToSortBy
    bind:showGroupedTable
    bind:numberOfSelectedHits
    {openedTab}
    bind:statusCounts
    hitSearchText={""}
    hitSearchSettings={{
      isCaseSensitive: { enabled: false, label: "Case sensitive" },
    }}
    on:selectAllOnPage={selectAllOnPage}
    on:selectAllFiltered={async () => await selectAllFiltered()}
    on:deselectAll={deselectAll}
    on:refresh={(e) => {
      dispatch("refresh", e.detail);
      data = e.detail;
    }}
  />
{/if}

{#key forceStateUpdate}
  <div class="flex flex-col gap-4">
    {#each groupedData as group}
      <div class="bg-base-200 flex gap-4 rounded border p-4">
        <div
          class="bg-base-100 relative flex w-52 shrink-0 flex-col gap-2 rounded border p-2"
        >
          <input
            class="checkbox-primary checkbox checkbox-sm absolute left-2 top-2"
            type="checkbox"
            checked={selectedGroups.has(group.group_id)}
            on:change={() => toggleGroupSelection(group)}
            disabled={disableEditing}
          />

          <TableType
            type={group.entries[0].hit.index}
            data_type={getDataType(group.entries[0].hit)}
            classes="text-4xl mx-auto"
          />

          <a
            href={group.entries[0].hit.content_link && isProperSite(group.entries[0].hit.content_link)
              ? group.entries[0].hit.content_link
              : group.entries[0].hit.url}
            class="text-primary truncate break-words font-medium hover:underline"
            target="_blank"
          >
            {group.entries[0].hit.title
              ? group.entries[0].hit.title
              : tidyPoolLabel(group.entries[0].hit.index)}
          </a>

          <p class="text-base-content/70 text-sm font-light">
            Rule: {group.entries[0].rule.name}
            {#if group.entries[0].rule.description}
              | {group.entries[0].rule.description}
            {/if}
          </p>

          <div>
            <TableSource
              hitId={group.entries[0].hit.id}
              hitUrl={group.entries[0].hit.url}
              hitTitle={group.entries[0].hit.title}
              hitContentLink={group.entries[0].hit.content_link}
              bind:searchText
              bind:searchSettings
              hitPool={group.entries[0].hit.index}
              hitDataType={group.entries[0].hit.data_type}
            />
          </div>
        </div>

        <div class="flex w-full flex-col gap-2">
          {#each group.entries.slice(0, group.entries.length > 4 && !group.entries[0].expandAll ? 4 : group.entries.length) as entry (entry.hit.id)}
            <div
              class="bg-base-100 flex w-full grow flex-wrap items-center justify-between gap-2 rounded border p-2"
              transition:slide={{
                duration: group.entries[0].expandAll ? 250 : 0,
              }}
            >
              <input
                type="checkbox"
                class="checkbox-primary checkbox checkbox-sm"
                checked={selectedHits.has(entry.hit.id)}
                disabled={disableEditing}
                on:change={() => toggleHitSelection(entry.hit.id)}
              />

              <div class="w-96 flex-auto">
                <TableHit
                  hit={entry.hit.highlight}
                  hitId={entry.hit.id}
                  hitUrl={entry.hit.url}
                  hitContentLink={entry.hit.content_link}
                  hitField={entry.hit.field}
                  bind:searchText
                  bind:searchSettings
                  hitPool={entry.hit.index}
                  hitDataType={entry.hit.data_type}
                  hitTimestamp={entry.hit.data_type.toLowerCase() == 'timestamp' ? entry.hit.media_timestamp : null}
                  on:click={()=>handleTimestampData(entry.hit)}
                />
              </div>

              <div class="flex items-center justify-end gap-2">
                <AssignUsers
                  asset_type="hit"
                  {disableEditing}
                  hit_id={entry.hit.id}
                  assignees={entry.assigned_users.map((id) => ({ assigned_id: id }))}
                  on:change={(e) => {
                    entry.assigned_users = e.detail.map((a) => a.assigned_id);
                    entry.hit = entry.hit;

                    data = data.map((e) => {
                      if (e.hit.id === entry.hit.id)
                        e.assigned_users = entry.assigned_users;
                      return e;
                    });
                  }}
                />

                <div class="tooltip" data-tip="Last Updated">
                  <TableAge
                    date={entry.hit.last_updated
                      ? entry.hit.last_updated
                      : entry.hit.created_date}
                  />
                </div>

                <TableStatus
                  status={entry.hit.in_report}
                  hitId={entry.hit.id}
                  {data}
                  {disableEditing}
                  bind:originalData
                  bind:forceStateUpdate
                  on:refresh={(e) => dispatch("refresh", e.detail)}
                />

                <TableHitOptions
                  bind:entry
                  reportId={entry.report.id}
                  ruleName={entry.rule.name}
                  {data}
                  {disableEditing}
                  on:open={() => (canUseKeysToPaginate = false)}
                  on:close={() => (canUseKeysToPaginate = true)}
                  on:refresh={(e) => dispatch("refresh", e.detail)}
                />
              </div>
            </div>
          {/each}

          {#if group.entries.length > 4}
            <div class="divider">
              <button
                class="btn btn-ghost btn-xs"
                on:click={() =>
                  (group.entries[0].expandAll = group.entries[0].expandAll
                    ? false
                    : true)}
              >
                {group.entries[0].expandAll ? "Collapse" : "View all"}
                {!group.entries[0].expandAll
                  ? `(${group.entries.length - 4} more)`
                  : ""}
              </button>
            </div>
          {/if}
        </div>
      </div>
    {/each}
  </div>
{/key}

{#if data.length > 0}
  <Pagination
    bind:currentPage
    bind:itemsPerPage
    bind:totalHits
    {canUseKeysToPaginate}
  />
{/if}
