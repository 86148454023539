<script>
  import { createEventDispatcher } from "svelte";
  import LabelBean from "./LabelBean.svelte";

  const dispatch = createEventDispatcher();

  export let labels = [];
  export let disabled = false;
  export let closeIcon = true;

  function handleBeanClick(event) {
    dispatch("labelBeanClicked", event.detail);
  }

  // Create a key that changes when labels change
  $: labelKey = labels.map(l => l.id).join('-');
</script>

{#if labels.length > 0}
  {#key labelKey}
    {#each labels as label}
      <LabelBean
        name={label.name}
        color={label.color}
        id={label.id}
        {closeIcon}
        {disabled}
        on:labelBeanClicked={handleBeanClick}
      />
    {/each}
  {/key}
{/if}