<script>
  import AssetListTable from "./AssetListTable.svelte";

  export let reportId;
  export let activeAssetId;
  export let asset;
  export let name = "";
  export let rows = [];
  export let workflow;
  export let assetStatusMap = new Map();
</script>

<div class="collapse-arrow bg-base-200 collapse">
  <input type="checkbox" class="peer" checked={rows.length != 0} />
  <div class="collapse-title font-semibold">
    {name} ({rows.length})
  </div>
  <div class="collapse-content overflow-auto">
    {#if rows.length == 0}
      <p>No Assets</p>
    {:else}
      <AssetListTable
        {rows}
        {reportId}
        bind:activeAssetId
        {asset}
        {workflow}
        {assetStatusMap}
      />
    {/if}
  </div>
</div>
