/**
 * @description Generic function to determine if a variable is `undefined`, `null`, an
 * empty `string`, an empty `array`, or an empty `Object`.
 * @param {*} value The value (variable) to check
 * @returns A `boolean` value corresponding to whether the supplied `value` parameter is "empty".
 */
export function isEmpty(value) {
  return (
    !value ||
    value == null ||
    (value.hasOwnProperty("length") && value.length === 0) ||
    (value.constructor === Object && Object.keys(value).length === 0)
  );
}

/**
 * @description Generic function to perform a [deep copy](https://developer.mozilla.org/en-US/docs/Glossary/Deep_copy)
 * of a given object. I.e., creates a new reference in memory for the given object.
 * @param {*} value The value (variable) to make a _deep_ copy of.
 * @returns A _deep copy_ (new reference in memory) to the object if it is not `undefined`.
 */
export function deepCopy(value) {
  if (!isEmpty(value)) {
    return JSON.parse(JSON.stringify(value));
  }
}

/**
 * @description Generic function to perform a [deep copy](https://developer.mozilla.org/en-US/docs/Glossary/Deep_copy)
 * of a given object. I.e., creates a new reference in memory for the given Map<K, V> object.
 * @param {Map} mapObject The Map object to make a _deep_ copy of.
 * @returns A _deep copy_ (new reference in memory) to the object if it is not `undefined`.
 */
export function deepCopyMap(mapObject) {
  if (mapObject && mapObject.size > 0) {
    return new Map(
      [...mapObject].map(([key, value]) => [key, deepCopy(value)]),
    );
  }

  return new Map();
}

/**
 * @description Capitalizes the first letter of a string and converts the rest to
 * lowercase.
 *
 * @param {string} string - The input string to be capitalized.
 * @returns {string} The input string with the first letter capitalized and the rest in lowercase.
 */
export function capitalise(str) {
  if (isEmpty(str)) {
    return str;
  }

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * @description Generates an array containing a range of numbers from start to stop, inclusive, with
 * a given step.
 *
 * @param {number} start - The starting value of the range.
 * @param {number} stop - The ending value of the range.
 * @param {number} step - The increment between each value in the range.
 * @returns {number[]} An array containing the numbers from start to stop with the specified step.
 *
 * @example
 * // returns [0, 2, 4, 6, 8, 10]
 * arrayRange(0, 10, 2);
 */
export function arrayRange(start, stop, step) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step,
  );
}

/**
 * Checks if all fields in the object are either `null`, an empty string, an empty array, or an empty object.
 *
 * @param {Object} obj - The object to be checked.
 * @returns {boolean} - Returns `true` if all fields are empty or `null`, otherwise `false`.
 */
export function isEmptyObj(obj) {
  return Object.values(obj).every(
    (value) =>
      value === null ||
      value === "" ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" &&
        value !== null &&
        Object.keys(value).length === 0),
  );
}

/**
 * Checks if a value is a non-null object and not an array.
 *
 * @param {any} val - The value to check.
 * @returns {boolean} - Returns true if the value is an object and not an array or null.
 */
export function isObj(val) {
  return typeof val === "object" && !Array.isArray(val) && val !== null;
}

/**
 * Checks if a value is an array of objects.
 *
 * @param {any} val - The value to check.
 * @returns {boolean} - Returns true if the value is an array and all elements in the array are objects.
 */
export function isArrayOfObj(val) {
  return Array.isArray(val) && val.every((v) => isObj(v));
}

/**
 * Calculate the percentage of `current` relative to `total`.
 *
 * @param {number} current - The current value (default is 0).
 * @param {number} total - The total value (default is 0).
 * @returns {number} - The percentage value rounded to the nearest integer.
 *                      Returns 0 if both `current` and `total` are 0, or if `total` is 0.
 */
export function calculatePercentage(current = 0, total = 0) {
  if (total === 0) return 0;

  return Math.round((100 * current) / total);
}

/**
 * Checks if a given string is a valid UUID (version 4) as generated by the function
 * `crypto.randomUUID()`.
 *
 * This function verifies that the input string matches the format of a
 * UUID v4, which is a 36-character string containing 32 hexadecimal characters
 * separated by hyphens in the format 8-4-4-4-12.
 *
 * UUID v4 has the following format: xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
 * where 'x' is any hexadecimal character and 'y' is one of 8, 9, A, or B.
 *
 * @param {string} uuid - The string to validate as a UUID v4.
 * @returns {boolean} - Returns true if the input is a valid UUID v4; otherwise, false.
 */
export function isValidUUID(uuid) {
  const uuidV4Pattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidV4Pattern.test(uuid);
}
