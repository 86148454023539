/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Rule` "statuses".
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * that utilise these values.
 */
export const RuleStatus = Object.freeze({
  PASSED: "Passed",
  REVIEW: "To Review",
  RISK: "Risk",
  UNKNOWN: "Unknown",
  FAILED: "Failed",
  TRUE: "True",
  FALSE: "False",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Rule` "search_type".
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * that utilise these values.
 */
export const RuleSearchType = Object.freeze({
  LEXICAL: "LEXICAL",
  BOOLEAN: "BOOLEAN",
  MANUAL: "MANUAL",
  TENSOR: "TENSOR",
  CONDITIONAL: "CONDITIONAL",
  CONTEXTUAL: "CONTEXTUAL",
  IMAGE: "IMAGE",
  VISUAL: "VISUAL",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Rule` "search_type" labels.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * that utilise these values.
 */
export const RuleSearchTypeLabel = Object.freeze({
  LEXICAL: "Exact Match",
  BOOLEAN: "Boolean",
  MANUAL: "Manual Task",
  TENSOR: "Similarity",
  CONDITIONAL: "Conditional",
  CONTEXTUAL: "AI Detection",
  IMAGE: "Image",
  VISUAL: "Visual",
});

/**
 * @description
 * Asset Rule Type Options, containing icons, names, descriptions, and search type ids.
 */
export const AssetRuleTypeOptions = Object.freeze([
  {
    icon: "octicon:goal-24",
    name: "Exact Match",
    desc: "Exact Matches are when an exact word or phrase (disregarding spacing and punctuation) is detected on the asset.",
    id: RuleSearchType.LEXICAL,
    section: 1,
  },
  {
    icon: "iconoir:union-alt",
    name: "Similarity Search",
    desc: "A Similarity Search looks for similar terms or examples that mean the same thing but may be expressed differently (for example, synonym or minor variations to a phrase).",
    id: RuleSearchType.TENSOR,
    section: 0,
  },
  {
    icon: "ic:round-plus-minus",
    name: "Boolean Query",
    desc: "A Boolean Query matches assets based on a set of true/false conditions connected by logical operators (e.g., AND, OR, NOT).",
    id: RuleSearchType.BOOLEAN,
    section: 1,
  },
  {
    icon: "iconoir:zoom-in",
    name: "AI Detection",
    desc: "The AI Detection will find matches based on the instructions, examples and definitions you give.",
    id: RuleSearchType.CONTEXTUAL,
    section: 0,
  },
  {
    icon: "iconoir:eye",
    name: "Image Entity",
    desc: "An Image Entity rule will detect similar features in the image when the specified idea or object is detected.",
    id: RuleSearchType.IMAGE,
    section: 1,
  },
  {
    icon: "mdi-car-manual-transmission",
    name: "Manual Task",
    desc: "Manual Tasks are tasks that currently require human investigation and verification to tick the checkboxes.",
    id: RuleSearchType.MANUAL,
    section: 1,
  },
  {
    icon: "iconoir:fast-arrow-right",
    name: "Conditional Rule",
    desc: 'With Conditional Rules, you can create tailored workflows using "if-then" logic to trigger actions based on your defined conditions.',
    id: RuleSearchType.CONDITIONAL,
    section: 0,
  },
]);

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Rule` "data.type". E.g.,
 * for `RuleSearchType.BOOLEAN` rules, we swap between the strings "Builder" and "Boolean" to
 * determine classic or visual representations of the given rule.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * that utilise these values.
 */
export const RuleDataType = Object.freeze({
  BOOLEAN: "Boolean",
  BUILDER: "Builder",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Rule` "scope". E.g.,
 * for displaying rules within the "Saved Rules" page, we swap between the strings
 * "my", "industry", "organization", and "shared" to display only the relevant rules
 * to the user.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * that utilise these values.
 */
export const RuleScope = Object.freeze({
  INDUSTRY: "Industry",
  MY: "My",
  ORGANIZATION: "Organization",
  SHARED: "Shared with Me",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Rule` "type". E.g.,
 * rules are either associated with "assets" or "live" (streams).
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * that utilise these values.
 */
export const RuleType = Object.freeze({
  ASSET: "asset",
  LIVE: "live",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for loaded `Rule` tab names.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * that utilise these values.
 */
export const LoadRulesTab = Object.freeze({
  INDUSTRY: "Industry Checklists",
  MY: "My Checklists",
  ORGANIZATION: "Organization Checklists",
  SHARED: "Checklists Shared With Me",
});

/**
 * @description
 * Intermediary `enum` for JavaScript implementation for conditional `Rule` types.
 */
export const ConditionalRuleType = Object.freeze({
  IF: "IF",
  THEN: "THEN",
  ELSE: "ELSE",
});
