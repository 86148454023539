<script>
  export let entry;
  export let tableRootPage = "";
</script>

{#if tableRootPage == "spelling"}
  <p>
    {#if entry.hit.text.split(",")[0]}Hit: <strong
        >{entry.hit.text.split(",")[1]}</strong
      >{/if}
  </p>
  <p>
    {#if entry.hit.text.split(",")[1]}Reason: <strong
        >{entry.hit.text.split(",")[0]}</strong
      >{/if}
  </p>
  <p>
    {#if entry.hit.text.split(",")[2]}Suggestion: <strong
        >{entry.hit.text.split(",")[2]}</strong
      >{/if}
  </p>
{:else}
  <div class="tooltip" data-tip={entry.rule.name}>
    <strong class="line-clamp-2 overflow-hidden text-ellipsis text-start">
      {entry.rule.name}
    </strong>
  </div>
{/if}
