<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { persisted } from "svelte-persisted-store";
  //import { date_format } from "../../stores";
  import TableSearch from "./TableFilters/TableSearch.svelte";

  const dispatch = createEventDispatcher();

  export let data = [];
  export let columnToSortBy;
  let columnToSort;
  let columnSortDirection;
  let columnSortTable;
  export let hitSearchText;
  export let hitSearchSettings;
  export let sourceSearchText;
  export let sourceSearchSettings;
  export let openedTab;
  export let tableRootPage = "";

  let spellingReport = tableRootPage == "spelling";

  export let dateToSortByString = "";

  /**
   * @todo Temporarily disabled the searching of sources
   */
  let sourceSearchEnabled = false;

  let dateToSortBy;
  $: dateToSortBy = new Date(dateToSortByString);

  let filters = {
    types: {},
    sourceLocations: {},
    hits: {},
    rules: {},
  };

  function sortByColumn(column, sortDirection, sortTable) {
    columnToSort = column;
    columnSortDirection = sortDirection;
    columnSortTable = sortTable;
    columnToSortBy =
      columnToSort + "-" + columnSortDirection + "-" + columnSortTable;
  }

  $: minColWidth = {
    checkbox: 40,
    type: 100,
    source: 96,
    hit: 70,
    rule: 80,
    updated: 150,
    more: spellingReport
      ? 328
      : openedTab === "Open"
        ? 228
        : openedTab === "Flagged"
          ? 328
          : openedTab === "Closed"
            ? 312
            : 300,
  };
  const defColWidths = {
    checkbox: 54,
    type: 130,
    source: 160,
    hit: 260,
    rule: 120,
    updated: 150,
    more: spellingReport
      ? 415
      : openedTab === "Open"
        ? 228
        : openedTab === "Flagged"
          ? 328
          : openedTab === "Closed"
            ? 312
            : 300,
  };
  const colWidths = persisted("colWidths", defColWidths);

  $: if (openedTab) $colWidths.more = minColWidth.more;
  $colWidths.updated = 150;

  onMount(() => {
    data = data.map((entry) => {
      entry["selected"] = false;
      return entry;
    });

    for (let i = 0; i < data.length; i++) {
      filters["types"][data[i]["hit"]["index"]] = true;
      filters["sourceLocations"][data[i]["hit"]["vendor"]] = true;
      filters["hits"][data[i]["hit"]["highlight"]] = true;
      filters["rules"][data[i]["rule"]["name"]] = true;
    }

    // col resizing logic
    const resizers = document.querySelectorAll(".resizer");

    resizers.forEach((r) => {
      r.addEventListener("dblclick", () => {
        $colWidths[r.dataset.colname] = defColWidths[r.dataset.colname];
      });

      r.addEventListener("mousedown", (e) => {
        let prevX = e.clientX;

        const mouseMoveHandler = (e) => {
          $colWidths[r.dataset.colname] += e.clientX - prevX;
          prevX = e.clientX;

          if ($colWidths[r.dataset.colname] < minColWidth[r.dataset.colname])
            $colWidths[r.dataset.colname] = minColWidth[r.dataset.colname];
        };

        const mouseUpHandler = () => {
          document.removeEventListener("mousemove", mouseMoveHandler);
          document.removeEventListener("mouseup", mouseUpHandler);
        };

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      });
    });
  });
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<thead>
  <tr>
    <th class="p-0" style:width="{$colWidths.checkbox}px">
      <div class="resizer" data-colname="checkbox" />
    </th>
    <th class="p-0" style:width="{$colWidths.type}px">
      <div class="dropdown w-full">
        <label tabindex="0" for="" class="btn btn-ghost btn-sm rounded-none">
          Type <Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-64 overflow-auto rounded border p-1 shadow"
        >
          <li>
            <button class="block" on:click={() => sortByColumn("data_type", "asc", "hit")}>
              Sort A-Z
              {#if columnToSortBy === "data_type-asc-hit"}
                <Icon icon="iconoir:check" class="text-xl float-right" />
              {/if}
            </button>
          </li>
          <li>
            <button class="block" on:click={() => sortByColumn("data_type", "desc", "hit")}>
              Sort Z-A
              {#if columnToSortBy === "data_type-desc-hit"}
                <Icon icon="iconoir:check" class="text-xl float-right" />
              {/if}
            </button>
          </li>
        </ul>
      </div>
      <div class="resizer" data-colname="type" />
    </th>
    <th class="w-[200px] p-0" style:width="{$colWidths.source}px">
      <div class="dropdown w-full">
        <label tabindex="0" for="" class="btn btn-ghost btn-sm rounded-none">
          Source<Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-64 overflow-auto rounded border p-1 shadow"
        >
          {#if sourceSearchEnabled}
            <TableSearch
              dropdownFilterDesign={false}
              bind:searchText={sourceSearchText}
              bind:searchSettings={sourceSearchSettings}
              on:enterPressed={() =>
                dispatch("search", { hitSearchText, sourceSearchText })}
            />
          {/if}
          <li>
            <button class="block" on:click={() => sortByColumn("title", "asc", "hit")}>
              Sort A-Z
              {#if columnToSortBy === "title-asc-hit"}
                <Icon icon="iconoir:check" class="text-xl float-right" />
              {/if}
            </button>
          </li>
          <li>
            <button class="block" on:click={() => sortByColumn("title", "desc", "hit")}>
              Sort Z-A
              {#if columnToSortBy === "title-desc-hit"}
                <Icon icon="iconoir:check" class="text-xl float-right" />
              {/if}
            </button>
          </li>
        </ul>
      </div>
      <div class="resizer" data-colname="source" />
    </th>
    <th class="p-0" style:width="{$colWidths.hit}px">
      <div class="dropdown w-full">
        <label tabindex="0" for="" class="btn btn-ghost btn-sm rounded-none">
          Hit <Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-64 overflow-auto rounded border p-1 shadow"
        >
          <TableSearch
            dropdownFilterDesign={false}
            bind:searchText={hitSearchText}
            bind:searchSettings={hitSearchSettings}
            on:enterPressed={() =>
              dispatch("search", { hitSearchText, sourceSearchText })}
          />
        </ul>
      </div>
      <div class="resizer" data-colname="hit" />
    </th>
    <th class="p-0" style:width="{$colWidths.rule}px">
      <div class="dropdown w-full">
        <label tabindex="0" for="" class="btn btn-ghost btn-sm rounded-none">
          {spellingReport ? "Spelling" : "Rule"}
          <Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-64 overflow-auto rounded border p-1 shadow"
        >
          <li>
            <button class="block" on:click={() => sortByColumn("name", "asc", "rule")}>
              Sort A-Z
              {#if columnToSortBy === "name-asc-rule"}
                <Icon icon="iconoir:check" class="text-xl float-right" />
              {/if}
            </button>
          </li>
          <li>
            <button class="block" on:click={() => sortByColumn("name", "desc", "rule")}>
              Sort Z-A
              {#if columnToSortBy === "name-desc-rule"}
                <Icon icon="iconoir:check" class="text-xl float-right" />
              {/if}
            </button>
          </li>
        </ul>
      </div>
      <div class="resizer" data-colname="rule" />
    </th>
    <th class="p-0" style:width="{$colWidths.updated}px">
      <div class="dropdown w-full">
        <label
          tabindex="0"
          for=""
          class="btn btn-ghost btn-sm flex-nowrap rounded-none"
        >
          Updated <Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-64 overflow-auto rounded border p-1 shadow"
        >
          <li>
            <button class="block" on:click={() => sortByColumn("last_updated", "asc", "hit")}>
              Sort Oldest to Latest
              {#if columnToSortBy === "last_updated-asc-hit"}
                <Icon icon="iconoir:check" class="text-xl float-right" />
              {/if}
            </button>
          </li>
          <li>
            <button
              class="block"
              on:click={() => sortByColumn("last_updated", "desc", "hit")}
            >
              Sort Latest to Oldest
              {#if columnToSortBy === "last_updated-desc-hit"}
                <Icon icon="iconoir:check" class="text-xl float-right" />
              {/if}
            </button>
          </li>
          <!--Removing date range filters until this is implemented-->
          <!--<li> 
            <button on:click={() => sortByColumn("last_updated")}>
              Date Range Filters
            </button>
          </li>-->
        </ul>
      </div>
      <div class="resizer" data-colname="updated" />
    </th>
    <th class="!min-w-max" style:width="{$colWidths.more}px">
      <div class="resizer" data-colname="more" />
    </th>
  </tr>
</thead>

<style>
  th {
    position: relative;
  }
  .resizer {
    background: rgb(0 0 0 /10%);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    z-index: 1;
    transition: all 200ms ease-in-out;
    cursor: col-resize;
  }
  .resizer:hover {
    background: rgb(0 0 0 /20%);
    width: 3px;
  }
  .resizer:active {
    background: oklch(var(--p));
    width: 4px;
  }
</style>
