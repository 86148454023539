<script>
  import Icon from "@iconify/svelte";
  import { link } from "svelte-spa-router";
  import { maximised } from "../../stores";
  import Dropdown from "../Notifications/NotificationsDropdown/Dropdown.svelte";
  import Search from "../WidgetBar/Search/Search.svelte";
  import NavLogo from "./NavLogo.svelte";

  export let role;
  export let total_notifications = 0;
  export let unseen_notifications = 0;
</script>

<nav class="navbar fixed top-0 z-50 w-full bg-primary text-primary-content">
  <div class="navbar-start gap-2">
    <button
      class="btn btn-square btn-ghost"
      on:click={() => ($maximised = !$maximised)}
    >
      <Icon icon="iconoir:menu" class="text-xl" />
    </button>

    <a
      class="my-auto mr-auto h-[32px] self-start"
      style="width: 140px;"
      href="/home"
      use:link
    >
      <NavLogo />
    </a>
  </div>

  <div class="navbar-end gap-2">
    <Search />

    <Dropdown {total_notifications} {unseen_notifications} />

    <a
      class="btn btn-square btn-ghost"
      href="/{role !== 'admin' ? 'user-' : ''}settings"
      use:link
    >
      <Icon icon="iconoir:settings" class="text-xl" />
    </a>
  </div>
</nav>
