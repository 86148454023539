<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../../helpers";
  import CommentInput from "./CommentInput.svelte";
  import CommentsView from "./CommentsView.svelte";

  const dispatch = createEventDispatcher();

  export let comments = [];
  export let apiEndpoint; // The API endpoint for posting comments
  export let type = "asset"; // Default type is "asset", but could be "rule" or others
  export let subType = "hit"; // Default type is "Hit" for `Status` child component
  export let commentInputId;
  export let isParentConditional = false;
  export let isChildConditional = false;
  export let xs = false;

  let comment = "";
  let taggedUserIds = new Set();

  async function addComment() {
    if (comment.trim() === "") {
      toast.warning("Please enter a comment");
      return;
    }

    try {
      const res = await fetchPost(apiEndpoint, {
        text: comment,
        category: "Text",
        taggedUserIds: Array.from(taggedUserIds),
      });

      if (!res?.success) {
        toast.error("There was an error adding your comment");
        return;
      }

      comment = "";
      taggedUserIds = new Set();
      dispatch("updateComments", res.comments);
    } catch (error) {
      console.error(error);
      toast.error("There was an error adding your comment");
    }
  }

  function handleKeyPress(event) {
    if (event.shiftKey && event.key === "Enter") {
      event.preventDefault();
      addComment();
    }
  }
</script>

<div class="flex flex-col {isChildConditional ? 'px-2' : ''}">
  <CommentInput
    {commentInputId}
    bind:comment
    bind:taggedUserIds
    on:keypress={handleKeyPress}
    on:submitComment={addComment}
  />

  <button
    class="btn btn-primary ml-auto mt-2"
    class:btn-sm={!xs}
    class:btn-xs={xs}
    on:click={addComment}
  >
    <Icon icon="iconoir:message-text" /> Comment
  </button>

  {#if comments}
    {#if comments.length > 0}
      <CommentsView {comments} {type} {subType} {isParentConditional} />
    {/if}
  {/if}
</div>
