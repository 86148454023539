<script>
  import Icon from "@iconify/svelte";

  export let currentPage = 1;
  export let itemsPerPage = 10;
  export let canUseKeysToPaginate = false;
  export let totalHits = 10;
  export let pageSizeOptions = [5, 10, 50, 100];

  let inputValue = 5;
  $: inputValue = currentPage;

  let isError = false;

  function handleKey(event) {
    if (!canUseKeysToPaginate) return;
    if (event.target.tagName === "INPUT") return;

    const maxPage = Math.ceil(totalHits / itemsPerPage);

    // If the page would go below 1 or above the max page, don't change the page
    if (currentPage === 1 && event.key === "ArrowLeft") return;
    if (currentPage === maxPage && event.key === "ArrowRight") return;

    let targetBtn = null;

    if (event.key === "ArrowLeft") {
      currentPage += 1;
      targetBtn = prevBtn;
    } else if (event.key === "ArrowRight") {
      currentPage += 1;
      targetBtn = null;
    }

    if (targetBtn) {
      targetBtn.classList.add("btn-active");
      setTimeout(() => targetBtn.classList.remove("btn-active"), 50);
    }
  }

  function handleJumpToPage() {
    // If the page would go below 1 or above the max page, don't change the page
    if (inputValue < 1) {
      isError = true;
      return;
    } else if (inputValue > Math.ceil(totalHits / itemsPerPage)) {
      isError = true;
      return;
    }

    isError = false;
    currentPage = inputValue;
  }

  $: if (currentPage > Math.ceil(totalHits / itemsPerPage)) {
    currentPage = Math.ceil(totalHits / itemsPerPage);
  } else if (currentPage < 1) {
    currentPage = 1;
  }
</script>

<svelte:window on:keydown={handleKey} />

<div class="flex items-center justify-center w-full relative">
  <div class="mt-2 absolute left-0">
    Rows per page:
    <select
      class="select select-bordered select-sm"
      bind:value={itemsPerPage}
      on:change={() => (currentPage = 1)}
    >
      {#each pageSizeOptions as option}
        <option value={option}>{option}</option>
      {/each}
    </select>
  </div>

  <div class="mt-2 flex shrink-0 items-center justify-center p-2">
    <button
      class="btn btn-square btn-ghost btn-sm rounded-r-none"
      class:invisible={currentPage <= 1}
      on:click={() => (currentPage = 1)}
    >
      <Icon icon="iconoir:fast-arrow-left" />
    </button>
    <button
      id="prevBtn"
      class="btn btn-square btn-ghost btn-sm rounded-l-none"
      class:invisible={currentPage <= 1}
      on:click={() => (currentPage = currentPage - 1)}
    >
      <Icon icon="iconoir:nav-arrow-left" />
    </button>

    <p class="ml-2 mr-1 text-sm">Page</p>
    <div class="join">
      <input
        class="input input-sm join-item input-bordered pr-0"
        bind:value={inputValue}
        on:keydown={(e) => e.which === 13 && handleJumpToPage()}
        type="number"
        min="1"
        max={Math.ceil(totalHits / itemsPerPage)}
      />

      <button
        class="btn btn-square btn-primary join-item btn-sm"
        on:click={() => handleJumpToPage()}
      >
        Go
      </button>
    </div>
    <p class="ml-1 mr-2 text-sm">of {Math.ceil(totalHits / itemsPerPage)}</p>

    <button
      id="nextBtn"
      class="btn btn-square btn-ghost btn-sm rounded-r-none"
      class:invisible={currentPage >= Math.ceil(totalHits / itemsPerPage)}
      on:click={() => (currentPage = currentPage + 1)}
    >
      <Icon icon="iconoir:nav-arrow-right" />
    </button>

    <button
      class="btn btn-square btn-ghost btn-sm rounded-l-none"
      class:invisible={currentPage >= Math.ceil(totalHits / itemsPerPage)}
      on:click={() => (currentPage = Math.ceil(totalHits / itemsPerPage))}
    >
      <Icon icon="iconoir:fast-arrow-right" />
    </button>
  </div>

  <div></div>
</div>

<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
</style>
