<script>
  import { onDestroy, onMount, tick } from "svelte";
  import { link, querystring } from "svelte-spa-router";

  import StreamInfo from "../components/new-stream/StreamInfo.svelte";

  import Icon from "@iconify/svelte";
  import ActionConfirmation from "../components/ActionConfirmation.svelte";
  import SelectDictionaries from "../components/new-stream/SelectDictionaries.svelte";
  import { maximised } from "../stores";
  import { deleteStream } from "./reportFunctions";

  export let params = null;

  let report_id = 0;
  let deletionInProgress = false;

  let streamName = "";
  let streamDesc = "";

  onMount(async () => {
    report_id = params.report_id;

    await tick();

    if ($querystring && $querystring === "step=1") {
      step = 1;
      stepsCompletedTill = 2;
    }

    $maximised = false;
  });
  onDestroy(() => ($maximised = true));

  const steps = ["Report Information", "Options"];
  let step = 0;
  let stepsCompletedTill = -1;

  // edit or new
  let streamType = window.location.hash.split("/")[2];
</script>

<aside
  class="bg-base-content/[5%] fixed bottom-0 top-16 w-60 shrink-0 p-4"
  style="left: {$maximised ? '220px' : '50px'}"
>
  <h1 class="text-2xl font-semibold">
    {streamType === "new" ? "Create" : "Edit"} Spelling Report
  </h1>

  <ul class="steps steps-vertical">
    {#each steps as steps, i}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <li
        class="step"
        class:step-primary={step >= i}
        class:pointer-events-none={stepsCompletedTill < i - 1}
        on:click={() => (step = i)}
      >
        {steps}
      </li>
    {/each}
  </ul>
</aside>

<main class="-mb-16 pl-[240px]">
  <div class="breadcrumbs text-sm">
    <ul>
      <li>
        <a href="/spelling" use:link>Spelling</a>
      </li>
      <li>{streamName}</li>
      <li>{streamType === "new" ? "Create" : "Edit"} Spelling Report</li>
      <li>{steps[step]}</li>
    </ul>
  </div>

  <div class="mt-4 flex max-w-xl items-center justify-between gap-4">
    <h1 class="text-3xl font-semibold">{steps[step]}</h1>

    {#if step === 0}
      <div class="dropdown dropdown-end">
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <label tabindex="0" class="btn btn-square btn-ghost btn-sm" for="">
          <Icon icon="iconoir:more-horiz" class="text-xl" />
        </label>
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <ul
          tabindex="0"
          class="menu dropdown-content bg-base-100 z-10 m-1 w-48 rounded border p-2"
        >
          <li>
            <!-- svelte-ignore missing-declaration -->
            <button on:click={() => deletionConfirmationModal.showModal()}>
              <Icon icon="iconoir:trash" class="text-xl" /> Delete Spelling Report
            </button>
          </li>
        </ul>
      </div>

      <!-- svelte-ignore missing-declaration -->
      <ActionConfirmation
        modalId="deletionConfirmationModal"
        message="Are you sure you want to delete this report?"
        onConfirm={async () => {
          deletionInProgress = true;
          deleteStream(report_id, deletionInProgress, true);
          deletionInProgress = false;
          deletionConfirmationModal.close();
        }}
        onCancel={() => deletionConfirmationModal.close()}
        confirmText="Delete"
        cancelText="Cancel"
        showLoadingSpinner={true}
        loading={deletionInProgress}
      />
    {/if}
  </div>

  {#if step === 0}
    <StreamInfo
      bind:step
      bind:stepsCompletedTill
      bind:streamName
      bind:streamDesc
      {report_id}
      {streamType}
      type={"spelling"}
    />
  {:else if step === 1}
    <SelectDictionaries bind:step {report_id} />
  {/if}
</main>
