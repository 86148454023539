<script>
  import Icon from "@iconify/svelte";
  import { fetchPatch } from "../../helpers";

  export let name = "";
  export let type;
  export let text;
  export let step_id;

  let editing = false;
  let new_text = text;

  async function updateText() {
    const res = await fetchPatch(`/workflow-step/${step_id}/${type}`, {
      text: new_text,
    });
    if (!res.success) {
      console.log("Updating failed");
      return;
    }

    text = new_text;
    editing = false;
  }
</script>

<div>
  <p class="flex flex-nowrap justify-between gap-2 text-sm font-medium">
    {name}
    <button
      class="btn btn-square btn-ghost btn-xs ml-2
        {editing ? 'pointer-events-none invisible' : ''}"
      on:click={() => (editing = true)}
    >
      <Icon icon="iconoir:edit-pencil" />
    </button>
  </p>
  {#if editing}
    <div class="join w-full">
      <input
        type="text"
        bind:value={new_text}
        placeholder={name}
        class="input input-sm join-item input-bordered text-xs"
      />
      <button
        class="btn btn-square btn-ghost join-item btn-sm border-base-content/20"
        on:click={() => (editing = false)}
      >
        <Icon icon="iconoir:cancel" />
      </button>
      <button
        class="btn btn-square btn-primary join-item btn-sm"
        on:click={updateText}
      >
        <Icon icon="iconoir:save-floppy-disk" />
      </button>
    </div>
  {:else}
    <span class="text-primary flex items-center gap-1 text-xs">{text}</span>
  {/if}
</div>
