<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { fetchPatch } from "../../helpers";
  import { HitBulkAction } from "../../lib/interfaces/Hit.interface";
  import { arrayRange, isEmpty } from "../../lib/utils/GenericUtils";
  import { org_users } from "../../stores";
  import Modal from "../Modals/Modal.svelte";
  import LogoLoader from "../LogoLoader.svelte";

  const dispatch = createEventDispatcher();

  export let itemsPerPage = 10;
  export let currentPage = 1;
  export let data = [];
  export let originalData = [];
  export let openedTab = "Open";
  export let selectedRulesId;
  export let selectedDataTypes;
  export let selectedVendors;
  export let selectedSocialTypes;
  export let selectedHits;
  export let reportId;
  export let columnToSortBy;
  export let showGroupedTable;
  export let forceStateUpdate;
  export let numberOfSelectedHits;
  export let statusCounts;
  export let hitSearchText;
  export let hitSearchSettings;

  const currentPageIndexes = arrayRange(
    currentPage - 1,
    currentPage * itemsPerPage - 1,
    1,
  );
  let enabledUsers = {};

  let usersToAssign = {};
  let unassignAllBeforeFlag = false;
  let filtered = false;
  let loading = false;

  function getUserIdFromFullName(fullname) {
    const firstName = fullname.split(" ")[0];
    const lastName = fullname.split(" ")[1];

    const user = $org_users.find(
      (user) => user.first_name === firstName && user.last_name === lastName,
    );

    return user.id;
  }

  function unassignAllUsersFromSelectedHit() {
    data = [
      ...data.map((entry) => {
        if (entry["selected"]) {
          entry["assigned_users"] = [];
        }

        return entry;
      }),
    ];
  }

  function setSelectedAttributeToValue(attribute, value, toRefresh = true) {
    let newData = [];

    for (let i = 0; i < originalData.length; i++) {
      const entry = originalData[i];
      //const selectedHitsArr = selectedHits ? Array.from(selectedHits) : [];

      if (entry["selected"]) {
        if (attribute === "assign") {
          if (
            entry["assigned_users"].some(
              (user) => user === getUserIdFromFullName(value),
            )
          ) {
            newData = [...newData, entry];
            continue;
          }

          entry["assigned_users"].push(getUserIdFromFullName(value));

          newData = [...newData, entry];
          continue;
        } else if (attribute === "unassign") {
          if (
            !entry["assigned_users"].some(
              (user) => user === getUserIdFromFullName(value),
            )
          ) {
            newData = [...newData, entry];
            continue;
          }

          entry["assigned_users"] = entry["assigned_users"].filter(
            (user) => user !== getUserIdFromFullName(value),
          );

          newData = [...newData, entry];
          continue;
        }

        entry["hit"][attribute] = value;
      }

      entry["selected"] = false;
      newData = [...newData, entry];
    }

    data = [...newData];

    if (toRefresh) {
      dispatch("refresh", data);
    }
  }

  let currentSelections = [];
  let allResultsOnPageSelected = false;

  function arraysContainSameValues(arr1, arr2) {
    return arr2.every((v) => arr1.includes(v));
  }

  $: if (data) {
    allResultsOnPageSelected = currentPageIndexes.every((idx) =>
      selectedHits.has(idx),
    );
  }

  onMount(() => {
    $org_users.forEach((user) => {
      if (!user.is_deleted) {
        enabledUsers[user.first_name + " " + user.last_name] = true;
      }
    });
  });
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div
  class="flex shrink-0 flex-wrap items-center gap-2 rounded"
  transition:slide
>
  Bulk Actions:

  {#if openedTab == "Open" || openedTab == "Closed"}
    <button
      class="btn btn-primary btn-sm"
      on:click={async () => {
        loading = true;
        await fetchPatch("/hit/bulk", {
          indexes: selectedHits ? Array.from(selectedHits) : [],
          action: HitBulkAction.FLAG,
          reportId: reportId,
          inReport: openedTab,
          selectedRulesId,
          selectedDataTypes,
          selectedVendors,
          selectedSocialTypes,
          sortBy: columnToSortBy,
          isGroupedHitsQuery: showGroupedTable,
          hitSearchCaseSensitive: hitSearchSettings.isCaseSensitive.enabled,
          hitSearchText: isEmpty(hitSearchText) ? null : hitSearchText,
        });
        setSelectedAttributeToValue("in_report", "Flagged");
        selectedHits = new Set();
        selectedHits = selectedHits;
        forceStateUpdate = await forceStateUpdate;
        loading = false;
      }}
    >
      <Icon icon="iconoir:dash-flag" class="text-lg" /> Flag
    </button>
  {/if}

  {#if openedTab == "Open" || openedTab == "Closed"}
    <button
      class="btn btn-primary btn-sm"
      on:click={() => {
        document.getElementById("flag_and_assign_modal").showModal();
      }}
    >
      <Icon icon="iconoir:group" /> Flag & Assign
    </button>
  {/if}

  {#if openedTab == "Open" || openedTab == "Flagged"}
    <button
      class="btn btn-sm {openedTab == 'Flagged' ? 'btn-primary' : ''}"
      on:click={async () => {
        loading = true;
        await fetchPatch("/hit/bulk", {
          indexes: selectedHits ? Array.from(selectedHits) : [],
          action: HitBulkAction.CLOSE,
          reportId: reportId,
          inReport: openedTab,
          selectedRulesId,
          selectedDataTypes,
          selectedVendors,
          selectedSocialTypes,
          sortBy: columnToSortBy,
          isGroupedHitsQuery: showGroupedTable,
          hitSearchCaseSensitive: hitSearchSettings.isCaseSensitive.enabled,
          hitSearchText: isEmpty(hitSearchText) ? null : hitSearchText,
        });
        setSelectedAttributeToValue("in_report", "Closed");
        selectedHits = new Set();
        selectedHits = selectedHits;
        forceStateUpdate = await forceStateUpdate;
        loading = false;
      }}
    >
      Close
    </button>
  {/if}

  {#if openedTab == "Flagged"}
    <button
      class="btn btn-primary btn-sm"
      on:click={() => {
        document.getElementById("assign_modal").showModal();
      }}
    >
      <Icon icon="iconoir:group" /> Assign
    </button>
  {/if}

  {#if openedTab == "Flagged" || openedTab == "Closed" || openedTab == "Removed"}
    <button
      class="btn btn-sm"
      on:click={async () => {
        loading = true;
        await fetchPatch("/hit/bulk", {
          indexes: selectedHits ? Array.from(selectedHits) : [],
          action: HitBulkAction.OPEN,
          reportId: reportId,
          inReport: openedTab,
          selectedRulesId,
          selectedDataTypes,
          selectedVendors,
          selectedSocialTypes,
          sortBy: columnToSortBy,
          isGroupedHitsQuery: showGroupedTable,
          hitSearchCaseSensitive: hitSearchSettings.isCaseSensitive.enabled,
          hitSearchText: isEmpty(hitSearchText) ? null : hitSearchText,
        });
        setSelectedAttributeToValue("in_report", "Open");
        selectedHits = new Set();
        selectedHits = selectedHits;
        forceStateUpdate = await forceStateUpdate;
        loading = false;
      }}
    >
      Re-Open
    </button>
  {/if}

  <span class="ml-auto">Selected ({numberOfSelectedHits})</span>

  <button
    class="btn btn-primary btn-sm"
    disabled={allResultsOnPageSelected}
    on:click={() => {
      filtered = false;
      dispatch("selectAllOnPage");
    }}
  >
    Select All (page)
  </button>
  <button
    class="btn btn-primary btn-sm"
    disabled={numberOfSelectedHits === statusCounts[openedTab]}
    on:click={() => {
      filtered = true;
      dispatch("selectAllFiltered");
    }}
  >
    Select All (filtered)
  </button>

  <button
    class="btn btn-sm"
    disabled={numberOfSelectedHits == 0}
    on:click={() => dispatch("deselectAll")}
  >
    Deselect All
  </button>
</div>

{#if loading}
  <div
    class="bg-base-100/50 fixed inset-0 z-20 flex items-center justify-center backdrop-blur-sm"
  >
    <h1 class="flex items-center gap-4 text-4xl font-semibold">
      <LogoLoader size="2.5rem" />
      Performing Bulk Actions...
    </h1>
  </div>
{/if}

<!-- svelte-ignore a11y-label-has-associated-control -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->

<Modal modalId="flag_and_assign_modal" cornerCloseButton={false}>
  <div class="flex flex-wrap items-center gap-2 rounded-lg bg-gray-200 p-2">
    {#each Object.keys(usersToAssign) as userToAssign}
      {#if usersToAssign[userToAssign]}
        <button
          class="btn-outline-secondary btn btn-sm"
          on:click={() => (usersToAssign[userToAssign] = false)}
        >
          <Icon icon="ic:baseline-close" />
          {userToAssign}
        </button>
      {/if}
    {/each}

    <div class="dropdown">
      <label tabindex="0" class="btn btn-primary btn-sm">
        <Icon icon="ic:baseline-group-add" class="text-lg" /> Add User
      </label>
      <ul
        tabindex="0"
        class="menu dropdown-content rounded-box bg-base-100 z-20 m-1 block max-h-96 w-52 overflow-y-auto p-2 shadow"
      >
        {#each Object.keys(enabledUsers) as user}
          {#if !usersToAssign[user]}
            <li>
              <button on:click={() => (usersToAssign[user] = true)}>
                {user}
              </button>
            </li>
          {/if}
        {/each}
      </ul>
    </div>
  </div>

  <div class="mt-16 flex items-center justify-between">
    <label class="label cursor-pointer">
      <input
        type="checkbox"
        bind:checked={unassignAllBeforeFlag}
        class="checkbox-primary checkbox"
      />
      <span class="label-text ml-2">Clear current assignees</span>
    </label>

    <form method="dialog" class="flex justify-end gap-2">
      <button class="btn-outline-primary btn btn-sm">Cancel</button>

      <button
        class="btn btn-primary btn-sm"
        on:click={async () => {
          if (unassignAllBeforeFlag) unassignAllUsersFromSelectedHit();

          Object.keys(usersToAssign).forEach((user) => {
            if (usersToAssign[user])
              setSelectedAttributeToValue("assign", user, false);
          });

          const usersToAssignIds = Object.keys(usersToAssign).flatMap(
            (user) => {
              if (usersToAssign[user]) return getUserIdFromFullName(user);
              return [];
            },
          );

          loading = true;
          await fetchPatch("/hit/bulk", {
            indexes: selectedHits ? Array.from(selectedHits) : [],
            action: HitBulkAction.FLAGASSIGN,
            reportId: reportId,
            inReport: openedTab,
            selectedRulesId,
            selectedDataTypes,
            selectedVendors,
            selectedSocialTypes,
            sortBy: columnToSortBy,
            isGroupedHitsQuery: showGroupedTable,
            hitSearchCaseSensitive: hitSearchSettings.isCaseSensitive.enabled,
            hitSearchText: isEmpty(hitSearchText) ? null : hitSearchText,
            user_ids: usersToAssignIds,
            reset_assignment: unassignAllBeforeFlag,
          });
          setSelectedAttributeToValue("in_report", "Flagged");
          selectedHits = new Set();
          selectedHits = selectedHits;
          forceStateUpdate = await forceStateUpdate;
          loading = false;

          if (unassignAllBeforeFlag) {
            data = [
              ...data.map((entry) => {
                if (entry["selected"]) entry["selected"] = false;
                return entry;
              }),
            ];
          }
        }}
      >
        <Icon icon="iconoir:dash-flag" class="text-lg" /> Flag & Assign
      </button>
    </form>
  </div>
</Modal>

<!-- svelte-ignore a11y-label-has-associated-control -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->

<Modal modalId="assign_modal" cornerCloseButton={false}>
  <div class="flex flex-wrap items-center gap-2 rounded-lg bg-gray-200 p-2">
    {#each Object.keys(usersToAssign) as userToAssign}
      {#if usersToAssign[userToAssign]}
        <button
          class="btn-outline-secondary btn btn-sm"
          on:click={() => (usersToAssign[userToAssign] = false)}
        >
          <Icon icon="ic:baseline-close" />
          {userToAssign}
        </button>
      {/if}
    {/each}

    <div class="dropdown">
      <label tabindex="0" class="btn btn-primary btn-sm m-1">
        <Icon icon="ic:baseline-group-add" class="text-lg" /> Add User
      </label>
      <ul
        tabindex="0"
        class="menu dropdown-content rounded-box bg-base-100 z-20 block max-h-96 w-52 overflow-y-auto p-2 shadow"
      >
        {#each Object.keys(enabledUsers) as user}
          {#if !usersToAssign[user]}
            <li>
              <button on:click={() => (usersToAssign[user] = true)}>
                {user}
              </button>
            </li>
          {/if}
        {/each}
      </ul>
    </div>
  </div>

  <div class="mt-16 flex items-center justify-between">
    <div class="form-control">
      <label class="label cursor-pointer">
        <input
          type="checkbox"
          bind:checked={unassignAllBeforeFlag}
          class="checkbox-primary checkbox"
        />
        <span class="label-text ml-2">Clear current assignees</span>
      </label>
    </div>

    <form method="dialog" class="modal-backdrop z-10">
      <div class="flex justify-end gap-2">
        <button class="btn-outline-primary btn btn-sm">Cancel</button>

        <button
          class="btn btn-primary btn-sm"
          on:click={async () => {
            if (unassignAllBeforeFlag) unassignAllUsersFromSelectedHit();

            const usersToAssignIds = Object.keys(usersToAssign).flatMap(
              (user) => {
                if (usersToAssign[user]) return getUserIdFromFullName(user);
                return [];
              },
            );

            loading = true;
            await fetchPatch("/hit/bulk", {
              indexes: selectedHits ? Array.from(selectedHits) : [],
              action: HitBulkAction.ASSIGN,
              reportId: reportId,
              inReport: openedTab,
              selectedRulesId,
              selectedDataTypes,
              selectedVendors,
              selectedSocialTypes,
              sortBy: columnToSortBy,
              isGroupedHitsQuery: showGroupedTable,
              hitSearchCaseSensitive: hitSearchSettings.isCaseSensitive.enabled,
              hitSearchText: isEmpty(hitSearchText) ? null : hitSearchText,
              user_ids: usersToAssignIds,
              reset_assignment: unassignAllBeforeFlag,
            });

            Object.keys(usersToAssign).forEach((user) => {
              if (usersToAssign[user])
                setSelectedAttributeToValue("assign", user, false);
            });
            selectedHits = new Set();
            selectedHits = selectedHits;
            forceStateUpdate = await forceStateUpdate;
            loading = false;

            if (unassignAllBeforeFlag) {
              data = [
                ...data.map((entry) => {
                  if (entry["selected"]) entry["selected"] = false;
                  return entry;
                }),
              ];
            }
          }}
        >
          <Icon icon="iconoir:dash-flag" class="text-lg" /> Assign
        </button>
      </div>
    </form>
  </div>
</Modal>
