<script>
  import UserSettings from "./UserSettings.svelte";
  import OrgSettings from "./OrgSettings.svelte";

  let activeSection = "orgsettings";

  function setSection(section) {
    activeSection = section;
  }
</script>

<svelte:head>
  <title>Org Settings - Haast</title>
</svelte:head>

<div class="flex max-w-screen-xl flex-col gap-6">
  <h1 class="my-4 text-3xl font-semibold">Settings</h1>
  <div
    class="tabs bg-base-200 relative mb-2 shrink-0 overflow-hidden rounded py-4"
  >
    <button
      class="tab font-semibold"
      class:text-primary={activeSection === "orgsettings"}
      on:click={() => setSection("orgsettings")}>Organisation Settings</button
    >
    <button
      class="tab font-semibold"
      class:text-primary={activeSection === "usersettings"}
      on:click={() => setSection("usersettings")}>Account Settings</button
    >
    <div
      class="slider bg-primary absolute bottom-0 left-0 h-0.5 w-1/2 transition-all"
      style:translate={activeSection === "orgsettings"
        ? "0%"
        : activeSection === "usersettings"
          ? "100%"
          : "200%"}
    ></div>
  </div>

  {#if activeSection === "usersettings"}
    <UserSettings />
  {:else}
    <OrgSettings />
  {/if}
</div>
