<script>
  //https://codepen.io/kachibito/pen/gZOxvJ
  import Step from "./Step.svelte";
  import { reference_fields } from "./constants";

  export let vendors = [];
  export let selectedChannels = [];
  export let isViewing = false;

  export let query = [
    {
      id: 1,
      not: false,
      type: "relation",
      relation: "AND",
      delete: false,
      nested: [
        {
          id: 2,
          field: "text",
          type: "clause",
          not: false,
          operation: "exactly contains",
          value: "Haast",
          slop: 1,
          term: true,
          regex: false,
          delete: false,
          options: [],
        },
        {
          id: 3,
          field: "text",
          type: "clause",
          not: false,
          operation: "exactly contains",
          value: "Haast",
          slop: 1,
          term: true,
          regex: false,
          delete: false,
          options: [],
        },
      ],
    },
  ];

  export let fields = reference_fields;
  export let searchModule = "live";
  fields = fields.filter((field) => {
    return field.scope.includes(searchModule);
  });
</script>

<div class="rounded bg-base-100">
  {#each query as elem}
    <Step
      {fields}
      bind:query={elem}
      {isViewing}
      {vendors}
      {selectedChannels}
      delBtn={false}
    />
  {/each}
</div>
