import App from "./App.svelte";
import "./app.pcss";

import * as Sentry from "@sentry/svelte";

const environment: string = import.meta.env.VITE_APP_ENVIRONMENT;
const sentryDSN: string | undefined = import.meta.env.VITE_APP_SENTRY_DSN;

if (
  ["production", "production-uk", "staging", "zurich"].includes(
    environment.toLowerCase(),
  ) &&
  sentryDSN
) {
  Sentry.init({
    dsn: sentryDSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: environment,
    release: "haast-webapp@2.0.8.4",
  });
}

const app = new App({
  target: document.getElementById("app") as HTMLElement,
});

export default app;
