<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import {
    buildExamples,
    buildSearchSources,
    fetchPost,
    saveLiveRule,
  } from "../../helpers";
  import { RuleType } from "../../lib/interfaces/Rule.interface";
  import { saveOneRule } from "../../lib/utils/RuleUtils";
  import { org_id } from "../../stores";
  import AssetRuleBuilder from "../AssetReview/AssetRuleBuilder.svelte";
  import AssetRuleTypeSelector from "../AssetReview/AssetRuleTypeSelector.svelte";
  import ConditionalAssetRuleBuilder from "../AssetReview/ConditionalAssetRuleBuilder.svelte";
  import Modal from "../Modals/Modal.svelte";
  import Rule from "../Rule.svelte";
  import RuleSetSelector from "./RuleSetSelector.svelte";

  export let ruleToCopy;
  export let dataTypes = [];
  export let ruleSets = [];

  const dispatch = createEventDispatcher();

  let savingRule = false;

  async function saveRule(e) {
    savingRule = true;
    let ruleResponse;
    let ruleSetResponse;

    if (ruleToCopy.type === RuleType.LIVE) {
      ruleResponse = await saveLiveRule(ruleToCopy, $org_id, true);
    } else if (ruleToCopy.type === RuleType.ASSET) {
      if (!ruleToCopy.is_conditional) {
        ruleResponse = await saveOneRule(ruleToCopy, $org_id, true);
      } else {
        // below just for conditional Rules
        const conditionalRule = e.detail;

        const rulesWithExamples = [
          conditionalRule.ifRule,
          ...conditionalRule.thenRules,
        ].map((r) => ({
          ...r,
          examples: buildExamples(r),
          search_sources: buildSearchSources(r.search_sources),
          rule_type: "asset",
          report_id: $org_id,
        }));

        ruleResponse = await fetchPost("/rule", {
          rules: rulesWithExamples,
          report_id: $org_id,
        });
      }
    }

    if (!ruleResponse.success) {
      toast.error(ruleResponse.message);
      savingRule = false;

      return;
    }

    if (selectedRuleSetId)
      ruleSetResponse = await fetchPost("/ruleset/add-to-ruleset", {
        rule_set_id: selectedRuleSetId,
        rule_id: ruleResponse.rules[0].id,
      });

    if (ruleResponse.success)
      toast.success(
        `Rule(${ruleToCopy.name}) was saved successfully${ruleSetResponse?.success ? " and added to checklist" : "."}`,
      );

    dispatch("updateRules");
    savingRule = false;
    closeCopyRuleModal();
  }

  const closeCopyRuleModal = () => {
    ruleToCopy = null;
    document.querySelector(`#ruleCopy`).close();
  };

  let selectedRuleSetId = ruleToCopy.is_industry_rule === true ? null : ruleToCopy.rule_set_details[0]?.id;

  let newRuleStep = 2;
  let editingSavedRule = true;
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore missing-declaration -->
<Modal modalId="ruleCopy" size="xl" on:close={closeCopyRuleModal}>
  <h3 class="text-xl font-semibold">Copy saved rule</h3>

  {#if ruleToCopy.type === RuleType.LIVE}
    <RuleSetSelector
      bind:ruleSets
      bind:selectedRuleSetId
      on:updateRuleSets={() => dispatch("updateRuleSets")}
    />
    <Rule
      index={ruleToCopy.index}
      loaded={ruleToCopy.loaded}
      bind:d_types={dataTypes}
      runRule={() => {}}
      on:saveRule={saveRule}
      deleteRule={() => {}}
      bind:data={ruleToCopy}
      bind:defaultRulePriority={ruleToCopy.default_hit_priority}
    />
  {:else if ruleToCopy.type === RuleType.ASSET}
    {#if newRuleStep === 1}
      <AssetRuleTypeSelector
        bind:value={ruleToCopy.search_type}
        bind:rule={ruleToCopy}
      />
      <button
        on:click={() => (newRuleStep = 2)}
        class="btn btn-primary btn-sm mt-2"
        class:btn-diabled={!ruleToCopy.search_type}
        class:tooltip={!ruleToCopy.search_type}
        data-tip="Please select a rule type to continue"
      >
        Select
      </button>
    {:else if newRuleStep === 2}
      <RuleSetSelector
        bind:ruleSets
        bind:selectedRuleSetId
        on:updateRuleSets={() => dispatch("updateRuleSets")}
      />
      {#if ruleToCopy.is_conditional}
        <ConditionalAssetRuleBuilder
          editingSavedRule={true}
          conditionalRuleData={ruleToCopy}
          on:removeRule={closeCopyRuleModal}
          on:saveConditionalRule={saveRule}
          loading={savingRule}
        />
      {:else}
        <AssetRuleBuilder
          bind:rule={ruleToCopy}
          on:removeRule={closeCopyRuleModal}
          on:saveRule={saveRule}
          on:changeRuleType={() => confirmRuleTypeChange?.showModal()}
          bind:editingSavedRule
          bind:savingRule
        />

        <Modal
          modalId="confirmRuleTypeChange"
          bottomButtons={{
            show: true,
            primaryText: "Switch rule type",
            secondaryText: "Cancel",
            primaryAction: () => {
              newRuleStep = 1;
              confirmRuleTypeChange.close();
            },
            secondaryAction: () => confirmRuleTypeChange.close(),
          }}
          containerClasses="flex flex-col gap-4 items-center"
          cornerCloseButton={false}
        >
          <span
            class="bg-error flex h-12 w-12 items-center justify-center rounded-full"
          >
            <Icon icon="iconoir:warning-triangle" class="text-xl text-white" />
          </span>

          <h3 class="font-semibold">
            Are you sure you want to switch rule type?
          </h3>
          <p class="text-base-content/70 text-center text-sm">
            If you switch rule type,
            <span class="font-semibold">you will lose your progress</span>
            with the current rule type.
          </p>
        </Modal>
      {/if}
    {/if}
  {/if}
</Modal>
