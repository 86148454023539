<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPatch } from "../../helpers";
  import Modal from "./Modal.svelte";

  const dispatch = createEventDispatcher();

  export let ruleSet;
  export let ruleSets;
  export let updateRules = false;

  const maximumNameLength = 64;

  let checklistName = "";
  let checklistDescription = "";
  let duplicateDetected = false;
  let exceedsMaxLength = false;

  async function editChecklist(id) {
    if (!id) toast.error("Something went wrong while editing the checklist.");

    if (
      ruleSets.some(
        (rs) =>
          rs.name === checklistName &&
          rs.description === checklistDescription &&
          rs.id !== id,
      )
    ) {
      toast.error(
        "Could not edit checklist details, one already exists with the same name and description.",
      );

      duplicateDetected = true;
      return;
    }

    try {
      const res = await fetchPatch("/ruleset/edit", {
        id: id,
        name: checklistName,
        description: checklistDescription,
        rules: updateRules ? ruleSet.rules : [],
      });

      if (!res.success) {
        throw new Error(
          `API request to edit RuleSet with Id(${id}) was not successful.`,
        );
      }

      ruleSet.rule_set.name = checklistName;
      ruleSet.description = checklistDescription;
      ruleSet = ruleSet;
      dispatch("updateRuleSets");
      toast.success("Checklist has been edited successfully.");
      handleCloseModal();
    } catch (e) {
      toast.error("Something went wrong while editing the checklist.");
      return;
    }
  }

  function handleDuplicateDetected() {
    if (duplicateDetected) duplicateDetected = !duplicateDetected;
  }

  function handleCloseModal() {
    checklistName = "";
    checklistDescription = "";
    document.querySelector(`#edit-checklist-${ruleSet.rule_set?.id}`)?.close();
  }

  function handleMaxLength() {
    if (checklistName.length > maximumNameLength) exceedsMaxLength = true;
    else exceedsMaxLength = false;
  }

  function truncateIfExceedMaxLength(name) {
    if (name.length > maximumNameLength)
      return `${name.slice(0, maximumNameLength - 3)}...`;

    return name;
  }

  onMount(() => {
    checklistName = truncateIfExceedMaxLength(ruleSet.rule_set?.name);
    checklistDescription = ruleSet.rule_set?.description;
  });

  $: handleDuplicateDetected(), [checklistName, checklistDescription];
</script>

<Modal
  modalId="edit-checklist-{ruleSet.rule_set?.id}"
  on:close={handleCloseModal}
  cornerCloseButton={false}
  cornerBackButton={true}
>
  <span
    class="bg-base-200 mx-auto my-4 flex h-12 w-12 items-center justify-center rounded-full"
  >
    <Icon icon="iconoir:google-docs" class="text-primary text-xl" />
  </span>

  <h3 class="text-center font-semibold">Edit Checklist</h3>

  <div class="flex flex-col items-center gap-2">
    <div class="w-full">
      <label class="label" for="checklist_name">
        <span class="label-text">Checklist name</span>
      </label>
      <input
        type="text"
        bind:value={checklistName}
        placeholder="Checklist Name"
        class="input input-bordered w-full
          {duplicateDetected || checklistName.length < 1 || exceedsMaxLength
          ? 'input-error'
          : ''}"
        id="checklist_name"
        maxlength={maximumNameLength}
        required
        on:change={() => {
          if (
            ruleSets.some(
              (rs) =>
                rs.name === checklistName &&
                rs.description === checklistDescription &&
                rs.id !== ruleSet.rule_set?.id,
            )
          )
            duplicateDetected = true;
          else duplicateDetected = false;

          if (checklistName.length > 64) exceedsMaxLength = true;
          else exceedsMaxLength = false;
        }}
      />
    </div>
    <div class="w-full">
      <label class="label" for="checklist_description">
        <span class="label-text">Checklist description</span>
      </label>
      <input
        type="text"
        bind:value={checklistDescription}
        placeholder="Checklist Description"
        class="input input-bordered w-full {duplicateDetected
          ? 'input-error'
          : ''}"
        id="checklist_description"
        on:change={() => {
          if (
            ruleSets.some(
              (rs) =>
                rs.name === checklistName &&
                rs.description === checklistDescription &&
                rs.id !== ruleSet.rule_set?.id,
            )
          )
            duplicateDetected = true;
          else duplicateDetected = false;
        }}
      />
    </div>
  </div>

  <div
    class="mt-4 w-full"
    class:tooltip={checklistName.length < 1 || duplicateDetected}
    data-tip={checklistName.length < 1
      ? "The checklist cannot have an empty name."
      : duplicateDetected
        ? "A checklist already exists with the same name and description"
        : exceedsMaxLength
          ? "The checklist name exceeds the maximum length."
          : ""}
  >
    <button
      class="btn btn-primary w-full"
      disabled={duplicateDetected ||
        checklistName.length < 1 ||
        exceedsMaxLength}
      on:click={() => editChecklist(ruleSet.rule_set?.id)}
    >
      Confirm Edits
    </button>
  </div>

  <button
    class="btn btn-ghost text-primary mt-2 w-full"
    on:click={handleCloseModal}
  >
    Cancel
  </button>
</Modal>
