<script>
  import EditableWorkflowVisualizer from "./EditableWorkflowVisualizer.svelte";

  export let selectedWorkflow = {
    id: 10,
    name: "",
    description: "",
    workflow_steps: [
      {
        id: 10,
        name: "",
        description: "",
        position: 1,
        teams: [],
        users: [],
      },
    ],
  };
  export let report_id = null;
</script>

<article class="flex w-full flex-col gap-4">
  <div>
    <div class="form-control">
      <label class="label" for="rule_name">
        <span class="label-text">Workflow Name</span>
      </label>
      <input
        class="input input-bordered w-full"
        id="workflow_name"
        placeholder="Add a workflow name"
        bind:value={selectedWorkflow.name}
        required
        maxlength="63"
      />
    </div>
    <div class="w-full">
      <label class="label" for="workflow_description">
        <span class="label-text">Workflow Description</span>
      </label>
      <input
        class="input input-bordered w-full"
        id="workflow_description"
        placeholder="Add a workflow description"
        bind:value={selectedWorkflow.description}
        required
      />
    </div>
  </div>

  <div class="w-full rounded border py-2">
    <EditableWorkflowVisualizer {report_id} bind:selectedWorkflow />
  </div>
</article>
