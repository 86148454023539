<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import { isProperSite } from "../../../lib/utils/TimestampUtils";
  import PreviewLink from "../../PreviewLink.svelte";

  export let hitId = "";
  export let hitUrl = "";
  export let hitTitle = "";
  export let hitContentLink = null;
  export let searchText = "";
  export let searchSettings = {};
  export let hitPool = "";
  export let hitDataType = "";

  let shouldDisplayHitContentLink = false;
  let useContentLinkAsURL = false;
  let swapPreviewLinks = false;

  $: previewContentLink = hitContentLink;
  $: previewUrlLink = hitUrl;

  $: if (hitContentLink && hitPool) {
    shouldDisplayHitContentLink =
      hitPool.includes("website_image_text") ||
      hitPool.includes("website_document_text") ||
      hitDataType.toLowerCase().includes("timestamp") && isProperSite(hitContentLink) ;
  } else shouldDisplayHitContentLink = false; 
</script>

<div class="text-xs">
  {#if hitContentLink && shouldDisplayHitContentLink}
    <div class="tooltip block" data-tip={previewContentLink}>
      <div
        class="{searchText !== ''
          ? 'line-clamp-3'
          : 'truncate'} flex gap-1 hyphens-auto break-words"
      >
        {#if hitId && hitId.length > 0}
          <span class="hit-id">#{hitId}: </span>
        {/if}
        <PreviewLink
          href={previewContentLink}
          text={previewContentLink}
          bind:search={searchText}
          truncate={searchText == ""}
        />
      </div>
    </div>
    <div class="tooltip block" data-tip={previewUrlLink}>
      <div
        class="{searchText !== ''
          ? 'line-clamp-3'
          : 'truncate'} flex gap-1 hyphens-auto break-words"
      >
        on <PreviewLink
          href={previewUrlLink}
          text={previewUrlLink}
          bind:search={searchText}
          bind:shouldSearchTitle={searchSettings.searchByHitTitle}
          truncate={searchText == ""}
        />
      </div>
    </div>
  {:else}
    <div class="tooltip block" data-tip={hitTitle}>
      <div
        class="{searchText !== ''
          ? 'line-clamp-3'
          : 'truncate'} flex gap-1 hyphens-auto break-words"
      >
        {#if hitId && hitId.length > 0}
          <span class="hit-id">#{hitId}: </span>
        {/if}
        <PreviewLink
          href={hitUrl}
          text={hitTitle}
          bind:search={searchText}
          bind:shouldSearchTitle={searchSettings.searchByHitTitle}
          truncate={searchText == ""}
        />
      </div>
    </div>
  {/if}
</div>
