<script>
  import Icon from "@iconify/svelte";
  import { querystring, replace } from "svelte-spa-router";
  import LabelBean from "./LabelBean.svelte";
  import {labelToFilter} from '../../stores'

  export let labels = [];
  export let selectedLabels = [];
  export let unlabeledOnly = false;

  let search = "";

  let showUnlabeled = true;

  let loaded = false;

  function toggleLabel(label) {
    unlabeledOnly = false;

    if (selectedLabels.includes(label)) {
      selectedLabels = selectedLabels.filter((l) => l !== label);
    } else {
      selectedLabels = [...selectedLabels, label];
    }
  }

  function searchLabels(search) {
    showUnlabeled = "unlabeled".includes(search.toLowerCase());
    return labels.filter((label) =>
      label.name.toLowerCase().includes(search.toLowerCase()),
    );
  }

  $: if ($labelToFilter < 0) {
    selectedLabels = [];
  } else {
    selectedLabels = [labels.find((l) => l.id === $labelToFilter)];
    $labelToFilter = -1;
  }

  $: if (
    loaded &&
    (window.location.href.includes("stream") ||
      window.location.href.includes("review"))
  ) {
    let hash = window.location.href.includes("stream")
      ? "/streams"
      : "/reviews";

    if (selectedLabels.length > 0)
      hash += "?labels=" + selectedLabels.map((label) => label.name).join(",");

    if (unlabeledOnly)
      hash += (selectedLabels.length > 0 ? "&" : "?") + "unlabeledOnly=true";

    replace(hash);
  } else if (labels.length > 0) {
    const url = new URL(window.location.href);
    const hashParams = new URLSearchParams(url.hash.split("?")[1]);

    if (hashParams.has("labels")) {
      const labelsInURL = hashParams.get("labels").split(",");

      labelsInURL.forEach((labelName) => {
        const label = labels.find((l) => l.name === labelName);

        if (label) selectedLabels = [...selectedLabels, label];
      });
    }

    if (hashParams.has("unlabeledOnly")) unlabeledOnly = true;

    loaded = true;
  }

  // So clicking on "asset review" or "streams" in side navbar clears labels
  $: if ($querystring === "") selectedLabels = [];

  let filtersActive = false;
  $: filtersActive = selectedLabels.length > 0;

  let allFiltersActive = false;
  $: allFiltersActive = selectedLabels.length == labels.length;
</script>

<div class="inline-flex flex-wrap items-center gap-1">
  <div class="dropdown">
    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <label
      tabindex="0"
      class="btn btn-outline btn-primary btn-sm rounded-full"
      for=""
    >
      Filter by Label <Icon icon="iconoir:plus" class="text-lg" />
    </label>

    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <div
      tabindex="0"
      class="dropdown-content bg-base-100 z-30 mt-1 max-h-80 w-64 overflow-auto rounded border p-2 shadow"
    >
      <input
        class="input input-sm input-bordered w-full"
        type="text"
        placeholder="Filter labels"
        bind:value={search}
      />

      <ul class="menu menu-sm gap-2 p-0 pt-2">
        {#each searchLabels(search) as label}
          <!-- svelte-ignore a11y-click-events-have-key-events -->
          <li>
            <button
              class:bg-base-200={selectedLabels.includes(label)}
              on:click={() => toggleLabel(label)}
            >
              <LabelBean
                name={label.name}
                color={label.color}
                id={label.id}
                on:labelBeanClicked={() => toggleLabel(label)}
              />
              <Icon
                icon="iconoir:{selectedLabels.includes(label)
                  ? 'cancel'
                  : 'check'}"
                class="ml-auto"
              />
            </button>
          </li>
        {/each}
        {#if showUnlabeled}
          <li>
            <button
              class:bg-base-200={unlabeledOnly}
              on:click={() => {
                unlabeledOnly = !unlabeledOnly;
                if (unlabeledOnly) selectedLabels = [];
              }}
            >
              Unlabeled Only
              <Icon
                icon="iconoir:{unlabeledOnly ? 'cancel' : 'check'}"
                class="ml-auto"
              />
            </button>
          </li>
        {/if}
      </ul>
    </div>
  </div>

  {#if selectedLabels.length > 0}
    {#each selectedLabels as selectedlabel}
      <LabelBean
        name={selectedlabel.name}
        color={selectedlabel.color}
        id={selectedlabel.id}
        closeIcon={true}
        on:labelBeanClicked={() => toggleLabel(selectedlabel)}
      />
    {/each}
  {:else}
    <span class="text-base-content/70 my-auto ml-2 text-sm">
      No labels selected
    </span>
  {/if}
</div>
