<script>
  import { getInitials } from "../../../helpers";
  import { fullname } from "../../../stores";
  import IconBot from "../icons/IconBot.svelte";
  import IconUser from "../icons/IconUser.svelte";
  export let data = {};
  export let role = "bot";
  export let timestamp = null;

  //NOTE: commenting with hyperlinks is commented out
  // function splitMessage(message) {
  //     return [...message.split(/(http[s]?:\/\/[^\s]+)/).entries()];
  // }
</script>

{#each data.text.text as message}
  {#if role == "system"}
    <div class="chat chat-start">
      <IconBot />

      <div class="chat-header">
        Haast Bot
        <time class="text-xs opacity-50">{timestamp}</time>
      </div>
      <div class="chat-bubble chat-bubble-secondary text-black">{message}</div>
      <div class="chat-footer opacity-50">
        <span class="text-xs opacity-50">Delivered</span>
      </div>
    </div>
  {:else}
    <div class="chat chat-end">
      <IconUser initials={getInitials($fullname)} />
      <div class="chat-header">
        {$fullname}
        <time class="text-xs opacity-50">{timestamp}</time>
      </div>
      <div class="chat-bubble chat-bubble-primary text-black">{message}</div>
      <div class="chat-footer opacity-50">
        <span class="text-xs opacity-50">Delivered</span>
      </div>
    </div>
  {/if}
{/each}
