<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fetchDelete, fetchGet, fetchPost } from "../helpers";

  const dispatch = createEventDispatcher();

  export let disabled = false;
  export let hitId = -1;

  let allUsers = {};
  let notAssignedUserIds = [];
  let assignedUserIds = [];

  function updateAssignedStateOnTable() {
    dispatch("assign_state_updated", assignedUserIds);
  }

  async function assignUserToHit(userId) {
    // Pre-emptively update the state
    assignedUserIds = [...assignedUserIds, userId];
    notAssignedUserIds = notAssignedUserIds.filter((id) => id !== userId);

    try {
      const res = await fetchPost(`/hit/${hitId}/assign/${userId}`);
      if (res.success) {
        updateAssignedStateOnTable();
        return;
      } else throw new Error("Failed to assign user to hit");
    } catch (error) {
      assignedUserIds = assignedUserIds.filter((id) => id !== userId);
      notAssignedUserIds = [...notAssignedUserIds, userId];
    }
  }

  async function unassignUserFromHit(userId) {
    // Pre-emptively update the state
    assignedUserIds = assignedUserIds.filter((id) => id !== userId);
    notAssignedUserIds = [...notAssignedUserIds, userId];

    try {
      const res = await fetchDelete(`/hit/${hitId}/assign/${userId}`);
      if (res.success) {
        updateAssignedStateOnTable();
        return;
      } else throw new Error("Failed to unassign user from hit");
    } catch (error) {
      // Reset the state if the request fails
      assignedUserIds = [...assignedUserIds, userId];
      notAssignedUserIds = notAssignedUserIds.filter((id) => id !== userId);
    }
  }

  async function setupAssignDetails() {
    const response = await fetchGet(`/hit/${hitId}/assign`);

    const rawAllUsers = response.all_users;
    const rawAssignedUsers = response.assigned_users;

    // Create a map of all users and their ids
    rawAllUsers.forEach((user) => {
      const userName = user.last_name
        ? `${user.first_name} ${user.last_name}`
        : user.first_name;
      allUsers[user.id] = { userName, isDeleted: user.is_deleted };
    });

    // Create a list of all users that are assigned to the hit
    rawAssignedUsers.forEach((user) => {
      assignedUserIds = [...assignedUserIds, user];
    });

    // Create a list of all users that are not assigned to the hit
    notAssignedUserIds = Object.keys(allUsers)
      .map(Number) // To resolve the issue of keys being strings
      .filter((id) => !assignedUserIds.includes(id));
  }

  onMount(setupAssignDetails);
</script>

<div class="my-2 flex flex-wrap items-center rounded border">
  {#each assignedUserIds as user}
    {#if !allUsers[user].isDeleted}
      <div class="ml-2 flex items-center">
        <button
          class="btn btn-xs"
          on:click={() => unassignUserFromHit(user)}
          {disabled}
        >
          {allUsers[user].userName} ✕
        </button>
      </div>
    {/if}
  {/each}

  <div class="dropdown">
    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <label
      tabindex="0"
      class="btn btn-primary btn-xs m-2"
      class:btn-disabled={disabled}
      for=""
    >
      + Assign
    </label>

    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm z-[1] w-52 rounded border bg-base-100 p-1 shadow max-h-56 flex-nowrap overflow-auto"
    >
      {#each notAssignedUserIds as user}
        {#if !allUsers[user].isDeleted}
          <li class:disabled>
            <button on:click={() => assignUserToHit(user)} {disabled}>
              {allUsers[user].userName}
            </button>
          </li>
        {/if}
      {/each}
      {#if notAssignedUserIds.length === 0}
        <li class="disabled">
          <button disabled>No users available</button>
        </li>
      {/if}
    </ul>
  </div>
</div>
