<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { fetchPost, hitColourToName } from "../../helpers";
  import { HitColour, HitColourName } from "../../lib/interfaces/Hit.interface";
  import { toast } from "svelte-sonner";

  export let activeAsset;
  export let hit;
  export let rule;

  export let editable = true;

  const dispatch = createEventDispatcher();

  async function updateColour(hitId, colour) {
    try {
      const res = await fetchPost(`/hit/${hitId}/colour`, {
        colour: colour,
        type: "asset_review",
        asset_id: activeAsset.id,
        rule_id: hit.rule_id,
      });

      if (!res.success) {
        throw new Error(`Error updating status of Hit with Id(${hitId})`);
      }

      dispatch("refresh", hit);
      dispatch("colourUpdated", {
        hitId: hitId,
        hitColour: colour,
        assetRuleColour: res.asset_rule_colour,
      });
      hit = hit; //force component to reinitialise
    } catch (e) {
      toast.error(
        `There was an error updating HitId(${hitId}) to Colour(${colour})`,
      );
    }
  }

  function rejectHit(hit) {
    hit.colour = HitColour.RISK;
    updateColour(hit.hit_id, hit.colour);
  }

  function irrelevantHit(hit) {
    hit.colour = HitColour.UNKNOWN;
    updateColour(hit.hit_id, hit.colour);
  }

  function indeterminatetHit(hit) {
    hit.colour = HitColour.REVIEW;
    updateColour(hit.hit_id, hit.colour);
  }

  function approveHit(hit) {
    hit.colour = HitColour.PASSED;
    updateColour(hit.hit_id, hit.colour);
  }

  function approveHitEverywhere(hit) {
    approveHit(hit);
    //TODO: add this to the examples for the rule
  }
</script>

{#key hit}
  <div class="dropdown dropdown-end">
    <!--svelte-ignore a11y-no-noninteractive-tabindex-->
    <!--svelte-ignore a11y-label-has-associated-control-->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
    <label
      tabindex="0"
      class="btn btn-ghost btn-xs w-[116px] flex-nowrap justify-between whitespace-nowrap
        hit-status-colour-{hit.colour ? hit.colour : 'grey'}"
      on:click|stopPropagation
    >
      ● {hitColourToName(hit.colour)}

      {#if editable}
        <Icon icon="iconoir:nav-arrow-down" class="text-xs" />
      {/if}
    </label>

    <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
    {#if editable}
      <ul
        tabindex="0"
        class="menu dropdown-content menu-xs bg-base-100 z-[1] mt-1 flex w-full min-w-max flex-col gap-1 rounded border p-1"
      >
        {#if rule.affirmative_rule}
          <li>
            <button
              class="text-[#336A1D]"
              on:click|stopPropagation={() => approveHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.colour == "green"}
              />
              {HitColourName.default.STRONG}
            </button>
          </li>
          <li>
            <button
              class="text-[#FFAD48]"
              on:click|stopPropagation={() => indeterminatetHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.colour == "amber"}
              />
              {HitColourName.default.POSSIBLE}
            </button>
          </li>
          <li>
            <button on:click|stopPropagation={() => irrelevantHit(hit)}>
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.colour == "grey"}
              />
              {HitColourName.default.WEAK}
            </button>
          </li>
          <li>
            <button
              class="text-[#FF4E4E]"
              on:click|stopPropagation={() => rejectHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.colour == "red"}
              />
              {HitColourName.default.REJECTED}
            </button>
          </li>
        {:else}
          <li>
            <button
              class="text-[#FF4E4E]"
              on:click|stopPropagation={() => rejectHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.colour == "red"}
              />
              {HitColourName.negative.STRONG}
            </button>
          </li>
          <li>
            <button
              class="text-[#FFAD48]"
              on:click|stopPropagation={() => indeterminatetHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.colour == "amber"}
              />
              {HitColourName.negative.POSSIBLE}
            </button>
          </li>
          <li>
            <button
              class=""
              on:click|stopPropagation={() => irrelevantHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.colour == "grey"}
              />
              {HitColourName.negative.WEAK}
            </button>
          </li>
          <li>
            <button
              class="text-[#336A1D]"
              on:click|stopPropagation={() => approveHit(hit)}
            >
              <input
                type="radio"
                class="radio-primary radio radio-xs"
                checked={hit.colour == "green"}
              />
              {HitColourName.negative.ACCEPTED}
            </button>
          </li>
        {/if}

        <!-- <button
          class="btn btn-primary btn-xs btn-outline"
          on:click={() => {
            approveHitEverywhere(hit);
          }}
        >
          {HitColourName.ACCEPTEVERYWHERE}
        </button> -->
      </ul>
    {/if}
  </div>
{/key}

<style>
  .hit-status-colour-red {
    background-color: #fae9e4;
    color: #ff4e4e;
  }

  .hit-status-colour-amber {
    background-color: #fff3e5;
    color: #ffad48;
  }

  .hit-status-colour-green {
    background-color: #ddffd0;
    color: #336a1d;
  }
</style>
