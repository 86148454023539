<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let labels = [];
  export let reportType;

  $: sortedLabels = reportType
    ? labels
        .filter((l) => 
          reportType === "live" 
            ? l.pinned || l.pinned_live 
            : l.pinned || l.pinned_asset
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    : labels.sort((a, b) => a.name.localeCompare(b.name));
</script>

<ul class="menu ml-4 p-0">
  {#each sortedLabels as label}
    <li>
      <button
        on:click={(e) => {
          e.stopPropagation();
          dispatch("labelClicked", label.id);
        }}
      >
        <Icon icon="iconoir:label" />
        {label.name}
      </button>
    </li>
  {/each}
</ul>

<style>
  ul.menu button {
    border-radius: 0;
  }

  ul.menu button:active {
    background-color: oklch(var(--b3) / 80%) !important;
    color: unset;
  }
</style>