<script>
  import Icon from "@iconify/svelte";
  import { fly } from "svelte/transition";
  import { isAuthenticated, maximised } from "../../../stores";
  import { fetchGet } from "../../../helpers";

  async function logoutUser() {
    try {
      await fetchGet(`/auth/logout`);
      $isAuthenticated = false;
      localStorage.setItem("logged_in", false);
      localStorage.setItem("accessToken", "");
      window.location.href = "/#/home";
    } catch (e) {
      console.warn(e);
    }
  }

  export let item;
</script>

<li>
  {#if item}
    <button on:click={logoutUser}>
      {#if $maximised}
        <Icon icon="iconoir:log-out" class="text-lg" />
        <span class="whitespace-nowrap" in:fly={{ x: -32, duration: 200 }}
          >{item.description}</span
        >
      {:else}
        <div class="tooltip tooltip-right" data-tip={item.description}>
          <Icon icon="iconoir:log-out" class="text-lg" />
        </div>
      {/if}
    </button>
  {/if}
</li>

<style>
  ul.menu a,
  ul.menu button {
    border-radius: 0;
  }

  ul.menu a:active,
  ul.menu button:active,
  ul.menu a.active {
    background-color: oklch(var(--b3) / 80%) !important;
    color: unset;
  }

  ul.menu a.active {
    box-shadow: inset 4px 0 0 0 oklch(var(--p)) !important;
  }
  ul.menu .disabled {
    pointer-events: none; /* Prevent clicking */
    color: grey; /* Change color to grey or your choice */
  }
</style>
