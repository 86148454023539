<script>
  import { onDestroy, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { link } from "svelte-spa-router";
  import { fetchGet, notification, updateNotifStore } from "../../helpers";
  import { createSidebar } from "../../routes";
  import {
    currentUser,
    maximised,
    notifications,
    org_id,
    org_name,
    user_id,
  } from "../../stores";
  import Divider from "./Components/Divider.svelte";
  import Logout from "./Components/Logout.svelte";
  import SidebarItem from "./Components/SidebarItem.svelte";
  import NavHeader from "./NavHeader.svelte";
  import { getTextConstant } from "../../lib/utils/ConstantsUtils";

  export let role;
  export let disabledPermissions = [];

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");

  let clientLogoUrl = ""; /* Insert logic to set this */
  let notificationInterval;

  // TODO: investigate whether this actually does anything
  notification.subscribe((message) => {
    if (message !== "") toast.error(message);
  });

  let my_issues_count = 0;

  onMount(() => {
    const assignedHitsCountQueryParams = new URLSearchParams({
      getCount: true,
    }).toString();
    const currentUserId = $currentUser?.id ?? $user_id;

    if (currentUserId) {
      fetchGet(
        `/user/${currentUserId}/hits?${assignedHitsCountQueryParams}`,
      ).then((res) => (my_issues_count = res.data));
    }

    updateNotifStore();
  });

  $: navData = createSidebar(
    disabledPermissions,
    my_issues_count,
    $notifications.unseen_notifications,
    $org_name,
    $org_id,
  );

  onDestroy(() => {
    clearInterval(notificationInterval);
  });

  notificationInterval = setInterval(() => {
    if (!document.hidden) updateNotifStore();
  }, 300000);
</script>

<NavHeader {role} />

<!-- can't have overflow-auto when minimized as it will hide the tooltips -->
<aside
  class="bg-base-200 fixed bottom-0 left-0 top-[64px] z-20 flex flex-col transition-all duration-200"
  id="MainAppLeftSidebar"
  class:overflow-auto={$maximised}
  style:width={$maximised ? "220px" : "50px"}
>
  {#if role == "admin" && $maximised}
    <a
      href={"/settings"}
      class="btn btn-sm bg-base-100 mx-auto mt-2 w-auto shadow"
      use:link
    >
      {#if clientLogoUrl != ""}
        <span
          class="block h-[50px] bg-contain bg-center bg-no-repeat"
          style="background-image: url({clientLogoUrl});"
        />
      {:else}
        {$org_name}
      {/if}
    </a>
  {/if}

  <ul class="menu my-2 p-0">
    {#each navData as item}
      {#if item.type == "divider" && item.roles.includes(role)}
        <Divider />
      {:else if item.type == "logout"}
        <Logout {item} />
        <!-- note need to test this part works correctly -->
      {:else if item.roles.includes(role)}
        {#if !item.disabled}
          {#if item.description && item.decription === "Streams"}
            <div>STREAM</div>
            <SidebarItem  {item} />
          {:else if item.description && item.decription === "Asset Review"}
            <div>ASSET</div>
            <SidebarItem {item} />
          {:else}
            <SidebarItem {item} />
          {/if}
        {/if}
      {/if}
    {/each}

    <a href="/" class="active hidden">
      hidden a just so svelte doesn't remove css for .active
    </a>
  </ul>
</aside>
