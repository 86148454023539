<script>
  export let href = "";
  export let text = "";
  export let search = "";
  export let truncate = true;
  export let isCaseSensitive = false;
  export let classes = "";

  function highlightText(text, searchTerm) {
    if (!searchTerm) return [text];
    const regex = new RegExp(`(${searchTerm})`, isCaseSensitive ? "" : "gi");
    let splitText = text.split(regex);
    return splitText;
  }

  let highlightedTextParts = [];
  $: highlightedTextParts = highlightText(text ? text : href, search);
</script>

<a
  class="hover:text-primary hyphens-auto break-words hover:underline {classes}
   {truncate
    ? 'block overflow-hidden text-ellipsis whitespace-nowrap'
    : 'line-clamp-3'}"
  {href}
  target="_blank"
>
  {#each highlightedTextParts as part, index}
    {#if index % 2 === 0}
      {part}
    {:else}
      <mark class="bg-yellow-300">{part}</mark>
    {/if}
  {/each}
</a>
