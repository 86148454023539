<script>
  import Icon from "@iconify/svelte";
  import { org_users } from "../../stores";
  import AssetListBlock from "./AssetList/AssetListBlock.svelte";

  export let assets = [];
  export let activeAssetId;
  export let report_loaded;
  export let reportId;
  export let workflow = {};
  export let assetStatusMap = new Map();

  let activeUsers = $org_users.filter((user) => !user.is_deleted);
  let columnItems = [];

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }

  const groupAssetsToColumns = () => {
    // uff should have used switch case
    if (groupBy === "Status") {
      columnItems = [
        { id: 1, name: "Pending", items: [] },
        { id: 2, name: "In Review", items: [] },
        { id: 3, name: "Changes Requested", items: [] },
        { id: 4, name: "Approved", items: [] },
        { id: 5, name: "Roadblock", items: [] },
      ];

      assets.forEach((a) => {
        // svelte-dnd needs .id for each items item
        a.id = a.asset.id;

        const cl = columnItems.find((c) => c.name === a.asset.approval_status);
        if (cl) cl.items = [...cl.items, a];
        else {
          columnItems[0].items = [...columnItems[0].items, a];
          console.warn("not column for status", a.asset.approval_status);
        }
      });
    } else if (groupBy === "Review Group") {
      // Create columns dynamically based on the workflow steps
      columnItems = workflow.workflow_steps.map((step) => ({
        id: step.id, // Use the workflow step id as the column id
        name: step.name, // Use the workflow step name as the column name
        items: [], // Initialize an empty array for the items
      }));

      // Assign assets to the columns based on their current workflow step
      assets.forEach((a) => {
        a.id = a.asset.id; // svelte-dnd needs .id for each items item

        // Find the column that matches the asset's current workflow step
        const column = columnItems.find(
          (c) => c.id === a.asset.workflow_step.id,
        );
        if (column) {
          column.items = [...column.items, a]; // If found, add the asset to the column
        } else {
          columnItems[0].items = [...columnItems[0].items, a];
          console.warn("Asset's workflow step not found in columns", a.asset);
        }
      });
    } else if (groupBy === "Assignees") {
      columnItems = activeUsers.map((u) => ({
        id: u.id,
        name: `${u.first_name} ${u.last_name}`,

        items: assets

          .filter((a) =>
            a.asset.asset_assigned_users.some((au) => au.assigned_id === u.id),
          )
          .map((a) => {
            a.id = a.asset.id;
            return a;
          }),
      }));

      // add unassigned column
      columnItems = [
        ...columnItems,
        {
          id: 0,
          name: "Unassigned",
          items: assets
            .filter((a) => a.asset.asset_assigned_users.length === 0)
            .map((a) => {
              a.id = a.asset.id;
              return a;
            }),
        },
      ];
    } else if (groupBy === "Uploaded date") {
      // Extract unique dates from assets
      const uniqueDates = [
        ...new Set(
          assets.map((a) => {
            // Format the date as YYYY-MM-DD
            return new Date(a.asset.created_date).toISOString().split("T")[0];
          }),
        ),
      ];

      // Sort the dates in ascending order
      uniqueDates.sort((a, b) => new Date(a) - new Date(b));

      // Create columns for each date
      columnItems = uniqueDates.map((date) => ({
        id: date,
        name: date, // Column name is the date
        items: [],
      }));

      // Assign assets to the corresponding date columns
      assets.forEach((a) => {
        a.id = a.asset.id; // svelte-dnd needs .id for each item

        const assetDate = new Date(a.asset.created_date)
          .toISOString()
          .split("T")[0];
        const column = columnItems.find((c) => c.id === assetDate);

        if (column) {
          column.items.push(a);
        } else {
          // If no matching column, place in an "Unknown" column
          let unknownColumn = columnItems.find((c) => c.id === "Unknown");
          if (!unknownColumn) {
            unknownColumn = { id: "Unknown", name: "Unknown", items: [] };
            columnItems.push(unknownColumn);
          }
          unknownColumn.items.push(a);
        }
      });
    } else if (groupBy === "Due Date") {
      // Extract assets with valid due dates and those without
      const assetsWithDueDate = assets.filter((a) => {
        const dueDate = new Date(a.asset.due_date);
        return a.asset.due_date && !isNaN(dueDate);
      });

      const assetsWithoutDueDate = assets.filter((a) => {
        const dueDate = new Date(a.asset.due_date);
        return !a.asset.due_date || isNaN(dueDate);
      });

      // Group assets by due date
      const groupedAssets = assetsWithDueDate.reduce((acc, a) => {
        const dueDate = formatDate(a.asset.due_date);
        if (!acc[dueDate]) {
          acc[dueDate] = [];
        }
        a.id = a.asset.id; // svelte-dnd needs .id for each item
        acc[dueDate].push(a);
        return acc;
      }, {});

      // Create columns for each unique due date
      columnItems = Object.entries(groupedAssets).map(([date, items]) => ({
        id: date,
        name: date,
        items: items,
      }));

      // Sort columns by date
      columnItems.sort((a, b) => new Date(a.id) - new Date(b.id));

      // Add "No Due Date" column at the end
      if (assetsWithoutDueDate.length > 0) {
        columnItems.push({
          id: "No Due Date",
          name: "No Due Date",
          items: assetsWithoutDueDate.map((a) => {
            a.id = a.asset.id; // svelte-dnd needs .id for each item
            return a;
          }),
        });
      }
    } else toast.error("Unknown groupBy value");
  };

  const sortColumnItems = () => {
    if (sortBy === "Status") {
      const statuses = [
        "Pending",
        "In Review",
        "Changes Requested",
        "Approved",
        "Roadblock",
      ];
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort(
          (a, b) =>
            statuses.indexOf(a.asset.approval_status) -
            statuses.indexOf(b.asset.approval_status),
        );
        return c;
      });
    } else if (sortBy === "Review Group") {
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort((a, b) => {
          return (
            a.asset.workflow_step.position - b.asset.workflow_step.position
          );
        });
        return c;
      });
    } else if (sortBy === "Assignees") {
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort((a, b) => {
          const aUserId = a.asset.asset_assigned_users[0]?.assigned_id;
          const bUserId = b.asset.asset_assigned_users[0]?.assigned_id;

          if (!aUserId) return 1;
          if (!bUserId) return -1;

          const aName = activeUsers.find((u) => u.id === aUserId).first_name;
          const bName = activeUsers.find((u) => u.id === bUserId).first_name;
          return aName.localeCompare(bName);
        });
        return c;
      });
    } else if (sortBy === "Asset Name") {
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort((a, b) =>
          a.asset.name.localeCompare(b.asset.name),
        );
        return c;
      });
    } else if (sortBy === "Uploaded date") {
      columnItems = columnItems.map((c) => {
        c.items = c.items.sort((a, b) => {
          const dateA = new Date(a.asset.created_date);
          const dateB = new Date(b.asset.created_date);
          return dateA - dateB;
        });
        return c;
      });
    } else if (sortBy === "Due Date") {
      // Sort columns by date ascending, "No Due Date" last
      columnItems.sort((a, b) => {
        if (a.id === "No Due Date") return 1;
        if (b.id === "No Due Date") return -1;
        return new Date(a.id) - new Date(b.id);
      });

      // Sort items within each column by due date
      columnItems = columnItems.map((c) => {
        if (c.id !== "No Due Date") {
          c.items.sort(
            (a, b) => new Date(a.asset.due_date) - new Date(b.asset.due_date),
          );
        }
        return c;
      });
    } else toast.error("Unknown sortBy value");

    if (reverseSort)
      columnItems = columnItems.map((c) => {
        c.items = c.items.reverse();
        return c;
      });
  };

  const updateColumnItems = async () => {
    groupAssetsToColumns();
    sortColumnItems();

    columnItems = columnItems.map((c) => {
      //show archived feature
      c.items = c.items.filter((a) => {
        // Include assets based on the archived status and filter settings
        const isArchived = a.asset.approval_status === "Archived";
        return showArchived ? true : !isArchived;
      });
      // filterByReviewGroup
      c.items = c.items.filter((a) => {
        return filterByReviewGroups.includes(a.asset.workflow_step.id);
      });

      // filterByAssignee
      c.items = c.items.filter((a) => {
        const aUserIds = a.asset.asset_assigned_users.map(
          (au) => au.assigned_id,
        );

        if (aUserIds.length === 0) return true;

        return filterByAssignees.some((id) => aUserIds.includes(id));
      });
      //make those with 0 length the end

      // TODO: filterArchived
      // c.items based on showArchived

      return c;
    });
    //make those with 0 length the end
    columnItems = columnItems
      .filter((c) => c.items.length > 0)
      .concat(columnItems.filter((c) => c.items.length === 0));
  };

  $: if (
    groupBy ||
    sortBy ||
    reverseSort ||
    filterByReviewGroups ||
    filterByAssignees ||
    showArchived
  )
    updateColumnItems();

  let groupBy = "Review Group";
  let sortBy = "Asset Name";
  let reverseSort = false;
  let filterByReviewGroups = workflow.workflow_steps.map((s) => s.id);
  let filterByAssignees = activeUsers.map((u) => u.id);
  let showArchived = false;

  // so on initial load, the columns are updated
  let loaded = false;
  $: if (assets.length && workflow && !loaded) {
    updateColumnItems();
    loaded = true;
  }

  $: updateColumnItems(), [assets];
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->

<div class="mb-2 flex flex-wrap gap-2 py-4">
  <div class="dropdown">
    <div
      tabindex="0"
      role="button"
      style="border-radius:24px"
      class="btn btn-outline btn-primary btn-sm"
    >
      Group: {groupBy}
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm bg-base-100 z-[10] mt-1 w-full min-w-fit rounded border p-1"
    >
      {#each ["Status", "Review Group", "Assignees", "Uploaded date", "Due Date"] as item}
        <li>
          <button on:click={() => (groupBy = item)}>
            <input
              type="radio"
              class="radio-primary radio radio-sm"
              checked={groupBy === item}
            />

            {item}
          </button>
        </li>
      {/each}
    </ul>
  </div>

  <div class="dropdown">
    <div
      tabindex="0"
      role="button"
      style="border-radius:24px"
      class="btn btn-outline btn-primary btn-sm"
    >
      Sort: <Icon icon="iconoir:arrow-{reverseSort ? 'down' : 'up'}" />
      {sortBy}
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm bg-base-100 z-[10] mt-1 w-full min-w-fit rounded border p-1"
    >
      {#each ["Status", "Review Group", "Assignees", "Asset Name", "Uploaded date", "Due Date"] as item}
        <li>
          <button
            class="justify-start"
            on:click={() => {
              if (sortBy === item) reverseSort = !reverseSort;
              else {
                sortBy = item;
                reverseSort = false;
              }
            }}
          >
            {#if sortBy === item}
              <Icon
                icon="iconoir:arrow-up"
                class={!reverseSort ? "text-primary" : ""}
              />
              <Icon
                icon="iconoir:arrow-down"
                class="{reverseSort ? 'text-primary' : ''} -ml-2"
              />
            {/if}

            {item}
          </button>
        </li>
      {/each}
    </ul>
  </div>

  <div class="dropdown">
    <div
      tabindex="0"
      role="button"
      style="border-radius:24px"
      class="btn btn-outline btn-primary btn-sm"
    >
      Review Group
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm bg-base-100 z-[10] mt-1 w-full min-w-fit rounded border p-1"
    >
      <!-- Dynamically list workflow steps as options in the dropdown -->
      {#each workflow.workflow_steps as { id, name }}
        <li>
          <button
            on:click={() => {
              if (filterByReviewGroups.includes(id))
                filterByReviewGroups = filterByReviewGroups.filter(
                  (a) => a !== id,
                );
              else filterByReviewGroups = [...filterByReviewGroups, id];
            }}
          >
            <input
              type="checkbox"
              class="checkbox-primary checkbox checkbox-sm"
              checked={filterByReviewGroups.includes(id)}
            />
            {name}
          </button>
        </li>
      {/each}
    </ul>
  </div>

  <div class="dropdown">
    <div
      tabindex="0"
      role="button"
      style="border-radius:24px"
      class="btn btn-outline btn-primary btn-sm"
    >
      Assignees
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm bg-base-100 z-[10] mt-1 w-full min-w-fit rounded border p-1"
    >
      {#each activeUsers as user}
        <li>
          <button
            class="whitespace-nowrap"
            on:click={() => {
              if (filterByAssignees.includes(user.id))
                filterByAssignees = filterByAssignees.filter(
                  (a) => a !== user.id,
                );
              else filterByAssignees = [...filterByAssignees, user.id];
            }}
          >
            <input
              type="checkbox"
              class="checkbox-primary checkbox checkbox-sm"
              checked={filterByAssignees.includes(user.id)}
            />

            {`${user.first_name} ${user.last_name}`}
          </button>
        </li>
      {/each}
    </ul>
  </div>

  <button
    style="border-radius:24px"
    class="btn btn-outline btn-primary btn-sm
      {showArchived ? 'btn-active' : ''}"
    on:click={() => (showArchived = !showArchived)}
  >
    {showArchived ? "Hide Archived" : "Show Archived"}
  </button>
</div>

<div class="flex flex-col gap-5">
  {#each columnItems as table}
    <AssetListBlock
      name={table.name}
      rows={table.items}
      {reportId}
      bind:activeAssetId
      asset={table}
      {workflow}
      {assetStatusMap}
    />
  {/each}
</div>
<!-- 

<div
      class="tabs relative mt-8 max-w-screen-lg"
      style="grid-template-columns: repeat(auto-fit, minmax(0, 1fr));"
    >
      {#each approval_statuses as item}
        <button
          class="tab whitespace-nowrap text-base font-medium"
          class:text-primary={activeTabLabel === item.label}
          on:click={() => (setActiveTabLabel(item.label))}
          disabled={!report_loaded}
        >
          <Icon icon="iconoir:{item.icon}" class="mr-1" />
          {item.label} - {assets.filter(
            (f) => f.asset.approval_status === item.label,
          ).length}
        </button>
      {/each}
      <div
        class="slider bg-primary absolute bottom-0 left-0 h-0.5 transition-all"
        style:width="{100 / approval_statuses.length}%"
        style="translate: {approval_statuses.findIndex(
          (i) => i.label === activeTabLabel,
        ) * 100}%"
      />
    </div>
    <div class="mt-4 flex max-w-screen-lg flex-col gap-2">
      {#each assets.filter((f) => f.asset.approval_status === activeTabLabel) as asset}
        <AssetListRow {asset} {activeAssetId} {reportId} {assets} />
      {/each}
    </div> -->
