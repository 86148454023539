<script>
  import Icon from "@iconify/svelte";
  import Modal from "./Modals/Modal.svelte";

  export let data = {
    id: Math.random().toString(36),
    name: "checklist name",
    description: "checklist description",
  };
</script>

<button
  class="btn btn-circle btn-ghost btn-xs"
  on:click|stopPropagation={() =>
    document.querySelector(`#checklist-info-${data.id}`).showModal()}
>
  <Icon icon="iconoir:info-circle" class="text-lg" />
</button>

<Modal modalId="checklist-info-{data.id}" class="font-normal">
  <h3 class="text-xl font-bold">{data.name}</h3>
  <p class="mb-2 mt-4 text-sm">ID: {data.id}</p>
  <p class="text-sm">{data.description ?? "No Description"}</p>
</Modal>
