<script>
  import Icon from "@iconify/svelte";
  import { getDisplayDate, getInitials, getUser } from "../../helpers";
  import { comment_icons, comment_types } from "./comments";

  export let type = "live";
  export let subType = "hit";
  export let comments = [];
  export let isParentConditional = false;

  let visibleCommentsCount = 3;

  $: if (comments)
    comments = comments.sort(
      (a, b) => new Date(b.created_date) - new Date(a.created_date),
    );
</script>

<ul class="timeline timeline-vertical timeline-compact ml-6">
  {#each comments.slice(0, visibleCommentsCount) as c, i (c.id)}
    <li class="gap-x-2">
      {#if i !== 0}<hr />{/if}
      <div class="timeline-middle">
        <div class="rounded-full bg-[#E8F1FE] p-2">
          <Icon icon={comment_icons[c.category]} class="text-primary text-xl" />
        </div>
      </div>
      <div class="timeline-end mb-0 mt-4 w-full">
        {#if c.category === "Text"}
          <svelte:component this={comment_types[c.category]} comment={c} />
        {:else}
          <div class="flex items-start gap-2">
            <div class="avatar placeholder">
              <div class="bg-neutral text-neutral-content w-6 rounded-full">
                <span class="text-xs">{getInitials(getUser(c.user_id))}</span>
              </div>
            </div>

            <div>
              {#if subType === "assetStatus"}
                <svelte:component
                  this={comment_types[c.category]}
                  comment={c}
                  {type}
                  {subType}
                />
              {:else}
                <svelte:component
                  this={comment_types[c.category]}
                  comment={c}
                  {type}
                  {isParentConditional}
                />
              {/if}

              <p class="text-xs text-[#94A3B8]">
                {getDisplayDate(c.created_date)}
              </p>
            </div>
          </div>
        {/if}
      </div>
      {#if i !== comments.length - 1}<hr />{/if}
    </li>
  {/each}
</ul>

<div class="divider" class:hidden={comments.length <= 3}>
  {#if visibleCommentsCount < comments.length}
    <button
      class="btn btn-ghost btn-xs"
      on:click={() => (visibleCommentsCount += 3)}
    >
      <Icon icon="iconoir:nav-arrow-down" />
      See More Comments
    </button>
  {/if}
  {#if visibleCommentsCount > 3}
    <button
      class="btn btn-ghost btn-xs"
      on:click={() => (visibleCommentsCount = 3)}
    >
      <Icon icon="iconoir:nav-arrow-up" />
      Minimize Comments
    </button>
  {/if}
</div>

<style lang="postcss">
  .timeline > li > hr {
    @apply w-0.5 bg-[#E8F1FE];
  }
</style>
