<script>
  import Icon from "@iconify/svelte";
  import { tick } from "svelte";
  import {
    getActiveVersion,
    getPreviewUrl,
  } from "../../../../lib/utils/AssetReviewUtils";
  import { org_name } from "../../../../stores";
  import { isEmpty } from "../../../../lib/utils/GenericUtils";
  import { isCompareSupportedFile } from "../../../../lib/utils/AssetReviewUtils";

  export let reportId;
  export let activeVersion;
  export let versions;
  export let versionIdx;
  export let currentStreamPerm;
  export let activeAssetId;
  export let previewUrl;
  export let versionUpdated = false;
  export let showCompareVersions = false;

  let selectedVersionIdx = versionIdx;

  async function updateActiveVersion(newVersionIdx) {
    if (versionIdx === newVersionIdx) {
      return;
    }

    versionIdx = newVersionIdx;
    activeVersion = await getActiveVersion(newVersionIdx, versions);
    previewUrl = getPreviewUrl(
      $org_name,
      reportId,
      activeAssetId,
      activeVersion.aws_version_id,
    );
    versionUpdated = true;
  }
  //TODO: rewrite this
  $: if (selectedVersionIdx !== versionIdx) {
    updateActiveVersion(selectedVersionIdx).then((_) => {
      console.log(activeVersion);
    });
  }
</script>

<div class="mr-auto flex shrink-0 cursor-pointer">
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <div class="dropdown">
    <label
      tabindex="0"
      class="btn btn-sm flex-nowrap rounded-l-lg rounded-r-none"
    >
      v{activeVersion.version}
      <Icon icon="iconoir:nav-arrow-down" class="-ml-2" />
    </label>
    <ul
      tabindex="0"
      class="dropdown-content bg-base-100 z-[1] flex w-[168px] flex-col gap-1 rounded border p-2"
    >
      {#each versions as version, index}
        <button
          class="btn btn-ghost btn-xs"
          on:click={() => (selectedVersionIdx = index)}
        >
          v{version.version}
        </button>
      {/each}
      <!-- svelte-ignore missing-declaration -->
      <div
        class={!versions ||
        versions?.length <= 1 ||
        versions.every((v) => !isCompareSupportedFile(v.fileType))
          ? "tooltip"
          : ""}
        data-tip={!versions || versions?.length <= 1
          ? "Please upload more than one version to compare versions."
          : "Only .pdf, .jpeg, and .png files can be compared."}
      >
        <button
          class="btn btn-primary btn-xs w-full"
          disabled={!versions ||
            versions?.length <= 1 ||
            versions.every((v) => !isCompareSupportedFile(v.fileType))}
          on:click={async () => {
            showCompareVersions = true;
            showCompareVersions = showCompareVersions;
            await tick();
            comparePDFModal.showModal();
          }}
        >
          Compare Versions
        </button>
      </div>
    </ul>
  </div>

  <!-- svelte-ignore missing-declaration -->
  <button
    class="btn btn-outline btn-sm rounded-l-none rounded-r-lg"
    style="border-color:#c2c2c2"
    class:btn-disabled={currentStreamPerm == "view"}
    disabled={currentStreamPerm == "view"}
    on:click={() => uploadNewVersionModal.showModal()}
  >
    <Icon icon="iconoir:upload" class="text-xl" />
    Upload
  </button>
</div>
