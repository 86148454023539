/**
 * @description
 * Intermediary `enum` for JavaScript implementation for `Workflow` entity `Asset` statuses.
 *
 * @todo
 * We need to convert this to a _proper_ enum and integrate it with other components
 * utilising Workflow Asset approval statuses so as to reduce redundancy & inconsistency in the codebase.
 */

//JW: I have edited this page to match my views on what the statuses should be. Changes need to be flowed through everywhere else to accomodate this.
// For "revert to previous stage" and "Approve to next stage", I suggest we implement these in ApprovalStatusDropdown.svelte

export const ApprovalStatus = Object.freeze({
  //UPLOADED: "Uploaded", //Uploaded should only be applied to the "Uploaded" workflow stage.
  PENDING: "Pending",
  REVIEW: "In Review",
  REQUESTCHANGES: "Changes Requested",
  ARCHIVE: "Archived",
  REVERT: "Revert",
  REJECTED: "Rejected", //Reverting to a previous stage should set the status back to pending
  APPROVE: "Approved",
  APPROVED: "Approved",
  //ROADBLOCK:"Roadblock"
});

export const ApprovalStatusLabel = Object.freeze({
  //UPLOADED: "Revert to Uploaded", //For clarity, if you click this, you can move it back to the uploaded stage directly (in addition to reverting it to the previous step).
  PENDING: "Mark Pending",
  REVIEW: "Mark In Review",
  REQUESTCHANGES: "Request Changes",
  ARCHIVE: "Archive",
  REJECTED: "Reject",
  REVERT: "Revert",
  APPROVE: "Approve",
  //ROADBLOCK:"Roadblock"
});

export const ApprovalStatusColor = Object.freeze({
  // UPLOADED: {
  //   Color: "#606468",
  //   Background: "#c2c2c2",
  // },
  PENDING: {
    Color: "#606468",
    Background: "#c2c2c2",
  },
  REVIEW: {
    Color: "#FF8D00",
    Background: "#fbe5b8",
  },
  REQUESTCHANGES: {
    Color: "#FF8D00",
    Background: "#fbe5b8",
  },
  // ROADBLOCK: {
  //   Color: "#FF8D00",
  //   Background: "#fbe5b8",
  // },
  ARCHIVE: {
    Color: "#000000",
    Background: "#FFFFFF",
  },
  REJECTED: {
    Color: "#FC0000",
    Background: "#ff4e4e5e",
  },
  REVERT: {
    Color: "#FC0000",
    Background: "#ff4e4e5e",
  },
  APPROVE: {
    Color: "#336A1D",
    Background: "#c4d4bd",
  },
  APPROVED: {
    Color: "#336A1D",
    Background: "#c4d4bd",
  },
});
