import { isEmpty } from "./utils/GenericUtils";

export function invalidInputFocus(el, { id, onChange, validateEmpty = false }) {
  const onFocus = () => {
    el.classList.remove("border-error");
    onChange(id, false);
  };
  const onBlur = () => {
    if (validateEmpty && isEmpty(el.value)) {
      onChange(id, true);
      el.classList.add("border-error");
    } else {
      onChange(id, false);
      el.classList.remove("border-error");
    }
  };

  el.addEventListener("focus", onFocus);
  el.addEventListener("blur", onBlur);

  return {
    destroy() {
      el.removeEventListener("focus", onFocus);
      el.removeEventListener("blur", onBlur);
    },
  };
}
