<script>
  export let query;

  let minimum_slop = 1;

  function countWords(str) {
    const arr = str.split(" ");
    const count = arr.filter((word) => word !== "").length;
    return count > 0 ? count : 1;
  }

  const minimum_slop_check = () => {
    minimum_slop = countWords(query.value);
  };

  $: if (query.value.includes('"') || query.value.includes("'"))
    query.value = query.value.replaceAll(/["']/g, "");

  $: if (query.slop < minimum_slop) query.slop = minimum_slop;
</script>

<div class="join w-full">
  <input
    class="input input-sm join-item input-bordered w-full min-w-[4rem]"
    bind:value={query.value}
    on:input={minimum_slop_check}
  />
  <div
    class="tooltip tooltip-bottom before:-left-24"
    data-tip="Adjust the slop value to find phrases where terms can be separated by up to 'n' words."
  >
    <input
      class="input input-sm join-item input-bordered w-16"
      type="number"
      min={minimum_slop}
      bind:value={query.slop}
    />
  </div>
</div>
