<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import Select from "../Select.svelte";
  import Number from "./Number.svelte";
  import ProximitySearch from "./ProximitySearch.svelte";
  import Text from "./Text.svelte";

  export let fields = [];
  export let query;
  export let selectedChannels = [];

  onMount(() => {
    if (!query.operation || query.operation == "") {
      query.operation =
        fields.filter((f) => f.name == query.field)[0]?.operations[0] || "";
    }
  });

  let opsDesc = {
    "exactly contains": "Matches this phrase exactly",
    "proximity search":
      "Contains these words within the specified proximity to each other",
    "includes any of": "Includes any words listed",
    "regular expression":
      "Advanced search only. Use regular expressions to provide finely tuned control",
  };
</script>

{#if !query.delete}
  <div class="flex min-w-max items-center gap-2 rounded border p-1">
    <!-- <div class="-m-1 mr-0 rounded-l border-r">
      <button
        class="btn btn-sm h-full rounded-l rounded-r-none text-lg text-opacity-75"
      >
        ⠿
      </button>
    </div> -->

    <Select
      dropdownRowClass="block justify-between"
      useCustomTooltip={true}
      inputClasses="!w-40"
      items={fields.map((e) => ({
        label: e.label ? e.label : e.name,
        value: e.name,
        desc: e.desc,
        disabled:
          e.name === "html" &&
          (selectedChannels.length < 1 ||
            selectedChannels.find((s) => s.includes("post"))),
      }))}
      bind:selectedValue={query.field}
    />

    <Select
      inputClasses="!w-24"
      items={[
        { label: "Does", value: false },
        { label: "Does Not", value: true },
      ]}
      bind:selectedValue={query.not}
    />

    {#if query.field != ""}
      <Select
        dropdownRowClass="block justify-between"
        useCustomTooltip={true}
        inputClasses="!w-36"
        items={fields
          .find((f) => f.name == query.field)
          ?.operations.map((e) => ({
            label: e,
            value: e,
            desc: opsDesc[e] ?? e,
          }))}
        bind:selectedValue={query.operation}
      />

      {#if query.field == "HTTP_Status"}
        <Number bind:query />
      {:else if query.operation == "proximity search"}
        <ProximitySearch bind:query />
      {:else}
        <Text bind:query {fields} classes="![field-sizing:fixed]" />
      {/if}
    {/if}

    <div
      class="divider divider-horizontal -mx-1 -my-1 before:w-[1px] after:w-[1px]"
    ></div>

    <button
      class="btn btn-square btn-ghost btn-sm hover:btn-error"
      on:click={() => {
        query.delete = true;
        query = query;
      }}
    >
      <Icon icon="iconoir:trash" />
    </button>
  </div>
{/if}
