<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { link } from "svelte-spa-router";
  import RuleInfoModal from "../../Modals/RuleInfoModal.svelte";
  import ReportExport from "../../Reports/ReportExport/ReportExport.svelte";

  const dispatch = createEventDispatcher();

  export let originalData;
  export let report_id;
  export let step = 2;
  export let exportRes = true;
  export let currentTab = "Open";
  export let tableRootPage = "";
  export let showGroupedTable = false;
  export let allRules = false;
  export let canEditPermissions;

  export let selectedRulesId = [];
  export let selectedDataTypes = [];
  export let selectedVendors = [];
  export let selectedSocialTypes = [];

  export let disableEditing = false;

  let lastSelectedRulesId = selectedRulesId;
  let lastSelectedDataTypes = selectedDataTypes;
  let lastSelectedVendors = selectedVendors;
  let lastselectedSocialTypes = selectedSocialTypes;

  $: if (
    lastSelectedRulesId !== selectedRulesId ||
    lastSelectedDataTypes !== selectedDataTypes ||
    lastSelectedVendors !== selectedVendors ||
    lastselectedSocialTypes !== selectedSocialTypes
  ) {
    dispatch("selectChanged");

    lastSelectedRulesId = selectedRulesId;
    lastSelectedDataTypes = selectedDataTypes;
    lastSelectedVendors = selectedVendors;
    lastselectedSocialTypes = selectedSocialTypes;
  }

  allRules = allRules
    ? allRules
    : [
        ...new Map(
          originalData.map((e) => e.rule).map((i) => [i.id, i]),
        ).values(),
      ];

  let allResults = originalData.map((e) => e.hit);

  let saved_rules = [];

  function convertUTCDateToCurrentTimezone(dateString) {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offset * 60 * 1000);
    return newDate;
  }

  function getHitsForRule(ruleId, currentTab) {
    return originalData.filter(
      (entry) =>
        entry.hit.rule_id === ruleId &&
        (entry.hit.in_report === currentTab || !entry.hit.in_report),
    ).length;
  }
</script>

<aside
  class="flex max-h-full w-52 shrink-0 flex-col overflow-y-auto rounded border"
  style:max-height={tableRootPage.includes("reports")
    ? "min(calc(100vh - 270px), 874px)"
    : !exportRes
      ? "min(calc(100vh - 272px), 833px)"
      : "auto"}
>
  <h2 class="flex items-center justify-between gap-2 p-2 font-semibold">
    Filters

    {#if tableRootPage.includes("reports")}
      <button
        class="btn btn-primary btn-xs"
        on:click={() => (showGroupedTable = !showGroupedTable)}
      >
        {showGroupedTable ? "Ungroup" : "Group"} Hits
      </button>
    {/if}
  </h2>

  <div class="divider my-0 h-0" />

  {#if tableRootPage !== "search" && tableRootPage !== "spelling"}
    <div class="collapse-arrow collapse shrink-0">
      <input type="checkbox" checked class="min-h-[unset]" />
      <div class="collapse-title min-h-[unset] pl-2 font-medium">
        <div class="flex items-center gap-1">
          <input
            type="checkbox"
            class="checkbox-primary checkbox checkbox-sm relative z-[1]"
            checked={selectedRulesId.length === allRules.length &&
              allRules.length > 0}
            indeterminate={selectedRulesId.length > 0 &&
              selectedRulesId.length !== allRules.length}
            on:change={(e) => {
              if (e.target.checked) selectedRulesId = allRules.map((r) => r.id);
              else selectedRulesId = [];
            }}
          />

          Rules
          <!-- export res is true when we are not in Stream Review -->
          {#if !window.location.href.includes("issues")}
            {#if exportRes}
              <div
                class={disableEditing || !canEditPermissions
                  ? "tooltip fixed z-[1] ml-28"
                  : " z-[1] ml-auto"}
                data-tip={disableEditing && canEditPermissions
                  ? "This report has been archived and cannot be modified"
                  : "You do not have permission to modify this report"}
              >
                <a
                  href="/stream/edit/{report_id}?step=1"
                  class="btn btn-primary btn-xs relative z-[1] ml-auto"
                  class:btn-disabled={disableEditing}
                  use:link
                >
                  Edit
                </a>
              </div>
            {:else}
              <button
                class="btn btn-primary btn-xs relative z-[1] ml-auto"
                on:click={() => (step = 1)}
              >
                Edit
              </button>
            {/if}
          {/if}
        </div>
      </div>
      <div class="collapse-content !py-0 !pl-2">
        {#each allRules as rule}
          <div class="flex items-center">
            <label class="label cursor-pointer justify-start gap-2">
              <input
                type="checkbox"
                class="checkbox-primary checkbox checkbox-sm"
                checked={selectedRulesId.includes(rule.id)}
                on:click={(e) => {
                  if (e.target.checked)
                    selectedRulesId = [...selectedRulesId, rule.id];
                  else
                    selectedRulesId = selectedRulesId.filter(
                      (e) => e !== rule.id,
                    );
                }}
                lineWrapping={true}
              />
              <!-- based on sidebar size, adjust if changed -->
              {#key currentTab || selectedRulesId}
                <span
                  class="label-text block w-[122px] truncate !text-sm hover:whitespace-normal hover:[text-overflow:revert]"
                >
                  ({getHitsForRule(rule.id, currentTab)}) {rule.name}
                </span>
              {/key}
            </label>

            <button
              class="btn btn-circle btn-ghost btn-xs"
              on:click={() =>
                document
                  .getElementById("rule_info_modal_" + rule.id)
                  .showModal()}
            >
              <Icon icon="material-symbols:info-outline" />
              <!--Note: using material symbols here not iconify, as the iconify info-outline is not rendering properly-->
            </button>
          </div>

          <RuleInfoModal {rule} bind:saved_rules />
        {/each}
      </div>
    </div>
  {/if}

  <div class="collapse-arrow collapse shrink-0">
    <input type="checkbox" checked class="min-h-[unset]" />
    <div class="collapse-title min-h-[unset] pl-2 font-medium">
      <div class="flex items-center gap-1">
        <input
          type="checkbox"
          class="checkbox-primary checkbox checkbox-sm relative z-[1]"
          checked={selectedDataTypes.length ===
            [...new Set(allResults.map((e) => e.data_type))].length &&
            [...new Set(allResults.map((e) => e.data_type))].length > 0}
          indeterminate={selectedDataTypes.length > 0 &&
            selectedDataTypes.length !==
              [...new Set(allResults.map((e) => e.data_type))].length}
          on:change={(e) => {
            if (e.target.checked)
              selectedDataTypes = [
                ...new Set(allResults.map((e) => e.data_type)),
              ];
            else if (e.target.checked === false) selectedDataTypes = [];
          }}
        />
        Asset Type
      </div>
    </div>
    <div class="collapse-content !py-0 !pl-2">
      {#each [...new Set(allResults.map((e) => e.data_type))] as d}
        <label class="label cursor-pointer justify-start gap-2 pt-0">
          <input
            type="checkbox"
            class="checkbox-primary checkbox checkbox-sm"
            checked={selectedDataTypes.includes(d)}
            on:change={(e) => {
              if (e.target.checked)
                selectedDataTypes = [...selectedDataTypes, d];
              else selectedDataTypes = selectedDataTypes.filter((e) => e !== d);
            }}
          />
          <span class="label-text !text-sm capitalize">{d?.toLowerCase()}</span>
        </label>
      {/each}
    </div>
  </div>

  <div class="collapse-arrow collapse shrink-0">
    <input type="checkbox" checked class="min-h-[unset]" />
    <div class="collapse-title min-h-[unset] pl-2 font-medium">
      <div class="flex items-center gap-1">
        <input
          type="checkbox"
          class="checkbox-primary checkbox checkbox-sm relative z-[1]"
          checked={selectedVendors.length ===
            [...new Set(allResults.map((e) => e.vendor))].length &&
            [...new Set(allResults.map((e) => e.vendor))].length > 0}
          indeterminate={selectedVendors.length > 0 &&
            selectedVendors.length !==
              [...new Set(allResults.map((e) => e.vendor))].length}
          on:change={(e) => {
            if (e.target.checked)
              selectedVendors = [...new Set(allResults.map((e) => e.vendor))];
            else selectedVendors = [];
          }}
        />
        Company
      </div>
    </div>
    <div class="collapse-content !py-0 !pl-2">
      {#each [...new Set(allResults.map((e) => e.vendor))] as v}
        <label class="label cursor-pointer justify-start gap-2 pt-0">
          <input
            type="checkbox"
            class="checkbox-primary checkbox checkbox-sm"
            checked={selectedVendors.includes(v)}
            on:click={(e) => {
              if (e.target.checked) selectedVendors = [...selectedVendors, v];
              else selectedVendors = selectedVendors.filter((e) => e !== v);
            }}
          />
          <!-- based on sidebar size, adjust if changed -->
          <div class="tooltip max-w-[146px]" data-tip={v}>
            <span
              class="label-text block overflow-hidden text-ellipsis whitespace-nowrap !text-sm"
            >
              {v}
            </span>
          </div>
        </label>
      {/each}
    </div>
  </div>

  <div class="collapse-arrow collapse shrink-0">
    <input type="checkbox" checked class="min-h-[unset]" />
    <div class="collapse-title min-h-[unset] pl-2 font-medium">
      <div class="flex items-center gap-1">
        <input
          type="checkbox"
          class="checkbox-primary checkbox checkbox-sm relative z-[1]"
          checked={selectedSocialTypes.length ===
            [...new Set(allResults.map((e) => e.index.split("_")[1]))].length &&
            [...new Set(allResults.map((e) => e.index.split("_")[1]))].length >
              0}
          indeterminate={selectedSocialTypes.length > 0 &&
            selectedSocialTypes.length !==
              [...new Set(allResults.map((e) => e.index.split("_")[1]))].length}
          on:change={(e) => {
            if (e.target.checked)
              selectedSocialTypes = [
                ...new Set(allResults.map((e) => e.index.split("_")[1])),
              ];
            else selectedSocialTypes = [];
          }}
        />
        Channel Type
      </div>
    </div>
    <div class="collapse-content flex flex-col !py-0 !pl-2">
      {#each [...new Set(allResults.map((e) => e.index.split("_")[1]))] as s}
        <label class="label cursor-pointer justify-start gap-2 pt-0">
          <input
            type="checkbox"
            class="checkbox-primary checkbox checkbox-sm"
            checked={selectedSocialTypes.includes(s)}
            on:click={(e) => {
              if (e.target.checked)
                selectedSocialTypes = [...selectedSocialTypes, s];
              else
                selectedSocialTypes = selectedSocialTypes.filter(
                  (e) => e !== s,
                );
            }}
          />
          <span class="label-text !text-sm capitalize">{s}</span>
        </label>
      {/each}
    </div>
  </div>

  {#if exportRes}
    <ReportExport reportId={report_id} bind:reports={originalData} />
  {/if}
</aside>
