<script>
  import Icon from "@iconify/svelte";
  import { onDestroy, onMount } from "svelte";
  import { pop } from "svelte-spa-router";
  import { maximised } from "../stores";

  onMount(() => ($maximised = false));
  onDestroy(() => ($maximised = true));
</script>

<div
  class="-mx-12 -mb-16 -mt-4 flex min-h-[calc(100vh-4rem)] grow flex-col items-center justify-center gap-4 bg-[#E9F5FE] p-4 text-center"
>
  <h1 class="text-9xl font-extrabold text-primary md:text-[22rem]">404</h1>

  <h2 class="text-3xl font-medium md:text-5xl">Ooops! Page Not Found</h2>

  <p class="md:text-xl">
    Sorry, this page doesn't exist or has been removed! 🥥
  </p>
  <button class="btn btn-primary btn-sm mt-4" on:click={() => pop()}>
    Go Back
    <Icon icon="iconoir:long-arrow-up-left" />
  </button>
</div>
