<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { link, push } from "svelte-spa-router";
  import { fetchDelete, fetchGet, fetchPost } from "../helpers";
  import { isEmptyObj } from "../lib/utils/GenericUtils";
  import { currentUser } from "../stores";
  import LabelBean from "./Labels/LabelBean.svelte";
  import ListOfBeans from "./Labels/ListOfBeans.svelte";

  const dispatch = createEventDispatcher();

  export let report;
  export let labels = [];

  let inReviewAssets = 0;
  let approvedAssets = 0;
  let changesRequestedAssets = 0;
  let pendingAssets = 0;
  let roadblockAssets = 0;
  let currentStreamPerm = "none"; // in future while setting currentStreamPerm if  status == "Archived" set as view only

  function parseDate(d) {
    if (d) {
      return d.split(":").slice(0, -1).join(":");
    }
    return "";
  }

  function labelSelected(event) {
    const selectedLabel = event.detail;

    if (!labels.some((label) => label.id === selectedLabel.id)) {
      fetchPost(`/label/assign/${selectedLabel.id}`, {
        report_id: report.id,
      });

      labels = [...labels, selectedLabel];
    }

    dispatch("labelAdded", selectedLabel);
  }

  function deleteLabel(event) {
    const label = event.detail;

    fetchDelete(`/label/assign/${label.id}`, {
      report_id: report.id,
    });

    labels = labels.filter((l) => l.id !== label.id);

    dispatch("labelRemoved", label);
  }

  onMount(async () => {
    for (let i = 0; i < report.assets.length; i++) {
      const asset = report.assets[i];

      if (isEmptyObj(asset)) continue;
;
      if (asset.approval_status !== "Archived") {
        const workflowStepName = asset.workflow_step[0]?.name;

        if (workflowStepName === "Uploaded") {
          pendingAssets++;
        } else if (workflowStepName === "Approved") {
          approvedAssets++;
        } else {
          inReviewAssets++;
        }
      }
    }

    // Set Folder Card Permissions
    if ($currentUser.role == "admin") currentStreamPerm = "edit";
    else {
      const userPerm = await fetchGet(
        `/report/${report.id}/get-user-permission-to-report`,
      );

      if (userPerm.permission === "none") {
        const orgPerm = await fetchGet(
          `/report/${report.id}/get-org-permission`,
        );
        if (orgPerm.permission === "none") push("/streams");
        else if (["view", "edit", "upload"].includes(orgPerm.permission))
          currentStreamPerm = orgPerm.permission;
      } else {
        if (["view", "edit", "upload"].includes(userPerm.permission))
          currentStreamPerm = userPerm.permission;
      }
    }
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }
</script>

<tr>
  <td
    class="min-w-[17vw] max-w-[17vw] {!(
      report.description && report.description.trim().length > 0
    )
      ? 'content-center'
      : ''}"
  >
    <a class="flex flex-col" href="/review/{report.id}" use:link>
      <p class="font-semibold">
        {report.name}
        <a
          href="/#/review/{report.id}"
          class="btn btn-square btn-ghost btn-xs"
          target="_blank"
        >
          <Icon icon="iconoir:open-new-window" />
        </a>
      </p>

      {#if report.description && report.description.trim().length > 0}
        <div class="tooltip" data-tip={report.description}>
          <p class="text-base-content/70 truncate text-start text-xs">
            {report.description}
          </p>
        </div>
      {/if}
    </a>
  </td>
  <td>{pendingAssets}</td>
  <td>{inReviewAssets}</td>
  <td>{approvedAssets}</td>
  <td class="space-x-1 whitespace-nowrap">
    {#if labels.length > 0}
      <ListOfBeans labels={labels.slice(0, 2)} disabled={true} />

      {#if labels.length > 2}
        <LabelBean name="+{labels.length - 2}" disabled={true} />
      {/if}
    {:else}
      <span class="text-base-content/70 text-sm">No labels</span>
    {/if}
  </td>
  <td>{formatDate(report.created_date)}</td>
  <td>{formatDate(report.last_refreshed)}</td>
  <td>
    <div class="flex flex-wrap gap-2">
      {#if currentStreamPerm == "edit"}
        <a
          href="/review/edit/{report.id}"
          use:link
          class="btn btn-outline btn-primary btn-xs flex-nowrap text-nowrap"
          class:btn-disabled={!["edit", "admin"].includes(report.permission)}
        >
          <Icon icon="iconoir:edit-pencil" />
          Edit
        </a>
      {:else}
        <div
          class="tooltip"
          data-tip="You do not have permission to edit this stream"
        >
          <button
            class="btn btn-disabled btn-outline btn-primary btn-xs flex-nowrap text-nowrap"
          >
            <Icon icon="iconoir:edit-pencil" />
            Edit
          </button>
        </div>
      {/if}

      {#if currentStreamPerm == "edit" || currentStreamPerm == "upload"}
        <a
          href="#/review/edit/{report.id}?step=upload"
          class="btn btn-outline btn-primary btn-xs flex-nowrap text-nowrap"
        >
          <Icon icon="iconoir:upload" />
          Upload
        </a>
      {/if}
      {#if currentStreamPerm == "edit"}
        <a
          href="#/review/edit/{report.id}?step=checklist"
          class="btn btn-outline btn-primary btn-xs flex-nowrap text-nowrap"
        >
          <Icon icon="iconoir:clipboard-check" />
          Manage checklist
        </a>
      {/if}
    </div>
  </td>
</tr>
