<script>
  import Icon from "@iconify/svelte";
  import ChatContent from "./ChatContent.svelte";
  import ChatInput from "./ChatInput.svelte";

  export let chat_type = "discover";
  export let d_types = [];
  export let report_id;

  let questionCount = 0;
  let addUserCommunication;

  function handleSubmit(message) {
    const text = message.detail.text;
    addUserCommunication(text);
  }
</script>

<article class="my-4 rounded border">
  <h2
    class="sticky top-0 z-10 flex items-center gap-2 border-b bg-base-100 px-4 py-2"
  >
    <span class="font-semibold">AI Assisted Rule Builder</span>
    <!-- for some reason daisy tooltips are sometimes addding few extra pixels to the height -->
    <div
      class="tooltip tooltip-bottom h-6"
      data-tip="Our AI-powered chat bot can help you build rules in the correct format. Always make sure to review the rules before using them."
    >
      <button class="btn btn-circle btn-ghost btn-xs">
        <Icon icon="iconoir:help-circle" class="text-lg" />
      </button>
    </div>
  </h2>

  <ChatContent
    {chat_type}
    {d_types}
    {report_id}
    bind:questionCount
    bind:addUserCommunication
    on:updateRulePen
  />

  <ChatInput on:submit={handleSubmit} />
</article>
