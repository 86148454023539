<script>
  import { createEventDispatcher } from "svelte";

  export let data = [];
  export let hitId = -1;
  export let selectedHits;
  export let index = -1;
  export let isSelected = selectedHits && selectedHits.has(index);

  /**
   * TODO:
   *  - Update the `checked` property to be initialised correctly when
   *    the Hit has a corresponding index in selectedHits.
   */

  export let disableEditing = false;

  const originalData = data;
  const dispatch = createEventDispatcher();

  function toggleEntrySelected() {
    dispatch("entrySelectedToggled");
  }

  function getEntrySelected() {
    let selected = false;

    if (selectedHits) {
      if (selectedHits.has(index)) {
        selected = true;
      }
    }

    return selected;
  }

  $: if (data) {
    isSelected = getEntrySelected();
  }
</script>

<input
  type="checkbox"
  class="checkbox-primary checkbox checkbox-sm"
  id={hitId}
  on:click={toggleEntrySelected}
  bind:checked={isSelected}
  disabled={disableEditing}
/>
