<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchGet, fetchPost } from "../../helpers";
  import {
      RuleSearchType
  } from "../../lib/interfaces/Rule.interface";
  import { getTextConstant } from "../../lib/utils/ConstantsUtils";
  import { org_id, org_name } from "../../stores";
  import ResultOptions from "../ResultOptions.svelte";
  import Comments from "../comments/Comments.svelte";
  import AssetAddRuleExample from "./AssetAddRuleExample.svelte";
  import AssetHitInfoDialog from "./AssetHitInfoDialog.svelte";
  import AssignUsers from "./AssignUsers.svelte";
  import HitColourSelector from "./HitColourSelector.svelte";

  export let hit;
  export let rule;
  export let activeAsset;
  export let previewSearchText = "";
  export let boundingBox = [
    [0, 0],
    [0, 0],
  ]; //[(0,20), (0,30)] [ top left, bottom right], [(x_left,y_top), (x_right,y_bottom) ]  (0,0) is top left no negatives
  export let report_id = null;
  export let actionTexts = [];
  export let currentStreamPerm = "none";
  export let asset_rule = {};
  export let currentAssetApprovingPermission = false;

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");

  function goToBoundingBox(newBoundingBox) {
    boundingBox = newBoundingBox; // This will trigger the viewer to jump
  }

  function convertHighlightToBoundingBox(highlightString) {
    try {
      // Parse the string into an array of numbers
      const coordinates = JSON.parse(highlightString);

      if (coordinates.length === 4) {
        // Convert the 4-value array into the bounding box format [[x_left, y_top], [x_right, y_bottom]]
        return [
          [coordinates[0], coordinates[1]], // Top-left corner (x_left, y_top)
          [coordinates[2], coordinates[3]], // Bottom-right corner (x_right, y_bottom)
        ];
      } else {
        throw new Error("Invalid bounding box format");
      }
    } catch (error) {
      console.error("Error parsing bounding box:", error);
      return [
        [0, 0],
        [0, 0],
      ]; // Return a default bounding box in case of error
    }
  }

  export let expanded = false;

  let isResultOptionsOpen = false;
  let entry = {};
  entry.hit = hit; // Compatibility layer for Asset Review

  let hitComment = "";
  const addHitComment = async () => {
    if (!hitComment || hitComment == "") {
      toast.warning("Please enter a comment");
      return;
    }

    const res = await fetchPost(`/hit/${hit.hit_id}/comment`, {
      text: hitComment,
      hit_id: hit.hit_id,
    });

    if (!res.success) {
      toast.error("Failed to add comment");
      return;
    }

    await updateHitComments();

    hitComment = "";
  };

  const dispatch = createEventDispatcher();

  const updateHitComments = async () => {
    const res = await fetchGet(`/hit/${hit.hit_id}/comment`);

    if (!res.success) {
      toast.error("Failed to fetch comments");
      return;
    }

    hit.comments = res.comments;
    dispatch("updateRuleCommentCount");
  };

  async function handleColourUpdate(event) {
    const { hitId, hitColour, assetRuleColour } = event.detail;

    asset_rule.colour = assetRuleColour;
    asset_rule = asset_rule;
    // Update the asset rule color in the parent component state
    // This assumes you have a way to reference or update the asset rule color in the parent
    // For example, if you have the asset rule data stored in a variable:
    activeAsset.ruleColour = assetRuleColour;
    activeAsset = activeAsset;
    // You might need to refresh or update other parts of your component or application state as needed

    updateHitComments();
  }

  function refreshTable(event) {
    dispatch("refresh", event);
  }
  function handleUpdateComments(event) {
    hit.comments = event.detail;
    dispatch("updateRuleCommentCount");
  }

  function handleGoToHit() {
    // For IMAGE rule type, navigate to bounding box; for others, preview search text
    if (rule.rule_type === RuleSearchType.IMAGE) {
      const boundingBoxArray = convertHighlightToBoundingBox(hit.highlight); // Convert the string to a bounding box array
      goToBoundingBox(boundingBoxArray);
    } else if (typeof hit.highlight === "string") {
      previewSearchText = hit.highlight; // Use the highlight for non-IMAGE rule types
    }
  }
</script>

{#key hit}
  <div class="bg-base-200 rounded border px-4 py-2">
    <button
      class="flex w-full items-center justify-between gap-2"
      on:click={() => (expanded = !expanded)}
    >
      {#if rule.rule_type === "IMAGE"}
        <span
          class="w-64 break-words text-start text-sm"
          class:line-clamp-4={!expanded}
        >
          {hit.explanation}
        </span>
      {:else}
        <span
          class="w-64 break-words text-start text-sm"
          class:line-clamp-4={!expanded}
        >
          {@html hit.highlight}
        </span>
      {/if}
      <div class="flex shrink-0 items-center">
        <button
          class="btn btn-circle btn-ghost btn-xs mr-1"
          on:click|stopPropagation={() =>
            document.getElementById("hit_info_modal_" + hit.hit_id).showModal()}
        >
          <Icon icon="iconoir:info-circle" class="text-lg" />
        </button>
        <div
          class={!currentAssetApprovingPermission ||
          currentStreamPerm !== "edit"
            ? "tooltip"
            : ""}
          data-tip={currentStreamPerm === "edit"
            ? 'Hit statuses cannot be changed while the asset is in the "Uploaded" step. Please promote this asset to another step to change the statuses of the hits.'
            : "You do not have permission to modify the status of hits for this asset."}
        >
          <HitColourSelector
            {hit}
            {rule}
            {activeAsset}
            showColour={true}
            editable={currentStreamPerm == "edit" &&
              currentAssetApprovingPermission}
            on:refresh={(e) => {
              hit = e.detail;
              dispatch("refresh");
            }}
            on:colourUpdated={async (e) => {
              await handleColourUpdate(e);
            }}
          />
        </div>

        <button
          class="btn btn-ghost btn-xs"
          on:click|stopPropagation={async () => {
            expanded = true;
            await tick();

            const ctxtarea = document.querySelector(`#hitComment${hit.hit_id}`);
            if (ctxtarea) {
              ctxtarea.scrollIntoView({ behavior: "smooth" });
              ctxtarea.focus();
            } else console.log("Comment textarea not found");
          }}
        >
          <Icon icon="iconoir:chat-lines" class="text-base" />
          {hit.comments?.length ?? 0}
        </button>

        <button class="btn btn-square btn-ghost btn-xs ml-auto">
          <Icon
            icon="iconoir:nav-arrow-down"
            class="text-lg transition-transform {expanded ? 'rotate-180' : ''}"
          />
        </button>
      </div>
    </button>

    {#if expanded}
      <div class="flex flex-col" transition:slide>
        <div class="divider my-2"></div>
        {#if hit.explanation !== undefined && hit.explanation !== null}
          <span class="mb-2 text-sm">
            <b>Explanation:</b> {hit.explanation}</span
          >
        {/if}
        <div class="mb-2 flex items-center gap-1">
          <AssignUsers
            hit_id={hit.hit_id}
            assignees={hit.assigned_users}
            asset_type={"hit"}
            disableEditing={!currentAssetApprovingPermission}
            on:change={(e) => {
              hit.assigned_users = e.detail;
              hit = hit;
              updateHitComments();
            }}
            isCompact
            alignLeft
          />
          {#if activeAsset.file_type.includes("image/")}
            <div class="tooltip" data-tip="Feature coming soon.">
              <button
                class="btn btn-primary btn-xs"
                on:click={() =>
                  goToBoundingBox(convertHighlightToBoundingBox(hit.highlight))}
                disabled
              >
                Go to hit
              </button>
            </div>
          {:else}
            <button
              class="btn btn-primary btn-xs"
              on:click={() => (previewSearchText = hit.highlight)}
            >
              Go to hit
            </button>
          {/if}

          {#if rule.rule_type === RuleSearchType.TENSOR || rule.rule_type === RuleSearchType.LEXICAL || rule.rule_type === RuleSearchType.CONTEXTUAL}
            <button
              class="btn btn-primary btn-xs whitespace-nowrap"
              disabled={currentStreamPerm !== "edit"}
              on:click|stopPropagation={() =>
                document
                  .getElementById(
                    "rule_add_example_" + rule.id + "_" + hit.hit_id,
                  )
                  .showModal()}
            >
              <!-- Provide Folder Level Feedback -->
              Add Example to {textConstants["Folder"]}
            </button>
          {/if}

          {#if isResultOptionsOpen}
            <!--TODO: Need to fix up caps on priority and status initial values-->
            <ResultOptions
              {report_id}
              rule_name={rule.name}
              issue_id={hit.hit_id}
              bind:maximize={isResultOptionsOpen}
              hit={entry}
              actions={actionTexts}
              assetView={true}
              on:close={refreshTable}
            />
          {/if}
        </div>
        <Comments
          commentInputId="hitComment{hit.hit_id}"
          comments={hit.comments}
          apiEndpoint="/hit/{hit.hit_id}/comment"
          xs
          on:updateComments={handleUpdateComments}
        />

        <!-- <textarea
          class="textarea textarea-bordered textarea-sm w-full"
          rows="1"
          placeholder="Comment"
          id="hitComment{hit.hit_id}"
          bind:value={hitComment}
          on:keypress={(e) => {
            if (e.shiftKey && e.key === "Enter") addHitComment();
          }}
        />
        {#if hitComment.length}
          <button
            class="btn btn-primary btn-xs ml-auto mt-2"
            on:click={addHitComment}
          >
            <Icon icon="iconoir:chat-lines" /> Comment
          </button>
        {/if}

        {#if hit.comments}
          <CommentsView comments={hit.comments} type="asset" />
        {/if} -->
      </div>
      <AssetAddRuleExample {rule} report_id={activeAsset.report_id} {hit} />
    {/if}
  </div>
  <AssetHitInfoDialog {hit} {rule} />
{/key}
