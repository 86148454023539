<script>
  export let bgColor = "#FFFFFF"; // Default background color
  export let textColor = "#000000"; // Default text color
  export let additionalClass = "";
  export let hidden = false;
  export let fullyRound = false;

  export let size = "xs";
</script>

<span
  class="inline-flex items-center whitespace-nowrap font-semibold
   {size === 'xs'
    ? 'px-2 py-1 text-xs'
    : size === 'sm'
      ? 'h-8 px-3 text-sm'
      : ''}   
    {fullyRound ? 'rounded-full' : 'rounded-md'} {additionalClass}"
  style="background-color: {bgColor}; color: {textColor};"
  class:hidden
>
  <slot />
</span>
