<script>
  import IconBot from "../icons/IconBot.svelte";
</script>

<div class="chat chat-start">
  <IconBot />
  <div class="chat-header">Haast Bot</div>
  <div class="chat-bubble chat-bubble-secondary pb-0 text-black">
    <span class="loading loading-dots" />
  </div>
</div>
