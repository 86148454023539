/**
 * Looks for the Hit ID in the URL and returns the page number of the Hit in the table.
 * @param {any[]} tableData
 * @param {number} itemsPerPage
 * @returns Page number of the Hit in the table. If nothing is found, returns 1.
 */
export function findHitFromURL(tableData, itemsPerPage = 10) {
  const isStream = window.location.pathname.includes("/stream/");
  const defaultOutput = { page: 1, tab: "Open" };

  if (isStream) {
    return defaultOutput;
  }

  const hitId = Number(window.location.hash.split("/").pop());

  if (!hitId) {
    return defaultOutput;
  }

  if (!tableData || !tableData.length) {
    return defaultOutput;
  }

  const hit = tableData.find((entry) => entry.hit.id === hitId);

  if (!hit) {
    return defaultOutput;
  }

  const hitIndex = tableData.indexOf(hit);
  const page = Math.floor(hitIndex / itemsPerPage);

  return { page: page + 1, tab: hit.hit.in_report };
}
