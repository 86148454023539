<script>
  import { createEventDispatcher } from 'svelte';
  import { formatSeconds } from '../lib/utils/GeneralDataUtils';
  const dispatch = createEventDispatcher();

  // using: https://web.dev/text-fragments/
  export let displayText;
  export let url;
  export let field = "text";
  export let search = "";
  export let isCaseSensitive = false;
  export let isNotATag = false;
  export let timestamp = null;

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  // Function to process displayText for 'entities' field:
  // - Remove '(@[Entity])' annotations
  // - Replace square brackets with <em> tags
    function processDisplayTextForEntities(text) {
    if (!text) return "";


    // Step 1: Remove all <em> tags (just the tags, nothing else)
    text = text.replace(/<em>/gi, '').replace(/<\/em>/gi, '');

    // Step 2: Add <strong> tags around text inside square brackets
    text = text.replace(/\[([^\]]+)\]/g, (match, entityText) => `<strong>${entityText}</strong>`);

    // Step 3: Remove the entity annotation like '(@AGEENTITY)'
    text = text.replace(/\(@[A-Z]+ENTITY\)/gi, '');

    return text;
  }


  export let highlightText = (text, searchTerm) => {
    if (!searchTerm) return [text];

    // Strip only <em> and </em> tags from the text
    const strippedText = text.replace(/<\/?em>/g, "").replace(/[\r\n]+/g, " ");

    const escapedSearchTerm = escapeRegExp(searchTerm);
    const regex = new RegExp(
      `(${escapedSearchTerm})`,
      isCaseSensitive ? "" : "gi",
    );
    let highlightedText = strippedText.split(regex);

    return highlightedText;
  };

  function getTableHighlighLink(text) {
    // Remove whitespace behind possible |
    text = text.trimStart();

    // Remove | from the start
    if (text[0] === "|") {
      text = text.slice(1);
    }

    // Remove whitespace in front of possible |
    text = text.trimStart();

    // Split around every " | "
    const textArray = text.split(" | ");

    // Get the first and the last member of the array
    const first = textArray[0];
    const last = textArray[textArray.length - 1];

    return first + "," + last;
  }

  function generateLink(text) {
    if (!text) {
      return "";
    }
    if (isNotATag) {
      return null
    }

    var prefix = text.split("<em>")[0];

    var suffix_array = text.split("</em>");
    var suffix = suffix_array[suffix_array.length - 1];

    var highlight = text.slice(prefix.length, text.length - suffix.length);

    var prefix_array = prefix.split("\n");
    prefix = prefix_array[prefix_array.length - 1];
    suffix = suffix.split("\n")[0];

    prefix = encodeTextFragment(prefix.replace(/<\/?em>/g, ""));
    highlight = encodeTextFragment(highlight.replace(/<\/?em>/g, ""));
    suffix = encodeTextFragment(suffix.replace(/<\/?em>/g, ""));

    return (
      url +
      "#:~:text=" +
      ("" == prefix ? "" : prefix + "-,") +
      highlight +
      ("" == suffix ? "" : ",-" + suffix)
    );
  }

  function encodeTextFragment(text) {
    //Remove leading and trailing whitespace
    text = text.trim();

    // Remove newline characters
    text = text.replace(/\\n/g, " ");

    // Remove newlines
    text = text.replace(/\n/g, " ");

    // If text has a |, it is a table
    if (text.includes("|")) {
      text = getTableHighlighLink(text);
    }

    text = text.replace(/(\s+|^)#+(\s+|$)/g, "");

    //Remove leading and trailing whitespace
    text = text.trim();

    //Percent Encode the URL
    text = encodeURIComponent(text);

    //for some reason encodeURIComponent doesn't awlays replace hyphens and apostrophes??
    text = text.replace(/\-/g, "%2D");
    text = text.replace(/\'/g, "%27");

    text = text.toLowerCase();

    return text;
  }
  let processedDisplayText;
  let highlightedDisplayText;
  // Process the displayText based on the field
  $: {
    if (field === 'entities') {
      // Special processing for 'entities' field
      processedDisplayText = processDisplayTextForEntities(displayText);
    } else {
      // Keep the original displayText for other fields
      processedDisplayText = displayText;
    }

    // Generate highlightedDisplayText
    highlightedDisplayText = highlightText(processedDisplayText, search);
  }

  let conditionalOnClick = {}
  let tag;

  // at a separate function to have a fixed handler signature
  function handleClick() {
    dispatch('click');
  }

  $: {
    if (tag) {
      tag.removeEventListener('click', handleClick)
      if (isNotATag) {
        tag.addEventListener('click', handleClick)
      } 
    } 
  }
</script>

<!-- Conditionally render the field -->
{#if displayText == "" || undefined}
  <a
    href={url}
    target="_blank"
    class={`hover:text-primary hyphens-auto break-words hover:underline ${isNotATag ? "cursor-pointer" : ""}`}
    bind:this={tag}
  >
    {url}
  </a>
{:else if field}
  <i>{field}: </i>
  {#if timestamp !== null && timestamp >= 0.0}
    ({formatSeconds(timestamp)})
  {/if}
  <a
    href={generateLink(processedDisplayText)}
    target="_blank"
    class={`hover:text-primary hyphens-auto break-words hover:underline ${isNotATag ? "cursor-pointer" : ""}`}
    bind:this={tag}
  >
    {#each highlightedDisplayText as part, index}
      {#if index % 2 === 0}
        {@html part}
      {:else}
        <span class="bg-yellow-300">{@html part}</span>
      {/if}
    {/each}
  </a>
{/if}

<style>
  :global(em) {
    font-weight: bold;
    text-decoration: underline;
  }
</style>
