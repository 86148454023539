<script>
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchGet, fetchPost } from "../helpers";

  const API_VERSION = import.meta.env.VITE_API_VERSION;
  let organizations = [];
  let selectedOrganization = "";
  let checklists = [
    { name: "", description: "", fileContent: "", status: "pending" },
  ]; // Added status field

  onMount(async () => {
    organizations = await fetchGet("industryrules/organisations");
  });

  function handleFileUpload(index, event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        checklists[index].fileContent = reader.result;
      };
      reader.readAsText(file);
    }
  }

  function addChecklist() {
    checklists = [
      ...checklists,
      { name: "", description: "", fileContent: "", status: "pending" },
    ];
  }

  async function uploadIndustryRules() {
    if (
      !selectedOrganization ||
      checklists.some((cl) => !cl.name || !cl.fileContent)
    ) {
      toast.error(
        "Please fill in all fields and upload a CSV file for each checklist.",
      );
      return;
    }

    for (let i = 0; i < checklists.length; i++) {
      checklists[i].status = "sending";
      toast.info(`Uploading checklist "${checklists[i].name}"...`);

      const payload = {
        organization_id: selectedOrganization,
        checklist_name: checklists[i].name,
        checklist_description: checklists[i].description,
        file_content: checklists[i].fileContent,
      };

      try {
        const response = await fetchPost(`/industryrules/upload`, payload);

        if (response.success) {
          checklists[i].status = "added";
          toast.success(
            `Checklist "${checklists[i].name}" uploaded successfully!`,
          );
        } else {
          checklists[i].status = "error";
          toast.error(`Failed to upload checklist "${checklists[i].name}".`);
        }
      } catch (error) {
        checklists[i].status = "error";
        toast.error(`Error uploading checklist "${checklists[i].name}".`);
      }
    }
  }
</script>

<svelte:head>
  <title>Industry Rules - Haast</title>
</svelte:head>

<div class="mx-auto max-w-screen-xl p-4">
  <h1 class="mb-4 text-3xl font-semibold">Industry Rules</h1>

  <div class="form-control mb-4">
    <label for="organization" class="label">
      <span class="label-text">Select Organization:</span>
    </label>
    <select
      id="organization"
      bind:value={selectedOrganization}
      class="select select-bordered w-full"
    >
      <option value="" disabled>Select organization</option>
      {#each organizations as org}
        <option value={org.id}>{org.name}</option>
      {/each}
    </select>
  </div>

  {#each checklists as checklist, index}
    <div class="bg-base-100 mb-4 space-y-4 rounded-lg border p-4">
      <div class="form-control">
        <label for="checklist-{index}" class="label">
          <span class="label-text">Checklist Name:</span>
        </label>
        <input
          type="text"
          id="checklist-{index}"
          bind:value={checklist.name}
          class="input input-bordered w-full"
          placeholder="Enter checklist name"
        />
      </div>

      <div class="form-control">
        <label for="description-{index}" class="label">
          <span class="label-text">Checklist Description:</span>
        </label>
        <input
          type="text"
          id="description-{index}"
          bind:value={checklist.description}
          class="input input-bordered w-full"
          placeholder="Enter checklist description"
        />
      </div>

      <div class="form-control">
        <label for="file-{index}" class="label">
          <span class="label-text">Upload CSV:</span>
        </label>
        <input
          type="file"
          id="file-{index}"
          accept=".csv"
          on:change={(event) => handleFileUpload(index, event)}
          class="file-input file-input-bordered w-full"
        />
      </div>

      <div class="mt-2">
        <span class="text-sm">
          Status: {checklist.status}
        </span>
      </div>
    </div>
  {/each}

  <div class="mb-4 flex items-center justify-between">
    <button type="button" on:click={addChecklist} class="btn">
      Add Another Checklist
    </button>
  </div>

  <button on:click={uploadIndustryRules} class="btn btn-primary"
    >Upload Industry Rules</button
  >
</div>
