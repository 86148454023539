<script>
  import { toast } from "svelte-sonner";
  import { fetchPatch } from "../helpers";
  import { dueDays } from "../stores";

  let dueDaysValue = $dueDays;

  async function handleUpdateDueDays() {
    try {
      const response = await fetchPatch("/organisation/settings", {
        setting: "due_days",
        due_days: dueDaysValue,
      });

      if (!response.success) {
        throw new Error(
          response.message ?? "Something went wrong updating the due days.",
        );
      }

      dueDays.set(response.settings.due_days);
      dueDaysValue = $dueDays;
      toast.success("Due days updated successfully!");
    } catch (e) {
      toast.error("Something went wrong updating the due days.");
    }
  }
</script>

<div class="m-2 p-2">
  <p class="py-2 text-sm">Input due days</p>
  <div class="flex gap-2">
    <input
      type="number"
      min="0"
      onkeypress="return (event.charCode != 8 && event.charCode == 0 || (event.charCode >= 48 && event.charCode <= 57))"
      placeholder="{$dueDays}..."
      bind:value={dueDaysValue}
      class="input input-sm input-bordered max-w-md grow"
    />
    <button
      class="btn btn-primary btn-sm"
      on:click={async () => {
        await handleUpdateDueDays();
      }}>Confirm</button
    >
  </div>
</div>
