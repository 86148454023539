/**
 * Tells whether a entry supports modal use for media. 
 * 
 * @param {string} dataType - the data type, ex., "POST", "DOCUMENT", "TIMESTAMP" 
 * @returns boolean
 */
export function doesDataTypeSupportMedia(dataType) {
    switch (dataType.toLowerCase()) {
        case "timestamp":
            return true;
        /*
            Kind of contradictory, but direct media entries
            should be treated as a normal document for now.
            This means that the link it provides just links
            to the media itself, unlike timestamps which can
            point directly to the hit.
        */
        case "multimedia":
        default:
            return false;
    }
}

/**
 * Not all media providers provides links for direct timestamp access out of the box. This
 * function tells whether you can access them easily. For example, SoundCloud can be accessed
 * but it requires logging in so this function returns false.
 * 
 * Here are some media providers that we know:
 * - vimeo
 * - youtube
 * - cdninstagram
 * 
 * @param {string} url - the URL of the content, NOT the site containing the content 
 * @returns 
 */
export function doesSupportDirectTimestamp(url) {
    return  url.includes("vimeo") ||
            url.includes("youtube");
}

/**
 * Some of our stored links are CDNs. The user should not be asked to access these links.
 * 
 * @param {string} url 
 * @returns 
 */
export function isProperSite(url) {
    const badUrls = [
        "fbcdn", // facebook, instagram, and meta
        "licdn", // linkedin
        "video.twimg" // twitter
    ]
    return !badUrls.some(el => url.includes(el))
}

export const defaultTimestampDialogData = {
    title: '',
    timestamp: 0.0,
    url: '',
    caption: ''
}