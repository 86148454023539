<script>
  import { toast } from "svelte-sonner";
  import { fetchDelete, fetchPatch } from "../../helpers";
  import { actions } from "../../stores";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import Pagination from "../table/Pagination.svelte";

  $actions.sort((a, b) => a.id - b.id);

  let actionToDelete = null;
  let editingAction = null;
  let currentPage = 1;
  let itemsPerPage = 5;

  async function deleteAction(id) {
    let originalActions = $actions;

    let updatedActions = $actions.filter((action) => action.id !== id);
    actions.set(updatedActions);

    let response = await fetchDelete("/action/" + id);

    if (!response.success) {
      console.warn("Error deleting action", response);
      toast.error("Failed to delete item.");
      actions.set(originalActions);
      return;
    }

    toast.success("Item deleted.");
  }

  async function saveAction() {
    if (!editingAction.action_text.trim()) {
      toast.warning("The action cannot be empty.");
      return;
    }

    let response = await fetchPatch("/action/" + editingAction.id, {
      action: editingAction.action_text,
    });

    if (!response.success) {
      toast.error("Failed to save item.");
      console.warn("Error saving action", response);
      actions.set(originalActions);

      return;
    }
    $actions.find((a) => a.id === editingAction.id).action_text =
      editingAction.action_text;

    editingAction = null;
    toast.success("Item saved.");
  }
</script>

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  modalId="deleteActionModal"
  message="Are you sure you want to delete this action?"
  onConfirm={() => {
    deleteAction(actionToDelete);
    deleteActionModal.close();
  }}
  onCancel={() => deleteActionModal.close()}
/>

<table class="text-base-content/80 table border-collapse text-sm *:h-12">
  <thead>
    <tr class="text-base-content/90 border-0 *:text-sm *:font-semibold">
      <th>Action Title</th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    {#each $actions.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as action}
      <tr class="border-0">
        {#if editingAction && action.id === editingAction.id}
          <td>
            <input
              class="input input-sm input-bordered w-full"
              required
              type="text"
              bind:value={editingAction.action_text}
            />
          </td>
          <td>
            <div class="m-auto flex gap-2">
              <button
                class="btn btn-outline btn-primary btn-xs"
                on:click={saveAction}
              >
                Save
              </button>

              <button
                class="btn btn-outline btn-error btn-xs"
                on:click={() => (editingAction = null)}
              >
                Cancel
              </button>
            </div>
          </td>
        {:else}
          <td>{action.action_text}</td>
          <td>
            <div class="m-auto flex gap-2">
              <button
                class="btn btn-outline btn-primary btn-xs"
                on:click={() => (editingAction = { ...action })}
              >
                Edit
              </button>

              <!-- svelte-ignore missing-declaration -->
              <button
                class="btn btn-outline btn-error btn-xs"
                on:click={() => {
                  deleteActionModal.showModal();
                  actionToDelete = action.id;
                }}
              >
                Delete
              </button>
            </div>
          </td>
        {/if}
      </tr>
    {/each}
  </tbody>
</table>

<Pagination bind:currentPage bind:itemsPerPage totalHits={$actions.length} />
