<script>
  import Icon from "@iconify/svelte";

  export let rule;
  export let isViewing = false;

  let list = [""];

  function handleKeyDown(event) {
    if (event.key === "Enter") event.preventDefault();
  }
</script>

{#each list as e, idx}
  <div class="flex items-center gap-2">
    <span class="min-w-8 pl-1 text-sm">{idx + 1}</span>
    <textarea
      placeholder="Image link or description"
      class="textarea textarea-bordered grow"
      rows="1"
      bind:value={e}
      on:keydown={handleKeyDown}
    ></textarea>
    <button
      class="!hover:btn-error btn btn-ghost border-base-content/20"
      disabled={isViewing}
      on:click={() => {
        list.splice(idx, 1);
        list = list; // Reassign to trigger rerender
      }}
    >
      <Icon icon="iconoir:trash" />
    </button>
  </div>
{/each}

<button
  class="btn btn-outline btn-primary btn-sm mr-auto"
  disabled={isViewing}
  on:click={() => (list = [...list, ""])}
>
  <Icon icon="iconoir:plus" />
  Add Phrase
</button>
