<script>
  export let data;
</script>

<li>
  <a
    href={"/#/stream/" + data.report_id + "/" + data.id}
    target="_blank"
    class="link-hover link link-primary"
  >
    Hit #{data.id}
  </a>
</li>
