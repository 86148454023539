<script>
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../helpers";
  import { validEmail, validPassword } from "../lib/utils/AuthUtils";

  let token;
  let email;

  let password;
  let passwordConfirm;

  let resetButtonDisabled = false;

  function getVariablesFromURLParams() {
    let url = new URL(window.location.href);
    token = url.searchParams.get("token");
    email = url.searchParams.get("email");
  }

  getVariablesFromURLParams();

  async function resetPassword() {
    resetButtonDisabled = true;

    if (password != passwordConfirm) {
      toast.warning("Passwords do not match.");
      resetButtonDisabled = false;
      return;
    }

    if (!validPassword(password) && !validPassword(passwordConfirm)) {
      toast.warning("Password must be at least five (5) characters long.");
      resetButtonDisabled = false;
      return;
    }

    if (!validEmail(email)) {
      toast.warning("Invalid email entered.");
      resetButtonDisabled = false;
      return;
    }

    try {
      let request = { email, new_password: password, token };

      let response = await fetchPost("/auth/reset-password", request);

      if (!response.success)
        toast.error("The password reset link is invalid or has expired.");
      else {
        toast.success("Your password has been reset.");

        setTimeout(() => (window.location.href = "/"), 2000);
      }
    } catch (e) {
      toast.error("The password reset failed due to an error on our side.");

      console.warn(e);
    }
  }
</script>

<div class="flex h-screen items-center justify-center bg-base-200">
  <main
    class="m-4 flex w-full max-w-md flex-col overflow-hidden rounded border bg-base-100 p-8"
  >
    <img src="/Haast.svg" class="h-12 self-center" alt="Haast" />

    <form on:submit|preventDefault={resetPassword}>
      <div class="form-control w-full">
        <label class="label" for="pass">
          <span class="label-text">New Password</span>
        </label>
        <input
          name="pass"
          id="pass"
          class="input input-bordered w-full"
          bind:value={password}
          placeholder="Enter new password"
          type="password"
        />
      </div>

      <div class="form-control w-full">
        <label class="label" for="cPass">
          <span class="label-text">Confirm Password</span>
        </label>
        <input
          name="cPass"
          id="cPass"
          class="input input-bordered w-full"
          bind:value={passwordConfirm}
          placeholder="Type your password again"
          type="password"
        />
      </div>

      <button
        class="btn btn-primary mt-2 w-full"
        type="submit"
        disabled={resetButtonDisabled}
      >
        Reset Password
      </button>
    </form>
  </main>
</div>
