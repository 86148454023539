<script>
  import PSPDFKit from "pspdfkit";
  import {
    afterUpdate,
    createEventDispatcher,
    onDestroy,
    onMount,
  } from "svelte";
  import { toast } from "svelte-sonner";

  const dispatch = createEventDispatcher();

  let currentDocument;
  let container = "#pspdfkit";
  let instance;

  function arraysAreEqual(a, b) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (
        a[i].length !== b[i].length ||
        a[i][0] !== b[i][0] ||
        a[i][1] !== b[i][1]
      ) {
        return false;
      }
    }
    return true;
  }

  export let documentLink;
  export let search = "";
  export let highlight = "";
  export let boundingBox = [
    [0, 0],
    [0, 0],
  ];
  export let documentType = "proxy";
  export let height = "600px";

  function dispatchLoadingState(loadingState = "Loading document...") {
    dispatch("loadingUpdated", {
      loading: loadingState,
    });
  }

  const performSearch = async (instance, query) => await instance.search(query);

  // No. I won't explain it. It's magic.

  const refineSearchNew = async (instance, search, highlight) => {
    // Searches the main highlight keyword then iterates over each word
    // before and after until it maximises the hit's context
    const highlightResults = await performSearch(instance, highlight);
    let startWords = [];
    let endWords = [];
    if (highlightResults.size > 0) {
      const start = search.indexOf(highlight);
      const end = start + highlight.length;
      startWords = search
        .slice(0, start - 1)
        .split(" ")
        .reverse();
      endWords = search.slice(end + 1).split(" ");
    } else {
      const words = search.split(" ");
      const middle = Math.floor(words.length / 2);
      startWords = words.slice(0, middle).reverse();
      endWords = words.slice(middle + 1);
      highlight = words[middle];
    }
    let query = highlight;
    for (const startOrEnd of ["start", "end"]) {
      let reference = startOrEnd === "start" ? startWords : endWords;
      for (let i = 0; i <= reference.length; i++) {
        let newQuery = "";
        if (startOrEnd === "start") {
          newQuery = reference[i] + " " + query;
        } else {
          newQuery = query + " " + reference[i];
        }
        const newResults = await performSearch(instance, newQuery);
        if (newResults.size < 1) {
          // This is meant to break when there are formatting differences
          // which prevent the correct hit from being found
          break;
        } else {
          query = newQuery;
        }
      }
    }
    return query;
  };

  const downloadButton = {
    type: "custom",
    id: "downloader",
    icon: "/icons/iconoir--download.svg",
    title: "Download",
    onPress: () => {
      const a = document.createElement("a");
      a.href = currentDocument;
      a.download = documentLink.split("/").pop().replaceAll(".", "_");
      a.click();
      a.remove();
    },
  };
  async function load() {
    dispatchLoadingState();
    const licenseKey = import.meta.env.VITE_APP_PSPDFKIT_LICENSE;
    currentDocument = documentLink;

    instance = await PSPDFKit.load({
      baseUrl: `${window.location.protocol}//${window.location.host}/`,
      licenseKey: licenseKey,
      container: container,
      disableTextSelection: false,
      document:
        documentType === "proxy"
          ? documentLink
          : `data:application/pdf;base64,${documentLink}`,
      toolbarItems: [
        { type: "sidebar-thumbnails" },
        { type: "sidebar-document-outline" },
        { type: "pager" },
        { type: "pan" },
        { type: "zoom-out" },
        { type: "zoom-in" },
        { type: "search" },
        { type: "print" },
        { type: "document-editor" },
        { type: "document-crop" },
        downloadButton,
        // { type: "export-pdf" },
        // { type: "comment" }, // Temporarily removed as comments are currently non-persistent. | 02/08/2024
      ],
      treatImportedDocumentAsOnePage: true,
      styleSheets: ["/custom-pdf-style.css"], //This hides the toolbar

      onError: (error) => {
        console.error("PSPDFKit loading error:", error);
        toast.error("Something went wrong loading the document.");
      },
    });

    dispatchLoadingState("Highlighting hit in document...");

    // Check if the search string is valid (not empty or whitespace)
    if (search.trim().length > 0) {
      const results = await performSearch(instance, search);
      const finalSearch =
        results.size > 0
          ? search
          : await refineSearchNew(instance, search, highlight);
      instance.startUISearch(finalSearch);
    }
    // Add bounding box if provided
    console.log(boundingBox);
    if (
      boundingBox &&
      // boundingBox.length === 2 &&
      // boundingBox[0].length === 2 &&
      // boundingBox[1].length === 2 &&
      !arraysAreEqual(boundingBox, [
        [0, 0],
        [0, 0],
      ])
    ) {
      console.log("building bouding box");
      //fix me
      const [topLeft, bottomRight] = boundingBox;

      // Create a rectangle annotation using the bounding box coordinates
      const rectAnnotation = await new PSPDFKit.Annotations.RectangleAnnotation(
        {
          boundingBox: new PSPDFKit.Geometry.Rect({
            left: topLeft[0],
            top: topLeft[1],
            width: bottomRight[0] - topLeft[0],
            height: bottomRight[1] - topLeft[1],
          }),
          strokeColor: PSPDFKit.Color.RED, // Change color as needed
          lineWidth: 2, // Change line width as needed
          pageIndex: 0, // Assuming the bounding box is for the first page, update accordingly
        },
      );

      // Add the annotation to the document
      instance.create([rectAnnotation]);
    }

    dispatchLoadingState("Done!");
  }

  function unload() {
    if (instance) {
      PSPDFKit.unload(instance);
      instance = null;
    }
  }

  onMount(() => {
    load();
  });

  afterUpdate(() => {
    if (documentLink !== currentDocument) {
      unload();
      load();
    }
  });

  onDestroy(() => {
    unload();
  });
</script>

<div
  bind:this={container}
  id="#pspdfkit"
  style="height: {height}; width: calc(99% + 1px); padding: 5px; background: #F5F5F5"
/>
