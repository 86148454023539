<script>
  import WorkflowVisualizer from "./WorkflowVisualizer.svelte";

  export let report_id = null;
  export let selectedWorkflow = {
    id: 10,
    name: "",
    description: "",
    workflow_steps: [
      {
        id: 10,
        name: "",
        description: "",
        position: 1,
        teams: [],
        users: [],
      },
    ],
  };
  // Sort the workflow_steps once on load
  $: selectedWorkflow.workflow_steps = selectedWorkflow.workflow_steps
    .filter((step) => !step.default)
    .sort((a, b) => a.position - b.position);
</script>

<div class="flex w-full flex-wrap items-center gap-4" style="padding-top:10px;">
  <div class="min-w-0 flex-1">
    <p class="truncate text-sm font-semibold text-gray-500">Workflow Name:</p>
    <p class="truncate text-gray-700">
      {selectedWorkflow.name}
    </p>
  </div>
  <div class="flex-2 min-w-0">
    <p class="truncate text-sm font-semibold text-gray-500">
      Workflow Description:
    </p>
    <p class="truncate text-gray-700">
      {selectedWorkflow.description}
    </p>
  </div>
</div>

<WorkflowVisualizer {selectedWorkflow} {report_id} />
