<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { fade, slide } from "svelte/transition";
  import Select from "../components/Select.svelte";
  import {
    clickOutside,
    fetchDelete,
    fetchGet,
    fetchPatch,
    fetchPost,
    getUTCDateString,
  } from "../helpers";
  import {
    canEditCurrentStream,
    currentUser,
    email_list,
    role,
  } from "../stores";
  import AssignedUserSelect from "./AssignedUserSelect.svelte";
  import Highlight from "./Highlight.svelte";
  import PreviewLink from "./PreviewLink.svelte";
  import TableType from "./TableViews/TableType.svelte";
  import CommentsView from "./comments/CommentsView.svelte";
  import Comments from "./comments/Comments.svelte";
  import { getDataType } from "../lib/utils/GeneralDataUtils";

  const dispatch = createEventDispatcher();

  export let hit = {};
  //TODO: need to set this fix this
  // hit.assigned_users = []
  export let rule_name;
  export let report_id;
  export let actions = [];
  export let issue_id = null;
  export let maximize = false;
  export let assetView = false;
  export let disableEditing = false;

  let comment = "";

  let assigned_email = "";
  let assigned_users = [];

  let assigned_ids = [];

  let assignmentPriority = hit.hit.priority;

  let currentStatus = hit.hit.in_report;
  let isDone = hit.hit.done;
  let modalTitle = "Hit Details";

  let hitContentLinkIsCdn;
  $: hitContentLinkIsCdn = hit.hit.content_link
    ? hit.hit.content_link.includes("fbcdn.net") ||
      hit.hit.content_link.includes("cdninstagram.com")
    : false;

  const priorities = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Med" },
    { label: "High", value: "High" },
  ];

  const statuses = [
    { label: "Open", value: "Open" },
    { label: "Flagged", value: "Flagged" },
    { label: "Closed", value: "Closed" },
  ];

  const isOnReportsPage = window.location.href.includes("/stream/");
  const isOnMyHitsPage = window.location.href.includes("/myissues");
  const url = window.location.href;
  const cleanUrl = url.endsWith("/") ? url.slice(0, -1) : url;
  const splitUrlArray = cleanUrl.split("/");
  const UrlSplitNum = isOnMyHitsPage ? 5 : 6;
  const issueIdURL = splitUrlArray[UrlSplitNum];
  const isOpenedByURL = issueIdURL && issueIdURL == hit.hit.id;

  if (isOpenedByURL || issue_id == hit.hit.id) {
    maximize = true;
  }

  // TODO: restore the assinged users
  // if (hit.hit?.assigned_users?.length != 0) {
  //   let foundUser;
  //   assigned_users = hit.hit.assigned_users
  //     .map((ass) => {
  //       assigned_ids.push(ass.assigned_id);
  //       foundUser = $org_users.filter((u) => u.id == ass.assigned_id)[0];

  //       if (!foundUser) {
  //         return null;
  //       }

  //       return {
  //         value: foundUser.id,
  //         label: foundUser.first_name + " " + foundUser.last_name,
  //       };
  //     })
  //     .filter(Boolean);
  // }

  async function refreshHitData() {
    const currentUserId = $currentUser.id;
    const response = await fetchGet(`/${currentUserId}/hits`);
    const data = response.data;

    hit = data.find((dataEntry) => dataEntry.hit.id === hit.hit.id) || hit;
  }

  async function saveActionDetails(hi_id, act, diff_user, to_delete) {
    if (!($role == "admin" || $role == "user") || !$canEditCurrentStream)
      return; //if user role is not admin or user, do nothing
    const payload = {
      hit_id: hi_id,
      action: hit.hit.action,
      assigned_user: diff_user,
      to_delete,
      act,
      report_id,
      rule_name,
    };

    hit.hit.last_updated = getUTCDateString();

    await fetchPost(`/hit/${hi_id}/assign`, payload);
    updateComments();
  }

  async function saveStatuses(e) {
    if (!($role == "admin" || $role == "user") || !$canEditCurrentStream)
      return; //if user role is not admin or user, do nothing
    const value = e.detail;
    const hitId = hit.hit.id;
    const apiUrl = `/hit/${hitId}/status`;

    if (value === hit.hit.in_report) return;

    hit.hit.in_report = value;
    hit.hit.last_updated = getUTCDateString();

    try {
      switch (value) {
        case "Open":
          await fetchGet(apiUrl);
          break;

        case "Flagged":
          await fetchPatch(apiUrl);
          break;

        case "Closed":
          await fetchDelete(apiUrl);
          break;

        default:
          break;
      }

      dispatch("refresh");
      dispatch("status_updated", value);
      updateComments();
    } catch (error) {
      console.warn("Something went wrong when setting the status.", error);
    }
  }

  async function addComment(hitId) {
    if (comment == "") return;

    try {
      let res = await fetchPost(`/hit/${hitId}/comment`, {
        hit_id: hitId,
        text: comment,
        category: "Text",
      });

      hit.hit.comments = res.comments;
    } catch (error) {
      console.error(error);
    }

    comment = "";
  }

  async function updateComments() {
    const response = await fetchGet(`/hit/${hit.hit.id}/comment`);

    if (!response.success) return;
    hit.hit.comments = response.comments;
    hit = hit;
  }

  function closeDialog(ruleId) {
    if (!maximize) {
      return;
    }

    maximize = false;

    if (!(isOnReportsPage || isOnMyHitsPage)) {
      dispatchCloseEvent();
      return;
    }

    if (isOnReportsPage && $role == "assignee") {
      navigateToMyIssues();
      dispatchCloseEvent(isOpenedByURL);
      return;
    }

    navigateToParentDirectory();
    dispatchCloseEvent(isOpenedByURL);

    if (ruleId) {
      dispatch("highlightRule", ruleId);
    }
  }

  function navigateToMyIssues() {
    const urlArr = window.location.href.split("/").slice(0, -3);
    const newUrl = urlArr.join("/") + "/myissues";
    updateHistoryState(newUrl);
  }

  function navigateToParentDirectory() {
    const url = removeTrailingSlash(window.location.href);
    const urlArr = url.split("/");
    urlArr.pop();
    const newUrl = urlArr.join("/");
    updateHistoryState(newUrl);
  }

  function updateHistoryState(url) {
    window.history.pushState({}, "", url);
  }

  function removeTrailingSlash(url) {
    return url.endsWith("/") ? url.slice(0, -1) : url;
  }

  function dispatchCloseEvent(arg) {
    dispatch("close", arg);
  }

  async function sendAssignedEmail() {
    await refreshHitData();

    try {
      const url = "/email/send/" + assigned_email + "/" + hit.hit.id;
      const response = await fetchGet(url);
      if (!response.success) {
        toast.error("Failed to send email");
        return;
      }

      toast.success("Email Successfully Sent");

      updateComments();
    } catch (error) {
      toast.error("Failed to send email");
    }
  }

  async function sendAssignmentPriority(e) {
    const value = e.detail;
    if (value === hit.hit.priority) return;

    const content = { hit_id: hit.hit.id, priority: value };

    hit.hit.priority = value;
    hit.hit.last_updated = getUTCDateString();

    try {
      const response = await fetchPost(`/hit/${hit.hit.id}/priority`, content);

      if (!response.success) {
        console.warn("Failed to change assignment priority: ", response);
      }

      updateComments();
    } catch (error) {
      console.warn(error);
    }
  }

  function saveAssignedAction(e) {
    if (!($role == "admin" || $role == "user") || !$canEditCurrentStream)
      return; //if user role is not admin or user, do nothing
    if (e.detail == null) hit.hit.action = null;
    else hit.hit.action = e.detail;

    hit.hit.last_updated = getUTCDateString();
    saveActionDetails(hit.hit.id, "action");
  }

  async function changeDoneStatus(e) {
    const { detail } = e;

    try {
      await fetchPatch(`/hit/${hit.hit.id}/done`);
      hit.hit.done = detail;
      hit.hit.last_updated = getUTCDateString();
      dispatch("refresh");

      updateComments();
    } catch (error) {
      console.warn(error);
    }
  }

  function getChannelType(channel) {
    let lowercaseType = channel.toLowerCase();

    if (lowercaseType.includes("facebook")) {
      return "Facebook";
    } else if (lowercaseType.includes("twitter")) {
      return "Twitter";
    } else if (lowercaseType.includes("instagram")) {
      return "Instagram";
    } else if (lowercaseType.includes("meta")) {
      return "Facebook";
    } else if (lowercaseType.includes("linkedin")) {
      return "LinkedIn";
    } else if (lowercaseType.includes("document")) {
      return "Document";
    } else if (lowercaseType.includes("image")) {
      return "Image";
    } else {
      return "Webpage";
    }
  }

  function addHitIdToUrl() {
    if (!(isOnReportsPage || isOnMyHitsPage)) {
      return;
    }

    if (issueIdURL) {
      return;
    }

    const currentUrl = window.location.href;
    const newUrl = currentUrl + "/" + hit["hit"]["id"];
    window.location.href = newUrl;
  }

  let isUpdating = true;

  async function fetchHitDetails() {
    isUpdating = true;

    const response = await fetchGet(`/hit/${hit.hit.id}`);
    hit = hit;

    assignmentPriority = response.hit.priority;
    currentStatus = response.hit.in_report;
    isDone = response.hit.done;

    if (response.hit.title == null || response.hit.title == "") {
      let channel = getChannelType(response.hit.index);
      if (channel == "Document" || channel == "Image")
        modalTitle = response.hit.content_link;
      else modalTitle = response.hit.url;
    } else modalTitle = response.hit.title;

    hit.hit.comments = response.comments;

    isUpdating = false;
  }

  let dialogClicked = false;

  function handleClickOutside() {
    if (dialogClicked) {
      closeDialog();
      dialogClicked = false;
    }
  }

  $: if (maximize) {
    fetchHitDetails();
    // fetchAllLabels();
    addHitIdToUrl();
  }

  let showComments = true;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->

{#if hit["hit"] && maximize}
  <dialog
    class="modal"
    class:modal-open={maximize}
    transition:fade
    on:click={() => (dialogClicked = true)}
  >
    <div
      class="modal-box max-h-[600px] max-w-screen-lg"
      use:clickOutside={handleClickOutside}
    >
      <div
        class="bg-base-200 sticky top-0 z-10 -mx-6 -mb-2 -translate-y-6 border-b px-6 py-4"
      >
        <div class="flex items-center justify-between">
          <h2 class="line-clamp-3 text-2xl font-medium">{modalTitle}</h2>
          <button
            class="btn btn-square btn-ghost btn-sm"
            data-target="modal-example"
            on:click={closeDialog}
          >
            <Icon icon="iconoir:cancel" class="text-lg" />
          </button>
        </div>

        <div>
          <span class="font-semibold">Rule: </span>
          {#if assetView}
            {rule_name}
          {:else}
            <a
              class="link-hover link link-primary"
              href={"/#/stream/" + report_id}
              on:click={() => closeDialog(hit.rule.id)}
            >
              {rule_name}
            </a>
          {/if}

          | <span class="font-semibold">ID:</span> #{hit.hit.id}
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="sm:w-3/5 sm:pr-4">
          <h3 class="text-xl">Hit Preview</h3>
          {#if assetView}
            {@html hit.hit.highlight}
          {:else}
            <Highlight
              displayText={hit.hit.highlight}
              url={hit.hit.content_link != null && !hitContentLinkIsCdn
                ? hit.hit.content_link
                : hit.hit.url}
              field={hit.hit.field}
            />
          {/if}
        </div>

        <div class="sm:w-2/5">
          {#if assetView}
            <h3 class="text-xl">Hit Location</h3>
            <p class="location-text">
              {#if hit.hit.content_link != null}
                <PreviewLink
                  href={hitContentLinkIsCdn
                    ? hit.hit.url
                    : hit.hit.content_link}
                  text={hitContentLinkIsCdn
                    ? "Post Multimedia"
                    : hit.hit.content_link}
                  truncate={false}
                  classes="text-primary"
                /> on
              {/if}
              <PreviewLink
                href={hit.hit.content_link ? hit.hit.content_link : hit.hit.url}
                text={hit.hit.content_link ? hit.hit.content_link : hit.hit.url}
                truncate={false}
                classes="text-primary"
              />
            </p>

            <div class="mt-2 flex gap-2">
              <div class="w-full">
                <p class="font-semibold">Asset Type</p>

                <span class="flex items-center gap-2">
                  <TableType
                    bind:type={hit.hit.index}
                    data_type={getDataType(hit.hit)}
                    data
                  />
                  {getChannelType(hit.hit.index)}
                </span>
              </div>
            </div>
          {:else}
            <h3 class="text-xl">Hit Location</h3>
            <p class="location-text">
              {#if hit.hit.content_link != null}
                <PreviewLink
                  classes="text-primary"
                  href={hitContentLinkIsCdn
                    ? hit.hit.url
                    : hit.hit.content_link}
                  text={hitContentLinkIsCdn
                    ? "Post Multimedia"
                    : hit.hit.content_link}
                  truncate={false}
                /> on
              {/if}
              <PreviewLink
                href={hit.hit.url}
                text={hit.hit.url}
                truncate={false}
                classes="text-primary"
              />
            </p>

            <div class="mt-2 flex gap-2">
              <div class="w-full">
                <p class="font-semibold">Channel</p>

                <span class="flex items-center gap-2">
                  <TableType
                    bind:type={hit.hit.index}
                    data_type={getDataType(hit.hit)}
                    displayInHitModal={true}
                  />
                  {getChannelType(hit.hit.index)}
                </span>
              </div>

              <div class="w-full">
                <p class="font-semibold">Vendor</p>
                {hit.hit.vendor}
              </div>
            </div>
          {/if}
        </div>
      </div>

      <div class="divider my-2" />

      <div class="mb-2 flex gap-2">
        <div class="w-full">
          Action
          <Select
            items={[
              { label: " None", value: null },
              ...actions.map((action) => ({
                label: action,
                value: action,
              })),
            ]}
            selectedValue={hit.hit.action}
            placeholder="Select action"
            classes="w-full"
            on:change={saveAssignedAction}
            disabled={!($role == "admin" || $role == "user") || disableEditing}
          />
        </div>
        <div class="w-full">
          Priority
          <Select
            items={priorities}
            selectedValue={assignmentPriority}
            placeholder="Select Priority"
            classes="w-full"
            on:change={sendAssignmentPriority}
            disabled={!($role == "admin" || $role == "user") || disableEditing}
          />
        </div>
        {#if assetView}
          <!-- TODO: jason to investigate wwhy dev didn't have || !$canEditCurrentStream in the diabled fields below -->
          <div class="w-full">
            Set Status
            <Select
              items={statuses}
              selectedValue={currentStatus}
              placeholder="Select status"
              classes="w-full"
              on:change={saveStatuses}
              disabled={!($role == "admin" || $role == "user") ||
                disableEditing}
            />
          </div>
        {:else}
          <div class="w-full">
            Set Status
            <Select
              items={statuses}
              selectedValue={currentStatus}
              placeholder="Select status"
              classes="w-full"
              on:change={saveStatuses}
              disabled={!($role == "admin" || $role == "user") ||
                disableEditing}
            />
          </div>
        {/if}
      </div>

      <div class="mb-2 flex gap-2">
        <div class="w-full">
          Assigned Users
          <AssignedUserSelect
            hitId={hit.hit.id}
            on:assign_state_updated={(event) => {
              updateComments();
              dispatch("assign_state_updated");
              hit.assigned_users = event.detail;
            }}
            disabled={!($role == "admin" || $role == "user") ||
              !$canEditCurrentStream ||
              disableEditing}
          />
        </div>
        <div class="flex flex-col">
          <span class="whitespace-nowrap">Set Completion</span>
          <div
            class="tooltip tooltip-left"
            data-tip="Mark as {isDone ? 'Not' : ''} Complete"
          >
            <input
              type="checkbox"
              class="toggle toggle-primary mx-auto my-[7px]"
              bind:checked={isDone}
              on:click={changeDoneStatus}
              disabled={disableEditing}
            />
          </div>
        </div>
      </div>

      <div>
        <div class="mb-2 flex gap-2">
          Assigned Email
          <p class="text-base-content/70 ml-auto">
            {#if !assigned_email && !hit?.assigned_users?.length}
              *Please select an email template, also at least one user must be
              assigned
            {/if}
            {#if !assigned_email && hit?.assigned_users?.length}
              *Please select an email template
            {/if}
            {#if assigned_email && !hit?.assigned_users?.length}
              *At least one user must be assigned
            {/if}
          </p>
        </div>

        <div class="mb-2 flex gap-2">
          <Select
            items={$email_list}
            bind:selectedValue={assigned_email}
            placeholder="Select email template"
            classes="w-full"
            disabled={disableEditing}
          />
          <button
            class="btn btn-primary btn-sm"
            disabled={!assigned_email ||
              !hit?.assigned_users?.length ||
              disableEditing}
            on:click={sendAssignedEmail}
          >
            <Icon icon="iconoir:send-mail" /> Send email
          </button>
        </div>
      </div>

      <div class="divider">
        <button
          class="btn btn-ghost btn-xs"
          on:click={() => (showComments = !showComments)}
        >
          <Icon icon="iconoir:nav-arrow-{showComments ? 'up' : 'down'}" />
          {showComments ? "Hide" : "Show"} Comments
        </button>
      </div>

      {#if showComments}
        <div class="mb-2 flex flex-col gap-2" transition:slide>
          <Comments
            comments={hit.hit.comments}
            apiEndpoint={`/hit/${hit.hit.id}/comment`}
            type="live"
            on:updateComments={async (event) => {
              hit.hit.comments = event.detail;
              await updateComments();
            }}
          />
        </div>
        <!-- <div class="mb-2 flex flex-col gap-2" transition:slide>
          <textarea
            class="textarea textarea-bordered w-full"
            id="comment"
            name="comment"
            placeholder="Comment"
            bind:value={comment}
            on:keypress={(e) => {
              if (e.shiftKey && e.key === "Enter") addComment(hit.hit.id);
            }}
            disabled={disableEditing}
          />
          <button
            class="btn btn-primary btn-sm ml-auto"
            disabled={!comment || disableEditing}
            on:click={addComment(hit.hit.id)}
          >
            <Icon icon="iconoir:message-text" /> Comment
          </button>
        </div> -->

        <!-- <footer class="text-base" transition:slide>
          {#if hit.hit.comments}
            <CommentsView comments={hit.hit.comments} />
          {/if}
        </footer>  -->
      {/if}
    </div>
  </dialog>
{/if}
