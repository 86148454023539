<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchDelete } from "../../helpers";
  import ActionConfirmation from "../ActionConfirmation.svelte";

  const dispatch = createEventDispatcher();

  export let ruleSet;
  export let ruleSetIdToDelete;

  let deleting = false;

  async function deleteChecklist(id) {
    deleting = true;

    try {
      if (!id) {
        throw new Error(
          `Cannot delete RuleSet because Parameter(id) is undefined.`,
        );
      }

      const res = await fetchDelete("/ruleset/delete", {
        id,
        owner: ruleSet.rule_set?.owner,
      });

      if (!res.success) {
        throw new Error(
          `Unable to delete RuleSet with Id(${id}) due to error.`,
        );
      }

      dispatch("deleteRuleSet");
      ruleSetIdToDelete = id;
      document.getElementById(`delete-checklist-${id}`).close();
    } catch (e) {
      toast.error("Something went wrong while deleting the checklist.");
      deleting = false;
      return;
    }

    deleting = false;
  }
</script>

<ActionConfirmation
  modalId="delete-checklist-{ruleSet.rule_set?.id}"
  message="Are you sure you want to delete this checklist?"
  onConfirm={() => deleteChecklist(ruleSet.rule_set?.id)}
  onCancel={() => {
    document.getElementById(`delete-checklist-${ruleSet.rule_set?.id}`).close();
  }}
  showLoadingSpinner={true}
  loading={deleting}
/>
