import { toast } from "svelte-sonner";
import { push } from "svelte-spa-router";
import { fetchDelete, fetchGet, fetchPost } from "../helpers";
import { isAuthenticated } from "../stores";

/* ************************** */
/* Label management functions */
/* ************************** */

export function labelSelected(event, labelData, report_id) {
  const selectedLabel = event.detail;

  if (!labelData.labels.some((label) => label.id === selectedLabel.id)) {
    fetchPost(`/label/assign/${selectedLabel.id}`, { report_id });

    labelData.labels = [...labelData.labels, selectedLabel];
  }
}

export function deleteLabel(event, labelData, report_id) {
  const label = event.detail;

  fetchDelete(`/label/assign/${label.id}`, { report_id });

  labelData.labels = labelData.labels.filter((l) => l.id !== label.id);
}

/* ************* */
/* Data fetching */
/* ************* */

//TODO: added a fetch with timeout and special spelling function
function fetchWithTimeout(url, options, timeout = 30000) {
  // Default timeout set to 30 seconds
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  return fetch(url, { ...options, signal: controller.signal })
    .then((response) => {
      clearTimeout(id);
      return response;
    })
    .catch((error) => {
      clearTimeout(id);
      throw error;
    });
}

export async function fetchDataAndUpdateDisplaySpelling(
  shouldReset = false,
  reportData,
  loading,
  report_id,
  reportDetails,
) {
  if (loading.state) {
    console.log("Already fetching data.");
    return;
  }

  loading.state = true;

  setReportDetails(report_id, reportDetails);

  if (shouldReset) {
    reportData.tableData = [];
    reportData.hitCountInRule = {};
  }

  try {
    const response = await fetchWithTimeout(`/report/${report_id}`);
    const responseJSON = await response.json();
    const data = responseJSON.data;

    reportData.allRules = data.map((report) => report.rule);
    const newData = [];

    data.forEach((rule) => {
      reportData.hitCountInRule[rule.rule.id] =
        (reportData.hitCountInRule[rule.rule.id] || 0) + rule.results.length;

      if (response.status !== 200) {
        throw new Error(`Error fetching data. Status code: ${response.status}`);
      }

      data.forEach((rule) => {
        reportData.hitCountInRule[rule.rule.id] =
          (reportData.hitCountInRule[rule.rule.id] || 0) + rule.results.length;

        rule.results.forEach((hit) => {
          hit["rule"] = rule.rule;
          hit["report"] = {
            id: report_id,
            name: reportDetails.name,
            description: reportDetails.description,
            status: reportDetails.status,
          };

          newData.push(hit);
        });
      });
    });

    reportData.tableData.push(...newData);
  } catch (error) {
    console.warn("WARNING: Data fetching error due to", error);
  } finally {
    loading.state = false;
  }
}

export async function fetchDataAndUpdateDisplay(
  shouldReset = false,
  reportData,
  loading,
  report_id,
  reportDetails,
) {
  if (loading.state) {
    //console.log("Already fetching data.");
    return;
  }

  loading.state = true;

  setReportDetails(report_id, reportDetails);

  if (shouldReset) {
    reportData.tableData = [];
    reportData.hitCountInRule = {};
  }

  try {
    const response = await fetch(`/report/${report_id}`);
    const responseJSON = await response.json();
    const data = responseJSON.data;

    reportData.allRules = data.map((report) => report.rule);
    const newData = [];

    data.forEach((rule) => {
      reportData.hitCountInRule[rule.rule.id] =
        (reportData.hitCountInRule[rule.rule.id] || 0) + rule.results.length;

      if (response.status !== 200) {
        throw new Error(`Error fetching data. Status code: ${response.status}`);
      }

      data.forEach((rule) => {
        reportData.hitCountInRule[rule.rule.id] =
          (reportData.hitCountInRule[rule.rule.id] || 0) + rule.results.length;

        rule.results.forEach((hit) => {
          hit["rule"] = rule.rule;
          hit["report"] = {
            id: report_id,
            name: reportDetails.name,
            description: reportDetails.description,
            status: reportDetails.status,
          };

          newData.push(hit);
        });
      });
    });

    reportData.tableData.push(...newData);
  } catch (error) {
    console.warn("WARNING: Data fetching error due to", error);
  } finally {
    loading.state = false;
  }
}

const logoutUser = async () => {
  isAuthenticated.set(false);
  localStorage.setItem("logged_in", false);
  localStorage.setItem("accessToken", "");
  await fetchGet(`/auth/logout`);
  push("/home");
};

async function setReportDetails(report_id, reportDetails) {
  const res = await fetchGet(`/report/details/${report_id}`);

  if (res.success === false) {
    if (res.message === "Unauthorized") logoutUser();
    else {
      toast.error(res.message);
      push("/streams");
    }
    return;
  }

  reportDetails.name =
    res.report.name || (isAsset ? "Untitled Folder" : "Untitled Stream");
  reportDetails.status = res.report.status || "Draft";
  reportDetails.description = res.report.description || "";
  reportDetails.report_labels = res.report.label_ids || [];
}

export async function getLabels(labelData, reportDetails) {
  const labelResponse = await fetchGet("/label");

  if (labelResponse.success) {
    labelData.allLabels = await labelResponse.labels;
    labelData.labels = labelData.allLabels.filter((label) => {
      return reportDetails.report_labels.includes(label.id);
    });
  }
}

/* **************** */
/* Action Functions */
/* **************** */

export async function saveDetails(
  report_id,
  reportDetails,
  isInitialised,
  currentUserId,
) {
  /*For some reason inserting these directly into the function below does not work*/
  let name = reportDetails.name;
  let description = reportDetails.description;
  let status = reportDetails.status;

  let response = await fetchPost("/report/1", {
    report_id,
    name,
    description,
    status,
  });

  if (!isInitialised?.state) {
    await fetchPost(`/user/${currentUserId}/report/${report_id}`, {
      permission: "edit",
    });

    isInitialised = {
      ...isInitialised,
      state: true,
    };
  }

  if (response.success) return true;
  else return false;
}

export const deleteStream = async (report_id, spellingReport = false) => {
  const res = await fetchDelete("/report/" + report_id);
  if (res.success) push(spellingReport ? "/spelling" : "/streams");
};

export function createReportClone(report_id) {
  fetchGet(`/report/clone/${report_id}`)
    .then((res) => {
      if (res.success) window.open(`/#/stream/edit/${res.report_id}`, "_blank");
    })
    .catch((error) => console.log(error));
}

export const requestScrape = async (report_id) => {
  const res = await fetchGet(`/report/scrape/${report_id}`);

  if (res.success) toast.success("Re-scrape request sent successfully.");
  else toast.error(res.message ? res.message : "Re-scrape request failed.");
};
