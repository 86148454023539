<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { link, push } from "svelte-spa-router";
  import { fetchDelete, fetchGet, fetchPost } from "../helpers";
  import { currentUser } from "../stores";
  import LabelManager from "./Labels/LabelManager.svelte";

  const dispatch = createEventDispatcher();

  export let report;
  export let labels = [];

  let inReviewAssets = 0;
  let approvedAssets = 0;
  let changesRequestedAssets = 0;
  let pendingAssets = 0;
  let roadblockAssets = 0;
  let currentStreamPerm = "none"; // in future while setting currentStreamPerm if  status == "Archived" set as view only

  function parseDate(d) {
    if (d) {
      return d.split(":").slice(0, -1).join(":");
    }
    return "";
  }


  onMount(async () => {
    // Get Uploaded Files
    // try {
    //   //const response = await fetchGet("/asset/" + report.id);
    //   if (response.success) {
    for (let i = 0; i < report.assets.length; i++) {
      const workflowStepName = report.assets[i].workflow_step[0]?.name;

      if (workflowStepName === "Uploaded") {
        pendingAssets++;
      } else if (workflowStepName === "Approved") {
        approvedAssets++;
      } else {
        inReviewAssets++;
      }
    }

    // Set Folder Card Permissions
    if ($currentUser.role == "admin") currentStreamPerm = "edit";
    else {
      const userPerm = await fetchGet(
        `/report/${report.id}/get-user-permission-to-report`,
      );

      if (userPerm.permission === "none") {
        const orgPerm = await fetchGet(
          `/report/${report.id}/get-org-permission`,
        );
        if (orgPerm.permission === "none") push("/streams");
        else if (["view", "edit", "upload"].includes(orgPerm.permission))
          currentStreamPerm = orgPerm.permission;
      } else {
        if (["view", "edit", "upload"].includes(userPerm.permission))
          currentStreamPerm = userPerm.permission;
      }
    }
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }
</script>

<article
  class="bg-base-100 relative flex min-w-60 flex-col justify-between rounded border p-4"
>
  <div>
    <div
      class="flex items-center {!(
        report.description && report.description.trim().length > 0
      )
        ? 'pb-1'
        : ''}"
    >
      <div class="tooltip max-w-[calc(100%-96px)]" data-tip={report.name}>
        <a
          class="block overflow-hidden text-ellipsis whitespace-nowrap text-start text-lg font-semibold"
          href="#/review/{report.id}"
          use:link
        >
          {report.name}
        </a>
      </div>
      <div class="ml-auto inline-flex">
        {#if currentStreamPerm == "edit"}
          <div class="tooltip" data-tip="Edit settings">
            <a
              href="#/review/edit/{report.id}"
              class="btn btn-square btn-ghost btn-sm"
            >
              <Icon icon="iconoir:folder-settings" />
            </a>
          </div>
        {/if}
        {#if currentStreamPerm == "edit" || currentStreamPerm == "upload"}
          <div class="tooltip" data-tip="Upload documents">
            <a
              href="#/review/edit/{report.id}?step=upload"
              class="btn btn-square btn-ghost btn-sm"
            >
              <Icon icon="iconoir:upload" />
            </a>
          </div>
        {/if}
        {#if currentStreamPerm == "edit"}
          <div class="tooltip tooltip-left" data-tip="Manage checklist">
            <a
              href="#/review/edit/{report.id}?step=checklist"
              class="btn btn-square btn-ghost btn-sm"
            >
              <Icon icon="iconoir:clipboard-check" />
            </a>
          </div>
        {/if}
      </div>
    </div>

    <div>
      {#if report.description && report.description.trim().length > 0}
        <p class="text-base-content/70 py-2 text-sm">{report.description}</p>
      {/if}

      <div class="flex flex-wrap items-center gap-2">
        <LabelManager
          bind:selectedLabels={labels}
          reportId={report.id}
          disabled={currentStreamPerm !== "edit"}
          showSearch={currentStreamPerm === "edit"}
        />
      </div>

      <div class="text-base-content/80 m-4 space-y-2 text-sm">
        <div class="flex items-center justify-between gap-2">
          <p class="inline-flex">Uploaded Assets:</p>
          <span
            class="bg-base-200 w-12 items-center rounded-full px-4 py-1 text-right"
          >
            {pendingAssets}
          </span>
        </div>
        <div class="flex items-center justify-between gap-2">
          <p class="inline-flex">Assets In Review:</p>
          <span
            class="bg-base-200 w-12 items-center rounded-full px-4 py-1 text-right"
          >
            {inReviewAssets}
          </span>
        </div>
        <!-- <div class="flex items-center gap-2 justify-between">
        <p class="inline-flex">Assets With Changes Requested:</p>
        <span
          class="inline-flex w-16 items-center rounded bg-base-200 px-4 py-1"
        >
          {changesRequestedAssets}
        </span>
      </div> -->
        <div class="flex items-center justify-between gap-2">
          <p class="inline-flex">Approved Assets:</p>
          <span
            class="bg-base-200 w-12 items-center rounded-full px-4 py-1 text-right"
          >
            {approvedAssets}
          </span>
        </div>
      </div>
      <!-- <div class="flex items-center gap-2 justify-between">
        <p class="inline-flex">Roadblocked Assets:</p>
        <span
          class="inline-flex w-16 items-center rounded bg-base-200 px-4 py-1"
        >
          {roadblockAssets}
        </span>
      </div> -->
    </div>
  </div>
  <div
    class="text-base-content/60 flex justify-between border-t-2 pt-2 text-xs"
  >
    <span>Date Created {formatDate(report.created_date)}</span>
    <span>Last Updated {formatDate(report.last_refreshed)}</span>
  </div>
</article>
