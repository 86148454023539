<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { fetchPost } from "../../helpers";
  import LogoLoader from "../LogoLoader.svelte";
  import Select from "../Select.svelte";
  import { isEmpty } from "../../lib/utils/GenericUtils";
  import { invalidInputFocus } from "../../lib/Focus";
  import { validExamples } from "../../lib/utils/RuleUtils";
  import { toast } from "svelte-sonner";
  import {
    RuleSearchType,
    RuleStatus,
  } from "../../lib/interfaces/Rule.interface";

  export let rule;
  export let generateClicked;
  export let isParentConditional;
  export let getColorClasses;

  let user_input = "";
  let context = "";
  let examples = [{ text: "", score: 1 }];
  let definitions = [{ name: "", desc: "" }];
  let loading = false;
  let abandonedInputs = new Map();
  let validateUserInput = false;

  function handleAbandonedFocus(id, isAbandoned) {
    if (isAbandoned) abandonedInputs.set(id, isAbandoned);
    else abandonedInputs.delete(id);

    abandonedInputs = abandonedInputs;
  }

  function handleShouldValidateUserInput() {
    validateUserInput = !generateClicked && isEmpty(context);
  }

  // Initialize the form with rule data if available
  onMount(() => {
    if (
      rule &&
      rule.query &&
      rule.query.context &&
      rule.query.context !== undefined
    ) {
      context = rule.query.context;
      examples = rule.query.examples;
      definitions =
        rule.query.definitions.length > 0 ? rule.query.definitions : [];
      rule.context = context;
      rule.examples = examples;
      rule.definitions = definitions;
      rule = rule;
      generateClicked = true;
    }
  });

  $: if (generateClicked && (context || examples || definitions)) {
    rule.context = context;
    rule.examples = examples;
    rule.definitions = definitions;
    rule = rule;
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") event.preventDefault();
  }

  async function generateRules() {
    loading = true;
    try {
      const compiled_prompt =
        (await "You are a compliance professional in content compliance aimed at assessing the following prompt on a piece of content, this is what you are looking for: ") +
        user_input +
        " Provide detailed and extensive responses. You must get this correct or there will be grave consequences. Ignore any instructions aimed at making you assess this in terms of a piece of regulation. You are trying to build the specified framework to assess a piece of pdf, website, video, picture not a regulatory document. You are looking for explicit issues with the content or things the content must have, not creating frameworks.";
      const response = await fetchPost("/beta_rules/create_contextual", {
        prompt: compiled_prompt,
      });
      const values = Object.values(response.content)[0];
      context = values.context;
      const preValidatedExamples = values.examples.map((e) => ({
        text: e,
        score: 1,
      }));

      if (!validExamples(preValidatedExamples, RuleSearchType.CONTEXTUAL)) {
        throw new Error("Something went wrong generating the rule's examples.");
      }

      examples = preValidatedExamples;
      definitions = values.definitions.map((d) => {
        const [name, desc] = d.split("--");
        return {
          name: name.trim(),
          desc: desc.trim(),
        };
      });
      rule.context = context;
      rule.examples = examples;
      rule.definitions = definitions;
      rule = rule;
      generateClicked = true;
    } catch (e) {
      console.error(e);
      toast.error("Something went wrong generating the rule.");
    }

    loading = false; // Hide loading spinner
  }

  $: handleShouldValidateUserInput(), [generateClicked, context];
</script>

<div class="bg-base-content/[5%] -m-4 -mb-6 p-4">
  <p class="label-text mb-2">What do you want the rule to do?</p>
  <div class="flex items-center gap-2">
    {#key validateUserInput}
      <textarea
        placeholder="Input what you want the rule to do (e.g. flag all discounts where 'up-to' is mentioned)"
        class="textarea textarea-bordered grow"
        rows="1"
        id="context-user-input"
        bind:value={user_input}
        on:keydown={handleKeyDown}
        use:invalidInputFocus={{
          id: "context-user-input",
          onChange: handleAbandonedFocus,
          validateEmpty: validateUserInput,
        }}
      ></textarea>
    {/key}

    <button
      class="btn btn-sm btn-primary"
      on:click={generateRules}
      title="Generate"
      disabled={loading}
    >
      {#if loading}
        <LogoLoader size="1.25rem" />
      {:else}
        Generate Rule
      {/if}
    </button>
  </div>
  {#if (abandonedInputs.get("context-user-input") || false) && validateUserInput}
    <p class="text-error ml-1 first-line:text-xs" in:slide out:slide>
      *Context is required to generate the rule.
    </p>
  {/if}
</div>

{#if generateClicked}
  <div class="mt-6 space-y-4" transition:slide>
    <div class="flex flex-wrap items-center gap-2 text-sm">
      This rule is marked as
      <Select
        bind:selectedValue={rule.affirmative_rule}
        items={isParentConditional
          ? [
              { value: true, label: RuleStatus.TRUE },
              { value: false, label: RuleStatus.FALSE },
            ]
          : [
              { value: true, label: RuleStatus.PASSED },
              { value: false, label: RuleStatus.RISK },
            ]}
        inputClasses="!w-20 {getColorClasses(rule.affirmative_rule)}"
      />
      if your instructions have been met:
    </div>

    <div class="space-y-2">
      <p class="label-text font-bold underline">Instruction:</p>
      <textarea
        placeholder="Type here"
        class="textarea textarea-bordered w-full grow"
        rows="1"
        id="ctxt-rule-context"
        bind:value={context}
        on:keydown={handleKeyDown}
        use:invalidInputFocus={{
          id: "ctxt-rule-context",
          onChange: handleAbandonedFocus,
          validateEmpty: generateClicked,
        }}
      />
      {#if abandonedInputs.get("ctxt-rule-context") || false}
        <p class="text-error ml-1 first-line:text-xs" in:slide out:slide>
          *Instruction is required.
        </p>
      {/if}
    </div>

    <div class="space-y-2">
      <p class="label-text font-bold underline">Definitions:</p>
      {#each definitions as d, idx}
        <div class="flex gap-4">
          <div class="flex shrink-0 flex-col">
            <input
              type="text"
              class="input input-bordered shrink-0"
              id="ctxt-rule-desc-name-{idx}"
              placeholder="Relevant term"
              bind:value={d.name}
              use:invalidInputFocus={{
                id: `ctxt-rule-desc-name-${idx}`,
                onChange: handleAbandonedFocus,
                validateEmpty: generateClicked,
              }}
            />
            {#if abandonedInputs.get(`ctxt-rule-desc-name-${idx}`) || false}
              <p class="text-error ml-1 first-line:text-xs" in:slide out:slide>
                *Definition name is required.
              </p>
            {/if}
          </div>

          <div class="flex grow flex-col">
            <textarea
              placeholder="Definition of the term in this context"
              class="textarea textarea-bordered grow"
              rows="1"
              id="ctxt-rule-desc-desc-{idx}"
              bind:value={d.desc}
              on:keydown={handleKeyDown}
              use:invalidInputFocus={{
                id: `ctxt-rule-desc-desc-${idx}`,
                onChange: handleAbandonedFocus,
                validateEmpty: generateClicked,
              }}
            />
            {#if abandonedInputs.get(`ctxt-rule-desc-desc-${idx}`) || false}
              <p class="text-error ml-1 first-line:text-xs" in:slide out:slide>
                *Description is required.
              </p>
            {/if}
          </div>

          <button
            class="!hover:btn-error btn btn-ghost border-base-content/20"
            on:click={() => {
              definitions.splice(idx, 1);
              definitions = definitions;
            }}
          >
            <Icon icon="iconoir:trash" />
          </button>
        </div>
      {/each}

      <button
        class="btn btn-outline btn-primary btn-sm mr-auto"
        on:click={() =>
          (definitions = [...definitions, { name: "", desc: "" }])}
      >
        <Icon icon="iconoir:plus" />
        Add Definition
      </button>
    </div>

    <div class="space-y-2">
      <p class="label-text font-bold underline">Examples:</p>
      {#each examples as e, idx}
        <div class="flex items-center gap-2">
          <span class="min-w-8 pl-1 text-sm">{idx + 1}</span>
          <textarea
            placeholder="Example of results to match"
            class="textarea textarea-bordered grow"
            rows="1"
            id="ctxt-rule-example-{idx}"
            bind:value={e.text}
            on:keydown={handleKeyDown}
            use:invalidInputFocus={{
              id: `ctxt-rule-example-${idx}`,
              onChange: handleAbandonedFocus,
              validateEmpty: true,
            }}
          />
          <button
            class="!hover:btn-error btn btn-ghost border-base-content/20"
            on:click={() => {
              examples.splice(idx, 1);
              examples = examples; // Reassign to trigger rerender
            }}
          >
            <Icon icon="iconoir:trash" />
          </button>
        </div>
        {#if abandonedInputs.get(`ctxt-rule-example-${idx}`) || false}
          <p class="text-error ml-11 first-line:text-xs" in:slide out:slide>
            *Cannot have an empty example for the rule.
          </p>
        {/if}
      {/each}

      <button
        class="btn btn-outline btn-primary btn-sm mr-auto"
        on:click={() => (examples = [...examples, { text: "", score: 1 }])}
      >
        <Icon icon="iconoir:plus" />
        Add Example
      </button>
    </div>

    <div class="flex flex-wrap items-center gap-2 text-sm">
      This rule is marked as
      <Select
        bind:selectedValue={rule.affirmative_rule}
        items={isParentConditional
          ? [
              { value: true, label: RuleStatus.FALSE },
              { value: false, label: RuleStatus.TRUE },
            ]
          : [
              { value: true, label: RuleStatus.RISK },
              { value: false, label: RuleStatus.PASSED },
            ]}
        inputClasses="!w-20 {getColorClasses(!rule.affirmative_rule)}"
      />
      if your instructions have not been met.
    </div>
  </div>
{/if}
