<script>
  import { createEventDispatcher } from "svelte";
  import {
    RuleDataType,
    RuleSearchType,
  } from "../../lib/interfaces/Rule.interface";
  import AssetRuleBuilder from "../AssetReview/AssetRuleBuilder.svelte";
  import AssetRuleTypeSelector from "../AssetReview/AssetRuleTypeSelector.svelte";
  import Modal from "../Modals/Modal.svelte";

  const dispatch = createEventDispatcher();

  export let rule;

  let ruleSetSearch = "";
  let selectableRuleSets = [];
  let originalRuleBoolean = rule["boolean"];
  let originalRuleName = rule["name"];
  let originalRuleDescription = rule["description"];
  let originalRuleDataTypes = rule["data_type"];
  let originalDefaultHitPriority = rule["default_hit_priority"];

  let savingRule = false;
  let selectedDataTypes = rule["data_type"].split(", ");

  let data = {
    loaded: false,
    index: -1,
    name: rule.name,
    type: RuleDataType.BUILDER,
    description: rule.description,
    remediation_step: rule.remediation_step,
    data_types: selectedDataTypes,
    size: 10000,
    boolean: rule.boolean,
    builder: [],
  };
  let ruleSetActionQueue = [];

  function cancelEdit() {
    rule["boolean"] = originalRuleBoolean;
    rule["name"] = originalRuleName;
    rule["description"] = originalRuleDescription;
    rule["data_type"] = originalRuleDataTypes;
    rule["default_hit_priority"] = originalDefaultHitPriority;

    dispatch("editCancelled");

    document.querySelector(`#ruleEdit-${rule?.id}`).close();
  }

  function handleSectionQuery(rule) {
    if (rule.search_type === RuleSearchType.BOOLEAN) {
      // Checks if section_query is defined and not empty
      if (rule.section_query && rule.section_query.trim().length > 0) {
        return rule.section_query + " AND " + rule.lexical;
      } else {
        return rule.lexical || ""; // Fallback to lexical or empty string if lexical is undefined
      }
    }
    return rule.section_query || ""; // Return existing section_query or empty string if undefined
  }

  let assetRuleStep = 2;
  let editingSavedRule = true;
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-label-has-associated-control -->
<!-- svelte-ignore a11y-click-events-have-key-events -->

<Modal modalId="ruleEdit-{rule?.id}" size="xl" on:close={cancelEdit}>
  <h3 class="text-xl font-semibold">Edit Rule</h3>

  {#if assetRuleStep === 1}
    <AssetRuleTypeSelector bind:value={rule.rule_type} bind:rule />

    <button
      on:click={() => (assetRuleStep = 2)}
      class="btn btn-primary btn-sm mt-2"
    >
      Next
    </button>
  {:else}
    <AssetRuleBuilder
      bind:rule
      {savingRule}
      bind:editingSavedRule
      on:removeRule={cancelEdit}
      on:saveRule={(e) => dispatch("save", rule)}
      on:back={() => (assetRuleStep = 1)}
    />
  {/if}
</Modal>
