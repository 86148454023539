<script>
  import Icon from "@iconify/svelte";

  export let type;
  export let data_type = "";
  export let classes = "";

  function getComponent(t, dt) {
    if (!t) return "iconoir:internet";

    let lowercaseType = t.toLowerCase();
    let lowercaseDt = dt.toLowerCase();

    if (lowercaseType.includes("facebook") || lowercaseType.includes("meta"))
      return "iconoir:facebook";
    else if (lowercaseType.includes("twitter")) return "iconoir:twitter";
    else if (lowercaseType.includes("instagram")) return "iconoir:instagram";
    else if (lowercaseType.includes("linkedin")) return "iconoir:linkedin";
    else if (lowercaseType.includes("document")) return "iconoir:empty-page";
    else if (lowercaseType.includes("image")) return "iconoir:media-image-list";
    else if (lowercaseType.includes("tiktok")) return "iconoir:tiktok";
    else if (lowercaseType.includes("youtube")) return "iconoir:youtube";
    else if (lowercaseDt.includes("video")) return "iconoir:media-video";
    else if (lowercaseDt.includes("audio")) return "iconoir:sound-high";
    else return "iconoir:internet";
  }
</script>

<Icon icon={getComponent(type, data_type)} class={classes} />
