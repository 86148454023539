<script>
  import Icon from "@iconify/svelte";
  import { toast } from "svelte-sonner";
  import { fetchPatch } from "../../helpers";
  import { capitalise } from "../../lib/utils/GenericUtils";
  import { enableApprovedStage, enableUploadedStage } from "../../stores";

  let defaultStagesMap = [
    {
      label: "Uploaded",
      name: "uploaded",
      enabled: $enableUploadedStage,
      information:
        "If disabled, Assets will go straight into the next status (usually 'In Review').",
    },
    {
      label: "Approved",
      name: "approved",
      enabled: $enableApprovedStage,
      information: "If disabled, Assets will stay in the final Review stage.",
    },
  ];
  let updating = false;

  async function handleToggleDefaultStage(settingName) {
    updating = true;
    const errorMessage = `Something went wrong updating settings for Stage(${capitalise(settingName)})`;

    try {
      const response = await fetchPatch("/organisation/settings", {
        setting: settingName,
      });

      if (!response.success) {
        throw new Error(response.message ?? errorMessage);
      }

      let stage = defaultStagesMap.find((stage) => stage.name === settingName);

      if (!stage) {
        throw new Error(errorMessage);
      }

      switch (settingName) {
        case "uploaded":
          enableUploadedStage.set(stage.enabled);
          break;
        case "approved":
          enableApprovedStage.set(stage.enabled);
          break;
        default:
          throw new Error(errorMessage);
      }
    } catch (e) {
      console.error(e);
      toast.error(errorMessage);
    }
    updating = false;
    toast.success("Changes saved.");
  }
</script>

<div class="overflow-x-auto">
  <table
    class="text-base-content/80 table border-collapse text-sm *:h-12 *:min-w-16"
  >
    <thead>
      <tr class="text-base-content/90 border-0 *:text-sm *:font-semibold">
        <th class="w-1/4">Stage</th>
        <th class="w-1/4">Action</th>
        <th class="min-w-60 whitespace-normal">Notes</th>
      </tr>
    </thead>

    <tbody>
      {#each defaultStagesMap as stage}
        <tr class="border-0">
          <td>
            {stage.label}
          </td>
          <td>
            <input
              class="toggle toggle-primary"
              type="checkbox"
              disabled={updating}
              bind:checked={stage.enabled}
              on:change={async () => {
                await handleToggleDefaultStage(stage.name);
              }}
            />
          </td>
          <td>
            {stage.information}
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>
