<script>
  import { disabledOrganisationModules } from "./../../stores.js";
  import Icon from "@iconify/svelte";
  import {
    AssetRuleTypeOptions,
    RuleSearchType,
  } from "../../lib/interfaces/Rule.interface";
  import { isParentConditionalRule } from "../../lib/utils/RuleUtils.js";

  export let value;
  export let rule;
  export let hideConditionalRule = false;
  export let isConditionalRule = false;
  export let isParentConditional = false;

  const updateType = (id) => {
    if (value === id) return;

    if (
      // exact to similarity conversion
      value === RuleSearchType.LEXICAL &&
      id === RuleSearchType.TENSOR
    ) {
      rule.examples = rule.lexical
        .split(" OR ")
        .map((e) => ({ text: e.replaceAll('"', ""), score: 1 }));
      rule.lexical = "";
    } else if (
      // similarity to exact conversion
      value === RuleSearchType.TENSOR &&
      id === RuleSearchType.LEXICAL
    ) {
      rule.lexical = rule.examples.map((e) => `"${e.text}"`).join(" OR ");
      rule.examples = [{ text: "", score: 1 }];
    } else if (
      // exact to manual
      value === RuleSearchType.LEXICAL &&
      id === RuleSearchType.MANUAL
    ) {
      rule.examples = rule.lexical.replaceAll(" OR ", " AND ");
      rule.lexical = "";
    } else if (
      // manual to exact
      value === RuleSearchType.MANUAL &&
      id === RuleSearchType.LEXICAL
    ) {
      rule.lexical = rule.examples.replaceAll(" AND ", " OR ");
      rule.examples = [{ text: "", score: 1 }];
    } else if (
      // similarity to manual
      value === RuleSearchType.TENSOR &&
      id === RuleSearchType.MANUAL
    ) {
      rule.examples = rule.examples.map((e) => `"${e.text}"`).join(" AND ");
      rule.lexical = "";
    } else if (
      // manual to similarity
      value === RuleSearchType.MANUAL &&
      id === RuleSearchType.TENSOR
    ) {
      rule.examples = rule.examples
        .split(" AND ")
        .map((e) => ({ text: e.replaceAll('"', ""), score: 1 }));
      rule.lexical = "";
    } else {
      // for all other cases
      rule.examples = [{ text: "", score: 1 }];
      rule.lexical = "";
    }

    rule.doc_query = "";
    rule.boolean = "";
    rule.section_query = "";
    rule.lexical = "";

    if (!isConditionalRule) {
      rule.rule_type = id;
    }

    rule.search_type = id;
    value = id;

    // TODO: we should notify that switching between rule types will reset rule data
  };

  function validateAssetRuleTypeOptions(section = 0) {
    return AssetRuleTypeOptions.filter((opt) => {
      let validOption = true;

      if ($disabledOrganisationModules.some((dm) => dm === opt.id))
        validOption = false;
      if (hideConditionalRule && opt.id === RuleSearchType.CONDITIONAL)
        validOption = false;
      if (isParentConditional && opt.id === RuleSearchType.MANUAL)
        validOption = false;
      if (
        section !== opt.section &&
        $disabledOrganisationModules.every(
          (dm) => dm !== "new_rule_type_selector",
        )
      )
        validOption = false;

      return validOption;
    });
  }
</script>

<p>Select one of the following</p>

{#if $disabledOrganisationModules.some((dm) => dm === "new_rule_type_selector")}
  <div class="flex flex-col gap-2">
    {#each validateAssetRuleTypeOptions() as t}
      <button
        class="hover:border-primary flex items-center gap-4 rounded border p-4 text-start"
        class:bg-primary={value === t.id}
        class:border-primary={value === t.id}
        class:text-base-100={value === t.id}
        on:click={() => updateType(t.id)}
      >
        <Icon icon={t.icon} class="text-4xl" />

        <div>
          <p class="font-semibold">{t.name}</p>
          <p class="text-xs opacity-70">{t.desc}</p>
        </div>
      </button>
    {/each}
  </div>
{:else}
  <div class="mt-2 flex flex-col gap-2">
    {#each validateAssetRuleTypeOptions(0) as t}
      <button
        class="hover:border-primary flex items-center gap-4 rounded border p-4 text-start"
        class:bg-primary={value === t.id}
        class:border-primary={value === t.id}
        class:text-base-100={value === t.id}
        on:click={() => updateType(t.id)}
      >
        <Icon icon={t.icon} class="text-4xl" />

        <div>
          <p class="font-semibold">{t.name}</p>
          <p class="text-xs opacity-70">{t.desc}</p>
        </div>
      </button>
    {/each}
  </div>

  <div class="mt-2 flex flex-col gap-2">
    <div class="collapse-arrow border-base-300 bg-base-200 collapse border">
      <input type="checkbox" class="peer" />
      <div class="collapse-title font-semibold">More Rules</div>
      <div class="collapse-content flex flex-col gap-2">
        {#each validateAssetRuleTypeOptions(1) as t}
          <button
            class="hover:border-primary border-base-200 flex items-center gap-4 rounded border p-4 text-start"
            class:bg-primary={value === t.id}
            class:border-primary={value === t.id}
            class:text-base-100={value === t.id}
            on:click={() => updateType(t.id)}
          >
            <Icon icon={t.icon} class="text-4xl" />

            <div>
              <p class="font-semibold">{t.name}</p>
              <p class="text-xs opacity-70">{t.desc}</p>
            </div>
          </button>
        {/each}
      </div>
    </div>
  </div>
{/if}
