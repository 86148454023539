<script>
  import Icon from "@iconify/svelte";
  import { onDestroy, onMount, tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { link, location } from "svelte-spa-router";
  import LabelBean from "../components/Labels/LabelBean.svelte";
  import LabelFilter from "../components/Labels/LabelFilter.svelte";
  import ListOfBeans from "../components/Labels/ListOfBeans.svelte";
  import ReportCard from "../components/ReportCard.svelte";
  import Pagination from "../components/table/Pagination.svelte";
  import { fetchGet, generate32BitInteger } from "../helpers";
  import { org_id,org_labels } from "../stores";
  import { ReportStatus } from "../lib/interfaces/Report.interface.js";
  import { calculatePercentage } from "../lib/utils/GenericUtils.js";

  let reports = [];
  let report_id = generate32BitInteger();
  let activeTabLabel = ReportStatus.LIVE;
  let spellingReport = false;
  let statuses = [
    { label: ReportStatus.DRAFT, value: 1, icon: "page-edit" },
    { label: ReportStatus.LIVE, value: 2, icon: "megaphone" },
    { label: ReportStatus.ARCHIVED, value: 3, icon: "archive" },
  ];
  let loading = true;
  let selectedLabels = [];
  let searchString = null;
  let unlabeledOnly = false;
  let itemsPerPage = 10;
  let groupBy = "Individual"; // Individual or Labels
  let view = "List"; // List or Grid
  let groupedData = [];
  let sortColumn = "created_date";
  let sortDirection = 1;

  const filterReports = (reports) => {
    let filteredResult = reports;

    if (unlabeledOnly) {
      filteredResult = reports.filter((report) => {
        return report.label_ids.length === 1 && report.label_ids[0] === null;
      });
    } else if (selectedLabels.length > 0) {
      filteredResult = reports.filter((report) => {
        return selectedLabels.every((label) =>
          report.label_ids.includes(label.id),
        );
      });
    } else {
      filteredResult = reports;
    }

    const checkMatch = (str, pattern) =>
      str && str.toLowerCase().indexOf(pattern.toLowerCase()) > -1;

    if (searchString) {
      filteredResult = filteredResult.filter(
        (report) =>
          checkMatch(report.name, searchString) ||
          checkMatch(report.description, searchString),
      );
    }

    return filteredResult;
  };

  function searchByString() {
    updateReportCount();
    groupStreams();
  }

  $: if ([searchString]) searchByString();

  function updateReportCount() {
    reports = reports.sort((a, b) => a.name.localeCompare(b.name));

    const fReports = filterReports(reports);

    statuses[0]["count"] = fReports.filter(
      (x) => x.status == ReportStatus.DRAFT,
    ).length;
    statuses[1]["count"] = fReports.filter((x) =>
      [ReportStatus.LIVE, ReportStatus.SEARCHING].includes(x.status),
    ).length;
    statuses[2]["count"] = fReports.filter(
      (x) => x.status == ReportStatus.ARCHIVED,
    ).length;
  }

  const duplicateStream = async (report_id) => {
    const res = await fetchGet(`/report/clone/${report_id}`);
    if (res.success) addClonedReport(res.report_id);
  };

  async function addClonedReport(report_id) {
    if (report_id) {
      const response = await fetchGet(`/report`);

      if (response.success) {
        reports = response.reports.filter((r) => r.id == $org_id || r.id > 1);
        updateReportCount();
      }
    }
  }

  onMount(async () => {
    if ($location == "/spelling") {
      spellingReport = true;
      const response = await fetchGet("/spelling");

      if (response.success) {
        reports = response.reports.filter((r) => r.id == $org_id || r.id > 1);
        updateReportCount();
      }
    } else {
      const response = await fetchGet("/report");

      if (response.success) {
        reports = response.reports.filter((r) => r.id == $org_id || r.id > 1);
        updateReportCount();
      }
    }

    loading = false;

    if (reports.length > 0) {
      const liveReport = reports.find((report) =>
        [ReportStatus.LIVE, ReportStatus.SEARCHING].includes(report.status),
      );
      const draftReport = reports.find(
        (report) => report.status == ReportStatus.DRAFT,
      );
      const archivedReport = reports.find(
        (report) => report.status == ReportStatus.ARCHIVED,
      );

      if (liveReport) activeTabLabel = ReportStatus.LIVE;
      else if (draftReport) activeTabLabel = ReportStatus.DRAFT;
      else if (archivedReport) activeTabLabel = ReportStatus.ARCHIVED;
      else activeTabLabel = ReportStatus.LIVE;
    }

    groupStreams();
  });

  $: if (selectedLabels || unlabeledOnly) updateReportCount();

  const groupStreams = () => {
    const fReports = filterReports(reports).filter((e) =>
      activeTabLabel === ReportStatus.LIVE
        ? [ReportStatus.LIVE, ReportStatus.SEARCHING].includes(e.status)
        : e.status === activeTabLabel,
    );

    switch (groupBy) {
      case "Individual":
        groupedData = [
          {
            label: { name: "Individual", id: Math.random() },
            reports: fReports,
            currentPage: 1,
            currentPage2: 1,
          },
        ];
        break;
      case "Labels":
        groupedData = [
          {
            label: { name: "Unlabeled", id: Math.random() },
            reports: fReports.filter((r) => {
              return r.label_ids.length === 1 && r.label_ids[0] === null;
            }),
            currentPage: 1,
            currentPage2: 1,
          },
          ...$org_labels.map((label) => {
            return {
              label,
              reports: fReports.filter((r) => r.label_ids.includes(label.id)),
              currentPage: 1,
              currentPage2: 1,
            };
          }),
        ];
        break;
    }

    groupedData = groupedData.filter((gd) => gd.reports.length);
  };

  function sortTable(column, direction) {
    sortDirection = direction;
    sortColumn = column;

    groupedData = groupedData.map((group) => ({
      ...group,
      reports: sortReports(group.reports),
    }));

    const elem = document.activeElement;
    if (elem) {
      elem.blur();
    }
  }

  function sortReports(reports) {
    if (sortColumn == "created_date") {
      return [...reports].sort(
        (a, b) =>
          (new Date(a.created_date) - new Date(b.created_date)) * sortDirection,
      );
    }

    if (sortColumn == "last_refreshed") {
      return [...reports].sort(
        (a, b) =>
          (new Date(a.last_refreshed) - new Date(b.last_refreshed)) *
          sortDirection,
      );
    }

    if (sortColumn == "name") {
      if (sortDirection > 0) {
        return [...reports].sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return [...reports].sort((a, b) => b.name.localeCompare(a.name));
      }
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }

  $: if ([groupBy, activeTabLabel, selectedLabels, unlabeledOnly, reports])
    groupStreams();
</script>

<svelte:head>
  <title>{spellingReport ? "Spelling Reports" : "Streams"} - Haast</title>
</svelte:head>

<header class="bg-base-content/[5%] -mx-12 -my-4 mb-4 px-12 py-4 pb-0">
  <!-- extra container required for max-w-screen-xl but full background -->
  <div class="mx-auto flex max-w-screen-xl flex-col gap-8">
    <div class="breadcrumbs text-sm">
      <ul>
        <li>
          <a href="/streams" use:link
            >{spellingReport ? "Spelling Reports" : "Streams"}</a
          >
        </li>
        <li>
          {activeTabLabel}
          {spellingReport ? "Spelling Reports" : "Streams"}
        </li>
      </ul>
    </div>

    <div class="flex items-center gap-4">
      <h1 class="text-3xl font-semibold">
        {spellingReport ? "Spelling Reports" : "Streams"}
      </h1>

      <a
        href="/{spellingReport ? 'spelling' : 'stream'}/new/{report_id}"
        class="btn btn-primary btn-sm ml-auto"
        use:link
      >
        Create {spellingReport ? "Report" : "Stream"}
      </a>
    </div>

    <div class="tabs relative">
      {#each statuses as item}
        <button
          class="tab whitespace-nowrap text-base font-medium"
          class:text-primary={activeTabLabel === item.label}
          on:click={() => (activeTabLabel = item.label)}
          disabled={loading}
        >
          <Icon icon="iconoir:{item.icon}" class="mr-1" />
          {item.label} - {item.count > 0 ? item.count : 0}
        </button>
      {/each}
      <div
        class="slider bg-primary absolute bottom-0 left-0 h-0.5 transition-all"
        style:width="{100 / statuses.length}%"
        style="translate: {statuses.findIndex(
          (i) => i.label === activeTabLabel,
        ) * 100}%"
      />
    </div>
  </div>
</header>

<div class="mx-auto flex max-w-screen-xl flex-col">
  <div class="flex flex-wrap items-center gap-2 py-4">
    <div class="dropdown">
      <div
        tabindex="0"
        role="button"
        class="btn btn-outline btn-primary btn-sm rounded-full"
      >
        View: {view}
      </div>
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <ul
        tabindex="0"
        class="menu dropdown-content menu-sm bg-base-100 z-10 mt-1 w-full min-w-fit rounded border p-1"
      >
        {#each ["List", "Grid"] as item}
          <li>
            <button on:click={() => (view = item)}>
              <input
                type="radio"
                class="radio-primary radio radio-sm"
                checked={view === item}
              />
              {item}
            </button>
          </li>
        {/each}
      </ul>
    </div>

    <div class="dropdown">
      <div
        tabindex="0"
        role="button"
        class="btn btn-outline btn-primary btn-sm rounded-full"
      >
        Group: {groupBy}
      </div>
      <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
      <ul
        tabindex="0"
        class="menu dropdown-content menu-sm bg-base-100 z-10 mt-1 w-full min-w-fit rounded border p-1"
      >
        {#each ["Individual", "Labels"] as item}
          <li>
            <button on:click={() => (groupBy = item)}>
              <input
                type="radio"
                class="radio-primary radio radio-sm"
                checked={groupBy === item}
              />
              {item}
            </button>
          </li>
        {/each}
      </ul>
    </div>

    {#if $org_labels.length > 0}
      <LabelFilter labels={$org_labels} bind:selectedLabels bind:unlabeledOnly />
    {/if}

    <div class="flex flex-grow justify-end">
      <div class="relative min-w-[320px]">
        <input
          class="input input-sm input-bordered w-full pr-16"
          bind:value={searchString}
          placeholder="Search Streams"
        />
        <button
          class="btn btn-sm btn-ghost absolute right-0"
          on:click={() => searchByString()}
        >
          <Icon icon="iconoir:search" />
        </button>
      </div>
    </div>
  </div>

  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  {#each groupedData as gd, idx (gd.label.id)}
    <div
      class="collapse-arrow bg-base-200 collapse mt-4 {view === 'List'
        ? 'overflow-auto'
        : 'overflow-auto sm:overflow-visible'}"
    >
      <input type="radio" name="reportsAccordion" checked={idx === 0} />
      <div class="collapse-title text-xl font-medium">
        {gd.label.name}
      </div>
      <div class="collapse-content">
        {#if view === "List"}
          <div class="bg-base-100 overflow-x-auto rounded border">
            <table class="table">
              <thead class="bg-base-200">
                <tr>
                  <th class="py-0">
                    <div class="dropdown w-full">
                      <button
                        tabindex="0"
                        for=""
                        class="btn-ghost btn btn-sm flex-grow flex-nowrap gap-2 rounded-sm p-0 text-xs font-bold"
                      >
                        Stream Name <Icon icon="iconoir:sort" />
                      </button>
                      <ul
                        tabindex="0"
                        class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-48 overflow-auto rounded border p-1 font-normal shadow"
                      >
                        <li>
                          <button on:click={() => sortTable("name", 1)}>
                            Sort A-Z
                          </button>
                        </li>
                        <li>
                          <button on:click={() => sortTable("name", -1)}>
                            Sort Z-A
                          </button>
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th>Total Hits</th>
                  <th>Flagged Hits</th>
                  <th>Open Hits</th>
                  <th>Labels</th>
                  <th class="py-0">
                    <div class="dropdown w-full">
                      <button
                        tabindex="0"
                        for=""
                        class="btn-ghost btn btn-sm flex-grow flex-nowrap gap-2 rounded-sm p-0 text-xs font-bold"
                      >
                        Date Created <Icon icon="iconoir:sort" />
                      </button>
                      <ul
                        tabindex="0"
                        class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-48 overflow-auto rounded border p-1 font-normal shadow"
                      >
                        <li>
                          <button on:click={() => sortTable("created_date", 1)}>
                            Sort Oldest to Latest
                          </button>
                        </li>
                        <li>
                          <button
                            on:click={() => sortTable("created_date", -1)}
                          >
                            Sort Latest to Oldest
                          </button>
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th class="py-0">
                    <div class="dropdown w-full">
                      <button
                        tabindex="0"
                        for=""
                        class="btn-ghost btn btn-sm flex-grow flex-nowrap gap-2 rounded-sm p-0 text-xs font-bold"
                      >
                        Last Updated <Icon icon="iconoir:sort" />
                      </button>
                      <ul
                        tabindex="0"
                        class="menu dropdown-content menu-sm bg-base-100 z-[1] max-h-96 w-48 overflow-auto rounded border p-1 font-normal shadow"
                      >
                        <li>
                          <button
                            on:click={() => sortTable("last_refreshed", 1)}
                          >
                            Sort Oldest to Latest
                          </button>
                        </li>
                        <li>
                          <button
                            on:click={() => sortTable("last_refreshed", -1)}
                          >
                            Sort Latest to Oldest
                          </button>
                        </li>
                      </ul>
                    </div>
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {#each gd.reports.slice((gd.currentPage - 1) * itemsPerPage, gd.currentPage * itemsPerPage) as report (report.id)}
                  {@const lbls = $org_labels.filter((l) =>
                    report.label_ids.includes(l.id),
                  )}
                  <tr>
                    <td
                      class="max-w-[20vw] {!(
                        report.description &&
                        report.description.trim().length > 0
                      )
                        ? 'content-center'
                        : ''}"
                    >
                      <a
                        class="flex flex-col"
                        href="/#/{spellingReport
                          ? 'spelling'
                          : 'stream'}/{activeTabLabel === 'Draft'
                          ? 'edit/'
                          : ''}{report.id}"
                      >
                        <p class="font-semibold">
                          {report.name}
                          <a
                            href="/#/{spellingReport
                              ? 'spelling'
                              : 'stream'}/{activeTabLabel === 'Draft'
                              ? 'edit/'
                              : ''}{report.id}"
                            class="btn btn-square btn-ghost btn-xs"
                            target="_blank"
                          >
                            <Icon icon="iconoir:open-new-window" />
                          </a>
                        </p>

                        {#if report.description && report.description.trim().length > 0}
                          <div class="tooltip" data-tip={report.description}>
                            <p
                              class="text-base-content/70 truncate text-start text-xs"
                            >
                              {report.description}
                            </p>
                          </div>
                        {/if}
                      </a>
                    </td>
                    <td>{report.hits_count}</td>
                    <td>{report.flagged_hits_count}</td>
                    <td>{report.open_hits_count}</td>
                    <td class="space-x-2 whitespace-nowrap">
                      {#if lbls.length > 0}
                        <ListOfBeans
                          labels={lbls.slice(0, 2)}
                          disabled={true}
                        />

                        {#if lbls.length > 2}
                          <LabelBean
                            name="+{lbls.length - 2}"
                            disabled={true}
                          />
                        {/if}
                      {:else}
                        <span class="text-base-content/70 text-sm"
                          >No labels</span
                        >
                      {/if}
                    </td>
                    <td>{formatDate(report.created_date)}</td>
                    <td>{formatDate(report.last_refreshed)}</td>
                    <td class="whitespace-nowrap">
                      {#if report.permission === "edit" || report.permission === "admin"}
                        <a
                          href="/{spellingReport
                            ? 'spelling'
                            : 'stream'}/edit/{report.id}"
                          use:link
                          class="btn btn-outline btn-primary btn-xs flex-nowrap text-nowrap"
                          class:btn-disabled={!["edit", "admin"].includes(
                            report.permission,
                          )}
                        >
                          <Icon icon="iconoir:edit-pencil" />
                          Edit
                        </a>
                      {:else}
                        <div
                          class="tooltip"
                          data-tip="You do not have permission to edit this stream"
                        >
                          <button
                            class="btn btn-disabled btn-outline btn-primary btn-xs"
                          >
                            <Icon icon="iconoir:edit-pencil" />
                            Edit
                          </button>
                        </div>
                      {/if}

                      <button
                        class="btn btn-outline btn-primary btn-xs flex-nowrap text-nowrap"
                        on:click={async () =>
                          toast.promise(duplicateStream(report.id), {
                            loading: "Creating duplicate stream...",
                            success: "Duplicate stream created",
                            error: "Error creating duplicate stream",
                          })}
                      >
                        <Icon icon="iconoir:copy" />
                        Duplicate
                      </button>
                    </td>
                  </tr>
                {/each}
              </tbody>

              <tr>
                <td colspan="8">
                  <div class="flex w-full items-center justify-center px-4">
                    <Pagination
                      bind:currentPage={gd.currentPage}
                      bind:itemsPerPage
                      totalHits={filterReports(gd.reports).filter((e) =>
                        activeTabLabel === ReportStatus.LIVE
                          ? [
                              ReportStatus.LIVE,
                              ReportStatus.SEARCHING,
                            ].includes(e.status)
                          : e.status === activeTabLabel,
                      ).length}
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        {:else if view === "Grid"}
          <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-3">
            {#each gd.reports.slice((gd.currentPage - 1) * itemsPerPage, gd.currentPage * itemsPerPage) as report (report.id)}
              <ReportCard
                {report}
                {activeTabLabel}
                bind:reports
                {spellingReport}
                on:reportDeleted={updateReportCount}
                labels={$org_labels.filter((label) => {
                  return report.label_ids.includes(label.id);
                })}
                on:labelAdded={(e) => {
                  const label = e.detail;
                  report.label_ids.push(label.id);
                }}
                on:labelRemoved={(e) => {
                  const label = e.detail;
                  report.label_ids = report.label_ids.filter(
                    (labelId) => labelId !== label.id,
                  );
                }}
                on:reportCloned={(e) => addClonedReport(e.detail)}
              />
            {:else}
              {#if loading}
                {#each { length: 3 } as e, i}
                  <div
                    class="flex flex-col gap-4 p-4 min-w-80 rounded border"
                    class:max-xl:hidden={i === 2}
                  >
                    <div class="skeleton h-32 w-full" />
                    <div class="skeleton h-4 w-28" />
                    <div class="skeleton h-4 w-full" />
                    <div class="skeleton h-4 w-full" />
                  </div>
                {/each}
              {/if}
            {/each}
          </div>
          {#if !loading}
            <Pagination
              bind:currentPage={gd.currentPage}
              bind:itemsPerPage
              totalHits={filterReports(gd.reports).filter((e) =>
                activeTabLabel === ReportStatus.LIVE
                  ? [ReportStatus.LIVE, ReportStatus.SEARCHING].includes(
                      e.status,
                    )
                  : e.status === activeTabLabel,
              ).length}
            />
          {/if}
        {/if}
      </div>
    </div>
  {/each}

  {#if groupedData.length === 0 && !loading}
    <p
      class="text-base-content/50 m-auto mt-4 rounded border-4 border-dotted p-4 text-center text-xl"
    >
      There are no {activeTabLabel === ReportStatus.DRAFT
        ? "draft"
        : activeTabLabel === ReportStatus.LIVE
          ? "live"
          : "archived"}
      {spellingReport ? "spelling reports" : "streams"}.
    </p>
  {/if}
</div>
