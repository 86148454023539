<script>
  export let data;
</script>

<li>
  <a
    href={"/#/stream/" + data.id}
    target="_blank"
    class="link-hover link link-primary"
  >
    Stream #{data.id}
  </a>
  : {data.name}
  {data.description ? `(${data.description})` : ""}
</li>
