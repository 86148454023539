<!-- WorkflowStepNode.svelte -->
<script>
  import { Handle, Position } from "@xyflow/svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../../helpers";
  import AssignUsers from "../AssetReview/AssignUsers.svelte";
  import UpdateText from "./UpdateText.svelte";

  export let data;
  export let isConnectable = false;

  let report_id = data.report_id;
  let associatedUsers = data.users.map((u) => u.user_id);
  let associatedTeams = data.teams.map((t) => t.team_id);
</script>

<div class="workflow-step-node">
  <Handle
    class="handle"
    type="target"
    position={Position.Left}
    {isConnectable}
  />

  <div class="flex w-64 flex-col gap-2 rounded border bg-white p-4 shadow-lg">
    <UpdateText
      name={"Step Name"}
      bind:text={data.name}
      type={"name"}
      step_id={data.id}
    />

    <UpdateText
      name={"Step Description"}
      bind:text={data.description}
      type={"description"}
      step_id={data.id}
    />

    <div>
      <p class="text-sm font-medium">Approvers</p>

      <AssignUsers
        containerClass="mt-1"
        assignees={associatedUsers.map((uid) => ({ assigned_id: uid }))}
        bind:selectedTeams={associatedTeams}
        asset_type="workflow_step"
        workflow_step_id={data.id}
        tableView={true}
        on:addUser={async (e) => {
          const res = await fetchPost(
            `/user/${e.detail}/report/${report_id}`,
            { permission: "view" },
          );
          if (!res.success) {
            toast.error(
              `Something went wrong adding User(${e.detail} to Report(${report_id}).`,
            );
          }
        }}
        on:addTeam={async (e) => {
          // sharePermissionWithEntity
          const res = await fetchPost(
            `/team/${e.detail}/report/${report_id}`,
            { permission: "view" },
          );
        }}
        on:change={(e) =>
          (associatedUsers = e.detail?.map((a) => a.assigned_id))}
      />
    </div>
  </div>

  <Handle
    class="handle"
    type="source"
    position={Position.Right}
    {isConnectable}
  />
</div>

<style>
  :global(.svelte-flow .handle) {
    background-color: transparent;
    pointer-events: none;
    min-width: 0;
    min-height: 0;
    width: 0;
    height: 0;
    background-color: var(
      --xy-handle-background-color,
      var(--xy-handle-background-color-default)
    );
    border: 0;
    border-radius: 0;
  }
</style>
