<script>
  import { getTextColor, nameToColor } from "../../../helpers";

  export let initials = "";
</script>

<div class="avatar placeholder chat-image">
  <div
    class="bg-neutral text-neutral-content w-10 rounded-full"
    style:background-color={nameToColor(initials)}
    style:color={getTextColor(nameToColor(initials))}
  >
    <span>{initials}</span>
  </div>
</div>
