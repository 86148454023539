<script>
  import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
  } from "chart.js";
  import ChartDataLabels from "chartjs-plugin-datalabels";
  import { onMount } from "svelte";
  import { Bar } from "svelte-chartjs";
  import { fetchGet } from "../../helpers";

  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ChartDataLabels,
  );

  let weeklyAssetCounts = {};
  let chartData = {
    labels: [],
    datasets: [
      {
        label: "Assets Checked",
        data: [],
        backgroundColor: "#D7E5FF",
        barThickness: "20",
        borderRadius: "4",
      },
    ],
  };

  let success = true;
  let loaded = false;
  let reason = "";
  let totalAssetsChecked = 0;
  export let dataReady = false;

  $: if (dataReady) {
    setChartTableData();
  }

  async function setChartTableData() {
    const data = await fetchGet("/stats/time");

    if (data.success) {
      weeklyAssetCounts = data.asset_count;

      Object.keys(weeklyAssetCounts).forEach((e) => {
        let date = new Date(e);
        chartData.labels.push(
          date.toLocaleString("default", { day: "numeric", month: "short" }),
        );
        chartData.datasets[0].data.push(weeklyAssetCounts[e]);
      });

      // Get last 6 items (for most recent 6 months)
      chartData.labels = chartData.labels.slice(-6);
      chartData.datasets[0].data = chartData.datasets[0].data.slice(-6);

      totalAssetsChecked =
        chartData.datasets[0].data.length > 0
          ? Math.round(chartData.datasets[0].data.reduce((x, y) => x + y, 0))
          : 0; //Generates and rounds the sum of all assets counted

      loaded = true;
    } else {
      reason = data.reason;
      success = false;
    }
  }

  onMount(async () => {
    if (dataReady) {
      await setChartTableData();
    }
  });
</script>

<div>
  <span class="text-4xl font-bold">
    {totalAssetsChecked.toLocaleString() + " "}
  </span>
  <span class="text-xs italic"> assets checked</span>
</div>
<div class="text-xs italic text-gray-500">over last 6 weeks</div>

{#if loaded}
  <div class="h-full max-h-96 min-h-80 max-w-full">
    <Bar
      data={chartData}
      options={{
        responsive: true,
        aspectRatio: false,
        plugins: {
          legend: { display: false },
          datalabels: {
            color: "#000",
            font: { weight: "italic" },
            anchor: "end",
            align: "end",
            formatter: function (value, context) {
              if (value > 1000000) {
                return (Math.round(value / 100000) / 10).toLocaleString() + "m";
              } else {
                return (Math.round(value / 100) / 10).toLocaleString() + "k";
              }
            },
            title: {
              text: "Week Commencing",
              display: true,
              padding: 10,
              font: { weight: "italic", size: 14 },
              position: "bottom",
            },
            scales: {
              x: {
                display: true,
                type: "category",
                grid: { display: false, offset: false },
                ticks: { padding: 0 },
              },
              y: {
                display: false,
                max:
                  Math.max(
                    ...chartData.datasets.map((d) => Math.max(...d.data)),
                  ) * 1.2, //add some additional breathing space
              },
            },
          },
        },
      }}
    />
  </div>
{:else}
  <div class="flex h-full max-h-80 justify-around gap-2 max-lg:h-80">
    {#each { length: 3 } as _, i}
      <div class="skeleton h-64 w-4" />
    {/each}
  </div>
{/if}
