<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchGet } from "../../helpers";
  import supabase_cl from "../../supabase";
  import LogoLoader from "../LogoLoader.svelte";
  import Modal from "../Modals/Modal.svelte";
  import QueryPrettyPrint from "./QueryPrettyPrint.svelte";
  import ReportHitSelector from "./ReportHitSelector.svelte";
  import { isParentConditionalRule } from "../../lib/utils/RuleUtils";

  const dispatch = createEventDispatcher();

  export let rule;
  export let asset;
  export let report_id;
  export let activeVersion;

  let loading = false;
  let hits = [];
  let comment = "";
  let err_cats = ["Incorrect Sectioning", "Missing Hits"];
  let sel_errs = [];

  onMount(async () => {
    loading = true;
    try {
      const res = await fetchGet(`/hit/asset/${asset.id}/rule/${rule.rule.id}`);

      if (!res.success) {
        throw new Error(res.message);
      }

      hits = res.results.filter((r) => r.version === activeVersion);
      hits = [...hits];
    } catch (e) {
      toast.error(
        `Something went wrong loading performance for Asset(${asset.id}), Rule(${rule.rule.id}).`,
      );
    }

    loading = false;
  });
</script>

<Modal modalId="rule_report_example_{rule.rule.id}" size="md">
  <div class="rule-data">
    <h2 class="max-w-xs truncate text-2xl font-semibold">
      {rule.rule.name}
    </h2>
    <p class="text-base-content/70 font-light">
      ID: #{rule.rule.id}
    </p>
    <br />

    <span class="text-sm font-medium"> Rule Data </span>
    <QueryPrettyPrint
      rule={rule.rule}
      isParentConditional={isParentConditionalRule(rule.rule)}
    />
    <br />
    <span class="text-sm font-medium"> Rule Hits </span>
    {#if loading}
      <div class="flex justify-center self-center align-middle">
        <LogoLoader size="5rem" />
      </div>
    {:else}
      {#each hits.map((hit) => {
        return { ...hit, expectedColour: hit.expectedColour ? hit.expectedColour : hit.colour };
      }) as hit, i}
        <div
          class="marker:bg-base-200 flex items-center justify-between gap-2 rounded border px-4 py-2"
        >
          <span class="text-start text-sm">
            {@html hit.highlight}
          </span>
          <span class="text-start text-sm">
            {@html hit._score}
          </span>
          <div class="flex justify-normal">
            <ReportHitSelector
              {rule}
              {hit}
              editable={true}
              on:refresh={(e) => {
                hit = e.detail;
                hits[i] = hit;
                dispatch("refresh");
              }}
            />
          </div>
        </div>
      {/each}
    {/if}
    <br />

    <span class="text-sm font-medium"> Any other comments? </span>
    <form method="dialog">
      <textarea
        class="textarea textarea-bordered max-h-full w-full"
        style="height: auto; min-height:100px"
        placeholder="Comments"
        bind:value={comment}
        on:keypress={(e) => {
          if (e.shiftKey && e.key === "Enter") e.preventDefault();
        }}
      ></textarea>
      <span class="text-sm font-medium"> Other error categories: </span>
      <div class="flex flex-col gap-2 py-2" in:slide>
        <select
          class="select select-bordered select-sm"
          multiple
          bind:value={sel_errs}
        >
          {#each err_cats as name}
            <option value={name} class="text-sm font-light">
              {name}
            </option>
          {/each}
        </select>
      </div>
      <button
        class="btn btn-primary btn-sm ml-auto"
        disabled={loading}
        on:click={async () => {
          const all = (arr, fn = Boolean) => arr.every(fn);
          let fp;
          let fn;
          let indp;
          let indn;
          if (rule.rule.affirmative_rule) {
            fp = hits
              .map((hit) =>
                hit.colour === "green" &&
                (hit.expectedColour === "grey" || hit.expectedColour === "red")
                  ? 1
                  : 0,
              )
              .reduce((partialSum, a) => partialSum + a, 0);
            fn = hits
              .map((hit) =>
                hit.colour === "grey" && hit.expectedColour === "green" ? 1 : 0,
              )
              .reduce((partialSum, a) => partialSum + a, 0);
            indp = hits
              .map((hit) =>
                hit.colour === "amber" && hit.expectedColour === "green"
                  ? 1
                  : 0,
              )
              .reduce((partialSum, a) => partialSum + a, 0);
            indn = hits
              .map((hit) =>
                hit.colour === "amber" && hit.expectedColour === "red" ? 1 : 0,
              )
              .reduce((partialSum, a) => partialSum + a, 0);
          } else {
            fp = hits
              .map((hit) =>
                hit.colour === "grey" && hit.expectedColour === "green" ? 1 : 0,
              )
              .reduce((partialSum, a) => partialSum + a, 0);
            fn = hits
              .map((hit) =>
                hit.colour === "green" &&
                (hit.expectedColour === "grey" || hit.expectedColour === "red")
                  ? 1
                  : 0,
              )
              .reduce((partialSum, a) => partialSum + a, 0);
            indp = hits
              .map((hit) =>
                hit.colour === "amber" && hit.expectedColour === "red" ? 1 : 0,
              )
              .reduce((partialSum, a) => partialSum + a, 0);
            indn = hits
              .map((hit) =>
                hit.colour === "amber" && hit.expectedColour === "green"
                  ? 1
                  : 0,
              )
              .reduce((partialSum, a) => partialSum + a, 0);
          }
          const { data, error } = await supabase_cl
            .from("flagged_asset_rule")
            .upsert({
              stream_id: report_id,
              asset_id: asset.id,
              rule_id: rule.rule.id,
              rule: rule.rule,
              hits: hits,
              is_perfect:
                all(
                  hits,
                  (hit) =>
                    typeof hit.expectedColour === "undefined" ||
                    hit.colour === hit.expectedColour,
                ) && sel_errs.length === 0,
              comments: comment,
              false_positive: fp,
              false_negative: fn,
              ind_positive: indp,
              ind_negative: indn,
              err_cat: sel_errs,
            })
            .select();

          if (error) toast.error(error);
          else toast.success("Feedback sent!");
        }}
      >
        <Icon icon="material-symbols:feedback-outline" /> Submit Feedback
      </button>
    </form>
  </div>
</Modal>
