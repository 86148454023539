<script>
  import Icon from "@iconify/svelte";
  import { tick } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPatch } from "../../../helpers";
  import { currentUser } from "../../../stores";
  import { UserRole } from "../../../lib/interfaces/User.interface";
  import Badge from "../WorkflowBoard/Badge.svelte";
  import ActiveAssetHotButtons from "./HotButtons/ActiveAssetHotButtons.svelte";
  import MiddleTruncator from "../../MiddleTruncator.svelte";

  export let reportId;
  export let activeAsset;
  export let activeVersion;
  export let versions;
  export let currentAssetApprovingPermission;
  export let previewUrl;
  export let editActiveAssetName;
  export let showCompareVersions = false;
  export let currentStreamPerm;
  export let activeAssetId;
  export let workflow;
  export let isInitialised;
  export let report;
  export let versionUpdated = false;

  function isOverdue(dateString) {
    if (!dateString) {
      return;
    }

    const dueDate = new Date(dateString);
    const today = new Date();
    return dueDate < today;
  }

  function formatDate(dateString) {
    if (!dateString) {
      return;
    }

    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }

  // const calculateReviewDate = (createdDate, reReviewTime) => {
  //   if (!createdDate || !reReviewTime) return null;
  //   const date = new Date(createdDate);
  //   date.setMonth(date.getMonth() + reReviewTime);
  //   return date;
  // };

  // const isReviewOverdue = (reviewDate) => {
  //   if (!reviewDate) return false;
  //   const today = new Date();
  //   return new Date(reviewDate) < today;
  // };

  /**
   * @todo
   * Add JSDoc comments
   */
  const renameActiveAsset = async () => {
    const res = await fetchPatch(`/asset/${activeAssetId}`, {
      name: editActiveAssetName,
      operation: "rename",
    });
    if (res.success) {
      toast.success("Asset name updated successfully");

      activeAsset.asset.name = editActiveAssetName;

      editActiveAssetName = undefined;
    } else {
      toast.error("Failed to update asset name");
    }
  };
</script>

{#if isInitialised && activeAsset}
  <div class="mt-4 flex justify-between gap-2">
    <div class="flex max-w-xl grow items-center gap-2">
      <Icon
        icon="iconoir:{activeAsset.asset.file_type.includes('image/')
          ? 'media-image'
          : 'page'}"
        class="mr-1 shrink-0 text-3xl"
      />

      {#if editActiveAssetName}
        <div class="join px-4">
          <input
            type="text"
            id="editActiveAssetName"
            class="input input-sm join-item input-bordered w-full"
            bind:value={editActiveAssetName}
            on:keypress={(e) => {
              if (e.key === "Enter") renameActiveAsset();
            }}
          />
          <button
            class="btn btn-square btn-ghost join-item btn-sm border-base-content/20 px-4"
            on:click={() => (editActiveAssetName = undefined)}
          >
            <Icon icon="iconoir:cancel" class="shrink-0 text-xl" />
          </button>
          <button
            class="btn btn-square btn-primary join-item btn-sm px-4"
            on:click={renameActiveAsset}
          >
            <Icon icon="iconoir:save-floppy-disk" class="shrink-0 text-xl" />
          </button>
        </div>
      {:else}
        <h1 class="text-2xl font-semibold">
          <MiddleTruncator text={activeAsset.asset.name} direction="right" />
        </h1>
        <button
          class="btn btn-square btn-ghost btn-sm"
          disabled={currentStreamPerm !== "edit"}
          on:click={async () => {
            editActiveAssetName = activeAsset.asset.name;
            await tick();
            document.getElementById("editActiveAssetName")?.focus();
          }}
        >
          <Icon icon="iconoir:edit-pencil" class="shrink-0 text-xl" />
        </button>
      {/if}

      <div class="dropdown">
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <label tabindex="0" class="btn btn-square btn-ghost btn-sm" for="">
          <Icon icon="iconoir:more-horiz" class="text-xl" />
        </label>
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <ul
          tabindex="0"
          class="menu dropdown-content bg-base-100 z-20 m-1 w-52 rounded border p-2"
        >
          <li>
            <button
              on:click={() =>
                document.querySelector("#report-history-dialog")?.showModal()}
            >
              <Icon icon="iconoir:open-book" class="text-xl" /> Asset History
            </button>
          </li>
        </ul>
      </div>

      {#if activeAsset.asset.approval_status !== "Approved" && activeAsset.asset.due_date}
        <Badge
          size="sm"
          bgColor={isOverdue(activeAsset.asset.due_date)
            ? "#FFE4E4"
            : "#E0E0E0"}
          textColor={isOverdue(activeAsset.asset.due_date)
            ? "#D92D20"
            : "black"}
        >
          Due Date {formatDate(activeAsset.asset.due_date)}
        </Badge>
      {/if}
      <!-- {#if report.re_review_time && activeAsset.asset.created_date}
        {@const reviewDate = calculateReviewDate(
          activeAsset.asset.created_date,
          report.re_review_time,
        )}
        {#if reviewDate}
          <Badge
            size="sm"
            bgColor={isReviewOverdue(reviewDate) ? "#FFE4E4" : "#E0E0E0"}
            textColor={isReviewOverdue(reviewDate) ? "#D92D20" : "black"}
          >
            Review Date {formatDate(reviewDate)}
          </Badge>
        {/if}
      {/if} -->
    </div>
    <div class="flex shrink-0 grow items-center justify-end gap-2">
      <ActiveAssetHotButtons
        bind:activeAsset
        bind:activeAssetId
        bind:activeVersion
        bind:currentAssetApprovingPermission
        bind:currentStreamPerm
        bind:editActiveAssetName
        bind:isInitialised
        bind:previewUrl
        bind:versions
        bind:workflow
        bind:reportId
        bind:versionUpdated
        bind:showCompareVersions
      />
    </div>
  </div>
{/if}
