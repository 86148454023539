<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { persisted } from "svelte-persisted-store";
  import IssueTableSearch from "./IssueTableSearch.svelte";

  const dispatch = createEventDispatcher();

  export let originalData = [];
  export let data = [];
  export let hitSearchText;
  export let hitSearchSettings;
  export let sourceSearchText;
  export let sourceSearchSettings;
  export let openedTab;
  export let tableRootPage = "";

  let spellingReport = tableRootPage == "spelling";

  export let dateToSortByString = "";
  let dateToSortBy;
  $: dateToSortBy = new Date(dateToSortByString);

  export let currentPage = 1;

  let filters = {
    types: {},
    sourceLocations: {},
    hits: {},
    rules: {},
  };

  function getSortedData(key, subKey, reverse = false) {
    let sortedData = [];

    switch (subKey) {
      case "assigned_users":
        sortedData = originalData.sort((a, b) => {
          if (a[key][subKey].length < b[key][subKey].length) return -1;
          else if (a[key][subKey].length > b[key][subKey].length) return 1;

          return 0;
        });
        break;

      case "last_updated":
        sortedData = originalData.sort((a, b) => {
          const aDate = a[key][subKey]
            ? new Date(a[key][subKey])
            : new Date(a[key].created_date);

          const bDate = b[key][subKey]
            ? new Date(b[key][subKey])
            : new Date(b[key].created_date);

          if (aDate < bDate) return -1;
          else if (aDate > bDate) return 1;

          return 0;
        });
        break;

      case "priority":
        // Yes, I am not fucking with you. This is how you sort by priority.
        sortedData = originalData.sort((a, b) => {
          if (a[key][subKey] === "High") return -1;
          if (a[key][subKey] === "Med" && b[key][subKey] === "Low") return -1;
          if (a[key][subKey] === "Low" && b[key][subKey] === "High") return 1;
          if (a[key][subKey] === "Med" && b[key][subKey] === "High") return 1;
          if (a[key][subKey] === "Low" && b[key][subKey] === "Med") return 1;
          return 0;
        });
        break;

      default:
        sortedData = originalData.sort((a, b) => {
          if (a[key][subKey] < b[key][subKey]) return -1;
          if (a[key][subKey] > b[key][subKey]) return 1;

          return 0;
        });
        break;
    }

    if (reverse) {
      sortedData.reverse();
    }

    return sortedData;
  }

  function sortByColumn(column, sortDirection) {
    switch (column) {
      case "highlight":
      case "in_report":
      case "priority":
      case "assigned_users":
      case "done":
      case "data_type":
      case "title":
        data = getSortedData("hit", column, sortDirection === "desc");
        break;

      case "last_updated":
        data = getSortedData("hit", "last_updated", sortDirection === "desc");
        break;

      case "rule":
        data = getSortedData("rule", "name", sortDirection === "desc");
        break;

      default:
        data = getSortedData("hit", "id", sortDirection === "desc");
        break;
    }

    // Adjusting the sort direction conditions
    if (sortDirection === "asc") {
      data = data.sort((a, b) => (a[column] > b[column] ? -1 : 1));
    } else if (sortDirection === "desc") {
      data = data.sort((a, b) => (a[column] < b[column] ? -1 : 1));
    }

    // Don't worry about the recursion, it's just to make sure the table is sorted correctly
    if (currentPage > 1) {
      currentPage = 1;
      sortByColumn(column, sortDirection);
    }
  }

  $: minColWidth = {
    checkbox: 40,
    type: 100,
    source: 96,
    hit: 70,
    rule: 80,
    updated: 110,
    more: spellingReport
      ? 328
      : openedTab === "Open"
        ? 204
        : openedTab === "Flagged"
          ? 328
          : openedTab === "Closed"
            ? 312
            : 300,
  };
  const defColWidths = {
    checkbox: 54,
    type: 130,
    source: 160,
    hit: 260,
    rule: 120,
    updated: 110,
    more: spellingReport
      ? 415
      : openedTab === "Open"
        ? 204
        : openedTab === "Flagged"
          ? 328
          : openedTab === "Closed"
            ? 312
            : 300,
  };
  const colWidths = persisted("colWidths", defColWidths);

  $: if (openedTab) $colWidths.more = minColWidth.more;

  onMount(() => {
    data = data.map((entry) => {
      entry["selected"] = false;
      return entry;
    });

    for (let i = 0; i < data.length; i++) {
      filters["types"][data[i]["hit"]["index"]] = true;
      filters["sourceLocations"][data[i]["hit"]["vendor"]] = true;
      filters["hits"][data[i]["hit"]["highlight"]] = true;
      filters["rules"][data[i]["rule"]["name"]] = true;
    }

    // col resizing logic
    const resizers = document.querySelectorAll(".resizer");

    resizers.forEach((r) => {
      r.addEventListener("dblclick", () => {
        $colWidths[r.dataset.colname] = defColWidths[r.dataset.colname];
      });

      r.addEventListener("mousedown", (e) => {
        let prevX = e.clientX;

        const mouseMoveHandler = (e) => {
          $colWidths[r.dataset.colname] += e.clientX - prevX;
          prevX = e.clientX;

          if ($colWidths[r.dataset.colname] < minColWidth[r.dataset.colname])
            $colWidths[r.dataset.colname] = minColWidth[r.dataset.colname];
        };

        const mouseUpHandler = () => {
          document.removeEventListener("mousemove", mouseMoveHandler);
          document.removeEventListener("mouseup", mouseUpHandler);
        };

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      });
    });
  });
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<thead>
  <tr>
    <th class="p-0" style:width="{$colWidths.checkbox}px">
      <div class="resizer" data-colname="checkbox" />
    </th>
    <th class="p-0" style:width="{$colWidths.type}px">
      <div class="dropdown w-full">
        <label tabindex="0" for="" class="btn btn-ghost btn-sm rounded-none">
          Type <Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm z-[1] max-h-96 w-64 overflow-auto rounded border bg-base-100 p-1 shadow"
        >
          <li>
            <button on:click={() => sortByColumn("data_type", "asc")}>
              Sort A-Z
            </button>
          </li>
          <li>
            <button on:click={() => sortByColumn("data_type", "desc")}>
              Sort Z-A
            </button>
          </li>
        </ul>
      </div>
      <div class="resizer" data-colname="type" />
    </th>
    <th class="w-[200px] p-0" style:width="{$colWidths.source}px">
      <div class="dropdown w-full">
        <label tabindex="0" for="" class="btn btn-ghost btn-sm rounded-none">
          Source<Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm z-[1] max-h-96 w-64 overflow-auto rounded border bg-base-100 p-1 shadow"
        >
          <IssueTableSearch
            dropdownFilterDesign={false}
            bind:searchText={sourceSearchText}
            bind:searchSettings={sourceSearchSettings}
            on:enterPressed={() =>
              dispatch("search", { hitSearchText, sourceSearchText })}
          />
          <li>
            <button on:click={() => sortByColumn("title", "asc")}>
              Sort A-Z
            </button>
          </li>
          <li>
            <button on:click={() => sortByColumn("title", "desc")}>
              Sort Z-A
            </button>
          </li>
        </ul>
      </div>
      <div class="resizer" data-colname="source" />
    </th>
    <th class="p-0" style:width="{$colWidths.hit}px">
      <div class="dropdown w-full">
        <label tabindex="0" for="" class="btn btn-ghost btn-sm rounded-none">
          Hit <Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm z-[1] max-h-96 w-64 overflow-auto rounded border bg-base-100 p-1 shadow"
        >
          <IssueTableSearch
            dropdownFilterDesign={false}
            bind:searchText={hitSearchText}
            bind:searchSettings={hitSearchSettings}
            on:enterPressed={() =>
              dispatch("search", { hitSearchText, sourceSearchText })}
          />
        </ul>
      </div>
      <div class="resizer" data-colname="hit" />
    </th>
    <th class="p-0" style:width="{$colWidths.rule}px">
      <div class="dropdown w-full">
        <label tabindex="0" for="" class="btn btn-ghost btn-sm rounded-none">
          {spellingReport ? "Spelling" : "Rule"}
          <Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm z-[1] max-h-96 w-64 overflow-auto rounded border bg-base-100 p-1 shadow"
        >
          <li>
            <button on:click={() => sortByColumn("rule", "asc")}>
              Sort A-Z
            </button>
          </li>
          <li>
            <button on:click={() => sortByColumn("rule", "desc")}>
              Sort Z-A
            </button>
          </li>
        </ul>
      </div>
      <div class="resizer" data-colname="rule" />
    </th>
    <th class="p-0" style:width="{$colWidths.updated}px">
      <div class="dropdown w-full">
        <label
          tabindex="0"
          for=""
          class="btn btn-ghost btn-sm flex-nowrap rounded-none"
        >
          Updated <Icon icon="iconoir:sort" />
        </label>
        <ul
          tabindex="0"
          class="menu dropdown-content menu-sm z-[1] max-h-96 w-64 overflow-auto rounded border bg-base-100 p-1 shadow"
        >
          <li>
            <button on:click={() => sortByColumn("last_updated", "asc")}>
              Sort Oldest to Latest
            </button>
          </li>
          <li>
            <button on:click={() => sortByColumn("last_updated", "desc")}>
              Sort Latest to Oldest
            </button>
          </li>
          <!--Removing date range filters until this is implemented-->
          <!--<li> 
            <button on:click={() => sortByColumn("last_updated")}>
              Date Range Filters
            </button>
          </li>-->
        </ul>
      </div>
      <div class="resizer" data-colname="updated" />
    </th>
    <th class="!min-w-max" style:width="{$colWidths.more}px">
      <div class="resizer" data-colname="more" />
    </th>
  </tr>
</thead>

<style>
  th {
    position: relative;
  }
  .resizer {
    background: rgb(0 0 0 /10%);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 2px;
    z-index: 1;
    transition: all 200ms ease-in-out;
    cursor: col-resize;
  }
  .resizer:hover {
    background: rgb(0 0 0 /20%);
    width: 3px;
  }
  .resizer:active {
    background: oklch(var(--p));
    width: 4px;
  }
</style>
