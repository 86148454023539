<script>
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../helpers";
  import { validEmail } from "../lib/utils/AuthUtils";

  export let showPasswordReset = true;

  let email = "";
  let isResetting = false;

  async function generatePasswordResetLink() {
    isResetting = true;

    if (!validEmail(email)) {
      toast.error("The email address provided is not valid.");
      isResetting = false;
      return;
    }

    try {
      const response = await fetchPost("/auth/generate-reset-token", { email });

      if (!response.success) {
        toast.error(response?.message);

        isResetting = false;
        return;
      }

      toast.success(
        "A password reset link has been sent to your email address.",
      );
    } catch (e) {
      toast.error("The email address is not registered.");

      isResetting = false;
      console.warn(e);
    }
  }
</script>

<div class="flex h-screen items-center justify-center bg-base-200">
  <main
    class="m-4 flex w-full max-w-md flex-col overflow-hidden rounded border bg-base-100 p-8"
  >
    <img src="/Haast.svg" class="h-12 self-center" alt="Haast" />

    <form on:submit|preventDefault={generatePasswordResetLink}>
      <div class="form-control w-full">
        <label class="label" for="email">
          <span class="label-text">Email</span>
        </label>
        <input
          class="input input-bordered w-full"
          placeholder="Enter your account email"
          name="email"
          id="email"
          type="text"
          bind:value={email}
          on:keydown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              generatePasswordResetLink();
            }
          }}
        />
      </div>

      <label class="label w-max">
        <button
          class="link-hover label-text-alt"
          on:click={() => (showPasswordReset = false)}
        >
          Back to login?
        </button>
      </label>

      <button
        class="btn btn-primary mt-2 w-full"
        type="submit"
        disabled={isResetting}
      >
        Request Password Reset
      </button>
    </form>
  </main>
</div>
