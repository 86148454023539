import { isEmpty } from "lodash";
import parser from "lucene";
import { reference_fields } from "./components/RuleBuilder/constants";

export const convertBuilderToBoolean = (builder) => {
  const processedBuilder = removeDeleted(builder[0]);
  return evaluate(processedBuilder);
};

export const removeDeleted = (builder) => {
  if (!isEmpty(builder)) {
    if (builder.delete) {
      return;
    }
    if (builder.type === "relation" && builder.nested.length === 0) {
      return;
    }
    if (builder.type === "clause") {
      return builder;
    } else if (builder.type === "relation") {
      builder.nested = builder.nested.map(removeDeleted).filter(Boolean);
      return builder;
    }
  }

  return builder;
};

const evaluate = (builder) => {
  if (!builder) {
    return "";
  }
  if (builder.type === "relation") {
    return relationBuilder(builder.not, builder.relation, builder.nested);
  } else if (builder.type === "clause") {
    return clauseBuilder(
      builder.not,
      builder.field,
      builder.value,
      builder.term,
      builder.operation,
      builder.slop,
      builder.options,
    );
  }
};

const relationBuilder = (ifNot, relation, nested) => {
  if (nested.length === 0) {
    return "";
  }
  const relString = " " + relation + " ";
  if (ifNot) {
    return "NOT (" + nested.map(evaluate).filter(Boolean).join(relString) + ")";
  }
  return " (" + nested.map(evaluate).filter(Boolean).join(relString) + ")";
};

const relationDict = {
  "greater than": ">",
  "less than": "<",
  "less than or equal to": "<=",
  "greater than or equal to": ">=",
  equal: "",
  "includes any of": "",
  "exactly contains": "",
  "regular expression": "",
  "proximity search": "",
};

const clauseBuilder = (ifNot, field, value, term, operation, slop, options) => {
  if (!term || operation === "exactly contains") {
    value = '"' + value.replaceAll('"', '\\"') + '"';
  } else if (!term || operation === "regular expression") {
    value = "/" + value.replaceAll("/", "\\/").replaceAll("\\", "\\\\") + "/";
  } else if (operation === "proximity search") {
    value = '"' + value + '"' + "~" + slop;
  } else if (operation === "includes any of") {
    value = value.replace(/[,.?!:\(\)"]+/, "");
  }
  if (ifNot) {
    return "(NOT " + field + ":(" + relationDict[operation] + value + "))";
  }
  return field + ":(" + relationDict[operation] + value + ")";
};

// lucene like string to json data structure
export const convertBoolToBuilder = (bool) => {
  let id_ = 0;
  const get_id = () => id_++;

  function combine_operands(side, op, field, not) {
    if (side.type === "clause") {
      if (side.field === field && side.not === not) return [side];
      else return false;
    } else if (
      side.type === "relation" &&
      side.relation === op &&
      side.not === not &&
      side.nested.every((e, _) => e.field === field)
    ) {
      return side.nested;
    }
    return false;
  }

  function classifyTerm(value, proximity, quoted, regex) {
    if (regex) {
      return {
        match: value.replaceAll("\\/", "/").replaceAll("\\\\", "\\"),
        operation: "regular expression",
      };
    } else if (proximity !== null && proximity !== undefined) {
      return {
        match: value,
        slop: proximity,
        operation: "proximity search",
      };
    } else if (quoted) {
      return {
        match: value.replaceAll('\\"', '"'),
        operation: "exactly contains",
      };
    } else if (value.slice(0, 2) == ">=") {
      return {
        match: value.slice(2).trim(), // remove op
        operation: "greater than or equal to",
      };
    } else if (value.slice(0, 2) == "<=") {
      return {
        match: value.slice(2).trim(), // remove op
        operation: "less than or equal to",
      };
    } else if (value.slice(0, 1) == ">") {
      return {
        match: value.slice(1).trim(), // remove op
        operation: "greater than",
      };
    } else if (value.slice(0, 1) == "<") {
      return {
        match: match.slice(1).trim(), // remove op
        operation: "less than",
      };
    } else {
      return {
        match: value.replace(/[,.?!:\(\)"]+$/, ""),
        operation: "includes any of",
      };
    }
  }

  let implicit_field = "";

  function custom_parse(results, field) {
    if (results.field !== undefined && results.field !== "<implicit>") {
      implicit_field = results.field;
    }
    if (results.hasOwnProperty("operator")) {
      if (results.field === "<implicit>" || results.field === undefined) {
        field = implicit_field;
      }

      const children = results.hasOwnProperty("right")
        ? [
            custom_parse(results.left, field),
            custom_parse(results.right, field),
          ]
        : [custom_parse(results.left, field)];
      if (field === undefined && children.length > 1) {
        field =
          children[0].field === children[1].field ? children[0].field : field;
      } else {
        field = children[0].field;
      }
      const all_combinable = children.map((e, _) =>
        combine_operands(e, results.operator, field, children[0].not),
      );
      if (results.operator.endsWith("NOT")) children[1].not = true;
      if (results.start === "NOT") children[0].not = true;
      return {
        id: get_id(),
        not: results.operator === "NOT",
        type: "relation",
        field: field,
        relation:
          results.operator === "<implicit>"
            ? "OR"
            : results.operator.split(" ")[0],
        // If at least one false
        nested: all_combinable.some((e, _) => e === false)
          ? children
          : all_combinable.flat(1),
      };
    } else if (results.hasOwnProperty("term") === true) {
      const { match, operation, slop } = classifyTerm(
        results.term,
        results.proximity,
        results.quoted,
        results.regex,
      );
      return {
        id: get_id(),
        field: results.field === "<implicit>" ? implicit_field : results.field,
        type: "clause",
        not: results.start === "NOT" ? true : false,
        operation: operation,
        value: match,
        slop: slop === undefined ? 0 : slop,
        term: true,
        regex: false,
        delete: false,
        options: [],
      };
    } else if (
      results.hasOwnProperty("left") &&
      !results.hasOwnProperty("right")
    ) {
      var out = custom_parse(
        results.left,
        results.field === "<implicit>" ? implicit_field : results.field,
      );
      out.not = results.start === "NOT" ? true : out.not ? true : false;
      return out;
    }
  }

  let results = parser.parse(bool);
  const parsedResults = custom_parse(results, "text");

  const allowedFields = reference_fields.map((obj) => obj.name);

  const setField = (nested) => {
    if (!allowedFields.includes(nested.field)) nested.field = "text";
    if (nested.nested) nested.nested.map((e, _) => setField(e));
  };
  setField(parsedResults);

  // single clause
  if (!parsedResults.nested) {
    return [
      {
        id: -1,
        not: false,
        type: "relation",
        field: "text",
        relation: "OR",
        nested: [parsedResults],
      },
    ];
  }

  return [parsedResults];
};
