import {
  isProperSite,
  doesDataTypeSupportMedia,
  doesSupportDirectTimestamp,
} from "./TimestampUtils";

/**
 * The URL may provide deeper insight regarding the real data type of
 * an entry, such as for timestamps. This function provides the more
 * specific data types, such as "audio" and "video".
 *
 * @param {object} hit
 * @returns
 */
export function getDataType(hit) {
  if (!hit.data_type) return "";
  // There are some hits that doesn't have a data type
  // so we return an empty string for that to not get undefined

  if (
    hit.data_type.toLowerCase().includes("timestamp") ||
    hit.data_type.toLowerCase().includes("multimedia")
  ) {
    if (hit.media_timestamp !== null) {
      if (hit.content_link.includes("soundcloud")) return "audio";
      else return "video";
    }
  }
  return hit.data_type;
}

/**
 * Converts seconds to a string of the format:
 *  HH:MM:SS
 *
 * @param {float} seconds
 */
export function formatSeconds(seconds) {
  seconds = Math.round(seconds);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

/**
 * Usable media link refers to the link that will display the content best.
 *
 * @param {string} pool
 * @param {string} dataType
 * @param {string} url - the actual link of the media (e.g., CDNs, vimeo, etc)
 * @param {string} contentLink - the site that contains the media
 * @returns
 */
export function getUsableMediaLink(pool, dataType, url, contentLink) {
  if (contentLink == null) return url;
  if (!isProperSite(contentLink)) return url;
  return contentLink;

  // I think the links are now generalized enough to need these cases
  if (
    pool.includes("website_image_text") ||
    pool.includes("website_document_text")
  )
    return contentLink;
  if (!isProperSite(url)) return contentLink;
  if (doesDataTypeSupportMedia(dataType)) return contentLink;
  return contentLink;
}
