<script>
  import Icon from "@iconify/svelte";
  import { link } from "svelte-spa-router";
  import { maximised,labelToFilter,pinnedLabels } from "../../../stores";

  import active from "svelte-spa-router/active";
  import { fly } from "svelte/transition";
  import ListOfLabels from "../../Labels/ListOfLabels.svelte";
  import { showStreamsWithSelectedLabel} from "../../Labels/labels";

  export let item;
  function handleLabelClick(labelId,reportType) {
    showStreamsWithSelectedLabel(labelId, reportType);
  }
</script>

<li class="option">
  <a href={item.href} use:link use:active={item.href}>
    {#if $maximised}
      <Icon icon={item.icon} class="text-lg" />
      <span class="whitespace-nowrap" in:fly={{ x: -32, duration: 200 }}>
        {item.description}
      </span>
    {:else}
      <div class="tooltip tooltip-right" data-tip={item.description}>
        <Icon icon={item.icon} class="text-lg" />
      </div> 
    {/if}
  </a>
</li>

{#if ["/streams", "/reviews"].includes(item.href) && $maximised}
  <ListOfLabels
    labels={$pinnedLabels.filter(label => 
      item.href === "/streams" 
        ? label.pinned_live 
        : label.pinned_asset
    )}
    reportType={item.href === "/streams" ? "live" : "asset"}
    on:labelClicked={(e) => handleLabelClick(
      e.detail,
      item.href === "/streams" ? "live" : "asset"
    )}
  />
{/if}

<style>
  li.option a,
  li.option button {
    border-radius: 0;
  }

  li.option a:active,
  li.option button:active,
  li.option a.active {
    background-color: oklch(var(--b3) / 80%) !important;
    color: unset;
  }

  li.option a.active {
    box-shadow: inset 4px 0 0 0 oklch(var(--p)) !important;
  }
  li.option .disabled {
    pointer-events: none; /* Prevent clicking */
    color: grey; /* Change color to grey or your choice */
  }
</style>
