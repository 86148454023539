<script>
  import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    Title,
    Tooltip,
  } from "chart.js";
  import { onMount } from "svelte";
  import { persisted } from "svelte-persisted-store";
  import { fetchGet } from "../../helpers";
  import ReportStatusDonut from "./ReportStatusDonut.svelte";

  ChartJS.register({
    id: "doughnut-centertext",
    beforeDraw: function (chart) {
      if (chart?.config?.options?.elements?.center) {
        let ctx = chart.ctx;

        let centerConfig = chart.config.options.elements.center;
        let fontStyle = centerConfig.fontStyle || "Arial";
        let bigTxt = centerConfig.bigText;
        let littleTxt = centerConfig.littleText;
        let color = centerConfig.color || "#000";
        let maxFontSize = centerConfig.maxFontSize || 75;
        let sidePadding = centerConfig.sidePadding || 20;
        let sidePaddingCalculated =
          (sidePadding / 100) *
          (chart._metasets[chart._metasets.length - 1].data[0].innerRadius * 2);
        ctx.font = "30px " + fontStyle;

        let bigTxtWidth = ctx.measureText(bigTxt).width;
        let littleTxtWidth = ctx.measureText(littleTxt).width;
        let stringWidth = Math.max(bigTxtWidth, littleTxtWidth);
        let elementWidth =
          chart._metasets[chart._metasets.length - 1].data[0].innerRadius * 2 -
          sidePaddingCalculated;

        let widthRatio = elementWidth / stringWidth;
        let newFontSize = Math.floor(30 * widthRatio);
        let elementHeight =
          chart._metasets[chart._metasets.length - 1].data[0].innerRadius * 2;

        let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
        let minFontSize = centerConfig.minFontSize;

        if (minFontSize === undefined) {
          minFontSize = 20;
        }

        if (minFontSize && fontSizeToUse < minFontSize) {
          fontSizeToUse = minFontSize;
        }

        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        let centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
        let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
        ctx.font =
          "bold " +
          (bigTxt === "No results" ? 0.8 : 1.2) * fontSizeToUse +
          "px " +
          fontStyle;
        ctx.fillStyle = color;

        ctx.fillText(bigTxt, centerX, centerY - fontSizeToUse / 4);

        ctx.font = "italic " + fontSizeToUse * 0.6 + "px " + fontStyle;
        ctx.fillText(littleTxt, centerX, centerY + 0.8 * fontSizeToUse);
      }
    },
  });

  ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

  let chartDataSet = [];
  let loaded = false;

  const chartIdxs = persisted("$chartIdxs", [0, 1, 2, 3]);

  function getReportChartData(counts) {
    const chartColors = ["#1774D5", "#74D1FC", "#EFF4FB"];

    return {
      labels: ["Resolved", "Flagged", "Open"],
      datasets: [
        {
          data: [counts.compliantHits, counts.flaggedHits, counts.openHits],
          backgroundColor: chartColors,
        },
      ],
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: counts.name,
          },
        },
      },
    };
  }

  function getChartOptions(reportName, counts) {
    let totalHitsCount =
      counts.compliantHits + counts.flaggedHits + counts.openHits;

    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
          text: reportName,
        },
        datalabels: {
          color: ["#fff", "#000", "#000"],
          backgroundColor: function (value, context) {
            let index = value.dataIndex;
            if (value.dataset.data[index] == 0) {
              return "transparent";
            } else {
              return value.dataset.backgroundColor[index];
            }
          },
          formatter: function (value, context) {
            return value == 0
              ? ""
              : Math.round((value * 100) / totalHitsCount) + "%";
          },
        },
      },
      cutout: "65%",
      elements: {
        center: {
          bigText:
            totalHitsCount == 0
              ? "No results"
              : Math.round((counts.compliantHits / totalHitsCount) * 100) + "%",
          littleText: totalHitsCount == 0 ? "" : "Resolved",
          color: "#000",
          fontStyle: "Arial",
          sidePadding: 5,
          minFontSize: 12,
        },
      },
    };
  }

  onMount(async () => {
    let response = await fetchGet("/report");
    const reports = await response.reports;

    for (const report of reports) {
      const counts = {
        compliantHits: report.compliant_hits_count,
        flaggedHits: report.flagged_hits_count,
        openHits: report.open_hits_count,
      };

      chartDataSet = [
        ...chartDataSet,
        {
          data: getReportChartData(counts),
          options: getChartOptions(report.name, counts),
          reportId: report.id,
        },
      ];
    }
    loaded = true;

    for (let i = 0; i < $chartIdxs.length; i++)
      if (!chartDataSet[$chartIdxs[i]]) $chartIdxs[i] = chartDataSet.length - 1;
  });
</script>

<div class="flex flex-col">
  {#if loaded}
    <div class="grid grid-cols-2 gap-0.5 bg-base-200 md:grid-cols-4">
      {#each { length: 4 } as e, idx}
        {#key $chartIdxs[idx]}
          <ReportStatusDonut
            {chartDataSet}
            chartData={chartDataSet[$chartIdxs[idx]]}
            chartTitle={chartDataSet[$chartIdxs[idx]]?.options?.plugins?.title
              ?.text}
            reportId={chartDataSet[$chartIdxs[idx]]?.reportId}
            bind:chartIndex={$chartIdxs[idx]}
          />
        {/key}
      {/each}
    </div>
  {:else}
    <div class="grid grid-cols-2 gap-0.5 bg-base-200 md:grid-cols-4">
      {#each { length: 4 } as e}
        <div class="aspect-square w-full bg-base-100 p-[10%]">
          <div class="skeleton relative mx-auto h-full rounded-full">
            <div class="absolute inset-[20%] rounded-full bg-base-100" />
          </div>
        </div>
      {/each}
    </div>
  {/if}

  <!-- -translate-x-6 is to align with the divider -->
  <div class="mx-auto mt-4 flex -translate-x-6 gap-2 text-sm">
    <div class="flex items-center gap-1">
      <div class="h-4 w-4 rounded-full bg-[#0074D9]" />
      Resolved
    </div>
    <div class="flex items-center gap-1">
      <div class="h-4 w-4 rounded-full bg-[#6AD2FF]" />
      Flagged
    </div>
    <div class="flex items-center gap-1">
      <div class="h-4 w-4 rounded-full bg-[#EFF4FB]" />
      Open
    </div>
  </div>
</div>
