<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { getPreviewUrl } from "../../lib/utils/AssetReviewUtils";
  import { org_name } from "../../stores";
  import Modal from "../Modals/Modal.svelte";
  import DisplayPdf from "../PDF/DisplayPDF.svelte";
  import { mutateURL } from "../../helpers";

  const dispatch = createEventDispatcher();

  export let assetId = 0;
  export let report_id = 0;
  export let versions = [];

  let accumulatedFiles = []; // Array to store accumulated files

  function handleUpdate(files) {
    const newFiles = Array.from(files);

    if (newFiles.length > 1) {
      toast.error(`You can only upload one file as a new version.`);
      return false;
    }

    const allowedExtensions = [
      ".pdf",
      ".doc",
      ".docx",
      ".png",
      ".jpeg",
      ".jpg",
      ".eml",
      ".msg",
    ];
    const filteredFiles = newFiles.filter((file) => {
      const fileExtension = file.name.toLowerCase().split(".").pop();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        toast.error(
          `File ${file.name} is not supported. Please upload a PDF, DOC, or DOCX file.`,
        );
        return false;
      }
      return true;
    });
    accumulatedFiles = [...accumulatedFiles, ...filteredFiles];
  }

  async function uploadNewVersion() {
    confirmNewVersionModal.close();
    uploadNewVersionModal.close();

    const toastId = toast.loading("Uploading new version...", {
      duration: 100000, /// a lot of time until we get a response
    });

    const formData = new FormData();
    formData.append("file", accumulatedFiles[accumulatedFiles.length - 1]);
    formData.append("message", "");
    try {
      const url = mutateURL(`/report/upload/${assetId}/version`);
      await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      toast.success("New version uploaded successfully", {
        id: toastId,
        duration: 3000,
      });

      dispatch("upload");

      return true;
    } catch (error) {
      console.log(`Error uploading file: ${error}`);
      toast.error("Error uploading new version", {
        id: toastId,
        duration: 3000,
      });
      return false;
    }
  }
</script>

<Modal modalId="uploadNewVersionModal" size="sm">
  <h2 class="text-center text-xl font-semibold">
    Upload Updated Asset Version
  </h2>

  <div>
    {#if accumulatedFiles.length == 0}
      <label
        class="border-base-content/30 hover:border-primary my-8 flex w-full max-w-screen-lg cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed p-10 text-center"
        id="uploader-container"
        on:drop|preventDefault={(e) => {
          handleUpdate(e.dataTransfer.files);
          e.target.classList.remove("border-primary");
        }}
        on:dragover|preventDefault
        on:dragenter|preventDefault={(e) =>
          e.target.classList.add("border-primary")}
        on:dragleave|preventDefault={(e) =>
          e.target.classList.remove("border-primary")}
        for="version_uploader"
      >
        <Icon icon="iconoir:cloud-upload" width="32px" />
        <p
          class="my-2 text-base font-semibold text-gray-500 dark:text-gray-400"
        >
          Click (or Drag & Drop) a .pdf, .doc, .docx, .png, .jpg or .jpeg
          <br /> file to upload a new version.
        </p>

        <input
          type="file"
          class="hidden"
          id="version_uploader"
          multiple
          on:change={(e) => handleUpdate(e.target.files)}
          accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .eml, .msg"
        />
      </label>
    {:else}
      <div
        class="bg-base-200 my-8 flex justify-between rounded border px-4 py-2"
      >
        <p
          class="col-span-1 w-full overflow-hidden text-ellipsis whitespace-nowrap text-start text-xl font-medium"
        >
          {accumulatedFiles[0].name}
        </p>
        <div class="items-right col-span-1 flex">
          <div class="flex gap-1">
            <button
              class="btn btn-error btn-xs"
              on:click={() => (accumulatedFiles = [])}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    {/if}
  </div>
  <div class="collapse-arrow bg-base-200 collapse mb-8">
    <input type="checkbox" />
    <div class="collapse-title text-xl font-medium">Version History</div>
    <div class="collapse-content">
      {#each versions as version}
        <div class="mb-4">
          <div class="flex gap-4">
            <h3 class="font-bold">Uploaded v{version.version}</h3>
            {#if !version?.currentVersion}
              {#if !version.showPDF}
                <button
                  class="link-hover link link-primary text-xs"
                  on:click={() => (version.showPDF = true)}
                >
                  View Old Version
                </button>
              {:else}
                <button
                  class="link-hover link link-primary text-xs"
                  on:click={() => (version.showPDF = false)}
                >
                  Hide Version
                </button>
              {/if}
            {/if}
          </div>

          {#if version.showPDF}
            <DisplayPdf
              documentURL={getPreviewUrl(
                $org_name,
                report_id,
                assetId,
                version.aws_version_id,
              )}
              documentType="proxy"
              open={true}
            />
          {/if}

          <span class="text-sm text-[#94A3B8]">
            {version.date} - Uploaded by {version.uploader}
          </span>
        </div>
      {/each}
    </div>
  </div>

  <!-- svelte-ignore missing-declaration -->
  <button
    on:click={() => confirmNewVersionModal.showModal()}
    class="btn btn-primary w-full"
  >
    Upload Asset
  </button>

  <!-- svelte-ignore missing-declaration -->
  <button
    class="btn btn-ghost text-primary mt-2 w-full"
    on:click={() => uploadNewVersionModal.close()}
  >
    Cancel
  </button>
</Modal>

<!-- TODO: Modal - move to confirmation modal  -->
<Modal modalId="confirmNewVersionModal">
  <Icon icon="iconoir:warning-triangle" class="text-warning mx-auto text-4xl" />
  <h3 class="my-4 text-center font-bold">
    Are you sure you want to replace this asset with a new version?
  </h3>
  <p class="text-base-content/70 text-sm">
    If you choose to continue, the old asset version can still be viewed through
    version history.
  </p>
  <button on:click={uploadNewVersion} class="btn btn-primary my-4 w-full">
    Continue Uploading New Version
  </button>

  <!-- svelte-ignore missing-declaration -->
  <button
    class="btn btn-ghost text-primary -mt-2 w-full"
    on:click={() => confirmNewVersionModal.close()}
  >
    Cancel
  </button>
</Modal>
