<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  export let date;

  /**
   * Since the timezone we are getting from the backend is an UTC date,
   * we need to convert it to the local timezone. It is important to get UTC
   * dates to have each user see the same date.
   * @param {string} dateString
   */
  function convertUTCDateToCurrentTimezone(dateString) {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offset * 60 * 1000);
    return newDate;
  }

  /**
   * Get issue age in years,
   * if it's not a year old in days,
   * if it's not a day old in hours,
   * if it's not an hour old in minutes,
   * if it's not a minute old say "just now."
   * @param {string} dateString
   */
  function getDisplayDate(dateString) {
    const dateToCompare = convertUTCDateToCurrentTimezone(dateString);
    const dateFormat = "date"; //localStorage.getItem("date_format");

    if (dateFormat === "ago" || !dateFormat) {
      const today = new Date();
      const diffTime = Math.abs(today.getTime() - dateToCompare.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      const diffMinutes = Math.ceil(diffTime / (1000 * 60));
      const diffYears = Math.ceil(diffDays / 365);

      if (diffYears > 1) {
        return diffYears + " years ago";
      } else if (diffDays > 1) {
        return diffDays + " days ago";
      } else if (diffHours > 2) {
        return diffHours + " hours ago";
      } else if (diffHours > 1) {
        return diffHours + " hour ago";
      } else if (diffMinutes > 2) {
        return diffMinutes + " mins ago";
      } else {
        return "Just now";
      }
    } else {
      return dateToCompare.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    }
  }
</script>

<span class="whitespace-nowrap text-sm text-base-content/70">
  {getDisplayDate(date)}
</span>
