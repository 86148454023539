<script>
  import { fetchGet, getDisplayDate } from "../helpers.js";
  import Modal from "./Modals/Modal.svelte";

  export let reportId = 0;
  export let assetId = 0;

  let assetView = false;
  let history = [];
  let historyListDialog = null;
  let ruleHeaderText = "";
  let ruleHistoryId = -1;

  function splitActionText(action) {
    const regex = /'([^']+)'/;
    const found = action.match(regex);
    if (found) {
      const before = action.slice(0, found.index);
      const word = found[1];
      const after = action.slice(found.index + word.length + 2);
      return [before, word, after];
    }
    return [action];
  }
  function splitActionFromText(action) {
    const fromRegex = /(.*from\s+')([^']+)(.*)/;
    const found = action.match(fromRegex);
    if (found) {
      const before = found[1];
      const word = found[2];
      const after = found[3];
      return [before, word, after];
    }
    return [action];
  }

  const getData = async () => {
    const endpoint = reportId
      ? `/report/${reportId}/history`
      : `/asset/${assetId}/history`;
    const defaultHistoryMessage = reportId
      ? "Failed to load stream history"
      : "Failed to load asset history";

    try {
      const res = await fetchGet(endpoint);

      if (res.data && res.data.length > 0) history = res.data;
      else history = [{ action: "No history found", details_type: "none" }];
    } catch (error) {
      console.error(`Failed to fetch history:`, error);
      history = [{ action: defaultHistoryMessage, details_type: "none" }];
    }

    if (assetId) assetView = true;
  };

  $: if (reportId || assetId) getData();
</script>

<Modal modalId="report-history-dialog">
  <h2 class="text-xl font-semibold">
    {assetView ? "Asset " : "Stream"} History
  </h2>

  <ul class="ml-4 list-disc">
    {#each history as item}
      {#if item.details_type === "report"}
        <li>
          {#if item.action.includes("Rule") && (item.action.includes("edited") || item.action.includes("created"))}
            {#each splitActionText(item.action) as part, index}
              {#if index === 1}
                <button
                  class="link-hover link link-primary"
                  on:click={() => {
                    // If item.action has the word "Rule" then copy the text
                    // between the single quotes and set it as the ruleHeaderText.
                    const regex = /'([^']+)'/;
                    const found = item.action.match(regex);
                    ruleHeaderText = found[1];
                    ruleHistoryId = item.id;
                    document.querySelector("#report-history-dialog")?.close();
                    document
                      .querySelector("#report-history-item-details")
                      .showModal();
                  }}
                >
                  {part}
                </button>
              {:else}
                {part}
              {/if}
            {/each}
            - {getDisplayDate(item.timestamp)} by {item.user}
          {:else}
            {item.action} - {getDisplayDate(item.timestamp)} by {item.user}
          {/if}
        </li>
      {:else}
        <li>
          {item.action}
        </li>
      {/if}
    {/each}
  </ul>
</Modal>

<Modal
  modalId="report-history-item-details"
  on:close={() => {
    document.querySelector("#report-history-dialog")?.close();
    document.querySelector("#report-history-item-details")?.close();
  }}
>
  <h3 class="text-lg font-semibold">
    {assetView ? "Asset " : "Stream"} History
    {ruleHeaderText} - History Item (#{ruleHistoryId})
  </h3>
  <ul class="ml-4 list-disc">
    {#each history as item}
      {#if item.details_type === "rule" && item.details_id === ruleHistoryId}
        <li>
          {#each splitActionFromText(item.action) as part, index}
            <span class:line-through={index === 1}>{part}</span>
          {/each}
          - {getDisplayDate(item.timestamp)} by {item.user}
        </li>
      {/if}
    {/each}
  </ul>
</Modal>
