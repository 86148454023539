<script>
  import { link } from "svelte-spa-router";
  import Badge from "../WorkflowBoard/Badge.svelte";
  import { getRuleColourCounts } from "../../../lib/utils/RuleUtils";
  import {
    getTotalAssetRules,
    getProcessingAssetRules,
  } from "../../../lib/utils/AssetReviewUtils";
  import { AssetStatus } from "../../../lib/interfaces/Asset.interface";
  import { RuleStatus } from "../../../lib/interfaces/Rule.interface";
  import AssignUsers from "../AssignUsers.svelte";

  export let rows = [];
  export let row = {};
  export let reportId;
  export let index;
  export let assetStatusMap = new Map();

  let assetStatus = {
    ready: 0,
    total: 0,
    progressPercentage: 0,
  };

  function isOverdue(dateString) {
    const dueDate = new Date(dateString);
    const today = new Date();
    return dueDate < today;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
  }

  function updateAssetStatus() {
    const stats = assetStatusMap.get(row.asset.id);

    if (stats && row.asset.status !== AssetStatus.READY) {
      assetStatus.total = stats.assetRuleStatuses.total;
      assetStatus.ready = stats.assetRuleStatuses.ready;

      if (assetStatus.total === 0 && assetStatus.ready === 0) {
        assetStatus.progressPercentage = 0;
      } else {
        assetStatus.progressPercentage = Math.round(
          (100 * assetStatus.ready) / assetStatus.total,
        );
      }
    } else if (row.asset.status === AssetStatus.READY) {
      assetStatus.total = 100;
      assetStatus.ready = 100;
      assetStatus.progressPercentage = 100;
    } else {
      assetStatus.total = 0;
      assetStatus.ready = 0;
      assetStatus.progressPercentage = 0;
    }

    assetStatus = assetStatus;
  }

  $: updateAssetStatus(), [assetStatusMap, row];
</script>

<tr style={index === rows.length - 1 ? "border-radius: 16px" : ""}>
  <td class="w-[25%]">
    <a
      class="hover:text-primary block truncate py-0.5 text-start font-semibold"
      href="/review/{reportId}/asset/{row.asset.id}"
      use:link
    >
      {row.asset.name}
    </a>
  </td>
  <td class="w-[25%]">
    <div class="flex gap-1">
      <Badge fullyRound={true} bgColor="#c4d4bd" textColor="#336A1D">
        {RuleStatus.PASSED} ({getRuleColourCounts(row.rules, "green")})
      </Badge>
      <Badge fullyRound={true} bgColor="#fbe5b8" textColor="#FF8D00">
        {RuleStatus.REVIEW} ({getRuleColourCounts(row.rules, "amber")})
      </Badge>
      <Badge fullyRound={true} bgColor="#ff4e4e5e" textColor="#FC0000">
        {RuleStatus.RISK} ({getRuleColourCounts(row.rules, "red")})
      </Badge>
    </div>
  </td>
  <td class="w-[10%]">
    <Badge fullyRound={true} bgColor="#e0e0e0" textColor="#000">
      {row.asset.status}
    </Badge>
  </td>
  <td class="w-[10%]">
    {#key assetStatus}
      {#if row.asset.status === AssetStatus.PROCESSING}
        <div class="flex flex-row items-center gap-2">
          <span class="min-w-8 text-start text-sm"> 0&#37; </span>
          <progress class="progress progress-primary w-3/4" />
        </div>
      {:else}
        <div class="flex flex-row items-center gap-2">
          <span class="min-w-8 text-start text-sm">
            {assetStatus.progressPercentage}&#37;
          </span>
          <progress
            class="progress progress-primary w-3/4"
            value={assetStatus.ready}
            max={assetStatus.total}
          />
        </div>
      {/if}
    {/key}
  </td>
  <td class="w-[10%]">
    <!-- <div class="flex gap-1"> -->
    <Badge fullyRound={true} bgColor="#e0e0e0" textColor="#000">
      {row.asset.approval_status}
    </Badge>
  </td>
  <td class="w-[10%]">
    <!-- </div> -->
    {#if row.asset.due_date && row.asset.approval_status !== "Approved"}
      <div class="tooltip h-6" data-tip="Due Date">
        <Badge
          fullyRound={true}
          bgColor={isOverdue(row.asset.due_date) ? "#FFE4E4" : "#E0E0E0"}
          textColor={isOverdue(row.asset.due_date) ? "#D92D20" : "black"}
        >
          {formatDate(row.asset.due_date)}
        </Badge>
      </div>
    {/if}
  </td>
  <td class="w-[20%]">
    <AssignUsers
      rule_id={null}
      asset_id={row.asset.id}
      assignees={row.asset.asset_assigned_users}
      disableEditing={true}
      containerClass="ml-auto"
    />
    <!-- <ApproverStatus
      bind:activeWorkflowStep={row.asset.workflow_step}
      bind:approvers={row.asset.approvers}
      bind:workflow
      assetId={asset.asset.id}
    /> -->
  </td>
</tr>
