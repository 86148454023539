<script>
  import { createEventDispatcher } from "svelte";
  import { link } from "svelte-spa-router";
  import { org_id, org_name } from "../../stores";
  import { getTextConstant } from "../../lib/utils/ConstantsUtils";

  const dispatch = createEventDispatcher();

  export let report_id;
  export let isNewWorkflow = true;
  export let uploadedFiles = [];
  export let ran_reports = [];

  const textConstants = getTextConstant($org_id, $org_name, "AssetReview");

  $: tooltip = isNewWorkflow
    ? "Select a workflow first"
    : uploadedFiles.length === 0
      ? "Upload assets first"
      : ran_reports.length === 0
        ? "You need at least one rule to proceed."
        : "";
</script>

<!-- if new workflow is false  -->

{#if tooltip === ""}
  <a
    href="/review/{report_id}"
    class="btn btn-primary btn-sm"
    use:link
    on:click={() => dispatch("click")}
  >
    Go to {textConstants["Folder"]}
  </a>
{:else}
  <div class="tooltip" data-tip={tooltip}>
    <button class="btn btn-disabled btn-primary btn-sm">
      Go to {textConstants["Folder"]}
    </button>
  </div>
{/if}
