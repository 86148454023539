<div class="divider my-0" />

<style>
  ul.menu a,
  ul.menu button {
    border-radius: 0;
  }

  ul.menu a:active,
  ul.menu button:active,
  ul.menu a.active {
    background-color: oklch(var(--b3) / 80%) !important;
    color: unset;
  }

  ul.menu a.active {
    box-shadow: inset 4px 0 0 0 oklch(var(--p)) !important;
  }
  ul.menu .disabled {
    pointer-events: none; /* Prevent clicking */
    color: grey; /* Change color to grey or your choice */
  }
</style>
