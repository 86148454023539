<script>
  import { toast } from "svelte-sonner";
  import { fetchPost, fetchGet } from "../../helpers";
  import { org_users } from "../../stores";
  import LogoLoader from "../LogoLoader.svelte";
  import Icon from "@iconify/svelte";
  import Modal from "../Modals/Modal.svelte";
  import Select from "../Select.svelte";

  let bottomButtons = {
    show: true,
    primaryText: "Submit",
    secondaryText: "Cancel",
    loading: false,
    primaryAction: inviteNewUser,
    secondaryAction: closeModal,
  };

  let resetToken;
  let email = "";
  let firstName = "";
  let lastName = "";
  let role = "";
  let errorMessage = "";
  let inviteDisabled = false;

  let roles = [
    { value: "admin", label: "Admin" },
    { value: "assignee", label: "Assignee" },
    { value: "user", label: "User" },
  ];

  function openModal() {
    email = "";
    firstName = "";
    lastName = "";
    role = "";

    errorMessage = "";
    inviteDisabled = false;
    document.querySelector("#org-user-invite")?.showModal();
  }

  function closeModal() {
    document.querySelector("#org-user-invite")?.close();
  }

  function validateForm() {
    if (!email.trim()) {
      errorMessage = "Email is required.";
      return false;
    }
    if (!firstName.trim()) {
      errorMessage = "First name is required.";
      return false;
    }
    if (!lastName.trim()) {
      errorMessage = "Last name is required.";
      return false;
    }
    if (!role) {
      errorMessage = "Role is required.";
      return false;
    }

    return true;
  }

  async function inviteNewUser() {
    if (!validateForm()) {
      return;
    }

    bottomButtons.loading = true;
    const response = await fetchPost("/org-users/invite", {
      email: email.toLowerCase().trim(),
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      role,
    });

    if (!response.success) {
      errorMessage = response.already_exists
        ? "Can't invite user that already is in another organization."
        : "";
      bottomButtons.loading = false;
      return;
    }

    // Add required properties and update store
    org_users.update(users => [...users, {
      ...response.user,
      is_deleted: false,
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      email: email.toLowerCase().trim(),
      role
    }]);
    
    toast.success("User invited successfully");
    closeModal();
    bottomButtons.loading = false;
  }

  function checkAddRemovedUser() {
    if ($org_users.some((ou) => ou.email === email && ou.is_deleted)) {
      errorMessage =
        "To re-invite a user that has been removed, please contact support.";
      inviteDisabled = true;
    } else if ($org_users.some((ou) => ou.email === email)) {
      errorMessage =
        "A user with this email already exists within your organisation.";
      inviteDisabled = true;
    } else {
      errorMessage = "";
      inviteDisabled = false;
    }
  }

  $: checkAddRemovedUser(), [email];
</script>

<button class="btn btn-primary btn-sm" on:click={openModal}>
  Invite User
</button>

<Modal
  modalId="org-user-invite"
  size="sm"
  cornerBackButton={true}
  cornerCloseButton={false}
  {bottomButtons}
>
  <div class="space-y-2">
    <div
      class="bg-primary-content m-auto flex aspect-square w-16 items-center justify-center rounded-full"
    >
      <Icon icon="iconoir:mail" class="text-primary text-3xl" />
    </div>
    <h3 class="text-center font-bold">Invite User</h3>
  </div>

  <div class="mt-6 space-y-2">
    <p class="text-sm">Invitee's Email</p>
    <input
      class="input {inviteDisabled ? 'input-error' : 'input-bordered'} w-full"
      placeholder="Enter invitee's email"
      bind:value={email}
      type="email"
    />

    <p class="text-sm">Invitee's First Name</p>
    <input
      class="input input-bordered w-full"
      placeholder="Enter invitee's first name"
      bind:value={firstName}
      type="text"
    />

    <p class="text-sm">Invitee's Last Name</p>
    <input
      class="input input-bordered w-full"
      placeholder="Enter invitee's last name"
      bind:value={lastName}
      type="text"
    />

    <p class="text-sm">Select User Role</p>
    <Select
      items={roles}
      placeholder="Select User Role"
      bind:selectedValue={role}
      size="md"
      classes="w-full"
    />
  </div>

  {#if errorMessage}
    <span class="text-xs text-red-500">*{errorMessage}</span>
  {/if}
</Modal>
