<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost, generate32BitInteger } from "../../helpers";
  import { invalidInputFocus } from "../../lib/Focus";
  import { slide } from "svelte/transition";

  export let query;
  export let classes = "";

  const dispatch = createEventDispatcher();

  let abandonedInputs = new Map();
  let elementId = generate32BitInteger();

  async function stem() {
    let stem_word = query.value.toLowerCase();
    let response = await fetchPost("/stem", { word: [stem_word] });
    return response.stems;
  }

  async function handleStemClick() {
    const stems = await stem();

    if (stems.length > 1) {
      // Create a new group with OR relation
      const newGroup = {
        id: generate32BitInteger(),
        not: false,
        type: "relation",
        relation: "OR",
        delete: false,
        nested: [
          // Original clause
          {
            ...query,
            id: generate32BitInteger(),
          },
          // Stem clauses
          ...stems.map((stem) => ({
            id: generate32BitInteger(),
            field: query.field,
            type: "clause",
            not: false,
            operation: query.operation,
            value: stem,
            slop: query.slop,
            term: query.term,
            regex: query.regex,
            delete: false,
            options: [],
          })),
        ],
      };

      // Replace the current clause with the new group
      Object.assign(query, newGroup);
      query = query;
    } else {
      toast.error("Word stems do not exist for this word");
    }
  }

  function handleAbandonedFocus(id, isAbandoned) {
    if (isAbandoned) abandonedInputs.set(id, isAbandoned);
    else abandonedInputs.delete(id);

    abandonedInputs = abandonedInputs;
  }

  $: wordCount = query.value.trim().split(/\s+/).length;
  $: isDisabled = wordCount > 1;

  // Whenever query changes, dispatch an event to notify the parent
  $: {
    dispatch("queryChanged", query);
  }
</script>

<div class="flex grow flex-col">
  <textarea
    class="textarea textarea-bordered textarea-sm w-full min-w-[4rem] {classes}"
    bind:value={query.value}
    rows="1"
    id="query-text-example-{elementId}"
    use:invalidInputFocus={{
      id: `query-text-example-${elementId}`,
      onChange: handleAbandonedFocus,
      validateEmpty: true,
    }}
  />
  {#if abandonedInputs.get(`query-text-example-${elementId}`) || false}
    <p class="text-error ml-1 first-line:text-xs" in:slide out:slide>
      *Cannot have an empty query.
    </p>
  {/if}
</div>

<div
  class="tooltip tooltip-left"
  data-tip="Add different variations of the word to broaden your check"
>
  <button
    class="btn btn-square btn-ghost btn-sm hover:btn-error"
    on:click={handleStemClick}
    disabled={isDisabled}
  >
    <Icon icon="iconoir:sparks" />
  </button>
</div>
