<script>
  import { tick } from "svelte";
  import { fetchPost } from "../../helpers";
  import { RuleDataType } from "../../lib/interfaces/Rule.interface";
  import { convertBoolToBuilder } from "./../../ruleBuilderConverter";
  import GeneratedRule from "./messages/GeneratedRule.svelte";
  import Text from "./messages/Text.svelte";
  import ThinkingDots from "./messages/ThinkingDots.svelte";

  export let questionCount;
  export let chat_type = "discover";
  export let d_types = [];

  export let report_id;

  const PRE_INSTRUCT = `Generate Opensearch Lucene DSL Boolean Queries to find social media posts and web assets that semantically match the prompt of the USER.
    A Boolean Query can use the following fields: \`text\` (string) - text contents, \`url\` (string) - website or endpoint posted on and \`like_count\` (integer).
    Follow these rules in creating a Boolean Query:
    - Use a lot of brackets, as often as possible or it is a failure. Do not use the + or - operators.
    - Create phrases and use proximity searches whenever possible. Use double quotes to indicate phrases. 
    - Always include a field when building a query.
    - Never use range queries.
    - When finding mentions of words, unless specified to obtain exact mentions, find words that are instances of the prompt that can be used in the same context.
    - If more information is needed to create the query, output the explanation you require to complete the request.
    - Ask as many questions before answering to assure accuracy.
    Don't explain to people the type of query, for example, you don't need to say this "To create a Lucene DSL boolean query" they are non-techincal users, they just want good rules and clear explanations.`;

  // This function will return the current local time as a string formatted as "hh:mm AM/PM"
  function getLocalTime() {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    const strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  const types = new Map([
    ["text", Text],
    ["thinking", ThinkingDots],
    ["rule", GeneratedRule],
  ]);
  let messages = [{ role: "system", content: PRE_INSTRUCT }];

  export let history = null;
  if (history === null) {
    history = [
      {
        type: "text",
        role: "system",
        timestamp: getLocalTime(),
        data: {
          text: {
            text: [
              "I'm here to help you build rules. I've suggested some rules I can help you build below, or feel free to ask me to help you build any other rule.",
            ],
          },
        },
      },
    ];
  } else {
    history = history.filter(
      (communication) => communication.type !== "thinking",
    );
  }

  export function addCommunication(communication) {
    if (!types.has(communication.type)) {
      console.error("unknown communication type", communication.type);
      return;
    }
    const thinking = {
      type: "thinking",
      data: {},
      placeholder: true,
    };

    if (communication.role === "user")
      history = [
        ...history.filter((communication) => !communication.placeholder),
        communication,
        thinking,
      ];
    else
      history = [
        ...history.filter((communication) => !communication.placeholder),
        communication,
      ];
  }

  export async function addUserCommunication(text) {
    questionCount = questionCount + 1;
    addCommunication({
      type: "text",
      role: "user",
      timestamp: getLocalTime(),
      data: { text: { text: [text] } },
    });
    // Add the new user message to the messages array
    messages.push({
      role: "user",
      content: text,
    });
    let replies;
    if (chat_type == "discover") {
      replies = await fetchPost("/chat/discover", { messages: messages });
    } else {
      replies = await fetchPost("/chat/build", { messages: messages });
    }
    if (!replies.success) {
      console.warn(
        "Something went wrong while asking a question",
        replies.reason,
      );
    } else {
      if (chat_type === "discover") {
        if (replies && replies.responses) {
          replies.responses.forEach((response) => {
            //add to message array for preserving the convo
            messages.push({ role: "system", content: response });
            const paragraphs = response.split("\n\n");
            paragraphs.forEach((paragraph) => {
              // Extracts rules enclosed in triple backticks and the text in between.
              const parts = paragraph.split(/(```[\s\S]+?```)/);
              parts.forEach((part) => {
                if (part.startsWith("```") && part.endsWith("```")) {
                  // This is a rule enclosed in triple backticks.
                  let rule = part.slice(3, -3).trim();
                  addCommunication({
                    type: "rule",
                    role: "system",
                    timestamp: getLocalTime(),
                    data: {
                      name: null,
                      description: null,
                      explanation: null,
                      boolean: rule,
                      validated: response.validated,
                      type: RuleDataType.BUILDER,
                      builder: convertBoolToBuilder(rule) || [],
                      data_types: [],
                      size: 10000,
                    },
                  });
                } else {
                  // This is text, split further by line breaks.
                  part.split("\n").forEach((line) => {
                    if (line.trim() !== "") {
                      addCommunication({
                        type: "text",
                        role: "system",
                        timestamp: getLocalTime(),
                        data: { text: { text: [line.trim()] } },
                      });
                    }
                  });
                }
              });
            });
          });
        }
      } else {
        replies.responses.forEach((response) => {
          try {
            // Attempt to convert the boolean to a builder format within the try block
            const builderResult = convertBoolToBuilder(response.rule_query);
            // Since we're here, it means convertBoolToBuilder succeeded without throwing an error
            addCommunication({
              type: "rule",
              role: "system",
              timestamp: getLocalTime(),
              data: {
                name: response.rule_name,
                description: response.rule_description,
                explanation: response.rule_explanation,
                boolean: response.rule_query,
                validated: response.validated,
                type: RuleDataType.BUILDER,
                builder: builderResult, // Directly use builderResult as it's successfully obtained
                data_types: [],
                size: 10000,
              },
            });
          } catch (error) {
            // If convertBoolToBuilder throws an error, we catch it here and can decide to log it or take other actions
            // This block will skip the addCommunication call for this iteration
            console.warn(
              "Failed to convert boolean to builder for response:",
              response.rule_query,
              error,
            );
          }
        });
        messages = replies.messages;
      }
    }
  }

  function handlePostback(event) {
    addUserCommunication(event.detail);
  }

  const scrollToBottom = async () => {
    await tick();
    scrollToMe.scrollIntoView({ behavior: "smooth" });
  };

  $: if (history) scrollToBottom();
</script>

<div class="p-4" id="chatContainer">
  {#each history as communication}
    <svelte:component
      this={types.get(communication.type)}
      class="element"
      role={communication.role}
      timestamp={communication.timestamp}
      data={communication.data}
      {d_types}
      {report_id}
      on:postback={handlePostback}
      on:updateRulePen
    />
  {/each}
  <div id="scrollToMe" />
</div>
