<script>
  import Icon from "@iconify/svelte";
  import { tidyPoolLabel } from "../helpers.js";
  import LogoLoader from "./LogoLoader.svelte";

  export let badgesInSearchboxDesign = false;
  export let useTidyPoolLabels = false;
  export let items = {};
  export let searchTerm = "";
  export let showSearchBar = true;
  export let selectedItems = [];

  export let searchBarClasses = badgesInSearchboxDesign
    ? "input input-sm input-ghost w-60 max-w-sm"
    : "input input-sm input-bordered w-60 max-w-sm";

  export let loading = false;

  $: if (items) {
    for (const key of Object.keys(items)) {
      let websiteSelectedCount = 0;
      let socialSelectedCount = 0;

      for (const member of items[key].website.members) {
        member.selected = selectedItems.includes(member.name);
        if (member.selected) websiteSelectedCount++;
      }

      for (const member of items[key].social.members) {
        member.selected = selectedItems.includes(member.name);
        if (member.selected) socialSelectedCount++;
      }

      items[key].website.selected = getSelectedState(
        websiteSelectedCount,
        items[key].website.members.length,
      );
      items[key].social.selected = getSelectedState(
        socialSelectedCount,
        items[key].social.members.length,
      );

      items[key].selected = getMainCategoryState(items[key]);

      let newSelectedItems = [];
      for (const member of items[key].website.members)
        if (member.selected) newSelectedItems.push(member.name);

      for (const member of items[key].social.members)
        if (member.selected) newSelectedItems.push(member.name);

      selectedItems = [...new Set([...selectedItems, ...newSelectedItems])];

      for (const member of items[key].website.members)
        member.tidyName = tidyPoolLabel(member.name);
      for (const member of items[key].social.members)
        member.tidyName = tidyPoolLabel(member.name);
    }
  }

  function getSelectedState(selectedCount, totalCount) {
    if (selectedCount === 0) return "none";
    else if (selectedCount === totalCount) return "all";
    else return "some";
  }

  function getMainCategoryState(key) {
    const websiteState = key.website.selected;
    const socialState = key.social.selected;

    if (websiteState === "all" && socialState === "all") return "all";
    else if (websiteState === "all" && key.social.members.length === 0)
      return "all";
    else if (socialState === "all" && key.website.members.length === 0)
      return "all";
    else if (websiteState === "none" && socialState === "none") return "none";
    else return "some";
  }

  function toggleItemSelection(itemName) {
    if (selectedItems.includes(itemName)) {
      selectedItems = selectedItems.filter((item) => item !== itemName);
    } else {
      selectedItems = [...selectedItems, itemName];
    }
  }

  function deselectItem(itemName) {
    for (const key of Object.keys(items)) {
      const memberIndex = items[key].website.members.findIndex(
        (member) => member.name === itemName,
      );
      if (memberIndex !== -1) {
        items[key].website.members[memberIndex].selected = false;
        items[key].website.members[memberIndex].show = true;
        items = { ...items };
        break;
      }

      const memberIndex2 = items[key].social.members.findIndex(
        (member) => member.name === itemName,
      );
      if (memberIndex2 !== -1) {
        items[key].social.members[memberIndex2].selected = false;
        items[key].social.members[memberIndex2].show = true;
        items = { ...items };
        break;
      }
    }

    selectedItems = selectedItems.filter((item) => item !== itemName);
  }

  function toggleAllItems(key, subcategory) {
    const members = items[key][subcategory].members;
    const selected = items[key][subcategory].selected;

    if (selected === "all")
      for (const member of members) {
        member.selected = false;
        const index = selectedItems.indexOf(member.name);
        if (index > -1) selectedItems.splice(index, 1);
      }
    else
      for (const member of members) {
        member.selected = true;
        if (!selectedItems.includes(member.name))
          selectedItems.push(member.name);
      }

    items[key][subcategory].selected = selected === "all" ? "none" : "all";
  }

  const toggleEntireCategory = (key) => {
    const members = items[key].website.members.concat(
      items[key].social.members,
    );
    const selected = items[key].selected;

    if (selected === "all")
      for (const member of members) {
        member.selected = false;
        const index = selectedItems.indexOf(member.name);
        if (index > -1) selectedItems.splice(index, 1);
      }
    else
      for (const member of members) {
        member.selected = true;
        if (!selectedItems.includes(member.name))
          selectedItems.push(member.name);
      }

    items[key].selected = selected === "all" ? "none" : "all";
  };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->

<div class="dropdown">
  {#if showSearchBar}
    {#if badgesInSearchboxDesign}
      <label
        class="flex flex-wrap items-center gap-2 rounded border p-4"
        for="searchChannels"
      >
        {#each selectedItems as item}
          <button
            class="btn btn-sm bg-base-300 grow-0"
            on:click={() => deselectItem(item)}
          >
            <Icon icon="ic:baseline-close" class="h-4 w-4" />
            {tidyPoolLabel(item)}
          </button>
        {/each}
        <input
          tabindex="0"
          type="text"
          placeholder="Type to filter the channel(s)"
          class="{searchBarClasses} !border-0 !outline-0"
          id="searchChannels"
          bind:value={searchTerm}
          autocomplete="off"
        />
      </label>
    {:else}
      <input
        tabindex="0"
        type="text"
        placeholder="Type to filter the channel(s)"
        class="{searchBarClasses} outline-none"
        bind:value={searchTerm}
        autocomplete="off"
      />
    {/if}
  {/if}

  <ul
    tabindex="0"
    class="menu dropdown-content menu-sm bg-base-100 z-10 max-h-72 flex-nowrap overflow-y-auto rounded border p-1 shadow
      {badgesInSearchboxDesign ? 'w-full' : 'w-96'}"
  >
    {#if loading}
      <li class="menu-title">
        <div class="flex gap-2">
          Loading <LogoLoader size="1.25rem" />
        </div>
      </li>
    {/if}

    {#each Object.keys(items) as key}
      {#if items[key].website.members.filter((m) => m.tidyName
          .toLowerCase()
          .includes(searchTerm.toLowerCase())).length > 0 || items[key].social.members.filter( (m) => m.tidyName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()), ).length > 0}
        <!-- Main category -->
        <li>
          <label for="" on:click={() => toggleEntireCategory(key)}>
            <input
              type="checkbox"
              checked={items[key].selected == "all"}
              indeterminate={items[key].selected === "some"}
              class="checkbox-primary checkbox checkbox-sm"
            />
            <span class="text-base font-semibold capitalize">{key}</span>
          </label>
        </li>
      {/if}

      <!-- website subcategory -->
      {#if items[key].website.members.filter((m) => m.tidyName
          .toLowerCase()
          .includes(searchTerm.toLowerCase())).length > 0}
        <li class="ml-4">
          <label for="" on:click={() => toggleAllItems(key, "website")}>
            <input
              type="checkbox"
              checked={items[key].website.selected == "all"}
              indeterminate={items[key].website.selected === "some"}
              class="checkbox-primary checkbox checkbox-xs"
            />
            <span class="font-semibold capitalize">Website</span>
          </label>
        </li>
      {/if}
      {#each items[key].website.members as member}
        {#if member.tidyName.toLowerCase().includes(searchTerm.toLowerCase())}
          <li class="ml-10">
            <label for="" on:click={() => toggleItemSelection(member.name)}>
              <input
                type="checkbox"
                class="checkbox-primary checkbox checkbox-xs"
                bind:checked={member.selected}
              />
              <span class="text-sm">
                {useTidyPoolLabels ? member.tidyName : member.name}
              </span>
            </label>
          </li>
        {/if}
      {/each}

      <!-- social subcategory -->
      {#if items[key].social.members.filter((m) => m.tidyName
          .toLowerCase()
          .includes(searchTerm.toLowerCase())).length > 0}
        <li class="ml-4">
          <label for="" on:click={() => toggleAllItems(key, "social")}>
            <input
              type="checkbox"
              checked={items[key].social.selected == "all"}
              indeterminate={items[key].social.selected === "some"}
              class="checkbox-primary checkbox checkbox-xs"
            />
            <span class="font-semibold capitalize">Social Media</span>
          </label>
        </li>
      {/if}
      {#each items[key].social.members as member}
        {#if member.tidyName.toLowerCase().includes(searchTerm.toLowerCase())}
          <li class="ml-10">
            <label for="" on:click={() => toggleItemSelection(member.name)}>
              <input
                type="checkbox"
                class="checkbox-primary checkbox checkbox-xs"
                bind:checked={member.selected}
              />
              <span class="text-sm">
                {useTidyPoolLabels ? member.tidyName : member.name}
              </span>
            </label>
          </li>
        {/if}
      {/each}
    {/each}
  </ul>
</div>

{#if !badgesInSearchboxDesign}
  {#each selectedItems as item}
    <button class="btn-base-300 btn btn-sm" on:click={() => deselectItem(item)}>
      <Icon icon="ic:baseline-close" class="h-4 w-4" />
      {tidyPoolLabel(item)}
    </button>
  {/each}
{/if}
