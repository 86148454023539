<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import { createEventDispatcher } from "svelte";
  import LogoLoader from "../../LogoLoader.svelte";
  import IssueTableAge from "./IssueTableAge.svelte";
  import IssueTableAssignedTo from "./IssueTableAssignedTo.svelte";
  import IssueTableHit from "./IssueTableHit.svelte";
  import IssueTableHitOptions from "./IssueTableHitOptions.svelte";
  import IssueTableHitSelector from "./IssueTableHitSelector.svelte";
  import IssueTableRule from "./IssueTableRule.svelte";
  import IssueTableSource from "./IssueTableSource.svelte";
  import IssueTableStatus from "./IssueTableStatus.svelte";
  import TableType from "../TableType.svelte";
  import { getDataType } from "../../../lib/utils/GeneralDataUtils";

  export let data;
  export let originalData;
  export let entry;

  export let hitSearchText;
  export let hitSearchSettings;

  export let sourceSearchText;
  export let sourceSearchSettings;

  export let currentPage;
  export let loading = false;

  export let openedTab = "Open";

  export let canUseKeysToPaginate = false;
  export let highlightedRule = "";

  export let disableEditing = false;

  export let tableRootPage = "";
  let assigneesUpdated = false;

  const dispatch = createEventDispatcher();

  function sendRefreshRequest(newData) {
    dispatch("refresh", newData);
  }

  function sendTimestampData(timestamp) {
    dispatch("clickTimestamp", timestamp);
  }

  function handleHighlightRule(e) {
    highlightedRule = e.detail;
  }
</script>

<tr class="text-xs">
  <td
    class="border-l-4"
    class:!border-l-warning={entry.hit.data_type === "DOCUMENT"}
    style="border-left-color: {entry.hit.index
      .toLowerCase()
      .includes('instagram')
      ? '#FF0069'
      : entry.hit.index.toLowerCase().includes('tiktok')
        ? '#00f2ea'
        : entry.hit.index.toLowerCase().includes('facebook')
          ? '#4267B2'
          : entry.hit.index.toLowerCase().includes('twitter')
            ? '#1DA1F2'
            : entry.hit.index.toLowerCase().includes('linkedin')
              ? '#0077b5'
              : entry.hit.index.toLowerCase().includes('youtube')
                ? '#FF0000'
                : '#2b3440'}"
  >
    <div class="flex items-center gap-2">
      {#key currentPage}
        <IssueTableHitSelector
          bind:data
          hitId={entry.hit.id}
          on:entrySelectedToggled={() => dispatch("entrySelectedToggled")}
          {disableEditing}
        />
      {/key}
    </div>
  </td>
  <td>
    <div class="flex items-center gap-2">
      <TableType
        type={entry.hit.index}
        data_type={getDataType(entry.hit)}
        classes="text-3xl shrink-0"
      />
      <div class="max-w-[calc(100%-38px)]">
        <h3 class="truncate font-semibold uppercase">
          {getDataType(entry.hit)}
        </h3>
        <div class="truncate">
          {entry.hit.vendor ? `- ${entry.hit.vendor}` : ""}
        </div>
      </div>
    </div>
  </td>
  <td class="w-[200px]">
    <IssueTableSource
      hitId={entry.hit.id}
      hitUrl={entry.hit.url}
      hitTitle={entry.hit.title}
      hitContentLink={entry.hit.content_link}
      bind:searchText={sourceSearchText}
      bind:searchSettings={sourceSearchSettings}
      hitPool={entry.hit.index}
    />
  </td>
  <td class="w-full min-w-[320px]">
    <IssueTableHit
      hitId={entry.hit.id}
      hit={entry.hit.highlight}
      hitUrl={entry.hit.url}
      hitContentLink={entry.hit.content_link}
      hitField={entry.hit.field}
      bind:searchText={hitSearchText}
      bind:searchSettings={hitSearchSettings}
      hitPool={entry.hit.index}
      hitDataType={entry.hit.data_type}
      hitTimestamp={entry.hit.data_type.toLowerCase() == 'timestamp' ? entry.hit.media_timestamp : null}
      on:click={() => sendTimestampData(entry.hit)}
    />
  </td>
  <td class="min-w-{tableRootPage == 'spelling' ? '[300px]' : '[120px]'}">
    <IssueTableRule {tableRootPage} {entry} />
  </td>
  <td>
    <IssueTableAge
      date={entry.hit.last_updated
        ? entry.hit.last_updated
        : entry.hit.created_date}
    />
  </td>
  <td>
    <div class="flex items-center justify-end gap-2">
      {#if ["Flagged", "Closed", "Removed"].includes(openedTab)}
        {#key entry.hit.id}
          <IssueTableAssignedTo
            bind:assigneesUpdated
            bind:assignees={entry.hit.assigned_users}
            hit={entry}
            {disableEditing}
            bind:data
          />
        {/key}
      {/if}

      {#if ["Open", "Flagged", "Closed"].includes(openedTab)}
        <IssueTableStatus
          status={entry.hit.in_report}
          hitId={entry.hit.id}
          {disableEditing}
          bind:originalData
          on:refresh={(e) => sendRefreshRequest(e.detail)}
          {tableRootPage}
          {entry}
        />
      {/if}

      {#if loading}
        <LogoLoader />
      {:else}
        <IssueTableHitOptions
          on:refresh={(e) => sendRefreshRequest(e.detail)}
          on:close={(e) => {
            canUseKeysToPaginate = true;
          }}
          on:open={(e) => (canUseKeysToPaginate = false)}
          on:highlightRule={handleHighlightRule}
          ruleName={entry.rule.name}
          reportId={entry.report.id}
          bind:originalData
          {entry}
          {disableEditing}
          on:assign_state_updated={() => {
            assigneesUpdated = true;
          }}
          bind:data
        />
      {/if}
    </div>
  </td>
</tr>
