<script>
  import Icon from "@iconify/svelte";
  import { clickOutside, fetchGet } from "../../../helpers";
  import Hit from "./Hit.svelte";
  import Report from "./Report.svelte";
  import Rule from "./Rule.svelte";

  let inputValue = "";
  let size = 8;
  let results = [];
  let search_component_dict = { Report: Report, Rule: Rule, Hit: Hit };
  let q = "";
  let isOpen = false;

  async function handleSearch() {
    if (inputValue != "") {
      q = inputValue.trim();
      const data = await fetchGet(`/search?q=${q}&size=${size}`);
      results = data ? await data.data : [];
      isOpen = true;
    } else results = [];
  }
</script>

<div class="relative ml-auto" use:clickOutside={() => (isOpen = false)}>
  <div
    class="relative rounded-full border"
    class:border-error={!inputValue.match(/^[0-9]*$/)}
  >
    <div
      class="tooltip tooltip-bottom"
      data-tip={inputValue.match(/^[0-9]*$/) ? "Search" : "ID must be a Number"}
    >
      <input
        class="h-8 bg-transparent px-4 py-2 pr-8 text-white placeholder:text-white/70 focus:outline-none"
        id="navSearch"
        bind:value={inputValue}
        on:keydown={(e) => e.which === 13 && handleSearch()}
        type="text"
        pattern="[0-9]*"
        placeholder="Hit, Stream or Rule ID"
      />
    </div>
    <button
      class="btn btn-circle btn-ghost btn-sm absolute right-0"
      on:click={handleSearch}
    >
      <Icon icon="iconoir:search" class="text-xl" />
    </button>
  </div>

  {#if isOpen}
    <ul
      class="absolute right-0 top-full mt-1 w-80 list-disc space-y-2 rounded border bg-base-100 p-4 pl-8 text-base-content shadow-xl"
    >
      {#if results.length}
        {#each results as result}
          <svelte:component
            this={search_component_dict[result.type]}
            data={result.data}
          />
        {/each}
      {:else if results.length == 0}
        <p>No results found</p>
      {/if}
    </ul>
  {/if}
</div>
