<script>
  import { javascript } from "@codemirror/lang-javascript";
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  import CodeMirror from "svelte-codemirror-editor";
  import { slide } from "svelte/transition";
  import {
    RuleSearchType,
    RuleStatus,
  } from "../../../lib/interfaces/Rule.interface";
  import { isEmpty } from "../../../lib/utils/GenericUtils";
  import ContextualRuleRow from "../../AssetReview/ContextualRules/ContextualRuleRow.svelte";
  import QueryPrettyPrint from "../../AssetReview/QueryPrettyPrint.svelte";
  import Badge from "../../AssetReview/WorkflowBoard/Badge.svelte";
  import { tidyPoolLabel } from "./../../../helpers.js";
  import {
    isParentConditionalRule,
    ruleSearchTypeToLabel,
  } from "../../../lib/utils/RuleUtils";

  const dispatch = createEventDispatcher();

  export let rules = [];
  export let assetRulesTable = false;
  export let showEdit = true;

  let activeChildRuleId = -1;
</script>

<tr transition:slide>
  <div class="flex flex-col gap-1 p-2 pt-0" transition:slide>
    {#each rules as rule}
      <div class="bg-base-100 min-w-[700px] rounded border">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="grid grid-cols-3 items-center justify-start px-4 py-2"
          on:click={() =>
            (activeChildRuleId = activeChildRuleId === rule.id ? -1 : rule.id)}
        >
          <div class="col-span-1 flex items-center gap-4 text-start">
            <span class="break-words">{rule.name}</span>
          </div>
          <div class="col-span-2 flex flex-nowrap justify-between gap-4">
            {#if assetRulesTable}
              <div class="flex flex-wrap items-center gap-2">
                <Badge bgColor="#929292" textColor="white">
                  {ruleSearchTypeToLabel(rule.rule_type)}
                </Badge>
                {#if rule.rule_type === RuleSearchType.MANUAL}
                  {#if rule.affirmative_rule}
                    <Badge bgColor="#929292" textColor="white">
                      {RuleStatus.PASSED} if Marked Complete
                    </Badge>
                  {:else}
                    <Badge bgColor="#929292" textColor="white">
                      {RuleStatus.RISK} if Not Marked Complete
                    </Badge>
                  {/if}
                {:else if rule.affirmative_rule}
                  <Badge bgColor="#929292" textColor="white">
                    Passes if Matched
                  </Badge>
                {:else}
                  <Badge bgColor="#929292" textColor="white">
                    Failed if Matched
                  </Badge>
                {/if}
                {#if rule.is_conditional}
                  <Badge bgColor="#929292" textColor="white">Conditional</Badge>
                {/if}
              </div>
            {:else}
              <div
                class="tooltip max-w-fit"
                data-tip={rule.data_type}
                class:invisible={activeChildRuleId === rule.id}
              >
                <p
                  class="col-span-1 overflow-hidden text-ellipsis whitespace-nowrap text-start text-sm"
                >
                  {tidyPoolLabel(rule.data_type)}
                </p>
              </div>
            {/if}
            <div class="ml-auto flex items-center gap-1">
              {#if showEdit}
                <button
                  class="btn btn-primary btn-outline btn-xs"
                  on:click|stopPropagation={() => dispatch("editRule", rule.id)}
                >
                  Edit
                </button>
              {/if}

              <button class="btn btn-square btn-ghost btn-sm">
                <Icon
                  icon="iconoir:nav-arrow-{activeChildRuleId === rule.id
                    ? 'up'
                    : 'down'}"
                  class="text-lg"
                />
              </button>
            </div>
          </div>
        </div>
        {#if activeChildRuleId === rule.id}
          <main
            class={`grid grid-cols-3 gap-4 px-4 py-2 pt-0`}
            transition:slide
          >
            {#if assetRulesTable}
              {#if rule.rule_type === RuleSearchType.MANUAL}
                <div>
                  <p class="text-sm font-medium">ID</p>
                  <p class="text-sm font-light">
                    #{rule.id}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-medium">Description</p>
                  <p class="text-sm font-light">
                    {rule.description}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-medium">Remediation Step</p>
                  <p class="text-sm font-light">
                    {isEmpty(rule.remediation_step)
                      ? "No remediation step."
                      : rule.remediation_step}
                  </p>
                </div>
                <div class="col-span-3 grid grid-cols-6 gap-4">
                  <div class="col-span-3 mr-[36px] flex flex-col gap-1">
                    <p class="mt-1 text-sm font-medium">Query</p>
                    <QueryPrettyPrint
                      {rule}
                      isParentConditional={isParentConditionalRule(rule)}
                    />
                  </div>
                  <div class="col-span-2 col-start-5 flex flex-col gap-1">
                    <p class="mt-1 text-sm font-medium">Sub-Tasks</p>
                    <ul class="rounded-box list-disc">
                      {#each rule.examples.split(" AND ") as example}
                        <li class="ml-6">
                          <span class="badge h-auto"
                            >{example.slice(1, -1)}</span
                          >
                        </li>
                      {/each}
                    </ul>
                  </div>
                </div>
              {:else if rule.rule_type === RuleSearchType.CONTEXTUAL}
                <ContextualRuleRow bind:rule isSavedRulesTable={true} />
              {:else}
                <div>
                  <p class="text-sm font-medium">ID</p>
                  <p class="text-sm font-light">
                    #{rule.id}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-medium">Description</p>
                  <p class="text-sm font-light">
                    {rule.description}
                  </p>
                </div>
                <div>
                  <p class="text-sm font-medium">Remediation Step</p>
                  <p class="text-sm font-light">
                    {isEmpty(rule.remediation_step)
                      ? "No remediation step."
                      : rule.remediation_step}
                  </p>
                </div>

                <div class="col-span-3 grid grid-cols-2">
                  <div class="col-span-1 mr-[36px] flex flex-col gap-1">
                    <p class="mt-1 text-sm font-medium">Query</p>
                    <QueryPrettyPrint
                      {rule}
                      isParentConditional={isParentConditionalRule(rule)}
                    />
                  </div>
                </div>
              {/if}
            {:else}
              <div>
                <p class="text-sm font-medium">ID</p>
                <p class="text-sm font-light">
                  #{rule.id}
                </p>
              </div>
              <div>
                <p class="text-sm font-medium">Description</p>
                <p class="text-sm font-light">
                  {rule.description}
                </p>
              </div>
              <div class="col-span-1">
                <p class="text-sm font-medium">Channels</p>
                <ul class="ml-4 list-disc text-sm">
                  {#each rule.data_type.split(", ") as d}
                    <li>{d}</li>
                  {/each}
                </ul>
              </div>
              <div class="col-span-3">
                <p class="text-sm font-medium">Remediation Step</p>
                <p class="text-sm font-light">
                  {isEmpty(rule.remediation_step)
                    ? "No remediation step."
                    : rule.remediation_step}
                </p>
              </div>
              <div class="col-span-3 grid grid-cols-2">
                <div class="col-span-1 mr-[36px] flex flex-col gap-1">
                  <p class="mt-1 text-sm font-medium">Query</p>
                  <CodeMirror
                    bind:value={rule.boolean}
                    editable={false}
                    readonly={true}
                    lang={javascript()}
                    styles={{
                      "&": { background: "white", fontSize: "14px" },
                      ".cm-gutters": { display: "none" },
                    }}
                    lineWrapping={true}
                  />
                </div>
              </div>
            {/if}
          </main>
        {/if}
      </div>
    {/each}
  </div>
</tr>
