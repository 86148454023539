<script>
  import { fetchPost, fetchGet } from "../../helpers";
  import { email_list } from "../../stores";
  import Icon from "@iconify/svelte";
  import Modal from "../Modals/Modal.svelte";
  import { toast } from "svelte-sonner";

  let bottomButtons = {
    show: true,
    primaryText: "Confirm",
    secondaryText: "Cancel",
    loading: false,
    primaryAction: createEmail,
    secondaryAction: closeModal,
  };

  let emails = [];
  let new_title = "";
  let new_text = "";
  let new_clarification_procedure = "";
  let details_table = true;
  let errorMessage = "";
  let inviteDisabled = false;

  function openModal() {
    emails = [];
    new_title = "";
    new_text = "";
    new_clarification_procedure = "";
    details_table = true;

    errorMessage = "";
    inviteDisabled = false;
    document.querySelector("#emailTemplateModal")?.showModal();
  }

  function closeModal() {
    document.querySelector("#emailTemplateModal")?.close();
  }

  function validateForm() {
    if (!new_title.trim()) {
      errorMessage = "Email title is required.";
      return false;
    }
    if (!new_text.trim()) {
      errorMessage = "Email description is required.";
      return false;
    }

    return true;
  }

  async function createEmail() {
    if (!validateForm()) {
      return;
    }

    bottomButtons.loading = true;
    const response = await fetchPost("/email/build/1", {
      title: new_title,
      text: new_text,
      clarification_procedure: new_clarification_procedure,
      details_table: details_table,
    });

    if (!response.success) {
      toast.error("Failed to add item.");
      return false;
    }

    emails = emails.concat([response.email]);

    const res = await fetchGet("/email");
    email_list.set(res);
    bottomButtons.loading = false;
    closeModal();
    toast.error("Item added successfully.");
    return true;
  }
</script>

<button class="btn btn-primary btn-sm text-nowrap" on:click={openModal}>
  Add Email Template
</button>

<Modal
  modalId="emailTemplateModal"
  size="sm"
  cornerBackButton={true}
  cornerCloseButton={false}
  {bottomButtons}
>
  <div class="space-y-2">
    <div
      class="bg-primary-content m-auto flex aspect-square w-16 items-center justify-center rounded-full"
    >
      <Icon icon="iconoir:mail" class="text-primary text-3xl" />
    </div>
    <h3 class="text-center font-bold">Add Email Template</h3>
  </div>

  <div class="mt-6 space-y-2">
    <p class="text-sm">Email Title</p>
    <input
      class="input {inviteDisabled ? 'input-error' : 'input-bordered'} w-full"
      placeholder="Enter email title"
      bind:value={new_title}
      type="text"
    />

    <p class="text-sm">Email Description</p>
    <input
      class="input input-bordered w-full"
      placeholder="Enter email description"
      bind:value={new_text}
      type="text"
    />

    <p class="text-sm">Clarification Procedure</p>
    <textarea
      class="textarea textarea-bordered w-full"
      placeholder="Enter clarification procedure (optional)"
      bind:value={new_clarification_procedure}
    />

    <span class="flex justify-between">
      <label for="details-toggle" class="text-sm">Include Details Table</label>
      <input
        id="details-toggle"
        class="toggle toggle-primary"
        type="checkbox"
        bind:checked={details_table}
      />
    </span>
  </div>

  {#if errorMessage}
    <span class="text-xs text-red-500">*{errorMessage}</span>
  {/if}
</Modal>
