<!--
  @component
  @deprecated
  @todo Need to refactor the MyHits and OrganizationHits to use the _new_ pagination strategy.
-->
<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { slide } from "svelte/transition";
  import { fetchPatch } from "../../../helpers";
  import { org_users } from "../../../stores";
  import Modal from "../../Modals/Modal.svelte";

  const dispatch = createEventDispatcher();

  export let itemsPerPage = 10;
  export let currentPage = 1;
  export let data = [];
  export let originalData = [];
  export let openedTab = "Open";

  let enabledUsers = {};

  let usersToAssign = {};
  let unassignAllBeforeFlag = false;

  function getUserIdFromFullName(fullname) {
    const firstName = fullname.split(" ")[0];
    const lastName = fullname.split(" ")[1];

    const user = $org_users.find(
      (user) => user.first_name === firstName && user.last_name === lastName,
    );

    return user.id;
  }

  function unassignAllUsersFromSelectedHit() {
    data = data.map((entry) => {
      if (entry["selected"]) {
        entry["hit"]["assigned_users"] = [];
      }

      return entry;
    });
  }

  function setSelectedAttributeToValue(attribute, value, toRefresh = true) {
    let newData = [];

    for (let i = 0; i < originalData.length; i++) {
      const entry = originalData[i];

      if (entry["selected"]) {
        if (attribute === "assign") {
          if (
            entry["hit"]["assigned_users"].some(
              (user) => user.assigned_id === getUserIdFromFullName(value),
            )
          ) {
            newData = [...newData, entry];
            continue;
          }

          entry["hit"]["assigned_users"].push({
            assigned_id: getUserIdFromFullName(value),
          });

          newData = [...newData, entry];
          continue;
        } else if (attribute === "unassign") {
          if (
            !entry["hit"]["assigned_users"].some(
              (user) => user.assigned_id === getUserIdFromFullName(value),
            )
          ) {
            newData = [...newData, entry];
            continue;
          }

          entry["hit"]["assigned_users"] = entry["hit"][
            "assigned_users"
          ].filter((user) => user.assigned_id !== getUserIdFromFullName(value));

          newData = [...newData, entry];
          continue;
        }

        entry["hit"][attribute] = value;
      }

      entry["selected"] = false;
      newData = [...newData, entry];
    }

    data = [...newData];

    if (toRefresh) {
      dispatch("refresh", data);
    }
  }

  let numberOfSelectedHits = 0;
  let currentPageData = [];
  let currentSelections = [];
  let allResultsOnPageSelected = false;
  const queryParams = new URLSearchParams({ useDeprecated: true }).toString();

  function arraysContainSameValues(arr1, arr2) {
    return arr2.every((v) => arr1.includes(v));
  }

  $: if (data) {
    currentPageData = [];
    currentSelections = [];

    const currentSelectionsObjs = data.filter((entry) => {
      return entry["selected"];
    });

    for (let i = 0; i < currentSelectionsObjs.length; i++) {
      currentSelections.push(currentSelectionsObjs[i].hit.id);
    }

    const currentPageDataObjs = data.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );

    for (let i = 0; i < currentPageDataObjs.length; i++) {
      currentPageData.push(currentPageDataObjs[i].hit.id);
    }

    numberOfSelectedHits = currentSelections.length;

    allResultsOnPageSelected = arraysContainSameValues(
      currentSelections,
      currentPageData,
    );
  }

  onMount(() => {
    $org_users.forEach((user) => {
      if (!user.is_deleted) {
        enabledUsers[user.first_name + " " + user.last_name] = true;
      }
    });
  });
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div
  class="flex shrink-0 flex-wrap items-center gap-2 rounded"
  transition:slide
>
  Bulk Actions:

  {#if openedTab == "Open" || openedTab == "Closed"}
    <button
      class="btn btn-primary btn-sm"
      on:click={async () => {
        await fetchPatch(`/hit/bulk?${queryParams}`, {
          hit_ids: currentSelections,
          action: "flag",
        });
        setSelectedAttributeToValue("in_report", "Flagged");
        dispatch("refresh", data);
      }}
    >
      <Icon icon="iconoir:dash-flag" class="text-lg" /> Flag
    </button>
  {/if}

  {#if openedTab == "Open" || openedTab == "Closed"}
    <button
      class="btn btn-primary btn-sm"
      on:click={() => {
        document.getElementById("flag_and_assign_modal").showModal();
      }}
    >
      <Icon icon="iconoir:group" /> Flag & Assign
    </button>
  {/if}

  {#if openedTab == "Open" || openedTab == "Flagged"}
    <button
      class="btn btn-sm {openedTab == 'Flagged' ? 'btn-primary' : ''}"
      on:click={async () => {
        await fetchPatch(`/hit/bulk?${queryParams}`, {
          hit_ids: currentSelections,
          action: "close",
        });
        setSelectedAttributeToValue("in_report", "Closed");
      }}
    >
      Close
    </button>
  {/if}

  {#if openedTab == "Flagged"}
    <button
      class="btn btn-primary btn-sm"
      on:click={() => {
        document.getElementById("assign_modal").showModal();
      }}
    >
      <Icon icon="iconoir:group" /> Assign
    </button>
  {/if}

  {#if openedTab == "Flagged" || openedTab == "Closed" || openedTab == "Removed"}
    <button
      class="btn btn-sm"
      on:click={async () => {
        await fetchPatch(`/hit/bulk?${queryParams}`, {
          hit_ids: currentSelections,
          action: "open",
        });
        setSelectedAttributeToValue("in_report", "Open");
      }}
    >
      Re-Open
    </button>
  {/if}

  <span class="ml-auto">Selected ({numberOfSelectedHits})</span>

  <button
    class="btn btn-primary btn-sm"
    disabled={allResultsOnPageSelected}
    on:click={() => dispatch("selectAllOnPage")}
  >
    Select All (page)
  </button>
  <button
    class="btn btn-primary btn-sm"
    disabled={numberOfSelectedHits == data.length}
    on:click={() => dispatch("selectAllFiltered")}
  >
    Select All (filtered)
  </button>

  <button
    class="btn btn-sm"
    disabled={numberOfSelectedHits == 0}
    on:click={() => dispatch("deselectAll")}
  >
    Deselect All
  </button>
</div>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-label-has-associated-control -->

<Modal modalId="flag_and_assign_modal" cornerCloseButton={false}>
  <div
    class="my-6 flex flex-wrap items-center gap-2 rounded-lg bg-gray-200 p-2"
  >
    {#each Object.keys(usersToAssign) as userToAssign}
      {#if usersToAssign[userToAssign]}
        <button
          class="btn-outline-secondary btn btn-sm"
          on:click={() => (usersToAssign[userToAssign] = false)}
        >
          <Icon icon="ic:baseline-close" />
          {userToAssign}
        </button>
      {/if}
    {/each}

    <div class="dropdown">
      <label tabindex="0" class="btn btn-primary btn-sm m-1">
        <Icon icon="ic:baseline-group-add" class="text-lg" /> Add User
      </label>
      <ul
        tabindex="0"
        class="menu dropdown-content rounded-box bg-base-100 z-20 block max-h-96 w-52 overflow-y-auto p-2 shadow"
      >
        {#each Object.keys(enabledUsers) as user}
          {#if !usersToAssign[user]}
            <li>
              <button on:click={() => (usersToAssign[user] = true)}>
                {user}
              </button>
            </li>
          {/if}
        {/each}
      </ul>
    </div>
  </div>

  <div class="mt-16 flex items-center justify-between">
    <div class="form-control">
      <label class="label cursor-pointer">
        <input
          type="checkbox"
          bind:checked={unassignAllBeforeFlag}
          class="checkbox-primary checkbox"
        />
        <span class="label-text ml-2">Clear current assignees</span>
      </label>
    </div>

    <form method="dialog" class="modal-backdrop z-10">
      <div class="flex justify-end gap-2">
        <button class="btn-outline-primary btn btn-sm">Cancel</button>

        <button
          class="btn btn-primary btn-sm"
          on:click={async () => {
            if (unassignAllBeforeFlag) unassignAllUsersFromSelectedHit();

            Object.keys(usersToAssign).forEach((user) => {
              if (usersToAssign[user])
                setSelectedAttributeToValue("assign", user, false);
            });

            const usersToAssignIds = Object.keys(usersToAssign).map((user) => {
              if (usersToAssign[user]) return getUserIdFromFullName(user);
            });

            await fetchPatch(`/hit/bulk?${queryParams}`, {
              hit_ids: currentSelections,
              user_ids: usersToAssignIds,
              reset_assignment: unassignAllBeforeFlag,
              action: "flag_and_assign",
            });
            setSelectedAttributeToValue("in_report", "Flagged");
          }}
        >
          <Icon icon="iconoir:dash-flag" class="text-lg" /> Flag & Assign
        </button>
      </div>
    </form>
  </div>
</Modal>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<!-- svelte-ignore a11y-label-has-associated-control -->

<Modal modalId="assign_modal" cornerCloseButton={false}>
  <div class="flex flex-wrap items-center gap-2 rounded-lg bg-gray-200 p-2">
    {#each Object.keys(usersToAssign) as userToAssign}
      {#if usersToAssign[userToAssign]}
        <button
          class="btn-outline-secondary btn btn-sm"
          on:click={() => (usersToAssign[userToAssign] = false)}
        >
          <Icon icon="ic:baseline-close" />
          {userToAssign}
        </button>
      {/if}
    {/each}

    <div class="dropdown">
      <label tabindex="0" class="btn btn-primary btn-sm m-1">
        <Icon icon="ic:baseline-group-add" class="text-lg" /> Add User
      </label>
      <ul
        tabindex="0"
        class="menu dropdown-content rounded-box bg-base-100 z-20 block max-h-96 w-52 overflow-y-auto p-2 shadow"
      >
        {#each Object.keys(enabledUsers) as user}
          {#if !usersToAssign[user]}
            <li>
              <button on:click={() => (usersToAssign[user] = true)}>
                {user}
              </button>
            </li>
          {/if}
        {/each}
      </ul>
    </div>
  </div>

  <div class="mt-16 flex items-center justify-between">
    <div class="form-control">
      <label class="label cursor-pointer">
        <input
          type="checkbox"
          bind:checked={unassignAllBeforeFlag}
          class="checkbox-primary checkbox"
        />
        <span class="label-text ml-2">Clear current assignees</span>
      </label>
    </div>

    <form method="dialog" class="modal-backdrop z-10">
      <div class="flex justify-end gap-2">
        <button class="btn-outline-primary btn btn-sm">Cancel</button>

        <button
          class="btn btn-primary btn-sm"
          on:click={async () => {
            if (unassignAllBeforeFlag) unassignAllUsersFromSelectedHit();

            const usersToAssignIds = Object.keys(usersToAssign).map((user) => {
              if (usersToAssign[user]) return getUserIdFromFullName(user);
            });

            await fetchPatch(`/hit/bulk?${queryParams}`, {
              hit_ids: currentSelections,
              user_ids: usersToAssignIds,
              reset_assignment: unassignAllBeforeFlag,
              action: "assign",
            });

            Object.keys(usersToAssign).forEach((user) => {
              if (usersToAssign[user])
                setSelectedAttributeToValue("assign", user, false);
            });
          }}
        >
          <Icon icon="iconoir:dash-flag" class="text-lg" /> Assign
        </button>
      </div>
    </form>
  </div>
</Modal>
