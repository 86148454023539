<script>
  import { javascript } from "@codemirror/lang-javascript";
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onDestroy, onMount } from "svelte";
  import CodeMirror from "svelte-codemirror-editor";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import RuleBuilder from "../components/RuleBuilder/RuleBuilder.svelte";
  import { dTypesToMultiSelect, fetchPost } from "../helpers";
  import { RuleDataType } from "../lib/interfaces/Rule.interface";
  import {
    getDataType,
    getUsableMediaLink,
  } from "../lib/utils/GeneralDataUtils";
  import { defaultTimestampDialogData } from "../lib/utils/TimestampUtils";
  import {
    convertBoolToBuilder,
    convertBuilderToBoolean,
    removeDeleted,
  } from "../ruleBuilderConverter";
  import { disabledOrganisationModules } from "../stores";
  import DropdownMultiSelect from "./DropdownMultiSelect.svelte";
  import LogoLoader from "./LogoLoader.svelte";
  import Modal from "./Modals/Modal.svelte";
  import TimestampViewModal from "./Modals/TimestampViewModal.svelte";
  import Select from "./Select.svelte";
  import TableHit from "./TableViews/TableHit.svelte";
  import TableSource from "./TableViews/TableSource.svelte";
  import TableType from "./TableViews/TableType.svelte";

  export let runRule;
  export let deleteRule;
  export let index;
  export let data;
  export let d_types;
  export let loaded = false;
  export let editing = false;
  export let defaultRulePriority = "Low";
  export let isViewing = false;
  export let isMyRulesPage = false;

  const dispatch = createEventDispatcher();

  let selectSearchTerm = "";
  let items = dTypesToMultiSelect(d_types);
  let timestampModal = null;
  let dialogData = { ...defaultTimestampDialogData };

  // to delete the component
  // https://stackoverflow.com/questions/62126621/how-to-call-destroy-on-a-component-from-inside-the-component

  let advanced = false;
  let loading = false;
  let resultCount = 0;

  let previewResults;

  const converter = () => {
    const dt =
      data.visual_type === RuleDataType.BUILDER
        ? RuleDataType.BOOLEAN
        : RuleDataType.BUILDER;

    if (dt === RuleDataType.BOOLEAN && data.builder) {
      try {
        data.boolean = convertBuilderToBoolean(data.builder);
        data.visual_type = dt;
      } catch (error) {
        toast.warning("Error while converting builder to boolean");
      }
    } else if (dt === RuleDataType.BUILDER && data.boolean) {
      try {
        data.builder = convertBoolToBuilder(data.boolean);
        data.visual_type = dt;
      } catch (error) {
        toast.warning("Error while converting boolean to builder");
      }
    }
  };

  onMount(() => {
    data.visual_type = RuleDataType.BOOLEAN;
    converter();
  });

  async function getRulePreviewHits() {
    previewResults = undefined;

    let previewModal = document.getElementById("preview_modal_" + randID);
    previewModal.showModal();

    try {
      const previewData = await fetchPost("/rule/preview", {
        data_types: data.data_types,
        boolean:
          data.visual_type === RuleDataType.BUILDER
            ? convertBuilderToBoolean(data.builder)
            : data.boolean,
      });

      if (!previewData.success)
        console.warn("Something went wrong while refreshing rule", index);

      resultCount = previewData.count;
      previewResults = previewData.preview;

      if (previewData?.failed_indices?.length !== 0) {
        toast.error(
          "Failed to search the following indices: [" +
            response.failed_indices.join(", ") +
            "].",
        );
      }
    } catch (error) {
      toast.error("Something went wrong while refreshing rule");
      console.warn("Error while running the rule", error);
    }

    return [];
  }

  // checks all clauses for empty values
  const checkFields = (obj) => {
    if (obj.type === "clause") {
      if (obj.value.trim() === "") {
        toast.warning("Clause value can not be empty.");
        return false;
      }
    } else if (obj.type === "relation") {
      for (let i = 0; i < obj.nested.length; i++) {
        if (!checkFields(obj.nested[i])) return false;
      }
    }
    return true;
  };

  async function runAndValidateRule() {
    if (loading) {
      toast.warning("Rule is already running.");
      return;
    } else if (!data.name) {
      toast.warning("Rule title is required.");
      return;
    } else if (!data.description) {
      toast.warning("Rule description is required.");
      return;
    } else if ($disabledOrganisationModules.includes("image_text")) {
      toast.warning(
        "Image text is deprecated. Please remove it from the channels to search.",
      );
      return;
    }

    data.builder = [removeDeleted(data.builder[0])];
    if (!checkFields(data.builder[0])) return;

    loading = true;
    dispatch("saveRule", data);
    await runRule(index, defaultRulePriority);
    loading = false;
  }

  function handleTimestampData(timestamp) {
    dialogData = {
      title: timestamp.title,
      timestamp: timestamp.media_timestamp,
      url: getUsableMediaLink(
        timestamp.index,
        timestamp.data_type,
        timestamp.url,
        timestamp.content_link,
      ),
      caption: timestamp.highlight,
    };
    timestampModal.showModal();
  }

  $: if (loading) document.body.style.overflow = "hidden";
  else document.body.style.overflow = "auto";

  onDestroy(() => (document.body.style.overflow = "auto"));

  const randID = Math.floor(Math.random() * 1000000000);
</script>

{#if loading}
  <div
    class="bg-base-100/50 fixed inset-0 z-20 flex items-center justify-center backdrop-blur-sm"
  >
    <h1 class="flex items-center gap-4 text-4xl font-semibold">
      <LogoLoader size="2.5rem" />
      {isMyRulesPage ? "Saving Rule" : "Running Rule"}
    </h1>
  </div>
{/if}

<!-- no transition:slide as it causes errors with select menu positioning -->
<article class="my-4 rounded border p-6">
  <div class="flex flex-wrap gap-4">
    <div class="form-control grow">
      <label class="label" for="rule_name_{index}">
        <span class="label-text">Rule name</span>
      </label>
      <input
        class="input input-bordered w-full"
        id="rule_name_{index}"
        placeholder="Add a rule name"
        bind:value={data.name}
        required
        maxlength="63"
      />
    </div>

    <div class="form-control grow-[3]">
      <label class="label" for="rule_description_{index}">
        <span class="label-text">Rule Description</span>
      </label>
      <textarea
        class="textarea textarea-bordered w-full content-center ![field-sizing:fixed]"
        id="rule_description_{index}"
        placeholder="Add a rule description, providing more detail about the particular issues this rule is meant to identify"
        bind:value={data.description}
        rows="1"
        required
      />
    </div>
  </div>

  <div class="form-control mt-2 grow">
    <label class="label" for={"rule_type_" + index}>
      <span class="label-text">Channels to search</span>
    </label>

    <DropdownMultiSelect
      {items}
      showSearchBar={true}
      badgesInSearchboxDesign={true}
      bind:searchTerm={selectSearchTerm}
      bind:selectedItems={data.data_types}
      useTidyPoolLabels={true}
    />
  </div>

  <div class="divider -mx-6" />

  {#if !loaded}
    {#if data.visual_type == RuleDataType.BOOLEAN}
      <CodeMirror
        bind:value={data.boolean}
        editable={!isViewing}
        lang={javascript()}
        styles={{
          "&": { fontSize: "14px" },
          ".cm-gutters": { display: "none" },
        }}
        lineWrapping={true}
      />
    {:else}
      <RuleBuilder
        bind:query={data.builder}
        {isViewing}
        selectedChannels={data.data_types}
        searchModule="live"
      />
    {/if}
  {:else}
    <div class="grid">
      <CodeMirror
        bind:value={data.boolean}
        editable={false}
        readonly={true}
        lang={javascript()}
        styles={{
          "&": { background: "white", fontSize: "14px" },
          ".cm-gutters": { display: "none" },
        }}
        lineWrapping={true}
      />
    </div>
  {/if}
  <div class="mt-4 flex w-full flex-wrap justify-between gap-2">
    <div class="flex gap-2">
      {#if isViewing}
        <button
          class="btn btn-outline btn-error btn-sm"
          on:click={() => {
            isViewing = !isViewing;
          }}
        >
          Exit
        </button>
      {:else}
        <button class="btn btn-outline btn-error btn-sm" on:click={deleteRule}>
          <Icon icon="iconoir:trash" class="text-lg" />
          {editing ? "Cancel" : "Remove Rule"}
        </button>
      {/if}

      {#if !data.name || !data.description || data.data_types.length < 1}
        <div
          class="tooltip"
          data-tip="Make sure the rule has a name, desciption & at least one channel selected."
        >
          <button class="btn btn-primary btn-sm" disabled>
            Preview Results
          </button>
        </div>
        <div
          class="tooltip"
          data-tip="Make sure the rule has a name, desciption & at least one channel selected."
        >
          {#if !isViewing}
            <button class="btn btn-primary btn-sm" disabled>
              <Icon icon="iconoir:save-floppy-disk" class="text-lg" />
              {isViewing ? "Save to My Rules" : "Save Rule"}
            </button>
          {/if}
        </div>
      {:else if $disabledOrganisationModules.includes("image_text")}
        <div
          class="tooltip"
          data-tip="Use of the website image text channel is deprecated. Please remove this channel to proceed or contact support@haast.io for further information."
        >
          <button class="btn btn-primary btn-sm" disabled>
            Preview Results
          </button>
        </div>
        <div
          class="tooltip"
          data-tip="Use of the website image text channel is deprecated. Please remove this channel to proceed or contact support@haast.io for further information."
        >
          {#if !isViewing}
            <button class="btn btn-primary btn-sm" disabled>
              <Icon icon="iconoir:save-floppy-disk" class="text-lg" />
              {isViewing ? "Save to My Rules" : "Save Rule"}
            </button>
          {/if}
        </div>
      {:else}
        <button class="btn btn-primary btn-sm" on:click={getRulePreviewHits}>
          Preview Results
        </button>
        {#if !isViewing}
          <button class="btn btn-primary btn-sm" on:click={runAndValidateRule}>
            <Icon icon="iconoir:save-floppy-disk" class="text-lg" />
            {isViewing ? "Save to My Rules" : "Save Rule"}
          </button>
        {/if}
      {/if}
    </div>
    <button class="btn btn-sm" on:click={() => (advanced = !advanced)}>
      <Icon icon="iconoir:more-vert" />
      {advanced ? "Hide" : "Show"} Advanced Options
    </button>
  </div>
  {#if advanced}
    <div class="mt-4 flex flex-col gap-4" transition:slide>
      <div class="form-control mb-2 grow-[3]">
        <label class="label" for="rule_remediation_step_{index}">
          <span class="label-text">Remediation Step</span>
        </label>
        <textarea
          class="textarea textarea-bordered w-full"
          id="rule_remediation_step_{index}"
          placeholder="Add a remediation step, providing more detail on how to fix a flagged issue"
          bind:value={data.remediation_step}
          rows="1"
          required
        />
      </div>

      <div class="flex gap-4">
        {#if !loaded}
          <button class="btn btn-sm" on:click={converter}>
            <Icon icon="iconoir:coins-swap" />
            Switch to {data.visual_type === RuleDataType.BUILDER
              ? "Classic"
              : "Visual"} Rule Builder
          </button>
        {/if}

        <div>
          <label for="priority">Default Priority:</label>
          <Select
            bind:selectedValue={defaultRulePriority}
            disabled={isViewing}
            items={[
              { label: "Low", value: "Low" },
              { label: "Medium", value: "Med" },
              { label: "High", value: "High" },
            ]}
          />
        </div>
      </div>
    </div>
  {/if}
</article>

<Modal modalId="preview_modal_{randID}" size="xl">
  <TimestampViewModal
    bind:this={timestampModal}
    title={dialogData.title}
    caption={dialogData.caption}
    url={dialogData.url}
    timestamp={dialogData.timestamp}
  />
  {#if previewResults && previewResults.length > 0}
    <p>
      Showing previews for {previewResults.length} of
      {resultCount > 500 ? "500+" : resultCount} results
    </p>
    <table class="table-zebra table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Location</th>
          <th>Hit</th>
          <!-- <th>Rules</th> -->
        </tr>
      </thead>
      <tbody>
        {#each previewResults as e}
          <tr>
            <td
              class="border-l-4"
              class:!border-l-warning={e.data_type === "DOCUMENT"}
              style="border-left-color: {e.index
                .toLowerCase()
                .includes('instagram')
                ? '#FF0069'
                : e.index.toLowerCase().includes('tiktok')
                  ? '#00f2ea'
                  : e.index.toLowerCase().includes('facebook')
                    ? '#4267B2'
                    : e.index.toLowerCase().includes('twitter')
                      ? '#1DA1F2'
                      : e.index.toLowerCase().includes('linkedin')
                        ? '#0077b5'
                        : e.index.toLowerCase().includes('youtube')
                          ? '#FF0000'
                          : '#2b3440'}"
            >
              <div class="flex items-center gap-2">
                <TableType
                  type={e.index}
                  data_type={getDataType(e)}
                  classes="text-3xl"
                />
                <div>
                  <h3 class="truncate font-semibold uppercase">
                    {getDataType(e)}
                  </h3>
                  <span class="whitespace-nowrap">
                    {e.vendor ? `- ${e.vendor}` : ""}
                  </span>
                </div>
              </div>
            </td>
            <td class="max-w-[200px]">
              <TableSource
                hitId={e.id}
                hitUrl={e.url}
                hitTitle={e.title}
                hitContentLink={e.content_link}
                hitPool={e.index}
                hitDataType={e.data_type}
              />
            </td>

            <td class="max-w-[max(400px,50vw)]">
              <TableHit
                hit={e.highlight}
                hitUrl={e.url}
                hitContentLink={e.content_link}
                hitField={e.field}
                hitPool={e.index}
                hitDataType={e.data_type}
                hitTimestamp={e.data_type.toLowerCase() == "timestamp"
                  ? e.media_timestamp
                  : null}
                on:click={() => handleTimestampData(e)}
              />
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  {:else if previewResults && previewResults.length === 0}
    <p>No results found.</p>
  {:else}
    <p>Loading results ...</p>
    <table class="table-zebra table">
      <thead>
        <tr>
          <th>Type</th>
          <th>Location</th>
          <th>Hit</th>
        </tr>
      </thead>
      <tbody>
        {#each { length: 10 } as _}
          <tr>
            <td class="max-w-max">
              <div class="flex flex-col gap-2">
                <div class="skeleton h-4 w-28" />
                <div class="skeleton h-4 w-24" />
              </div>
            </td>
            <td class="max-w-max">
              <div class="flex flex-col gap-2">
                <div class="skeleton h-4 w-28" />
                <div class="skeleton h-4 w-24" />
              </div>
            </td>
            <td class="w-full">
              <div class="flex flex-col gap-2">
                <div class="skeleton h-4 w-full max-w-[max(400px,50vw)]" />
                <div class="skeleton h-4 w-full max-w-[max(400px,50vw)]" />
                <div class="skeleton h-4 w-full max-w-[max(400px,50vw)]" />
              </div>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  {/if}
</Modal>
