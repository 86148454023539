<script>
  import { tick } from "svelte";
  import { maximised } from "../stores";
  import { defaultTimestampDialogData } from "../lib/utils/TimestampUtils";
  import {
    getDataType,
    getUsableMediaLink,
  } from "../lib/utils/GeneralDataUtils";
  import LogoLoader from "./LogoLoader.svelte";
  import IssueTableFilterSidebar from "./TableViews/IssueTable/IssueTableFilterSidebar.svelte";
  import IssueTableHit from "./TableViews/IssueTable/IssueTableHit.svelte";
  import IssueTableSource from "./TableViews/IssueTable/IssueTableSource.svelte";
  import TableLoading from "./TableViews/TableLoading.svelte";
  import Pagination from "./table/Pagination.svelte";
  import TimestampViewModal from "./Modals/TimestampViewModal.svelte";
  import TableType from "./TableViews/TableType.svelte";

  export let data = [];
  export let moreHits = false;

  let oldDataLength = 0;
  let currentPage = 1;
  let itemsPerPage = 10;
  let filteredData = [];
  let selectedRulesId = [];
  let selectedDataTypes = [];
  let selectedVendors = [];
  let selectedSocialTypes = [];
  let shouldSearch = true;
  let timestampModal = null;

  let timestampDialogData = { ...defaultTimestampDialogData };

  function handleTimestampData(timestamp) {
    timestampDialogData = {
      title: timestamp.title,
      timestamp: timestamp.media_timestamp,
      url: getUsableMediaLink(
        timestamp.index,
        timestamp.data_type,
        timestamp.url,
        timestamp.content_link,
      ),
      caption: timestamp.highlight,
    };
    timestampModal.showModal();
  }

  const setSelectedTypes = async () => {
    await tick();
    selectedDataTypes = [...new Set(data.map((e) => e.data_type))];
    selectedVendors = [...new Set(data.map((e) => e.vendor))];
    selectedSocialTypes = [...new Set(data.map((e) => e.index.split("_")[1]))];
  };

  $: if (moreHits) setSelectedTypes();
  $: if (data.length !== oldDataLength) {
    oldDataLength = data.length;
    setSelectedTypes();
  }
  $: if (shouldSearch) {
    filteredData = data
      .filter((hit) => selectedDataTypes.includes(hit.data_type))
      .filter((hit) => selectedVendors.includes(hit.vendor))
      .filter((hit) => selectedSocialTypes.includes(hit.index.split("_")[1]));

    shouldSearch = false;
  }
  $: if (data.length > 0) $maximised = false;
</script>

<TimestampViewModal
  bind:this={timestampModal}
  title={timestampDialogData.title}
  caption={timestampDialogData.caption}
  url={timestampDialogData.url}
  timestamp={timestampDialogData.timestamp}
/>

<div class="mx-auto -mb-12 max-w-[1706px]">
  <div class="mb-1">
    {#if data.length === 0}
      <div class="flex items-center justify-end gap-1">
        <LogoLoader />
        <p class="text-base-content/70 text-end text-xs">Searching...</p>
      </div>
    {:else if data.length > 0 < 10000 && moreHits}
      <div class="flex items-center justify-end gap-1">
        <LogoLoader />
        <p class="text-base-content/70 text-end text-xs">
          Searching... | Showing {data.length} results
        </p>
      </div>
    {:else if data.length < 10000 && !moreHits}
      <p class="text-base-content/70 text-end text-xs">
        {data.length} results
      </p>
    {:else if data.length >= 10000}
      <div class="flex justify-end">
        <div
          class="tooltip tooltip-left"
          data-tip="The results for this search exceed 10,000. Please create a report to see the additional results."
        >
          <p class="text-base-content/70 text-end text-xs">10,000+ results</p>
        </div>
      </div>
    {:else}
      {data.length} results
    {/if}
  </div>

  {#if data.length}
    <div class="flex">
      <IssueTableFilterSidebar
        originalData={data.map((e) => ({ hit: e }))}
        report_id="search"
        exportRes={false}
        tableRootPage="search"
        bind:selectedRulesId
        bind:selectedDataTypes
        bind:selectedVendors
        bind:selectedSocialTypes
        allRules={[]}
        on:selectChanged={() => (shouldSearch = true)}
      />

      <div class="ml-2 w-full">
        <div
          class="max-h-[calc(100vh-328px)] min-h-[300px] overflow-x-auto rounded border"
        >
          <table class="table-zebra table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Location</th>
                <th class="w-full">Hit</th>
              </tr>
            </thead>
            <tbody>
              {#each filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as hit}
                <tr>
                  <td
                    class="border-l-4"
                    class:!border-l-warning={hit.data_type === "DOCUMENT"}
                    style="border-left-color: {hit.index
                      .toLowerCase()
                      .includes('instagram')
                      ? '#FF0069'
                      : hit.index.toLowerCase().includes('tiktok')
                        ? '#00f2ea'
                        : hit.index.toLowerCase().includes('facebook')
                          ? '#4267B2'
                          : hit.index.toLowerCase().includes('twitter')
                            ? '#1DA1F2'
                            : hit.index.toLowerCase().includes('linkedin')
                              ? '#0077b5'
                              : hit.index.toLowerCase().includes('youtube')
                                ? '#FF0000'
                                : '#2b3440'}"
                  >
                    <div class="flex items-center gap-2">
                      <TableType
                        type={hit.index}
                        data_type={getDataType(hit)}
                        classes="text-3xl shrink-0"
                      />
                      <div class="max-w-[calc(100%-38px)]">
                        <h3 class="truncate font-semibold uppercase">
                          {getDataType(hit)}
                        </h3>
                        <div class="truncate">
                          {hit.vendor ? `- ${hit.vendor}` : ""}
                        </div>
                      </div>
                    </div>
                  </td>

                  <td class="max-w-[200px]">
                    <IssueTableSource
                      hitId=""
                      hitUrl={hit.url}
                      hitTitle={hit.title}
                      hitContentLink={hit.content_link}
                      hitPool={hit.index}
                      hitDataType={hit.data_type}
                    />
                  </td>

                  <td class="max-w-[max(400px,50vw)]">
                    <IssueTableHit
                      hit={hit.highlight}
                      hitUrl={hit.url}
                      hitContentLink={hit.content_link}
                      hitField={hit.field}
                      hitPool={hit.index}
                      hitDataType={hit.data_type}
                      hitTimestamp={hit.data_type.toLowerCase() == 'timestamp' ? hit.media_timestamp : null}
                      on:click={() => handleTimestampData(hit)}
                    />
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>

        <Pagination
          bind:currentPage
          bind:itemsPerPage
          bind:totalHits={filteredData.length}
          canUseKeysToPaginate={true}
        />
      </div>
    </div>
  {:else}
    <TableLoading tableRootPage="search" />
  {/if}
</div>
