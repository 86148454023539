import { writable } from "svelte/store";

// State controllers
export const showGroupedTable = writable(false);

// Data managers
export const groupBy = writable("url");

export const groupDataByGroupId = (data, openedTab) => {
  const groupedData = [];
  const groupIds = [...new Set(data.map((d) => d.group_id))];

  for (let i = 0; i < groupIds.length; i++) {
    const resultSet = data.filter((d) => d.group_id === groupIds[i]);
    const firstValidResult = resultSet.find(
      (r) => r.hit.in_report === openedTab,
    );
    const key = `${firstValidResult.rule.id}:${
      firstValidResult.hit.content_link &&
      firstValidResult.hit.data_type === "DOCUMENT"
        ? firstValidResult.hit.content_link
        : firstValidResult.hit.url
    }`;

    groupedData[i] = {
      group_id: groupIds[i],
      key: key,
      entries: resultSet,
    };
  }

  return groupedData;
};
