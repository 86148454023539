<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchGet, fetchPost, fetchPatch } from "../../../helpers";
  import MultiSelect from "../../MultiSelect.svelte";
  import Select from "../../Select.svelte";
  import Modal from "../Modal.svelte";
  import DashboardModal from "./DashboardModal.svelte";
  import ManageAiDescriptions from "./ManageAiDescriptions.svelte";

  const dispatch = createEventDispatcher();

  const resetAndClose = () => {
    activeStep = 0;
    selectedAssetIds = [];
    accumulatedFiles = [];
    companyDocumentContext = defaultCompanyDocumentContext;
    rules = [];
    thread_id = undefined;
    aiGenChecklistModal?.close();
  };

  let initialRuleDesc = "";
  let chatHistory = [
    {
      role: "bot",
      text: "I'm here to help you manage the AI-generated rule descriptions generated from the documents. Are you ready to check the AI-generated rule descriptions?",
      timestamp: new Date().toLocaleTimeString("en-US", { hour12: true }),
    },
  ];

  const defaultCompanyDocumentContext = {
    company_industry: "",
    company_bio: "",
    document_type: "", // TODO: remove this in backend ?
    document_format: "",
    additional_context: "",
  };
  let companyDocumentContext = defaultCompanyDocumentContext;

  let thread_id;
  let rules = [];

  // TODO: currently Unused to be implemented
  let errorType = "corruptFile";

  let allAssets = [];
  let selectedAssetIds = [];
  let accumulatedFiles = [];

  let activeStep = 0;
  $: steps = [
    {
      icon: "iconoir:folder",
      title: "Upload Asset",
      desc: "Upload your files here, and let our AI do the rest. We'll transform your documents into compliance rules quickly.",
      bottomButtons: {
        primaryText: accumulatedFiles.length ? "Upload Assets →" : "Proceed →",
        primaryDisabled:
          selectedAssetIds.length + accumulatedFiles.length === 0,
        primaryDisabledTooltip: "Please upload/select at least one file.",
        secondaryText: "Cancel",
        show: true,
        primaryAction: async () => {
          if (selectedAssetIds.length + accumulatedFiles.length === 0) {
            toast.error("Please upload/select at least one file.");
            return;
          }

          activeStep = 1;

          if (accumulatedFiles.length) {
            const formData = new FormData();
            for (let file of accumulatedFiles) {
              formData.append("files", file, file.name);
            }

            try {
              const res = await fetchPatch(
                "/rule_generation/upload_files",
                formData,
                true,
              );
              updateRuleGenAssets();
              selectedAssetIds = [...selectedAssetIds, ...res.file_ids];
            } catch (err) {
              console.error(err);
              toast.error("Error uploading files. Please try again.");
              activeStep = 0;
              return;
            }
          }

          activeStep = 2;
        },
        secondaryAction: resetAndClose,
      },
    },
    {
      icon: "iconoir:refresh-double",
      title: "Processing Your Documents",
      desc: "Please hold on a moment while our AI analyzes your files to generate compliance rules. This may take a few minutes.",
      bottomButtons: { show: false },
    },
    {
      icon: "iconoir:google-docs",
      title: "Provide Company and Document Context",
      desc: "Help us better understand what your company and document is about to enhance the AI's accuracy in generating rules.",
      bottomButtons: {
        primaryText: "Proceed to Manage AI-generated Descriptions →",
        primaryDisabled: companyDocumentContext.document_format.length === 0,
        primaryDisabledTooltip: "Please select a document type.",
        loading: false,
        secondaryText: "Cancel",
        show: true,
        primaryAction: async () => {
          if (companyDocumentContext.document_format.length === 0) {
            toast.error("Please select a document type.");
            return;
          }

          steps[2].bottomButtons.loading = true;

          try {
            const res = await fetchPost("/rule_generation/create_thread", {
              ...companyDocumentContext,
              file_ids: selectedAssetIds,
            });

            if (res.message !== "Thread created successfully")
              throw new Error();

            chatHistory = [
              ...chatHistory,
              {
                role: "bot",
                text: res.content,
                timestamp: new Date().toLocaleTimeString("en-US", {
                  hour12: true,
                }),
                hint: [
                  "Yes, I'm ready! Let's turn these into rules.",
                  "Not yet. Can you change the rule about...?",
                ],
              },
            ];

            thread_id = res.thread_id;

            aiGenChecklistModal?.close();
            editDescriptionsModal?.showModal();
          } catch (e) {
            console.error(e);
            toast.error(e);

            // TODO: implement showing error modal
            // errorType = "corruptFile" | "contentError"
            errorModal?.showModal();
          }

          steps[2].bottomButtons.loading = false;
        },
        secondaryAction: resetAndClose,
      },
    },
  ];

  function handleUpdate(files) {
    const newFiles = Array.from(files);
    const allowedExtensions = [
      ".pdf",
      ".doc",
      ".docx",
      ".png",
      ".jpeg",
      ".jpg",
      ".eml",
      ".msg",
    ];
    const filteredFiles = newFiles.filter((file) => {
      const fileExtension = file.name.toLowerCase().split(".").pop();
      if (!allowedExtensions.includes(`.${fileExtension}`)) {
        toast.error(
          `File ${file.name} is not supported. Please upload a PDF, DOC, or DOCX file.`,
        );
        return false;
      }
      return true;
    });

    accumulatedFiles = [...accumulatedFiles, ...filteredFiles];
  }

  const updateRuleGenAssets = async () => {
    const res = await fetchGet("/rule_generation/list_files");
    if (res.success) allAssets = res.files;
  };

  onMount(updateRuleGenAssets);
</script>

<!-- svelte-ignore missing-declaration -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->

<Modal
  modalId="aiGenChecklistModal"
  cornerCloseButton={false}
  bottomButtons={steps[activeStep].bottomButtons}
  on:close={resetAndClose}
>
  <div class="flex flex-col items-center gap-2">
    <span
      class="bg-base-200 flex h-12 w-12 items-center justify-center rounded-full"
    >
      <Icon icon={steps[activeStep].icon} class="text-primary text-xl" />
    </span>
    <p class="text-base-content/70 text-sm">
      Step {activeStep + 1} of {steps.length}
    </p>
    <h3 class="font-semibold">{steps[activeStep].title}</h3>
    <p class="text-base-content/70 text-center text-sm">
      {steps[activeStep].desc}
    </p>
  </div>

  {#if activeStep === 0}
    <!-- TODO: Implement actual file upload -->
    <label
      class="border-base-content/30 hover:border-primary my-4 flex w-full cursor-pointer items-center gap-4 rounded border-2 border-dashed p-4"
      on:drop|preventDefault={(e) => {
        handleUpdate(e.dataTransfer.files);
        e.target.classList.remove("border-primary");
      }}
      on:dragover|preventDefault
      on:dragenter|preventDefault={(e) =>
        e.target.classList.add("border-primary")}
      on:dragleave|preventDefault={(e) =>
        e.target.classList.remove("border-primary")}
    >
      <span
        class="bg-base-200 flex h-24 w-24 items-center justify-center rounded-full"
      >
        <Icon icon="iconoir:cloud-upload" class="text-primary text-3xl" />
      </span>

      <div>
        <p class="font-semibold">Upload Assets ...</p>
        <p class="text-sm">
          Drop your files here or <span class="text-primary">browse</span>.
        </p>
        <p class="text-base-content/70 text-xs">
          Supported file types: PDF, DOCX, TXT, XLS
        </p>
      </div>

      <input
        type="file"
        class="hidden"
        name="files"
        multiple
        on:change={(e) => handleUpdate(e.target.files)}
        accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .eml, .msg"
      />
    </label>

    <p class="text-base-content/70 text-center text-sm">
      or you may choose from your assets library.
    </p>

    <MultiSelect
      items={allAssets.map((a) => ({ label: a.filename, value: a.id }))}
      placeholder="Please Select"
      size="md"
      classes="w-full my-4"
      bind:selectedValues={selectedAssetIds}
    />

    {#if selectedAssetIds.length + accumulatedFiles.length}
      <div class="text-sm" transition:slide>
        <div class="grid grid-cols-4 gap-4 border-b px-4 py-2 font-semibold">
          <p class="col-span-2">Asset</p>
          <p>Status</p>
          <p>Actions</p>
        </div>

        {#each selectedAssetIds as id (id)}
          {@const asset = allAssets.find((a) => a.id === id)}
          <div
            class="bg-base-200 my-2 grid grid-cols-4 gap-4 rounded border px-4 py-2"
            transition:slide
          >
            <p class="col-span-2 truncate">{asset.filename}</p>
            <p>Uploaded</p>
            <div>
              <button
                class="btn btn-outline btn-error btn-xs"
                on:click={() =>
                  (selectedAssetIds = selectedAssetIds.filter((a) => a !== id))}
              >
                Delete
              </button>
            </div>
          </div>
        {/each}
        {#each accumulatedFiles as asset (asset.id)}
          <div
            class="bg-base-200 my-2 grid grid-cols-4 gap-4 rounded border px-4 py-2"
            transition:slide
          >
            <p class="col-span-2 truncate">{asset.name}</p>
            <p>Queued</p>
            <div>
              <button
                class="btn btn-outline btn-error btn-xs"
                on:click={() =>
                  (accumulatedFiles = accumulatedFiles.filter(
                    (a) => a.id !== asset.id,
                  ))}
              >
                Delete
              </button>
            </div>
          </div>
        {/each}
      </div>
    {/if}
  {:else if activeStep === 1}
    <div class="flex justify-center">
      <span
        class="loading loading-spinner text-primary my-4 w-40 text-center"
      />
    </div>
    <p class="text-base-content/70 text-center text-sm">
      In Progress: Your documents is being processed...
    </p>
  {:else if activeStep === 2}
    <label class="form-control w-full" for="">
      <div class="label">
        <span class="label-text">Type of Company Industry</span>
      </div>

      <input
        type="text"
        placeholder="Type here"
        class="input input-bordered w-full"
        bind:value={companyDocumentContext.company_industry}
      />
    </label>

    <label class="form-control w-full">
      <div class="label">
        <span class="label-text">Company Bio</span>
      </div>
      <textarea
        class="textarea textarea-bordered"
        placeholder="Type here"
        bind:value={companyDocumentContext.company_bio}
      />
    </label>

    <label class="form-control w-full" for="">
      <div class="label">
        <span class="label-text">
          Type of Documents

          <div class="dropdown dropdown-hover">
            <div
              tabindex="0"
              role="button"
              class="btn btn-circle btn-ghost btn-xs"
            >
              <Icon icon="iconoir:info-circle" />
            </div>

            <div
              tabindex="0"
              class="dropdown-content bg-base-200 z-[1] w-72 rounded border px-4 py-2 shadow"
            >
              <h2 class="flex items-center gap-2 font-semibold">
                <Icon icon="iconoir:info-circle" class="text-lg" /> Document Format
              </h2>

              <p class="text-base-content/70 my-2 text-xs">
                We currently only support three (3) categories for rule
                generation.
                <br /> Please contact support@haast.io to discuss alternate categories.
              </p>

              <ul class="list-inside list-disc text-sm">
                <li>
                  <span class="font-semibold">PDS</span>
                  <ul class="text-base-content/70 list-disc pl-10 text-xs">
                    <li>
                      When given a PDS to make rules from, the Al will extract
                      information in your PDS and generate rules for other
                      content to make sure it aligns with the information in the
                      PDS
                    </li>
                  </ul>
                </li>

                <li>
                  <span class="font-semibold">Checklist/Rules document</span>
                  <ul class="text-base-content/70 list-disc pl-10 text-xs">
                    <li>
                      This document should contain rules, guidelines, do's and
                      don'ts for your content. The Al will translate these into
                      Haast rules that can be applied to your digital assets
                    </li>
                  </ul>
                </li>

                <li>
                  <span class="font-semibold">Unstructured Text</span>
                  <ul class="text-base-content/70 list-disc pl-10 text-xs">
                    <li>
                      This document doesn't fit one of the pre-existing
                      templates. The Al will try to interpret the content and
                      make creative rules with it, but it may be less accurate
                      without specific context.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </span>
      </div>
      <Select
        items={[
          { label: "Financial Services PDS", value: "FINANCIAL_PDS" },
          { label: "General Insurance PDS", value: "GENERAL_PDS" },
          { label: "Life Insurance PDS", value: "LIFE_PDS" },
          { label: "Checklist/Rules document", value: "RULES" },
          {
            label: "Unstructured Text (NOT recommended)",
            value: "UNSTRUCTURED",
          },
        ]}
        bind:selectedValue={companyDocumentContext.document_format}
        size="md"
      />
    </label>

    <label class="form-control w-full">
      <div class="label">
        <span class="label-text">
          Additional Context: Share any other important information
        </span>
      </div>
      <input
        type="text"
        placeholder="Type here"
        class="input input-bordered w-full"
        bind:value={companyDocumentContext.additional_context}
      />
    </label>
  {/if}
</Modal>

<!-- svelte-ignore missing-declaration -->
<Modal
  modalId="errorModal"
  cornerCloseButton={false}
  bottomButtons={{
    show: true,
    primaryText: "Re-upload File →",
    secondaryText: "Cancel",
    primaryAction: () => aiGenChecklistModal?.showModal(),
    secondaryAction: () => errorModal?.close(),
  }}
>
  <div class="flex flex-col items-center gap-2">
    <span
      class="bg-warning/20 flex h-12 w-12 items-center justify-center rounded-full"
    >
      <Icon icon={steps[activeStep].icon} class="text-warning text-xl" />
    </span>

    <h3 class="font-semibold">Issue with Your Document</h3>

    <p class="text-base-content/70 text-center text-sm">
      We encountered a problem while processing your file.
    </p>

    {#if errorType === "corruptFile"}
      <p class="text-base-content/70 text-center text-sm">
        <span class="font-semibold">
          We couldn't extract rules from your document.
        </span> Ensure it contains relevant branding or legal compliance information
        and upload again.
      </p>
    {:else if errorType === "contentError"}
      <p class="text-base-content/70 text-center text-sm">
        <span class="font-semibold"> It seems your file might be damaged.</span>
        Please check your document and try uploading it again.
      </p>
    {/if}
  </div>
</Modal>

<!-- svelte-ignore missing-declaration -->
<ManageAiDescriptions
  {thread_id}
  {chatHistory}
  bind:rules
  on:back={() => {
    editDescriptionsModal?.close();
    aiGenChecklistModal?.showModal();
  }}
/>

<DashboardModal
  {thread_id}
  bind:selectedAssetIds
  bind:rules
  on:updateRules={() => {
    resetAndClose();
    dispatch("updateRules");
  }}
/>
