<script>
    import { createEventDispatcher } from 'svelte';
    import Icon from "@iconify/svelte";

	const dispatch = createEventDispatcher();

    export let title = "";
    export let caption = "";
    export let url = "";
    export let timestamp = 0.0;

    export let dialogModal;
    export function showModal() {
        dialogModal.showModal();
    }

    const formatSeconds = (seconds) => {
        seconds = Math.round(seconds)
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

</script>

<dialog class="modal" bind:this={dialogModal}>
    <div class="modal-box">
        <form method="dialog">
            <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
        </form>
        <div class="space-y-2">
            <div
                class="bg-primary-content m-auto flex aspect-square w-16 items-center justify-center rounded-full"
            >
                <Icon
                    icon="iconoir:window-xmark"
                    style="color: text-primary; font-size: 2em"
                />
            </div>
            <h3 class="text-center font-bold">Site not supported</h3>
            <!-- <h3 class="text-center font-bold">{title}</h3>
            <p class="text-center text-xs">...{@html caption}...</p> -->
            <p class="text-center text-sm">Go-to-timestamp functionality not supported for this website. The
      timestamp the hit was found at is</p>
            <p class="text-center font-bold text-2xl">{formatSeconds(timestamp)}</p>
        </div>
        <a  
            href="{url}" 
            class="m-2 w-full btn btn-outline btn-primary"
            target="_blank" 
            rel="noopener noreferrer">
            Go to media
        </a>
        <div class="text-xs italic px-2">Note: For SoundCloud, you must be logged in for the timestamp to work properly.</div>
    </div>
    <form method="dialog" class="modal-backdrop">
        <button>close</button>
    </form>
</dialog>