<script>
  import Icon from "@iconify/svelte";
  import { toast } from "svelte-sonner";
  import { slide } from "svelte/transition";
  import { fetchPost } from "../../../helpers";
  import Modal from "../Modal.svelte";

  export let thread_id;
  export let rules = [];
  export let feedback_rule_ids = [];

  const resetAndClose = () => {
    activeStep = 0;
    feedback = "";
    feedback_rule_ids = [];
    tempRules = [];
    activeTempRuleId = -1;
    regenerateRulesModal?.close();
  };

  let feedback = "";
  let tempRules = [];
  let activeTempRuleId = -1;

  let activeStep = 0;
  let steps = [
    {
      title: "Regenerate Rules",
      desc: "We're ready to refine this rule for better accuracy and relevance. You can help us by providing feedback below.",
      bottomButtons: {
        primaryText: "Submit and Regenerate →",
        secondaryText: "Cancel",
        show: true,
        primaryAction: async () => {
          if (feedback.trim() === "") {
            toast.warning("Please provide feedback on the rules.");
            return;
          }

          if (feedback_rule_ids.length === 0) {
            toast.warning(
              "Please select at least one rule to provide feedback.",
            );
            return;
          }

          activeStep = 1;

          const res = await fetchPost("/rule_generation/regenerate_rules", {
            thread_id,
            feedback,
            enable_rule_output: true,
            feedback_rule_ids,
            accepted_rule_ids: rules
              .filter((r) => !feedback_rule_ids.includes(r.id))
              .map((r) => r.id),
          });

          tempRules = Object.entries(res.content).map(([key, value]) => ({
            id: key,
            ...value,
          }));

          activeStep = 2;
        },
        secondaryAction: resetAndClose,
      },
    },
    {
      title: "Regenerating Rules",
      desc: "Please hold on a moment while our AI analyzes your document to regenerate rules. This may take a few minutes.",
      bottomButtons: { show: false },
    },
    {
      title: "Choose a Regenerated Rule",
      desc: "We've refined multiple versions based on your feedback. Please review and select the rule that best meets your needs.",
      bottomButtons: {
        primaryText: "Confirm Selection →",
        // Regenerate Again button also
        secondaryText: "Cancel",
        show: true,
        primaryAction: async () => {
          rules = tempRules;
          toast.success("Rules regenerated successfully.");
          resetAndClose();
        },
        secondaryAction: resetAndClose,
      },
    },
  ];
</script>

<!-- svelte-ignore a11y-no-noninteractive-tabindex -->

<Modal
  modalId="regenerateRulesModal"
  cornerCloseButton={false}
  cornerBackButton={true}
  bottomButtons={steps[activeStep].bottomButtons}
  on:close={resetAndClose}
>
  <div class="flex flex-col items-center gap-2">
    <span
      class="bg-base-200 flex h-12 w-12 items-center justify-center rounded-full"
    >
      <Icon icon="iconoir:refresh-double" class="text-primary text-xl" />
    </span>

    <h3 class="font-semibold">{steps[activeStep].title}</h3>

    <p class="text-base-content/70 text-center text-sm">
      {steps[activeStep].desc}
    </p>
  </div>

  {#if activeStep === 0}
    <label class="form-control my-4">
      <div class="label">
        <span class="label-text">Feedback on All Rules</span>
      </div>
      <input
        type="text"
        placeholder="Type here"
        class="input input-bordered"
        bind:value={feedback}
      />
    </label>

    {#each rules as r}
      <div class="my-2 flex items-center gap-2">
        <input
          type="checkbox"
          class="checkbox checkbox-primary checkbox-sm"
          value={r.id}
          bind:group={feedback_rule_ids}
        />

        <div class="bg-base-200 w-full rounded border p-4">
          <div class="flex gap-2">
            {r.rule_title}

            <button
              class="btn btn-square btn-ghost btn-xs ml-auto"
              on:click={() =>
                (activeTempRuleId = activeTempRuleId === r.id ? -1 : r.id)}
            >
              <Icon
                icon="iconoir:nav-arrow-down"
                class="text-lg transition-transform
          {activeTempRuleId === r.id ? 'rotate-180' : ''}"
              />
            </button>
          </div>

          {#if activeTempRuleId === r.id}
            <div
              class="bg-base-100 mt-4 flex flex-col gap-4 rounded p-4"
              transition:slide
            >
              <p>
                <span class="font-semibold">Rule:</span>
                {r.rule_title}
              </p>
              <p>
                <span class="font-semibold">Type of Rule:</span>
                {r.rule_type}
              </p>
              <p>
                <span class="font-semibold">Description:</span>
                {r.explanation}
              </p>
            </div>
          {/if}
        </div>
      </div>
    {/each}
  {:else if activeStep === 1}
    <div class="flex justify-center">
      <span
        class="loading loading-spinner text-primary my-4 w-40 text-center"
      />
    </div>
    <p class="text-base-content/70 text-center text-sm">
      In Progress: Your new rule is being processed...
    </p>
  {:else if activeStep === 2}
    <div class="mt-4"></div>

    {#each tempRules as r}
      <div class="bg-base-200 mt-2 w-full rounded border p-4">
        <div class="flex gap-2">
          {r.rule_title}

          <button
            class="btn btn-square btn-ghost btn-xs ml-auto"
            on:click={() =>
              (activeTempRuleId = activeTempRuleId === r.id ? -1 : r.id)}
          >
            <Icon
              icon="iconoir:nav-arrow-down"
              class="text-lg transition-transform
            {activeTempRuleId === r.id ? 'rotate-180' : ''}"
            />
          </button>
        </div>

        {#if activeTempRuleId === r.id}
          <div
            class="bg-base-100 mt-4 flex flex-col gap-4 rounded p-4"
            transition:slide
          >
            <p>
              <span class="font-semibold">Rule:</span>
              {r.rule_title}
            </p>
            <p>
              <span class="font-semibold">Type of Rule:</span>
              {r.rule_type}
            </p>
            <p>
              <span class="font-semibold">Description:</span>
              {r.explanation}
            </p>
          </div>
        {/if}
      </div>
    {/each}
  {/if}
</Modal>
