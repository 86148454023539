<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchPost } from "../../helpers";
  import Modal from "./Modal.svelte";

  const dispatch = createEventDispatcher();

  export let ruleSet;
  export let ruleSets;

  const maximumNameLength = 64;

  let checklistName = "";
  let checklistDescription = "";
  let duplicateDetected = false;
  let exceedsMaxLength = false;

  function handleCloseModal() {
    checklistName = "";
    checklistDescription = "";
    document
      .querySelector(`#duplicate-checklist-${ruleSet.rule_set?.id}`)
      ?.close();
  }

  async function duplicateChecklist(id) {
    try {
      if (!id) {
        throw new Error("Error: Required Parameter(id) is missing.");
      }

      if (
        ruleSets.some(
          (rs) =>
            rs.name === checklistName &&
            rs.description === checklistDescription,
        )
      ) {
        toast.error(
          "Could not edit checklist details, one already exists with the same name and description.",
        );

        duplicateDetected = true;
        return;
      }

      const res = await fetchPost(`/ruleset/duplicate/${id}`, {
        id: id,
        name: checklistName,
        description: checklistDescription,
        rules: ruleSet.rules,
        copyAllRules: true,
      });

      if (!res.success) {
        throw new Error(
          `API request to edit RuleSet with Id(${id}) was not successful.`,
        );
      }

      dispatch("updateRuleSets");
      toast.success(
        `Checklist(${ruleSet.rule_set?.name}) was successfully duplicated.`,
      );

      ruleSets = [...ruleSets, res.rule_set];

      handleCloseModal();
    } catch (e) {
      console.log(e);
      toast.error(
        `Something went wrong while copying Checklist(${ruleSet.rule_set?.name})`,
      );
    }
  }

  function handleDuplicateDetected() {
    if (duplicateDetected) duplicateDetected = !duplicateDetected;
  }

  function handleMaxLength() {
    if (checklistName.length > maximumNameLength) exceedsMaxLength = true;
    else exceedsMaxLength = false;
  }

  function truncateIfExceedMaxLength(name) {
    if (name.length > maximumNameLength)
      return `${name.slice(0, maximumNameLength - 3)}...`;

    return name;
  }

  onMount(() => {
    checklistName = truncateIfExceedMaxLength(
      `Copy of ${ruleSet.rule_set?.name}`,
    );
    checklistDescription = ruleSet.rule_set?.description;
  });

  $: handleDuplicateDetected(), [checklistName, checklistDescription];
  $: handleMaxLength(), [checklistName];
</script>

<Modal
  modalId="duplicate-checklist-{ruleSet.rule_set?.id}"
  on:close={handleCloseModal}
>
  <h3 class="text-center font-bold">Copy Checklist</h3>

  <div class="flex flex-col items-center gap-2">
    <div class="w-full">
      <label class="label" for="checklist_name">
        <span class="label-text">Checklist name</span>
      </label>
      <input
        type="text"
        bind:value={checklistName}
        placeholder="Checklist Name"
        class="input input-bordered w-full
          {duplicateDetected || checklistName.length < 1 || exceedsMaxLength
          ? 'input-error'
          : ''}"
        id="checklist_name"
        maxlength={maximumNameLength}
        on:change={() => {
          if (
            ruleSets.some(
              (rs) =>
                rs.name === checklistName &&
                rs.description === checklistDescription,
            )
          )
            duplicateDetected = true;
          else duplicateDetected = false;
        }}
      />
    </div>
    <div class="w-full">
      <label class="label" for="checklist_description">
        <span class="label-text">Checklist description</span>
      </label>
      <input
        type="text"
        bind:value={checklistDescription}
        placeholder="Checklist Description"
        class="input input-bordered w-full
          {duplicateDetected ? 'input-error' : ''}"
        id="checklist_description"
        required
        on:change={() => {
          if (
            ruleSets.some(
              (rs) =>
                rs.name === checklistName &&
                rs.description === checklistDescription,
            )
          )
            duplicateDetected = true;
          else duplicateDetected = false;
        }}
      />
    </div>
  </div>

  <div
    class="mt-4 w-full"
    class:tooltip={checklistName.length < 1 ||
      duplicateDetected ||
      exceedsMaxLength}
    data-tip={checklistName.length < 1
      ? "The checklist cannot have an empty name."
      : duplicateDetected
        ? "A checklist already exists with the same name and description."
        : exceedsMaxLength
          ? "The checklist name exceeds the maximum length."
          : ""}
  >
    <button
      class="btn btn-primary btn-sm w-full"
      disabled={duplicateDetected ||
        checklistName.length < 1 ||
        exceedsMaxLength}
      on:click={async () => await duplicateChecklist(ruleSet.rule_set?.id)}
    >
      Save
    </button>
  </div>
</Modal>
