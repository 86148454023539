<script>
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchGet, fetchPatch, fetchPost } from "../../../../../helpers";
  import { getCurrentStepIndex } from "../../../../../lib/utils/AssetReviewUtils";
  import { org_users } from "../../../../../stores";
  import LogoLoader from "../../../../LogoLoader.svelte";
  import Modal from "../../../../Modals/Modal.svelte";

  const dispatch = createEventDispatcher();

  export let reportId;
  export let activeAsset;
  export let activeAssetId;
  export let workflow;

  let requestChangesDialog = null;
  let assignees = [];
  let selectedUsers = [];
  let comment = "";
  let comments = [];
  let readOnly = false;
  let asset_type = "asset";
  let asset_id = activeAssetId;
  let status = "Changes Requested";
  let containerClass = "";
  let disableEditing = false;
  let requestingChanges = false;
  let resetApprovalWorkflow = false;

  function getUserInfo(id) {
    const user = $org_users.find((user) => user.id === id);
    if (!user) return { first_name: "", last_name: "" };
    if (user)
      return { first_name: user.first_name, last_name: user.last_name ?? "" };
  }

  const deleteAssignee = async (id) => {
    assignees = assignees.filter((a) => a.assigned_id !== id);
  };

  const updateAssignees = async (id) => {
    assignees = [...assignees, { id: id, assigned_id: id }];
  };

  async function handleSendApproval() {
    // TODO: implement resetApprovalWorkflow

    requestingChanges = true;

    try {
      const currStepIdx = getCurrentStepIndex(workflow, activeAsset);
      let step = workflow.workflow_steps[currStepIdx].id;

      if (
        status === "Approved" &&
        currStepIdx < workflow.workflow_steps.length - 1
      ) {
        step = workflow.workflow_steps[currStepIdx + 1].id;
      }

      const res = await fetchPatch(`/asset/${activeAssetId}`, {
        status,
        step,
        operation: "approval",
        resetApprovalWorkflow: resetApprovalWorkflow,
        suppressEmailNotification: true,
      });

      if (!res.success) {
        throw new Error(
          res?.message ??
            `Something went wrong updating the status for Asset(${activeAssetId})`,
        );
      }

      activeAsset.asset = res?.asset;
      activeAsset = activeAsset;
      toast.success(
        res?.message ??
          `Successfully updated the status of Asset(${activeAssetId}) to Status(${status})`,
      );
      //console.log("Reset Approval workflow",resetApprovalWorkflow);
      const emailResponse = await fetchPost(
        `/email/request-changes/${activeAssetId}`,
        {
          assignees,
          comment,
          report_id: reportId,
          email_type: "request_changes",
          resetApprovalWorkflow: resetApprovalWorkflow,
        },
      );

      if (!emailResponse.success)
        throw new Error(
          "Something went wrong sending asset status change email.",
        );

      requestChangesModal.close();
      dispatch("updateComments");
      resetApprovalWorkflow = false;
      toast.success(
        "Successfully sent asset status change notification email.",
      );
    } catch (e) {
      console.error(e);
      toast.error(e.toString());
    }

    requestingChanges = false;
  }

  async function fetchReportAccessors() {
    const errorMessage =
      "Someting went wrong fetching pre-populating users and teams to request changes from.";

    try {
      const queryParams = new URLSearchParams({
        field: "permissions",
      }).toString();
      const response = await fetchGet(`/asset/${activeAssetId}?${queryParams}`);

      if (!response.success) {
        throw new Error(response?.message ?? errorMessage);
      }

      response.data.forEach((ap) => {
        //if (ap.permission === "edit" || ap.permission == "manage" || ap.permission == "view") {
        updateAssignees(ap.user_id);
        //}
      });
    } catch (e) {
      toast.error(errorMessage);
      console.log(e);
    }
  }

  onMount(async () => {
    await fetchReportAccessors();
  });
</script>

<Modal modalId="requestChangesModal">
  <h3 class="text-center font-semibold">Notify users and request changes</h3>

  <p class="mt-2 text-sm">Select Users</p>
  <div class="flex w-24 items-center gap-0.5 pt-1 {containerClass}">
    {#if assignees?.length > 0}
      <div class="avatar-group -space-x-2 overflow-visible">
        {#each assignees.slice(0, assignees.length === 3 ? 3 : 2) as assignee}
          <div
            class="tooltip"
            data-tip="{getUserInfo(assignee.assigned_id)
              .first_name} {getUserInfo(assignee.assigned_id).last_name}"
          >
            <button
              class="avatar placeholder group border-2"
              class:btn-disabled={disableEditing}
              on:click={() => deleteAssignee(assignee.assigned_id)}
            >
              <div
                class="bg-neutral text-neutral-content w-6 rounded-full"
                class:group-hover:bg-error={!disableEditing}
                class:group-hover:text-error-content={!disableEditing}
              >
                <span
                  class="uppercase"
                  class:group-hover:hidden={!disableEditing}
                >
                  {getUserInfo(assignee.assigned_id).first_name[0]}{getUserInfo(
                    assignee.assigned_id,
                  ).last_name[0] ?? ""}
                </span>
                <Icon
                  icon="iconoir:cancel"
                  class="hidden text-lg {!disableEditing
                    ? 'group-hover:inline'
                    : ''}"
                />
              </div>
            </button>
          </div>
        {/each}
        {#if assignees?.length > 3}
          <div
            class="tooltip"
            data-tip={assignees
              .slice(2, assignees.length)
              .toString()
              .replaceAll(",", ", ")}
          >
            <div class="avatar placeholder border-2">
              <div class="bg-neutral text-neutral-content w-6 rounded-full">
                <span>+{assignees.length - 2}</span>
              </div>
            </div>
          </div>
        {/if}
      </div>
    {/if}
    {#if !readOnly}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
      <div class="dropdown">
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <label
          tabindex="0"
          class="btn btn-primary btn-xs whitespace-nowrap"
          class:btn-disabled={disableEditing}
          class:btn-circle={assignees?.length}
          for=""
          on:click|stopPropagation
        >
          {assignees?.length ? "+" : "Add Assignee"}
        </label>
        <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
        <ul
          tabindex="0"
          class="menu dropdown-content menu-xs bg-base-100 z-[1] m-1 w-max gap-1 rounded border p-1 shadow"
        >
          {#each $org_users.filter((ou) => !ou.is_deleted) as user}
            <li>
              <button
                class:active={assignees?.find((a) => a.id === user.id)}
                on:click|stopPropagation={() => {
                  if (assignees?.find((a) => a.assigned_id === user.id))
                    deleteAssignee(user.id);
                  else updateAssignees(user.id);
                }}
              >
                {user.first_name}
                {user.last_name}
              </button>
            </li>
          {/each}
        </ul>
      </div>
    {/if}
  </div>

  <p class="mt-2 text-sm">Add instructions</p>
  <textarea
    class="textarea textarea-bordered w-full"
    placeholder="Comment"
    bind:value={comment}
  ></textarea>

  <div class="form-control">
    <label class="label cursor-pointer">
      <span class="label-text !text-sm">Reset approval workflow</span>
      <input
        type="checkbox"
        class="toggle"
        bind:checked={resetApprovalWorkflow}
      />
    </label>
  </div>

  <div class="mt-2 flex">
    <button
      class="btn btn-primary btn-sm ml-auto"
      disabled={requestingChanges}
      on:click={handleSendApproval}
    >
      {#if requestingChanges}<LogoLoader size="1.25rem" />{/if}
      Request Changes
    </button>
  </div>
</Modal>
