<script>
  import { onMount } from "svelte";
  import { toast } from "svelte-sonner";
  import { fetchDelete, fetchGet, fetchPatch } from "../../helpers";
  import { email_list } from "../../stores";
  import ActionConfirmation from "../ActionConfirmation.svelte";
  import Pagination from "../table/Pagination.svelte";

  let emails = [];
  let emailToDelete = null;
  let edited_email = null;

  async function getEmails() {
    const response = await fetchGet("/email");
    emails = await response.emails;
    emails.sort((a, b) => a.id - b.id);
  }

  async function delete_email(id) {
    const response = await fetchDelete("/email/build/" + id);

    if (!response.success) {
      toast.error("Failed to delete item.");
      return false;
    }

    toast.success("Item deleted");
    emails = emails.filter((f) => f.id != id);
    return true;
  }

  async function save_email() {
    if (
      edited_email.title.trim().length === 0 ||
      edited_email.text.trim().length === 0 ||
      edited_email.clarification_procedure.trim().length === 0
    ) {
      toast.warning("Please fill in all fields");
      return;
    }

    const res = await fetchPatch("/email/build/" + edited_email.id, {
      title: edited_email.title,
      text: edited_email.text,
      clarification_procedure: edited_email.clarification_procedure,
      details_table: edited_email.details_table,
    });
    if (!res.success) {
      toast.error("Failed to save email.");
      return false;
    }

    let updateEmail = emails.find((f) => f.id === edited_email.id);
    updateEmail.title = edited_email.title;
    updateEmail.text = edited_email.text;
    updateEmail.clarification_procedure = edited_email.clarification_procedure;
    updateEmail.details_table = edited_email.details_table;

    edited_email = null;
    toast.success("Changes saved.");
    return true;
  }

  onMount(() => {
    getEmails();
  });

  $: getEmails(), [$email_list];

  let currentPage = 1;
  let itemsPerPage = 5;
</script>

<div class="overflow-x-auto">
  <table
    class="bg-base-200 text-base-content/80 table border-collapse text-sm *:h-12"
  >
    <thead>
      <tr
        class="text-base-content/90 border-gray-200 *:text-sm *:font-semibold"
      >
        <th>Action Title</th>
        <th>Action Description</th>
        <th>Clarification Procedure</th>
        <th>Include Details Table</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {#each emails.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as email}
        <tr>
          {#if edited_email && edited_email.id === email.id}
            <td>
              <input
                class="input input-sm input-bordered"
                placeholder="Enter your new email title..."
                bind:value={edited_email.title}
              />
            </td>
            <td>
              <textarea
                class="textarea textarea-bordered textarea-sm w-full"
                placeholder="Enter your new email text..."
                bind:value={edited_email.text}
              />
            </td>
            <td>
              <textarea
                class="textarea textarea-bordered textarea-sm w-full"
                placeholder="Enter your clarification procedure (optional)..."
                bind:value={edited_email.clarification_procedure}
              />
            </td>
            <td class="text-center">
              <input
                class="toggle toggle-primary"
                type="checkbox"
                bind:checked={edited_email.details_table}
              />
            </td>
            <td>
              <div class="m-auto flex gap-2">
                <button
                  class="btn btn-outline btn-primary btn-xs"
                  on:click={save_email}
                >
                  Save
                </button>

                <button
                  class="btn btn-outline btn-error btn-xs"
                  on:click={() => (edited_email = null)}
                >
                  Cancel
                </button>
              </div>
            </td>
          {:else}
            <td>{email.title}</td>
            <td>{email.text}</td>
            <td>{email.clarification_procedure}</td>
            <td>{email.details_table ? "Yes" : "No"}</td>
            <td>
              <div class="m-auto flex gap-2">
                <button
                  class="btn btn-outline btn-primary btn-xs"
                  on:click={() => (edited_email = { ...email })}
                >
                  Edit
                </button>

                <!-- svelte-ignore missing-declaration -->
                <button
                  class="btn btn-outline btn-error btn-xs"
                  on:click={() => {
                    emailToDelete = email.id;
                    deleteEmailConfirmation.showModal();
                  }}
                >
                  Delete
                </button>
              </div>
            </td>
          {/if}
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<Pagination bind:currentPage bind:itemsPerPage totalHits={emails.length} />

<!-- svelte-ignore missing-declaration -->
<ActionConfirmation
  message="Are you sure you want to delete this email template?"
  modalId="deleteEmailConfirmation"
  onConfirm={() => {
    deleteEmailConfirmation.close();

    delete_email(emailToDelete);
  }}
  onCancel={() => deleteEmailConfirmation.close()}
/>
