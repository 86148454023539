<script>
  import { createEventDispatcher, onMount } from "svelte";

  export let items = [];
  export let placeholder = "Please Select";
  export let size = "sm";
  export let dropdownPosition = "bottom";
  export let selectedValues = [];
  export let disabled = false;
  export let useFixedPositioning = true;
  export let classes = "";

  const dispatch = createEventDispatcher();

  let search = "";

  items = items.sort((a, b) => a.label.localeCompare(b.label));

  const groupsPresent =
    items.filter((i) => {
      if (i.group) return i.group;
    }).length > 0;
  if (groupsPresent)
    items = items.sort((a, b) => a.group.localeCompare(b.group));

  $: selectedValueLabels =
    selectedValues.length > 0
      ? selectedValues.map((e) => items.find((i) => i.value === e).label)
      : undefined;

  let dropdown;
  let dropdownCoords;
  const updateDropdownCoords = () => {
    if (dropdown) dropdownCoords = dropdown.getBoundingClientRect();
  };
  onMount(() => {
    if (!useFixedPositioning) return;

    updateDropdownCoords();

    window.addEventListener("scroll", updateDropdownCoords);
    window.addEventListener("resize", updateDropdownCoords);

    const observer = new MutationObserver(updateDropdownCoords);
    observer.observe(document.body, { subtree: true, childList: true });

    setInterval(updateDropdownCoords, 100);

    return () => {
      window.removeEventListener("scroll", updateDropdownCoords);
      window.removeEventListener("resize", updateDropdownCoords);
      observer.disconnect();
      clearInterval(updateDropdownCoords);
    };
  });
</script>

<div
  class="dropdown min-w-fit {classes}"
  class:dropdown-top={dropdownPosition === "top"}
  bind:this={dropdown}
>
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <label tabindex="0" for="" class:pointer-events-none={disabled}>
    <input
      type="text"
      class="input input-bordered w-full"
      class:input-xs={size === "xs"}
      class:input-sm={size === "sm"}
      class:input-lg={size === "lg"}
      class:placeholder:text-black={selectedValueLabels}
      placeholder={selectedValueLabels ?? placeholder}
      bind:value={search}
      {disabled}
      tabindex="-1"
    />
  </label>
  <!-- svelte-ignore a11y-no-noninteractive-tabindex -->
  <ul
    tabindex="0"
    class="menu dropdown-content bg-base-100 z-10 my-1 w-full gap-1 rounded border shadow"
    class:p-1={size === "xs" || size === "sm"}
    class:p-2={size === "md" || size === "lg"}
    class:menu-xs={size === "xs"}
    class:menu-sm={size === "sm"}
    class:menu-lg={size === "lg"}
    class:!fixed={useFixedPositioning}
    style="left: {dropdownCoords?.left}px; top: {dropdownCoords?.bottom}px; width:{dropdownCoords?.width}px;"
  >
    {#each items.filter((i) => i.label
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase())) as e, idx}
      {#if groupsPresent && (idx === 0 || e.group !== items[idx - 1].group)}
        <li class="menu-title">{e.group}</li>
      {/if}

      <li>
        <button
          class:active={selectedValues.includes(e.value)}
          on:click={() => {
            if (selectedValues.includes(e.value))
              selectedValues = selectedValues.filter((i) => i !== e.value);
            else {
              selectedValues = [...selectedValues, e.value];
              dispatch("change", selectedValues);
            }
            search = "";
            document.activeElement.blur();
          }}
          tabindex="-1"
        >
          {e.label}
        </button>
      </li>
    {/each}
    {#if items.filter((i) => i.label
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase())).length === 0}
      <li class="menu-title">No items found</li>
    {/if}
  </ul>
</div>

<style>
  .menu li button.active {
    background-color: oklch(var(--p) / var(--tw-bg-opacity));
    color: oklch(var(--pc) / var(--tw-text-opacity));
  }
</style>
