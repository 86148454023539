<script>
  import { getUser } from "../../../helpers";

  export let comment;
</script>

<p class="text-sm">
  {#if comment.old}
    {#if comment.new}
      {getUser(comment.user_id)} <strong>changed action</strong> from
      <i>{comment.old}</i> to <i>{comment.new}</i>
    {:else}
      {getUser(comment.user_id)} <strong>removed action</strong>
      <i>{comment.old}</i>
    {/if}
  {:else}
    {getUser(comment.user_id)} <strong>assigned action</strong>
    <i>{comment.new}</i>
  {/if}
</p>
