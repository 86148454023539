<script>
  import { javascript } from "@codemirror/lang-javascript";
  import Icon from "@iconify/svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import CodeMirror from "svelte-codemirror-editor";
  import { toast } from "svelte-sonner";
  import { fly, slide } from "svelte/transition";
  import { debounce } from "lodash";
  import { fetchGet, fetchPost } from "../../helpers";
  import {
    ConditionalRuleType,
    LoadRulesTab,
    RuleSearchType,
    RuleStatus,
    RuleType,
  } from "../../lib/interfaces/Rule.interface";
  import { NoRuleSetDefaults } from "../../lib/interfaces/RuleSet.interface";
  import { isEmpty } from "../../lib/utils/GenericUtils";
  import { currentUser, org_id, user_teams } from "../../stores";
  import QueryPrettyPrint from "../AssetReview/QueryPrettyPrint.svelte";
  import Badge from "../AssetReview/WorkflowBoard/Badge.svelte";
  import ChecklistInfoModal from "../ChecklistInfoModal.svelte";
  import SavedRulesTableRowConditionalRules from "../TableViews/SavedRulesTable/SavedRulesTableRowConditionalRules.svelte";
  import Pagination from "../table/Pagination.svelte";
  import {
    isParentConditionalRule,
    ruleSearchTypeToLabel,
  } from "../../lib/utils/RuleUtils";
  import LogoLoader from "../LogoLoader.svelte";

  const dispatch = createEventDispatcher();

  export let reportId;
  export let allRuleSets = [];
  export let reportRules = [];
  export let showAssetRules = false;
  export let sharedRules = [];
  export let loading = false;

  let activeTab = "My Checklists";
  let rulesGrouped = [];
  let savedRules = [];
  let selectedRules = [];
  let selectedSharedRules = new Map();
  let selectedChecklists = new Map();
  let activeChecklistIdx = -1;
  let activeRuleId = -1;

  let currentTabRules = [];
  let currentPage = 1;
  let itemsPerPage = 10;
  let rulesOnCurrentPage = rulesGrouped;
  let searchInputValue = "";
  let searchPattern = "";

  async function duplicateChecklists() {
    if (selectedChecklists.size > 0) {
      for (const [key, _] of selectedChecklists) {
        const res = await fetchPost(`/ruleset/duplicate/${key}`, {
          newReportId: reportId,
          copyAllRules: false,
        });

        if (!res.success) {
          throw new Error(`${res.message} Error: ${res.error}`);
        }
        selectedChecklists.set(key, res.rule_set?.id);
      }
    }
  }

  async function duplicateSharedRuleChecklist(parentRuleSetId) {
    const appliedSRWithRuleSet = sharedRules.find(
      (sr) =>
        sr.parent_rule_set_id === parentRuleSetId &&
        !isEmpty(sr.rule_id) &&
        !isEmpty(sr.rule_set_id) &&
        reportRules?.some((rr) => rr?.some((r) => r?.rule?.id === sr.rule_id)),
    );

    if (!isEmpty(appliedSRWithRuleSet)) {
      selectedChecklists.set(
        appliedSRWithRuleSet.parent_rule_set_id,
        appliedSRWithRuleSet.rule_set_id,
      );
    }

    if (!selectedChecklists.has(parentRuleSetId)) {
      const res = await fetchPost(`/ruleset/duplicate/${parentRuleSetId}`, {
        newReportId: reportId,
        copyAllRules: false,
      });

      if (!res.success) {
        throw new Error(`${res.message} Error: ${res.error}`);
      }

      selectedChecklists.set(parentRuleSetId, res.rule_set?.id);
    }
  }

  async function applyRules() {
    loading = true;
    const queryParamsObj = {
      isNewChecklist: true,
    };

    try {
      await duplicateChecklists();

      for (const [index, id] of selectedRules.entries()) {
        if (selectedSharedRules.has(id)) {
          const sharedRuleDetails = selectedSharedRules.get(id);
          await duplicateSharedRuleChecklist(
            sharedRuleDetails.parent_rule_set_id,
          );
          await fetchPost(`/rule/share/${id}/apply/${reportId}`, {
            owner: sharedRuleDetails.owner,
            permission: sharedRuleDetails.permission,
            is_team:
              sharedRuleDetails.team_id !== -1 &&
              sharedRuleDetails.user_id === -1,
            team_id: sharedRuleDetails.team_id,
            user_id: sharedRuleDetails.user_id,
            rule_set_id: selectedChecklists.get(
              sharedRuleDetails.parent_rule_set_id,
            ),
            parent_rule_id: sharedRuleDetails.parent_rule_id,
            parent_rule_set_id: sharedRuleDetails.parent_rule_set_id,
          });
        } else {
          const queryParams = new URLSearchParams(queryParamsObj);
          await fetchGet(`/rule/duplicate/${id}/${reportId}?${queryParams}`);
        }
      }

      dispatch("ruleDuplicated");
    } catch (e) {
      console.log(e);
      toast.error(`Something went wrong while loading rules.`);
    }
  }

  function clearSelections() {
    selectedRules = [];
    selectedSharedRules = new Map();
    selectedChecklists = new Map();
  }

  onMount(async () => {
    loading = true;
    const queryParams = new URLSearchParams({
      type: "saved",
    }).toString();
    const res = await fetchGet(`/rule?${queryParams}`);

    savedRules = showAssetRules
      ? res.rules.filter((r) => r.type === "asset")
      : res.rules.filter((r) => r.type != "asset");

    updateRuleGroups();
    loading = false;
  });

  const debouncedSearch = debounce(() => onSearch(), 600);

  const onSearch = () => {
    searchPattern = searchInputValue;
    currentPage = 1;
    updateRuleGroups();
  };

  const calculateRulesOnCurrentPage = () => {
    rulesOnCurrentPage = rulesGrouped.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
    );

    rulesOnCurrentPage = rulesOnCurrentPage;
  };

  const resetFilteringAndPagination = () => {
    currentPage = 1;
    searchPattern = "";
    searchInputValue = "";
    updateRuleGroups();
  };

  const updateRuleGroups = () => {
    currentTabRules = savedRules.filter((r) => {
      if (
        (activeTab == LoadRulesTab.ORGANIZATION && r.is_organization_rule) ||
        (activeTab == LoadRulesTab.INDUSTRY && r.is_industry_rule) ||
        (activeTab == LoadRulesTab.MY &&
          !r.is_organization_rule &&
          !r.is_industry_rule &&
          r.owner === $currentUser.id &&
          r.report_id === $org_id) ||
        (activeTab == LoadRulesTab.SHARED &&
          !r.is_organization_rule &&
          !r.is_industry_rule &&
          r.owner !== $currentUser.id &&
          r.report_id === $org_id)
      ) {
        return true;
      }
    });

    const ruleSetsIds = [
      ...new Set(currentTabRules.flatMap((r) => r.rule_sets)),
    ];

    rulesGrouped = ruleSetsIds.map((rs_id) => {
      return currentTabRules
        .filter((r) => r.rule_sets.includes(rs_id))
        .map((r) => {
          return {
            rule_set: r.rule_set_details.find((rs) => rs.id === rs_id),
            rule: r,
          };
        })
        .sort((a, b) => a.rule.name.localeCompare(b.rule.name));
    });

    // if there is any rule that is not in a rule set
    if (currentTabRules.some((r) => r.rule_sets.length === 0)) {
      rulesGrouped = [
        currentTabRules
          .filter((r) => r.rule_sets.length === 0)
          .map((r) => {
            return {
              rule_set: {
                id: NoRuleSetDefaults.ID,
                name: NoRuleSetDefaults.NAME,
                description: NoRuleSetDefaults.DESCRIPTION,
              },
              rule: r,
            };
          }),
        ...rulesGrouped,
      ];
    }

    rulesGrouped = [
      ...rulesGrouped.sort((a, b) => {
        if (
          a[0].rule_set.id === "no_rule_set" &&
          b[0].rule_set.id !== "no_rule_set"
        ) {
          return 1;
        }

        if (
          b[0].rule_set.id === "no_rule_set" &&
          a[0].rule_set.id !== "no_rule_set"
        ) {
          return -1;
        }

        return a[0].rule_set.name.localeCompare(b[0].rule_set.name);
      }),
    ];

    rulesGrouped.forEach((group) => {
      const ruleSetInfo = allRuleSets.find(
        (rs) => rs.id === group[0].rule_set.id,
      );
      group.sort((a, b) => a.rule.name.localeCompare(b.rule.name));

      group.forEach((member) => {
        member.rule_set = {
          ...member.rule_set,
          description:
            ruleSetInfo && !isEmpty(ruleSetInfo.description)
              ? ruleSetInfo.description
              : "No Description",
        };
      });
    });

    if (searchPattern) {
      rulesGrouped = rulesGrouped.filter((group) =>
        group[0].rule_set.name
          .toLowerCase()
          .includes(searchPattern.toLowerCase()),
      );
    }

    rulesGrouped = rulesGrouped;

    if (rulesGrouped.length === 1) activeChecklistIdx = 0;

    calculateRulesOnCurrentPage();
  };

  $: if (activeTab) {
    updateRuleGroups();
  }
  $: calculateRulesOnCurrentPage(), [currentPage, rulesGrouped, itemsPerPage];
  $: clearSelections(), [activeTab];
  $: resetFilteringAndPagination(), [activeTab];
</script>

<div class="mb-2 mt-4 grid grid-cols-3 overflow-auto rounded border">
  {#each [LoadRulesTab.MY, LoadRulesTab.SHARED, LoadRulesTab.INDUSTRY] as e}
    <button
      class="hover:bg-base-200 px-4 py-2 text-center text-sm"
      class:bg-base-200={activeTab === e}
      on:click={() => (activeTab = e)}
    >
      {e}
    </button>
  {/each}
</div>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
{#key (activeTab, rulesGrouped)}
  <div
    class="grid w-full gap-2 overflow-auto rounded border p-2"
    in:fly={{ y: 32 }}
  >
    <div class="grid min-w-[750px] grid-cols-2 border-b px-6 font-semibold">
      <label
        class="label col-span-1 cursor-pointer justify-start gap-2"
        on:click|stopPropagation
      >
        <input
          type="checkbox"
          class="checkbox-primary checkbox checkbox-sm"
          indeterminate={currentTabRules.some((r) =>
            selectedRules.includes(r.id),
          ) && !currentTabRules.every((r) => selectedRules.includes(r.id))}
          checked={currentTabRules.every((r) => selectedRules.includes(r.id))}
          on:change={(e) => {
            if (e.target.checked) {
              selectedRules = currentTabRules.map((r) => r.id);

              if (activeTab === LoadRulesTab.SHARED) {
                currentTabRules.forEach((r) => {
                  const sharedRule = sharedRules.find(
                    (sr) =>
                      sr.parent_rule_id === r.id &&
                      (sr.user_id === $currentUser.id ||
                        $user_teams.some((ut) => ut.id === sr.team_id)),
                  );

                  if (sharedRule) {
                    selectedSharedRules.set(r.id, sharedRule);
                    selectedSharedRules = selectedSharedRules;
                  }
                });
              }

              currentTabRules.forEach((ctr) => {
                if (
                  ctr.rule_sets[0] &&
                  !selectedChecklists.has(ctr.rule_sets[0]) &&
                  !reportRules.some((rr) =>
                    rr.some(
                      (r) =>
                        r.rule.rule_set_details &&
                        r.rule.rule_set_details.some((rsd) =>
                          ctr.rule_set_details.some(
                            (crsd) => crsd.name === rsd.name,
                          ),
                        ),
                    ),
                  ) &&
                  ctr.rule_sets[0] !== NoRuleSetDefaults.ID
                ) {
                  selectedChecklists.set(ctr.rule_sets[0], -1);
                  selectedChecklists = selectedChecklists;
                }
              });
            } else {
              selectedRules = [];
              selectedChecklists = new Map();

              if (activeTab === LoadRulesTab.SHARED) {
                selectedSharedRules = new Map();
              }
            }
          }}
        />
        <span class="label-text">Checklist Name</span>
      </label>

      <div class="relative mb-2">
        <input
          class="input input-bordered w-full pr-16"
          bind:value={searchInputValue}
          on:keydown={(e) => {
            if (e.which === 13) {
              onSearch();
            }
          }}
          on:input={debouncedSearch}
          placeholder="Filter by checklist name..."
        />
        <button
          class="btn btn-ghost absolute right-0"
          on:click={() => onSearch()}
        >
          <Icon icon="iconoir:search" />
        </button>
      </div>
      <!-- <span class="col-span-1 text-end">Actions</span> -->
    </div>

    {#each rulesOnCurrentPage as checklist, i}
      <div class="rounded border">
        <div
          class="flex cursor-pointer items-center justify-between gap-2 px-6 py-2"
          on:click={() => {
            activeChecklistIdx = activeChecklistIdx === i ? -1 : i;
            activeRuleId = -1;
          }}
        >
          <label
            class="label col-span-1 cursor-pointer justify-start gap-2"
            on:click|stopPropagation
          >
            <input
              type="checkbox"
              class="checkbox-primary checkbox checkbox-sm"
              indeterminate={checklist.some((c) =>
                selectedRules.includes(c.rule.id),
              ) && !checklist.every((c) => selectedRules.includes(c.rule.id))}
              checked={checklist.every((c) =>
                selectedRules.includes(c.rule.id),
              )}
              on:change={(e) => {
                selectedRules = selectedRules.filter(
                  (r) => !checklist.map((c) => c.rule.id).includes(r),
                );

                selectedChecklists.delete(checklist[0].rule_set.id);
                selectedChecklists = selectedChecklists;

                if (activeTab === LoadRulesTab.SHARED) {
                  checklist.forEach((c) =>
                    selectedSharedRules.delete(c.rule.id),
                  );
                  selectedSharedRules = selectedSharedRules;
                }

                if (e.target.checked) {
                  selectedRules = [
                    ...selectedRules,
                    ...checklist.map((c) => c.rule.id),
                  ];

                  if (
                    checklist[0].rule_set.id &&
                    !selectedChecklists.has(checklist[0].rule_set.id) &&
                    !reportRules.some((rr) =>
                      rr?.some(
                        (r) => r.rule_set.name === checklist[0].rule_set?.name,
                      ),
                    ) &&
                    checklist[0].rule_set.id !== NoRuleSetDefaults.ID
                  ) {
                    selectedChecklists.set(checklist[0].rule_set.id, -1);
                    selectedChecklists = selectedChecklists;
                  }

                  if (activeTab === LoadRulesTab.SHARED) {
                    checklist.forEach((c) => {
                      const sharedRule = sharedRules.find(
                        (sr) =>
                          sr.parent_rule_id === c.rule.id &&
                          (sr.user_id === $currentUser.id ||
                            $user_teams.some((ut) => ut.id === sr.team_id)),
                      );

                      if (sharedRule) {
                        selectedSharedRules.set(c.rule.id, sharedRule);
                        selectedSharedRules = selectedSharedRules;
                      }
                    });
                  }
                }
              }}
            />
            <span class="label-text">{checklist[0].rule_set.name}</span>

            <ChecklistInfoModal data={checklist[0].rule_set} />
          </label>

          <div class="flex items-center gap-1">
            <button class="btn btn-square btn-ghost btn-sm">
              <Icon
                icon="iconoir:nav-arrow-{activeChecklistIdx === i
                  ? 'up'
                  : 'down'}"
                class="text-lg"
              />
            </button>
          </div>
        </div>

        {#if activeChecklistIdx === i}
          <div class="flex flex-col gap-1 p-2 pt-0" transition:slide>
            {#each checklist as { rule }}
              <div class="bg-base-200 min-w-[750px] rounded border">
                <div
                  class="grid grid-cols-3 items-center justify-between gap-4 px-4 py-2"
                  on:click={() =>
                    (activeRuleId = activeRuleId === rule.id ? -1 : rule.id)}
                >
                  <label
                    class="label col-span-1 cursor-pointer justify-start gap-2 py-0"
                    on:click|stopPropagation
                  >
                    <input
                      type="checkbox"
                      class="checkbox-primary checkbox checkbox-sm"
                      checked={selectedRules.includes(rule.id)}
                      on:change={(e) => {
                        if (e.target.checked) {
                          selectedRules = [...selectedRules, rule.id];

                          if (activeTab === LoadRulesTab.SHARED) {
                            const sharedRule = sharedRules.find(
                              (sr) =>
                                sr.parent_rule_id === rule.id &&
                                (sr.user_id === $currentUser.id ||
                                  $user_teams.some(
                                    (ut) => ut.id === sr.team_id,
                                  )),
                            );

                            if (sharedRule) {
                              selectedSharedRules.set(rule.id, sharedRule);
                              selectedSharedRules = selectedSharedRules;
                            }

                            if (
                              checklist[0].rule_set.id &&
                              !checklist.some((c) =>
                                selectedSharedRules.has(c.rule.id),
                              ) &&
                              !reportRules.some((rr) =>
                                rr?.some(
                                  (r) =>
                                    r.rule_set.name ===
                                    checklist[0].rule_set?.name,
                                ),
                              ) &&
                              checklist[0].rule_set.id !== NoRuleSetDefaults.ID
                            ) {
                              selectedChecklists.set(
                                checklist[0].rule_set.id,
                                -1,
                              );
                              selectedChecklists = selectedChecklists;
                            }
                          }
                        } else {
                          selectedRules = selectedRules.filter(
                            (r) => r !== rule.id,
                          );

                          if (
                            !checklist.some((c) =>
                              selectedSharedRules.has(c.rule.id),
                            )
                          ) {
                            selectedChecklists.delete(checklist[0].rule_set.id);
                          }

                          if (activeTab === LoadRulesTab.SHARED) {
                            selectedSharedRules.delete(rule.id);
                          }
                        }
                      }}
                    />
                    <span class="label-text">{rule.name}</span>
                  </label>

                  <div class="col-span-1 flex w-max gap-2">
                    {#if rule.type === "asset"}
                      <Badge bgColor="#929292" textColor="white">
                        {ruleSearchTypeToLabel(rule.rule_type)}
                      </Badge>
                      {#if rule.rule_type === RuleSearchType.MANUAL}
                        {#if rule.affirmative_rule}
                          <Badge bgColor="#929292" textColor="white">
                            {RuleStatus.PASSED} if Marked Complete
                          </Badge>
                        {:else}
                          <Badge bgColor="#929292" textColor="white">
                            {RuleStatus.RISK} if Not Marked Complete
                          </Badge>
                        {/if}
                      {:else if rule.affirmative_rule}
                        <Badge bgColor="#929292" textColor="white">
                          {RuleStatus.PASSED} if Matched
                        </Badge>
                      {:else}
                        <Badge bgColor="#929292" textColor="white">
                          {RuleStatus.RISK} if Matched
                        </Badge>
                      {/if}
                    {/if}
                  </div>

                  <div class="col-span-1 ml-auto flex items-center gap-1">
                    <button class="btn btn-square btn-ghost btn-sm">
                      <Icon
                        icon="iconoir:nav-arrow-{activeRuleId === rule.id
                          ? 'up'
                          : 'down'}"
                        class="text-lg"
                      />
                    </button>
                  </div>
                </div>

                {#if activeRuleId === rule.id}
                  <main
                    class="grid grid-cols-3 gap-4 px-4 py-2 pt-0"
                    transition:slide
                  >
                    {#if rule.type === RuleType.ASSET}
                      {#if rule.rule_type === RuleSearchType.MANUAL}
                        <div>
                          <p class="text-sm font-medium">ID</p>
                          <p class="text-sm font-light">
                            #{rule.id}
                          </p>
                        </div>
                        <div>
                          <p class="text-sm font-medium">Description</p>
                          <p class="text-sm font-light">
                            {rule.description}
                          </p>
                        </div>
                        <div>
                          <p class="text-sm font-medium">Remediation Step</p>
                          <p class="text-sm font-light">
                            {isEmpty(rule.remediation_step)
                              ? "No remediation step."
                              : rule.remediation_step}
                          </p>
                        </div>
                        <div class="col-span-3 grid grid-cols-6 gap-4">
                          <div class="col-span-3 mr-[36px] flex flex-col gap-1">
                            <p class="mt-1 text-sm font-medium">Query</p>
                            <QueryPrettyPrint
                              {rule}
                              isParentConditional={isParentConditionalRule(
                                rule,
                              )}
                            />
                          </div>
                          <div
                            class="col-span-2 col-start-5 flex flex-col gap-1"
                          >
                            <p class="mt-1 text-sm font-medium">Sub-Tasks</p>
                            <ul class="rounded-box list-disc">
                              {#each rule.examples.split(" AND ") as example}
                                <li class="ml-6">
                                  <span class="badge h-auto"
                                    >{example.slice(1, -1)}</span
                                  >
                                </li>
                              {/each}
                            </ul>
                          </div>
                        </div>
                      {:else}
                        <!-- for non manual rules -->
                        <div>
                          <p class="text-sm font-medium">ID</p>
                          <p class="text-sm font-light">
                            #{rule.id}
                          </p>
                        </div>
                        <div>
                          <p class="text-sm font-medium">Description</p>
                          <p class="text-sm font-light">
                            {rule.description}
                          </p>
                        </div>
                        <div>
                          <p class="text-sm font-medium">Remediation Step</p>
                          <p class="text-sm font-light">
                            {isEmpty(rule.remediation_step)
                              ? "No remediation step."
                              : rule.remediation_step}
                          </p>
                        </div>

                        <div class="col-span-3 grid grid-cols-2">
                          <div class="col-span-1 mr-[36px] flex flex-col gap-1">
                            <p class="mt-1 text-sm font-medium">Query</p>
                            <QueryPrettyPrint
                              {rule}
                              isParentConditional={isParentConditionalRule(
                                rule,
                              )}
                            />
                          </div>
                        </div>

                        {#if !isEmpty(rule?.conditional_id) && rule?.condition_type === ConditionalRuleType.IF}
                          <div class="col-span-3 flex flex-col">
                            <div class="mb-2 gap-1">
                              <p class="text-sm font-medium">
                                Conditional Child Rules
                              </p>
                              <p class="text-sm font-light">
                                If this rule is
                                <Badge bgColor="#336A1D" textColor="white">
                                  {RuleStatus.TRUE}
                                </Badge>, then the following rules will be run.
                              </p>
                            </div>

                            <SavedRulesTableRowConditionalRules
                              rules={rule?.children ?? []}
                              assetRulesTable={true}
                              showEdit={false}
                            />
                          </div>
                        {/if}
                      {/if}
                    {:else}
                      <!-- below is for live rules -->
                      <div>
                        <p class="text-sm font-medium">ID</p>
                        <p class="text-sm font-light">
                          #{rule.id}
                        </p>
                      </div>
                      <div>
                        <p class="text-sm font-medium">Description</p>
                        <p class="text-sm font-light">
                          {rule.description}
                        </p>
                      </div>
                      <div class="col-span-1">
                        <p class="text-sm font-medium">Channels</p>
                        <ul class="ml-4 list-disc text-sm">
                          {#each rule.data_type.split(", ") as d}
                            <li>{d}</li>
                          {/each}
                        </ul>
                      </div>
                      <div class="col-span-3">
                        <p class="text-sm font-medium">Remediation Step</p>
                        <p class="text-sm font-light">
                          {isEmpty(rule.remediation_step)
                            ? "No remediation step."
                            : rule.remediation_step}
                        </p>
                      </div>
                      <div class="col-span-3 grid grid-cols-2">
                        <div class="col-span-1 mr-[36px] flex flex-col gap-1">
                          <p class="mt-1 text-sm font-medium">Query</p>
                          <CodeMirror
                            bind:value={rule.boolean}
                            editable={false}
                            readonly={true}
                            lang={javascript()}
                            styles={{
                              "&": { background: "white", fontSize: "14px" },
                              ".cm-gutters": { display: "none" },
                            }}
                            lineWrapping={true}
                          />
                        </div>
                      </div>
                    {/if}
                  </main>
                {/if}
              </div>
            {/each}
          </div>
        {/if}
      </div>
    {:else}
      <div
        class="flex flex-col justify-center items-center p-4 text-base-content/50"
      >
        {#if loading}
          <LogoLoader size="100px" />
        {:else}
          <Icon icon="iconoir:folder" class="text-8xl" />
          <p class="text-sm text-center">
            No checklist rules yet! <br />
            We're on a clean slate waiting for your brilliance.
          </p>
        {/if}
      </div>
    {/each}
    <Pagination
      bind:currentPage
      bind:itemsPerPage
      bind:totalHits={rulesGrouped.length}
      canUseKeysToPaginate={true}
    />
  </div>
{/key}

<div class="mt-4 flex gap-2">
  {#if showAssetRules}
    <button class="btn btn-sm" on:click={() => dispatch("back")}>Back</button>
  {/if}

  {#if selectedRules.length}
    <button
      class="btn btn-primary btn-sm"
      on:click={async () => {
        await applyRules();
      }}
      disabled={loading}
    >
      {#if loading}
        <span class="loading loading-sm"></span>
      {/if}
      {showAssetRules ? "Apply" : "Load Rules"}
    </button>
  {:else}
    <div class="tooltip" data-tip="Please select at least one rule to add.">
      <button class="btn btn-primary btn-sm" disabled>
        {showAssetRules ? "Apply" : "Load Rules"}
      </button>
    </div>
  {/if}
</div>
