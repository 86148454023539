<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { fetchGet } from "../../helpers";
  import PSPDFKit from "./PdfViewerComponent.svelte";

  const API_VERSION = import.meta.env.VITE_API_VERSION;
  const dispatch = createEventDispatcher();

  export let documentURL =
    "https://github.com/mozilla/pdf.js-sample-files/raw/master/helloworld.pdf";
  let documentToOpen = `${window.location.protocol}//${window.location.host}/api/${API_VERSION}/pdf/proxy_pdf?url=${documentURL}`;

  function arraysAreEqual(a, b) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (
        a[i].length !== b[i].length ||
        a[i][0] !== b[i][0] ||
        a[i][1] !== b[i][1]
      ) {
        return false;
      }
    }
    return true;
  }

  export let textToSearch = "";
  export let boundingBox = [
    [0, 0],
    [0, 0],
  ];

  let highlightString = "";
  const highlightMatch = textToSearch.match(/<em>([^<>]+)<\/em>/m);
  if (highlightMatch) {
    highlightString = highlightMatch[1];
  }
  const formattedTextToSearch = textToSearch
    .replace(/<[^>]*>/gm, "")
    .replace(/(\r\n|\n|\r)/gm, " ")
    .replace(/\s+/g, " ");

  let loadingStateText = "";

  export let documentType = "proxy";
  export let height = "600px";
  export let open = false;

  //should only search when text to search is not "" OR null
  function isValidSearchText(text) {
    return text && text.trim().length > 0;
  }

  function handleLoadingUpdated(event) {
    loadingStateText = event.detail.loading;

    if (loadingStateText === "Done!") {
      setTimeout(() => {
        loadingStateText = "";
      }, 1000);
    }
  }

  function dispatchOpenStateChange() {
    dispatch("openStateChange", {
      open: open,
    });
  }

  onMount(() => {
    if (documentType === "base64") {
      fetchGet(`/pdf/pdf_to_base64?url=${documentURL}`)
        .then((response) => {
          documentToOpen = response.pdf;
        })
        .catch((error) => {
          console.error(error);
        });
    }
  });
</script>

<!-- If check to prevent the document from being loaded before the dialog is open -->
{#if open && documentURL}
  <!-- Width hack as for some reason setting width = 100% does not work -->
  {#if isValidSearchText(formattedTextToSearch)}
    <PSPDFKit
      {documentType}
      documentLink={documentToOpen}
      search={formattedTextToSearch}
      highlight={highlightString}
      {height}
      on:loadingUpdated={handleLoadingUpdated}
    />
  {:else if !arraysAreEqual(boundingBox, [[0, 0], [0, 0]])}
    <PSPDFKit
      {documentType}
      documentLink={documentToOpen}
      {boundingBox}
      {height}
      on:loadingUpdated={handleLoadingUpdated}
    />
  {:else}
    <PSPDFKit
      {documentType}
      documentLink={documentToOpen}
      {height}
      on:loadingUpdated={handleLoadingUpdated}
    />
  {/if}
{/if}
