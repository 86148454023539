<script>
  import { onMount } from "svelte";
  import { fetchGet } from "../../helpers";
  import Pagination from "../table/Pagination.svelte";
  import { teamToShowId } from "./teamOverview";

  let data = [];

  let currentPage = 1;
  let itemsPerPage = 5;
  let filteredData = [];

  onMount(async () => {
    const response = await fetchGet("/team/overview");
    data = response.data;
  });

  $: if ($teamToShowId === -1) {
    filteredData = data;
  } else {
    filteredData = [];
    for (let i = 0; i < data.length; i++)
      if (data[i].teams.some((team) => team.id === $teamToShowId))
        filteredData.push(data[i]);
  }
</script>

<div class="overflow-x-auto">
  <table class="table table-fixed">
    <colgroup>
      <col span="1" style="width:30%" />
      <col span="1" style="width:17.5%; min-width: 100px;" />
      <col span="1" style="width:17.5%; min-width: 100px;" />
      <col span="1" style="width:17.5%; min-width: 100px;" />
      <col span="1" style="width:17.5%; min-width: 100px;" />
    </colgroup>
    <thead>
      <tr>
        <th class="whitespace-normal">Name</th>
        <th class="whitespace-normal">Flagged hits</th>
        <th class="whitespace-normal">Closed hits</th>
        <th class="whitespace-normal">Average time to close hit</th>
        <th class="whitespace-normal">Hits past due date</th>
      </tr>
    </thead>

    <tbody>
      {#each filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as row}
        <tr>
          <td>
            <p class="font-semibold">
              {row.first_name}
              {row.last_name}
            </p>
            <p class="text-xs italic">
              {#each row["teams"] as team, i}
                {team.name}{i == row["teams"].length - 1 ? "" : ", "}
              {/each}
            </p>
          </td>
          <td class="font-semibold"
            >{row?.flagged_hits ? Math.round(row.flagged_hits) : 0}</td
          >
          <td class="font-semibold"
            >{row?.closed_hits ? Math.round(row.closed_hits) : 0}</td
          >
          <td class="font-semibold">
            {Math.round(row.average_time_to_close)} days
          </td>
          <td class="font-semibold">{Math.round(row.past_due_hits)}</td>
        </tr>
      {:else}
        {#each { length: 6 } as _, i}
          <tr>
            <td>
              <div class="skeleton w-32 h-4" />
            </td>
            <td>
              <div class="skeleton w-16 h-4" />
            </td>
            <td>
              <div class="skeleton w-16 h-4" />
            </td>
            <td>
              <div class="skeleton w-20 h-4" />
            </td>
            <td>
              <div class="skeleton w-16 h-4" />
            </td>
          </tr>
        {/each}
      {/each}
    </tbody>
  </table>
</div>

<Pagination
  bind:currentPage
  bind:itemsPerPage
  totalHits={filteredData.length}
/>
