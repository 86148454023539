<script>
  import { Controls, Position, SvelteFlow, MarkerType } from "@xyflow/svelte";
  import "@xyflow/svelte/dist/style.css";
  import { writable } from "svelte/store";
  import DisabledWorkflowStep from "./DisabledWorkflowStep.svelte";

  export let selectedWorkflow;
  export let report_id = null;

  // The writable stores for nodes and edges
  const nodes = writable([]);
  const edges = writable([]);

  const nodeTypes = {
    workflowStep: DisabledWorkflowStep,
  };

  // This function will create nodes and edges based on the selected workflow
  function createLinearNodes(workflow) {
    let xPos = 20; // Initial x position for the first node
    const yPos = 60; // Y position for all nodes
    const xOffset = 300; // X offset for each node

    const newNodes = workflow.workflow_steps.map((step, index) => ({
      id: `horizontal-${step.id}`,
      type: "workflowStep", // replace with your actual node type
      data: {
        ...step, // Preserving existing data
        report_id,
      },
      position: { x: xPos + xOffset * index, y: yPos },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    }));

    // Create edges between the sequential nodes
    const newEdges = newNodes.slice(1).map((node, index) => ({
      id: `edge-${newNodes[index].id}-${node.id}`,
      source: newNodes[index].id,
      target: node.id,
      type: "bezier",
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 15,
        height: 15,
        color: "#555",
      },
      style: "stroke-width: 1px; stroke: #555;",
      //animated: true,
    }));

    // Update the nodes and edges stores
    nodes.set(newNodes);
    edges.set(newEdges);
  }

  // Reactive statement to update the flow when selectedWorkflow changes
  $: if (selectedWorkflow && selectedWorkflow.workflow_steps) {
    createLinearNodes(selectedWorkflow);
    //deletes the svelte flow
    //works on reload not immediatedly
    const anchorElement = document.querySelector(
      'a[href="https://svelteflow.dev"]',
    );
    anchorElement?.parentNode?.removeChild(anchorElement);
  }
  const proOptions = { hideAttribution: true };
</script>

<div class="collapse mt-4 overflow-auto rounded border" style="height:50vh;">
  <SvelteFlow {nodes} {edges} {nodeTypes} {proOptions} fitView>
    <!-- <MiniMap /> -->
    <Controls />
  </SvelteFlow>
</div>
