<script>
  import { assetApprovalStatusToColour, getUser } from "../../../helpers";
  import { onMount } from "svelte";
  import { isEmpty } from "../../../lib/utils/GenericUtils";
  import { toast } from "svelte-sonner";

  export let comment;

  let action = "";
  let setStatus = "";
  let commentDetails = "";

  function parseActionFromComment(text) {
    if (!isEmpty(text)) {
      return text.replace(/ .*/, "");
    }
  }

  function parseSetStatusFromComment(text) {
    if (!isEmpty(text)) {
      const commentNoPunctuation = text.replace(
        /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
        "",
      );
      return commentNoPunctuation.split(" ").slice(-1)[0];
    }
  }

  function parseCommentDetailsFromComment(comment) {
    try {
      if (!isEmpty(comment?.text)) {
        let words = comment?.text.split(" ");

        if (words.length <= 2) {
          throw new Error(`Unable to parse Comment(${comment.id}).`);
        }

        words.shift();
        words.pop();

        return words.join(" ");
      }
    } catch (e) {
      console.error(e);
      toast.error(e?.message);
    }
  }

  onMount(() => {
    action = parseActionFromComment(comment?.text);
    setStatus = parseSetStatusFromComment(comment?.text);
    commentDetails = parseCommentDetailsFromComment(comment);
  });
</script>

<p class="text-sm">
  {getUser(comment.user_id)}
  <strong style={assetApprovalStatusToColour(action)}>{action}</strong>
  {commentDetails}
  <strong style={assetApprovalStatusToColour(setStatus)}>{setStatus}</strong>
</p>
