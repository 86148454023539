<script>
  import { getTextColor, nameToColor } from "../../../helpers";
</script>

<div class="avatar placeholder chat-image">
  <div
    class="w-10 rounded-full"
    style:background-color={nameToColor("HB")}
    style:color={getTextColor(nameToColor("HB"))}
  >
    <span>HB</span>
  </div>
</div>
