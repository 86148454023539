<script>
  import { getUser } from "../../../helpers";

  export let comment;
</script>

<p class="text-sm">
  {getUser(comment.user_id)} <strong>unassigned</strong>
  <i>{getUser(comment.to_user_id)}</i>
</p>
