<script>
  import { push } from "svelte-spa-router";
  import { currentUser } from "../../stores";

  export let dictionaries = [];
  export let dictToEditId = -1;
</script>

<div class="my-8">
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
  <div class=" grid w-full gap-2 overflow-auto p-2">
    <div class="grid min-w-[750px] grid-cols-2 border-b px-6 font-semibold">
      <div
        class=" col-span-1 cursor-pointer justify-start gap-4"
        on:click|stopPropagation
      >
        <span class="label-text">Dictionary Name</span>
      </div>
      <span class="col-span-1 text-end">Actions</span>
    </div>

    {#each dictionaries as dictionary}
      <div class="rounded border bg-base-200">
        <div
          class="flex cursor-pointer items-center justify-between gap-2 px-6 py-2"
        >
          <div
            class="label col-span-1 cursor-pointer justify-start gap-4"
            on:click|stopPropagation
          >
            <div>
              <span class="label-text">{dictionary.name}</span>
              <p class="text-sm font-light">
                {dictionary.description}
              </p>
            </div>
          </div>

          <div class="flex items-center gap-1">
            <btn
              class="btn btn-outline btn-primary btn-xs"
              on:click={() => {
                dictToEditId = dictionary.id;
                push(`/spelling/dictionaries/${dictionary.id}`);
              }}
            >
              {dictionary?.collaborators?.includes($currentUser.id) ||
              dictionary?.owner?.includes($currentUser.id)
                ? "Edit"
                : "View"} Dictionary
            </btn>
          </div>
        </div>
      </div>
    {/each}
  </div>
</div>
