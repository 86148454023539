<script>
  import { Controls, MarkerType, Position, SvelteFlow } from "@xyflow/svelte";
  import "@xyflow/svelte/dist/style.css";
  import { writable } from "svelte/store";
  import CreateWorkflowStepCard from "./CreateWorkflowStepCard.svelte";
  import EditableWorkflowStep from "./EditableWorkflowStep.svelte";
  import InsertStepEdge from "./InsertStepEdge.svelte";
  import { saveStep, workflowAction } from "./workflowAction";

  export let selectedWorkflow;
  export let report_id = null;

  // The writable stores for nodes and edges
  const nodes = writable([]);
  const edges = writable([]);

  const nodeTypes = {
    workflowStep: EditableWorkflowStep,
    createWorkflowStep: CreateWorkflowStepCard,
  };
  const edgeTypes = {
    insertStepEdge: InsertStepEdge,
  };
  workflowAction.subscribe(($workflowAction) => {
    $workflowAction.addStep = (position) => {
      let newPosition;
      if (position === "start") {
        const smallestPosition = selectedWorkflow.workflow_steps.reduce(
          (min, step) => Math.min(min, step.position),
          Number.MAX_SAFE_INTEGER,
        );
        newPosition = smallestPosition - 1;
      } else if (position === "end") {
        const largestPosition = selectedWorkflow.workflow_steps.reduce(
          (max, step) => Math.max(max, step.position),
          Number.MIN_SAFE_INTEGER,
        );
        newPosition = largestPosition + 1;
      }
      const newStep = {
        id: Date.now(),
        name: "",
        description: "",
        position: newPosition,
        teams: [],
        users: [],
      };
      if (position === "start") {
        selectedWorkflow.workflow_steps.unshift(newStep); // Add to the start
      } else if (position === "end") {
        selectedWorkflow.workflow_steps.push(newStep); // Add to the end
      }

      createLinearNodes(selectedWorkflow);
    };
  });
  // Subscribe to saveStep for handling updates
  saveStep.subscribe(($saveStep) => {
    //save the changes
    if ($saveStep.id && $saveStep.type == "save") {
      const index = selectedWorkflow.workflow_steps.findIndex(
        (step) => step.id === $saveStep.id,
      );
      if (index !== -1) {
        selectedWorkflow.workflow_steps[index] = {
          ...selectedWorkflow.workflow_steps[index],
          name: $saveStep.name,
          description: $saveStep.description,
          users: $saveStep.users,
          teams: $saveStep.teams,
        };
        // Trigger reactivity manually if necessary
        selectedWorkflow = selectedWorkflow;
        // Re-generate the nodes and edges to reflect the changes
        createLinearNodes(selectedWorkflow);
      }
    }
    //delete the node
    if ($saveStep.id && $saveStep.type == "delete") {
      const index = selectedWorkflow.workflow_steps.findIndex(
        (step) => step.id === $saveStep.id,
      );
      if (index !== -1) {
        selectedWorkflow.workflow_steps.splice(index, 1); // Remove the item at the specified index
        // Update the positions of the remaining steps, if required
        /// Adjust the positions of the remaining steps to fill in the gap
        selectedWorkflow.workflow_steps.forEach((step, idx) => {
          // Assuming `position` starts at 1 and increments by 1 for each step
          step.position = idx + 1;
        });
        // Trigger reactivity manually if necessary
        selectedWorkflow = selectedWorkflow;
        // Re-generate the nodes and edges to reflect the changes
        createLinearNodes(selectedWorkflow);
      }
    }
  });

  function createLinearNodes(workflow) {
    let xPos = 20;
    const yPos = 60;
    const xOffset = 300;

    // First, sort the workflow steps by their position property
    const sortedSteps = workflow.workflow_steps.sort(
      (a, b) => a.position - b.position,
    );

    let steps = [
      { id: "start", type: "createWorkflowStep", data: { position: "start" } },
      ...sortedSteps.map((step) => ({
        ...step,
        type: "workflowStep",
        data: {
          ...step, // Preserving existing data
          report_id,
        },
      })),
      { id: "end", type: "createWorkflowStep", data: { position: "end" } },
    ];

    const newNodes = steps.map((step, index) => ({
      id: `horizontal-${step.id}`,
      type: step.type,
      data: step.data || step,
      position: { x: xPos + xOffset * index, y: yPos },
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    }));

    // // Dynamically determine edge type based on node types it connects
    const newEdges = newNodes
      .slice(1) // Excluding the first node for iteration
      .map((node, index) => {
        const current = newNodes[index];
        const next = node;

        // Determine edge type based on the current and next node types
        let edgeType = "bezier"; // Default edge type
        // TODO: reimplement the insert and edge between two steps
        // if (current.type === 'workflowStep' && next.type === 'workflowStep') {
        //     edgeType = 'insertStepEdge'; // Use insertStepEdge if both nodes are workflowSteps
        // }

        return {
          id: `edge-${current.id}-${next.id}`,
          source: current.id,
          target: next.id,
          type: edgeType, // This dynamically assigns the edge type
          //animated: true,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 15,
            height: 15,
            color: "#555",
          },
          style: "stroke-width: 1px; stroke: #555;",
        };
      });

    nodes.set(newNodes);
    edges.set(newEdges);
  }

  // Reactively update the nodes and edges when the selected workflow changes
  $: if (selectedWorkflow && selectedWorkflow.workflow_steps) {
    createLinearNodes(selectedWorkflow);
  }

  const proOptions = { hideAttribution: true };
</script>

<div class="workflow-visualizer" style="height: 50vh;">
  <SvelteFlow {nodes} {edges} {nodeTypes} {edgeTypes} {proOptions} fitView>
    <!-- <MiniMap /> -->
    <Controls />
  </SvelteFlow>
</div>

<style>
  :global(.svelte-flow .handle) {
    background-color: transparent;
    pointer-events: none;
    min-width: 0;
    min-height: 0;
    width: 0;
    height: 0;
    background-color: var(
      --xy-handle-background-color,
      var(--xy-handle-background-color-default)
    );
    border: 0;
    border-radius: 0;
  }
</style>
