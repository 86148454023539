<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { link, push } from "svelte-spa-router";
  import { fly } from "svelte/transition";
  import Modal from "../components/Modals/Modal.svelte";
  import DictionaryEditor from "../components/Spelling/DictionaryEditor.svelte";
  import DictionaryManager from "../components/Spelling/DictionaryManager.svelte";
  import { currentUser } from "../stores";

  export let params = null;

  let newDictName = "";
  let newDictDescription = "";

  let wordToAdd = "";

  let dictionaries = [];
  let standardDictionaries = [];
  let sharedDictionaries = [];
  let myDictionaries = [];

  let dictToEditId = params.dictionary_id ? params.dictionary_id : -1;
  let dictionaryToEdit = {
    id: 1,
    name: "",
    description: "",
    type: "",
    owner: [], //No owner
    collaborators: [], //No collaborators
  };

  function loadDictionaries() {
    //To implement actual API.

    dictionaries = [
      {
        id: 1,
        name: "Australian English",
        description: "Standard Australian English Dictionary",
        type: "standard",
        owner: [], //No owner
        collaborators: [], //No collaborators
      },
      {
        id: 2,
        name: "Aboriginal Place Names",
        description: "Common Aboriginal Place Names",
        type: "standard",
        owner: [], //No owner
        collaborators: [], //No collaborators
      },
      {
        id: 3,
        name: "NRMA Terms",
        description: "Specific Terms for NRMA",
        type: "shared",
        owner: [2], //User ID of the owner of this dictionary
        collaborators: [1, 3, 4], //User IDs of any collaborators of this dictionary
      },
      {
        id: 4,
        name: "My Team Dictionary",
        description: "My Custom Terms",
        type: "shared",
        owner: [1], //User ID of the owner of this dictionary
        collaborators: [2, 3, 4], //User IDs of any collaborators of this dictionary
      },
      {
        //This should be autocreated and autoupdated for the report
        id: 142292782, //ID always equals stream ID
        name: "Report Dictionary",
        description: "Custom Dictionary, specific to this report",
        type: "report",
        owner: [], //Set to none
        collaborators: [1, 2, 3, 4], //Any users with edit permissions on the stream
      },
    ];

    //Classify dictionaries

    for (let i = 0; i < dictionaries.length; i++) {
      if (dictionaries[i].type == "standard") {
        standardDictionaries.push(dictionaries[i]);
      } else if (dictionaries[i].owner.includes($currentUser.id)) {
        myDictionaries.push(dictionaries[i]);
      } else if (dictionaries[i].type == "shared") {
        sharedDictionaries.push(dictionaries[i]);
      }
    }

    standardDictionaries = standardDictionaries;
    sharedDictionaries = sharedDictionaries;
    myDictionaries = myDictionaries;
  }

  function addDict() {
    // Make API call to add the dictionary. Should default as "my dictionary"
  }

  function addWord() {
    // Make API call to add the dictionary. Should default as "my dictionary"
  }

  const tabs = [
    { label: "My Dictionaries", type: "my" },
    { label: "Dictionaries Shared With Me", type: "shared" },
    { label: "Standard Dictionaries", type: "standard" },
  ];
  let activeTab = "My Dictionaries";

  onMount(async () => {
    loadDictionaries();
  });

  $: if (dictToEditId > 0) {
    dictionaryToEdit = dictionaries.filter((d) => d.id == dictToEditId)[0];
  } else {
    dictionaryToEdit = {
      id: 1,
      name: "",
      description: "",
      type: "",
      owner: [], //No owner
      collaborators: [], //No collaborators
    };
  }
</script>

<svelte:head>
  <title>Dictionaries - Haast</title>
</svelte:head>

{#key dictToEditId}
  <header class="bg-base-content/[5%] -mx-12 -my-4 mb-4 px-12 py-4 pb-0">
    <!-- extra contianer required for max-w-screen-xl but full background -->
    <div
      class="mx-auto flex max-w-screen-xl flex-col gap-8 {dictToEditId > 0
        ? 'pb-8'
        : 'pb-0'}"
    >
      <div class="breadcrumbs text-sm">
        <ul>
          <li>
            <a href="/spelling/dictionaries" use:link>Manage Dictionaries</a>
          </li>
          <li>{activeTab}</li>
          {#if dictToEditId > 0 && dictionaryToEdit?.name}
            <li>{dictionaryToEdit.name}</li>
          {/if}
        </ul>
      </div>

      <div class="flex items-center gap-4">
        <div>
          {#if dictToEditId > 0 && dictionaryToEdit}
            <h1 class="text-3xl font-semibold">{dictionaryToEdit.name}</h1>

            <p class="text-sm font-light">
              {dictionaryToEdit.description}
            </p>
          {:else}
            <h1 class="text-3xl font-semibold">Manage Dictionaries</h1>
          {/if}
        </div>

        <div class="ml-auto">
          {#if dictToEditId > 0 && dictionaryToEdit}
            <button
              class="btn btn-sm"
              on:click={() => {
                dictToEditId = -1;
                push("/spelling/dictionaries");
              }}
            >
              Back to Dictionaries
            </button>
            <!-- svelte-ignore missing-declaration -->
            <button
              class="btn btn-primary btn-sm"
              disabled={!(
                dictionaryToEdit.collaborators.includes($currentUser.id) ||
                dictionaryToEdit.owner.includes($currentUser.id)
              )}
              on:click={() => {
                addWordModal.showModal();
              }}
            >
              Add Word
            </button>
          {:else}
            <!-- svelte-ignore missing-declaration -->
            <button
              class="btn btn-primary btn-sm"
              on:click={() => {
                newDictionaryModal.showModal();
              }}
            >
              Create New Dictionary
            </button>
          {/if}
        </div>
      </div>

      {#if !(dictToEditId > 0)}
        <div class="tabs relative">
          {#each tabs as item}
            <button
              class="tab whitespace-nowrap text-base font-medium"
              class:text-primary={activeTab === item.label}
              on:click={() => (activeTab = item.label)}
            >
              <Icon icon={item.icon} class="mr-1" />
              {item.label}
            </button>
          {/each}
          <div
            class="slider bg-primary absolute bottom-0 left-0 h-0.5 transition-all"
            style:width="{100 / tabs.length}%"
            style="translate: {tabs.findIndex((i) => i.label === activeTab) *
              100}%"
          />
        </div>
      {/if}
    </div>
  </header>
{/key}

<div class="mx-auto max-w-screen-xl">
  {#if dictToEditId > 0 && dictionaryToEdit}
    <DictionaryEditor dictionary={dictionaryToEdit} />
  {:else}
    {#key activeTab}
      <div class="flex flex-col gap-2 rounded border p-2" in:fly={{ y: 50 }}>
        {#if activeTab == "My Dictionaries"}
          <DictionaryManager dictionaries={myDictionaries} bind:dictToEditId />
        {:else if activeTab == "Dictionaries Shared With Me"}
          <DictionaryManager
            dictionaries={sharedDictionaries}
            bind:dictToEditId
          />
        {:else}
          <DictionaryManager
            dictionaries={standardDictionaries}
            bind:dictToEditId
          />
        {/if}
      </div>
    {/key}
  {/if}
</div>

<!--Dialogs-->

<Modal
  modalId="newDictionaryModal"
  on:close={() => {
    newDictName = "";
    newDictDescription = "";
  }}
>
  <h3 class="text-center font-bold">Create New Dictionary</h3>

  <div class="my-4 flex flex-col items-center gap-2">
    <div class="w-full">
      <label class="label" for="dictionary_name">
        <span class="label-text">Dictionary name</span>
      </label>
      <input
        type="text"
        bind:value={newDictName}
        placeholder="Dictionary Name"
        class="input input-bordered w-full"
        id="dictionary_name"
      />
    </div>
    <div class="w-full">
      <label class="label" for="dictionary_description">
        <span class="label-text">Dictionary description</span>
      </label>
      <input
        type="text"
        bind:value={newDictDescription}
        placeholder="Dictionary Description"
        class="input input-bordered w-full"
        id="dictionary_description"
      />
    </div>
  </div>

  <button
    on:click={() => {
      addDict();
      newDictName = "";
      newDictDescription = "";
      newDictionaryModal.close();
    }}
    class="btn btn-primary w-full"
  >
    Create Dictionary
  </button>
</Modal>

<Modal modalId="addWordModal" on:close={() => (wordToAdd = "")}>
  <h3 class="text-center font-bold">Add Word / Phrase to Dictionary</h3>

  <div class="flex flex-col items-center gap-2">
    <div class="w-full">
      <label class="label" for="word_to_add">
        <span class="label-text"
          >What word / phrase would you like to add as accepted to the
          dictionary?</span
        >
      </label>
      <input
        type="text"
        bind:value={wordToAdd}
        placeholder="Word / phrase to add"
        class="input input-bordered w-full"
        id="word_to_add"
      />
    </div>
  </div>

  <!-- svelte-ignore missing-declaration -->
  <button
    on:click={() => {
      addWord();
      wordToAdd = "";
      addWordModal.close();
    }}
    class="btn btn-primary w-full"
  >
    Add Word / Phrase
  </button>
</Modal>
