<script lang="ts">
  import { javascript } from "@codemirror/lang-javascript";
  import CodeMirror from "svelte-codemirror-editor";
  import { toast } from "svelte-sonner";
  import { saveRuleToMyRules, tidyPoolLabel } from "../../helpers";
  import Modal from "./Modal.svelte";

  export let rule;
  export let saved_rules: any;

  function convertUTCDateToCurrentTimezone(dateString: string) {
    const date = new Date(dateString);
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - offset * 60 * 1000);
    return newDate;
  }
</script>

<Modal modalId="rule_info_modal_{rule.id}">
  <div class="tooltip" data-tip={rule.name}>
    <h2 class="max-w-xs truncate text-2xl font-semibold">
      {rule.name}
    </h2>
  </div>
  <p class="text-base-content/70 font-light">
    ID: #{rule.id}{rule.description ? " | " + rule.description : ""}
  </p>

  <p class="mt-2 flex gap-1 overflow-auto">
    <span class="font-semibold">Query: </span>
    <CodeMirror
      bind:value={rule.boolean}
      editable={false}
      readonly={true}
      lang={javascript()}
      styles={{
        "&": { fontSize: "14px" },
        ".cm-gutters": { display: "none" },
      }}
    />
  </p>

  <p class="mt-2 text-sm font-light">
    <span class="font-semibold">Channels: </span>
    {tidyPoolLabel(rule.data_type)}
  </p>

  <p class="mt-2 text-sm font-light">
    <span class="font-semibold">Default Priority: </span>
    {rule.default_hit_priority}
  </p>
  <p class="mt-2 text-sm font-light">
    <span class="font-semibold">Created Date: </span>
    {convertUTCDateToCurrentTimezone(rule.created_date).toLocaleString()}
  </p>

  <button
    class="btn btn-outline btn-primary btn-xs mt-4"
    on:click={async () => {
      saved_rules = saveRuleToMyRules(rule.id, saved_rules);
      toast.success("Rule has been saved to My Rules");
    }}
  >
    Save to My Rules
  </button>
</Modal>
