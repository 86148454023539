<script>
  import Icon from "@iconify/svelte";
  import {
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    Title,
    Tooltip,
  } from "chart.js";
  import { onMount } from "svelte";
  import { Bar } from "svelte-chartjs";
  import Pagination from "../../components/table/Pagination.svelte";
  import { fetchGet, tidyPoolLabel } from "../../helpers";

  Chart.register(
    Title,
    Tooltip,
    BarElement,
    CategoryScale,
    LinearScale,
    Legend,
  );

  export let dataReady = false;

  $: if (dataReady) {
    setChartTableData();
  }

  let chartData = {};
  let loaded = false;
  let tableData = [];

  let currentView = "Chart";

  let sortedColumn = null;
  let sortDirection = 1; // 1 for ascending, -1 for descending

  let customerFilter = "";
  let datatypeFilter = "";

  function sortTable(column) {
    if (sortedColumn === column) {
      sortDirection *= -1;
    } else {
      sortedColumn = column;
      sortDirection = 1;
    }

    tableData = [...tableData].sort((a, b) => {
      if (column === "count") {
        return (a.count - b.count) * sortDirection;
      } else {
        const comparison = a[column].localeCompare(b[column]);
        return comparison * sortDirection;
      }
    });
  }

  const setChartTableData = async () => {
    const response = await fetchGet("/pool/chart");
    chartData = await response.data;

    for (let i = 0; i < chartData.labels.length; i++) {
      const element = chartData.labels[i];
      const tidyLabel = tidyPoolLabel(element);
      chartData.labels[i] = tidyLabel;
    }

    // Prepare the table data from the chartData object
    const allCustomers = chartData.datasets.map((dataset) => dataset.label);
    const allDatatypes = chartData.labels;

    tableData = allCustomers.flatMap((customer) =>
      allDatatypes.map((datatype) => {
        const datasetIndex = allCustomers.indexOf(customer);
        const datatypeIndex = allDatatypes.indexOf(datatype);
        const count =
          datasetIndex !== -1 &&
          datatypeIndex !== -1 &&
          chartData.datasets[datasetIndex].data.length > datatypeIndex
            ? chartData.datasets[datasetIndex].data[datatypeIndex]
            : 0;

        return {
          customer: customer,
          datatype: datatype,
          count: count,
        };
      }),
    );

    loaded = true;
  };
  onMount(async () => {
    if (dataReady) {
      await setChartTableData();
    }
  });

  async function refreshIndexCount() {
    loaded = false;
    const response = await fetchGet("/pool/refresh");
    if (response.success) setChartTableData();
  }

  const exportToCSV = () => {
    const csvData = tableData.map((item) => {
      return [item.customer, item.datatype, item.count].join(",");
    });

    const csvContent = "Customer,Datatype,Count\n" + csvData.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let currentPage = 1;
  let itemsPerPage = 10;
</script>

<div class="flex items-center gap-2">
  <button class="btn btn-square btn-ghost btn-sm" on:click={refreshIndexCount}>
    <Icon icon="iconoir:refresh" />
  </button>

  {#if currentView === "Table"}
    <input
      type="text"
      placeholder="Filter by Customer"
      bind:value={customerFilter}
      class="input input-sm input-bordered grow"
    />
    <input
      type="text"
      placeholder="Filter by Datatype"
      bind:value={datatypeFilter}
      class="input input-sm input-bordered grow"
    />
    <button class="btn btn-primary btn-sm" on:click={exportToCSV}>
      Export to CSV
    </button>
  {/if}

  <button
    class="btn btn-primary btn-sm ml-auto"
    on:click={() => (currentView = currentView === "Chart" ? "Table" : "Chart")}
  >
    {currentView === "Chart" ? "Table" : "Chart"}
  </button>
</div>

{#if currentView === "Chart"}
  {#if loaded}
    <Bar
      data={chartData}
      options={{
        responsive: true,
        plugins: {
          legend: { display: true, position: "top" },
          datalabels: { display: false },
        },
      }}
    />
  {:else}
    <div class="flex h-full max-h-96 justify-around gap-2 max-lg:h-96">
      {#each { length: 6 } as _, i}
        <div class="flex items-end gap-2">
          <div class="skeleton h-40 w-4" />
          <div class="skeleton h-80 w-4" />
          <div class="skeleton h-64 w-4" />
        </div>
      {/each}
    </div>
  {/if}
{:else if currentView === "Table"}
  <table class="table">
    <thead>
      <tr>
        <th on:click={() => sortTable("customer")}>Customer</th>
        <th on:click={() => sortTable("datatype")}>Datatype</th>
        <th on:click={() => sortTable("count")}>Count</th>
      </tr>
    </thead>
    <tbody>
      {#if loaded}
        {#each tableData
          .filter((item) => item.customer
                .toLowerCase()
                .includes(customerFilter.toLowerCase()) && item.datatype
                .toLowerCase()
                .includes(datatypeFilter.toLowerCase()))
          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) as item}
          <tr>
            <td>{item.customer}</td>
            <td>{item.datatype}</td>
            <td>{item.count}</td>
          </tr>
        {/each}
      {:else}
        {#each { length: 6 } as _, i}
          <tr>
            <td>
              <div class="skeleton h-4 w-24" />
            </td>
            <td>
              <div class="skeleton h-4 w-40" />
            </td>
            <td>
              <div class="skeleton h-4 w-20" />
            </td>
          </tr>
        {/each}
      {/if}
    </tbody>
  </table>
  <Pagination
    bind:currentPage
    bind:itemsPerPage
    totalHits={tableData.filter(
      (item) =>
        item.customer.toLowerCase().includes(customerFilter.toLowerCase()) &&
        item.datatype.toLowerCase().includes(datatypeFilter.toLowerCase()),
    ).length}
  />
{/if}
