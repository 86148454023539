<script>
  import { createEventDispatcher, onMount } from "svelte";
  const dispatch = createEventDispatcher();

  let filters = {
    status: {
      flagged: { displayName: "Flagged", selected: true },
      open: { displayName: "Open", selected: true },
      closed: { displayName: "Closed", selected: true },
      removed: { displayName: "Removed", selected: true },
    },
    done: {
      done: { displayName: "Complete", selected: true },
      notDone: { displayName: "Not Complete", selected: true },
    },
    priority: {
      high: { displayName: "High", selected: true },
      medium: { displayName: "Medium", selected: true },
      low: { displayName: "Low", selected: true },
    },
  };

  onMount(async () => {
    dispatch("updateFilters", filters);
  });

  $: dispatch("updateFilters", filters);
</script>

<div>
  <h3 class="text-lg font-semibold">Filter by</h3>

  <div class="grid grid-cols-2 gap-x-2 sm:grid-cols-3">
    {#each Object.keys(filters) as filter}
      <div class="flex flex-col">
        <p class="mt-1 font-semibold capitalize">{filter}</p>
        {#each Object.keys(filters[filter]) as filterOption}
          <label class="label cursor-pointer justify-start gap-2">
            <input
              class="checkbox checkbox-sm"
              type="checkbox"
              name={"filter_" + filters[filter][filterOption]["displayName"]}
              id={"filter_" + filters[filter][filterOption]["displayName"]}
              bind:checked={filters[filter][filterOption]["selected"]}
            />

            <span class="label-text">
              {filters[filter][filterOption]["displayName"]}
            </span>
          </label>
        {/each}
      </div>
    {/each}
  </div>
</div>
